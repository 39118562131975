<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';

const props = defineProps<{
  modelValue: number;
}>();
const emit = defineEmits<{
  (e: 'update:modelValue', data: number): void;
}>();

onMounted(() => {
  window.addEventListener('keydown', onKeyPress);
});
onUnmounted(() => {
  window.removeEventListener('keydown', onKeyPress);
});
const zoomScale = computed<number>(() => {
  return Math.round(props.modelValue * 100);
});

const onKeyPress = (event) => {
  if ((event?.code == 'Equal' || event?.keyCode == 187) && event?.ctrlKey) {
    event.preventDefault();
    zoomInOut(0.2);
  }
  if ((event?.code == 'Minus' || event?.keyCode == 189) && event?.ctrlKey) {
    event.preventDefault();
    zoomInOut(-0.2);
  }
};

const zoomTo = (scale) => {
  console.log('🚀 Tictop ~ scale:', scale);
  emit('update:modelValue', scale);
};
const zoomInOut = (addScale) => {
  if (addScale < 0 && props.modelValue <= 0.25) return;

  scaleInputValue.value += addScale;
  emit('update:modelValue', scaleInputValue.value / 100);
};
const scaleInputValue = ref<number>(Math.round(props.modelValue * 100));

const onChangeScale = (data) => {
  const scale = data?.target?.value;

  if (Number.isNaN(scale) || scale <= 0) return;

  zoomTo(scale / 100);
};

let typingTimer;
const handleScaleInput = () => {
  // Clear the previous timer
  if (typingTimer) clearTimeout(typingTimer);

  // Set a new timer
  typingTimer = setTimeout(() => {
    if (Number.isNaN(scaleInputValue.value) || scaleInputValue.value <= 0)
      return;

    zoomTo(scaleInputValue.value / 100);
  }, 1000);
};

const onResetZoom = () => {
  zoomTo(1);

  scaleInputValue.value = 100;
};
</script>
<template>
  <VigDropdown placement="top-start" :arrow="false">
    <template #dropdown-toggle>
      <div class="flex-center px-4 py-2 bg-gray-100 z-100 cursor-pointer">
        <span class="font-semibold text-base"> {{ zoomScale }} % </span>
      </div>
    </template>
    <template #dropdown-menu>
      <div class="flex flex-col w-60">
        <div class="list-ul py-2 relative">
          <div
            v-if="scaleInputValue !== 100"
            class="px-2 flex items-center justify-end"
          >
            <span
              class="
                w-max
                text-sm
                px-2
                py-1
                cursor-pointer
                rounded
                hover:bg-current-50
                border
                flex-center
                gap-2
              "
              @click="onResetZoom"
            >
              <SynIcon name="reset" custom-class="w-3 h-3 fill-gray-500" />
              <span>
                {{ $t('COMMON_LABEL_RESET') }}
              </span>
            </span>
          </div>
          <div
            class="
              list-li
              px-4
              flex
              items-center
              justify-between
              hover-to-show__parent
              h-9
            "
            @click="zoomInOut(-20)"
          >
            <span class="text-sm">
              {{ $t('COMMON_LABEL_ZOOM_OUT') }}
            </span>
            <span class="text-xs font-semibold"> Ctrl - </span>
          </div>
          <div
            class="
              list-li
              px-4
              flex
              items-center
              justify-between
              hover-to-show__parent
              h-9
            "
            @click="zoomInOut(20)"
          >
            <span class="text-sm">
              {{ $t('COMMON_LABEL_ZOOM_IN') }}
            </span>

            <span class="text-xs font-semibold"> Ctrl +</span>
          </div>
          <div
            class="
              list-li
              px-4
              flex
              items-center
              justify-between
              hover-to-show__parent
              min-h-9 min-h-9
            "
          >
            <span class="text-sm"> {{ $t('COMMON_LABEL_ZOOM_TO') }} </span>
            <div class="flex-center gap-4">
              <div class="w-max">
                <input
                  v-model="scaleInputValue"
                  type="number"
                  class="
                    w-full
                    zoom-input
                    hover:bg-current-50
                    focus:bg-current-50
                    rounded
                    text-current-500
                  "
                  size="3"
                  @change="onChangeScale"
                  @input="handleScaleInput"
                />%
              </div>
              <div class="flex-center gap-2">
                <span
                  class="
                    w-max
                    text-xs
                    cursor-pointer
                    rounded
                    hover:bg-current-50
                    p-1
                    border
                  "
                  @click="zoomInOut(-5)"
                >
                  -5
                </span>
                <span
                  class="
                    w-max
                    text-xs
                    p-1
                    cursor-pointer
                    rounded
                    hover:bg-current-50
                    border
                  "
                  @click="zoomInOut(5)"
                >
                  +5
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </VigDropdown>
</template>
