<template>
  <section class="text-center">
    <div class="flex items-center space-x-8 text-sm">
      <div class="flex items-center space-x-2">
        <SynIcon name="globals" class="fill-current" />
        <span>
          <a
            class="text-current-700 hover:text-current-900"
            href="https://www.fiine.vn/"
            target="_blank"
            >https://www.fiine.vn</a
          >
        </span>
      </div>
      <div class="flex items-center space-x-2">
        <SynIcon name="email" class="fill-current" />
        <span>
          <a
            class="text-current-700 hover:text-current-900"
            href="mailto:services@fiine.pro"
            target="_blank"
            >services@fiine.pro</a
          >
        </span>
      </div>
      <div class="flex items-center space-x-2">
        <SynIcon name="support" class="fill-current" />
        <span>
          <a
            class="text-current-700 hover:text-current-900"
            href="mailto:support@fiine.pro"
            target="_blank"
            >support@fiine.pro</a
          >
        </span>
      </div>
      <div class="flex items-center space-x-2">
        <SynIcon name="phone" class="fill-current" />
        <span>
          <a class="text-current-700 hover:text-current-900" target="_blank"
            >(+84) 919 326 983</a
          >
        </span>
      </div>
    </div>
    <div class="text-gray-600 text-xs pt-2">
      Copyright © 2024 Tictop | Powered by Tictop
    </div>
  </section>
</template>
