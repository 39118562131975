import { Ref } from 'vue';
import DesktopDrawerEntity from '@/ui/components/drawer/entities/DesktopDrawerEntity';
import { IDesktopDrawerShape } from '@/ui/components/drawer/schema';

export interface TDesktopDrawer {
    keepingShape: Ref<{
        shapeId: string;
        groupId?: string;
        offsetX: number;
        offsetY: number;
    }>;
    updateKeepingShape: (
        shape: IDesktopDrawerShape,
        type?: 'RESIZE' | 'MOVE'
    ) => void;
    desktopDrawerInstance: Ref<DesktopDrawerEntity>;
    unSelectAll: () => void;
    onMouseUp: () => void;
    onSelectResize: () => void;
    updateArrowShape: (shapeId: string, side: 'start' | 'end') => void;
    addMoreHistory: () => void;
}
export interface TDesktopDrawerShape {
    onMouseDown: (event: any, type?: 'RESIZE' | 'MOVE') => void;
}

export default {
    app: 'APP',
    DESKTOP_DRAWER: 'DESKTOP_DRAWER',
    DESKTOP_DRAWER_SHAPE: 'DESKTOP_DRAWER_SHAPE',
};
