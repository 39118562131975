<script setup lang="ts">
import { useRouter, useRoute } from 'vue-router';
import LoginSlide from '@/ui/components/slide-image/LoginSlide.vue';
import loginPageStore from '@/store/un-authenticate/login-store';
import { ref } from 'vue';

const router = useRouter();
const route = useRoute();
const _loginPageStore = loginPageStore();
const isLoading = ref(true);

const initData = async () => {
  // Call api check token
  try {
    const verifyToken = await _loginPageStore.verifyToken();
    if (verifyToken?.isExpired) return;

    await _loginPageStore.prepareDataBeforeNextRoute();

    gotoNextRoute();
  } finally {
    isLoading.value = false;
  }
};

const gotoNextRoute = () => {
  const nextRoute = route.query?.redirect
    ? route.query?.redirect.toString()
    : `/`;

  router.push(nextRoute);
};

initData();
</script>

<template>
  <template v-if="isLoading">
    <SynLoading />
  </template>
  <div v-else class="w-screen h-screen flex relative">
    <!-- DESCRIPTION -->
    <section class="flex-1 w-1/2 h-full hidden md:block">
      <LoginSlide />
    </section>

    <!-- CONTENT -->
    <section class="flex-1 w-full bg-white relative">
      <slot />
    </section>
  </div>
</template>
