<script setup lang="ts">
import {
  colorByAlphabet,
  convertViToEn,
  cleanString,
} from '@/ui/common/molecules/SynAvatar/colorByAlphabet';
import { computed } from 'vue';
import userStore from '@/store/user';
import groupStore from '@/store/group';
import dayjs from 'dayjs';
import { formatTaskCode, taskStates } from '@/ui/hooks/taskHook';
import { ETaskListModule } from '@/application/types/task/task.types';

const props = defineProps<{
  data: any;
  sourceType: ETaskListModule;
}>();
const _groupStore = groupStore();

const textAva = computed(() => {
  // if (!props?.data?.userId) return 'A';
  // return '';
  return cleanString(assigneeInfo.value?.avatar)
    .split(' ')
    [cleanString(assigneeInfo.value?.avatar).split(' ').length - 1].charAt(0);
});
const allGroupByIds = computed(() => {
  return {
    ..._groupStore.allGroupByIds,
    ..._groupStore.myGroupByIds,
  };
});

const assigneeInfo = computed<any>(() => {
  if (props?.data?.assigneeId)
    return userStore().allUserByIds[props?.data?.assigneeId];

  return allGroupByIds.value[props?.data?.groupId];
});

const overdueDay = computed<number>(() => {
  return props?.data?.scheduleTime
    ? dayjs().diff(dayjs(props?.data?.scheduleTime), 'd')
    : 0;
});
</script>
<template>
  <div
    class="
      flex
      justify-between
      items-center
      space-x-1
      px-1.5
      p-2
      rounded
      cursor-pointer
      w-full
      text-xs
    "
    :class="[taskStates[data?.taskLife]?.background]"
  >
    <div class="flex-center space-x-2 truncate">
      <template v-if="sourceType !== ETaskListModule.USER">
        <div
          v-if="assigneeInfo?.avatar"
          class="rounded-full overflow-hidden w-5 h-5 shadow-sm flex-center"
        >
          <img
            class="w-5 h-5 object-cover rounded-full"
            :src="assigneeInfo?.avatar"
          />
        </div>
        <div
          v-else
          class="
            object-cover
            flex-center
            text-white
            shadow
            font-thin
            rounded-full
            w-5
            h-5
          "
          :style="`background: ${
            colorByAlphabet[convertViToEn(textAva, true)] || '#4bb0b2'
          }`"
        >
          <span v-if="textAva" class="uppercase" style="font-size: 0.75em">{{
            textAva
          }}</span>
        </div>
      </template>

      <!-- <span class="text-gray-800"> {{ data?.userName }} Xin nghỉ </span> -->
      <div class="flex-1 flex items-center gap-1 truncate">
        <span
          :class="[
            overdueDay > 0
              ? 'text-red-500'
              : overdueDay == 0
              ? 'text-orange-500'
              : 'text-gray-500',
          ]"
        >
          {{ formatTaskCode(data?.code) }}
        </span>
        <span
          :title="data?.name"
          class="truncate"
          :style="{
            color: '#000',
          }"
        >
          {{ data?.name }}
        </span>
      </div>
    </div>
  </div>
</template>
