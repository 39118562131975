<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { AdminType } from '@/ui/common/constants/constant';
import { ChatConversationModel } from '@/application/models/chat/ChatConversationModel';
import ChatIcon from '@/ui/modules/messaging/chat-icon/ChatIcon.vue';
import ActionExtend from '@/ui/modules/messaging/chat-action/ActionExtend.vue';
import ModalFilesList from '@/ui/modules/ged/modal-files-list/ModalFilesList.vue';
import ModalPinnedMessages from '@/ui/modules/messaging/modal-pinned-messages/ModalPinnedMessages.vue';
import GroupDetailModal from '@/ui/modules/group/detail/GroupDetailModal.vue';
import chatStore from '@/store/chat';
import myProfileStore from '@/store/auth/my-profile';
import { encryptGroupId, encryptUserId } from '@/ui/plugins/crypto/crypto';
import {
  isOpenCreateTaskDrawer,
  newTaskDefaultPayload,
  onCreateGlobalSuccess,
} from '@/ui/modules/task/task-global-state';
import ChatExternalInfoHeader from '@/ui/modules/messaging/chat-external/chat-external-info-header/ChatExternalInfoHeader.vue';
import chatExternalStore from '@/store/chat/chat-external';
import ChatCallButton from '@/ui/modules/messaging/chat-info-header/chat-call-button/ChatCallButton.vue';
import ChatWidgetHeader from '@/ui/modules/messaging/chat-info-header/ChatWidgetHeader.vue';
import { activeAndFocusOnConversationId } from '@/ui/modules/messaging/chat-widget/chat-widget-public-state';
import ChatExternalWidgetHeader from '@/ui/modules/messaging/chat-external/chat-external-widget-header/ChatExternalWidgetHeader.vue';
import ChatSupportWidgetHeader from '@/ui/modules/messaging/chat-support/chat-support-widget-header/ChatSupportWidgetHeader.vue';
import ChatSupportInfoHeader from '@/ui/modules/messaging/chat-support/chat-support-info-header/ChatSupportInfoHeader.vue';
import ChatEmbedWidgetHeader from '@/ui/modules/messaging/chat-embed/ChatEmbedWidgetHeader.vue';
import PostMessageService from '@/application/services/PostMessageService';
import { AppMessageTypeEnum } from '@/domain/enums/app-message-type.enum';
import ModalNotificationSetting from '@/ui/components/notification/ModalNotificationSetting.vue';
import notificationSettingStore from '@/store/notificationSetting/notificationSettingStore';
import groupStore from '@/store/group';
import overviewGroupStore from '@/store/dashboard/overview-group-store';

const props = defineProps<{
  organizationId?: number | string;
  conversationId?: string;
  conversation?: ChatConversationModel;
  contact?: any;
  isPersonalModel?: boolean;
  isChatWidget?: boolean;
  isEmbeddedApp?: boolean;
}>();

const emit = defineEmits([
  'onOpenNoteClick',
  'onMessageReply',
  'onMessageViewFile',
  'onMessageTaskClick',
  'onSupportingChatLeft',
  'onChatPanelClose',
]);

const _useRouter = useRouter();
const _chatStore = chatStore();
const _groupStore = groupStore();

const showFilesCons = ref<any>();
const isShowPinnedMsg = ref<boolean>();
const groupShowMember = ref();

const myUserId = computed(() => myProfileStore().myProfile?.id);
const isChatDisabled = computed(
  () =>
    props.conversation?.isDeleted ||
    props.conversation?.isDeactive ||
    props.conversation?.isDisallowed
);

const _notificationSettingStore = notificationSettingStore();
const notificationSetting = computed(() => {
  return props.conversationId
    ? _chatStore.mutedConversations[props.conversationId]
    : undefined;
});
const initGroupNotification = async () => {
  if (!props.conversation?.isGroup) return;

  await _notificationSettingStore.getSettingNotification(
    props.contact?.id,
    'GROUP'
  );
};

const initComponent = () => {
  if (props.conversation?.isGroup) {
    initGroupNotification();
  }
};

const onViewFilesClick = () => {
  if (props.isEmbeddedApp) {
    return PostMessageService.getInstance().postMessageToParent({
      type: AppMessageTypeEnum.tictopViewChatFilesClick,
    });
  }

  const params: any = {
    orgId: props.organizationId,
    conversationId: props.conversationId,
    category: 'CHAT',
  };

  if (props.conversation?.externalChat) {
    params.userId = chatExternalStore().getExternalUserId(props.conversationId);
  } else if (props.contact?.isUser) {
    params.userId = props.contact?.id;
  } else {
    params.groupId = props.contact?.id;
  }

  showFilesCons.value = params;
};

const onGotoChatClick = () => {
  closeBubbleChatPanel();

  _chatStore.closeBubbleChat();

  _useRouter.push({
    name: 'Messages',
    params: { conversationId: props.conversationId },
  });
};

const onGotoTaskClick = () => {
  closeBubbleChatPanel();

  if (!props.conversation?.isGroup) {
    const redirectPath =
      props.conversation?.contactId == myUserId.value
        ? '/tasks/my-tasks'
        : `/tasks/users/${encryptUserId(props.conversation?.contactId)}`;

    _useRouter.push(redirectPath);
  } else {
    _useRouter.push(
      `/tasks/groups/${encryptGroupId(props.conversation?.contactId)}`
    );
  }
};

const toggleShowTaskListInChat = () => {
  _chatStore.toggleShowTaskListInChat(props.conversationId);
};

const onOpenCreateTask = () => {
  newTaskDefaultPayload.value = {
    groupId: props.conversation?.isGroup
      ? props.conversation?.contactId
      : undefined,
    assigneeId: props.conversation?.isGroup
      ? undefined
      : props.conversation?.contactId,
    name: '',
  };

  onCreateGlobalSuccess.value = (task) => {
    setTimeout(() => _chatStore.setChatAboutTask(task));
  };

  isOpenCreateTaskDrawer.value = true;
};

const onViewMembersClick = () => {
  groupShowMember.value = { id: props.contact?.id, tab: 'MEMBERS' };
};

const onSettingsClick = () => {
  groupShowMember.value = { id: props.contact?.id, tab: 'INFO_GROUP' };
};

const closeBubbleChatPanel = () => {
  _chatStore.closeBubbleChat();

  setTimeout(() => {
    activeAndFocusOnConversationId.value = '';
  });
  emit('onChatPanelClose');
};

const isShowNotificationSetting = ref(false);

const onChangeNotification = async () => {
  initGroupNotification();
  isShowNotificationSetting.value = false;
};

const toggleNotification = async () => {
  const groupId = props.contact?.id;
  if (!groupId) return;

  if (notificationSetting.value) {
    const enableResult =
      await _notificationSettingStore.enableSettingNotification(
        `group-${groupId}`
      );
    if (enableResult) {
      _groupStore.setValueSettingGroup({}, groupId);
      overviewGroupStore().updateNotificationByGroupId({}, groupId);
      initGroupNotification();
    }
  } else {
    isShowNotificationSetting.value = true;
  }
};

initComponent();
</script>

<template>
  <!--EMBED CHAT-->
  <ChatEmbedWidgetHeader
    v-if="isEmbeddedApp"
    :conversation-id="conversationId"
    :conversation="conversation"
    :contact="contact"
    @on-open-note-click="$emit('onOpenNoteClick')"
    @on-show-files-click="onViewFilesClick"
    @on-show-pinned-messages-click="isShowPinnedMsg = true"
    @on-chat-panel-close="closeBubbleChatPanel"
  />

  <!--EXTERNAL CHAT-->
  <ChatExternalInfoHeader
    v-else-if="conversation?.externalChat && !isChatWidget"
    :conversation="conversation"
    @on-open-note-click="$emit('onOpenNoteClick')"
    @on-show-files-click="onViewFilesClick"
    @on-show-pinned-messages-click="isShowPinnedMsg = true"
  />

  <!--EXTERNAL CHAT WIDGET-->
  <ChatExternalWidgetHeader
    v-else-if="conversation?.externalChat && isChatWidget"
    :conversation="conversation"
    @on-open-note-click="$emit('onOpenNoteClick')"
    @on-show-files-click="onViewFilesClick"
    @on-show-pinned-messages-click="isShowPinnedMsg = true"
    @on-goto-chat-click="onGotoChatClick"
    @on-chat-panel-close="closeBubbleChatPanel"
  />

  <!--SUPPORT CHAT-->
  <ChatSupportInfoHeader
    v-else-if="conversation?.supportingChat && !isChatWidget"
    :conversation="conversation"
    :conversation-id="conversationId"
    @on-open-note-click="$emit('onOpenNoteClick')"
    @on-supporting-chat-left="$emit('onSupportingChatLeft')"
    @on-show-files-click="onViewFilesClick"
    @on-show-pinned-messages-click="isShowPinnedMsg = true"
  />

  <!--SUPPORT CHAT WIDGET-->
  <ChatSupportWidgetHeader
    v-else-if="conversation?.supportingChat && isChatWidget"
    :conversation="conversation"
    :conversation-id="conversationId"
    @on-open-note-click="$emit('onOpenNoteClick')"
    @on-show-files-click="onViewFilesClick"
    @on-show-pinned-messages-click="isShowPinnedMsg = true"
    @on-goto-chat-click="onGotoChatClick"
    @on-chat-panel-close="closeBubbleChatPanel"
  />

  <!--CHAT WIDGET-->
  <ChatWidgetHeader
    v-else-if="isChatWidget"
    :conversation="conversation"
    :conversation-id="conversationId"
    :contact="contact"
    @on-open-create-task="onOpenCreateTask"
    @on-goto-task-click="onGotoTaskClick"
    @on-goto-chat-click="onGotoChatClick"
    @on-chat-panel-close="closeBubbleChatPanel"
    @on-open-note-click="$emit('onOpenNoteClick')"
    @on-show-files-click="onViewFilesClick"
    @on-show-pinned-messages-click="isShowPinnedMsg = true"
    @on-view-group-member="onViewMembersClick"
    @on-view-group-setting="onSettingsClick"
    @on-view-task-list="toggleShowTaskListInChat"
  />

  <!--NORMAL CHAT-->
  <div
    v-else
    class="
      flex
      items-center
      justify-between
      border-b border-current-100
      px-3
      py-2
    "
  >
    <!--INFO-->
    <div class="flex-center space-x-2">
      <ChatIcon
        is-chat-page
        :is-show-suffix-icon="!isPersonalModel"
        :is-dropdown-disabled="isPersonalModel"
      >
        <template #dropdownToggle>
          <SynAvatarStatusActive
            :id="conversation?.contactId"
            :avatar="
              (conversation?.avatarUrl || conversation?.avatar)?.replace(
                'original',
                'mini'
              )
            "
            :name="conversation?.name"
            :is-user="!conversation?.isGroup"
            :is-visitor="conversation?.adminType === AdminType.Visitor"
            :is-deactive="conversation?.isDeactive"
            :is-deleted="conversation?.isDeleted"
            :is-disallowed="conversation?.isDisallowed"
            :short-name="conversation?.shortName"
            class="text-lg font-semibold"
            custom-class="h-10 w-10"
          />
        </template>
      </ChatIcon>

      <SynListAvatar
        :users="contact?.groupUsers"
        :max-length="5"
        class="pl-2"
        custom-class="w-6 h-6 border-none"
      />
    </div>

    <!--ACTIONS-->
    <div class="flex-center space-x-1">
      <ChatCallButton
        v-if="!isChatDisabled && !conversation?.isGroupChatDisallowed"
        :contact="contact"
        custom-class="hover:bg-current-50 fill-current"
      />
      <VigButton
        v-vig-tooltip="{
          content: $t('CHAT_MINI_SHOW_FILES'),
          placement: 'bottom',
        }"
        ghost
        color="gray"
        rounded="rounded-full"
        icon="Files"
        @click="onViewFilesClick"
      />
      <VigButton
        v-vig-tooltip="{
          content: $t('CHAT_MINI_SHOW_PINNED_MESSAGES'),
          placement: 'bottom',
        }"
        ghost
        color="gray"
        rounded="rounded-full"
        icon="Pin"
        @click="isShowPinnedMsg = true"
      />
      <VigButton
        v-if="!isChatDisabled && contact?.groupUsers"
        ghost
        color="gray"
        rounded="rounded-full"
        icon="Groups"
        :title="$t('CHAT_MINI_VIEW_MEMBERS')"
        @click="onViewMembersClick"
      />
      <ActionExtend
        :is-hide-action="['view-page-chat']"
        :is-chat-disabled="isChatDisabled"
        :current-contact-active="contact"
        :conversation-id="conversationId"
        :conversation="conversation"
        :is-chat-widget="isChatWidget"
        :is-turning-off-notification="!!notificationSetting"
        @on-goto-task-click="onGotoTaskClick"
        @on-open-create-task="onOpenCreateTask"
        @on-show-files-click="onViewFilesClick"
        @on-show-pinned-messages-click="isShowPinnedMsg = true"
        @on-open-note-click="$emit('onOpenNoteClick')"
        @on-view-group-member="onViewMembersClick"
        @on-view-group-setting="onSettingsClick"
        @on-view-task-list="toggleShowTaskListInChat"
        @on-toggle-notification="toggleNotification"
      >
        <VigButton
          ghost
          color="gray"
          rounded="rounded-full"
          icon="DotsVertical"
        />
      </ActionExtend>
    </div>
  </div>

  <ModalFilesList
    v-if="showFilesCons"
    :filter-conditions="showFilesCons"
    @on-close="showFilesCons = null"
  />

  <ModalPinnedMessages
    v-if="isShowPinnedMsg"
    :organization-id="organizationId"
    :conversation-id="conversationId"
    :conversation="conversation"
    @on-message-reply="
      $emit('onMessageReply', $event);
      isShowPinnedMsg = false;
    "
    @on-message-view-file="$emit('onMessageViewFile', $event)"
    @on-message-task-click="$emit('onMessageTaskClick', $event)"
    @on-close="isShowPinnedMsg = false"
  />

  <GroupDetailModal
    v-if="groupShowMember"
    :group-id="groupShowMember?.id"
    :current-tab="groupShowMember?.tab"
    @cancel="groupShowMember = null"
  />

  <ModalNotificationSetting
    v-if="isShowNotificationSetting"
    :ref-id="contact?.id"
    scope="GROUP"
    :name="conversation?.name || ''"
    :function-default="'MESSAGING'"
    @change-notification="onChangeNotification"
    @cancel="isShowNotificationSetting = false"
  />
</template>
