<script setup lang="ts">
// *** IMPORTS ***
import {
  VideoRecordingModel,
  VideoRecordingStatus,
} from '@/domain/entities/video-recording/VideoRecordingModel';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { copyToClipBroard, formatDate } from '@/ui/hooks/commonFunction';
import { openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import FilesDownload from '@/ui/modules/ged/file-actions/files-download/FilesDownload.vue';
import VideoRecordingStatusComponent from '@/ui/modules/video-recorder/_common/recording-status/VideoRecordingStatus.vue';
import { generateRandomString } from '@/ui/helpers/utils';
import OrgUserRecordingsRepository from '@/application/repositories/org-user-recording/OrgUserRecordingRepository';
import FileStorageRepository from '@/application/repositories/FileStorageRepository';

// *** PROPS, EMITS ***
const props = defineProps<{
  recording: VideoRecordingModel;
}>();

const emit = defineEmits(['onCloseConfirm', 'onRestart', 'onStatusChange']);

// *** PRIVATE VARIABLES ***
let _unsubRecording;

// *** COMPOSABLES ***

// *** REFS ***
const isRestarting = ref<boolean>();
const isSavingAndClose = ref<boolean>();
const isSavingAndStarting = ref<boolean>();
const isProcessing = ref<boolean>();
const videoFileName = ref<string>(
  `Video recording ${formatDate(new Date(), 'YYYY-MM-DD HH:mm')}.mp4`
);
const errorMesssage = ref<string>();

// *** COMPUTED ***
const recordingDuration = computed(() => {
  if (
    !props.recording?.recordingStartDate ||
    !props.recording?.recordingEndDate
  ) {
    return 0;
  }

  return (
    new Date(props.recording?.recordingEndDate).getTime() -
    new Date(props.recording?.recordingStartDate).getTime()
  );
});

const recordingFiles = computed(() => {
  if (!props.recording?.mediaUrl) return [];

  const fileUrl = props.recording.mediaUrl;
  const name = fileUrl.split('/').pop();

  return [
    {
      id: generateRandomString(12),
      name: videoFileName.value || name,
      fileUrl,
    },
  ];
});

// *** WATCHES ***

// *** HOOKS ***
onMounted(() => {
  _subscribeRecordingStatus();
});

onBeforeUnmount(() => {
  if (_unsubRecording) _unsubRecording();
});

// *** HANDLER FUNCTIONS ***
const onCopyLink = () => {
  const copied = copyToClipBroard(props.recording?.mediaUrl);

  if (copied) {
    openNotification({
      body: translate('TASK_SHARING_URL_COPIED_MSG'),
      duration: 2000,
    });
  }
};

const onFileNameInputFocus = (event) => {
  event.target.setSelectionRange(0, videoFileName.value?.lastIndexOf('.'));
};

const onCloseClick = async () => {
  settingGlobalModal({
    type: 'confirm',
    title: 'Warning',
    content: 'Your recording video has not been save! Are you sure to leave?',
    cancelLabel: 'No',
    confirmLabel: 'Yes',
    confirmable: true,
    closeable: true,
  });

  const confirmed = await ask();

  if (confirmed) emit('onCloseConfirm');
};

const onRestartClick = async () => {
  settingGlobalModal({
    type: 'confirm',
    title: 'Warning',
    content:
      'Your recording video has not been save! Are you sure to restart recording?',
    cancelLabel: 'No',
    confirmLabel: 'Yes',
    confirmable: true,
    closeable: true,
  });

  const confirmed = await ask();

  if (confirmed) {
    isProcessing.value = true;
    isRestarting.value = true;

    // await _startNewRecording();
    emit('onRestart');

    isProcessing.value = false;
    isRestarting.value = false;
  }
};

const onSaveAndStartClick = async () => {
  if (!videoFileName.value?.trim()) {
    return (errorMesssage.value = 'You must enter name to save');
  }

  isProcessing.value = true;
  isSavingAndStarting.value = true;

  try {
    await _saveVideoRecording();
    // await _startNewRecording();

    emit('onRestart');
  } catch (e) {
    console.log(e);
  }

  isProcessing.value = false;
  isSavingAndStarting.value = false;
};

const onSaveAndCloseClick = async () => {
  if (!videoFileName.value?.trim()) {
    return (errorMesssage.value = 'You must enter name to save');
  }

  isProcessing.value = true;
  isSavingAndClose.value = true;

  try {
    await _saveVideoRecording();

    emit('onCloseConfirm');
  } catch (e) {
    console.log(e);
  }

  isProcessing.value = false;
  isSavingAndClose.value = false;
};

// *** PRIVATE FUNCTIONS ***
// const _startNewRecording = async () => {
//   try {
//     const region = await getNearestMediaRegion();
//
//     const res: any =
//       await VideoRecordingRepository.getInstance().createVideoRecording({
//         region,
//       });
//
//     if (res?.result) emit('onRestart', res?.result);
//   } catch (e) {
//     console.log(e);
//   }
// };

const _subscribeRecordingStatus = () => {
  console.log(
    '---_subscribeRecordingStatus',
    props.recording.organizationId,
    props.recording.userId,
    props.recording.id
  );
  if (
    !props.recording.organizationId ||
    !props.recording.userId ||
    !props.recording.id
  ) {
    return;
  }

  _unsubRecording = OrgUserRecordingsRepository.subscribeRecording(
    props.recording.organizationId,
    props.recording.userId,
    props.recording.id,
    (value) => {
      console.log('---subscribeRecording value', value);
      if (value?.status) emit('onStatusChange', value?.status);
    }
  );
};

const _saveVideoRecording = async () => {
  if (!props.recording) return;

  const recordingFile = {
    name: videoFileName.value,
    contentType: 'video/mp4',
    path: props.recording.mediaUrl,
    metadata: {
      videoRecordingId: props.recording.id,
    },
  };

  const res: any = await FileStorageRepository.getInstance().addVideoRecording(
    recordingFile
  );

  // Send message to original page
  if (res?.result) {
    window.opener.postMessage(
      'FiineVideoRecorder:RecordingSaved',
      window.location.origin
    );
  }

  return res?.result;
};

// *** EXPOSES ***
</script>

<template>
  <div class="h-full flex-center flex-col overflow-auto small-scrollbar p-5">
    <div class="mb-5">
      <div class="text-3xl text-center text-current-500 mb-5">
        Recording information
      </div>
      <div class="flex gap-3 mb-3 text-white">
        <div class="w-24">Start time</div>
        <div class="flex-1 overflow-hidden font-semibold">
          <SynLabelDateTime
            format="date_time"
            :datetime="recording?.recordingStartDate"
          />
        </div>
      </div>
      <div class="flex gap-3 mb-3 text-white">
        <div class="w-24">Duration</div>
        <div class="flex-1 overflow-hidden font-semibold">
          {{ $filters.duration(recordingDuration) }}
        </div>
      </div>
      <div class="flex gap-3 mb-3 text-white">
        <div class="w-24">Name</div>
        <div class="flex-1 overflow-hidden text-black min-w-[20rem]">
          <input
            v-model="videoFileName"
            type="text"
            class="vig-input"
            @focus="onFileNameInputFocus"
            @change="errorMesssage = ''"
          />
        </div>
      </div>
      <div class="flex gap-3 mb-3 text-white">
        <div class="w-24">Status</div>
        <div class="flex-1 overflow-hidden">
          <div
            class="
              px-2
              py-0.5
              text-sm
              bg-black bg-opacity-30
              rounded-lg
              inline-block
            "
          >
            <VideoRecordingStatusComponent :recording="recording" />
          </div>
        </div>
      </div>
      <div
        v-if="
          recording?.status === VideoRecordingStatus.MediaProcessed &&
          recording?.mediaUrl
        "
        class="flex gap-3 mb-3 text-white"
      >
        <div class="w-24">Video</div>
        <div class="flex-1 overflow-hidden flex items-start gap-2">
          <a
            :href="recording?.mediaUrl"
            target="_blank"
            class="
              flex-1
              overflow-hidden
              max-w-[25rem]
              text-sm text-current-500
              break-words
            "
            >{{ recording?.mediaUrl }}</a
          >
          <VigButton
            light
            color="gray"
            rounded="rounded-full"
            icon="Copy"
            @click="onCopyLink"
          />
          <FilesDownload :files="recordingFiles">
            <VigButton
              light
              color="gray"
              rounded="rounded-full"
              icon="Download"
            />
          </FilesDownload>
        </div>
      </div>
    </div>
    <div v-if="errorMesssage" class="text-center text-red-500 mb-2">
      {{ errorMesssage }}
    </div>
    <div class="flex-center flex-wrap gap-2">
      <VigButton
        color="current"
        rounded="rounded-full"
        :disabled="isProcessing"
        :processing="isSavingAndStarting"
        @click="onSaveAndStartClick"
        ><span>Save and start new recording</span>
      </VigButton>
      <VigButton
        color="green"
        rounded="rounded-full"
        :disabled="isProcessing"
        :processing="isSavingAndClose"
        @click="onSaveAndCloseClick"
        ><span>Save and close</span>
      </VigButton>
      <VigButton
        color="orange"
        rounded="rounded-full"
        :disabled="isProcessing"
        :processing="isRestarting"
        @click="onRestartClick"
        ><span>Restart recording</span>
      </VigButton>
      <VigButton
        color="red"
        rounded="rounded-full"
        :disabled="isProcessing"
        @click="onCloseClick"
        ><span>Close</span>
      </VigButton>
    </div>
  </div>
</template>

<style scoped></style>
