<script setup lang="ts">
// *** IMPORTS ***
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import {
  bindVideoElement,
  currentVideoTileId,
} from '@/ui/plugins/awss3/aws-chime';
import CameraMicroVoice from '@/ui/modules/video-recorder/video-recording/video-camera-tile/CameraMicroVoice.vue';

// *** PROPS, EMITS ***

// *** PRIVATE VARIABLES ***

let resizeObserver;

// *** COMPOSABLES ***

// *** REFS ***
const videoContainerRef = ref<HTMLDivElement>();
const videoRef = ref<HTMLVideoElement>();
const microRef = ref<HTMLDivElement>();

// *** COMPUTED ***

// *** WATCHES ***
watch(
  () => currentVideoTileId.value,
  () => _bindVideoElement()
);

// *** HOOKS ***
onMounted(() => {
  resizeObserver = new ResizeObserver(_processVideoSize);
  resizeObserver.observe(videoContainerRef.value);

  _bindVideoElement();
  _processVideoSize();
});

onBeforeUnmount(() => {
  resizeObserver?.unobserve(videoContainerRef.value);
});

// *** HANDLER FUNCTIONS ***

// *** PRIVATE FUNCTIONS ***
const _bindVideoElement = () => {
  if (!currentVideoTileId.value || !videoRef.value) return;

  bindVideoElement(currentVideoTileId.value, videoRef.value);
};

const _processVideoSize = () => {
  if (!videoContainerRef.value || !videoRef.value) return;

  const videoRatio = 1.75;

  const containerWidth = videoContainerRef.value?.offsetWidth;
  const containerHeight = videoContainerRef.value?.offsetHeight;

  let videoWidth = containerWidth;
  let videoHeight = videoWidth / videoRatio;

  if (videoHeight > containerHeight) {
    videoHeight = containerHeight;
    videoWidth = videoHeight * videoRatio;
  }

  videoRef.value.style.width = videoWidth + 'px';
  videoRef.value.style.height = videoHeight + 'px';
};

// *** EXPOSES ***
</script>

<template>
  <div ref="videoContainerRef" class="w-full h-full">
    <div class="relative rounded-2xl overflow-hidden bg-black bg-opacity-30">
      <video ref="videoRef" class="w-full h-full object-cover"></video>

      <div ref="microRef" class="absolute right-3 bottom-3">
        <CameraMicroVoice />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
