<script setup lang="ts">
import dayjs from 'dayjs';
import SynButton from '@/ui/common/atoms/SynButton/SynButton.vue';
import { computed, onMounted, ref, watch } from 'vue';
import principleStore from '@/store/principle';

const emit = defineEmits(['loading']);

const props = defineProps<{
  userId: any;
  userDetail: any;
  userTab: any;
}>();

const _principleStore = principleStore();

const dateConfig = ref({ type: 'string', mask: 'YYYY-MM-DD' });
const isLoading = ref(false);
const statisticUserPrinciple = computed(
  () => _principleStore.statisticUserPrinciple
);

const userDetailInfo = computed(() => props.userDetail);

const filterObj = ref({
  fromDate: props.userDetail?.filterFromDate
    ? props.userDetail?.filterFromDate
    : (null as any),
  toDate: props.userDetail?.filterToDate
    ? props.userDetail?.filterToDate
    : (null as any),
});

const selectDateFilterList = [
  // {
  //   text: 'LABEL_LAST_7_DAYS',
  //   key: 1,
  // },
  {
    text: 'LABEL_LAST_15_DAYS',
    key: 2,
  },
  {
    text: 'LABEL_LAST_1_MONTH',
    key: 3,
  },
  {
    text: 'LABEL_LAST_2_MONTHS',
    key: 4,
  },
  {
    text: 'LABEL_LAST_3_MONTHS',
    key: 5,
  },
  {
    text: 'LABEL_CUSTOM_DATE',
    key: 6,
  },
];

const selectDateDefault = ref(
  props.userDetail?.filterDateOption ? props.userDetail?.filterDateOption : 2
);

const selectDateFilter = (value) => {
  selectDateDefault.value = value;
  switch (selectDateDefault.value) {
    // case 1:
    // getWorkInfoByDateRange(6, 'days'); // last 7 days
    // break;
    case 2:
      filterObj.value.fromDate = calculateDate(14, 'days');
      filterObj.value.toDate = dayjs.utc().startOf('day').format('YYYY-MM-DD');
      getPrincipleDetail();
      break;
    case 3:
      filterObj.value.fromDate = calculateDate(1, 'month');
      filterObj.value.toDate = dayjs.utc().startOf('day').format('YYYY-MM-DD');
      getPrincipleDetail();
      break;
    case 4:
      filterObj.value.fromDate = calculateDate(2, 'months');
      filterObj.value.toDate = dayjs.utc().startOf('day').format('YYYY-MM-DD');
      getPrincipleDetail();
      break;
    case 5:
      filterObj.value.fromDate = calculateDate(3, 'months');
      filterObj.value.toDate = dayjs.utc().startOf('day').format('YYYY-MM-DD');
      getPrincipleDetail();
      break;
    case 6:
      filterObj.value.fromDate = undefined;
      filterObj.value.toDate = undefined;
      break;
    default:
      break;
  }
};

const calculateDate = (value, type) => {
  return dayjs.utc().startOf('day').subtract(value, type).format('YYYY-MM-DD');
};

const getPrincipleDetail = () => {
  isLoading.value = true;
  emit('loading', isLoading.value);

  let promise = [
    _principleStore.getPrincipleDetail(
      props.userId,
      filterObj.value.fromDate,
      filterObj.value.toDate
    ),
    _principleStore.getUserPrincipleStatisticByRange(
      props.userId,
      filterObj.value.fromDate,
      filterObj.value.toDate
    ),
  ];
  Promise.all(promise)
    .then(() => {
      isLoading.value = false;
      userDetailInfo.value.filterFromDate = filterObj.value?.fromDate;
      userDetailInfo.value.filterToDate = filterObj.value?.toDate;
      userDetailInfo.value.filterDateOption = selectDateDefault.value;
      userDetailInfo.value.statisticUserPrinciple =
        statisticUserPrinciple.value;

      emit('loading', isLoading.value);
    })
    .catch((err) => {
      console.log(err);
    });
};

const onResetFromDate = () => {
  filterObj.value.fromDate = undefined;
};

const onResetToDate = () => {
  filterObj.value.toDate = undefined;
};

const isDateValid = () => {
  return (
    dayjs(filterObj.value.toDate).startOf('day').valueOf() -
      dayjs(filterObj.value.fromDate).startOf('day').valueOf() >=
    0
  );
};

const onFilterByRangeDate = async () => {
  if (!isDateValid()) return;
  filterObj.value.fromDate = dayjs(filterObj.value.fromDate)
    .startOf('day')
    .format('YYYY-MM-DD');
  filterObj.value.toDate = dayjs(filterObj.value.toDate)
    .startOf('day')
    .format('YYYY-MM-DD');
  getPrincipleDetail();
};

const formatDecimal = (duration) => {
  const hours = Math.floor(duration);
  const minutes = Math.floor((duration % 1) * 60);
  const durationObj = dayjs.duration({ hours, minutes });
  return hours > 0
    ? durationObj.format('H[h] m[m]')
    : durationObj.format('m[m]');
};

onMounted(() => {
  initComponent();
});

const initComponent = () => {
  if (
    userDetailInfo.value?.filterDateOption &&
    userDetailInfo.value?.filterFromDate &&
    userDetailInfo.value?.filterToDate
  ) {
    filterObj.value.fromDate = dayjs(filterObj.value.fromDate)
      .startOf('day')
      .format('YYYY-MM-DD');
    filterObj.value.toDate = dayjs(filterObj.value.toDate)
      .startOf('day')
      .format('YYYY-MM-DD');
    // getPrincipleDetail();
  } else {
    selectDateFilter(selectDateDefault.value);
  }
};

watch(
  () => props.userId,
  () => {
    filterObj.value.fromDate = dayjs(props.userDetail.filterFromDate)
      .startOf('day')
      .format('YYYY-MM-DD');
    filterObj.value.toDate = dayjs(props.userDetail.filterToDate)
      .startOf('day')
      .format('YYYY-MM-DD');
    selectDateDefault.value = props.userDetail.filterDateOption;
  }
);
</script>

<template>
  <div class="flex flex-col pt-1 space-y-2">
    <div
      class="
        z-20
        sticky
        flex
        items-center
        pb-2.5
        border-b border-gray-100
        space-x-2
      "
    >
      <SynSelect
        :model-value="selectDateDefault"
        width-class="w-42 text-sm"
        custom-class="py-2"
        is-translate
        :options="selectDateFilterList"
        key-field="key"
        text-field="text"
        @change="selectDateFilter"
      />
      <div class="flex items-center space-x-2">
        <v-date-picker
          v-model="filterObj.fromDate"
          :locale="$locale"
          color="current"
          :model-config="dateConfig"
          :attributes="[
            {
              key: 'today',
              dot: {
                color:
                  dayjs(new Date()).format('MM-DD-YYYY') ===
                  dayjs(filterObj.fromDate).format('MM-DD-YYYY')
                    ? 'yellow'
                    : 'current',
                class:
                  dayjs(new Date()).format('MM-DD-YYYY') ===
                  dayjs(filterObj.fromDate).format('MM-DD-YYYY')
                    ? 'mb-1 z-70'
                    : '',
              },
              dates: new Date(),
            },
          ]"
          :popover="{ visibility: 'click' }"
          :max-date="new Date()"
          :first-day-of-week="2"
        >
          <template #default="{ inputValue, inputEvents }">
            <div
              class="relative text-sm text-gray-500"
              :class="selectDateDefault !== 6 ? 'cursor-not-allowed' : ''"
              style="width: 11rem"
            >
              <input
                ref="inputFromDate"
                type="text"
                class="vig-input text-sm pl-12"
                :class="
                  selectDateDefault !== 6
                    ? 'bg-gray-100 cursor-not-allowed'
                    : 'bg-white'
                "
                :value="inputValue"
                :disabled="selectDateDefault !== 6"
                v-on="inputEvents"
              />
              <div class="absolute left-2" style="top: 9px">
                <span class="italic text-sm"
                  >{{ $t('CHECKIN_LABEL_FROM') }}:</span
                >
              </div>
              <button
                v-if="filterObj.fromDate && selectDateDefault == 6"
                class="
                  absolute
                  top-1/2
                  right-1
                  -mt-3
                  w-6
                  h-6
                  flex-center
                  rounded-full
                  hover:bg-gray-100
                "
                @click="onResetFromDate"
              >
                <SynIcon name="Close" />
              </button>
            </div>
          </template>
        </v-date-picker>
        <v-date-picker
          v-model="filterObj.toDate"
          :locale="$locale"
          color="current"
          :model-config="dateConfig"
          :attributes="[
            {
              key: 'today',
              dot: {
                color:
                  dayjs(new Date()).format('MM-DD-YYYY') ===
                  dayjs(filterObj.toDate).format('MM-DD-YYYY')
                    ? 'yellow'
                    : 'current',
                class:
                  dayjs(new Date()).format('MM-DD-YYYY') ===
                  dayjs(filterObj.toDate).format('MM-DD-YYYY')
                    ? 'mb-1 z-70'
                    : '',
              },
              dates: new Date(),
            },
          ]"
          :popover="{ visibility: 'click' }"
          :max-date="new Date()"
          :first-day-of-week="2"
        >
          <template #default="{ inputValue, inputEvents }">
            <div
              class="relative text-sm text-gray-500"
              :class="selectDateDefault !== 6 ? 'cursor-not-allowed' : ''"
              style="width: 11rem"
            >
              <input
                type="text"
                class="vig-input text-sm pl-12"
                :class="
                  selectDateDefault !== 6
                    ? 'bg-gray-100 cursor-not-allowed'
                    : 'bg-white'
                "
                :value="inputValue"
                :disabled="selectDateDefault !== 6"
                v-on="inputEvents"
              />
              <div class="absolute left-2" style="top: 9px">
                <span class="italic text-sm"
                  >{{ $t('CHECKIN_LABEL_TO') }}:</span
                >
              </div>
              <button
                v-if="filterObj.toDate && selectDateDefault == 6"
                class="
                  absolute
                  top-1/2
                  right-1
                  -mt-3
                  w-6
                  h-6
                  flex-center
                  rounded-full
                  hover:bg-gray-100
                "
                @click="onResetToDate"
              >
                <SynIcon name="Close" />
              </button>
            </div>
          </template>
        </v-date-picker>
        <SynButton
          v-if="selectDateDefault == 6"
          :disabled="!filterObj.fromDate || !filterObj.toDate || !isDateValid()"
          class="text-sm"
          @click="onFilterByRangeDate()"
        >
          OK
        </SynButton>
      </div>
    </div>
    <div class="flex flex-col space-y-2">
      <div class="italic">
        <span class="text-current-500 text-sm font-medium">{{
          $t('LABEL_STATISTICS_AVERAGE')
        }}</span>
      </div>
      <div v-if="isLoading" class="flex items-center space-x-2">
        <div
          v-for="(i, index) in 6"
          :key="index"
          class="w-32 h-7 bg-gray-200 animate-pulse rounded"
        ></div>
      </div>
      <div v-else class="flex space-x-2 text-xs">
        <div
          class="px-2 py-1 flex items-center space-x-2 bg-current-50 rounded"
        >
          <span>{{ $t('DASHBOARD_LABEL_DESCRIPTION_PAUSE_TIME_SHORT') }}</span>
          <span
            class="
              flex-center
              py-1
              px-1.5
              rounded
              bg-current-400
              font-medium
              text-white
            "
            >{{
              formatDecimal(
                userDetailInfo?.statisticUserPrinciple?.pauseDuration
              ) || 0
            }}</span
          >
        </div>
        <div
          class="px-2 py-1 flex items-center space-x-2 bg-current-50 rounded"
        >
          <span>{{ $t('DASHBOARD_TABLE_LABEL_TIME_WD') }}</span>
          <span
            class="
              flex-center
              py-1
              px-1.5
              rounded
              bg-current-400
              font-medium
              text-white
            "
            >{{
              formatDecimal(
                userDetailInfo?.statisticUserPrinciple?.avgWorkingTime
              ) || 0
            }}</span
          >
        </div>
        <div
          class="px-2 py-1 flex items-center space-x-2 bg-current-50 rounded"
        >
          <span>{{
            $t('DASHBOARD_TABLE_LABEL_DASHBOARD_TABLE_LABEL_ON_DAY')
          }}</span>
          <span
            class="
              flex-center
              py-1
              px-1.5
              rounded
              bg-current-400
              font-medium
              text-white
            "
            >{{ userDetailInfo?.statisticUserPrinciple?.todoTasks || 0 }}</span
          >
        </div>
        <div
          class="px-2 py-1 flex items-center space-x-2 bg-current-50 rounded"
        >
          <span>{{ $t('LABEL_STOP_WORKING_TASK_FINISH') }}</span>
          <span
            class="
              flex-center
              py-1
              px-1.5
              rounded
              bg-current-400
              font-medium
              text-white
            "
            >{{
              userDetailInfo?.statisticUserPrinciple?.toFinishTasks || 0
            }}</span
          >
        </div>
        <div
          class="px-2 py-1 flex items-center space-x-2 bg-current-50 rounded"
        >
          <span>{{ $t('LABEL_TOTAL_DAYOFF') }}</span>
          <span
            class="
              flex-center
              py-1
              px-1.5
              rounded
              bg-current-400
              font-medium
              text-white
            "
            >{{
              userDetailInfo?.statisticUserPrinciple?.totalDayOffs || 0
            }}</span
          >
        </div>
        <div
          class="px-2 py-1 flex items-center space-x-2 bg-current-50 rounded"
        >
          <span>{{ $t('LABEL_TOTAL_REQUEST_DAYOFF') }}</span>
          <span
            class="
              flex-center
              py-1
              px-1.5
              rounded
              bg-current-400
              font-medium
              text-white
            "
            >{{
              userDetailInfo?.statisticUserPrinciple?.totalDayOffRequested || 0
            }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
