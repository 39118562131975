<script setup lang="ts">
import { ref, computed, inject } from 'vue';
import { useRoute } from 'vue-router';
import { EScreenPermission } from '@/application/types/block-function.types';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import permissionStore from '@/store/permission';

const permission: any = inject('permission');
const route = useRoute();
const settings = [
  {
    path: 'organization',
    name: 'OrganizationSetting',
    keyName: 'COMMON_LABEL_ORGANIZATION',
    defaultName: 'Organization Infomation',
    screenKey: ALL_SCREENS.COMMON.ADMIN.SETTING.ORGANIZATION,
  },
  {
    path: 'personal',
    name: 'PersonalSetting',
    keyName: 'COMMON_LABEL_PERSONAL_SETTINGS',
    defaultName: 'Personal settings',
  },
  {
    path: 'start-working',
    name: 'StartWorkingSetting',
    keyName: 'SETTING_SW_CONDITION_TITLE',
    defaultName: 'Start working settings',
    screenKey: ALL_SCREENS.COMMON.ADMIN.SETTING.START_WORKING,
  },
  {
    path: 'plans',
    name: 'PlansSetting',
    keyName: 'COMMOM_LABEL_PLANS_SETTING',
    defaultName: 'Plans and Billing',
  },
];

const permissionScreen: Function | undefined = inject('permissionScreen');
const organizationModel = computed<any>(() => {
  return permissionStore().organizationModel;
});
const routerList = computed<any>(() => {
  if (organizationModel.value == 'PERSONAL')
    return [
      {
        path: 'personal',
        name: 'PersonalSetting',
        keyName: 'COMMON_LABEL_PERSONAL_SETTINGS',
        defaultName: 'Personal settings',
      },
    ];

  return settings
    ?.filter((o) => permission(o.name))
    .filter((o) => {
      if (!o.screenKey) return true;
      if (!permissionScreen) return false;
      return (
        permissionScreen(o.screenKey) !== EScreenPermission.HIDDEN &&
        permissionScreen(o.screenKey) !== EScreenPermission.NOT_SET
      );
    });
});

const currentRoute = computed(() => route.name);

const isActiveRouter = (name, path) => {
  return currentRoute.value == name || route.path?.includes(path);
};

const activeClass = ref('text-current-500 bg-current-50 bg-opacity-50');
const inactiveClass = ref(
  'transform hover:bg-gray-50 cursor-pointer hover:text-current'
);
// const getMenuLinkUrl = (routePath) => {
//   return `/settings/${routePath}`;
// };
</script>

<template>
  <div class="flex-center h-full w-full m-auto">
    <div class="w-full h-full flex flex-row divide-x rounded-lg relative">
      <div
        class="
          w-72
          bg-white
          max-h-full
          flex flex-col
          space-y-1
          overflow-y-auto overflow-x-hidden
          neumorphism-shadow
          p-1
        "
      >
        <RouterLink
          v-for="(item, index) in routerList"
          :key="index"
          class="
            font-semibold
            transition
            duration-200
            rounded-lg
            h-16
            text-sm
            flex
            items-center
            lg:h-auto
            w-full
            py-3
            lg:px-6
          "
          :class="[
            isActiveRouter(item.name, item.path) ? activeClass : inactiveClass,
          ]"
          :to="{ name: item?.name }"
        >
          {{ $t(item.keyName) || item.defaultName }}
        </RouterLink>
      </div>

      <div
        class="
          flex flex-col
          rounded-md
          max-h-full
          w-full
          shadow
          bg-white
          m-3
          overflow-y-auto
          small-scrollbar
        "
      >
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
