import {
    ECustomFilterSourceType,
    TaskApprovalStatus,
} from './../../types/task/task.types';
import {
    ICustomTaskColumn,
    ICustomTaskFilter,
    ITaskReccurring,
    ITaskReminderInput,
} from './../../interfaces/tasks/task-interfaces';
import TaskRepository from '@/application/repositories/TaskRepository';
import { removeAwsFile } from '@/ui/modules/task/helper/task-upload-helper';
import { TaskMedia } from '@/domain/entities/task/TaskResponseClass';
import { bucketTictop } from '@/ui/plugins/awss3/AwsS3Config';
import {
    convertUrgencyToImportant,
    convertUrgencyToPriority,
} from '@/ui/hooks/taskHook';
import DomainRepository from '@/application/repositories/DomainRepository';
import { FilterByDomain, FilterQuery } from '@/domain/entities/task/FilterTask';
import AttachmentClass from '../attachment/AttachmentClass';
import { ModuleSourceFileEnum } from '@/ui/plugins/awss3/AwsS3Type';
import NoteClass from '../attachment/NoteClass';
import { BaseResponse } from '@/base/repositories/BaseRepository';
import { ITaskRepeat } from '@/application/interfaces/tasks/task-interfaces';
import {
    ETaskListModule,
    IDeleteTaskUserRolePayload,
    ITaskTodoList,
    IUpdateCollaboratorPayload,
    TaskResponsible,
    TaskUserRole,
    UpdateMultipleTaskPayload,
} from '@/application/types/task/task.types';
import { ITaskWorkflowAttachPayload } from '@/domain/entities/task/TaskWorkflowEntity';
// import WorkflowRepository from '@/application/repositories/WorkflowRepository';
// import { IAddTaskWorkflow } from '@/application/types/workflow/workflow-repository.types';

export default class TaskServiceSingleton {
    private static instance: TaskServiceSingleton;
    _taskRepository: TaskRepository;
    _domainRepository: DomainRepository;

    constructor() {
        this._taskRepository = TaskRepository.getInstance();
        this._domainRepository = DomainRepository.getInstance();
    }

    public static getInstance(): TaskServiceSingleton {
        if (!TaskServiceSingleton.instance) {
            // Get from local storage
            TaskServiceSingleton.instance = new TaskServiceSingleton();
        }

        return TaskServiceSingleton.instance;
    }

    create(payloadForm: any): Promise<BaseResponse> {
        // console.log('🚀 Tictop ~ payloadForm:', payloadForm);
        // if (payloadForm.taskWorkflow && payloadForm.taskWorkflow?.workflowId) {
        //     const addTaskWorkflowPayload: IAddTaskWorkflow = {
        //         ...payloadForm,
        //         taskWorkflow: payloadForm.taskWorkflow,
        //     };
        //     return WorkflowRepository.getInstance().addTaskWorkflow(
        //         addTaskWorkflowPayload
        //     );
        // }

        return this._taskRepository.createTask(payloadForm);
    }

    async finish(task: any): Promise<any> {
        return await this._taskRepository.finishTask(task);
    }

    async clone(task: any): Promise<any> {
        const result = await this._taskRepository.clone({
            taskId: task.id,
            groupId: task.groupId,
            assigneeId: task.assigneeId,
        });
        return result['result'];
    }

    async changeState(
        taskId: number,
        state: number,
        comment: {
            isCreateComment: boolean;
            content: string;
            attachments: {
                files: any[];
                notes: any[];
            };
            mentionUserIds: any[];
            mentionGroup: boolean;
        }
    ): Promise<any> {
        let attachmentObj: {
            uploadedFiles: any[];
            uploadedNotes: any[];
        } = {
            uploadedFiles: [],
            uploadedNotes: [],
        };
        if (
            comment?.attachments?.files?.length > 0 ||
            comment?.attachments?.notes?.length > 0
        )
            attachmentObj = await uploadAttachments(comment?.attachments);

        return this._taskRepository.changeState({
            taskId,
            taskLife: state,

            isCreateComment:
                !!comment?.content ||
                attachmentObj?.uploadedFiles?.length > 0 ||
                attachmentObj?.uploadedNotes?.length > 0,
            comment: comment?.content,
            attachments: {
                files: attachmentObj?.uploadedFiles || [],
                notes: attachmentObj?.uploadedNotes || [],
            },
        });
    }

    changeOrgTaskState(orgId: number, updTask: any): Promise<any> {
        return this._taskRepository.changeOrgTaskState(orgId, updTask);
    }

    async setPlanned(data: {
        taskId: number;
        planType: number;
        isUpdateScheduleTime?: boolean;
        scheduleTime?: string;
        scheduleByHour?: boolean;
        scheduleTimezone?: string;
    }): Promise<any> {
        return await this._taskRepository.setPlannedTask(data);
    }

    setOrgTaskPlanned(orgId: number, updTask: any): Promise<any> {
        return this._taskRepository.setOrgTaskPlanned(orgId, updTask);
    }

    async setAssignee(
        taskId: number,
        assigneeId: number | null,
        assignToMe: boolean
    ): Promise<any> {
        return await this._taskRepository.setAssignee({
            taskId,
            assigneeId,
            assignToMe,
        });
    }

    setOrgTaskAssignee(orgId, data): Promise<any> {
        return this._taskRepository.setOrgTaskAssignee(orgId, data);
    }

    delete(
        taskId: number,
        isDeleteRepeat: boolean | undefined,
        deleteSubTaskIds: number[] | undefined
    ): Promise<any> {
        return this._taskRepository.deleteTask({
            taskId,
            isDeleteRepeat,
            deleteSubTaskIds,
        });
    }

    getTaskWithFilter(filter: any): Promise<any> {
        const filterData = new FilterQuery(filter);
        if (
            !filterData.listOwnerId &&
            !filterData.groupIds &&
            !filterData.assignorId &&
            !filterData.approverId &&
            !filterData.domainId &&
            !filterData.projectId &&
            !filterData.subProjectId
        ) {
            return Promise.resolve([]);
        }

        return this._taskRepository.getTaskWithFilter(filterData);
    }

    getSharedTasksWithFilters(filter: any): Promise<any> {
        const filterData = new FilterQuery(filter);

        return this._taskRepository.getSharedTasksWithFilters(filterData);
    }

    getPinnedTasks(filter: any): Promise<any> {
        const filterData = new FilterQuery(filter);
        if (
            !filterData.listOwnerId &&
            !filterData.groupIds &&
            !filterData.assignorId &&
            !filterData.domainId
        ) {
            return Promise.resolve([]);
        }

        return this._taskRepository.getPinnedTasks(filterData);
    }

    getCollaboratorTask(filter: any) {
        const filterData = new FilterQuery(filter);
        if (
            !filterData.listOwnerId &&
            !filterData.groupIds &&
            !filterData.assignorId &&
            !filterData.domainId
        ) {
            return Promise.resolve([]);
        }
        return this._taskRepository.getCollaboratorTask(filterData);
    }

    getDoTodayTask(filter: any): Promise<any> {
        const filterData = new FilterQuery(filter);
        if (
            !filterData.listOwnerId &&
            !filterData.groupIds &&
            !filterData.assignorId &&
            !filterData.domainId
        ) {
            return Promise.resolve([]);
        }

        return this._taskRepository.getDoTodayTask(filterData);
    }

    getOverdueTask(filter: any): Promise<any> {
        const filterData = new FilterQuery(filter);
        if (
            !filterData.listOwnerId &&
            !filterData.groupIds &&
            !filterData.assignorId &&
            !filterData.domainId
        ) {
            return Promise.resolve([]);
        }

        return this._taskRepository.getOverdueTask(filterData);
    }

    getFinishedTask(filter: any): Promise<any> {
        const filterData = new FilterQuery(filter);
        if (
            !filterData.listOwnerId &&
            !filterData.groupIds &&
            !filterData.assignorId &&
            !filterData.domainId
        ) {
            return Promise.resolve([]);
        }

        return this._taskRepository.getFinishedTask(filterData);
    }

    getOtherTask(filter: any): Promise<any> {
        const filterData = new FilterQuery(filter);
        if (
            !filterData.listOwnerId &&
            !filterData.groupIds &&
            !filterData.assignorId &&
            !filterData.domainId
        ) {
            return Promise.resolve([]);
        }

        return this._taskRepository.getOtherTask(filterData);
    }

    getTasksByDomain(filter: FilterByDomain): Promise<any> {
        return this._taskRepository.getTasksByDomain(filter);
    }

    async getTaskMedia(taskId: number): Promise<TaskMedia> {
        const res = await this._taskRepository.getAttachmentNoteByTaskId(
            taskId
        );
        return res['result'];
    }

    async getOrgTaskMedia(orgId: number, taskId: number): Promise<TaskMedia> {
        const res = await this._taskRepository.getOrgAttachmentNoteByTaskId(
            orgId,
            taskId
        );
        return res['result'];
    }

    async getDetailByCode(taskCode: string): Promise<any> {
        const res = await this._taskRepository.getDetailTaskByCode(taskCode);
        return res['result'];
    }

    async getOrgTaskDetailByCode(
        orgId: number,
        taskCode: string
    ): Promise<any> {
        const res = await this._taskRepository.getOrgTaskDetailByCode(
            orgId,
            taskCode
        );
        return res?.result;
    }

    async getDetailByID(taskId: number): Promise<any> {
        const res = await this._taskRepository.getDetailTaskById(taskId);
        return res['result'];
    }

    async getOrgTaskDetail(orgId: number, taskId: number): Promise<any> {
        const res = await this._taskRepository.getOrgTaskDetail(orgId, taskId);
        return res?.result;
    }

    getTaskCodeShort(taskCode: string): string {
        return taskCode.substring(
            taskCode.indexOf('-') > -1 ? taskCode.indexOf('-') + 1 : 0,
            taskCode.length
        );
    }

    getDetailTaskIncognitoById(taskId: number): Promise<any> {
        return this._taskRepository.getDetailTaskIncognitoById(taskId);
    }

    reopenTask(taskId: number): Promise<any> {
        return this._taskRepository.reopen(taskId);
    }

    renameTask(data: { id: any; name: string }): Promise<any> {
        return this._taskRepository.renameTask(data);
    }

    renameOrgTask(orgId, data: any): Promise<any> {
        return this._taskRepository.renameOrgTask(orgId, data);
    }

    removeAwsFile(bucket, filePath): any {
        let key = filePath;
        if (filePath.includes(bucket)) {
            key = filePath.split(bucket + '/').pop();
            key = key
                ?.replace('xlarge', 'original')
                ?.replace('small', 'original')
                ?.replace('medium', 'original')
                ?.replace('mini', 'original');
        }
        removeAwsFile(bucketTictop.AttachmentPhoto, key);
    }

    getCurrentTaskCycle(organizationId?): Promise<any> {
        return this._taskRepository.getCurrentTaskCycle(organizationId);
    }

    changeScheduleTime(data: IUpdateScheduleTime): Promise<any> {
        return this._taskRepository.changeScheduleTime(data);
    }

    changeStartEndTime(data: IUpdateStartTime): Promise<any> {
        return this._taskRepository.changeStartEndTime(data);
    }

    changeOrgTaskScheduleTime(orgId, data: any): Promise<any> {
        return this._taskRepository.changeOrgTaskScheduleTime(orgId, data);
    }

    async updateFiles(taskId, files) {
        //1. upload to aws
        // const uploadFiles = await uploadTaskAttachments(files);
        const promiseAll: Promise<any>[] = [];
        Array.from(files).forEach((file) => {
            promiseAll.push(
                new AttachmentClass(ModuleSourceFileEnum.task, file).upload()
            );
        });
        const uploadFiles = await Promise.all(promiseAll);
        //2. update db
        return this._taskRepository.updateFiles({
            id: taskId,
            files: uploadFiles,
        });
    }

    removeFile(taskId, file) {
        removeAwsFile(bucketTictop.AttachmentPhoto, file?.path);

        return this._taskRepository.updateFiles({
            id: taskId,
            files: [
                {
                    ...file,
                    isDeleted: true,
                },
            ],
        });
    }

    async updateNote(taskId, noteData) {
        // 1. update image, audios
        // const payloadNote = await uploadTaskSingleNote({
        //   id,
        //   fileUrl: base64,
        //   type: 'image/jpeg',
        //   isBase64: true,
        //   isDeleted: false,
        // });

        const payloadNote = await new NoteClass(ModuleSourceFileEnum.task, {
            base64: noteData?.base64,
            drawData: noteData?.drawData,
            creationTime: noteData?.creationTime,
        }).upload();
        // TODO
        // uploadMultipleFiles(audios);
        // 2. save note data to database
        return this._taskRepository.updateNote({
            id: taskId,
            note: {
                ...payloadNote,
                id: noteData.id,
            },
        });
    }

    removeNote(taskId, noteId) {
        return this._taskRepository.updateNote({
            id: taskId,
            note: {
                id: noteId,
                isDeleted: true,
            },
        });
    }

    changeDescription(data: any): Promise<any> {
        return this._taskRepository.changeDescription(data);
    }

    changeOrgTaskDescription(orgId, data: any): Promise<any> {
        return this._taskRepository.changeOrgTaskDescription(orgId, data);
    }

    changeAssigneeTask(data: TaskResponsible): Promise<any> {
        return this._taskRepository.changeAssigneeTask(data);
    }

    changeUrgency(taskId: number, urgency: number): Promise<any> {
        const payload = {
            id: taskId,
            priority: convertUrgencyToPriority(urgency),
            important: convertUrgencyToImportant(urgency),
        };
        return this._taskRepository.changeUrgency(payload);
    }

    changeOrgTaskUrgency(orgId, data): Promise<any> {
        return this._taskRepository.changeOrgTaskUrgency(orgId, data);
    }

    changeDomain(payload): Promise<any> {
        return this._taskRepository.changeDomain(payload);
    }

    changeOrgTaskDomain(orgId, payload): Promise<any> {
        return this._taskRepository.changeOrgTaskDomain(orgId, payload);
    }

    changePrivate(payload): Promise<any> {
        return this._taskRepository.changePrivate(payload);
    }

    changeOrgTaskPrivate(orgId, payload): Promise<any> {
        return this._taskRepository.changeOrgTaskPrivate(orgId, payload);
    }

    async getTaskListOverview(): Promise<any> {
        const res = await this._taskRepository.getTaskListOverview();
        return res['result'];
    }

    async getTaskListPlannedToTreat(): Promise<any> {
        const res = await this._taskRepository.getTaskListPlannedToTreat();
        return res['result'];
    }

    async getTaskListPlannedToFinish(): Promise<any> {
        const res = await this._taskRepository.getTaskListPlannedToFinish();
        return res['result'];
    }

    updateTaskListPlannedToFinish(data) {
        return this._taskRepository.updateTaskListPlannedToFinish(data);
    }

    saveTaskListPlannedToday(data) {
        return this._taskRepository.saveTaskListPlannedToday(data);
    }

    getStopWorkingDayTaskList() {
        return this._taskRepository.getStopWorkingDayTaskList();
    }

    getTaskOverviewStatistic() {
        return this._taskRepository.getTaskOverviewStatistic();
    }

    stopWorkingDay(data) {
        return this._taskRepository.stopWorkingDay(data);
    }

    updateTaskConfig(config) {
        return this._taskRepository.updateTaskConfig(config);
    }

    async getDomainScopeListByUserId(userId): Promise<any> {
        const res = await this._domainRepository.getDomainScopeListByUserId(
            userId
        );
        return res['result'];
    }

    getDrawDataById(noteIdString) {
        return this._taskRepository.getDrawDataById(noteIdString);
    }

    getDataConfig(): Promise<any> {
        return this._taskRepository.getDataConfig();
    }

    getUserTaskSchedule(assigneeId) {
        return this._taskRepository.getUserTaskSchedule(assigneeId);
    }

    // Media
    uploadAws(files) {
        return this._taskRepository.uploadAws(files);
    }

    //dashboard
    getFinishedTasks(departmentId?: number) {
        return this._taskRepository.getFinishedTasks(departmentId);
    }

    getFinishedTaskRankingByMember(departmentId?: number) {
        return this._taskRepository.getFinishedTaskRankingByMember(
            departmentId
        );
    }

    getFinishedTaskRankingByDomain(departmentId?: number) {
        return this._taskRepository.getFinishedTaskRankingByDomain(
            departmentId
        );
    }

    getFinishedTaskOverview(departmentId?: number) {
        return this._taskRepository.getFinishedTaskOverview(departmentId);
    }

    getCreatedTasks(departmentId?: number) {
        return this._taskRepository.getCreatedTasks(departmentId);
    }

    // getFinishedTaskRankingByMember() {
    //   return this._taskRepository.getFinishedTaskRankingByMember();
    // }

    getCreatedTaskByDomain(departmentId?: number) {
        return this._taskRepository.getCreatedTaskByDomain(departmentId);
    }

    getCreatedTaskOverview(departmentId?: number) {
        return this._taskRepository.getCreatedTaskOverview(departmentId);
    }

    // ACTIVITY
    getTaskActivityById(taskId, orderBy) {
        return this._taskRepository.getTaskActivityById(taskId, orderBy);
    }

    getTaskGroupActivityById(orgId, taskId, orderBy) {
        return this._taskRepository.getTaskGroupActivityById(
            orgId,
            taskId,
            orderBy
        );
    }

    // COMMENT
    getAllComments(orgId, taskId) {
        return this._taskRepository.getAllComments(orgId, taskId);
    }

    async createComment(
        orgId,
        taskId,
        content,
        attachmentFiles,
        notes,
        mentionUserIds,
        mentionGroup,
        replyTo
    ) {
        console.log('🚀 Tictop ~ attachmentFiles:', attachmentFiles);
        //1. upload to aws
        // const uploadFiles = await uploadTaskAttachments(files);
        const promiseAll: Promise<any>[] = [];
        const promiseNoteAll: Promise<any>[] = [];
        Array.from(attachmentFiles).forEach((file) => {
            promiseAll.push(
                new AttachmentClass(ModuleSourceFileEnum.task, file).upload()
            );
        });
        Array.from(
            notes?.map((note) => {
                return { ...note, id: '00000000-0000-0000-0000-000000000000' };
            })
        ).forEach((file) => {
            // console.log('🚀 Hyrin ~ TaskServiceSingleton ~ ).forEach ~ file', file);
            promiseNoteAll.push(
                new NoteClass(ModuleSourceFileEnum.task, file).upload()
            );
        });
        const uploadFiles = await Promise.all(promiseAll);
        const uploadNotes = await Promise.all(promiseNoteAll);

        //2. update db
        return this._taskRepository.createComment(orgId, taskId, {
            taskId,
            content: content,
            attachments: {
                files: uploadFiles || [],
                notes: uploadNotes || [],
            },
            mentionUserIds: mentionUserIds || [],
            mentionGroup: mentionGroup,
            replyTo,
        });
    }

    updateComment(commentId, content, mentionUserIds, mentionGroup) {
        const dateRequest = {
            commentId: commentId,
            content: content,
            mentionUserIds: mentionUserIds || [],
            mentionGroup: mentionGroup,
        };
        return this._taskRepository.updateComment(commentId, dateRequest);
    }

    async updateCommentAttachments(
        commentId,
        attachments: {
            //files
            addedFiles: any[];
            removedFiles: any[];
            //notes
            addedNotes: any[];
            editedNotes: any[];
            removedNotes: any[];
        }
    ) {
        const promiseAll: Promise<any>[] = [];
        const promiseNoteAll: Promise<any>[] = [];
        Array.from(attachments.addedFiles).forEach((file) => {
            promiseAll.push(
                new AttachmentClass(ModuleSourceFileEnum.task, file).upload()
            );
        });
        Array.from([
            ...attachments.addedNotes?.map((note) => {
                return {
                    ...note,
                    id: '00000000-0000-0000-0000-000000000000',
                };
            }),
            ...attachments.editedNotes,
        ]).forEach((file) => {
            console.log(
                '🚀 Hyrin ~ TaskServiceSingleton ~ ]).forEach ~ file',
                file
            );
            promiseNoteAll.push(
                new NoteClass(ModuleSourceFileEnum.task, {
                    ...file,
                    base64: file?.base64,
                    drawData: file?.drawData,
                }).upload()
            );
        });
        const newUploadedFiles = await Promise.all(promiseAll);
        const newUploadedNotes = await Promise.all(promiseNoteAll);

        return this._taskRepository.updateCommentAttachments(commentId, {
            files: [
                ...newUploadedFiles,
                ...attachments.removedFiles?.map((file) => {
                    return { ...file, isDeleted: true };
                }),
            ],
            notes: [
                ...newUploadedNotes,
                ...attachments.removedNotes?.map((note) => {
                    return {
                        ...note,
                        drawData: JSON.stringify(note.drawData),
                        isDeleted: true,
                    };
                }),
            ],
        });
    }

    pinnedComment(commentId) {
        return this._taskRepository.pinnedComment(commentId);
    }

    reactionComment(commentId, data) {
        return this._taskRepository.reactionComment(commentId, data);
    }

    deleteComment(commentId) {
        return this._taskRepository.deleteComment(commentId);
    }

    deleteAttachmentInComment(commentId, data) {
        return this._taskRepository.deleteAttachmentInComment(commentId, data);
    }

    createTaskRepeatInfo(taskId, data: ITaskReccurring, taskCode) {
        return this._taskRepository.createTaskRepeatInfo(
            taskId,
            data,
            taskCode
        );
    }

    getRepeatTemplateInfo(templateId) {
        return this._taskRepository.getRepeatTemplateInfo(templateId);
    }

    getRepeatDetail(taskId) {
        return this._taskRepository.getRepeatDetail(taskId);
    }

    updateTaskRepeatInfo(taskId, data: ITaskReccurring, taskCode, isTurnOn) {
        return this._taskRepository.updateTaskRepeatInfo(
            taskId,
            data,
            taskCode,
            isTurnOn
        );
    }

    setRepeat(taskId, data: ITaskRepeat) {
        return this._taskRepository.setRepeat(taskId, data);
    }

    skipRepeatToCurrentTask(taskId) {
        return this._taskRepository.skipRepeatToCurrentTask(taskId);
    }

    getTaskWorkFlow(taskId) {
        return this._taskRepository.getTaskWorkFlow(taskId);
    }

    getAllStepTemplates() {
        return this._taskRepository.getAllStepTemplates();
    }

    getAllSharedWorkFlow() {
        return this._taskRepository.getAllSharedWorkFlow();
    }

    getUserTaskSummaryStatistics(userId) {
        return this._taskRepository.getUserTaskSummaryStatistics(userId);
    }

    getTaskSummaryStatistics(sourceType: ETaskListModule, listOwnerId: number) {
        return this._taskRepository.getTaskSummaryStatistics(
            sourceType,
            listOwnerId
        );
    }

    getTaskApprovalStatistic(listOwnerId: number) {
        return this._taskRepository.getTaskApprovalStatistic(listOwnerId);
    }

    updateMultipleTasks(data: UpdateMultipleTaskPayload) {
        return this._taskRepository.updateMultipleTasks(data);
    }

    getAllTaskUserRole() {
        return this._taskRepository.getAllTaskUserRole();
    }

    createOrUpdateTaskUserRole(data: TaskUserRole) {
        return this._taskRepository.createOrUpdateTaskUserRole(data);
    }

    getTaskUserAffectedByRole() {
        return this._taskRepository.getTaskUserAffectedByRole();
    }

    deleteTaskUserRole(data: IDeleteTaskUserRolePayload) {
        return this._taskRepository.deleteTaskUserRole(data);
    }

    addCollaboratorsInTask(data: IUpdateCollaboratorPayload) {
        return this._taskRepository.addCollaboratorsInTask(data);
    }

    deleteCollaboratorsInTask(data: IUpdateCollaboratorPayload) {
        return this._taskRepository.deleteCollaboratorsInTask(data);
    }

    getUserCustomColumnTask() {
        return this._taskRepository.getUserCustomColumnTask();
    }

    addUserCustomTaskColumn(data: ICustomTaskColumn[]) {
        return this._taskRepository.addUserCustomTaskColumn(data);
    }

    updateUserCustomTaskColumn(data: ICustomTaskColumn[]) {
        return this._taskRepository.updateUserCustomTaskColumn(data);
    }

    resetUserCustomTaskColumn() {
        return this._taskRepository.resetUserCustomTaskColumn();
    }

    getUserCustomTaskFilters(
        sourceType: ETaskListModule | ECustomFilterSourceType,
        sourceId: number
    ) {
        return this._taskRepository.getUserCustomTaskFilters(
            sourceType,
            sourceId
        );
    }

    addUserCustomTaskFilter(
        sourceType: ETaskListModule | ECustomFilterSourceType,
        sourceId: number,
        data: ICustomTaskFilter
    ) {
        return this._taskRepository.addUserCustomTaskFilter(
            sourceType,
            sourceId,
            data
        );
    }

    updateUserCustomTaskFilterFilter(
        sourceType: ETaskListModule,
        sourceId: number,
        data: ICustomTaskFilter
    ) {
        return this._taskRepository.updateUserCustomTaskFilterFilter(
            sourceType,
            sourceId,
            data
        );
    }

    deleteUserCustomTaskFilter(
        sourceType: ETaskListModule,
        sourceId: number,
        id: string
    ) {
        return this._taskRepository.deleteUserCustomTaskFilter(
            sourceType,
            sourceId,
            id
        );
    }

    getAllAssigneeTaskStatisticByUser(userId) {
        return this._taskRepository.getAllAssigneeTaskStatisticByUser(userId);
    }

    // todo list
    getTaskCheckListByTaskId(taskId, orgId?: number) {
        return this._taskRepository.getTaskCheckListByTaskId(taskId, orgId);
    }

    getSubTasks(taskId) {
        return this._taskRepository.getSubTasks(taskId);
    }

    removeAsSubTask(taskId, parentTaskCode) {
        return this._taskRepository.removeAsSubTask(taskId, parentTaskCode);
    }

    setParentTask(taskId, parentId) {
        return this._taskRepository.setParentTask(taskId, parentId);
    }

    createOrUpdateTaskCheckList(data: ITaskTodoList, orgId?: number) {
        return this._taskRepository.createOrUpdateTaskCheckList(data, orgId);
    }

    removeTaskCheckList(
        data: { taskId: number; taskTodoIds: string[] },
        orgId?: number
    ) {
        return this._taskRepository.removeTaskCheckList(data, orgId);
    }

    removeItemsInTaskCheckList(
        data: {
            taskId: number;
            taskTodoId: string;
            todoItemIds: string[];
        },
        orgId?: number
    ) {
        return this._taskRepository.removeItemsInTaskCheckList(data, orgId);
    }

    changePin(data: { taskId: number; color: string }) {
        return this._taskRepository.changePin(data);
    }

    unpin(taskId: number) {
        return this._taskRepository.unpin(taskId);
    }

    attachWorkFlowToTask(taskId: number, payload: ITaskWorkflowAttachPayload) {
        return this._taskRepository.attachWorkFlowToTask(taskId, payload);
    }

    setTaskApproval(
        taskId: number,
        payload: {
            approvalEnabled: boolean;
            approverIds: number[];
        }
    ) {
        return this._taskRepository.setTaskApproval(taskId, payload);
    }

    async requestTaskApproval(
        taskId: number,
        comment: {
            isCreateComment: boolean;
            content: string;
            attachments: {
                files: any[];
                notes: any[];
            };
            mentionUserIds: any[];
            mentionGroup: boolean;
        },
        workflowApprovalNextStepId: string | undefined
    ) {
        let attachmentObj: {
            uploadedFiles: any[];
            uploadedNotes: any[];
        } = {
            uploadedFiles: [],
            uploadedNotes: [],
        };
        if (
            comment?.attachments?.files?.length > 0 ||
            comment?.attachments?.notes?.length > 0
        )
            attachmentObj = await uploadAttachments(comment?.attachments);

        return this._taskRepository.requestTaskApproval(taskId, {
            isCreateComment:
                !!comment?.content ||
                attachmentObj?.uploadedFiles?.length > 0 ||
                attachmentObj?.uploadedNotes?.length > 0,
            comment: comment?.content,
            attachments: {
                files: attachmentObj?.uploadedFiles || [],
                notes: attachmentObj?.uploadedNotes || [],
            },
            workflowApprovalNextStepId,
        });
    }

    async setTaskApprovalStatus(
        taskId: number,
        approvalStatus: TaskApprovalStatus,
        comment: {
            content: string;
            attachments: {
                files: any[];
                notes: any[];
            };
            mentionUserIds: any[];
            mentionGroup: boolean;
        },
        workflowApprovalNextStepId: string | undefined
    ) {
        let attachmentObj: {
            uploadedFiles: any[];
            uploadedNotes: any[];
        } = {
            uploadedFiles: [],
            uploadedNotes: [],
        };
        if (
            comment?.attachments?.files?.length > 0 ||
            comment?.attachments?.notes?.length > 0
        )
            attachmentObj = await uploadAttachments(comment?.attachments);

        return this._taskRepository.setTaskApprovalStatus(taskId, {
            approvalStatus: approvalStatus,
            comment: comment?.content,
            attachments: {
                files: attachmentObj?.uploadedFiles || [],
                notes: attachmentObj?.uploadedNotes || [],
            },
            workflowApprovalNextStepId,
        });
    }

    cancelRequestTaskApproval(taskId: number) {
        return this._taskRepository.cancelRequestTaskApproval(taskId);
    }

    //Task reminder

    setReminderTask(taskId, payload: ITaskReminderInput) {
        return this._taskRepository.setReminderTask(taskId, payload);
    }

    renewReminderTask(eventId, expiredAfter) {
        return this._taskRepository.renewReminderTask(eventId, expiredAfter);
    }

    stopReminderTask(eventId) {
        return this._taskRepository.stopReminderTask(eventId);
    }

    reminderActionFromWebPush(eventId) {
        return this._taskRepository.reminderActionFromWebPush(eventId);
    }

    getReminderByTaskId(taskId) {
        return this._taskRepository.getTaskReminder(taskId);
    }

    removeReminderByTaskId(taskId) {
        return this._taskRepository.removeReminderByTaskId(taskId);
    }

    updateTaskWorkflowSteps(taskWorkflowId: string, payload) {
        return this._taskRepository.updateTaskWorkflowSteps(
            taskWorkflowId,
            payload
        );
    }

    getStatisticOverview(departmentId?: number) {
        return this._taskRepository.getStatisticOverview(departmentId);
    }

    getStatisticsByPlanToday(departmentId?: number) {
        return this._taskRepository.getStatisticsByPlanToday(departmentId);
    }

    changeTaskScheduleTimeLocked(taskId: number, scheduleTimeLocked: boolean) {
        return this._taskRepository.changeTaskScheduleTimeLocked(
            taskId,
            scheduleTimeLocked
        );
    }
    updateEvidenceSettings(
        taskId: number,
        data: {
            finishProofEnabled: boolean;
            finishProofTypes: string;
            finishProofSkipAllowed: boolean;
        }
    ) {
        return this._taskRepository.updateEvidenceSettings(taskId, data);
    }
    finishTaskInWorkflow(taskId: number, nextStepId: string | null) {
        return this._taskRepository.finishTaskInWorkflow(taskId, nextStepId);
    }
}

export interface IUpdateScheduleTime {
    taskId: number;
    scheduleTime: string | null;
    scheduleByHour: boolean;
    startTime?: string;
    scheduleTimezone?: string;
    isFinishToday?: boolean;
    changeStartTimeOnly?: boolean;
}

export interface IUpdateStartTime {
    taskId: number;
    scheduleTime?: string | null;
    scheduleByHour?: boolean;
    startTime?: string;
    scheduleTimezone?: string;
    isFinishToday?: boolean;
    changeStartTimeOnly?: boolean;
}

const uploadAttachments = async (attachments) => {
    //1. upload to aws
    // const uploadFiles = await uploadTaskAttachments(files);
    const promiseAll: Promise<any>[] = [];
    const promiseNoteAll: Promise<any>[] = [];
    Array.from(attachments?.files).forEach((file) => {
        promiseAll.push(
            new AttachmentClass(ModuleSourceFileEnum.task, file).upload()
        );
    });
    Array.from(
        attachments?.notes?.map((note) => {
            return { ...note, id: '00000000-0000-0000-0000-000000000000' };
        })
    ).forEach((file) => {
        // console.log('🚀 Hyrin ~ TaskServiceSingleton ~ ).forEach ~ file', file);
        promiseNoteAll.push(
            new NoteClass(ModuleSourceFileEnum.task, file).upload()
        );
    });
    const uploadedFiles = await Promise.all(promiseAll);
    const uploadedNotes = await Promise.all(promiseNoteAll);
    return {
        uploadedFiles,
        uploadedNotes,
    };
};
