<script setup lang="ts">
import UserById from '@/ui/components/user/UserById.vue';
import TaskCommentInput from '@/ui/common/plugins/ckeditor/TaskCommentInput.vue';
import commentTaskComposable from '@/ui/composables/task/comment-task';
import { computed, watch } from 'vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import taskCommentStore from '@/store/task/task-comment';

const props = withDefaults(
  defineProps<{
    taskId?: string | number;
    currentTask?: any;
    organizationId?: number;
    users?: any[];
    userClass?: any;
    inputPlaceholder?: string;
    replyTo?: string;
    functionCode?: string;
  }>(),
  {
    userClass: 'w-8 h-8',
  }
);

const emits = defineEmits<{
  (e: 'onSaveSuccess', isNeedUpdateAttachments: boolean): void;
  (e: 'update:modelValue', value: any): void;
}>();

const {
  commentTaskInputRef,
  commentObjectCreate,
  isLoading,
  resetData,
  // getAllComments,
  onUpdateContentValue,
  onCreateComment,
  updateCacheStateByTaskId,
} = commentTaskComposable(emits);

const _taskCommentStore = taskCommentStore();

const currentId = getCurrentUserId();
const currentUser = computed(() =>
  props.users?.find((user: any) => user?.id === currentId)
);

const listUserRelated = computed(() => [
  props?.currentTask?.creatorId,
  props?.currentTask?.assigneeId,
]);

watch(
  () => props.currentTask,
  () => {
    const currentCommentCacheState =
      _taskCommentStore.commentCacheByTaskIds[props.currentTask?.id];

    if (
      currentCommentCacheState?.content ||
      currentCommentCacheState?.attachmentFiles?.length > 0 ||
      currentCommentCacheState?.noteList?.length > 0
    ) {
      updateCacheStateByTaskId(currentCommentCacheState);
    } else {
      resetData();
      commentTaskInputRef.value?.resetForm();
      commentTaskInputRef.value?.onCloseTools();
    }
  }
);

const onSendComment = async (attachments) => {
  await onCreateComment(
    props.organizationId,
    props.taskId || props?.currentTask?.id,
    attachments,
    props.replyTo
  );

  emits(
    'onSaveSuccess',
    attachments?.addedFiles?.length > 0 || attachments?.addedNotes?.length > 0
  );
};

const onOpenInputComment = () => {
  // _taskDetailStore.setNavigatePoint('other', 'commentTaskInputRefId');
};

const onCloseInputComment = () => {
  resetData();
  commentTaskInputRef.value?.resetForm();
};
const focusOnInput = () => {
  if (commentTaskInputRef.value?.focusToInput) {
    commentTaskInputRef.value?.focusToInput();
  }
};

defineExpose({
  focusOnInput,
});
</script>

<template>
  <div class="flex w-full h-full items-start gap-4">
    <!--  CREATOR  -->
    <div class="pt-0.5">
      <UserById
        :user-id="currentId"
        :user="currentUser"
        is-hidden-name
        :avatar-class="userClass"
      />
    </div>
    <TaskCommentInput
      ref="commentTaskInputRef"
      v-model="commentObjectCreate.content"
      v-model:mention-user-ids="commentObjectCreate.mentionUserIds"
      v-model:mention-group="commentObjectCreate.mentionGroup"
      v-model:attachments="commentObjectCreate.attachments"
      :current-task="currentTask"
      :task-id="currentTask?.id"
      :notes="commentObjectCreate?.attachments?.notes"
      :files="commentObjectCreate?.attachments?.files"
      edit-mode="CREATE"
      :group-id="currentTask?.groupId"
      :task-private="currentTask?.isPrivate"
      :users-related="listUserRelated"
      :is-loading-send="isLoading?.send"
      :placeholder="inputPlaceholder"
      :function-code="functionCode"
      @update:model-value="onUpdateContentValue"
      @on-save="onSendComment"
      @on-open="onOpenInputComment"
      @on-close="onCloseInputComment"
      @on-focus="onOpenInputComment"
    />
  </div>
</template>
