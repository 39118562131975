<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed } from 'vue';
import registerStore from '@/store/auth/register';
import { SignUpInput } from '@/domain/entities/PayloadClass/AuthPayloadClass';
import { destroyGoogleCaptcha } from '@/ui/components/layouts/logic/init-google-capcha';
import {
  clearLocalStorage,
  getLocalStorage,
  // setLocalStorage,
  StorageConstant,
} from '@/ui/hooks/storageHook';
import loginPageStore from '@/store/un-authenticate/login-store';
import atomFormInputComposables from '@/ui/common/atoms/SynInput/atom-form-input-composables';
import VoucherDetailModal from '@/ui/modules/voucher/VoucherDetailModal.vue';
import { validateUsername } from '@/ui/hooks/commonFunction';
import { stringNomalize } from '@/ui/hooks/commonFunction';
import { GOOGLE_ANALYTICS_ELEMENT_ID } from '@/application/constants/register.const';
import organizationStore from '@/store/organization';

defineProps<{
  voucherCode: string;
  isProcessD?: boolean;
  isShowSelectPosition?: boolean;
  isHiddenAttach?: boolean;
}>();
defineEmits<{
  (e: 'onNext', data: any): void;
  (e: 'onBack'): void;
  (e: 'onOpenAttachModal'): void;
}>();

const _loginPageStore = loginPageStore();
const _registerStore = registerStore();
const registerPayload = ref(
  new SignUpInput({
    phone: _registerStore.phone || _loginPageStore.phone || '',
  })
);
const responseErrorCode = ref('');

const initComponent = async () => {};

const isShowModalError = ref(false);

const linkWebsite = ref('http://fiine.vn');
const linkWebsiteLabel = ref('fiine.vn') as any;

const isEuLocation = ref(false);
const initLocation = () => {
  const location = getLocalStorage(StorageConstant.LOCATION);
  linkWebsite.value = location == 'EU' ? 'http://tictop.eu' : 'http://fiine.vn';
  linkWebsiteLabel.value = linkWebsite.value?.split('://').pop();

  isEuLocation.value = location == 'EU';
};

initLocation();

// FIRSTNAME
const {
  isTyping: isTypingFirstName,
  validationResult: validationResultFirstName,
  onValidation: onValidationFirstName,
} = atomFormInputComposables(
  [
    (value) => {
      return value.trim() ? '' : 'COMMON_THIS_FIELD_REQUIRED';
    },
  ],
  750
);
// last name
const {
  isTyping: isTypingLastName,
  validationResult: validationResultLastName,
  onValidation: onValidationLastName,
} = atomFormInputComposables(
  [
    (value) => {
      return value.trim() ? '' : 'COMMON_THIS_FIELD_REQUIRED';
    },
  ],
  0
);
// last name
const {
  isTyping: isTypingUsername,
  validationResult: validationResultUsername,
  onValidation: onValidationUsername,
  reset: onResetValidationUsername,
} = atomFormInputComposables(
  [
    (value) => {
      return value.trim() || value?.length < 8
        ? ''
        : 'COMMON_VALID_USERNAME_WARNING';
    },
    async (value) => {
      if (!validateUsername(value)) return 'COMMON_VALID_USERNAME_WARNING';

      const resCheckExistUsername = await _registerStore.checkUsername(value);
      return resCheckExistUsername.existed
        ? 'REGISTER_LABEL_USER_NAME_EXISTED'
        : '';
    },
  ],
  0
);
const _generateUsernameFromFirstNameAndLastName = (firstName, lastName) => {
  var username = stringNomalize(
    (firstName + lastName).toLowerCase().replace(/\s/g, '')
  );
  var numbers = '0123456789';
  var generatedNumbers = '';

  // Generate two random numbers
  for (var i = 0; i < 2; i++) {
    generatedNumbers += numbers.charAt(
      Math.floor(Math.random() * numbers.length)
    );
  }

  if (username.length > 6) {
    username = username.substring(0, 6);
  } else if (username.length < 6) {
    var additionalChars = 'abcdefghijklmnopqrstuvwxyz';
    var requiredLength = 8 - username.length;

    // Generate additional random characters
    for (var j = 0; j < requiredLength - 2; j++) {
      username += additionalChars.charAt(
        Math.floor(Math.random() * additionalChars.length)
      );
    }
  }
  username += generatedNumbers;

  return username;
};
const generateUsername = () => {
  if (registerPayload.value.username) return;

  registerPayload.value.username = _generateUsernameFromFirstNameAndLastName(
    registerPayload.value?.firstName,
    registerPayload.value?.lastName
  );

  onValidationUsername(registerPayload.value.username);
};

const onClickSignIn = () => {
  _registerStore.phone =
    typeof registerPayload.value.phone == 'string'
      ? registerPayload.value.phone
      : '';
};

onMounted(() => {});

onUnmounted(() => {
  destroyGoogleCaptcha();
});

const isConfirmAcceptPolicy = ref(false);

const isDisableRegisterButton = computed(
  () =>
    // first name
    isTypingFirstName.value ||
    !validationResultFirstName.value?.isValid ||
    // first name
    isTypingLastName.value ||
    !validationResultLastName.value?.isValid ||
    // first name
    isTypingUsername.value ||
    !validationResultUsername.value?.isValid ||
    // must accept policy in EU
    (isEuLocation.value && !isConfirmAcceptPolicy.value)
);

const isLoadingRefresh = ref(false);
const onRefreshPage = async () => {
  isLoadingRefresh.value = true;
  clearLocalStorage({ isSwitchingOrg: false });
  await window.location.reload();
  isLoadingRefresh.value = false;
};

const onApplyVoucherCode = () => {
  isOpenVoucherDetail.value = false;
};

const isOpenVoucherDetail = ref(true);

const jobTitles = computed<any[]>(() => {
  return organizationStore().jobTitles;
});

const onSelectJobTitle = (jobTitle) => {
  registerPayload.value.jobTitle = jobTitle?.code;
  registerPayload.value.jobTitleName = jobTitle?.name;
};
const onJobTitle = () => {
  registerPayload.value.jobTitle = '';
  registerPayload.value.jobTitleName = '';
};
initComponent();
</script>

<template>
  <section class="mt-4">
    <div
      class="
        relative
        flex flex-col
        min-w-0
        break-words
        w-full
        mb-6
        rounded-lg
        border-0
        lg:items-center
      "
    >
      <div class="flex-auto py-10 pt-0 w-full">
        <div class="relative flex space-x-3 mb-3 mt-3">
          <SynFormInput :error-message="validationResultFirstName.message">
            <SynInput
              v-model="registerPayload.firstName"
              class="w-full"
              autofocus
              input-type="text"
              :max-length="50"
              :placeholder="$t('USERMANAGEMENT_TABLE_FIRST_NAME') + '*'"
              :error-message="validationResultFirstName.message"
              @update:model-value="onValidationFirstName"
            />
          </SynFormInput>
          <SynFormInput :error-message="validationResultLastName.message">
            <SynInput
              v-model="registerPayload.lastName"
              class="w-full"
              input-type="text"
              :max-length="50"
              :placeholder="$t('USERMANAGEMENT_TABLE_LAST_NAME') + '*'"
              @update:model-value="onValidationLastName"
              @blur="generateUsername"
            />
          </SynFormInput>
        </div>

        <div
          v-if="isProcessD && isShowSelectPosition"
          class="relative w-full mb-3"
        >
          <SynFormInput>
            <VigSelect
              :placeholder="$t('REGISTER_LABEL_SELECT_POSITION_TITLE')"
              key-search="name"
              key-label="name"
              toggle-class="py-1 text-sm "
              :label-value="registerPayload.jobTitleName"
              :origin-options="jobTitles"
              @on-choose="onSelectJobTitle"
              @on-clear="onJobTitle"
            />
          </SynFormInput>
        </div>
        <div class="relative w-full mb-3">
          <SynFormInput
            is-html
            :error-message="validationResultUsername.message"
          >
            <SynInput
              v-model="registerPayload.username"
              class="w-full"
              input-type="text"
              :min-length="8"
              :max-length="30"
              :placeholder="$t('REGISTER_LABEL_USERNAME') + '*'"
              @update:model-value="onResetValidationUsername"
              @blur="onValidationUsername(registerPayload.username)"
            />
          </SynFormInput>
        </div>

        <div class="flex items-center justify-between">
          <template v-if="voucherCode">
            <div class="flex-center space-x-2 rounded-md px-2">
              <SynIcon name="ticket-voucher" custom-class="h-8 w-8" />
              <span class="text-sm text-current-500 font-semibold">
                {{ voucherCode }}
              </span>
            </div>
          </template>
        </div>

        <div
          v-if="isEuLocation"
          class="flex flex-col space-y-2 text-gray-600 text-sm font-semibold"
        >
          <div class="flex items-start space-x-2">
            <span>
              <input
                :id="'isConfirmAcceptPolicy'"
                v-model="isConfirmAcceptPolicy"
                type="checkbox"
                class="text-current focus:ring-current rounded"
              />
            </span>

            <label
              class="cursor-pointer rounded-full flex flex-wrap space-x-2"
              :for="'isConfirmAcceptPolicy'"
              :class="isConfirmAcceptPolicy ? 'text-current' : ''"
            >
              <span>
                {{
                  $t(
                    'REGISTER_CONFIRM_ACCEPT_THE_TERMS_OF_USE_AND_THE_PRIVACY_POLICY_1'
                  )
                }}
              </span>
              <a
                href="https://www.tictop.eu/privacy/"
                target="_blank"
                class="
                  text-current
                  underline
                  decoration-solid decoration-current
                "
              >
                {{
                  $t(
                    'REGISTER_CONFIRM_ACCEPT_THE_TERMS_OF_USE_AND_THE_PRIVACY_POLICY_2'
                  )
                }}
              </a>
              <span>
                {{
                  $t(
                    'REGISTER_CONFIRM_ACCEPT_THE_TERMS_OF_USE_AND_THE_PRIVACY_POLICY_3'
                  )
                }}
              </span>
              <a
                href="https://www.tictop.eu/charte-dutilisation/ "
                target="_blank"
                class="
                  text-current
                  underline
                  decoration-solid decoration-current
                "
              >
                {{
                  $t(
                    'REGISTER_CONFIRM_ACCEPT_THE_TERMS_OF_USE_AND_THE_PRIVACY_POLICY_4'
                  )
                }}
              </a>
              {{
                $t(
                  'REGISTER_CONFIRM_ACCEPT_THE_TERMS_OF_USE_AND_THE_PRIVACY_POLICY_5'
                )
              }}
            </label>
          </div>
        </div>

        <div class="text-center pt-6">
          <div id="register_capcha-container" class="flex items-center">
            <AtomButton
              v-if="isProcessD"
              type="TEXT"
              color="gray"
              class="w-1/2"
              :label="$t('COMMON_LABEL_PREVIOUS')"
              @click="$emit('onBack')"
            />
            <div class="flex-1">
              <SynButton
                :id="
                  GOOGLE_ANALYTICS_ELEMENT_ID.REGISTER
                    .register_by_username_submit_form_btn
                "
                class="w-full m-auto"
                :disabled="isDisableRegisterButton"
                :label="$t('COMMON_LABEL_SIGN_UP')"
                @click.stop="
                  $emit('onNext', {
                    payload: registerPayload,
                    language: null,
                    sessionInfo: null,
                  })
                "
              />
            </div>
          </div>
          <div class="pt-4 text-sm flex-center gap-2">
            <template v-if="isHiddenAttach">
              <label class="text-gray-500 text-sm">{{
                $t('COMMON_LABEL_QUESTION_LOGIN') + ' '
              }}</label>
              <RouterLink :to="{ name: 'Login' }">
                <label
                  class="
                    font-medium
                    text-current-600
                    cursor-pointer
                    hover:text-current-200
                    text-sm
                  "
                  @click="onClickSignIn"
                >
                  {{ $t('COMMON_MODULE_LOGIN') }}
                </label>
              </RouterLink>
            </template>
            <template v-else>
              <span class="text-gray-500 text-sm">{{
                $t('COMMON_LABEL_OR')
              }}</span>
              <router-link :to="{ name: 'Login' }">
                <span
                  class="
                    font-medium
                    text-current-600
                    cursor-pointer
                    hover:text-current-200
                    text-sm
                  "
                  @click="onClickSignIn"
                >
                  {{ $t('COMMON_LABEL_SIGN_UP') }}
                </span>
              </router-link>
              <span>-</span>
              <span
                class="
                  font-medium
                  text-current-600
                  cursor-pointer
                  hover:text-current-200
                  text-sm
                "
                @click="$emit('onOpenAttachModal')"
              >
                {{ $t('COMMON_LABEL_JOIN_THE_ORGANIZATION') }}
              </span>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>

  <SynQuestionModal
    :visible="isShowModalError"
    :show-confirm-btn="false"
    :delete-label="$t('COMMON_LABEL_TRY_AGAIN')"
    :is-loading="isLoadingRefresh"
    show-delete-btn
    header-text="Oops!!"
    @cancel="isShowModalError = false"
    @on-confirm="onRefreshPage"
  >
    <template #content>
      <syn-animation name="oops" stype="width: 200px;" :loop="false" />
      <div class="text-center text-red-700">
        {{ $t(responseErrorCode) }}
      </div>
    </template>
  </SynQuestionModal>
  <VoucherDetailModal
    v-if="isOpenVoucherDetail && voucherCode"
    :voucher-code="voucherCode"
    :plan-id="'PRO'"
    need-to-update
    @apply="onApplyVoucherCode"
  />
</template>
