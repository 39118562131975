<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    label?: string;
    disabled?: boolean;
    readonly?: boolean;
    align?: 'center' | 'start';
    direction?: 'row' | 'col';
    space?: number;
    checkboxClass?: string;
  }>(),
  {
    align: 'start',
    direction: 'row',
    space: 2,
  }
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'onChange', value: boolean): void;
}>();

let _value;

const value = computed({
  get() {
    _value = props.modelValue;
    return props.modelValue;
  },
  set(value) {
    _value = value;
    emit('update:modelValue', value);
  },
});

const onChange = () => {
  emit('onChange', _value);
};
</script>

<template>
  <label
    class="cursor-pointer"
    :class="[
      disabled ? 'opacity-60 pointer-events-none' : '',
      readonly ? 'pointer-events-none' : '',
    ]"
  >
    <span
      class="flex items-center"
      :class="['justify-' + align, 'flex-' + direction, 'gap-' + space]"
    >
      <input
        v-model="value"
        :disabled="disabled"
        type="checkbox"
        class="vig-checkbox"
        :class="[{ 'opacity-60': disabled || readonly }, checkboxClass]"
        @change="onChange"
      />

      <slot>
        <span v-if="label">{{ $t(label) || label }}</span>
      </slot>
    </span>
  </label>
</template>
