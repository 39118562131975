<script setup lang="ts">
// *** IMPORTS ***
import { computed, onBeforeMount, onBeforeUnmount, ref } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import FileStorageRepository from '@/application/repositories/FileStorageRepository';
import VideoRecodingFileRow from '@/ui/modules/video-recordings/recording-file-row/VideoRecodingFileRow.vue';
import $constants from '@/ui/plugins/constants';
import { chain } from 'lodash';
import ModalMultipleFileViewerInfo from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewerInfo.vue';

// *** PROPS, EMITS ***

// *** PRIVATE VARIABLES ***

// *** COMPOSABLES ***

// *** REFS ***
const isLoading = ref<boolean>();
const isReloading = ref<boolean>();
const canLoadMore = ref<boolean>();
const recordingFiles = ref<any[]>();
const fileShowViewer = ref<any>();
const indexCurrentFile = ref<number | null>();

// *** COMPUTED ***
const myProfile = computed(() => myProfileStore().myProfile);

// *** WATCHES ***

// *** HOOKS ***
onBeforeMount(() => {
  _getVideoRecordingFiles();

  window.addEventListener('message', _listenMessageFromRecorderWindow);
});

onBeforeUnmount(() => {
  window.removeEventListener('message', _listenMessageFromRecorderWindow);
});

// *** HANDLER FUNCTIONS ***
const onAddRecordingClick = () => {
  const width = 1200;
  const height = 800;
  const iMyWidth = window.screen.width / 2 - (width / 2 + 10);
  const iMyHeight = window.screen.height / 2 - (height / 2 + 50);

  window.open(
    `/video-recorder`,
    'FiineVideoRecorder',
    `width=${width},height=${height},left=${iMyWidth},top=${iMyHeight},directories=0,titlebar=0,location=0,toolbar=0,menubar=0,scrollbars=0,resizable=0,status=0`
  );
};

const onReloadClick = async () => {
  isReloading.value = true;

  try {
    await _getVideoRecordingFiles();
  } catch (e) {
    console.log(e);
  }

  isReloading.value = false;
};

const onFileClick = (file) => {
  fileShowViewer.value = file;

  indexCurrentFile.value = recordingFiles.value?.findIndex(
    (f) => f?.id === file?.id
  );

  if (indexCurrentFile.value === -1) indexCurrentFile.value = null;
};

// *** PRIVATE FUNCTIONS ***
const _getVideoRecordingFiles = async (
  isReload = true,
  isShowLoading = true
) => {
  if (isShowLoading) isLoading.value = true;

  if (isReload) {
    recordingFiles.value = [];
    canLoadMore.value = false;
  }

  const query: any = {
    category: $constants.FILE.CATEGORY.VIDEO_RECORDING,
    userId: myProfile.value?.id,
    pageSize: 50,
    pageIndex: isReload ? 0 : recordingFiles.value?.length,
  };

  try {
    const res: any = await FileStorageRepository.getInstance().getFiles(query);
    const result = res?.result;

    recordingFiles.value = isReload
      ? result?.files
      : chain(recordingFiles.value).concat(result?.files).uniqBy('id').value();

    canLoadMore.value =
      (recordingFiles.value?.length || 0) < (result?.total || 0);
  } catch (e) {
    console.log(e);
  }

  isLoading.value = false;
};

const _listenMessageFromRecorderWindow = (event: any) => {
  if (
    event?.origin === window.location.origin &&
    event?.data === 'FiineVideoRecorder:RecordingSaved'
  ) {
    console.log('---event', event);
    _getVideoRecordingFiles();
  }
};

// *** EXPOSES ***
</script>

<template>
  <div class="h-full flex flex-col">
    <div class="flex gap-3 items-center justify-between px-3 py-2">
      <div class="text-xl leading-6">
        {{ $t('GED_TAB_VIDEO_RECORDINGS') }}
      </div>
      <div class="flex gap-2">
        <VigButton
          v-vig-tooltip="$t('GED_VIDEO_RECORDING_ADD')"
          icon="Plus"
          @click="onAddRecordingClick"
        />
        <VigButton
          v-vig-tooltip="$t('NOTIFICATION_RELOAD')"
          ghost
          color="gray"
          icon="Reload"
          :processing="isReloading"
          @click="onReloadClick"
        />
      </div>
    </div>

    <div class="flex-1 overflow-auto small-scrollbar bg-white">
      <table v-if="recordingFiles?.length > 0" class="w-full table-fixed">
        <thead>
          <SynTr>
            <SynTh class="w-16"></SynTh>
            <SynTh is-sort :label="$t('GED_LINK_NAME')"></SynTh>
            <SynTh is-sort :label="$t('GED_CREATOR_NAME')" class="w-44"></SynTh>
            <SynTh
              is-sort
              :label="$t('TASK_EXPORT_COLUMNS_CREATION_DATE')"
              class="w-44"
            ></SynTh>
            <SynTh class="w-12"></SynTh>
          </SynTr>
        </thead>
        <tbody>
          <VideoRecodingFileRow
            v-for="file in recordingFiles"
            :key="file"
            :file="file"
            @on-file-click="onFileClick(file)"
          />
        </tbody>
      </table>

      <VigLoading v-if="isLoading" />
    </div>
  </div>

  <ModalMultipleFileViewerInfo
    v-if="fileShowViewer && indexCurrentFile !== null"
    :start-index="indexCurrentFile"
    :files="recordingFiles"
    :editable="false"
    @on-close="fileShowViewer = null"
  />
</template>

<style scoped></style>
