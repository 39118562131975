import { createApp, h } from 'vue';
import DayoffEventItem from '@/ui/components/calendars/event-item/DayoffEventItem.vue';
import { DayoffDetailItemClass } from '@/domain/entities/dayoff/DayoffCalendarItemClass';
import VigImage from '@/ui/common/atoms/VigImage/VigImage.vue';
import { DayoffType } from '@/domain/enums/DayoffEnums';
// import Schedule from '@/ui/components/icons/Schedule.vue';
import EventDayOff from '@/ui/components/icons/EventDayOff.vue';
import BreakDayOff from '@/ui/components/icons/BreakDayOff.vue';
import WfhDayOff from '@/ui/components/icons/WfhDayOff.vue';
// import WorkFromHome from '@/ui/components/icons/WorkFromHome.vue';
// import BreakTime from '@/ui/components/icons/BreakTime.vue';

export function renderDayoffItemEvent(dayoffDetailItem: DayoffDetailItemClass) {
    const iconComponent = getIconTypeComponent(dayoffDetailItem.type);

    const appItem = createApp({
        render() {
            return h(
                DayoffEventItem,
                {
                    data: dayoffDetailItem,
                    iconComponent: iconComponent,
                    avatarComponent: VigImage,
                },
                () => [h(iconComponent, {}), h(VigImage, {})]
            );
        },
    });
    const tmpEl = document.createElement('div');
    const mountedApp = appItem.mount(tmpEl);
    const el = document.createElement('div');
    el.innerHTML = mountedApp.$el.outerHTML;
    return el;
}

export function getIconTypeComponent(type: DayoffType) {
    let component;
    switch (type) {
        case DayoffType.SCHEDULE:
            component = EventDayOff;
            break;
        case DayoffType.WFH:
            component = WfhDayOff;
            break;
        case DayoffType.EXCEPTION:
            component = BreakDayOff;
            break;
        default:
            break;
    }
    return component;
}
