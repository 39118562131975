<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { isValidEmail, isValidPhoneNumber } from '@/ui/hooks/commonFunction';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import organizationStore from '@/store/organization';
import {
  AlertType,
  NotificationPositionType,
  openNotification,
} from '@/ui/hooks/commonHook';
import plansStore from '@/store/plans';
import {
  EPlanCapacityKeys,
  EResourceUsageStatus,
  EUsageItemUnit,
} from '@/domain/enums/PlanEnums';
// import EmailPhoneInput from '@/ui/components/email-phone-input/email-phone-input.vue';
import { CountryCode } from 'libphonenumber-js';
import paymentStore from '@/store/payment';
import atomFormInputComposables from '@/ui/common/atoms/SynInput/atom-form-input-composables';
import { EPlanState, IPlanCapacityByKey } from '@/application/types/plan.types';
import { EPlanId, getUiByPlanState } from '@/application/constants/plan.const';
import permissionStore from '@/store/permission';
import { copyToClipBroard } from '@/ui/hooks/commonFunction';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import UploadAvatarIcon from '@/ui/components/photo-upload/UploadAvatarIcon.vue';
import PlansOverviewModal from '@/ui/modules/settings/payments/overview/PlansOverviewModal.vue';
import PendingUsers from '@/ui/pages/usermanagement/PendingUsers.vue';
import { EPaymentPurpose } from '@/application/types/payment.types';
import OrganizationPlan from '@/domain/entities/payment/OrganizationPlan';

const adminTypeList = {
  role_1: {
    text: 'Admin',
    textCode: 'COMMON_LABEL_ADMIN',
    key: 1,
  },
  role_3: {
    text: 'User',
    textCode: 'USERMANAGEMENT_FORM_TYPE_USER',
    key: 3,
  },
  role_2: {
    text: 'Visitor',
    textCode: 'COMMON_LABEL_VISITOR',
    key: 2,
  },
};

defineProps({
  isHiddenClose: {
    type: Boolean,
    default: false,
  },
  cancelCode: {
    type: String,
    default: 'COMMON_LABEL_CANCEL',
  },
});

const emit = defineEmits([
  'onInviteSuccess',
  'updateListItem',
  'onClose',
  'onCancel',
]);
const _organizationStore = organizationStore();
const _planStore = plansStore();
const _paymentStore = paymentStore();

const attachmentCode = computed(() => _organizationStore.attachmentCode);
const organizationInfo = computed(() => _organizationStore.organizationInfo);
const uiByState = computed(() => {
  return getUiByPlanState(
    currentOrgPlan.value.planId,
    currentOrgPlan.value.stateCode
  );
});
const uiBySubState = computed(() => {
  const subState = currentOrgPlan.value.subStateCode || 'DEFAULT';
  if (
    !uiByState.value ||
    !Object.prototype.hasOwnProperty.call(uiByState.value, subState)
  )
    return {};

  return uiByState.value[subState];
});
const planCapacityByKeys = computed<IPlanCapacityByKey>(
  () => _planStore.planCapacityByKeys
);

const totalPremiumMembers = computed<number>(() => {
  if (currentOrgPlan.value?.stateCode === EPlanState.OVERDUE_TRIAL) return 10;

  return planCapacityByKeys.value?.totalMember?.limitation;
});
const totalPremiumMembersUsage = computed<number>(() => {
  return planCapacityByKeys.value?.totalMember?.usage;
});
const totalInvitedMembers = computed<number>(() => {
  return planCapacityByKeys.value?.totalValidInvite?.usage;
});
const totalVisitors = computed<number>(() => {
  return planCapacityByKeys.value?.totalVisitor?.usage;
});
const limitVisitors = computed<number>(() => {
  return planCapacityByKeys.value?.totalVisitor?.limitation;
});

const maximumMembersAddable = computed(() => {
  return (
    totalPremiumMembers.value -
    totalPremiumMembersUsage.value -
    totalInvitedMembers.value
  );
});

const emailPhoneItem = ref('');

const {
  isChecking: isCheckingPhone,
  validationResult: validationResultPhone,
  onValidation: onValidationPhone,
  reset: onResetValidationPhone,
} = atomFormInputComposables([
  (value) => {
    if (value.trim()) return false;
    return true;
  },
  () => {
    return resultPhoneObject.value?.isValid
      ? ''
      : 'COMMON_INVALID_PHONE_NUMBER';
  },
  (value) => {
    const isValid = isValidPhoneNumber(value);
    return isValid ? '' : 'COMMON_INVALID_PHONE_NUMBER';
  },
  (value) => {
    const isValid =
      value.startsWith('+') &&
      ['+33', '+84', '+1', '+81', '+82'].includes(value?.slice(0, 3));
    return isValid ? '' : 'COMMON_INVALID_PHONE_NUMBER';
  },
  (value) => {
    const isDuplicated = emailPhoneInvites.value?.some(
      (o) => o.emailOrPhone.toLowerCase() == value
    );
    return isDuplicated ? 'COMMON_INVALID_DUPLICATE_PHONE_NUMBER' : '';
  },
  async (value) => {
    let errorMessage = '';
    try {
      const resCheckExist =
        await _organizationStore.checkEmailPhoneInOrganization(
          encodeURIComponent(value)
        );
      if (resCheckExist.attachedOrganization) {
        errorMessage = translate('COMMON_LABEL_PHONE_EXISTED_IN_ORGANIZATION');
      } else if (resCheckExist.isDeleted) {
        errorMessage = translate('COMMON_LABEL_PHONE_ACCOUNT_DELETED');
      }
    } catch (error: any) {
      const errorCode = error?.error?.code;

      errorMessage =
        translate(`COMMON_ERROR_BY_CODE_${errorCode}`) ||
        translate('COMMON_SOMETHING_WRONG');
    }

    return errorMessage;
  },
]);

const {
  isChecking: isCheckingEmail,
  validationResult: validationResultEmail,
  onValidation: onValidationEmail,
} = atomFormInputComposables(
  [
    (value) => {
      if (value.trim()) return false;
      return true;
    },
    (value) => {
      return isValidEmail(value) ? '' : 'COMMON_LABEL_REQUIRE_INVALID_EMAIL';
    },
    (value) => {
      // 2.2 Check duplicated
      const isDuplicated = emailPhoneInvites.value?.some(
        (o) => o.emailOrPhone?.toLowerCase() == value
      );

      return isDuplicated ? 'COMMON_INVALID_DUPLICATE_EMAIL' : '';
    },
    async (value) => {
      let result = '';
      const resCheckExist =
        await _organizationStore.checkEmailPhoneInOrganization(value);
      if (resCheckExist.attachedOrganization) {
        result = 'COMMON_LABEL_EMAIL_EXISTED_IN_ORGANIZATION';
      } else if (resCheckExist.isDeleted) {
        result = 'COMMON_LABEL_EMAIL_ACCOUNT_DELETED';
      }

      return result;
    },
  ],
  1000
);

const emailPhoneInvites = ref([] as any[]);
const inviteUserModal = ref(null);

const currentOrgPlan = computed(() => _planStore.currentOrgPlan);

onMounted(() => {
  window.addEventListener('keypress', function (e) {
    if (e.key === 'Enter') {
      // code for enter
      onAddInvite();
    }
  });
  onGetLimitTotalMember();
  _organizationStore.getPendingUsers();
});
onUnmounted(() => {
  window.removeEventListener('keypress', () => {});
});

const inviteMoreCapacity = ref<{
  key: string;
  limitation: number;
  status: EResourceUsageStatus;
  unit: EUsageItemUnit;
  usage: number;
}>({
  key: 'TotalMember',
  limitation: 0,
  status: EResourceUsageStatus.Allowable,
  unit: EUsageItemUnit.Quantity,
  usage: 0,
});

const isExceedTotalMemberInvite = computed(() => {
  if (currentOrgPlan.value?.stateCode === EPlanState.TRIAL) return false;

  return (
    maximumMembersAddable.value -
      emailPhoneInvites.value?.filter((x) => x.adminType !== 2)?.length <
    0
  );
});

const isExceedTotalVisitorInvite = computed(() => {
  return (
    totalVisitors.value +
      emailPhoneInvites.value?.filter((x) => x.adminType === 2)?.length >
    limitVisitors.value
  );
});

const isInitLoading = ref(true);
const onGetLimitTotalMember = async () => {
  try {
    isInitLoading.value = true;
    const result = await _planStore.getOrgPlanCapacities([
      EPlanCapacityKeys.TotalMember,
    ]);
    if (result?.length > 0) inviteMoreCapacity.value = result[0];
  } finally {
    isInitLoading.value = false;
  }
};
const inputType = ref(1);
const isPhoneNumber = computed(() => inputType.value == 2);

const onAddInvite = () => {
  if (!isEnabledEnter.value || !emailPhoneItem.value) return;
  // 3. Add to list
  const itemValue = emailPhoneItem.value;
  emailPhoneInvites.value.push({
    adminType: parseInt(adminType.value?.toString()),
    emailOrPhone: itemValue,
  });
  emit('updateListItem', emailPhoneInvites.value);
  emailPhoneItem.value = '';
  onValidationPhone('');
  onValidationEmail('');
};

const onRemoveItem = (data) => {
  emailPhoneInvites.value = emailPhoneInvites.value.filter(
    (o) => o.emailOrPhone.toLowerCase() !== data.emailOrPhone?.toLowerCase()
  );
  emit('updateListItem', emailPhoneInvites.value);
};
const isFetchingInvite = ref(false);
const isDisabledInvite = computed(() => emailPhoneInvites.value?.length == 0);

const onFetchInviteUsers = async () => {
  if (isDisabledInvite.value) return;
  const totalMembers = emailPhoneInvites.value?.length;
  isFetchingInvite.value = true;
  const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);

  const payload = {
    organizationId,
    items: emailPhoneInvites.value,
  };
  try {
    await _organizationStore.inviteUser(payload);
    _organizationStore.getPendingUsers();
    if (permissionStore().organizationModel !== 'PERSONAL')
      _planStore.getAllPlanCapacities();

    const firstPerson = emailPhoneInvites.value[0].emailOrPhone;

    const contentMessage =
      totalMembers > 1
        ? translate('USERMANAGEMENT_PENDING_ACTION_INVITE_MEMBER', {
            total: totalMembers,
          })
        : translate('USERMANAGEMENT_PENDING_ACTION_RESENDSUCCESS', {
            address: firstPerson,
          });

    openNotification({
      body: contentMessage,
      duration: 3000,
      position: NotificationPositionType.TopCenter,
    });
    // Emit after invite success
    emit('onInviteSuccess');
  } catch (error: any) {
    isFetchingInvite.value = false;
    const errorMessage = error?.error?.message;
    openNotification({
      type: AlertType.error,
      body: errorMessage,
      duration: 3000,
    });
  }
  // alert invite success
};

const onChangeInputType = () => {
  emailPhoneItem.value = '';
  // emailPhoneErrorMessage.value = '';
};

const resultPhoneObject = ref<{
  country?: CountryCode;
  countryCallingCode: string;
  nationalNumber: string;
  number: string;
  isValid: boolean;
  formattedNumber: string;
}>({
  countryCallingCode: '',
  nationalNumber: '',
  number: '',
  isValid: true,
  formattedNumber: '',
});
const onChangePhone = (phoneObj: {
  country?: CountryCode;
  countryCallingCode: string;
  nationalNumber: string;
  number: string;
  isValid: boolean;
  formattedNumber: string;
}) => {
  onResetValidationPhone();
  resultPhoneObject.value = phoneObj;
  // 1. Set value for phone
  if (!phoneObj || !phoneObj.number) return;
  emailPhoneItem.value = phoneObj.number;
  if (phoneObj?.number?.length == 12) onValidationPhone(phoneObj.number);
};

const onBlurPhone = () => {
  if (!emailPhoneItem.value || validationResultPhone.value?.isValid) return;

  onValidationPhone(emailPhoneItem.value);
};

const onChangeEmail = (event) => {
  const value = event.target.value;
  onValidationEmail(value);
};

const isEnabledEnter = computed(() => {
  // 1. Phone
  if (isPhoneNumber.value)
    return (
      !isCheckingPhone.value &&
      validationResultPhone.value.isValid &&
      emailPhoneItem.value
    );

  // 2. Email
  return (
    !isCheckingEmail.value &&
    validationResultEmail.value.isValid &&
    emailPhoneItem.value
  );
});

const adminType = ref(3 as number);

const isShowOptions = ref(false);

const isCreatingToken = ref<boolean>(false);
const handleBuyMoreUser = async () => {
  const planId = currentOrgPlan.value?.planId;
  if (!planId) return;

  try {
    isCreatingToken.value = true;
    await _paymentStore.createPaymentToken({
      planId,
      purpose: EPaymentPurpose.GET_MORE,
      period: null,
      totalUsers: null,
    });
    isCreatingToken.value = false;
  } catch (error) {
    console.log(
      '🚀 ~ file: PlanManage.vue ~ line 49 ~ onUpgrade ~ error',
      error
    );
  }
};

const makeNewPayment = async () => {
  isCreatingToken.value = true;
  // await _paymentStore.checkAndMakeNewPayment({
  //   planId: currentOrgPlan.value?.planId,
  //   purpose: uiByState.value?.action?.defaultActionKey || null,
  //   period: 6,
  //   totalUsers: totalPremiumMembers.value,
  // });

  await new OrganizationPlan(
    _planStore.currentOrgPlan,
    organizationStore().organizationInfo,
    _paymentStore.paymentConfigs,
    _planStore.planCapacityByKeys,
    _planStore.capacityByPlanIds
  ).createPayment({
    planId: currentOrgPlan.value?.planId,
    purpose: EPaymentPurpose.PURCHASE,
    period: 6,
    totalUsers: totalPremiumMembers.value,
  });

  isCreatingToken.value = false;
};

const onCopyAttachmentCode = () => {
  const copied = copyToClipBroard(
    attachmentCode.value || organizationInfo.value?.attachmentCode
  );
  if (copied) {
    openNotification({
      body: translate('COMMON_LABEL_COPIED'),
      duration: 2000,
    });
  }
};

const onGenerateAttachmentCode = async () => {
  settingGlobalModal({
    type: 'confirm',
    title:
      translate('COMMON_CONFIRM_BEFORE_GENERATE_ATTACHMENT_CODE') ||
      'Are you sure you want to generate a new code?',
    content:
      translate('COMMON_CONFIRM_BEFORE_GENERATE_ATTACHMENT_CODE_BODY') ||
      'Once the new code is generated, the current code will not be able use to attach your organization.',
    confirmable: true,
    closeable: true,
  });
  const confirmed = await ask();
  if (confirmed) {
    await _organizationStore.generateOrganizationAttachmentCode();
  }
};

const isShowPlansOverview = ref<boolean>(false);
const isShowPendingUserModal = ref<boolean>(false);
const pendingUsersUpToDate = computed(() => _organizationStore.pendingUsers);
</script>

<template>
  <teleport to="body">
    <div
      class="absolute w-full inset-0 overflow-hidden flex-center z-50"
      style="background: rgba(0, 0, 0, 0.7)"
    >
      <div
        ref="inviteUserModal"
        class="
          relative
          h-auto
          shadow-lg
          bg-white
          mx-auto
          rounded-md
          flex flex-col
        "
        style="max-height: 80%; max-width: 75rem"
      >
        <div class="h-full text-left flex flex-col overflow-y-auto">
          <!--Body-->

          <div
            class="
              rounded-md
              h-full
              max-h-full
              flex
              bg-white
              overflow-x-hidden overflow-y-auto
              small-scrollbar
            "
            style="min-height: 500px"
          >
            <div class="flex flex-col flex-1 p-12 pr-10">
              <div class="flex flex-col">
                <span class="font-medium text-current text-2xl">
                  {{
                    $t('SETUP_TEAM_INVITE_TITLE') ||
                    'Mời thành viên của bạn cùng tham gia'
                  }}
                </span>
                <span class="text-gray-600 text-sm">
                  {{
                    $t('SETUP_TEAM_INVITE_TITLE_SUB') ||
                    'We will send invitations to your teammates by email or SMS message'
                  }}
                </span>
              </div>
              <div
                class="flex flex-col flex-1 overflow-y-auto small-scrollbar p-1"
              >
                <div class="mt-12 mr-14 flex justify-between items-center">
                  <!-- CHOOSE TYPE INPUT -->
                  <div class="">
                    <div class="float-left px-2">
                      <SynRadio
                        v-model="inputType"
                        :value="1"
                        :label="'Email'"
                        @click="onChangeInputType"
                      />
                    </div>
                    <div class="float-left px-2">
                      <SynRadio
                        v-model="inputType"
                        :value="2"
                        :label="$t('COMMON_LABEL_MOBILE_PHONE_TEXT')"
                        @click="onChangeInputType"
                      />
                    </div>
                  </div>

                  <!-- QUESTION -->
                  <div
                    class="
                      flex
                      items-center
                      text-gray-600
                      fill-gray-500
                      text-sm
                    "
                  >
                    <div class="flex-center space-x-2 px-2">
                      <span>{{ $t('USERMANAGEMENT_FORM_TYPE_ADMIN') }}</span>
                      <vig-dropdown placement="top-end">
                        <template #dropdown-toggle>
                          <div class="flex-center">
                            <SynIcon
                              name="question"
                              custom-class="h-4 w-4 hover:fill-current cursor-pointer"
                            />
                          </div>
                        </template>
                        <template #dropdown-menu>
                          <div class="pl-8 p-4 w-96 bg-gray-50 rounded-lg">
                            <div class="flex justify-between items-center pb-4">
                              <span class="font-semibold text-current">
                                {{ $t('COMMON_LABEL_WHAT_IS_ADMIN') }}
                              </span>
                              <SynIcon
                                name="question"
                                custom-class="h-4 w-4 fill-current"
                              />
                            </div>
                            <ul
                              class="pl-4"
                              style="list-style: circle"
                              v-html="$t('COMMON_LABEL_WHAT_IS_ADMIN_ANSWER')"
                            ></ul>
                          </div>
                        </template>
                      </vig-dropdown>
                    </div>
                    <div class="flex-center space-x-2 px-6">
                      <span> {{ $t('USERMANAGEMENT_FORM_TYPE_USER') }} </span>
                      <vig-dropdown placement="top-end">
                        <template #dropdown-toggle>
                          <div class="flex-center">
                            <SynIcon
                              name="question"
                              custom-class="h-4 w-4 hover:fill-current cursor-pointer"
                            />
                          </div>
                        </template>
                        <template #dropdown-menu>
                          <div class="pl-8 p-4 w-96 bg-gray-50 rounded-lg">
                            <div class="flex justify-between items-center pb-4">
                              <span class="font-semibold text-current">
                                {{
                                  $t('COMMON_LABEL_WHAT_IS_MEMBER') ||
                                  'What is a Member'
                                }}
                              </span>
                              <SynIcon
                                name="question"
                                custom-class="h-4 w-4 fill-current"
                              />
                            </div>
                            <ul
                              class="pl-4"
                              style="list-style: circle"
                              v-html="$t('COMMON_LABEL_WHAT_IS_MEMBER_ANSWER')"
                            ></ul>
                          </div>
                        </template>
                      </vig-dropdown>
                    </div>
                    <div class="flex-center space-x-2 pl-2 pr-6">
                      <span> {{ $t('COMMON_LABEL_VISITOR') }} </span>
                      <vig-dropdown placement="top-end">
                        <template #dropdown-toggle>
                          <div class="flex-center">
                            <SynIcon
                              name="question"
                              custom-class="h-4 w-4 hover:fill-current cursor-pointer"
                            />
                          </div>
                        </template>
                        <template #dropdown-menu>
                          <div class="pl-8 p-4 w-96 bg-gray-50 rounded-lg">
                            <div class="flex justify-between items-center pb-4">
                              <span class="font-semibold text-current">
                                {{
                                  $t('COMMON_LABEL_WHAT_IS_VISITOR') ||
                                  'What is a Member'
                                }}
                              </span>
                              <SynIcon
                                name="question"
                                custom-class="h-4 w-4 fill-current"
                              />
                            </div>
                            <ul
                              class="pl-4"
                              style="list-style: circle"
                              v-html="$t('COMMON_LABEL_WHAT_IS_VISITOR_ANSWER')"
                            ></ul>
                          </div>
                        </template>
                      </vig-dropdown>
                    </div>
                  </div>
                </div>

                <!-- INPUT -->
                <div
                  class="w-full flex justify-between items-center mt-4"
                  style="max-width: 100%"
                >
                  <div class="flex-1 flex flex-col relative">
                    <div class="flex flex-1 items-center space-x-1">
                      <!-- PHONE -->
                      <SynFormInput
                        v-if="isPhoneNumber"
                        :is-checking="isCheckingPhone"
                        :is-valid="validationResultPhone.isValid"
                        :error-message="validationResultPhone.message"
                      >
                        <AtomPhoneInput
                          autofocus
                          :value="emailPhoneItem"
                          :input-options="{
                            placeholder: $t(
                              'COMMON_LABEL_MOBILE_PHONE_TO_INVITE'
                            ),
                          }"
                          @input="onChangePhone"
                          @blur="onBlurPhone"
                          @enter="onAddInvite"
                        >
                          <template #suffix>
                            <div class="flex-center">
                              <vig-dropdown
                                placement="bottom-end"
                                @on-dropdown-open="isShowOptions = true"
                                @on-dropdown-close="isShowOptions = false"
                              >
                                <template #dropdown-toggle>
                                  <button
                                    class="
                                      text-sm
                                      bg-white
                                      p-2
                                      text-gray-800
                                      border-l
                                      w-36
                                      h-9
                                      rounded-r
                                      cursor-pointer
                                      hover:bg-gray-200
                                    "
                                  >
                                    <div
                                      class="
                                        w-full
                                        h-full
                                        flex
                                        items-center
                                        justify-between
                                      "
                                    >
                                      <span>
                                        {{
                                          $t(
                                            adminTypeList[`role_${adminType}`]
                                              .textCode
                                          ) ||
                                          adminTypeList[`role_${adminType}`]
                                            .text
                                        }}
                                      </span>
                                      <SynIcon
                                        :name="
                                          isShowOptions
                                            ? 'arrow-up'
                                            : 'arrow-down'
                                        "
                                        custom-class="w-2 h-2 fill-gray-500 text-gray-400"
                                      />
                                    </div>
                                  </button>
                                </template>
                                <template #dropdown-menu>
                                  <div
                                    class="p-2 w-60 rounded-lg flex flex-col"
                                  >
                                    <div
                                      v-for="(value, index) in adminTypeList"
                                      :key="index"
                                      class="
                                        list-li
                                        cursor-pointer
                                        dropdown-item
                                        rounded
                                      "
                                      :class="
                                        adminType == value.key
                                          ? 'bg-gray-100 font-semibold'
                                          : ''
                                      "
                                      @click="adminType = value.key"
                                    >
                                      {{ $t(value.textCode) || value.text }}
                                    </div>
                                  </div>
                                </template>
                              </vig-dropdown>
                            </div>
                          </template>
                        </AtomPhoneInput>
                        <template #checking>
                          <div
                            v-if="isCheckingPhone"
                            class="w-4 flex-center absolute right-40 top-3"
                          >
                            <SynIcon
                              name="spinner"
                              class="animate-spin fill-current"
                            />
                          </div>
                        </template>
                        <template #valid>
                          <div
                            v-if="
                              !validationResultPhone.message &&
                              !isCheckingPhone &&
                              validationResultPhone.isValid
                            "
                            class="w-4 flex-center absolute right-40 top-3"
                          >
                            <SynIcon name="check" class="text-green-500" />
                          </div>
                        </template>
                      </SynFormInput>

                      <!-- EMAIL -->
                      <SynFormInput
                        v-else
                        :is-checking="isCheckingEmail"
                        :is-valid="validationResultEmail.isValid"
                        :error-message="validationResultEmail.message"
                      >
                        <SynInput
                          id="login-email"
                          v-model="emailPhoneItem"
                          autofocus
                          icon-prefix="email"
                          :max-length="50"
                          :placeholder="$t('COMMON_LABEL_EMAIL_INVITE')"
                          @input="onChangeEmail"
                          @enter="onAddInvite"
                        >
                          <template #suffix>
                            <div class="flex-center">
                              <vig-dropdown
                                placement="bottom-end"
                                @on-dropdown-open="isShowOptions = true"
                                @on-dropdown-close="isShowOptions = false"
                              >
                                <template #dropdown-toggle>
                                  <button
                                    class="
                                      text-sm
                                      bg-white
                                      p-2
                                      text-gray-800
                                      border-l
                                      w-36
                                      h-9
                                      rounded-r
                                      cursor-pointer
                                      hover:bg-gray-200
                                    "
                                  >
                                    <div
                                      class="
                                        w-full
                                        h-full
                                        flex
                                        items-center
                                        justify-between
                                      "
                                    >
                                      <span>
                                        {{
                                          $t(
                                            adminTypeList[`role_${adminType}`]
                                              .textCode
                                          ) ||
                                          adminTypeList[`role_${adminType}`]
                                            .text
                                        }}
                                      </span>
                                      <SynIcon
                                        :name="
                                          isShowOptions
                                            ? 'arrow-up'
                                            : 'arrow-down'
                                        "
                                        custom-class="w-2 h-2 fill-gray-500 text-gray-400"
                                      />
                                    </div>
                                  </button>
                                </template>
                                <template #dropdown-menu>
                                  <div
                                    class="p-2 w-60 rounded-lg flex flex-col"
                                  >
                                    <div
                                      v-for="(value, index) in adminTypeList"
                                      :key="index"
                                      class="
                                        list-li
                                        cursor-pointer
                                        dropdown-item
                                        rounded
                                      "
                                      :class="
                                        adminType == value.key
                                          ? 'bg-gray-100 font-semibold'
                                          : ''
                                      "
                                      @click="adminType = value.key"
                                    >
                                      {{ $t(value.textCode) || value.text }}
                                    </div>
                                  </div>
                                </template>
                              </vig-dropdown>
                            </div>
                          </template>
                        </SynInput>
                        <template #checking>
                          <div
                            v-if="isCheckingEmail"
                            class="w-4 flex-center absolute right-40 top-3"
                          >
                            <SynIcon
                              name="spinner"
                              class="animate-spin fill-current"
                            />
                          </div>
                        </template>
                        <template #valid>
                          <div
                            v-if="
                              !isCheckingEmail && validationResultEmail.isValid
                            "
                            class="w-4 flex-center absolute right-40 top-3"
                          >
                            <SynIcon name="check" class="text-green-500" />
                          </div>
                        </template>
                      </SynFormInput>
                    </div>

                    <div
                      v-if="isEnabledEnter"
                      class="
                        flex
                        items-center
                        justify-end
                        space-x-4
                        text-xs
                        w-full
                        py-2
                      "
                    >
                      <span class="text-current">
                        {{
                          $t('COMMON_LABEL_PRESS_ENTER_TO_ADD') ||
                          'press ENTER to add'
                        }}
                      </span>

                      <SynButton
                        :style="
                          isEnabledEnter
                            ? 'visibility:visible'
                            : 'visibility:hidden'
                        "
                        :label="$t('COMMON_LABEL_ADD')"
                        @click="onAddInvite"
                      />
                    </div>
                  </div>
                </div>

                <!-- LIST INVITE -->
                <div class="mt-8 w-full flex flex-col space-y-1">
                  <div
                    v-for="(data, index) of emailPhoneInvites"
                    :key="data"
                    class="
                      relative
                      px-2
                      rounded
                      shadow
                      bg-gray-50
                      text-sm
                      flex
                      items-center
                      space-x-2
                    "
                  >
                    <div class="flex-1 flex justify-between pr-8">
                      <span class="flex-center space-x-2">
                        <span>
                          {{ data.emailOrPhone }}
                        </span>
                        <SynIcon
                          v-if="
                            data?.adminType !== 2 &&
                            index + 1 > maximumMembersAddable &&
                            isExceedTotalMemberInvite
                          "
                          name="info"
                          custom-class="w-4 h-4 fill-red-500"
                        />
                      </span>
                      <!-- <i class="px-2">
                        (
                        {{
                          data.adminType == 1
                            ? 'Admin'
                            : data.adminType == 2
                            ? $t('COMMON_LABEL_VISITOR')
                            : 'User'
                        }}
                        )
                      </i> -->
                      <vig-dropdown placement="bottom-end">
                        <template #dropdown-toggle>
                          <button
                            class="
                              text-sm
                              bg-gray-50
                              p-2
                              text-gray-800
                              w-36
                              h-9
                              rounded
                              cursor-pointer
                              hover:bg-gray-200
                              mr-1
                            "
                          >
                            <div
                              class="
                                w-full
                                h-full
                                flex
                                items-center
                                justify-between
                              "
                            >
                              <span>
                                {{
                                  $t(
                                    adminTypeList[`role_${data.adminType}`]
                                      .textCode
                                  ) ||
                                  adminTypeList[`role_${data.adminType}`].text
                                }}
                              </span>
                              <SynIcon
                                :name="
                                  isShowOptions ? 'arrow-up' : 'arrow-down'
                                "
                                custom-class="w-2 h-2 fill-gray-500 text-gray-400"
                              />
                            </div>
                          </button>
                        </template>
                        <template #dropdown-menu>
                          <div class="p-2 w-60 rounded-lg flex flex-col">
                            <div
                              v-for="(value, index) in adminTypeList"
                              :key="index"
                              class="
                                list-li
                                cursor-pointer
                                dropdown-item
                                rounded
                              "
                              :class="
                                data.adminType == value.key
                                  ? 'bg-gray-100 font-semibold'
                                  : ''
                              "
                              @click="data.adminType = value.key"
                            >
                              {{ $t(value.textCode) || value.text }}
                            </div>
                          </div>
                        </template>
                      </vig-dropdown>
                    </div>
                    <SynIcon
                      class="absolute right-2"
                      name="close"
                      @click="onRemoveItem(data)"
                    />
                  </div>
                </div>
              </div>

              <!-- FOOTER -->
              <div class="flex flex-col pt-12 space-y-2">
                <div
                  v-if="
                    currentOrgPlan.stateCode === EPlanState.OVERDUE_TRIAL ||
                    currentOrgPlan.stateCode === EPlanState.OVERDUE_PAID
                  "
                  class="
                    bg-orange-100
                    px-4
                    py-1
                    rounded
                    shadow
                    text-orange-500 text-sm
                  "
                >
                  <span
                    class="text-orange-500 text-sm"
                    v-html="
                      $t(uiBySubState?.message?.title, {
                        planId: currentOrgPlan?.planId,
                      })
                    "
                  ></span>
                </div>
                <div
                  v-else-if="
                    !isInitLoading &&
                    isExceedTotalMemberInvite &&
                    emailPhoneInvites?.filter((x) => x.adminType !== 2)
                      ?.length > 0
                  "
                  class="
                    bg-orange-100
                    px-4
                    py-1
                    rounded
                    shadow
                    text-orange-500 text-sm
                    inline
                  "
                >
                  <p
                    v-if="
                      currentOrgPlan?.planId == EPlanId.FREE ||
                      currentOrgPlan?.planId == EPlanId.BASIC
                    "
                    class="text-orange-500 text-sm"
                    v-html="
                      $t('PLAN_EXCEED_INVITE_TOTAL_MEMBER_FOR_FREE_PLAN', {
                        total: totalPremiumMembers || 0,
                      })
                    "
                  ></p>
                  <p
                    v-else
                    class="text-orange-500 text-sm"
                    v-html="
                      $t('PLAN_EXCEED_INVITE_TOTAL_MEMBER_FOR_PLAN', {
                        planId: currentOrgPlan?.planId,
                        note:
                          totalInvitedMembers > 0
                            ? `(${$t(
                                'USER_MANAGEMENT_INCLUDE_TOTAL_WAITING_INVITATION',
                                {
                                  total: totalInvitedMembers || 0,
                                }
                              )})`.toLowerCase()
                            : '',
                      })
                    "
                  ></p>
                </div>
                <div
                  v-else-if="
                    !isInitLoading &&
                    isExceedTotalVisitorInvite &&
                    emailPhoneInvites?.filter((x) => x.adminType === 2)
                      ?.length > 0
                  "
                  class="
                    bg-orange-100
                    px-4
                    py-1
                    rounded
                    shadow
                    text-orange-500 text-sm
                  "
                >
                  <span
                    class="text-orange-500 text-sm"
                    v-html="
                      $t('PLAN_EXCEED_INVITE_TOTAL_VISITOR_FOR_PLAN', {
                        member: limitVisitors,
                      })
                    "
                  >
                  </span>
                </div>
                <!-- ACTION -->
                <div class="flex items-center justify-between px-2">
                  <div class="flex items-end">
                    <span
                      v-if="pendingUsersUpToDate?.length > 0"
                      class="
                        hover:text-current-500 hover:underline
                        text-sm
                        cursor-pointer
                      "
                      @click="isShowPendingUserModal = true"
                    >
                      {{
                        $t('ADMIN_LABEL_VIEW_THE_LIST_OF_PENDING_INVITATIONS')
                      }}
                    </span>
                  </div>
                  <div class="flex items-center space-x-4">
                    <!-- Todo: Need to change to slot when Setup team -->

                    <span
                      class="text-sm hover:text-current"
                      :class="
                        !isFetchingInvite
                          ? 'cursor-pointer'
                          : 'cursor-not-allowed'
                      "
                      @click="!isFetchingInvite && $emit('onCancel')"
                    >
                      {{ $t(cancelCode) }}
                    </span>

                    <!-- OVERDUE_TRIAL & OVERDUE_PAID -->
                    <SynButton
                      v-if="
                        currentOrgPlan.stateCode === EPlanState.OVERDUE_TRIAL ||
                        currentOrgPlan.stateCode === EPlanState.OVERDUE_PAID
                      "
                      :is-loading="isCreatingToken"
                      @click="makeNewPayment"
                    >
                      <span>
                        {{ $t(uiByState?.action?.defaultActionLabelCode) }}
                      </span>
                    </SynButton>

                    <!-- PAID -->
                    <template
                      v-else-if="
                        !isInitLoading &&
                        emailPhoneInvites?.filter((x) => x.adminType !== 2)
                          ?.length > 0 &&
                        isExceedTotalMemberInvite
                      "
                    >
                      <SynButton
                        v-if="
                          currentOrgPlan?.planId == EPlanId.FREE ||
                          currentOrgPlan?.planId == EPlanId.BASIC
                        "
                        :is-loading="isCreatingToken"
                        @click="isShowPlansOverview = true"
                      >
                        <span class="flex-center space-x-2">
                          <SynIcon
                            name="crown-fill"
                            :custom-class="'w-3 h-3'"
                            :style="{
                              fill: uiBySubState?.style?.contrastColor,
                            }"
                          />
                          <span class="text-sm font-normal">
                            {{ $t('PAYMENT_LABEL_SELECT_PLAN') }}
                          </span>
                        </span>
                      </SynButton>
                      <SynButton
                        v-else
                        :is-loading="isCreatingToken"
                        @click="handleBuyMoreUser"
                      >
                        <span>
                          {{ $t('PAYMENT_BUY_MORE_USER') || 'Invite' }}
                        </span>
                      </SynButton>
                    </template>
                    <SynButton
                      v-else
                      :is-loading="isFetchingInvite"
                      :disabled="isDisabledInvite || isExceedTotalVisitorInvite"
                      @click="onFetchInviteUsers"
                    >
                      <span>
                        {{
                          $t('COMMON_LABEL_INVITE_YOUR_TEAM_ACTION') || 'Invite'
                        }}
                      </span>
                    </SynButton>
                  </div>
                </div>
              </div>
            </div>

            <!-- Right area -->
            <div
              class="
                hidden
                md:flex
                flex-col
                justify-center
                p-8
                w-1/3
                bg-gray-100
                relative
              "
            >
              <!-- <div class="w-full text-center absolute top-8 left-0 truncate">
                <span class="text-current text-xl">
                  {{ organizationInfo?.name }}
                </span>
              </div>

              <syn-animation name="businessTeam" stype="width: 500px;" />
              <div class="text-center font-thin text-current-800 text-2xl">
                {{ $t('INVITE_USER_LABEL_WELCOME') }}
              </div> -->
              <div class="flex flex-col w-full p-4">
                <!--  AVATAR    -->
                <div class="flex flex-col space-y-2 w-full">
                  <div class="flex flex-col space-y-1 w-max">
                    <UploadAvatarIcon
                      :avatar-thumbnail-url="
                        organizationInfo?.logoThumbnailUrl ||
                        organizationInfo?.logoUrl
                      "
                      :name="organizationInfo?.name"
                      avatar-readonly
                      rounded="full"
                      type-upload="GROUP"
                    />
                  </div>
                </div>
                <!--  GENERAL    -->
                <div class="mt-4 font-medium text-gray-700">
                  {{ organizationInfo?.name }}
                </div>
                <div
                  class="
                    rounded
                    mt-2
                    p-3
                    flex flex-col
                    space-y-4
                    text-gray-500 text-sm
                  "
                >
                  <div class="flex items-center justify-between">
                    <span>{{ $t('ORGANIZATION_LABEL_REGISTER_DATE') }}</span>
                    <span class="font-medium">
                      <SynLabelDateTime
                        format="date"
                        :datetime="organizationInfo?.registerdDate"
                      />
                    </span>
                  </div>

                  <div class="flex items-center justify-between">
                    <div class="flex-center space-x-1">
                      <span>{{ $t('COMMON_ATTACHMENT_CODE') }}</span>
                      <SynIcon
                        v-vig-tooltip="{
                          content: `
                          <div class='p-4 w-80 text-left bg-white flex flex-col space-y-2'>
                            <span class='font-semibold text-current'>
                            ${
                              $t('COMMON_LABEL_WHAT_IS_ATTACHMENT_CODE') ||
                              'What is Attachment code?'
                            }
                            </span>
                            <span class='font-normal'>${
                              $t('COMMON_ATTACHMENT_EXPLAIN') ||
                              'User can using to join your organization'
                            }</span>
                          </div>`,
                          arrow: true,
                          placement: 'bottom',
                          delay: 0,
                          theme: 'light',
                          animation: 'scale',
                        }"
                        name="question"
                        custom-class="h-3.5 w-3.5 fill-gray-500 hover:fill-current cursor-default"
                      />
                    </div>
                    <div class="flex space-x-1 items-center">
                      <span
                        class="
                          font-medium
                          px-3
                          h-6
                          bg-gray-200
                          flex-center
                          rounded
                        "
                        >{{
                          attachmentCode || organizationInfo?.attachmentCode
                        }}</span
                      >
                      <div
                        class="
                          flex-center
                          w-6
                          h-6
                          p-1
                          border
                          fill-gray-500
                          text-gray-500
                          rounded
                          cursor-pointer
                          hover:bg-gray-100
                        "
                        @click="onCopyAttachmentCode"
                      >
                        <SynIcon name="copy" custom-class="w-3.5 h-3.5" />
                      </div>
                      <div
                        class="
                          flex-center
                          w-6
                          h-6
                          p-1
                          border
                          fill-gray-500
                          text-gray-500
                          rounded
                          cursor-pointer
                          hover:bg-gray-100
                        "
                        @click="onGenerateAttachmentCode"
                      >
                        <SynIcon name="reload" custom-class="w-3 h-3" />
                      </div>
                    </div>
                  </div>

                  <div class="flex items-center justify-between">
                    <span>{{
                      $t('TITLE_TOTAL_ACTIVE_MEMBERS') +
                      ` (${organizationInfo?.members?.length})`
                    }}</span>
                    <SynListAvatar
                      v-if="organizationInfo?.members?.length > 0"
                      :users="organizationInfo?.members"
                      :custom-class="'w-6 h-6 text-xs'"
                      :max-length="5"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!isHiddenClose"
          v-vig-tooltip="$t('COMMON_LABEL_CLOSE')"
          class="
            w-8
            h-8
            bg-gray-200
            rounded-full
            cursor-pointer
            hover:text-current-500 hover:bg-gray-100
            absolute
            -top-2
            -right-2
            flex-center
          "
          @click="$emit('onCancel')"
        >
          <SynIcon name="close" custom-class="w-4 h-4" />
        </div>
      </div>
    </div>
  </teleport>
  <PlansOverviewModal
    v-if="isShowPlansOverview"
    @on-close="isShowPlansOverview = false"
  />

  <SynModal
    v-if="isShowPendingUserModal"
    z-index="z-60"
    @cancel="isShowPendingUserModal = false"
  >
    <template #header>
      <div class="flex items-center">
        <span class="px-2">{{
          $t('ADMIN_LABEL_LIST_OF_PENDING_INVITATIONS')
        }}</span>
      </div>
    </template>
    <template #body>
      <PendingUsers ref="pendingUsersRef" />
    </template>
  </SynModal>
</template>
