<script setup lang="ts">
import SynTh from '@/ui/common/molecules/SynTableCustom/SynTh.vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import SynTableCustom from '@/ui/common/molecules/SynTableCustom/SynTableCustom.vue';
import SynTableTrLoading from '@/ui/common/molecules/SynTableCustom/SynTableTrLoading.vue';
import SynAnimation from '@/ui/common/atoms/SynAnimation.vue';
import userStore from '@/store/user';
import { computed, reactive, ref } from 'vue';
import {
  UserActivitiesType,
  UserSubActivitiesType,
} from '@/ui/common/constants/constant';
import _ from 'lodash';
import appStore from '@/store/app';
import FilterPlatformUserActivities from '@/ui/pages/overview/working-time/FilterPlatformUserActivities.vue';
import VigButton from '@/ui/common/atoms/VigButton/VigButton.vue';
import ButtonViewLocationMap from '@/ui/components/map/ButtonViewLocationMap.vue';

const emit = defineEmits(['cancel']);
const props = defineProps<{
  userId: number;
  userName: string;
}>();

const _userStore = userStore();
const _appStore = appStore();

const lang = computed(() => _appStore.language);
const queryActivity = computed(() => _userStore.queryActivity);
const totalDocument = computed(() => _userStore.activities.totalDocument);
const activitiesDatas = computed(() => _userStore.activities.datas);

const loadMore = ref(false);
const activitiesList = ref([] as any);
const platformSelect = ref('');

const loadingData = reactive<{
  init: boolean;
  loadMore: boolean;
}>({ init: true, loadMore: false });

const initComponent = async () => {
  try {
    loadingData.init = true;
    queryActivity.value.userId = props.userId;
    queryActivity.value.pageIndex = 0;
    queryActivity.value.pageSize = 20;
    queryActivity.value.activity = UserActivitiesType.StartWorking;
    queryActivity.value.subActivity = UserSubActivitiesType.Start;
    queryActivity.value.platform = platformSelect.value;
    await _userStore.getUserActivities();
    activitiesList.value = activitiesDatas.value;
    loadMore.value = activitiesList.value.length < totalDocument.value;
  } catch (e) {
    console.log(e);
  } finally {
    loadingData.init = false;
  }
};

const onLoadMore = async () => {
  if (loadingData.loadMore) return;
  try {
    loadingData.loadMore = true;
    queryActivity.value.pageIndex += queryActivity.value.pageSize;
    await _userStore.getUserActivities();
    activitiesList.value = _.concat(
      activitiesList.value,
      activitiesDatas.value
    );
    loadMore.value = activitiesList.value.length < totalDocument.value;
  } catch (e) {
    console.log(e);
  } finally {
    loadingData.loadMore = false;
  }
};

const onReload = () => {
  initComponent();
};

const onCancel = () => {
  emit('cancel', false);
};

initComponent();
</script>

<template>
  <SynModal
    z-index="z-50"
    container-class="w-max h-max"
    style-body="mt-0 pl-2 overflow-hidden"
    disable-click-outside
    is-hidden-footer
    container-style="width: 60rem; height: 40rem; max-height: 90%"
    @cancel="onCancel"
  >
    <template #header>
      <div class="w-full flex items-center space-x-1">
        <span class="text-base w-max">
          {{
            $t('USERMANAGEMENT_LABEL_ACTIVITIES_OF_USER', { user: userName })
          }}
        </span>
        <span>({{ totalDocument }})</span>
      </div>
    </template>
    <template #body>
      <SynTableCustom class="rounded">
        <template #header>
          <SynTr>
            <SynTh :label="$t('CALENDAR_TITLE_DAY')" class="w-24"></SynTh>
            <SynTh :label="$t('CALENDAR_LABEL_TIME')" class="w-24"></SynTh>
            <SynTh
              :label="$t('USERMANAGEMENT_LABEL_ACTIVITY')"
              class="w-36"
            ></SynTh>
            <SynTh :label="$t('USERMANAGEMENT_LABEL_PLATFORM')" class="w-32">
              <template #custom>
                <FilterPlatformUserActivities
                  @on-choose-platform="
                    (value) => [(platformSelect = value), initComponent()]
                  "
                />
              </template>
            </SynTh>
            <SynTh :label="$t('COMMOM_LABEL_LOCATION')"></SynTh>
            <SynTh
              :label="$t('USERMANAGEMENT_LABEL_IP_ADDRESS')"
              class="w-32"
            ></SynTh>
            <SynTh class="w-12">
              <button
                v-vig-tooltip="$t('NOTIFICATION_RELOAD') || 'Reload'"
                class="
                  flex-center
                  rounded-full
                  hover:fill-current
                  fill-gray-500
                  w-6
                  h-6
                "
                @click="onReload"
              >
                <SynIcon name="reload" class="w-4 h-4" />
              </button>
            </SynTh>
          </SynTr>
        </template>
        <template #body>
          <SynTableTrLoading v-if="loadingData.init">
            <div v-for="item in 7" :key="item" class="table-cell p-2">
              <div class="p-2 w-3/4 bg-gray-200 rounded-md"></div>
            </div>
          </SynTableTrLoading>

          <template
            v-for="(activity, index) in activitiesList"
            v-else-if="activitiesList.length > 0"
            :key="index"
          >
            <SynTr
              class="group/user-activity"
              :class="index % 2 == 0 ? 'bg-gray-50' : ''"
              style="height: 40px"
            >
              <SynTd>
                <div class="">
                  {{
                    $filters.dayjsUtc(
                      activity?.activityDate,
                      lang == 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY'
                    )
                  }}
                </div>
              </SynTd>
              <SynTd>
                <div class="flex-center" style="width: 70px">
                  {{
                    $filters.dayjsGetTime(
                      activity?.activityDate,
                      lang == 'en' ? 'hh:mm a' : 'HH:mm'
                    )
                  }}
                </div>
              </SynTd>
              <SynTd style="">
                <div
                  v-if="
                    activity.activity == UserActivitiesType.StartWorking &&
                    activity.subActivity == UserSubActivitiesType.Start
                  "
                  style="width: 120px"
                >
                  <span
                    class="
                      px-2
                      py-1
                      bg-white
                      text-current-500
                      font-medium
                      text-center
                      rounded
                      text-xs
                      border border-current
                    "
                    >{{ $t('HEADER_LABEL_START_WD') }}</span
                  >
                </div>
              </SynTd>
              <SynTd>
                <div
                  class="
                    px-2
                    py-1
                    rounded
                    flex-center
                    text-white text-xs
                    font-medium
                  "
                  :class="
                    activity?.platform == 'WEB' ? 'bg-current' : 'bg-green-400'
                  "
                  style="width: 70px"
                >
                  {{ activity?.platform }}
                </div>
              </SynTd>

              <SynTd>
                <div
                  v-if="activity?.location?.address"
                  class="flex items-center space-x-1"
                >
                  <div
                    class="text-overflow-hidden-line"
                    :title="activity?.location?.address"
                  >
                    {{ activity?.location?.address }}
                  </div>
                  <div class="w-5 invisible group-hover/user-activity:visible">
                    <ButtonViewLocationMap :position="activity?.location">
                      <VigButton
                        v-vig-tooltip="
                          $t('SETTING_SW_CONDITION_POSITION_VIEW_MAP')
                        "
                        light
                        color="gray"
                        padding="p-1"
                        icon="MapPin"
                      />
                    </ButtonViewLocationMap>
                  </div>
                </div>
                <div
                  v-else-if="activity?.region && activity?.region !== '-'"
                  class="flex items-center space-x-1"
                >
                  <div class="text-overflow-hidden-line">
                    {{ activity?.region }} ({{ activity?.country }})
                  </div>
                  <div class="w-5 invisible group-hover/user-activity:visible">
                    <ButtonViewLocationMap :position="activity?.location">
                      <VigButton
                        v-vig-tooltip="
                          $t('SETTING_SW_CONDITION_POSITION_VIEW_MAP')
                        "
                        light
                        color="gray"
                        padding="p-1"
                        icon="MapPin"
                      />
                    </ButtonViewLocationMap>
                  </div>
                </div>
                <div v-else class="mx-2">-</div>
              </SynTd>

              <SynTd>
                <div>{{ activity?.ipAddress }}</div>
              </SynTd>

              <SynTd></SynTd>
            </SynTr>
          </template>
          <template v-else>
            <SynTr class="h-full" hover="hover:bg-current-50">
              <SynTd :colspan="7" style="height: calc(100vh - 40rem)">
                <SynAnimation
                  name="searchIcon"
                  stype="width: 150px; height: 150px"
                  :label="$t('USERMANAGEMENT_LABEL_NO_ACTIVITY')"
                />
              </SynTd>
            </SynTr>
          </template>
          <div
            v-if="loadMore && !loadingData.init"
            class="
              ml-1
              my-2
              px-2
              py-1
              border border-gray-300
              text-gray-500
              rounded
              flex-center
              cursor-pointer
              hover:bg-gray-50
              space-x-1.5
              w-max
            "
            @click="onLoadMore"
          >
            <span>{{ $t('COMMON_LABEL_VIEW_MORE') }}</span>
            <SynIcon
              v-if="loadingData.loadMore"
              name="spinner"
              class="fill-gray-500 border-none animate-spin"
              custom-class="w-3.5 h-3.5"
            />
            <SynIcon
              v-else
              name="down-arrow"
              class="fill-gray-500"
              custom-class="h-3.5 w-3.5"
            />
          </div>
        </template>
      </SynTableCustom>
    </template>
  </SynModal>
</template>
