<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
import ChatFiles from '@/ui/modules/messaging/chat-panel/chat-form/chat-files/ChatFiles.vue';
import ChatAudio from '@/ui/modules/messaging/chat-panel/chat-form/chat-audio/ChatAudio.vue';
import ChatReplying from '@/ui/modules/messaging/chat-panel/chat-form/chat-replying/ChatReplying.vue';
import ChatLinks from '@/ui/modules/messaging/chat-panel/chat-form/chat-link/ChatLinks.vue';
import ChatInput from '@/ui/common/plugins/ckeditor/ChatInput.vue';
import ChatTask from '@/ui/modules/messaging/chat-panel/chat-form/chat-task/ChatTask.vue';
import CreateNoteModal from '@/ui/modules/task/detail/CreateNoteModal.vue';
import ModalFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalFileViewer.vue';
import DayoffListItem from '@/ui/components/dayoff/atoms/DayoffListItem.vue';
import { dayoffIdGlobal, eventIdGlobal } from '@/ui/app-global-state';
import chatStore from '@/store/chat';
import { DayoffStatusUI } from '@/ui/components/dayoff/atoms/dayoff-ui-constant';
import {
  _prepareMsgDayOff,
  _prepareMsgEvent,
  _prepareMsgTask,
  _processMsgTextBlankLine,
} from '@/ui/modules/messaging/chat-panel/chat-form/parser-msg-discuss';
import ButtonSend from '@/ui/modules/messaging/chat-panel/chat-form/button-send/ButtonSend.vue';
import { onBeforeUnmount } from '@vue/runtime-core';
import { generateRandomString } from '@/ui/helpers/utils';
import useLimitation from '@/ui/composables/limitation/limitation-composable';
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';
import {
  isShowTaskDetailGlobal,
  taskCodeGlobal,
} from '@/ui/modules/task/task-global-state';
import taskStore from '@/store/task';
import EventListItem from '@/ui/components/calendars/atoms/EventListItem.vue';
import PostMessageService from '@/application/services/PostMessageService';
import { AppMessageTypeEnum } from '@/domain/enums/app-message-type.enum';

const props = defineProps<{
  size?: 'sm';
  organizationId?: number;
  conversationId?: string;
  conversation?: any;
  members?: any;
  isNotFocus?: boolean;
  task?: any;
  readonly?: boolean;
  isEmbeddedApp?: boolean;
}>();

const emit = defineEmits([
  'onSendMessage',
  'onTyping',
  'onMessageChange',
  'onHaveActionChangeForm',
]);

const _chatStore = chatStore();
const _useLimitation = useLimitation('CHAT');
const _taskStore = taskStore();

const formId = generateRandomString(8).toLowerCase();

const inputRef = ref(null as any);
const chatAudioRef = ref(null as any);
const message = ref({
  text: '',
  files: [],
  replyTo: null,
  task: props?.task ? props?.task : null,
  mentionUserIds: [],
  linkObjects: [],
} as any);

const replyToMsg = ref(null);
const isOpenNote = ref(false);
const isRecordingAudio = ref(false);
const noteEditData = ref();
const fileViewer = ref();
const isOpenReactiveModal = ref(false);
const isCollapseAction = ref(false);

let _typingTimer, _isTyping;

onMounted(() => {
  _focusInputChat();

  if (props.task?.id) {
    setTask(props.task);
  }
});

onBeforeUnmount(() => {
  chatAudioRef.value?.cancelRecord();
});

onUnmounted(() => {
  _resetTyping();
});

// TEXT
const onChangeMessageText = () => {
  emit('onMessageChange', message.value);

  _processTyping();

  if (props.size == 'sm' && !isOpenReactiveModal.value) {
    isCollapseAction.value = !!message.value?.text;
  }
};

const onTextboxBlur = () => {
  _resetTyping();
};

const onEmojiSelected = (emoji) => {
  inputRef.value?.insertEmoji(emoji);
};

// FILE
const onFilesSelected = (event) => {
  _processSelectedFiles(Array.from(event?.target?.files));

  event.target.value = null;

  _focusInputChat();
};

const onFileClick = (file) => {
  // Click note
  if (file?.drawData) {
    noteEditData.value = file;

    if (props.isEmbeddedApp) {
      return PostMessageService.getInstance().postMessageToParent({
        type: AppMessageTypeEnum.tictopNoteClick,
        data: noteEditData.value,
      });
    }

    return (isOpenNote.value = true);
  }

  // Click file
  fileViewer.value = {
    name: file?.name,
    type: file?.type,
    url_full: URL.createObjectURL(file),
  };
};

const onFileDelete = (file) => {
  message.value.files.splice(message.value.files.indexOf(file), 1);

  emit('onMessageChange', message.value);

  _focusInputChat();
};

// NOTE
const onOpenNote = () => {
  _verifyOpenNote();
};

const onSaveNote = (data) => {
  if (!data || !message.value) return;
  // Case edit note
  if (noteEditData.value) {
    const fileIdx = (message.value.files || []).findIndex(
      (file) => file?.name === noteEditData.value.name
    );

    if (fileIdx > -1) {
      message.value.files[fileIdx].url = data.base64;
      message.value.files[fileIdx].url_full = data.base64;
      message.value.files[fileIdx].size = atob(
        data.base64?.split(';base64,').pop()
      ).length;
      message.value.files[fileIdx].drawData = data.drawData;
    }
  }
  // Case add new note
  else {
    const newNote = {
      name: _chatStore.genFileName('Note', 'jpeg', props?.conversationId),
      type: 'image/jpeg',
      url: data.base64,
      url_full: data.base64,
      size: atob(data.base64?.split(';base64,').pop()).length,
      drawData: data.drawData,
    };

    message.value.files = [...message.value.files, newNote];
  }

  noteEditData.value = null;
  isOpenNote.value = false;

  emit('onMessageChange', message.value);
  _focusInputChat();
};

// AUDIO
const onChangeRecording = (isRecording) => {
  isRecordingAudio.value = isRecording;

  emit('onHaveActionChangeForm', isRecording);
};

const onRecordSubmit = (audio) => {
  audio.name = _chatStore.genFileName('Audio', 'mp3', props?.conversationId);
  // const newMessage = { ...message.value };
  // message.value = {
  //   text: '',
  //   files: [],
  //   replyTo: null,
  //   task: null
  // };
  //
  // let text = '';
  //
  // if (newMessage?.task) {
  //   text = _prepareMsgTask(newMessage, props.members)?.task;
  //   return;
  // }
  //
  // emit('onSendMessage', { files: [audio], text });

  message.value.files = (message.value.files || []).concat([audio]);

  onSubmit();
};

// REPLY
const onReplyToDelete = () => {
  message.value.replyTo = null;

  replyToMsg.value = null;

  emit('onMessageChange', message.value);
  _focusInputChat();
};

// TASK
const onTaskDelete = () => {
  message.value.task = null;

  emit('onMessageChange', message.value);

  _focusInputChat();
};

// DAYOFF
const onDeleteDayoff = () => {
  message.value.dayoff = null;

  emit('onMessageChange', message.value);

  _focusInputChat();
};

const onOpenDayoffDetail = (dayoffId) => {
  if (!dayoffId) return;
  dayoffIdGlobal.value = dayoffId;
};

// EVENT
const onDeleteEvent = () => {
  message.value.eventData = null;

  emit('onMessageChange', message.value);

  _focusInputChat();
};
const onOpenEventDetail = (eventId) => {
  if (!eventId) return;
  eventIdGlobal.value.isShow = true;
  eventIdGlobal.value.id = eventId;
};

// LINK
const onGetLinkObjects = async (objectLinks) => {
  if (!message.value.linkObjects) {
    message.value.linkObjects = [] as any[];
  } else {
    message.value.linkObjects = message.value.linkObjects.filter((obj) =>
      objectLinks.some((el) => el?.link === obj?.link)
    );
  }
  const newLinks = objectLinks
    .map((u) => u.link)
    .filter((link) => !message.value.linkObjects.some((o) => o?.link == link))
    .map((elLink: any) => {
      let domainUrl = new URL(elLink);
      let domain = domainUrl.hostname.replace('www.', '');
      return {
        link: elLink,
        title: domain,
        isLoading: true,
        previewData: {
          isPreview: false,
        },
      };
    });

  message.value.linkObjects = [...message.value?.linkObjects, ...newLinks];

  for (const link of newLinks) {
    const dataPreview = ref();
    try {
      dataPreview.value = await _chatStore.getPreviewLinkInMessage(link?.link);
      let index = message.value?.linkObjects.findIndex(
        (el) => el.link === link?.link
      );

      if (index == -1) continue;
      message.value.linkObjects[index] = {
        ...link,
        title: dataPreview?.value?.title || link?.link,
        isLoading: false,
        previewData: {
          isPreview: !!dataPreview?.value?.title,
          description: dataPreview?.value?.title || '',
          title: dataPreview?.value?.title || link?.title,
          imageUrl: dataPreview?.value?.imageUrl || '',
          domain: dataPreview?.value?.domain || link?.title,
        },
      };
    } catch (error) {
      continue;
    }
  }
};

const onClosePreviewLink = (link) => {
  // console.log('onClosePreviewLink', link);
  let index = message.value?.linkObjects.findIndex((el) => el.link === link);
  if (index == -1) return;
  message.value.linkObjects[index].previewData.isPreview = false;
  message.value.linkObjects[index].isLoading = false;
};

// SUBMIT
const onSubmit = async () => {
  const msgText = _processMsgTextBlankLine(message.value?.text);

  if (
    !msgText &&
    !message.value?.files?.length &&
    !message.value?.task &&
    !message.value?.dayoff &&
    !message.value?.eventData
  ) {
    return;
  }

  const newMessage = { ...message.value };

  if (message.value?.text) newMessage.text = msgText;

  if (newMessage?.task) {
    newMessage.comment = newMessage?.text;
    newMessage.text =
      _prepareMsgTask(newMessage, props.members)?.all ||
      _prepareMsgTask(newMessage, props.members)?.task;
  }

  if (newMessage?.dayoff) {
    newMessage.comment = newMessage?.text;
    newMessage.text =
      _prepareMsgDayOff(newMessage, props?.members)?.all ||
      _prepareMsgDayOff(newMessage, props?.members)?.dayoff;
  }

  if (newMessage?.eventData) {
    newMessage.comment = newMessage?.text;
    newMessage.text =
      _prepareMsgEvent(newMessage)?.all ||
      _prepareMsgEvent(newMessage)?.eventData;
  }

  if (newMessage?.linkObjects?.length > 0) {
    newMessage?.linkObjects?.map((o, idx) => {
      delete o['isLoading'];
      o['index'] = idx;
      return o;
    });
  }

  emit('onSendMessage', newMessage);

  message.value = {
    text: '',
    files: [],
    replyTo: null,
    task: null,
  };

  emit('onMessageChange', message.value);

  replyToMsg.value = null;

  _resetTyping();
  _focusInputChat();
};

// EXPOSE METHODS

const focusInput = () => {
  _focusInputChat();
};

const setReplyTo = (msgId, msg) => {
  message.value = { ...message.value, replyTo: msgId, replyToMsg: msg };
  replyToMsg.value = msg;

  emit('onMessageChange', message.value);

  _focusInputChat();
};

const setTask = (task) => {
  message.value = { ...message.value, task };

  emit('onMessageChange', message.value);

  _focusInputChat();
};

const setDayoff = (dayoff) => {
  message.value = { ...message.value, dayoff };

  emit('onMessageChange', message.value);

  _focusInputChat();
};

const setEvent = (event) => {
  message.value = { ...message.value, eventData: event };

  emit('onMessageChange', message.value);

  _focusInputChat();
};

const setMessage = (mes) => {
  // if (!_processSelectedFiles([])) return;

  message.value = mes || {
    text: '',
    files: [],
    replyTo: null,
    task: null,
  };

  replyToMsg.value = mes?.replyToMsg;

  _focusInputChat();
};

const addFiles = (files) => {
  _processSelectedFiles(files);

  _focusInputChat();
};

const openNote = () => {
  _verifyOpenNote();
};

const setNote = (note) => {
  onSaveNote(note);
};

// PRIVATE METHODS

const _processTyping = () => {
  if (!_isTyping && message.value?.text) {
    emit('onTyping', (_isTyping = true));
  }

  // Set non typing after 5 seconds
  clearTimeout(_typingTimer);
  _typingTimer = setTimeout(() => {
    _resetTyping();
  }, 5000);
};

const _resetTyping = () => {
  _isTyping && emit('onTyping', (_isTyping = false));
};

const _focusInputChat = () => {
  inputRef.value?.focusToEnd(message.value?.text || '');
};

const _processSelectedFiles = (files) => {
  if (!files?.length) return true;

  const currentFiles = message.value?.files?.filter((file) => !file?.drawData);

  const validFiles = _useLimitation.verifySelectedFiles(currentFiles, files);

  if (validFiles?.length) {
    message.value.files = [
      ...(message.value.files || []),
      ...(validFiles || []),
    ];
  }

  emit('onMessageChange', message.value);

  return !!validFiles?.length;
};

const _verifyOpenNote = () => {
  const currentNotes = message.value?.files?.filter((file) => file?.drawData);

  const isValid = _useLimitation.verifyNote(currentNotes);

  if (isValid) {
    if (props.isEmbeddedApp) {
      if (props.isEmbeddedApp) {
        return PostMessageService.getInstance().postMessageToParent({
          type: AppMessageTypeEnum.tictopNoteClick,
        });
      }
    }

    isOpenNote.value = true;
  }
};

const onTaskClick = (taskCode) => {
  taskCodeGlobal.value = taskCode;
  isShowTaskDetailGlobal.value = true;

  // taskDrawerStore().pushCurrentIds({
  //   id: task?.id,
  //   code: task?.code,
  //   name: task?.name,
  //   tabType: 'DETAIL',
  // });
};

const onFocus = () => {
  _taskStore.getCurrentTaskCycleForChat(props.organizationId);
};

const setStopRecording = (blob) => {
  isRecordingAudio.value = true;

  chatAudioRef.value.setStopRecording(blob);
};

defineExpose({
  focusInput,
  setReplyTo,
  setTask,
  setMessage,
  setEvent,
  addFiles,
  openNote,
  setDayoff,
  setNote,
  setStopRecording,
});
</script>

<template>
  <form
    class="bg-white shadow chat-form"
    style="box-shadow: -5px 0px 5px #e5e7eb"
    @submit.prevent="onSubmit"
  >
    <!--REPLY TO-->
    <ChatReplying
      v-if="message?.replyTo"
      :reply-to-msg="replyToMsg"
      :members="members"
      :size="size"
      @on-reply-to-delete="onReplyToDelete"
    />

    <!--TASK-->
    <ChatTask
      v-if="message?.task"
      class="pt-1"
      :task="message?.task"
      :size="size"
      :readonly="readonly"
      @on-delete="onTaskDelete"
      @on-task-click="onTaskClick"
    />

    <!--DAY OFF-->
    <div
      v-if="message?.dayoff"
      class="
        relative
        w-max
        m-2
        border border-orange-500
        bg-white
        shadow-sm
        rounded
        cursor-pointer
        max-w-md
        xl:min-w-xs
      "
      :style="{
        borderColor: DayoffStatusUI[message?.dayoff?.status]?.color,
      }"
      @click="onOpenDayoffDetail(message?.dayoff?.id)"
    >
      <DayoffListItem
        is-show-title
        is-hidden-name
        :dayoff-data="message?.dayoff"
      />
      <div class="absolute -right-2 -top-2 rounded-full bg-white">
        <button
          type="button"
          class="
            rounded-full
            bg-gray-700 bg-opacity-50
            text-white
            hover:bg-opacity-100
            w-5
            h-5
            flex-center
          "
          @click="onDeleteDayoff"
        >
          <SynIcon name="Close" custom-class="fill-white w-4 h-4" />
        </button>
      </div>
    </div>

    <!--    EVENT -->
    <div
      v-if="message?.eventData"
      class="
        relative
        w-max
        m-2
        border border-purple-500
        bg-white
        shadow-sm
        rounded
        cursor-pointer
        max-w-md
        xl:min-w-xs
      "
      @click="onOpenEventDetail(message?.eventData?.id)"
    >
      <EventListItem :event-data="message?.eventData" />
      <div class="absolute -right-2 -top-2 rounded-full bg-white">
        <button
          type="button"
          class="
            rounded-full
            bg-gray-700 bg-opacity-50
            text-white
            hover:bg-opacity-100
            w-5
            h-5
            flex-center
          "
          @click="onDeleteEvent"
        >
          <SynIcon name="Close" custom-class="fill-white w-4 h-4" />
        </button>
      </div>
    </div>

    <!--FILES-->
    <ChatFiles
      v-if="message?.files?.length"
      :files="message.files"
      :size="size"
      @on-file-delete="onFileDelete"
      @on-file-click="onFileClick"
    />

    <!-- LINKS -->
    <template
      v-if="
        message?.linkObjects?.filter(
          (link) => link?.previewData?.isPreview || link?.isLoading
        )?.length > 0
      "
    >
      <div
        class="flex overflow-auto small-scrollbar mx-2 space-x-2"
        :class="size === 'sm' ? 'p-1 pt-2' : 'p-2 pt-3'"
      >
        <ChatLinks
          v-for="link in message?.linkObjects"
          :key="link"
          class="relative mr-3"
          :link="link"
          @on-close-preview-link="onClosePreviewLink"
        />
      </div>
    </template>

    <!-- ACTION + INPUT CHAT -->
    <div class="flex items-end">
      <template v-if="!isRecordingAudio">
        <!--ACTION COLLAPSED-->
        <div v-if="isCollapseAction" class="py-2 pl-2">
          <SynIcon
            name="ArrowRight"
            has-action
            custom-class="h-6 w-6 fill-current"
            @click="isCollapseAction = false"
          />
        </div>

        <div v-else class="py-2 pl-2 flex">
          <!--BUTTON NOTE-->
          <vig-button
            v-permission-function="{
              functionCode: ALL_FUNCTIONS.CHAT.NOTE,
              onContinue: () => {
                onOpenNote();
              },
            }"
            v-vig-tooltip="$t('TASK_CREATE_FORM_LABEL_NOTE')"
            type="button"
            ghost
            rounded="rounded-full"
            padding="p-0"
            class="w-8 h-8 hover:bg-current-50 hover:border-transparent"
            @click="onOpenNote"
          >
            <SynIcon
              name="noter"
              custom-class="w-5 h-5"
              is-active
              :class="isNotFocus ? 'fill-gray-500' : 'fill-orange-500'"
            />
          </vig-button>

          <!--BUTTON ATTACH-->
          <label :for="'chat-form-' + formId + '-attachments-file'">
            <span
              v-vig-tooltip="$t('TASK_DETAIL_LABEL_ATTACHMENT')"
              class="
                w-8
                h-8
                rounded-full
                flex-center
                cursor-pointer
                hover:bg-current-50
              "
            >
              <SynIcon
                name="Attach"
                custom-class="w-5 h-5"
                :class="isNotFocus ? 'fill-gray-500' : 'fill-current'"
              />
            </span>

            <input
              :id="'chat-form-' + formId + '-attachments-file'"
              multiple
              type="file"
              class="hidden"
              @change="onFilesSelected"
            />
          </label>

          <!--BUTTON EMOJI-->
          <VigEmojiPicker
            v-vig-tooltip="$t('COMMON_LABEL_EMOJI')"
            toggle-class="w-8 h-8 rounded-full flex-center hover:bg-current-50"
            :close-on-select="false"
            @on-emoji-click="onEmojiSelected"
            @on-open="isOpenReactiveModal = true"
            @on-close="isOpenReactiveModal = false"
          >
            <SynIcon
              name="Smile"
              custom-class="w-5 h-5"
              :class="isNotFocus ? 'fill-gray-500' : 'fill-yellow-500'"
            />
            <!-- <span v-twemoji="{ size: 'w-7 h-7' }">😀</span> -->
          </VigEmojiPicker>
        </div>

        <!--TEXT MSG-->
        <div
          class="flex-1 overflow-auto p-2 relative"
          :class="{ 'actions-collapsed': isCollapseAction }"
        >
          <ChatInput
            ref="inputRef"
            v-model="message.text"
            v-model:mentionUserIds="message.mentionUserIds"
            :conversation="conversation"
            @on-get-link-from-msg="onGetLinkObjects"
            @on-enter="onSubmit"
            @blur="onTextboxBlur"
            @update:model-value="onChangeMessageText"
            @focus="onFocus"
          />
          <section
            v-if="isCollapseAction"
            class="flex-center absolute top-2.5 right-2.5"
          >
            <!--BUTTON EMOJI-->
            <VigEmojiPicker
              v-vig-tooltip="$t('COMMON_LABEL_EMOJI')"
              toggle-class="w-8 h-8 rounded-full flex-center hover:bg-current-50"
              :close-on-select="false"
              @on-emoji-click="onEmojiSelected"
              @on-open="isOpenReactiveModal = true"
              @on-close="isOpenReactiveModal = false"
            >
              <SynIcon
                name="Smile"
                custom-class="w-5 h-5"
                :class="isNotFocus ? 'fill-gray-500' : 'fill-yellow-500'"
              />
              <!-- <span v-twemoji="{ size: 'w-7 h-7' }">😀</span> -->
            </VigEmojiPicker>
          </section>
        </div>

        <div
          v-if="
            message?.text ||
            message?.files?.length ||
            message?.task ||
            message?.dayoff
          "
          class="py-2 pr-2"
        >
          <!--BUTTON SEND-->
          <ButtonSend :is-not-focus="isNotFocus" @on-send-click="onSubmit" />
        </div>
      </template>

      <!--AUDIO MSG-->
      <div
        v-if="
          !message?.text &&
          !message?.files?.length &&
          !message?.task &&
          !message?.dayoff
        "
        :class="isRecordingAudio ? 'flex-1 p-2' : 'py-2 pr-2'"
      >
        <ChatAudio
          ref="chatAudioRef"
          :is-not-focus="isNotFocus"
          :is-embedded-app="isEmbeddedApp"
          @on-record-submit="onRecordSubmit"
          @is-recording="onChangeRecording"
        />
      </div>
    </div>
  </form>

  <CreateNoteModal
    v-if="isOpenNote"
    :draw-note-data="noteEditData || null"
    @on-close="
      isOpenNote = false;
      noteEditData = null;
    "
    @on-save="onSaveNote"
  />

  <ModalFileViewer
    v-if="fileViewer"
    :file="fileViewer"
    :name="fileViewer?.name"
    :content-type="fileViewer?.type"
    :path="fileViewer?.url_full"
    :has-download="false"
    @on-close="fileViewer = null"
  />
</template>

<style lang="scss">
@import '@/ui/plugins/ckeditor/css/content-styles.scss';
</style>
