<script setup lang="ts">
import { onMounted, ref, computed, onUnmounted, watch } from 'vue';
import appStore from '@/store/app';
import calendarsOverviewStore from '@/store/calendars/calendars-overview';
import '@/ui/components/dayoff/dayoff-calendar.scss';
import DayoffCreateForm from '@/ui/components/dayoff/DayoffCreateForm.vue';
import { fullCalendarInstance } from '@/store/dayoff/dayoff-state';
import dayoffCreate from '@/store/dayoff/dayoff-create';
import calendarStore from '@/store/calendar';
import { CalendarType, CalendarSource } from '@/ui/common/constants/calendar';
import DayoffDetail from '@/ui/components/dayoff/DayoffDetail.vue';
import DetailViewMeeting from '@/ui/modules/calendar/detail-view-event/DetailViewMeeting.vue';
import DetailViewReminder from '@/ui/modules/calendar/detail-view-event/DetailViewReminder.vue';
import DetailViewSystem from '@/ui/modules/calendar/detail-view-event/DetailViewSystem.vue';
import Draggable from '@/ui/components/calendars/Draggable.vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import EventCreateModal from '@/ui/modules/calendar/event/EventCreateModal.vue';
import ReminderCreateModal from '@/ui/modules/calendar/reminder/ReminderCreateModal.vue';
import { CalendarTypeValue } from '@/ui/common/constants/calendar';

const _appStore = appStore();
const _eventStore = calendarStore();
const _calendarsOverviewStore = calendarsOverviewStore();
const _dayoffCreate = dayoffCreate();

// import { DayoffDetailItemClass } from '@/domain/entities/dayoff/DayoffCalendarItemClass';
const language = computed(() => _appStore.language);
const tictopCalendar = ref(null as HTMLElement | null);

watch(
  () => language.value,
  () => {
    fullCalendarInstance.value.setOption('locale', language.value);
  }
);

const initData = () => {
  _calendarsOverviewStore.initDayoffCalendar(tictopCalendar.value);
};

const updateViewCalendar = () => {
  _calendarsOverviewStore.onChangeViewDateCalendar();
};

const currentDayoffDetailId = computed(
  () => _calendarsOverviewStore.currentDayoffDetailId
);

// const element = ref(_calendarsOverviewStore.currentElement);

const dynamicPositionDayoff = computed(
  () => _calendarsOverviewStore.positionDayoff
);

// const draggable = ref(null);

onMounted(() => {
  initData();
});

const openAddEventType = computed<CalendarTypeValue | null>(
  () => _eventStore.openAddEventType
);
const eventDetail = computed<any>(() => _calendarsOverviewStore.eventDetail);

// const currentDayoffDetailId = computed(
//   () => _calendarsOverviewStore.currentDayoffDetailId
// );
const isOpenView = computed(() => _calendarsOverviewStore.isOpenView);

// const onCloseDetail = () => {
//   _calendarsOverviewStore.onCloseDetail();
// };

onUnmounted(() => {
  fullCalendarInstance.value && fullCalendarInstance.value.destroy();
});

const update = () => {
  initData();
};

const isOpenCreateForm = computed(() => _dayoffCreate.isOpenCreateForm);
const positionEventDetail = computed(
  () => _calendarsOverviewStore.isPositionEventDetail
);

const onCloseCreateForm = () => {
  _dayoffCreate.onCloseCreateForm();
};

const onCreateDayoffSuccess = async () => {
  // await initData();
  updateViewCalendar();
  onCloseCreateForm();
};

const onEditMeeting = () => {
  _calendarsOverviewStore.handlePositionPopup();
};

const onCancelAdd = () => {
  _eventStore.openAddEventType = null;
};
const onCreateSuccess = () => {
  // initData();
  updateViewCalendar();
  onCancelAdd();
};

const onCancelView = (isLoadData: boolean) => {
  _calendarsOverviewStore.isOpenView.type = 0;
  _calendarsOverviewStore.isOpenView.subType = 0;
  _calendarsOverviewStore.currentDayoffDetailId = '';

  _calendarsOverviewStore.elementEvent?.removeAttribute('style');

  if (isLoadData) {
    // update();
    updateViewCalendar();
  }
};

// const updateEvent = () => {
//   _calendarsOverviewStore.isOpenView.type = 0;
//   _calendarsOverviewStore.isOpenView.subType = 0;
//   _calendarsOverviewStore.currentDayoffDetailId = '';
//   updateViewCalendar();
// };

watch(
  () => currentDayoffDetailId.value,
  () => {
    const element = document.getElementById('draggable') as HTMLElement;
    element.setAttribute('style', 'transform: none');
    element.setAttribute('data-x', dynamicPositionDayoff.value.x as any);
    element.setAttribute('data-y', dynamicPositionDayoff.value.y as any);
  }
);

watch(
  () => eventDetail.value,
  () => {
    const element = document.getElementById('draggable') as HTMLElement;
    element.setAttribute('style', 'transform: none');
    element.setAttribute('data-x', dynamicPositionDayoff.value.x as any);
    element.setAttribute('data-y', dynamicPositionDayoff.value.y as any);
  }
);

const startDateDefault = computed(
  () => _calendarsOverviewStore.defaultStartDate
);

const onCancel = () => {
  _eventStore.onOpenAddEvent(null);
};
defineExpose({
  update,
});
</script>

<template>
  <div class="flex-1 h-full relative">
    <div id="dayoff-calendar-id" v-bind="$attrs" ref="tictopCalendar"></div>
  </div>
  <!--  <DayoffDetailModal-->
  <!--    v-if="currentDayoffDetailId"-->
  <!--    :id="currentDayoffDetailId"-->
  <!--    @close="onCloseDetail"-->
  <!--  />-->

  <SynModal
    v-if="isOpenCreateForm"
    container-class="w-2/5"
    container-style="min-width: 60rem; max-width: 1200px"
    disable-click-outside
    disable-esc-to-close
    is-hidden-header
    is-hidden-footer
    style-body="p-0"
    @cancel="onCloseCreateForm"
  >
    <template #body>
      <DayoffCreateForm
        :default-data="{
          startDate: startDateDefault
            ? new Date(startDateDefault).getTime()
            : Date.now(),
        }"
        @on-create-success="onCreateDayoffSuccess"
        @cancel="onCloseCreateForm"
      />
    </template>
  </SynModal>

  <EventCreateModal
    v-if="openAddEventType == CalendarTypeValue.Meeting"
    :default-data="{
      startDate: startDateDefault
        ? new Date(startDateDefault).getTime()
        : Date.now(),
    }"
    @cancel="onCancel"
    @on-create-success="onCreateSuccess"
  />
  <ReminderCreateModal
    v-if="openAddEventType == CalendarTypeValue.Reminder"
    :default-data="{
      startDate: startDateDefault
        ? new Date(startDateDefault).getTime()
        : Date.now(),
    }"
    @cancel="onCancel"
    @on-create-success="onCreateSuccess"
  />

  <!--  <ModalViewReminder-->
  <!--    v-if="-->
  <!--      isOpenView.subType == CalendarType.Reminder &&-->
  <!--      isOpenView.type == CalendarSource.Work-->
  <!--    "-->
  <!--    :event="eventDetail"-->
  <!--    @cancel="onCancelView"-->
  <!--  />-->

  <!--  <ModalViewMeeting-->
  <!--    v-if="-->
  <!--      isOpenView.subType == CalendarType.Meeting &&-->
  <!--      isOpenView.type == CalendarSource.Work-->
  <!--    "-->
  <!--    :event="eventDetail"-->
  <!--    @cancel="onCancelView"-->
  <!--  />-->

  <!--  <ModalViewSystem-->
  <!--    v-if="isOpenView.type == CalendarSource.System"-->
  <!--    :event="eventDetail"-->
  <!--    @cancel="onCancelView"-->
  <!--  />-->

  <teleport to="body">
    <!--  dayoffDetail-->
    <Draggable
      id="draggable"
      class="shadow-2xl border border-current-100 rounded-md"
      :class="positionEventDetail ? 'opacity-100' : 'opacity-0'"
      :style="[
        `top: ${dynamicPositionDayoff?.y}px !important`,
        `left: ${dynamicPositionDayoff?.x}px !important`,
        // positionEventDetail ? 'transition : all 0.3s' : '',
      ]"
    >
      <template #move>
        <div
          v-if="
            currentDayoffDetailId ||
            (isOpenView.subType == CalendarType.Meeting &&
              isOpenView.type == CalendarSource.Work) ||
            (isOpenView.subType == CalendarType.Reminder &&
              isOpenView.type == CalendarSource.Work) ||
            isOpenView.type == CalendarSource.System
          "
          id="dayoffDetailId"
          class="bg-white rounded-md shadow-2xl w-full z-20 opacity-0"
          :class="
            positionEventDetail
              ? 'opacity-100 transition-all ease-in-out duration-150'
              : 'opacity-0'
          "
          :style="[
            `top: ${dynamicPositionDayoff?.y}px !important`,
            `left: ${dynamicPositionDayoff?.x}px !important`,
            `max-width: 960px`,
            `width: fit-content`,
            // `width: 900px`,
            `height: max-content`,
            // positionEventDetail ? 'transition : all 0.3s' : '',
          ]"
          @click.stop
        >
          <div class="w-full drag-handle h-6 bg-white"></div>

          <DayoffDetail
            v-if="currentDayoffDetailId"
            :id="currentDayoffDetailId"
            :is-screen="true"
            custom-class="pb-4 pt-1"
            @on-close="onCancelView(true)"
          />
          <DetailViewMeeting
            v-if="
              isOpenView.subType == CalendarType.Meeting &&
              isOpenView.type == CalendarSource.Work
            "
            custom-class="px-4 pb-4"
            style="width: 40rem !important"
            :event="eventDetail"
            @cancel="onCancelView(true)"
            @edit="onEditMeeting"
            @on-update="onCancelView(true)"
          />
          <DetailViewReminder
            v-if="
              isOpenView.subType == CalendarType.Reminder &&
              isOpenView.type == CalendarSource.Work
            "
            :key="eventDetail?.id"
            custom-class="px-4 pb-4"
            :event-id="eventDetail?.id"
            @cancel="onCancelView"
            @update="onCancelView(true)"
            @edit="onEditMeeting"
          />

          <DetailViewSystem
            v-if="isOpenView.type == CalendarSource.System"
            :event="eventDetail"
            @cancel="onCancelView"
          />
          <div
            :title="$t('COMMON_LABEL_CLOSE')"
            class="
              w-8
              h-8
              bg-gray-200
              rounded-full
              cursor-pointer
              hover:text-current-500 hover:bg-gray-100
              absolute
              -top-3
              -right-3
              flex-center
              vig-modal-close
            "
            @click="onCancelView(false)"
          >
            <SynIcon name="close" custom-class="w-4 h-4" />
          </div>
        </div>
      </template>
    </Draggable>
    <!--  dayoffDetail-->
  </teleport>
</template>

<style scoped></style>
