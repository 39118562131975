<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { ignoreUnicode } from '@/ui/plugins/utils';
import { chain, filter, orderBy, slice } from 'lodash';
import myProfileStore from '@/store/auth/my-profile';
import ConversationItem from '@/ui/modules/messaging/conversation-item/ConversationItem.vue';
import ModalNotificationSetting from '@/ui/components/notification/ModalNotificationSetting.vue';
import { ChatConversationModel } from '@/application/models/chat/ChatConversationModel';
import { windowFocusStatus } from '@/ui/app-global-state';
import ChatService from '@/application/services/ChatService';
import ConversationTypes from '@/ui/modules/messaging/conversations-list/conversation-types/ConversationTypes.vue';
import ConversationActions from '@/ui/modules/messaging/conversation-item/ConversationActions.vue';
import SortAble from '@/ui/components/draggable/SortAble.vue';
import { moveItemInArray } from '@/ui/helpers/utils';

const props = withDefaults(
  defineProps<{
    activeConversation?: ChatConversationModel;
    organizationId?: number;
    conversationIds?: string[];
    conversationById?: any;
    members?: any;
    canCreateNewChat?: boolean;
    canViewGroupChatTask?: boolean;
    isPersonalOrg?: boolean;
    isLoading?: boolean;
  }>(),
  {
    conversationById: {},
  }
);

const emit = defineEmits(['onNewChatClick', 'onConversationClick']);

const PAGE_SIZE = 20;

const _myProfileStore = myProfileStore();

const inputSearchRef = ref();
const conversationsListRef = ref();
const activeTab = ref<string>('CHAT-ALL');
const searchText = ref<string>('');
const currentIndex = ref();
const pageIndex = ref<number>(0);
const notificationSetting = ref<{
  type: 'PERSONAL' | 'GROUP' | 'SUPPORTING';
  id: number;
  name: string;
} | null>(null);
const isLoadingContacts = ref({
  isLoading: true,
  isHaveContact: false,
});
const userConversationIdsSearch = ref<string[]>([]);
const userUnseenConversations = ref<any>({});

const myProfile = computed(() => _myProfileStore.myProfile);

const userConversationIdsOriginal = computed<string[]>(() => {
  return orderBy(
    props.conversationIds?.filter(
      (conversId) =>
        !props.conversationById[conversId]?.closed &&
        props.conversationById[conversId]?.name
    ),
    [
      (conversId) =>
        props.conversationById[conversId]?.pinned
          ? props.conversationById[conversId]?.pinnedIndex || 0
          : 99,
      (conversId) =>
        props.conversationById[conversId]?.lastMessageTime ||
        props.conversationById[conversId]?.createdDate ||
        0,
    ],
    ['asc', 'desc']
  );
});

const pinnedConversationIds = computed<string[]>(() =>
  filter(
    userConversationIdsSearch.value,
    (converId) => !!props.conversationById[converId]?.pinned
  )
);

const unpinnedConversationIds = computed<string[]>(() =>
  filter(
    userConversationIdsSearch.value,
    (converId) => !props.conversationById[converId]?.pinned
  )
);

const userConversationIds = computed<string[]>(() =>
  slice(unpinnedConversationIds.value, 0, pageIndex.value + PAGE_SIZE)
);

const canLoadMore = computed<boolean>(
  () =>
    userConversationIds.value?.length <
    (unpinnedConversationIds.value?.length || 0)
);

const isExternalChat = (conversId) =>
  props.conversationById[conversId]?.externalChat;
const isSupporterChat = (conversId) =>
  props.conversationById[conversId]?.supportingChat &&
  props.conversationById[conversId]?.supportingOrgId;
const isChatAboutTask = (conversId) =>
  props.conversationById[conversId]?.chatAboutTaskId;
const isChatInternal = (conversId) =>
  !isExternalChat(conversId) &&
  !isSupporterChat(conversId) &&
  !isChatAboutTask(conversId);

let _unsubscribeUnseenConvers;

watch(
  () => props.organizationId,
  () => {
    activeTab.value = 'CHAT-ALL';
    searchText.value = '';

    _subscribeUnseenConversations();
    _checkToSeenAllConversations();
  }
);

watch(
  () => userConversationIdsOriginal.value,
  () => {
    _processLoading();
    _processSearchConversations();
  }
);

watch(
  () => [windowFocusStatus.value, activeTab.value],
  () => _checkToSeenAllConversations()
);

onMounted(() => {
  _processLoading();
  _processSearchConversations();
  _listenWindowEvent();
  _subscribeUnseenConversations();
  _checkToSeenAllConversations();
});

onUnmounted(() => {
  _unlistenWindowEvent();

  if (_unsubscribeUnseenConvers) _unsubscribeUnseenConvers();
});

const onSearchTextChange = () => {
  pageIndex.value = 0;

  _processSearchConversations();

  setTimeout(() => conversationsListRef.value?.scroll(0, 0));
};

const onSearchPressEnter = () => {
  const id = userConversationIdsSearch.value[currentIndex.value || 0];

  emit('onConversationClick', id);
};

const onTabClick = (tabId) => {
  _selectTab(tabId);
};

const onConversationsLoadMore = () => {
  if (!canLoadMore.value) return;

  pageIndex.value = userConversationIds.value?.length || 0;
};

const onOpenTurnOffNotification = (converId) => {
  const converInfo = props.conversationById[converId];

  notificationSetting.value = {
    type: converInfo?.supportingChat
      ? 'SUPPORTING'
      : converInfo?.isGroup
      ? 'GROUP'
      : 'PERSONAL',
    id: converInfo?.contactId || converId,
    name: converInfo?.name,
  };
};

const onLeaveConversationClick = async (conversId) => {
  if (conversId === props.activeConversation?.id) {
    emit('onConversationClick', null);
  }
};

const onPinnedConversationsSort = (event) => {
  const orgId = props.organizationId || myProfile?.value?.organizationId;
  const userId = myProfile?.value?.id;
  if (!orgId || !userId) return;

  const newPinnedConversationIds = moveItemInArray(
    pinnedConversationIds.value,
    event?.oldIndex,
    event?.newIndex
  );

  const convers = newPinnedConversationIds?.map((converId, index) => ({
    id: converId,
    pinnedIndex: index + 1,
  }));

  ChatService.updateConversationsPinnedIndex(orgId, userId, convers);
};

const _selectTab = (tabId) => {
  activeTab.value = tabId;

  _processSearchConversations();
};

const _processLoading = () => {
  isLoadingContacts.value.isHaveContact = userConversationIds.value?.length > 0;
  isLoadingContacts.value.isLoading = false;
};

const _processSearchConversations = () => {
  if (searchText.value) {
    return (userConversationIdsSearch.value =
      userConversationIdsOriginal.value?.filter((conversId) =>
        ignoreUnicode(props.conversationById[conversId]?.name).includes(
          ignoreUnicode(searchText.value)
        )
      ));
  }

  if (activeTab.value === 'CHAT-EXTERNAL') {
    return (userConversationIdsSearch.value =
      userConversationIdsOriginal.value?.filter(isExternalChat));
  }

  if (activeTab.value === 'CHAT-SUPPORTER') {
    return (userConversationIdsSearch.value =
      userConversationIdsOriginal.value?.filter(isSupporterChat));
  }

  if (activeTab.value === 'CHAT-ABOUT-TASK') {
    return (userConversationIdsSearch.value =
      userConversationIdsOriginal.value?.filter(isChatAboutTask));
  }

  if (activeTab.value === 'CHAT-INTERNAL') {
    return (userConversationIdsSearch.value =
      userConversationIdsOriginal.value?.filter(isChatInternal));
  }

  userConversationIdsSearch.value = userConversationIdsOriginal.value;
};

const _onWindowKeydown = (e) => {
  if (e.ctrlKey && e.keyCode === 81) {
    // console.log('CTRL + Q');
    inputSearchRef.value?.focus();
    e.preventDefault();
  }
};

const _listenWindowEvent = () => {
  window.addEventListener('keydown', _onWindowKeydown);
};

const _unlistenWindowEvent = () => {
  window.removeEventListener('keydown', _onWindowKeydown);
};

const _subscribeUnseenConversations = () => {
  if (_unsubscribeUnseenConvers) _unsubscribeUnseenConvers();

  const orgId = props.organizationId || myProfile?.value?.organizationId;
  const userId = myProfile?.value?.id;

  _unsubscribeUnseenConvers = ChatService.subscribeUserData(
    orgId,
    userId,
    (doc) => {
      userUnseenConversations.value = doc?.data()?.unSeenConversations || {};
    }
  );
};

const _checkToSeenAllConversations = () => {
  if (!windowFocusStatus.value) return;

  const orgId = props.organizationId || myProfile?.value?.organizationId;
  const userId = myProfile?.value?.id;

  const updConverIds = userConversationIdsSearch.value?.filter(
    (converId) => userUnseenConversations.value[converId]
  );
  if (!updConverIds?.length) return;

  ChatService.updateUserData(orgId, userId, {
    unSeenConversations: chain(updConverIds)
      .keyBy()
      .mapValues(() => false)
      .value(),
  });
};
</script>

<template>
  <div class="h-full flex flex-col">
    <!--SEARCH BOX-->
    <div
      v-if="userConversationIdsOriginal?.length > 1"
      class="px-3 py-2 mb-1"
      :class="searchText ? 'bg-gradient-to-r from-current-300 to-teal-400' : ''"
    >
      <VigSearchBox
        ref="inputSearchRef"
        v-model="searchText"
        v-model:current-index="currentIndex"
        :total-result="userConversationIdsSearch?.length"
        name="message-page"
        :input-class="
          searchText
            ? 'bg-white border border-transparent'
            : 'bg-gray-100 border border-transparent'
        "
        @update:model-value="onSearchTextChange"
        @enter="onSearchPressEnter"
        @blur="currentIndex = null"
      />
    </div>

    <!--TABS-->
    <ConversationTypes
      v-if="!searchText"
      v-model:active-tab="activeTab"
      :user-conversations="conversationById"
      :user-unseen-conversations="userUnseenConversations"
      :user-conversation-ids-original="userConversationIdsOriginal"
      @update:active-tab="onTabClick"
    />

    <!--SEARCH NOT FOUND-->
    <div
      v-if="searchText?.length && !userConversationIdsSearch?.length"
      class="flex-center flex-col space-y-4 p-3"
    >
      <syn-animation name="searchIcon" stype="width: 150px; height: 150px" />
      <span class="text-gray-500 text-sm">{{
        $t('CHAT_THE_CONVERSATION_IS_NOT_FOUND')
      }}</span>
    </div>

    <!--NO CONVERSATION-->
    <div
      v-else-if="!searchText?.length && !userConversationIdsOriginal?.length"
      class="flex-1 flex-center flex-col space-y-4"
    >
      <div class="">
        <syn-animation name="noMessage" stype="width: 200px; height: 200px" />
      </div>
      <em class="text-lg text-gray-700 text-center px-2 leading-6">{{
        $t('CHAT_NO_CONVERSATION_MSG') || "You don't have any chats"
      }}</em>
      <SynButton
        v-if="canCreateNewChat"
        :label="$t('CHAT_NEW') || 'New chat'"
        @click="$emit('onNewChatClick')"
      />
    </div>

    <!--CONVERSATIONS LIST-->
    <div
      ref="conversationsListRef"
      v-listen-scroll="{
        onScroll: onConversationsLoadMore,
        distance: 30,
      }"
      class="p-1 flex-1 overflow-auto small-scrollbar"
    >
      <!--PINNED CONVERSATIONS-->
      <SortAble
        :disabled="activeTab !== 'CHAT-ALL' || !!searchText"
        :options="{ onMove: true }"
        @on-end-sort="onPinnedConversationsSort"
      >
        <template #list-item>
          <div
            v-for="(conversId, index) in pinnedConversationIds"
            :id="`vig-search-box-item-id_message-page_${index.toString()}`"
            :key="conversId"
            class="rounded-md overflow-hidden relative group"
          >
            <ConversationItem
              :is-focusing="currentIndex === index"
              :conversation-id="conversId"
              :members="members"
              :conversation="conversationById[conversId]"
              :selected="activeConversation?.id === conversId"
              :is-loading="isLoading"
              @click="$emit('onConversationClick', conversId)"
            />
            <ConversationActions
              v-if="!isPersonalOrg"
              class="
                absolute
                right-2
                top-1/2
                -my-4
                invisible
                group-hover:visible
              "
              :organization-id="organizationId"
              :conversation="conversationById[conversId]"
              :can-view-group-chat-task="canViewGroupChatTask"
              @on-leave="onLeaveConversationClick(conversId)"
              @on-turn-off-notification="onOpenTurnOffNotification(conversId)"
            />
          </div>
        </template>
      </SortAble>

      <!--NORMAL CONVERSATIONS-->
      <div
        v-for="(conversId, index) in userConversationIds"
        :id="`vig-search-box-item-id_message-page_${(
          index + (pinnedConversationIds?.length || 0)
        ).toString()}`"
        :key="conversId"
        class="rounded-md overflow-hidden relative group"
      >
        <ConversationItem
          :is-focusing="
            currentIndex === index + (pinnedConversationIds?.length || 0)
          "
          :conversation-id="conversId"
          :members="members"
          :conversation="conversationById[conversId]"
          :selected="activeConversation?.id === conversId"
          :is-loading="isLoading"
          @click="$emit('onConversationClick', conversId)"
        />
        <ConversationActions
          v-if="!isPersonalOrg"
          class="absolute right-2 top-1/2 -my-4 invisible group-hover:visible"
          :organization-id="organizationId"
          :conversation="conversationById[conversId]"
          :can-view-group-chat-task="canViewGroupChatTask"
          @on-leave="onLeaveConversationClick(conversId)"
          @on-turn-off-notification="onOpenTurnOffNotification(conversId)"
        />
      </div>

      <!--LOAD MORE-->
      <div v-if="canLoadMore" class="p-2 flex-center">
        <VigButton
          light
          icon="DownArrow"
          color="gray"
          rounded="rounded-full"
          @click="onConversationsLoadMore"
        />
      </div>
    </div>

    <!--LOADING-->
    <div
      v-if="!isLoadingContacts?.isHaveContact && isLoadingContacts?.isLoading"
      class="flex-1 flex flex-col p-2 space-y-2"
    >
      <div v-for="item in 2" :key="item" class="flex items-center space-x-2">
        <div class="h-12 w-12 animate-pulse bg-gray-200 rounded-full"></div>
        <div class="flex flex-1 flex-col space-y-1">
          <div
            class="h-2 p-2 w-20 animate-pulse bg-gray-200 rounded-full"
          ></div>
          <div
            class="h-2 p-2 w-full animate-pulse bg-gray-200 rounded-full"
          ></div>
        </div>
      </div>
    </div>
  </div>

  <ModalNotificationSetting
    v-if="!!notificationSetting?.id"
    :ref-id="notificationSetting.id"
    :scope="notificationSetting.type"
    :name="notificationSetting.name"
    is-hidden-change-function
    :function-default="'MESSAGING'"
    @cancel="() => (notificationSetting = null)"
  />
</template>
