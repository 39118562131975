<script setup lang="ts">
import { ref } from 'vue';
import getUserInfo from '@/ui/helpers/user-helper';
import SynAnimation from '@/ui/common/atoms/SynAnimation.vue';

const props = defineProps<{
  userId: Number;
}>();

const userInformation = ref(getUserInfo(props.userId));
</script>

<template>
  <div class="flex justify-center text-center flex-col w-full">
    <SynAnimation name="statistics" />
    <span class="font-medium text-2xl">{{
      `See ${userInformation?.name}'s work spirit from every angle`
    }}</span>
    <span class="py-2 text-base"
      >Get insights with charts using real-time data across teams, projects—even
      departments. Fiine can help you set up your first Dashboard.</span
    >
  </div>
</template>
