<script setup lang="ts">
import { onMounted, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    isSub?: boolean;
    rowId?: number | string;
    isHighLight?: boolean;
    customClass?: string;
    isScrollToRow?: boolean;
    isShowBorder?: boolean;
    highLightTime?: number;
  }>(),
  {
    highLightTime: 0,
  }
);

defineEmits<{
  (e: 'click'): void;
}>();

onMounted(() => {
  handleScrollToHighLightItem();
});
watch(
  () => props.rowId,
  () => {
    handleScrollToHighLightItem();
  }
);
watch(
  () => props.isScrollToRow,
  () => {
    handleScrollToHighLightItem();
  }
);
watch(
  () => props.highLightTime,
  () => {
    if (props.highLightTime && Date.now() - props.highLightTime < 1000)
      handleScrollToHighLightItem();
  }
);

const handleScrollToHighLightItem = () => {
  if (props.isHighLight && props.rowId) {
    let el = document.getElementById(props.rowId?.toString());
    el?.scrollIntoView({ block: 'center' });
  }
};
</script>
<template>
  <tr
    :id="rowId?.toString()"
    class="h-max px-1"
    :class="[
      isHighLight || Date.now() - highLightTime < 500 ? 'syn-tr__blink' : '',
      customClass,
      isShowBorder ? 'border-b border-gray-50' : '',
    ]"
    @click="$emit('click')"
  >
    <slot></slot>
  </tr>
</template>

<style>
@keyframes change {
  0% {
    background-color: white;
  }
  30% {
    background-color: #e0f1f2;
  }
  40% {
    background-color: white;
  }
  70% {
    background-color: #e0f1f2;
  }
  100% {
    background-color: white;
  }
}

.syn-tr__blink {
  animation-name: change;
  animation-duration: 2s;
}
</style>
