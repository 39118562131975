<script setup>
import { computed } from 'vue';
import dashboardStore from '@/store/dashboard';
import { translate } from '@/ui/plugins/i18n/myi18n';
import {
  colorByAlphabet,
  convertViToEn,
  cleanString,
} from '@/ui/common/molecules/SynAvatar/colorByAlphabet.js';
import { AdminType } from '@/ui/common/constants/constant';

const props = defineProps({
  user: {
    default: () => {},
    type: Object,
  },
});

const _dashboardStore = dashboardStore();
const userWorkingStatus = computed(() => _dashboardStore.userWorkingStatus);
const textNotInGroup = translate('CHAT_MENTION_NOT_IN_GROUP');

const statusColor = computed(() => {
  let status =
    userWorkingStatus.value[props?.user?.userId]?.status || 'offline';

  switch (status) {
    case 'offline':
      return 'background: #cbd5e1 !important; ';
    case 'absent':
      return 'background: #fb923c !important;';
    default:
      return 'background: #4ade80 !important;';
  }
});

const textAva = computed(() => {
  if (!props?.user?.name) return 'A';
  return cleanString(props?.user?.name)
    .split(' ')
    [cleanString(props?.user?.name).split(' ').length - 1].charAt(0);
});
</script>

<template>
  <div
    class="flex items-center justify-between space-x-3 hover:bg-current-50"
    style="
      min-width: 240px !important;
      font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
        'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji' !important;
    "
  >
    <div class="flex items-center space-x-2 overflow-hidden">
      <div
        class="relative w-8 h-8"
        :class="customClass"
        style="
          position: relative;
          width: 2rem !important;
          height: 2rem !important;
        "
      >
        <img
          v-if="user?.avatar"
          class="w-8 h-8 object-cover rounded-full shadow"
          :src="user?.avatar.replace('original', 'small')"
          style="width: 2rem !important; height: 2rem !important"
        />
        <div
          v-else
          class="w-8 h-8 flex-center text-white shadow font-thin rounded-full"
          :class="`${customClass}`"
          :style="`background: ${
            colorByAlphabet[convertViToEn(textAva, true)] || '#4bb0b2'
          }`"
          style="
            width: 2rem !important;
            height: 2rem !important;
            color: white !important;
            font-weight: 500 !important;
          "
        >
          <span
            v-if="user?.name"
            class="uppercase text-base"
            style="color: white !important; font-weight: 500 !important"
            >{{ textAva }}
          </span>
        </div>
        <div
          v-if="!user?.mentionGroup"
          class="w-2 h-2 border border-white rounded-full"
          :style="statusColor"
          style="
            position: absolute !important;
            bottom: 2px !important;
            right: 0px !important;
            width: 8px !important;
            height: 8px !important;
          "
        ></div>
      </div>
      <span
        class="truncate"
        style="
          font-family: ui-sans-serif, system-ui, -apple-system,
            BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
            'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
            'Segoe UI Symbol', 'Noto Color Emoji' !important;
          font-size: 0.875rem;
          line-height: 1.25rem;
          color: #374151;
        "
        >{{ user?.name }}</span
      >
      <span
        v-if="user?.adminType == AdminType.Visitor"
        class="text-xs"
        :style="{
          fontFamily: `ui-sans-serif, system-ui, -apple-system,
            BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
            'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
            'Segoe UI Symbol', 'Noto Color Emoji'`,
          fontStyle: 'italic',
          fontSize: '0.75rem',
          lineHeight: '1rem',
          color: '#374151',
        }"
      >
        {{ translate('COMMON_LABEL_VISITOR') }}
      </span>
    </div>
    <div
      v-if="!user?.isInGroup && !user?.isDirectMessage"
      class="text-gray-400 text-sm"
      style="color: #9ca3af !important; font-size: 12px !important"
    >
      {{ textNotInGroup }}
    </div>
    <div v-if="user?.mentionGroup" class="w-6 h-6 flex-center">
      <div class="w-4 h-4 fill-gray-500 text-gray-500">
        <svg
          width="1rem"
          height="1rem"
          viewBox="924 565.952 200 200"
          xml:space="preserve"
        >
          <g>
            <g>
              <path
                d="M984.585,626.893c0,14-9.609,25.348-21.461,25.348s-21.459-11.348-21.459-25.348c0-13.999,9.607-25.345,21.459-25.345    S984.585,612.895,984.585,626.893z"
              />
              <path
                d="M987.586,671.591c1.549-0.945,3.265-1.56,5.041-1.854c-3.606-5.088-6.161-10.546-7.637-17.078    c-0.404-2.387-3.672-2.667-6.102-0.687c-4.545,3.706-9.849,6.186-15.764,6.186c-6.03,0-11.577-2.399-16.025-6.414    c-1.419-1.283-3.51-1.476-5.142-0.479c-8.444,5.157-14.835,13.344-17.623,23.064c-0.748,2.607-0.223,5.421,1.411,7.59    c1.637,2.166,4.192,3.443,6.906,3.443h38.669C975.947,680.023,981.41,675.362,987.586,671.591z"
              />
            </g>
            <g>
              <path
                d="M1063.414,626.893c0,14,9.61,25.348,21.462,25.348s21.46-11.348,21.46-25.348c0-13.999-9.608-25.345-21.46-25.345    S1063.414,612.895,1063.414,626.893z"
              />
              <path
                d="M1060.413,671.591c-1.549-0.945-3.264-1.56-5.04-1.854c3.605-5.088,6.16-10.546,7.637-17.078    c0.404-2.387,3.674-2.667,6.103-0.687c4.545,3.706,9.849,6.186,15.764,6.186c6.03,0,11.576-2.399,16.024-6.414    c1.42-1.283,3.51-1.476,5.143-0.479c8.443,5.157,14.834,13.344,17.623,23.064c0.748,2.608,0.222,5.421-1.412,7.59    c-1.635,2.166-4.192,3.443-6.906,3.443h-38.668C1072.052,680.023,1066.59,675.362,1060.413,671.591z"
              />
            </g>
            <g>
              <path
                d="M1082.474,713.402c-4.198-14.654-13.72-27.044-26.327-34.991c-2.487-1.567-5.715-1.313-7.921,0.631    c-6.765,5.958-15.136,9.506-24.226,9.506c-9.268,0-17.791-3.686-24.626-9.856c-2.181-1.97-5.393-2.267-7.901-0.734    c-12.977,7.925-22.8,20.505-27.082,35.445c-1.151,4.008-0.344,8.329,2.166,11.663c2.516,3.329,6.443,5.29,10.615,5.29h92.521    c4.173,0,8.103-1.954,10.618-5.29C1082.822,721.731,1083.625,717.414,1082.474,713.402z"
              />
              <path
                d="M1056.98,640.499c0,21.512-14.767,38.955-32.98,38.955s-32.979-17.442-32.979-38.955    c0-21.515,14.765-38.951,32.979-38.951S1056.98,618.984,1056.98,640.499z"
              />
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>
