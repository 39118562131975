<script setup lang="ts">
import { copyToClipBroard } from '@/ui/hooks/commonFunction';
import { openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import FilesDownload from '@/ui/modules/ged/file-actions/files-download/FilesDownload.vue';

const props = withDefaults(
  defineProps<{
    file: any;
  }>(),
  {}
);

const onCopyClick = () => {
  if (!props.file?.pathUrl) return;

  copyToClipBroard(props.file?.pathUrl);

  openNotification({
    title: translate('COMMON_LABEL_SUCCESS'),
    body: translate('GED_LABEL_COPY_URL_SUCCESS'),
    duration: 3000,
  });
};
</script>

<template>
  <VigDropdown z-index="100">
    <template #dropdown-toggle>
      <slot />
    </template>
    <template #dropdown-menu>
      <div>
        <VigButton
          ghost
          color="gray"
          rounded="rounded-0"
          class="w-full justify-start space-x-3 dropdown-item"
          @click="onCopyClick"
        >
          <SynIcon name="Link" />
          <span class="font-normal text-sm">{{
            $t('GED_GOOGLE_COPY_LINK')
          }}</span>
        </VigButton>
        <FilesDownload :files="[file]">
          <VigButton
            ghost
            color="gray"
            class="w-full justify-start space-x-3 dropdown-item"
          >
            <SynIcon name="Download" />
            <span class="font-normal text-sm">{{
              $t('COMMON_LABEL_DOWNLOAD')
            }}</span>
          </VigButton>
        </FilesDownload>
      </div>
    </template>
  </VigDropdown>
</template>
