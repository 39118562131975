<script setup lang="ts">
import { computed, ref } from 'vue';
import { saveAs } from 'file-saver';
import axios from 'axios';
import JSZip from 'jszip';
import commentTaskComposable from '@/ui/composables/task/comment-task';
import TaskCommentInput from '@/ui/common/plugins/ckeditor/TaskCommentInput.vue';
import MessageItem from '@/ui/modules/task/components/activity-comment/MessageItem.vue';
import { AlertType, openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import ModalMultipleFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewer.vue';
import { chain, map } from 'lodash';
import taskCommentStore from '@/store/task/task-comment';
import TaskCommentBottom from '@/ui/modules/task/detail/TaskCommentBottom.vue';
import TaskCommentReplyExpand from '@/ui/modules/task/detail/_commons/task-comments/TaskCommentReplyExpand.vue';

const props = defineProps<{
  taskDetail: any;
  taskComments: any[];
  readonly?: boolean;
  users?: any[];
}>();

const emits = defineEmits<{
  (e: 'onSaveSuccess', isNeedUpdateAttachments: boolean): void;
  (e: 'onSaveUpdateNote', noteData: any): void;
  (e: 'onFileInfoClick');
  (e: 'onScrollTo', position: number);
}>();

const viewerFiles = ref<any[]>();
const viewerActiveFile = ref();
const viewerActiveIndex = ref(0 as any);
const editCommentTaskPinnedInputRef = ref(null);

const listComments = computed(() =>
  taskCommentStore().orderByListComment(props.taskComments)
);

const primaryComments = computed(
  () => listComments.value?.filter((el) => !el?.replyTo) || []
);

const repliesByComment = computed<{ [commentId: string]: any[] }>(() =>
  chain(listComments.value)
    .filter((cmt) => cmt?.replyTo)
    .groupBy('replyTo')
    .value()
);

const listCommentPinned = computed(
  () => listComments.value?.filter((el) => el?.pinned) || []
);

const {
  commentTaskInputRef,
  currentCommentEdit,
  isLoading,
  expandedRepliesByComment,
  onAddReactionComment,
  onSaveUpdateComment,
  onEditComment,
  onCloseEditComment,
  onDeleteComment,
  onPinComment,
  onRemoveReaction,
  onReplyCommentClick,
  setInputCommentReplyRefs,
} = commentTaskComposable(emits);

const onFileClick = async (comment, file) => {
  viewerFiles.value = chain(comment?.attachments?.files)
    .concat(
      map(comment?.attachments?.notes, (note) => ({
        ...note,
        fileName: 'tictop-note.jpeg',
        isNote: true,
        contentType: 'image/jpeg',
      }))
    )
    .sortBy('creationTime')
    .value();

  viewerActiveFile.value = file;
  viewerActiveIndex.value = viewerFiles.value.findIndex(
    (f) => f?.id === file?.id
  );
  if (viewerActiveIndex.value === -1) viewerActiveIndex.value = null;
};

const onFileClickByIndex = (index) => {
  viewerActiveFile.value = viewerFiles.value ? viewerFiles.value[index] : null;
};

const onMessageDownload = (files) => {
  if (files.length < 1) return;

  if (files.length === 1) {
    return axios
      .get(files[0].pathUrl || files[0].url_full, { responseType: 'blob' })
      .then((res) => {
        saveAs(res.data, files[0].name);
      });
  } else {
    const zip = new JSZip();
    files.forEach((file) => {
      zip.file(
        file.name,
        axios(file.pathUrl || file.url_full, { responseType: 'blob' }).then(
          (res) => res?.data
        )
      );
    });
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `files-${new Date().getTime()}.zip`);
    });
  }
};

const listUserRelated = computed(() => [
  props?.taskDetail?.creatorId,
  props?.taskDetail?.assigneeId,
]);

const onClickTextComment = (event) => {
  const target = event?.target;

  if (
    target.getAttribute('data-type') == 'mention' &&
    target.getAttribute('data-mention-group') == 'true'
  ) {
    event.preventDefault();
    if (props.taskDetail?.groupId) {
      let linkCurrent = target?.href?.split('groups')?.shift();
      window.open(
        `${linkCurrent}groups/${props.taskDetail?.groupId}`,
        '_blank'
      );
    }
    if (!props?.taskDetail?.groupId) {
      openNotification({
        type: AlertType.warning,
        title: translate(''),
        body: translate('COMMENT_ALERT_NOT_INCLUDE_GROUP', {
          code: props.taskDetail?.code,
        }),
      });
    }
  }
};

const onSaveUpdateNote = (noteData) => {
  emits('onSaveUpdateNote', noteData);
};
</script>

<template>
  <template v-if="isLoading?.get">
    <div class="flex flex-col space-y-2 w-full px-4 py-2">
      <div class="w-1/4 bg-gray-200 h-3 rounded-full animate-pulse"></div>
      <div class="w-2/3 bg-gray-200 h-3 rounded-full animate-pulse"></div>
    </div>
  </template>
  <template v-else>
    <div
      class="w-full h-full flex flex-col relative"
      @click="commentTaskInputRef?.onClose()"
    >
      <!--PINNED COMMENTS-->
      <div
        v-if="listCommentPinned?.length > 0"
        class="flex flex-col bg-orange-50 bg-opacity-70 h-full"
      >
        <MessageItem
          v-for="cmtPinned in listCommentPinned"
          :key="cmtPinned?.id"
          :message="cmtPinned"
          :is-show-line-date="false"
          class="mb-1 w-full"
          bg-msg="bg-white bg-opacity-20"
          :is-edit="
            currentCommentEdit &&
            currentCommentEdit?.id === cmtPinned?.id &&
            currentCommentEdit?.isPinned
          "
          :is-loading="isLoading"
          :users="users"
          :readonly="readonly"
          @on-reaction-add="onAddReactionComment"
          @on-edit-comment="onEditComment({ ...cmtPinned, isPinned: true })"
          @on-remove-comment="onDeleteComment(cmtPinned?.id)"
          @on-pin-comment="onPinComment(cmtPinned?.id)"
          @on-file-click="onFileClick(cmtPinned, $event)"
          @on-click-text-comment="onClickTextComment"
        >
          <template
            v-if="
              currentCommentEdit &&
              currentCommentEdit?.id === cmtPinned?.id &&
              currentCommentEdit?.isPinned
            "
            #input-edit
          >
            <TaskCommentInput
              ref="editCommentTaskPinnedInputRef"
              v-model="currentCommentEdit.content"
              v-model:mention-user-ids="currentCommentEdit.mentionUserIds"
              v-model:mention-group="currentCommentEdit.mentionGroup"
              edit-mode="EDIT"
              :current-task="taskDetail"
              :group-id="taskDetail?.groupId"
              :users-related="listUserRelated"
              :task-private="taskDetail?.isPrivate"
              :files="cmtPinned?.attachments?.files"
              :notes="cmtPinned?.attachments?.notes"
              :is-loading-send="isLoading?.update"
              is-action-close
              @on-save="onSaveUpdateComment"
              @on-close="onCloseEditComment"
            />
          </template>
        </MessageItem>
      </div>

      <!--COMMENTS-->
      <div v-if="primaryComments?.length > 0" class="bg-gray-50">
        <template v-for="(cmt, index) in primaryComments" :key="cmt?.id">
          <MessageItem
            :message="cmt"
            :is-edit="
              currentCommentEdit &&
              currentCommentEdit?.id === cmt?.id &&
              !currentCommentEdit?.isPinned
            "
            :users="users"
            :readonly="readonly"
            :can-reply="true"
            @on-reaction-add="onAddReactionComment"
            @on-edit-comment="onEditComment(cmt)"
            @on-remove-comment="onDeleteComment(cmt?.id)"
            @on-pin-comment="onPinComment(cmt?.id)"
            @on-reaction-remove="onRemoveReaction"
            @on-file-click="onFileClick(cmt, $event)"
            @on-message-download="onMessageDownload"
            @on-click-text-comment="onClickTextComment"
            @on-reply-click="onReplyCommentClick(cmt?.id, index)"
          >
            <!--EDIT MODE-->
            <template
              v-if="
                currentCommentEdit &&
                currentCommentEdit?.id === cmt?.id &&
                !currentCommentEdit?.isPinned
              "
              #input-edit
            >
              <TaskCommentInput
                ref="editCommentTaskInputRef"
                v-model="currentCommentEdit.content"
                v-model:mention-user-ids="currentCommentEdit.mentionUserIds"
                v-model:mention-group="currentCommentEdit.mentionGroup"
                edit-mode="EDIT"
                is-auto-focus
                :current-task="taskDetail"
                :group-id="taskDetail?.groupId"
                :users-related="listUserRelated"
                :task-private="taskDetail?.isPrivate"
                :files="currentCommentEdit?.attachments?.files"
                :notes="currentCommentEdit?.attachments?.notes"
                :is-loading-send="isLoading?.update"
                is-action-close
                @on-save="onSaveUpdateComment"
                @on-close="onCloseEditComment"
              />
            </template>

            <!--COMMENT REPLIES-->
            <template #replies>
              <TaskCommentReplyExpand
                :expanded="expandedRepliesByComment[cmt?.id]"
                :replies="repliesByComment[cmt?.id]"
                @on-expanded-toggle="
                  expandedRepliesByComment[cmt?.id] =
                    !expandedRepliesByComment[cmt?.id]
                "
              />
              <div v-if="expandedRepliesByComment[cmt?.id]" class="border-l">
                <MessageItem
                  v-for="reply in repliesByComment[cmt?.id]"
                  :key="reply?.id"
                  :message="reply"
                  :is-edit="
                    currentCommentEdit &&
                    currentCommentEdit?.id === reply?.id &&
                    !currentCommentEdit?.isPinned
                  "
                  :users="users"
                  :readonly="readonly"
                  @on-reaction-add="onAddReactionComment"
                  @on-edit-comment="onEditComment(reply)"
                  @on-remove-comment="onDeleteComment(reply?.id)"
                  @on-pin-comment="onPinComment(reply?.id)"
                  @on-reaction-remove="onRemoveReaction"
                  @on-file-click="onFileClick(reply, $event)"
                  @on-message-download="onMessageDownload"
                  @on-click-text-comment="onClickTextComment"
                >
                  <template
                    v-if="
                      currentCommentEdit &&
                      currentCommentEdit?.id === reply?.id &&
                      !currentCommentEdit?.isPinned
                    "
                    #input-edit
                  >
                    <TaskCommentInput
                      ref="editCommentTaskInputRef"
                      v-model="currentCommentEdit.content"
                      v-model:mention-user-ids="
                        currentCommentEdit.mentionUserIds
                      "
                      v-model:mention-group="currentCommentEdit.mentionGroup"
                      edit-mode="EDIT"
                      is-auto-focus
                      :current-task="taskDetail"
                      :group-id="taskDetail?.groupId"
                      :users-related="listUserRelated"
                      :task-private="taskDetail?.isPrivate"
                      :files="currentCommentEdit?.attachments?.files"
                      :notes="currentCommentEdit?.attachments?.notes"
                      :is-loading-send="isLoading?.update"
                      is-action-close
                      @on-save="onSaveUpdateComment"
                      @on-close="onCloseEditComment"
                    />
                  </template>
                </MessageItem>
                <div :id="'input-reply-cmt-' + cmt?.id" class="px-4 py-2">
                  <TaskCommentBottom
                    :ref="setInputCommentReplyRefs(index)"
                    user-class="w-6 h-6"
                    input-placeholder="Thêm phản hồi..."
                    :task-id="taskDetail?.id"
                    :current-task="taskDetail"
                    :reply-to="cmt?.id"
                    @on-save-success="$emit('onSaveSuccess', false)"
                  />
                </div>
              </div>
            </template>
          </MessageItem>
        </template>
      </div>
    </div>
  </template>

  <ModalMultipleFileViewer
    v-if="viewerActiveFile && viewerActiveIndex !== null"
    :start-index="viewerActiveIndex"
    :files="viewerFiles"
    :content-type="viewerActiveFile?.contentType"
    :editable="!readonly"
    :has-task-actions="!readonly"
    :has-image-actions="!readonly"
    :is-loading="isLoadingWhenUpdateNote"
    @on-file-click="onFileClickByIndex"
    @on-close="viewerActiveFile = null"
    @on-save-note="onSaveUpdateNote"
  >
    <template #title>
      <span class="truncate">
        {{ viewerActiveFile?.fileName || viewerActiveFile?.name }}
      </span>
    </template>
  </ModalMultipleFileViewer>
</template>

<style scoped>
@import '@/ui/plugins/ckeditor/css/content-styles.scss';
</style>
