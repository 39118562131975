import { AxiosInstance } from 'axios';
import BaseRequest from '@/base/api/base-request';

export default class VideoRecordingRepository {
    private static instance: VideoRecordingRepository;
    request: AxiosInstance;

    constructor() {
        this.request = BaseRequest;
    }

    public static getInstance(): VideoRecordingRepository {
        if (!VideoRecordingRepository.instance) {
            VideoRecordingRepository.instance = new VideoRecordingRepository();
        }
        return VideoRecordingRepository.instance;
    }

    createVideoRecording(data) {
        return this.request({
            url: `VideoRecording/CreateVideoRecording`,
            method: 'post',
            data,
        });
    }

    getVideoRecording(id) {
        return this.request({
            url: `VideoRecording/GetVideoRecording/${id}`,
            method: 'get',
        });
    }

    startVideoRecording(id) {
        return this.request({
            url: `VideoRecording/StartVideoRecording/${id}`,
            method: 'put',
            data: {},
        });
    }

    stopVideoRecording(id) {
        return this.request({
            url: `VideoRecording/StopVideoRecording/${id}`,
            method: 'put',
            data: {},
        });
    }
}
