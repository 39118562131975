<script setup lang="ts">
import { onUnmounted, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    percent: number;
    isAutoRun?: boolean;
    speed?: number;
    maxPercent?: number;
    color: string;
  }>(),
  {
    speed: 100,
    maxPercent: 100,
    color: '#4bb0b2',
  }
);
const emit = defineEmits(['onTimeOut']);

const percentProcess = ref(props.percent);

let intervalId;

const init = () => {
  if (!props.isAutoRun) return;
  play();
};

onUnmounted(() => {
  clearInterval(intervalId);
});

const isPause = ref<boolean>(false);
const pause = () => {
  isPause.value = true;
};
const play = () => {
  isPause.value = false;

  intervalId = setInterval(() => {
    if (isPause.value) return;

    if (percentProcess.value == props.maxPercent) {
      emit('onTimeOut');
      return clearInterval(intervalId);
    }

    percentProcess.value++;
  }, props.speed);
};
const stop = () => {
  isPause.value = false;
  if (intervalId) clearInterval(intervalId);
};
const reset = () => {
  percentProcess.value = props.percent;
  if (intervalId) clearInterval(intervalId);
  play();
};

init();

defineExpose({
  init,
  pause,
  play,
  stop,
  reset,
});
</script>

<template>
  <div class="w-full min-h-[0.25rem] relative rounded-full">
    <div
      class="h-full min-h-[0.25rem] z-10 border"
      :class="[percentProcess == 100 ? 'rounded-full' : 'rounded-l-full']"
      :style="`width: ${percentProcess}%; background-color: ${color}; border-color:${color}`"
    ></div>
  </div>
</template>
