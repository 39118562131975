import { ScheduleDayOptions, TaskLife } from '@/domain/enums/taskEnum';
import {
    convertUrgencyToImportant,
    convertUrgencyToPriority,
    TaskUrgency,
    getUrgencyValue,
    getScheduleTimeAfterChangeDate,
} from '@/ui/hooks/taskHook';
import TaskOriginalClass, { ITaskOriginalClass } from './TaskOriginalClass';
import { NotificationTypeEnum, openNotification } from '@/ui/hooks/commonHook';
import TaskService from '@/application/services/task/TaskService';
import { IAttachmentFileDetail } from '@/application/services/attachment/AttachmentClass';
import { IDrawNoteDetail } from '@/application/services/attachment/NoteClass';
import { translate } from '@/ui/plugins/i18n/myi18n';
import groupStore from '@/store/group';
import {
    ask,
    settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import myProfileStore from '@/store/auth/my-profile';
import {
    ITaskTodo,
    TaskCollaborator,
} from '@/application/types/task/task.types';
import { TaskFromChat } from '@/domain/entities/task/TaskFromChat';
import ChatService from '@/application/services/ChatService';
import { ITaskWorkflowEntity } from './TaskWorkflowEntity';
// import { EWorkflowTaskStepStatus } from '@/application/types/workflow/workflow.types';
import TaskWorkflowStepEntity, {
    ITaskWorkflowStep,
} from './TaskWorkflowStepEntity';
import taskDrawerStore from '@/store/task/drawer';
import {
    newTaskDefaultPayload,
    onCreateGlobalSuccess,
} from '@/ui/modules/task/task-global-state';
import createFormStore from '@/store/task/create-form';
import startWorkingStore from '@/store/task/start-working-store';

export interface ITaskCreateEntity extends ITaskOriginalClass {
    localId?: number;
    urgency: number; // 1 | 2 | 3 | 4
    domainName: string;
    projectName: string;
    subprojectName: string;
    // attachments: any[];
    files: IAttachmentFileDetail[];
    notes: IDrawNoteDetail[];
    isPrivate: boolean;
    taskTodos: ITaskTodo[];
    taskFromChat: TaskFromChat | null;

    // workflow
    taskWorkflow?: ITaskWorkflowEntity;
    workflowId?: string;
    multiAssignees?: {
        assigneeId: number;
        groupId?: number;
        domainId: number;
        projectId?: number;
        subprojectId?: number;
    }[];
}

export default class TaskCreateEntity
    extends TaskOriginalClass
    implements ITaskCreateEntity
{
    parentId?: number;
    localId?: number;
    urgency: number; // 1 | 2 | 3 | 4

    domainName: string;
    projectName: string;
    subprojectName: string;
    // attachments: any[];

    files: IAttachmentFileDetail[];
    notes: IDrawNoteDetail[];
    isPrivate: boolean;

    taskTodos: ITaskTodo[];
    taskWorkflow?: ITaskWorkflowEntity;
    workflowId?: string;

    taskFromChat: TaskFromChat | null;
    subTasks?: any;
    subTaskIds?: number[];

    multiAssignees?: {
        assigneeId: number;
        domainId: number;
        projectId?: number;
        subprojectId?: number;
    }[];

    static userDayInfo: any;
    static userOtherDay: any;

    constructor(data) {
        super(data);
        this.parentId = data?.parentId;
        this.localId = data?.localId;
        this.name = data?.name || '';
        this.description = data?.description || '';
        this.urgency = data?.urgency || 1;
        // this.attachments = data?.attachments || [];
        this.domainName = data?.domainName;
        this.projectName = data?.projectName;
        this.subprojectName = data?.subprojectName;
        this.isPrivate = data?.isPrivate;

        this.files = data?.files || [];
        this.notes = data?.notes || [];
        this.taskTodos = data?.taskTodos || [];
        this.taskWorkflow = data?.taskWorkflow;
        this.workflowId = data?.workFlowTaskId || '';
        this.taskFromChat = data?.taskFromChat;
        this.subTasks = data?.subTasks;
        this.subTaskIds = data?.subTaskIds;
        this.multiAssignees = data?.multiAssignees;
    }

    async create(isShowNotification = true) {
        try {
            if (this.taskWorkflow?.id && this.taskWorkflow?.steps?.length > 0) {
                let index = 0;
                for await (const step of this.taskWorkflow.steps) {
                    const _step = new TaskWorkflowStepEntity(step);

                    await _step.saveAttachment(true);

                    if (
                        !_step.updateFields ||
                        !_step.updateFields?.includes('files')
                    )
                        _step.updateFields = [
                            ...(_step.updateFields || []),
                            'files',
                        ];
                    if (
                        !_step.updateFields ||
                        !_step.updateFields?.includes('notes')
                    )
                        _step.updateFields = [
                            ...(_step.updateFields || []),
                            'notes',
                        ];
                    if (
                        !_step.updateFields ||
                        !_step.updateFields?.includes('todoLists')
                    )
                        _step.updateFields = [
                            ...(_step.updateFields || []),
                            'todoLists',
                        ];

                    this.taskWorkflow.steps[index] = _step;
                    index++;
                }
            }

            console.log('🚀 Tictop ~ this:', this);
            const res = await TaskService.getInstance().create(
                new TaskCreateEntity({
                    ...this,
                    files: this.files,
                    notes: this.notes?.map((note) => {
                        return {
                            ...note,
                            drawData:
                                typeof note?.drawData == 'string'
                                    ? note?.drawData
                                    : JSON.stringify(note?.drawData),
                            id: undefined,
                        };
                    }),
                    taskWorkflow: this.taskWorkflow
                        ? {
                              workflowId: this.taskWorkflow?.id,
                              steps: this.taskWorkflow?.steps,
                          }
                        : undefined,
                    subTasks: undefined,
                })
            );

            const newTask = {
                ...res?.result,
                // creationTime: new Date()
            };

            const myProfile = myProfileStore().myProfile;

            if (newTask?.id) {
                const taskCode = newTask.code;
                let groupName = '';
                if (this.groupId) {
                    const groupDetail: any = groupStore().getDetailGroupById(
                        this.groupId
                    );
                    groupName = groupDetail?.name;
                }

                if (isShowNotification) {
                    openNotification({
                        metaData: {
                            hasAction: true,
                            isOpenTaskDetail: true,
                            taskCode,
                        },
                        notificationValue: {
                            ...newTask,
                            actionType: 'AddNew',
                            type: 'task',
                            task_name: this.name,
                            task_code: newTask?.code,
                            isPlannedToTreat: this.taskSchedule.doItToday,
                            isPlannedToFinish: this.taskSchedule.finishItToday,
                            group_name: groupName,
                            executor_name: myProfile?.firstName,
                            icon: myProfile?.avatarUrl,
                        },
                        notificationType: NotificationTypeEnum.task,
                    });
                }
            }

            if (newTask?.id && this.taskFromChat?.messageId) {
                try {
                    ChatService.addTaskCreatedFromMessage(
                        this.taskFromChat?.orgId,
                        this.taskFromChat?.conversationId,
                        this.taskFromChat?.messageId,
                        newTask,
                        myProfile?.id
                    );
                } catch (e) {
                    console.log('addTaskCreatedFromMessage FAIL', e);
                }
            }

            if (
                onCreateGlobalSuccess.value &&
                typeof onCreateGlobalSuccess.value == 'function'
            )
                onCreateGlobalSuccess.value(newTask);

            newTaskDefaultPayload.value = undefined;
            createFormStore().removeCacheByLocalId(this.localId);
            createFormStore().resetFormAfterCreate({
                ...newTask,
                multiAssignees: this.multiAssignees,
            });

            if (this.localId)
                taskDrawerStore().replaceCurrentIds(this.localId, newTask);

            startWorkingStore().updateTodoListAfterCreate(newTask);

            return Promise.resolve(newTask);
        } catch (error) {
            settingGlobalModal({
                type: 'notification',
                title: '',
                content:
                    error == 'UPLOAD_FILE_FAILED'
                        ? translate('TASK_CREATE_UPLOAD_FILE_FAILED')
                        : translate('COMMON_ERROR_MESSAGE_CREATE_TASK') ||
                          'Đã xảy ra lỗi khi tạo task, vui lòng thử lại.',
                confirmable: true,
            });
            await ask();

            return Promise.reject(false);
        }
    }

    changePriority(isHigh: boolean) {
        this.changeUrgency(
            isHigh ? TaskUrgency.UrgentImportant.key : TaskUrgency.Cool.key
        );
    }

    changeUrgency(urgency: number) {
        // 1 | 2 | 3 | 4
        this.priority = convertUrgencyToPriority(urgency);
        this.important = convertUrgencyToImportant(urgency);
        this.urgency = urgency;
    }

    changeDeadline(deadlineDate: {
        scheduleOptionKey: number;
        otherTimeKey: number;
        scheduleTime: string;
        overdue: number;
        startTime?: string;
        updateMode?: 'START_ONLY' | 'END_ONLY' | 'BOTH';
    }) {
        if (!deadlineDate) return;
        switch (deadlineDate.updateMode) {
            case 'START_ONLY':
                this.startTime = deadlineDate?.startTime;
                break;
            case 'BOTH':
            case 'END_ONLY':
                {
                    if (deadlineDate.updateMode == 'BOTH')
                        this.startTime = deadlineDate?.startTime;

                    this.taskSchedule.scheduleOptionKey =
                        deadlineDate.scheduleOptionKey;
                    this.taskSchedule.otherTime = deadlineDate.otherTimeKey;
                    this.scheduleTime = getScheduleTimeAfterChangeDate(
                        this.scheduleTime,
                        deadlineDate.scheduleTime,
                        this.scheduleByHour,
                        this.scheduleTimezone
                    );

                    this.taskSchedule.finishItToday = false;
                    switch (deadlineDate.scheduleOptionKey) {
                        case ScheduleDayOptions.OnDay:
                            this.taskSchedule.finishItToday = true;
                            this.taskSchedule.doItToday = true;
                            this.taskLife = TaskLife.Todo;
                            break;
                        case ScheduleDayOptions.Nothing:
                            this.scheduleByHour = false;
                            break;

                        default:
                            break;
                    }
                }
                break;

            default:
                break;
        }
    }
    getFileUrl(file) {
        if (file instanceof File) return URL.createObjectURL(file);
        if (file?.fileBlob instanceof Blob) {
            return URL.createObjectURL(file?.fileBlob);
        }
        if (file?.blob instanceof Blob) {
            return URL.createObjectURL(file?.blob);
        }
        if (file?.base64) return file?.base64;
        if (file?.fileUrl) return file?.fileUrl;
        if (file?.url_full) return file?.url_full;
        if (file?.pathUrl) return file?.pathUrl;
        if (file?.Path) return file?.Path;

        return file?.imagePath || file?.src;
    }

    async getUserDayInfo() {
        const userId = this.assigneeId;
        if (userId) {
            const res: any =
                await TaskService.getInstance().getUserTaskSchedule(userId);

            TaskCreateEntity.userDayInfo = res.result;

            if (TaskCreateEntity.userDayInfo?.length > 0) {
                const index = TaskCreateEntity.userDayInfo.findIndex(
                    (o) => o.scheduleOptionKey == ScheduleDayOptions.OtherTime
                );

                if (index > -1) {
                    TaskCreateEntity.userOtherDay =
                        TaskCreateEntity.userDayInfo[index].otherTimes;
                }
            }
        }
    }

    addCollaboratorsInTask(payload) {
        this.collaborators = [...this.collaborators, ...payload];
    }

    deleteCollaboratorsInTask(userId) {
        this.collaborators = this.collaborators?.filter(
            (user) => user?.memberUserId !== userId
        );
    }
    onAttachWorkflow(workflow: ITaskWorkflowEntity) {
        this.workflowId = workflow?.id;
        this.taskWorkflow = workflow;

        if (!this.taskWorkflow?.steps || this.taskWorkflow?.steps?.length == 0)
            return {
                needToUpdateDomain: false,
            };

        const currentStep: ITaskWorkflowStep | undefined =
            this.taskWorkflow?.steps?.find((s) => s?.isStart);

        if (!currentStep || (!currentStep?.groupId && !currentStep?.assigneeId))
            return {
                needToUpdateDomain: false,
            };

        if (currentStep.domainId) {
            this.domainId = currentStep.domainId;
            this.projectId = undefined;
            this.subprojectId = undefined;
        }

        this.groupId = currentStep.groupId;
        this.assigneeId = currentStep.assigneeId;
        this.collaborators =
            currentStep.collaborators?.map((u) => {
                return {
                    memberUserId: u?.userId,
                    memberRoleId: u?.roleId,
                } as TaskCollaborator;
            }) || [];

        this.important = !!currentStep?.important;
        this.priority = currentStep?.priority || 0;
        this.urgency = getUrgencyValue(this.priority, this.important);
        if (typeof currentStep?.scheduleDayOption == 'number')
            this.taskSchedule.scheduleOptionKey =
                currentStep?.scheduleDayOption;
        this.scheduleByHour = currentStep.scheduleByHour;
        this.scheduleTime = currentStep.scheduleTime || '';
        this.scheduleTimezone = currentStep.scheduleTimezone;

        // approval
        this.approvalEnabled = currentStep?.approvalEnabled;
        this.approvalStatus = currentStep?.approvalStatus;
        this.approvers = currentStep?.approvers;
        this.approverIds = currentStep?.approvers?.map((data) => {
            return data?.userId;
        });

        // new rule 22/12/2023: do not need to insert current step to current task
        // this.description = currentStep.taskDescription || '';
        // this.files = currentStep?.files;
        // this.notes = currentStep?.notes;
        // this.taskTodos = currentStep?.todoLists || [];

        return { needToUpdateDomain: !!currentStep.domainId };
    }
    updatePlanAndScheduleAfterAttachWorkflow(useDayByScheduleOptionKey) {
        // Plan
        if (this.taskSchedule.scheduleOptionKey == ScheduleDayOptions.OnDay) {
            this.taskSchedule.doItToday = true;
            this.taskSchedule.finishItToday = true;
        } else {
            this.taskSchedule.finishItToday = false;
        }

        // Deadline

        this.scheduleTime = getScheduleTimeAfterChangeDate(
            this.scheduleTime,
            useDayByScheduleOptionKey[this.taskSchedule.scheduleOptionKey]
                ?.scheduleDateValue,
            this.scheduleByHour,
            this.scheduleTimezone
        );
    }
    onDetachWorkflow() {
        this.workflowId = undefined;
        this.taskWorkflow = undefined;
    }

    synchronizeTaskToWorkflowCurrentStep() {
        if (!this.taskWorkflow) return;

        this.taskWorkflow.steps[0] = {
            ...this.taskWorkflow.steps[0],
            groupId: this.groupId,
            assigneeId: this.assigneeId,
            domainId: this.domainId,
            priority:
                this.urgency && this.urgency > 0
                    ? convertUrgencyToPriority(this.urgency)
                    : this.priority || 1,
            important:
                this.urgency && this.urgency > 0
                    ? convertUrgencyToImportant(this.urgency)
                    : !!this.important,
            scheduleDayOption: this.taskSchedule?.scheduleOptionKey,
            scheduleByHour: this.scheduleByHour,
            scheduleTime: this.scheduleTime ? this.scheduleTime : undefined,
            scheduleTimezone: this.scheduleTimezone,
        };
    }
}

export interface IAttachmentItem {
    objectUrl: string;
    fileBlob: File | Blob | null;
    base64: string;
    name: string;
    size: number;
    type: string;
    creationTime: Date | number | string;
    localId: string;
}
