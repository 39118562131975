<script setup lang="ts">
import { computed, ref } from 'vue';
import { activeAndFocusOnConversationId } from '@/ui/modules/messaging/chat-widget/chat-widget-public-state';
import { ChatConversationModel } from '@/application/models/chat/ChatConversationModel';
import ChatExternalActions from '@/ui/modules/messaging/chat-external/chat-external-actions/ChatExternalActions.vue';
import ModalChatSupporters from '@/ui/modules/messaging/chat-support/modal-chat-supporters/ModalChatSupporters.vue';
import chatStore from '@/store/chat';
import chatSupportingStore from '@/store/chat/chat-supporting';

const props = defineProps<{
  conversationId: string;
  conversation: ChatConversationModel;
}>();

defineEmits([
  'onShowFilesClick',
  'onShowPinnedMessagesClick',
  'onOpenNoteClick',
  'onChatPanelClose',
  'onGotoChatClick',
]);

const isShowSupporters = ref();

const isMeSupporter = computed(() =>
  chatStore().isMeSupporter(props.conversation)
);

const userClickableUrl = computed(() =>
  chatSupportingStore().prepareSupportingUserExternalLink(
    props.conversation?.supportingMemberId
  )
);

const onSupportingUserClick = () => {
  if (!userClickableUrl.value) return;

  window.open(userClickableUrl.value, 'SupportingExternalInfo');
};
</script>

<template>
  <div
    class="w-full flex items-center justify-between p-2"
    :class="{
      'bg-gray-100 text-gray-800': !activeAndFocusOnConversationId,
      'bg-current-500 text-white': activeAndFocusOnConversationId,
    }"
  >
    <div
      class="flex items-center gap-2"
      :class="{ 'cursor-pointer': userClickableUrl }"
      @click="onSupportingUserClick"
    >
      <SynAvatar
        custom-class="w-8 h-8"
        :src="conversation?.avatar"
        :name="conversation?.name"
        :short-name="conversation?.shortName"
      />

      <div>
        <div class="text-sm font-semibold">{{ conversation?.name }}</div>
        <div
          v-if="conversation?.supportingOrgName"
          class="flex items-center gap-1"
        >
          <SynAvatar
            custom-class="w-5 h-5"
            :src="conversation?.supportingOrgAvatar"
            :name="conversation?.supportingOrgName"
          />
          <div class="text-xs">
            {{ conversation?.supportingOrgName }}
          </div>
        </div>
      </div>

      <div
        class="
          flex-center
          bg-green-200
          rounded-full
          shadow
          overflow-hidden
          p-0.5
        "
      >
        <SynIcon custom-class="w-5 h-5 fill-green-600" name="CustomerSupport" />
      </div>
    </div>

    <div class="flex-center">
      <ChatExternalActions
        :conversation-id="conversationId"
        :can-add-supporters="isMeSupporter"
        can-go-to-chat-screen
        @on-show-files-click="$emit('onShowFilesClick')"
        @on-show-pinned-messages-click="$emit('onShowPinnedMessagesClick')"
        @on-open-note-click="$emit('onOpenNoteClick')"
        @on-go-to-chat-screen-click="$emit('onGotoChatClick')"
        @on-add-supporters-click="isShowSupporters = true"
      >
        <button
          class="w-8 h-8 rounded-full flex-center"
          :class="
            activeAndFocusOnConversationId
              ? 'hover:bg-white fill-white hover:bg-opacity-30'
              : 'hover:bg-white fill-gray-500'
          "
        >
          <SynIcon name="DotsVertical" />
        </button>
      </ChatExternalActions>

      <button
        class="w-8 h-8 rounded-full flex-center"
        :class="
          activeAndFocusOnConversationId
            ? 'hover:bg-white fill-white hover:bg-opacity-30'
            : 'hover:bg-white fill-gray-500'
        "
        @click="$emit('onChatPanelClose')"
      >
        <SynIcon name="Minus" :custom-class="['w-6']" />
      </button>
    </div>
  </div>

  <ModalChatSupporters
    v-if="isShowSupporters"
    :conversation-id="conversationId"
    @on-close="isShowSupporters = false"
  />
</template>
