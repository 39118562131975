<script setup lang="ts">
import { computed } from 'vue';
import PersonalSidebarItem from './PersonalSidebarItem.vue';
import settingStore from '@/store/setting';
import appStore from '@/store/app';

defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  currentActive: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    required: false,
    default: '',
  },
  onAction: {
    type: Function,
    required: false,
    default: () => {},
  },
  isCollapse: {
    type: Boolean,
    default: false,
  },
});
defineEmits(['on-collapse']);
const _settingStore = settingStore();
const _appStore = appStore();
const isOpenMenuBar = computed(() => _appStore.isOpenMenuBar);
// show setting group

const getMainPageLinkUrl = () => {
  return `/messages`;
};

const chatEnabled = computed(() => _settingStore.chatEnabled);
</script>

<template>
  <div v-if="chatEnabled" v-permission="'Messages'" class="w-full">
    <PersonalSidebarItem
      icon-name="contact"
      :is-active="
        currentActive == 'messages' || currentActive.includes('messages')
      "
      :is-hidden-text="!isOpenMenuBar"
      :item-name="$t('CHAT_AND_CALL_LABEL') || 'Chat & call'"
      :link-to="getMainPageLinkUrl()"
    />
  </div>
</template>
