<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import remoteConfigStore from '@/store/remoteConfig';
import { RemoteConfigKey } from '@/ui/plugins/firebases/firebase-remote-config';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { isAudio, isImage, isOffice, isVideo } from '@/ui/helpers/file-utils';
import { chain } from 'lodash';

const props = withDefaults(
  defineProps<{
    invalidFiles: Array<File>;
    module: 'CHAT' | 'TASK' | 'STORAGE';
  }>(),
  {}
);

const _remoteConfigStore = remoteConfigStore();

const limitConfigs = ref<Array<any>>();

const configByType = computed(() =>
  chain(limitConfigs.value).keyBy('type').mapValues('value').value()
);

const limitByFile = computed(() => (file) => {
  if (!file || !configByType.value) return null;

  const fileType = file.type;

  if (isImage(fileType)) return configByType.value['IMAGE'];
  if (isAudio(fileType)) return configByType.value['AUDIO'];
  if (isVideo(fileType)) return configByType.value['VIDEO'];
  if (isOffice(fileType)) return configByType.value['OFFICE'];

  return configByType.value['OTHER'];
});

onMounted(() => {
  limitConfigs.value = _processLimitConfigs(props.module);
});

const _processLimitConfigs = (module) => {
  switch (module) {
    case 'CHAT':
      return [
        {
          type: 'IMAGE',
          text: translate('COMMON_FILE_IMAGE') || 'Image file',
          value: _getConfigLimitSize(
            RemoteConfigKey.CommonChatFileImageSizeLimit
          ),
        },
        {
          type: 'AUDIO',
          text: translate('COMMON_FILE_AUDIO') || 'Audio file',
          value: _getConfigLimitSize(
            RemoteConfigKey.CommonChatFileAudioSizeLimit
          ),
        },
        {
          type: 'VIDEO',
          text: translate('COMMON_FILE_VIDEO') || 'Video file',
          value: _getConfigLimitSize(
            RemoteConfigKey.CommonChatFileVideoSizeLimit
          ),
        },
        {
          type: 'OFFICE',
          text: translate('COMMON_FILE_OFFICE') || 'Office file',
          value: _getConfigLimitSize(
            RemoteConfigKey.CommonChatFileOfficeSizeLimit
          ),
        },
        {
          type: 'OTHER',
          text: translate('COMMON_FILE_OTHER') || 'Other file',
          value: _getConfigLimitSize(RemoteConfigKey.CommonChatFileSizeLimit),
        },
      ];

    case 'TASK':
      return [
        {
          type: 'IMAGE',
          text: translate('COMMON_FILE_IMAGE') || 'Image file',
          value: _getConfigLimitSize(RemoteConfigKey.WebTaskUploadMaxFilesize),
        },
        {
          type: 'AUDIO',
          text: translate('COMMON_FILE_AUDIO') || 'Audio file',
          value: _getConfigLimitSize(RemoteConfigKey.WebTaskUploadMaxFilesize),
        },
        {
          type: 'VIDEO',
          text: translate('COMMON_FILE_VIDEO') || 'Video file',
          value: _getConfigLimitSize(RemoteConfigKey.WebTaskUploadMaxFilesize),
        },
        {
          type: 'OFFICE',
          text: translate('COMMON_FILE_OFFICE') || 'Office file',
          value: _getConfigLimitSize(RemoteConfigKey.WebTaskUploadMaxFilesize),
        },
        {
          type: 'OTHER',
          text: translate('COMMON_FILE_OTHER') || 'Other file',
          value: _getConfigLimitSize(RemoteConfigKey.WebTaskUploadMaxFilesize),
        },
      ];

    case 'STORAGE':
      return [];

    default:
      return [];
  }
};

const _getConfigLimitSize = (configKey) => {
  const limitSize = _remoteConfigStore.getRemoteConfig(configKey).asNumber();
  return (limitSize || 0) * 1024 * 1024;
};
</script>

<template>
  <div class="flex flex-col">
    <div
      class="
        text-xl text-current-600 text-center
        font-medium
        uppercase
        break-normal
        mb-3
      "
    >
      <span>{{
        $t('COMMON_MSG_FILE_SIZE_EXCEED') || 'Maximum file size exceeded'
      }}</span>
      <span>&nbsp;</span>
      <VigDropdown
        class="inline-block"
        placement="top"
        trigger="mouseenter focus"
        :z-index="100"
      >
        <template #dropdown-toggle>
          <SynIcon class="align-baseline" name="info" color="gray" />
        </template>
        <template #dropdown-menu>
          <div class="w-96 p-5">
            <div class="text-2xl text-center text-current-600 mb-1">
              {{
                $t('COMMON_MSG_FILE_SIZE_LIMIT') || 'Limitation of files size'
              }}
            </div>
            <div class="text-center text-gray-500 mb-3">
              <span v-if="module === 'CHAT'">{{
                $t(
                  'CHAT_FILE_SIZE_LIMIT_MSG' ||
                    'Fiine lets you send files in Chat with limitation of files size'
                )
              }}</span>
            </div>
            <table class="w-full">
              <tr
                v-for="(config, index) in limitConfigs"
                :key="index"
                :class="{ 'border-t': index > 0 }"
              >
                <td class="py-1">{{ config?.text }}</td>
                <td class="py-1 font-semibold text-right">
                  {{ $filters.fileSize(config?.value, 0) }}
                </td>
              </tr>
            </table>
          </div>
        </template>
      </VigDropdown>
    </div>
    <div class="flex-1 max-h-96 overflow-auto small-scrollbar px-2">
      <table class="w-full">
        <thead class="text-sm font-normal text-gray-400 whitespace-nowrap">
          <tr>
            <th class="px-1.5 py-1 font-semibold">
              {{ $t('COMMON_LABEL_FILE') || 'File' }}
            </th>
            <th class="px-1.5 py-1 font-semibold text-right">
              {{ $t('COMMON_LABEL_SIZE') || 'Size' }}
            </th>
            <th class="px-1.5 py-1 font-semibold text-right">
              {{ $t('COMMON_LABEL_MAX') || 'Maximum' }}
            </th>
          </tr>
        </thead>
        <tbody class="whitespace-nowrap">
          <tr
            v-for="(file, index) in invalidFiles"
            :key="index"
            class="border-t"
          >
            <td
              class="px-1.5 py-1 truncate"
              style="max-width: 1px"
              :title="file?.name"
            >
              {{ file?.name }}
            </td>
            <td
              class="px-1.5 py-1 text-right font-semibold text-red-600"
              style="width: 0"
            >
              {{ $filters.fileSize(file?.size) }}
            </td>
            <td class="px-1.5 py-1 text-right" style="width: 0">
              {{ $filters.fileSize(limitByFile(file), 0) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
