<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';
import tippy from 'tippy.js';

const props = defineProps<{
  delay?: number[];
}>();
const dropdownToggle: any = ref(null);
const dropdownMenu: any = ref(null);

onMounted(() => {
  if (!dropdownToggle.value || !dropdownMenu.value) return;

  tippy(dropdownToggle.value, {
    appendTo: () => document.body,
    content: dropdownMenu.value,
    allowHTML: true,
    theme: 'light',
    trigger: 'mouseenter focus',
    interactive: true,
    animation: 'scale',
    placement: 'top',
    arrow: true,
    delay: props.delay || [0, 0],
  });
});

onBeforeUnmount(() => {
  if (dropdownToggle.value?._tippy) {
    dropdownToggle.value?._tippy.hide();
    dropdownToggle.value?._tippy.destroy();
  }
});
</script>

<template>
  <div>
    <div ref="dropdownToggle">
      <slot name="trigger"></slot>
    </div>
    <div ref="dropdownMenu" className="text-base">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<style scoped>
.tippy-box[data-theme~='light'] .tippy-content {
  @apply p-0;
}
.tippy-box[data-theme~='translucent'] .tippy-content {
  @apply p-1;
}
</style>
