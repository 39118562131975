import router from './router';
import NProgress from 'nprogress';
import {
    clearLocalStorage,
    getLocalStorage,
    StorageConstant,
} from '@/ui/hooks/storageHook';
import RouterInstanceClass from './router/RouterInstanceClass';

const whiteList = [
    '/login',
    '/buy-license',
    '/embed-login',
    '/auth-redirect',
    '/register',
    '/forgot-password',
    '/verify-email',
    '/verify-pwd-token',
    '/suspicious-request',
    '/super-admin-session',
    '/verify-email',
    '/waiting-activation',
    '/organization-inactive',
    '/super-admin-session',
    '/users/invitation/accept',
    '/account-deactivated',
    '/system-deployment',
    '/system-maintenace',
    '/not-found',

    // ticket
    '/ticket',
    '/ticket/tasks/new',
    '/ticket/tasks/histories',
    '/ticket/my-page',

    // '/embed-chats',
    '/embed-chat/login',
    '/video-recorder',
]; // no redirect whitelist

NProgress.configure({ showSpinner: false }); // NProgress Configuration
router.beforeEach(async (to) => {
    NProgress.start();

    if (whiteList.some((path) => to?.path?.startsWith(path))) {
        return true;
    }

    if (
        to?.meta?.layout &&
        ['Shared', 'ticket'].includes(to?.meta?.layout?.toString())
    ) {
        return true;
    }

    const isEmbed = to.meta?.layout === 'embed';
    if (isEmbed) return '/embed-login';

    const token = getLocalStorage(StorageConstant.TOKEN);
    if (token) {
        const routerInstance = RouterInstanceClass.getInstance();
        const permissionCreated = routerInstance.permissionCreated;
        const dynamicRouteCreated = routerInstance.dynamicRouteCreated;

        if (!dynamicRouteCreated) {
            await RouterInstanceClass.getInstance().generateDynamicRoutes();
        }

        if (permissionCreated) return true;

        await RouterInstanceClass.getInstance().generateRoutes();

        return { ...to, replace: true };
    } else {
        const redirectUrl = to.fullPath.replace('?', '&');

        if (to.meta?.layout === 'embed-chat') {
            return `/embed-chat/login?redirect=${redirectUrl}`;
        }

        clearLocalStorage({ isSwitchingOrg: false });
        return `/login?redirect=${redirectUrl}`;
    }
});

router.afterEach(() => {
    // finish progress bar
    NProgress.done();
});
