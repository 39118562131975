import { translate } from '@/ui/plugins/i18n/myi18n';
import dayjs from 'dayjs';
import { RRule } from 'rrule';

export const CalendarSource = {
    Work: 1,
    System: 2,
};

export const CalendarType = {
    Task: 10,
    Meeting: 20,
    Reminder: 30,
    Event: 40,
};

export const CalendarSourceId = {
    Task: 'taskid',
    Meeting: 'meetingid',
    Reminder: 'reminderid',
};

export const CalendarId = {
    Event: 1,
};

export enum CalendarTypeValue {
    Event = 'EVENT',
    Reminder = 'REMINDER',
    Meeting = 'MEETING',
    Task = 'TASK',
}

export const CalendarSourceType = {
    Task: 1,
    Event: 2,
};

export enum CalendarStatus {
    Created = 1,
    ReminderOverdue = 2,
    ReminderFinished = 3,
}

export const CalendarNotificationType = {
    DonotNotice: 'NONE',

    NoticeAfter0Minutes: 'M0',
    NoticeAfter5Minutes: 'M5',
    NoticeAfter10Minutes: 'M10',
    NoticeAfter15Minutes: 'M15',
    NoticeAfter30Minutes: 'M30',

    NoticeAfter1Hour: 'H1',
    NoticeAfter2Hour: 'H2',
    NoticeAfter3Hour: 'H3',
    NoticeAfter4Hour: 'H4',
    NoticeAfter5Hour: 'H5',
    NoticeAfter6Hour: 'H6',
    NoticeAfter7Hour: 'H7',
    NoticeAfter8Hour: 'H8',
    NoticeAfter9Hour: 'H9',
    NoticeAfter10Hour: 'H10',
    NoticeAfter11Hour: 'H11',
    NoticeAfter12Hour: 'H12',
    NoticeAfter18Hour: 'H18',

    NoticeAfter1Days: 'D1',
    NoticeAfter2Days: 'D2',
    NoticeAfter3Days: 'D3',
    NoticeAfter4Days: 'D4',

    NoticeAfter1Weeks: 'W1',
    NoticeAfter2Weeks: 'W2',

    Custom: 'CUSTOM',
};

export const CalendarReminderRepeatType = {
    DoNotRepeat: 1,
    EveryDay: 2,
    EveryWeekDays: 3,
    EverySpecificDayOfWeek: 4,
    EverySpecificDayInMonth: 5,
    CustomSpecificDayOfWeek: 6,
    EverySpecificDayInYear: 7,
    CustomRRule: 99,
};

export const CalendarMeetingType = {
    PhysicalMeeting: 1,
    OnlineMeeting: 2,
};

export const CalendarReminderRepeatTypeValue = (date) => {
    return {
        [CalendarReminderRepeatType.DoNotRepeat]: {
            label: translate('CALENDAR_REMINDER_NO_REPEAT'),
            time: CalendarReminderRepeatType.DoNotRepeat,
        },
        [CalendarReminderRepeatType.EveryDay]: {
            label: translate('CALENDAR_REMINDER_REPEAT_DAILY'),
            time: CalendarReminderRepeatType.EveryDay,
        },
        [CalendarReminderRepeatType.EveryWeekDays]: {
            label: translate('CALENDAR_REMINDER_REPEAT_EVERYDAY'),
            time: CalendarReminderRepeatType.EveryWeekDays,
        },
        [CalendarReminderRepeatType.EverySpecificDayOfWeek]: {
            label: translate('CALENDAR_REMINDER_REPEAT_DAILY_WEEK', {
                day: dayjs(date).format('dddd'),
            }),
            time: CalendarReminderRepeatType.EverySpecificDayOfWeek,
        },
        [CalendarReminderRepeatType.CustomSpecificDayOfWeek]: {
            label: translate('CALENDAR_REMINDER_REPEAT_CUSTOM_DAY_WEEK'),
            time: CalendarReminderRepeatType.CustomSpecificDayOfWeek,
        },
    };
};

export const CalendarDayInWeek = {
    Monday: { label: 'COMMON_LABEL_DATE_MO', key: 1, byWeekDay: 'mo' },
    Tuesday: { label: 'COMMON_LABEL_DATE_TU', key: 2, byWeekDay: 'tu' },
    Wednesday: { label: 'COMMON_LABEL_DATE_WE', key: 3, byWeekDay: 'we' },
    Thursday: { label: 'COMMON_LABEL_DATE_TH', key: 4, byWeekDay: 'th' },
    Friday: { label: 'COMMON_LABEL_DATE_FR', key: 5, byWeekDay: 'fr' },
    Saturday: { label: 'COMMON_LABEL_DATE_SA', key: 6, byWeekDay: 'sa' },
    Sunday: { label: 'COMMON_LABEL_DATE_SU', key: 0, byWeekDay: 'su' },
};

export const Weekday = {
    SU: 0,
    MO: 1,
    TU: 2,
    WE: 3,
    TH: 4,
    FR: 5,
    SA: 6,
};

export const ByWeekDay = {
    [Weekday.SU]: {
        key: RRule.SU,
        label: 'COMMON_LABEL_DATE_SU',
    },
    [Weekday.MO]: {
        key: RRule.MO,
        label: 'COMMON_LABEL_DATE_MO',
    },
    [Weekday.TU]: {
        key: RRule.TU,
        label: 'COMMON_LABEL_DATE_TU',
    },
    [Weekday.WE]: {
        key: RRule.WE,
        label: 'COMMON_LABEL_DATE_WE',
    },
    [Weekday.TH]: {
        key: RRule.TH,
        label: 'COMMON_LABEL_DATE_TH',
    },
    [Weekday.FR]: {
        key: RRule.FR,
        label: 'COMMON_LABEL_DATE_FR',
    },
    [Weekday.SA]: {
        key: RRule.SA,
        label: 'COMMON_LABEL_DATE_SA',
    },
};

export const CalendarNotificationTypeValue = {
    0: {
        key: 'NONE',
        label: translate('CALENDAR_NOTICE_ITEM_NOT_NOTICE'),
        labelDetail: translate('CALENDAR_NOTICE_ITEM_NOT_NOTICE'),
        time: 0,
    },
    1: {
        key: 'M5',
        label: translate('CALENDAR_NOTICE_ITEM', { time: 15 }),
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL', { time: 15 }),
        time: 5,
    },
    2: {
        key: 'M10',
        label: translate('CALENDAR_NOTICE_ITEM', { time: 10 }),
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL', { time: 10 }),
        time: 10,
    },
    3: {
        key: 'M15',
        label: translate('CALENDAR_NOTICE_ITEM', { time: 15 }),
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL', { time: 15 }),
        time: 15,
    },
    4: {
        key: 'M30',
        label: translate('CALENDAR_NOTICE_ITEM', { time: 30 }),
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL', { time: 30 }),
        time: 30,
    },
    5: {
        key: 'H1',
        label: translate('CALENDAR_NOTICE_ITEM_HOUR', { time: 11 }),
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL_HOUR', {
            time: 60,
        }),
        time: 60,
    },

    // New
    NONE: {
        key: 'NONE',
        // label: translate('CALENDAR_NOTICE_ITEM_NOT_NOTICE'),
        label: 'CALENDAR_NOTICE_ITEM_NOT_NOTICE',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_NOT_NOTICE'),
        time: 0,
    },
    M0: {
        key: 'M0',
        // label: translate('CALENDAR_NOTICE_ITEM', { time: 0 }),
        label: 'CALENDAR_NOTICE_ITEM',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL', { time: 0 }),
        time: 0,
    },
    M5: {
        key: 'M5',
        // label: translate('CALENDAR_NOTICE_ITEM', { time: 5 }),
        label: 'CALENDAR_NOTICE_ITEM',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL', { time: 5 }),
        time: 5,
    },
    M10: {
        key: 'M10',
        // label: translate('CALENDAR_NOTICE_ITEM', { time: 10 }),
        label: 'CALENDAR_NOTICE_ITEM',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL', { time: 10 }),
        time: 10,
    },

    M15: {
        key: 'M15',
        // label: translate('CALENDAR_NOTICE_ITEM', { time: 15 }),
        label: 'CALENDAR_NOTICE_ITEM',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL', { time: 15 }),
        time: 15,
    },
    M30: {
        key: 'M30',
        // label: translate('CALENDAR_NOTICE_ITEM', { time: 30 }),
        label: 'CALENDAR_NOTICE_ITEM',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL', { time: 30 }),
        time: 30,
    },
    H1: {
        key: 'H1',
        // label: translate('CALENDAR_NOTICE_ITEM_HOUR', { time: 1 }),
        label: 'CALENDAR_NOTICE_ITEM_HOUR',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL_HOUR', {
            time: 1,
        }),
        time: 1,
    },
    H2: {
        key: 'H2',
        // label: translate('CALENDAR_NOTICE_ITEM_HOUR', { time: 2 }),
        label: 'CALENDAR_NOTICE_ITEM_HOUR',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL_HOURS', {
            time: 2,
        }),
        time: 2,
    },
    H3: {
        key: 'H3',
        // label: translate('CALENDAR_NOTICE_ITEM_HOUR', { time: 3 }),
        label: 'CALENDAR_NOTICE_ITEM_HOUR',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL_HOURS', {
            time: 3,
        }),
        time: 3,
    },
    H4: {
        key: 'H4',
        // label: translate('CALENDAR_NOTICE_ITEM_HOUR', { time: 4 }),
        label: 'CALENDAR_NOTICE_ITEM_HOUR',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL_HOURS', {
            time: 4,
        }),
        time: 4,
    },
    H5: {
        key: 'H5',
        // label: translate('CALENDAR_NOTICE_ITEM_HOUR', { time: 5 }),
        label: 'CALENDAR_NOTICE_ITEM_HOUR',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL_HOURS', {
            time: 5,
        }),
        time: 5,
    },
    H6: {
        key: 'H6',
        // label: translate('CALENDAR_NOTICE_ITEM_HOUR', { time: 6 }),
        label: 'CALENDAR_NOTICE_ITEM_HOUR',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL_HOURS', {
            time: 6,
        }),
        time: 6,
    },
    H7: {
        key: 'H7',
        // label: translate('CALENDAR_NOTICE_ITEM_HOUR', { time: 7 }),
        label: 'CALENDAR_NOTICE_ITEM_HOUR',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL_HOURS', {
            time: 7,
        }),
        time: 7,
    },
    H8: {
        key: 'H8',
        // label: translate('CALENDAR_NOTICE_ITEM_HOUR', { time: 8 }),
        label: 'CALENDAR_NOTICE_ITEM_HOUR',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL_HOURS', {
            time: 8,
        }),
        time: 8,
    },
    H9: {
        key: 'H9',
        // label: translate('CALENDAR_NOTICE_ITEM_HOUR', { time: 9 }),
        label: 'CALENDAR_NOTICE_ITEM_HOUR',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL_HOURS', {
            time: 9,
        }),
        time: 9,
    },
    H10: {
        key: 'H10',
        // label: translate('CALENDAR_NOTICE_ITEM_HOUR', { time: 10 }),
        label: 'CALENDAR_NOTICE_ITEM_HOUR',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL_HOURS', {
            time: 10,
        }),
        time: 10,
    },
    H11: {
        key: 'H11',
        // label: translate('CALENDAR_NOTICE_ITEM_HOUR', { time: 11 }),
        label: 'CALENDAR_NOTICE_ITEM_HOUR',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL_HOURS', {
            time: 11,
        }),
        time: 11,
    },
    H12: {
        key: 'H12',
        // label: translate('CALENDAR_NOTICE_ITEM_DAY', { time: 0.5 }),
        label: 'CALENDAR_NOTICE_ITEM_DAY',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL_DAYS', {
            time: 0.5,
        }),
        time: 0.5,
    },
    // H18: {
    //     key: 'H18',
    //     label: translate('CALENDAR_NOTICE_ITEM_HOUR', { time: 18 }),
    //     labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL_HOURS', {
    //         time: 18,
    //     }),
    //     time: 1320,
    // },
    D1: {
        key: 'D1',
        // label: translate('CALENDAR_NOTICE_ITEM_DAY', { time: 1 }),
        label: 'CALENDAR_NOTICE_ITEM_DAY',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL_DAY', {
            time: 1,
        }),
        time: 1,
    },
    D2: {
        key: 'D2',
        // label: translate('CALENDAR_NOTICE_ITEM_DAY', { time: 2 }),
        label: 'CALENDAR_NOTICE_ITEM_DAY',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL_DAYS', {
            time: 2,
        }),
        time: 2,
    },
    D3: {
        key: 'D3',
        // label: translate('CALENDAR_NOTICE_ITEM_DAY', { time: 3 }),
        label: 'CALENDAR_NOTICE_ITEM_DAY',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL_DAYS', {
            time: 3,
        }),
        time: 3,
    },
    D4: {
        key: 'D4',
        // label: translate('CALENDAR_NOTICE_ITEM_DAY', { time: 4 }),
        label: 'CALENDAR_NOTICE_ITEM_DAY',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL_DAYS', {
            time: 4,
        }),
        time: 4,
    },
    W1: {
        key: 'W1',
        // label: translate('CALENDAR_NOTICE_ITEM_WEEK', { time: 1 }),
        label: 'CALENDAR_NOTICE_ITEM_WEEK',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL_WEEK', {
            time: 1,
        }),
        time: 1,
    },
    W2: {
        key: 'W2',
        // label: translate('CALENDAR_NOTICE_ITEM_WEEK', { time: 2 }),
        label: 'CALENDAR_NOTICE_ITEM_WEEK',
        labelDetail: translate('CALENDAR_NOTICE_ITEM_DETAIL_WEEKS', {
            time: 2,
        }),
        time: 2,
    },
};

export const RRuleCalendar = {
    // DoNotRepeat: {
    //     freq: 'yearly',
    //     interval: 0,
    //     byweekday: [],
    // },
    EveryDay: {
        freq: RRule.DAILY, //'daily',
        interval: 1,
        byweekday: [],
    },
    EveryWeekDays: {
        freq: RRule.WEEKLY, //'weekly',
        interval: 1,
        byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR], //['mo', 'tu', 'we', 'th', 'fr'],
    },
    EverySpecificDayOfWeek: {
        freq: RRule.WEEKLY, //'weekly',
        interval: 1,
    },
    EverySpecificDayInMonth: {
        freq: RRule.MONTHLY, //'monthly',
        interval: 1,
    },
};

export const GoogleCalendar = {
    Primary: 'primary',
};
