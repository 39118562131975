<script setup lang="ts">
import { ref, computed } from 'vue';

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    nameIcon?: string;
    isLoading?: boolean;
    customClass?: string;
    customLabelClass?: string;
    color?: string;
    typeFill?: boolean;
    typeOutline?: boolean;
    typeText?: boolean;
    label?: string;
    customClassIcon?: string;
    isTextHtml?: boolean;
  }>(),
  {
    disabled: false,
    isLoading: false,
    customClass: 'rounded',
    customLabelClass: 'rounded',
    color: 'current',
    typeFill: false,
    typeOutline: false,
    typeText: false,
    customClassIcon: 'h-4 w-4',
    isTextHtml: false,
    nameIcon: '',
    label: '',
  }
);

defineEmits(['click']);

const colorBtn = ref('');
const classFill = computed(
  () => `bg-${props.color}-500
hover:bg-${props.color}-400
text-white fill-white hover:shadow-lg`
);
const classOutline = computed(
  () => `bg-transparent
hover:bg-${props.color}-50
text-${props.color}-500
fill-${props.color}-500 border border-${props.color}-500`
);

const classText = computed(
  () =>
    `bg-transparent text-${props.color}-500 hover:bg-${props.color}-50 fill-${props.color}-500`
);
</script>

<template>
  <button
    v-bind="$attrs"
    class="
      focus:outline-none
      py-2
      px-4
      flex-center
      min-w-max
      relative
      flex-center
      space-x-1
      font-semibold
    "
    :class="[
      typeFill
        ? classFill
        : typeOutline
        ? classOutline
        : typeText
        ? classText
        : classFill,
      customClass,
    ]"
    :style="
      disabled && !isLoading
        ? 'cursor: not-allowed; opacity: 0.5'
        : disabled && isLoading
        ? 'cursor: wait; opacity: 0.5'
        : 'cursor: pointer;'
    "
    :disabled="disabled || isLoading"
    @click="($event) => $emit('click', $event)"
  >
    <SynIcon
      v-if="nameIcon && !isLoading"
      class="border-none mr-1"
      is-active
      :custom-class="customClassIcon"
      :color="typeFill ? 'white' : colorBtn"
      :name="nameIcon"
    />

    <SynIcon
      v-if="isLoading"
      name="spinner"
      class="fill-white border-none animate-spin"
      custom-class="w-4 h-4"
    />

    <span v-if="label" :class="customLabelClass" v-html="label"></span>
    <slot></slot>
  </button>
</template>
