<script setup lang="ts">
import { watch, ref, onMounted, computed } from 'vue';
import { DayoffExceptionType, DayoffType } from '@/domain/enums/DayoffEnums';
import dayoffStatisticStore from '@/store/dayoff/dayoff-statistic';
import GraphStatisticByUser from './GraphStatisticByUser.vue';
import SelectMembers from '@/ui/modules/member/select-members/SelectMembers.vue';
import userStore from '@/store/user';
import myProfileStore from '@/store/auth/my-profile';
import UserById from '@/ui/components/user/UserById.vue';
import SynTh from '@/ui/common/molecules/SynTableCustom/SynTh.vue';
import DayoffStatusAtom from '@/ui/components/dayoff/atoms/DayoffStatusAtom.vue';
import DayoffDateSessionAtom from '@/ui/components/dayoff/atoms/DayoffDateSessionAtom.vue';
import DayoffTypeAtom from '@/ui/components/dayoff/atoms/DayoffTypeAtom.vue';
import DayoffExceptionTypeAtom from '@/ui/components/dayoff/atoms/DayoffExceptionTypeAtom.vue';
import SynTd from '@/ui/common/molecules/SynTableCustom/SynTd.vue';

const props = defineProps({
  userId: {
    type: [String, Number],
    default: '',
  },
});
defineEmits(['cancel']);

const _dayoffStatisticStore = dayoffStatisticStore();
const _userStore = userStore();

const totalDayoffInYear = computed(() => {
  return new Date().getMonth() + 1;
});
const isLoading = ref(false);
const dayoffUsed = ref([] as any);
const statisticDayoff = ref([] as any);
const currentYear = ref(new Date().getFullYear());
const userIdSelected = ref();

const myProfile = computed(() => myProfileStore().myProfile);
const members = computed(() => _userStore.listShortInfosUser);
const dayoffList = computed(() => _dayoffStatisticStore.dayoffListByMonth);
const parserDataType = computed(() => _dayoffStatisticStore.parserDataType);
const totalDayoff = computed(() => dayoffUsed.value?.totalDayoff);
const detailEventList = ref([]);
const isShowDetailEvent = ref(false);
const dateTime = ref(null as any);
const typeEvent = ref(null as any);

const detailDayoffUsed = computed(() => {
  return [
    {
      type: DayoffType.SCHEDULE,
      totalCount:
        dayoffUsed.value?.detail?.find((el) => el?.type == DayoffType.SCHEDULE)
          ?.totalCount || 0,
      totalDayoff:
        dayoffUsed.value?.detail?.find((el) => el?.type == DayoffType.SCHEDULE)
          ?.totalDayoff || 0,
    },
    {
      type: DayoffType.WFH,
      totalCount:
        dayoffUsed.value?.detail?.find((el) => el?.type == DayoffType.WFH)
          ?.totalCount || 0,
      totalDayoff:
        dayoffUsed.value?.detail?.find((el) => el?.type == DayoffType.WFH)
          ?.totalDayoff || 0,
    },
    {
      type: DayoffType.EXCEPTION,
      totalCount:
        dayoffUsed.value?.detail?.find((el) => el?.type == DayoffType.EXCEPTION)
          ?.totalCount || 0,
      totalDayoff:
        dayoffUsed.value?.detail?.find((el) => el?.type == DayoffType.EXCEPTION)
          ?.totalDayoff || 0,
    },
  ];
});

watch(
  () => props.userId,
  async () => {
    userIdSelected.value = {
      id: props.userId,
    };
    isLoading.value = true;
    await getDayoffUsedByUser(props.userId);
    isLoading.value = false;
  }
);

onMounted(async () => {
  if (props.userId) {
    userIdSelected.value = {
      id: props.userId,
    };
    isLoading.value = true;
    await getDayoffUsedByUser(props.userId);
    isLoading.value = false;
  }
});

const getDayoffUsedByUser = async (
  userId,
  fromDate: any = null,
  toDate: any = null
) => {
  dayoffUsed.value = await _dayoffStatisticStore.getDayoffUsedByUser(
    userId,
    fromDate,
    toDate
  );
  statisticDayoff.value =
    await _dayoffStatisticStore.getStatisticByUserAndRange(
      userId,
      fromDate,
      toDate
    );
};

const translateMonth = (month) => {
  switch (month) {
    case 1:
      return 'LABEL_JANUARY';
    case 2:
      return 'LABEL_FEBRUARY';
    case 3:
      return 'LABEL_MARCH';
    case 4:
      return 'LABEL_APRIL';
    case 5:
      return 'LABEL_MAY';
    case 6:
      return 'LABEL_JUNE';
    case 7:
      return 'LABEL_JULY';
    case 8:
      return 'LABEL_AUGUST';
    case 9:
      return 'LABEL_SEPTEMBER';
    case 10:
      return 'LABEL_OCTOBER';
    case 11:
      return 'LABEL_NOVEMBER';
    case 12:
      return 'LABEL_DECEMBER';
    default:
      return '';
  }
};

const onPreviewCalendar = async (currentValue) => {
  switch (currentValue?.type) {
    case 'year':
      // eslint-disable-next-line no-case-declarations
      const startYear = new Date(currentValue?.value, 0, 1).getTime();
      // eslint-disable-next-line no-case-declarations
      const lastYear = new Date(currentValue?.value, 11, 31).getTime();
      getDayoffUsedByUser(props.userId, startYear, lastYear);

      break;
    case 'month':
      break;
    case 'day':
      break;
    default:
      break;
  }
};

const onNextCalendar = async (currentValue) => {
  switch (currentValue?.type) {
    case 'year':
      // eslint-disable-next-line no-case-declarations
      const startYear = new Date(currentValue?.value, 0, 1).getTime();
      // eslint-disable-next-line no-case-declarations
      const lastYear = new Date(currentValue?.value, 11, 31).getTime();
      getDayoffUsedByUser(props.userId, startYear, lastYear);
      break;
    case 'month':
      break;
    case 'day':
      break;
    default:
      break;
  }
};

const showDetailEvents = (list, time, type) => {
  isShowDetailEvent.value = true;
  detailEventList.value = list;
  dateTime.value = time;
  typeEvent.value = type;
};

const onMemberChange = async (value) => {
  isLoading.value = true;
  isShowDetailEvent.value = false;
  await getDayoffUsedByUser(value.id);
  isLoading.value = false;
};
</script>

<template>
  <syn-modal
    style-body="px-4 py-4"
    container-class="w-3/4"
    z-index="z-50"
    container-style="max-width: 1180px; height: 900px;"
    is-hidden-footer
    @cancel="$emit('cancel', false)"
  >
    <template #header>
      <div class="flex items-center justify-between">
        <!--   TITLE     -->
        <div class="flex items-center space-x-1">
          <span>{{ $t('DAYOFF_STATISTIC_TITLE_MODAL') }}</span>
          <span class="text-current-500 px-1">{{ currentYear }}</span>
        </div>

        <!--   MEMBER     -->
        <template v-if="!isLoading">
          <SelectMembers
            v-if="$permission('ViewCalendarStatistic')"
            v-model="userIdSelected"
            :members="members"
            :is-show-scroll-toggle="false"
            toggle-class="text-sm pr-4 w-72 flex justify-end min-w-max hover:text-current-500"
            size="small"
            @update:model-value="onMemberChange"
          />
          <div v-else class="flex items-center space-x-2">
            <UserById :user-id="myProfile?.id" class="text-sm" />
          </div>
        </template>
        <template v-else>
          <div class="flex-center space-x-1">
            <span class="w-8 h-8 rounded-full bg-gray-200 animate-pulse"></span>
            <span
              class="w-24 p-1 h-2 rounded-full bg-gray-200 animate-pulse"
            ></span>
          </div>
        </template>
      </div>
    </template>
    <template #body>
      <template v-if="!isLoading">
        <GraphStatisticByUser
          :user-id="userId"
          :dayoff-used="statisticDayoff"
          :statistic-dayoff="detailDayoffUsed"
          :total-dayoff="totalDayoff"
          @on-previous-calendar="onPreviewCalendar"
          @on-next-calendar="onNextCalendar"
        >
          <template #infomation-statistic>
            <div class="flex items-center justify-between px-5">
              <!-- INFOMATION STATISTIC -->
              <div
                class="flex flex-col p-3 rounded"
                :class="{
                  'bg-current-50': totalDayoff < 5,
                  'bg-orange-50': totalDayoff <= 9 && totalDayoff >= 5,
                  'bg-red-50': totalDayoff > 9,
                }"
              >
                <div class="flex items-center space-x-4">
                  <div
                    class="
                      flex-center
                      h-full
                      space-x-1
                      bg-white
                      rounded
                      px-2
                      py-4
                    "
                  >
                    <div class="flex items-end">
                      <span
                        class="text-5xl font-medium"
                        :class="{
                          'text-current-500': totalDayoff < 5,
                          'text-orange-500':
                            totalDayoff <= 9 && totalDayoff >= 5,
                          'text-red-500': totalDayoff > 9,
                        }"
                      >
                        {{ totalDayoff }}
                      </span>
                      <span class="text-xl lowercase text-gray-500">
                        {{
                          '/ ' +
                          totalDayoffInYear +
                          ' ' +
                          $t('DAYOFF_CREATE_FORM_DAYS')
                        }}
                      </span>
                    </div>
                  </div>
                  <div class="flex flex-col items-start pl-6">
                    <template v-for="item in detailDayoffUsed" :key="item">
                      <div class="flex items-center space-x-2 h-6">
                        <div
                          class="flex items-center space-x-1"
                          style="min-width: 12rem"
                        >
                          <span class="text-xs text-current-500">
                            {{ $t(parserDataType[item?.type]?.label) }}
                          </span>
                          <span
                            v-if="item?.totalCount > 0"
                            class="text-xs text-gray-500 lowercase"
                          >
                            ({{
                              item?.totalCount > 1
                                ? $t('COMMOM_LABEL_TIMES', {
                                    time: item?.totalCount,
                                  })
                                : $t('COMMOM_LABEL_TIME', {
                                    time: item?.totalCount,
                                  })
                            }})
                          </span>
                        </div>

                        <span
                          v-if="item?.type !== 'EXCEPTION'"
                          class="text-xs text-gray-500 lowercase"
                        >
                          {{
                            item?.totalDayoff > 1
                              ? $t('COMMOM_LABEL_DAYS', {
                                  day: item?.totalDayoff,
                                })
                              : $t('COMMOM_LABEL_DAY', {
                                  day: item?.totalDayoff,
                                })
                          }}
                        </span>
                      </div>
                    </template>
                  </div>
                </div>
              </div>

              <!-- LEGEND -->
              <div class="flex flex-col justify-end space-y-3 text-sm w-max">
                <template v-for="legend in parserDataType" :key="legend">
                  <div class="flex flex-col items-start space-x-1.5 py-0.5">
                    <div class="flex space-x-2 items-center">
                      <div class="flex-center space-x-2 text-xs text-gray-500">
                        <template
                          v-for="(item, index) in legend.value"
                          :key="item"
                        >
                          <div
                            class="flex items-center space-x-1.5"
                            style="width: 150px"
                          >
                            <span
                              v-vig-tooltip="
                                (legend.type !== 'EXCEPTION'
                                  ? $t(legend.label)
                                  : '') +
                                ' ' +
                                legend?.labelValue[index]
                              "
                              class="rounded-sm cursor-pointer"
                              :style="`background-color: ${legend.color[index]}; width: 15px; height: 15px;`"
                            ></span>
                            <span
                              ><span v-if="legend.type !== 'EXCEPTION'">{{
                                $t(legend.label)
                              }}</span>
                              {{ legend?.labelValue[index] }}</span
                            >
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </GraphStatisticByUser>
      </template>

      <!--  LOADING    -->
      <template v-else>
        <div class="flex flex-col space-y-2 px-2 py-3">
          <div class="flex items-center justify-between">
            <span
              class="w-24 p-1 h-2 rounded-full bg-gray-200 animate-pulse"
            ></span>
            <span
              class="w-12 p-1 h-2 rounded-full bg-gray-200 animate-pulse"
            ></span>
          </div>
          <div class="flex items-center space-x-1">
            <template v-for="item in 12" :key="item">
              <div class="grid grid-cols-5 gap-1 px-1">
                <div
                  v-for="sub in 30"
                  :key="sub"
                  class="w-4 h-4 rounded-sm bg-gray-100 animate-pulse"
                ></div>
              </div>
            </template>
          </div>
        </div>
      </template>
      <div class="px-4 w-full flex flex-1 min-h-0 overflow-hidden">
        <div class="overflow-auto small-scrollbar w-full">
          <syn-table-custom class="rounded">
            <template #header>
              <syn-tr>
                <SynTh :label="$t('CALENDAR_TITLE_MONTH')"></SynTh>
                <SynTh is-center :label="$t('COMMON_MODULE_DAYOFF')"></SynTh>
                <SynTh
                  is-center
                  :label="$t('DAYOFF_EXCEPTION_TYPE_START_LATE')"
                ></SynTh>
                <SynTh
                  is-center
                  :label="$t('DAYOFF_EXCEPTION_TYPE_STOP_EARLY')"
                ></SynTh>
                <SynTh
                  is-center
                  :label="$t('DAYOFF_LABEL_MEMBER_OFF_REMOTE')"
                ></SynTh>
                <SynTh
                  is-center
                  :label="$t('DAYOFF_EXCEPTION_TYPE_BREAK_TIME')"
                ></SynTh>
                <SynTh is-center :label="$t('LABEL_TOTAL_EVENT')"></SynTh>
                <!--            <SynTh :label="$t('COMMON_LABEL_MONTH')"></SynTh>-->
              </syn-tr>
            </template>
            <template #body>
              <syn-table-tr-loading v-if="isLoading">
                <div v-for="item in 7" :key="item" class="table-cell p-2">
                  <div class="p-2 w-3/4 bg-gray-200 rounded-md"></div>
                </div>
              </syn-table-tr-loading>
              <template v-for="item in dayoffList" v-else :key="item">
                <syn-tr class="">
                  <SynTd>
                    <div class="text-current-500">
                      {{ $t(translateMonth(item.month)) }}
                    </div>
                  </SynTd>
                  <SynTd class="flex-center">
                    <div
                      class="px-2 py-1 rounded"
                      :class="
                        item.dayOffList.length > 0
                          ? 'text-current-500 bg-current-50 cursor-pointer hover:border-current hover:bg-current-100'
                          : 'text-gray-500'
                      "
                      style="width: fit-content"
                      @click="
                        item.dayOffList.length > 0
                          ? showDetailEvents(
                              item.dayOffList,
                              item.month,
                              'COMMON_MODULE_DAYOFF'
                            )
                          : ''
                      "
                    >
                      {{ item.dayOffList.length }}
                    </div>
                  </SynTd>
                  <SynTd>
                    <div class="w-full flex-center">
                      <div
                        class="px-2 py-1 rounded"
                        :class="
                          item.startLateList.length > 0
                            ? 'text-current-500 bg-current-50 cursor-pointer hover:border-current hover:bg-current-100'
                            : 'text-gray-500'
                        "
                        style="width: fit-content"
                        @click="
                          item.startLateList.length > 0
                            ? showDetailEvents(
                                item.startLateList,
                                item.month,
                                'DAYOFF_EXCEPTION_TYPE_START_LATE'
                              )
                            : ''
                        "
                      >
                        {{ item.startLateList.length }}
                      </div>
                    </div>
                  </SynTd>
                  <SynTd>
                    <div class="w-full flex-center">
                      <div
                        class="px-2 py-1 rounded"
                        :class="
                          item.stopEarlyList.length > 0
                            ? 'text-current-500 bg-current-50 cursor-pointer hover:border-current hover:bg-current-100'
                            : 'text-gray-500'
                        "
                        style="width: fit-content"
                        @click="
                          item.stopEarlyList.length > 0
                            ? showDetailEvents(
                                item.stopEarlyList,
                                item.month,
                                'DAYOFF_EXCEPTION_TYPE_STOP_EARLY'
                              )
                            : ''
                        "
                      >
                        {{ item.stopEarlyList.length }}
                      </div>
                    </div>
                  </SynTd>
                  <SynTd>
                    <div class="w-full flex-center">
                      <div
                        class="px-2 py-1 rounded"
                        :class="
                          item.workFromHomeList.length > 0
                            ? 'text-current-500 bg-current-50 cursor-pointer hover:border-current hover:bg-current-100'
                            : 'text-gray-500'
                        "
                        style="width: fit-content"
                        @click="
                          item.workFromHomeList.length > 0
                            ? showDetailEvents(
                                item.workFromHomeList,
                                item.month,
                                'DAYOFF_LABEL_MEMBER_OFF_REMOTE'
                              )
                            : ''
                        "
                      >
                        {{ item.workFromHomeList.length }}
                      </div>
                    </div>
                  </SynTd>
                  <SynTd>
                    <div class="w-full flex-center">
                      <div
                        class="px-2 py-1 rounded"
                        :class="
                          item.breakTimeList.length > 0
                            ? 'text-current-500 bg-current-50 cursor-pointer hover:border-current hover:bg-current-100'
                            : 'text-gray-500'
                        "
                        style="width: fit-content"
                        @click="
                          item.breakTimeList.length > 0
                            ? showDetailEvents(
                                item.breakTimeList,
                                item.month,
                                'DAYOFF_EXCEPTION_TYPE_BREAK_TIME'
                              )
                            : ''
                        "
                      >
                        {{ item.breakTimeList.length }}
                      </div>
                    </div>
                  </SynTd>
                  <SynTd>
                    <div class="w-full flex-center">
                      <span>{{
                        item.startLateList.length +
                        item.stopEarlyList.length +
                        item.workFromHomeList.length +
                        item.breakTimeList.length
                      }}</span>
                    </div>
                  </SynTd>
                </syn-tr>
              </template>
            </template>
          </syn-table-custom>
        </div>
        <div
          v-if="!isLoading"
          class="flex flex-col rounded overflow-hidden"
          :class="isShowDetailEvent ? 'w-2/5 border' : 'w-0'"
          style="transition: width 200ms ease-in-out"
        >
          <div class="flex justify-between p-2 items-start shadow">
            <div class="flex flex-col space-y-1 text-sm">
              <div class="flex space-x-2">
                <span>{{ $t(typeEvent) }}</span>
                <span>({{ $t(translateMonth(dateTime)) }})</span>
              </div>
              <div
                class="
                  px-1.5
                  py-0.5
                  rounded
                  border border-current
                  space-x-2
                  text-xs text-current-500
                "
                style="width: fit-content"
              >
                <span>{{ $t('COMMON_LABEL_TOTAL') }}:</span>
                <span>{{ detailEventList.length }}</span>
              </div>
            </div>
            <div
              class="
                flex-center
                rounded-full
                p-1
                hover:bg-gray-200
                cursor-pointer
              "
              @click="isShowDetailEvent = false"
            >
              <SynIcon name="close" custom-class="h-4 w-4 fill-gray-500" />
            </div>
          </div>
          <div class="flex-1 overflow-auto small-scrollbar">
            <div
              v-for="item in detailEventList"
              :key="item"
              class="border-b border-gray-200"
            >
              <div class="flex flex-col px-3 py-2">
                <div class="text-sm">
                  <span>
                    <SynLabelDateTime
                      format="date"
                      ignore-timezone
                      :datetime="item.date"
                    />
                  </span>
                </div>

                <div class="flex flex-col mt-2">
                  <!-- INFO -->
                  <div class="flex space-x-1 items-center justify-between">
                    <div class="flex items-center space-x-1">
                      <div class="flex flex-col">
                        <div class="min-w-max">
                          <DayoffTypeAtom
                            v-if="
                              item?.type && item?.type !== DayoffType.EXCEPTION
                            "
                            :type="item?.type"
                            size="small"
                            class="min-w-max w-max"
                          />
                          <div
                            v-if="
                              item?.type === DayoffType.EXCEPTION &&
                              item?.exception
                            "
                            class="
                              flex
                              space-x-1
                              items-center
                              text-xs text-gray-500
                            "
                          >
                            <DayoffExceptionTypeAtom
                              is-active
                              class="text-xs min-w-max w-max"
                              :exception-type="item?.exception?.type"
                              size="small"
                            />
                          </div>
                        </div>
                      </div>
                      <DayoffStatusAtom
                        v-if="item?.status"
                        :status="item?.status"
                        size="small"
                      />
                      <span
                        v-if="item?.type !== 'EXCEPTION'"
                        class="text-gray-500 text-xs"
                      >
                        (<DayoffDateSessionAtom
                          is-text-only
                          :date-session="item?.daySession"
                        />)
                      </span>
                    </div>

                    <div
                      v-if="
                        item?.dayAmount && item?.type !== DayoffType.EXCEPTION
                      "
                      class="text-xs text-gray-500"
                    >
                      {{
                        $t('DAYOFF_STATISTIC_USED_DAY', {
                          total: item?.dayAmount,
                        })
                      }}
                    </div>

                    <div
                      v-if="item?.type === DayoffType.EXCEPTION"
                      class="min-w-max text-gray-500 text-2xs"
                    >
                      <span class="flex items-center space-x-1">
                        <span>
                          {{
                            $filters.dayjs(
                              item?.exception?.startTime,
                              ' hh:mm A'
                            )
                          }}
                        </span>
                        <span
                          v-if="
                            item?.exception?.type ===
                            DayoffExceptionType.BREAK_TIME
                          "
                        >
                          -
                          {{
                            $filters.dayjs(item.exception.endTime, 'hh:mm A')
                          }}
                        </span>
                      </span>
                    </div>
                  </div>
                  <!-- NOTE -->
                  <div
                    v-if="item?.requestNote"
                    class="py-1 pl-1 text-xs text-gray-600 text-overflow-hidden"
                  >
                    <span style="min-width: fit-content" class="font-medium"
                      >{{ $t('DAYOFF_DETAIL_LABEL_REASON') }}:
                      <span
                        class="ml-1 font-normal italic"
                        :title="item?.requestNote"
                        >{{ item?.requestNote }}</span
                      ></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </syn-modal>
</template>
