<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { computed, ref, onMounted } from 'vue';
import plansStore from '@/store/plans';
import paymentStore from '@/store/payment';
import PlansOverviewModal from '@/ui/modules/settings/payments/overview/PlansOverviewModal.vue';
import { ExceededPlanNotify } from '@/domain/enums/PlanEnums';
import InviteUserModal from '@/ui/modules/member/invite-user/InviteUserModal.vue';
import { EPlanState } from '@/application/types/plan.types';
import { EPlanId, getUiByPlanState } from '@/application/constants/plan.const';
import CurrentPlanItem from '@/ui/modules/settings/payments/CurrentPlanItem.vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import organizationStore from '@/store/organization';
import { IOrganizationDetail } from '@/application/types/organization/organization.type';
import permissionStore from '@/store/permission';
import { IPaymentConfig } from '@/application/types/payment-configs.types';
import OrganizationPlan from '@/domain/entities/payment/OrganizationPlan';
import { EPaymentPurpose } from '@/application/types/payment.types';

const _planStore = plansStore();
const _paymentStore = paymentStore();
const route = useRoute();
const router = useRouter();
const _organizationStore = organizationStore();

const paymentConfigs = computed<IPaymentConfig>(
  () => _paymentStore.paymentConfigs
);
const organizationInfo = computed<IOrganizationDetail>(
  () => _organizationStore.organizationInfo
);
const isBuyMore = ref(false);
onMounted(() => {
  if (route.query?.openSelectPlan == 'true') isShowPlansOverview.value = true;
  if (route.query?.isBuyMore == 'true') isBuyMore.value = true;

  router.replace({ name: 'PlansSetting' });
});
const currentOrgPlan = computed<OrganizationPlan>(() => {
  return new OrganizationPlan(
    _planStore.currentOrgPlan,
    organizationInfo.value,
    paymentConfigs.value,
    planCapacityByKeys.value,
    _planStore.capacityByPlanIds
  );
});

const isShowPremiumMember = computed(() => {
  return (
    currentOrgPlan.value?.stateCode == EPlanState.PAID ||
    currentOrgPlan.value?.stateCode == EPlanState.EVALUATION
  );
});
const uiByState = computed(() => {
  return getUiByPlanState(
    currentOrgPlan.value.planId,
    currentOrgPlan.value.stateCode
  );
});
const uiBySubState = computed(() => {
  const subState = currentOrgPlan.value.subStateCode || 'DEFAULT';
  if (
    !uiByState.value ||
    !Object.prototype.hasOwnProperty.call(uiByState.value, subState)
  )
    return null;

  return uiByState.value[subState];
});

const isCurrentOrgLifeTime = computed(() => currentOrgPlan?.value?.isLifeTime);
const isEvaluationVersion = computed(
  () => currentOrgPlan?.value?.stateCode === EPlanState.EVALUATION
);
const planCapacityByKeys = computed(() => _planStore.planCapacityByKeys);
const totalAvailableUsers = computed(
  () =>
    planCapacityByKeys.value?.totalMember?.limitation -
    planCapacityByKeys.value?.totalMember?.usage
);
const isLoading = computed(() => _planStore.isLoadingCurrentPlan);
const isLoadingAllPlans = computed(() => _planStore.isLoadingAllPlans);
const planStatusInfo = computed(() => _planStore.planStatusInfo);

const isOpenModalCommingSoon = ref(false);

const initData = () => {
  let promiseAll = [
    _planStore.getCurrentOrgPlans(),
    _planStore.getAllPlansDetail(),
  ];
  if (permissionStore().organizationModel !== 'PERSONAL')
    promiseAll.push(_planStore.getAllPlanCapacities());

  Promise.all(promiseAll);
};

initData();

const onGotoWebContact = () => {
  window.open('https://fiine.vn/contact', '_blank');
};

const isShowPlansOverview = ref(false);
const isCreatingTokenForPlanId = ref('');
const onUpgrade = async (planId, isBuyMore) => {
  if (!planId) return;
  if (isBuyMore) {
    const canBuyMore = await currentOrgPlan.value.canBuyMore();
    if (!canBuyMore?.continue) return;
    isBuyMore = canBuyMore?.isBuyMore;
  }

  try {
    isCreatingTokenForPlanId.value = planId;
    // const totalUsers = currentOrgPlan.value.getTotalUserBySize();
    // await _paymentStore.checkAndMakeNewPayment({
    //   planId,
    //   purpose: isBuyMore ? EPlanAction.GET_MORE : null,
    //   period: 3,
    //   totalUsers,
    // });
    _paymentStore.isCreatingPaymentToken = true;

    await new OrganizationPlan(
      _planStore.currentOrgPlan,
      organizationStore().organizationInfo,
      _paymentStore.paymentConfigs,
      _planStore.planCapacityByKeys,
      _planStore.capacityByPlanIds
    ).createPayment({
      planId,
      purpose: isBuyMore ? EPaymentPurpose.GET_MORE : null,
      period: 3,
      totalUsers: null,
    });
    isCreatingTokenForPlanId.value = '';
    _paymentStore.isCreatingPaymentToken = false;
  } catch (error) {
    console.log(
      '🚀 ~ file: PlanManage.vue ~ line 49 ~ onUpgrade ~ error',
      error
    );
  }
};

const isInvite = ref(false);
const onInviteUser = () => {
  isInvite.value = true;
};

const onInviteSuccess = async () => {
  isInvite.value = false;
};

const onCancelInvite = () => {
  isInvite.value = false;
};

const defaultTotalUsers = computed<number>(() => {
  const totalDefaultBy = uiByState.value?.defaultTotalUserBy;

  if (totalDefaultBy == 'LIMITATION')
    return planCapacityByKeys.value?.totalMember?.limitation;

  if (totalDefaultBy == 'USAGE')
    return planCapacityByKeys.value?.totalMember?.usage;

  return Math.max(
    planCapacityByKeys.value?.totalMember?.usage,
    planCapacityByKeys.value?.totalMember?.limitation
  );
});
</script>

<template>
  <div
    class="
      grid grid-flow-row-dense grid-cols-1
      2xl:grid-cols-5
      w-full
      px-2
      py-6
      gap-8
    "
    :class="isCreatingTokenForPlanId ? 'cursor-wait' : ''"
  >
    <!-- CURRENT PLAN   -->
    <div
      v-if="!isLoading"
      class="
        col-span-1
        grid grid-cols-1
        2xl:col-span-3
        gap-6
        w-full
        h-full
        bg-gray-50
        shadow
        p-6
      "
    >
      <!--  WELCOME PLAN    -->
      <div
        class="
          flex flex-1
          2xl:items-center 2xl:flex-row
          flex-col
          2xl:justify-between
          px-6
          py-8
          rounded
          relative
        "
        :class="`${
          isCurrentOrgLifeTime || isEvaluationVersion
            ? 'bg-gradient-to-r from-current-300 to-green-200'
            : 'border border-current bg-current-50 bg-opacity-30'
        }`"
      >
        <div
          class="text-2xl font-medium 3xl:min-w-max text-gray-700"
          v-html="
            $t('SETTING_PLAN_WELCOME_TITLE', { plan: currentOrgPlan.planId })
          "
        ></div>
        <div class="flex flex-col 3xl:min-w-max">
          <span class="text-sm text-gray-500">{{
            $t('MESSAGE_CALL_LABEL_STARTED')
          }}</span>
          <span class="text-sm font-medium">
            <SynLabelDateTime
              format="date"
              :datetime="currentOrgPlan.startDate"
            />
          </span>
        </div>
        <div
          v-if="isCurrentOrgLifeTime || isEvaluationVersion"
          class="absolute"
          style="right: -1.4rem; top: -0.6rem"
        >
          <div class="flex-center relative">
            <SynIcon name="tag" custom-class="h-28" />
            <span
              v-if="isEvaluationVersion"
              class="absolute text-white font-bold rotate-45"
              style="top: 33%; left: 30%; font-size: 0.7rem"
            >
              EVALUATION
            </span>
            <span
              v-else
              class="absolute text-white font-bold rotate-45"
              style="top: 33%; left: 19%; font-size: 0.7rem"
            >
              LIFETIME VERSION
            </span>
          </div>
        </div>
      </div>

      <!--  INFORMATION CURRENT PLAN    -->
      <div
        class="
          flex-1 flex-col
          items-center
          divide-y divide-gray-100
          rounded
          bg-white bg-opacity-60
          border border-gray-50
        "
        style="box-shadow: 0px 0px 2px 1px rgb(0 0 0 / 10%)"
      >
        <div
          v-if="!isEvaluationVersion"
          class="flex items-center justify-between px-6 py-5 w-full"
        >
          <span class="font-medium">
            {{ $t('SETTING_PLAN_PAYMENT_STATUS_LABEL') }}
          </span>
          <div
            class="py-1 px-2 rounded flex-center space-x-2 uppercase text-sm"
            :style="{
              color: uiBySubState?.style?.contrastColor,
              backgroundColor: uiBySubState?.style?.mainColor,
            }"
          >
            <span class="uppercase">
              {{ $t(uiByState?.nameCode) || uiByState?.name }}
            </span>
          </div>
        </div>
        <!-- TOTAL ACTIVE MEMBERS -->
        <div class="flex items-center justify-between px-6 py-5 w-full">
          <span class="font-medium">{{
            $t(
              currentOrgPlan?.planId == EPlanId.FREE ||
                currentOrgPlan?.planId == EPlanId.BASIC
                ? 'COMMON_LABEL_TOTAL_ACTIVE_MEMBERS'
                : 'SETTING_PLAN_PAYMENT_TOTAL_MEMBER_LABEL'
            )
          }}</span>

          <span class="flex flex-col items-end space-y-2">
            <!-- <span class="font-medium text-sm text-right">{{
              dayjs(currentOrgPlan.endDate).format('MMM DD, YYYY')
            }}</span> -->
            <span
              class="lowercase py-1 px-2 rounded text-sm"
              :class="{
                'bg-current text-white': totalAvailableUsers >= 0,
                'bg-orange-400 text-white': totalAvailableUsers < 0,
              }"
            >
              {{ planCapacityByKeys?.totalMember?.usage }}
            </span>
          </span>
        </div>
        <!-- END TOTAL ACTIVE MEMBERS -->

        <!-- PREMIUM MEMBERS -->
        <div class="flex flex-col">
          <div class="flex items-center justify-between px-6 py-4 w-full">
            <span class="font-medium">
              {{
                $t(
                  currentOrgPlan?.planId == EPlanId.BASIC ||
                    currentOrgPlan?.planId == EPlanId.FREE
                    ? 'COMMON_LABEL_MAXIMUM_MEMBERS_FOR_THE_BASIC_PLAN'
                    : 'PLAN_PREMIUM_MEMBERS'
                )
              }}
            </span>

            <div class="flex flex-col items-end space-y-2">
              <span
                class="lowercase py-1 px-2 rounded text-sm text-white"
                :class="
                  totalAvailableUsers > 0 ? 'bg-orange-500' : 'bg-current'
                "
              >
                {{ planCapacityByKeys?.totalMember?.limitation || 0 }}
              </span>
            </div>
          </div>
          <template v-if="isShowPremiumMember">
            <div
              class="w-full flex flex-col items-end justify-end space-y-2 pb-2"
            >
              <div
                v-if="totalAvailableUsers > 0"
                class="
                  w-full
                  flex
                  justify-end
                  items-center
                  space-x-1
                  flex-wrap
                  text-xs text-current-500
                  px-6
                "
              >
                <span>
                  {{
                    $t('PAYMENT_AVAILABLE_MEMBERS_NOT_USE', {
                      available: totalAvailableUsers,
                    })
                  }}
                </span>
                <AtomPremiumLabel />
                <span>
                  {{ $t('PAYMENT_AVAILABLE_MEMBERS_NOT_USE_2') }}
                  <span
                    class="text-current-500 cursor-pointer lowercase underline"
                    @click="onInviteUser()"
                  >
                    {{ $t('USERMANAGEMENT_LABEL_INVITE_USER') || 'Invite' }}
                  </span>
                  {{ $t('PAYMENT_AVAILABLE_MEMBERS_NOT_USE_3') }}
                </span>
              </div>
              <div
                v-if="!isCurrentOrgLifeTime && !isEvaluationVersion"
                class="
                  w-max
                  rounded
                  shadow-md
                  text-sm text-right text-white
                  px-2
                  py-1
                  bg-current
                  mx-6
                  mb-2
                  hover:bg-current-600
                "
                :class="
                  isCreatingTokenForPlanId ? 'cursor-wait' : 'cursor-pointer'
                "
                @click="onUpgrade(currentOrgPlan?.planId, true)"
              >
                {{ $t('PAYMENT_BUY_MORE_USER') }}
              </div>
            </div>
          </template>
        </div>

        <!-- END PREMIUM MEMBERS -->

        <!-- END DATE -->
        <div class="flex flex-col px-6 pt-5 pb-2 w-full">
          <div class="flex items-center justify-between pb-2">
            <span class="font-medium w-2/5">
              {{
                $t(
                  currentOrgPlan?.stateCode == EPlanState.TRIAL
                    ? 'SETTING_PLAN_PAYMENT_FREE_TRIAL_LABEL'
                    : 'PAYMENT_EXPRIRED_DATE'
                )
              }}
            </span>
            <span class="flex flex-col items-end space-y-2">
              <!-- <span class="font-medium text-sm text-right">{{
                dayjs(currentOrgPlan.endDate).format('MMM DD, YYYY')
              }}</span> -->
              <template v-if="isCurrentOrgLifeTime">
                <span class="text-sm text-gray-600">Unlimited</span>
              </template>
              <template v-else>
                <span
                  class="font-medium text-sm"
                  :class="
                    planStatusInfo?.notificationType ==
                      ExceededPlanNotify.PaymentOverdue &&
                    'px-2 py-1 w-max rounded bg-orange-600 text-white'
                  "
                >
                  <SynLabelDateTime
                    format="date"
                    :datetime="currentOrgPlan.endDate"
                  />
                </span>
              </template>
            </span>
          </div>

          <span
            v-if="uiBySubState.message"
            class="text-sm text-overflow-hidden italic"
            :style="`color: ${
              uiBySubState?.style?.contrastColor == '#FFF'
                ? uiBySubState?.style?.mainColor
                : uiBySubState?.style?.contrastColor
            }`"
          >
            <div
              v-if="
                currentOrgPlan?.offer?.remainDays > 0 &&
                uiBySubState?.message?.titleWithBonus
              "
              v-html="
                $t(uiBySubState?.message?.titleWithBonus, {
                  remainDays: currentOrgPlan?.remainDay || 15,
                  bonusDays: currentOrgPlan?.offer?.bonusDays || 30,
                  planId: currentOrgPlan?.planId,
                })
              "
            ></div>
            <div
              v-else
              v-html="
                $t(uiBySubState.message?.title, {
                  remainDays: currentOrgPlan?.remainDay,
                  planId: currentOrgPlan?.planId,
                  bonusDays: currentOrgPlan?.offer?.bonusDays || 0,
                })
              "
            ></div>
          </span>
        </div>

        <div
          v-if="
            currentOrgPlan?.planId !== EPlanId.FREE &&
            currentOrgPlan?.planId !== EPlanId.BASIC
          "
          class="flex items-center justify-between px-6 py-5 w-full"
        >
          <div class="flex flex-col">
            <span class="font-medium">
              {{ $t('SETTING_PLAN_PAYMENT_PAYMENT_OPTION_LABEL') }}
            </span>
            <span class="text-gray-500 text-sm">
              {{ $t('SETTING_PLAN_PAYMENT_PAYMENT_OPTION_SUB_LABEL') }}
            </span>
          </div>
          <template v-if="isEvaluationVersion">
            <span class="text-sm text-current-500 font-medium">EVALUATION</span>
          </template>
          <template v-else-if="isCurrentOrgLifeTime">
            <span class="text-sm text-current-500 font-medium">
              {{ $t('COMMON_LABEL_LIFETIME') }}
            </span>
          </template>
          <span
            v-else-if="currentOrgPlan?.period && currentOrgPlan?.period > 0"
            class="
              w-16
              text-current-500
              hover:text-current-800
              text-sm text-right
              cursor-pointer
            "
            @click="isOpenModalCommingSoon = true"
          >
            {{
              `${currentOrgPlan?.period} ${$t('COMMON_LABEL_MONTH', {
                suffix: currentOrgPlan?.period > 1 ? 's' : '',
              })}`
            }}
          </span>
          <span
            v-else-if="currentOrgPlan?.stateCode === EPlanState.TRIAL"
            class="font-medium uppercase text-sm text-orange-600"
          >
            TRIAL
          </span>
        </div>
      </div>

      <!--  CONTACT    -->
      <div
        class="
          flex
          items-start
          space-x-4
          rounded
          px-4
          py-6
          border border-gray-100
          bg-white bg-opacity-60
        "
        style="box-shadow: 0px 0px 2px 1px rgb(0 0 0 / 10%)"
      >
        <div class="w-14 h-14 flex-center pt-2">
          <SynIcon name="support" custom-class="w-12 h-12 fill-current" />
        </div>
        <div class="flex flex-col">
          <span class="font-medium text-lg">
            {{ $t('SETTING_PLAN_PAYMENT_CONTACT_SUPPORT_TITLE') }}
          </span>
          <span class="text-gray-600">
            {{ $t('SETTING_PLAN_PAYMENT_CONTACT_SUPPORT_SUB_TITLE') }}
          </span>
          <div class="pt-3">
            <SynButton
              :label="$t('SETTING_PLAN_PAYMENT_CONTACT_SUPPORT_TITLE')"
              @click="onGotoWebContact"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- SWITCH PLAN   -->
    <div
      v-if="!isLoading && !isLoadingAllPlans"
      class="
        2xl:col-span-2
        flex
        items-center
        flex-col
        space-y-6
        p-6
        shadow
        bg-gray-50
      "
    >
      <CurrentPlanItem
        v-if="currentOrgPlan?.planId"
        :is-upgrading="!!isCreatingTokenForPlanId"
        :default-total-users="defaultTotalUsers"
        @on-compare="isShowPlansOverview = true"
        @on-renew="onUpgrade(currentOrgPlan?.planId, false)"
      />
    </div>
  </div>

  <SynModal
    v-if="isOpenModalCommingSoon"
    is-hidden-header
    container-class="w-max px-8"
    @cancel="isOpenModalCommingSoon = false"
  >
    <template #body>
      <div class="flex-center flex-col">
        <syn-animation
          name="contractionsystem"
          stype="width: 250px; height: 250px;"
        />
        <span class="font-medium text-2xl uppercase text-current-600">{{
          $t('COMMOM_LABEL_COMMING_SOON')
        }}</span>
        <span class="text-gray-500 pt-2">{{
          $t('ALERT_FEATURE_DEVELOPMENT_LABEL')
        }}</span>
      </div>
    </template>
  </SynModal>
  <PlansOverviewModal
    v-if="isShowPlansOverview"
    :is-creating-plan-id="isCreatingTokenForPlanId"
    :is-disable-pay-button="isCurrentOrgLifeTime || isEvaluationVersion"
    @on-close="isShowPlansOverview = false"
  />

  <InviteUserModal
    v-if="isInvite"
    @on-invite-success="onInviteSuccess"
    @on-cancel="onCancelInvite"
    @on-close="onCancelInvite"
  />
</template>
