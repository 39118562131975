<script setup lang="ts">
import SearchTasks from '@/ui/modules/task/components/search/SearchTasks.vue';

defineProps<{ title?: string; filterFunction?: Function }>();
const emit = defineEmits<{
  (e: 'onClose'): void;
  (e: 'onChoose', task: any): void;
}>();

const onConfirmSelectTask = (task) => {
  emit('onChoose', task);
};
</script>
<template>
  <SynModal
    z-index="z-50"
    container-style="width: 760px; max-width: 1200px;"
    disable-click-outside
    @cancel="$emit('onClose')"
  >
    <template #header>
      <div class="px-4 flex items-center justify-between">
        {{ title }}
      </div>
    </template>
    <template #body>
      <div
        class="flex-1 w-full h-full flex flex-col rounded-md overflow-hidden"
      >
        <SearchTasks
          hidden-close-button
          view-task-mode="LIST"
          :filter-function="filterFunction"
          @on-select-one="onConfirmSelectTask"
        />
      </div>
    </template>
  </SynModal>
</template>
