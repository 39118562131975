<script setup lang="ts">
import { computed, ref } from 'vue';
import inviteUserStore from '@/store/organization/invite-user';
import { isValidEmail, isValidPhoneNumber } from '@/ui/hooks/commonFunction';
import InviteUserError from '../invite-user/invite-user-error.vue';
import { AcceptedInvitation } from '@/application/services/authenticate/authenticate-service';
import { CountryCode } from 'libphonenumber-js';
import atomFormInputComposables from '@/ui/common/atoms/SynInput/atom-form-input-composables';

const props = defineProps<{
  token: string;
  isHiddenClose?: boolean;
  location: string;
}>();

const emit = defineEmits<{
  (e: 'onClose'): void;
  (e: 'onRejectSuccess', attachedOrganization: any, result: any): void;
  (e: 'onAcceptSuccess', attachedOrganization: any, result: any): void;
}>();
const isLoading = ref(true);

const _inviteUserStore = inviteUserStore();
const getCodeErrorMessage = ref('');
const isChecking = ref(true);

const firstName = ref('');
const firstNameError = ref('');
const lastName = ref('');
const lastNameError = ref('');

const hasError = ref(false);
const hasErrorMessage = ref('');
const hasErrorMessageOrgName = ref('');

const invitedMethod = ref('email');
const isSentViaEmail = computed(() => invitedMethod.value == 'email');
const isSentViaPhone = computed(() => invitedMethod.value == 'phone');
const accountExisted = ref(false);
const isNeedVerifyPhone = ref(false);
const organizationInfo = ref({} as any);
const isInited = ref(false);
const inviteToken = ref('');
const errorMessage = ref('');
const errorResponseCode = ref(0);

const invitationOrganization = ref({});
const inviteeInfo = ref({
  name: '',
  avatar: '',
  email: '',
});
const invitationInfo = ref<any>({});
const onInit = async () => {
  // get invite info by code
  inviteToken.value = props.token;
  const params = {
    tokenCode: props.token,
  };
  try {
    const res = await _inviteUserStore.getInvitedUser(params);
    const data = res['result'];
    invitationInfo.value = data;

    invitationOrganization.value = data?.organization;
    inviteeInfo.value = data?.inviteeInfo;

    // setValues({
    //   firstName: data?.firstName,
    //   lastName: data?.lastName,
    //   email: data?.email,
    //   phone: data?.phone,
    // });

    if (data?.firstName) firstName.value = data?.firstName;
    if (data?.lastName) lastName.value = data?.lastName;
    if (data?.email) email.value = data?.email;
    if (data?.phone) phoneNumber.value = data?.phone;
    invitedMethod.value = data?.invitedMethod;
    accountExisted.value = data?.accountExisted;
    isNeedVerifyPhone.value = data?.isNeedVerifyPhone;
    organizationInfo.value = data?.organization;
    isInited.value = true;
  } catch (error: any) {
    errorResponseCode.value = error?.error?.code;
    errorMessage.value = error?.error?.message;
  }
  // 1. Verified phone: Join
  // 2. Not Verified phone: Verify phone before
  isLoading.value = false;
};
onInit();

const phoneNumber = ref('');

// Phone
const {
  isChecking: isCheckingPhone,
  validationResult: validationResultPhone,
  onValidation: onValidationPhone,
} = atomFormInputComposables(
  [
    () => {
      return resultPhoneObject.value?.isValid
        ? ''
        : 'COMMON_INVALID_PHONE_NUMBER';
    },
    (value) => {
      const isValid = isValidPhoneNumber(value);
      return isValid ? '' : 'COMMON_INVALID_PHONE_NUMBER';
    },
    (value) => {
      const isValid =
        value.startsWith('+') &&
        ['+33', '+84', '+1', '+81', '+82'].includes(value?.slice(0, 3));
      return isValid ? '' : 'COMMON_INVALID_PHONE_NUMBER';
    },
    async (value) => {
      const resCheckExistPhone = await _inviteUserStore.checkPhone(value);
      return resCheckExistPhone.existed
        ? 'COMMON_LABEL_REQUIRE_PHONE_IS_EXISTED'
        : '';
    },
  ],
  1000
);

let timer;

const resultPhoneObject = ref<{
  country?: CountryCode;
  countryCallingCode: string;
  nationalNumber: string;
  number: string;
  isValid: boolean;
  formattedNumber: string;
}>({
  countryCallingCode: '',
  nationalNumber: '',
  number: '',
  isValid: true,
  formattedNumber: '',
});
const onChangePhone = (phoneObj: {
  country?: CountryCode;
  countryCallingCode: string;
  nationalNumber: string;
  number: string;
  isValid: boolean;
  formattedNumber: string;
}) => {
  // 1. clear error
  resultPhoneObject.value = phoneObj;

  // 1. Set value for phone

  if (!isInited.value || !phoneObj || !phoneObj.number) return;

  const local = phoneObj.countryCallingCode.toLowerCase();
  currentLanguage.value = local == 'vn' ? 'vi' : local;
  phoneNumber.value = phoneObj.number;

  onValidationPhone(phoneObj.number);
};

let lastCheckingEmail;
const isFetchChecking = ref(false);
const emailIsValid = ref(false);
const email = ref('');
const emailError = ref('');
const onChangeEmail = async (event) => {
  if (!isInited.value) return true;
  // 1. clear error
  emailError.value = '';
  emailIsValid.value = false;
  // 2. clear timeout
  clearTimeout(timer);
  isChecking.value = true;
  // 3. start timeout
  const value = event.target.value;
  if (value) {
    timer = setTimeout(async () => {
      lastCheckingEmail = value;
      // 2.1 Check valid
      if (!isValidEmail(value)) {
        emailError.value = 'COMMON_INVALID_EMAIL';
        return;
      }
      // 2.2 Check existed in system
      isFetchChecking.value = true;
      try {
        const resCheckExist = await _inviteUserStore.checkEmail(value);
        if (
          resCheckExist?.value == lastCheckingEmail &&
          resCheckExist?.existed
        ) {
          emailError.value = 'COMMON_LABEL_REQUIRE_EMAIL_IS_EXISTED';
        }
        isChecking.value = false;
        isFetchChecking.value = false;
        emailIsValid.value = true;
      } catch {
        isChecking.value = false;
        isFetchChecking.value = false;
      }
    }, 750);
  } else {
    emailError.value = 'COMMON_THIS_FIELD_REQUIRED';
  }
};

const isFetchingSignup = ref(false);
const isFetchingJoin = ref(false);
const currentLanguage = ref('');

const onFetchJoinNow = async () => {
  try {
    const acceptedClass = new AcceptedInvitation(
      firstName.value,
      lastName.value,
      phoneNumber.value,
      email.value,
      inviteToken.value
    );

    const res = await acceptedClass.accepted(
      invitationOrganization.value,
      props.location
    );
    emit('onAcceptSuccess', invitationOrganization.value, res?.result);
  } catch (error: any) {
    console.log(error);
    isFetchingJoin.value = false;
    const errorCode = error?.error?.code;
    if (errorCode) {
      hasError.value = true;
      hasErrorMessage.value = `ERROR_EXCEEDING_THE_LIMIT_VISITOR_${errorCode}`;
      hasErrorMessageOrgName.value = error?.error?.organizationName;
    }
  }
};
const onRejectInvitation = async () => {
  try {
    const acceptedClass = new AcceptedInvitation(
      firstName.value,
      lastName.value,
      phoneNumber.value,
      email.value,
      inviteToken.value
    );

    const res = await acceptedClass.reject();

    emit('onRejectSuccess', invitationOrganization.value, res?.result);
  } catch (error) {
    console.log(error);
    isFetchingJoin.value = false;
  }
};

const isDisabledButton = computed(() => {
  // 1. Not VerifiedExisted account
  if (
    !accountExisted.value &&
    (isCheckingPhone.value ||
      !validationResultPhone.value.isValid ||
      emailError.value ||
      (isSentViaEmail.value && !phoneNumber.value) ||
      (isSentViaPhone.value && !email.value) ||
      getCodeErrorMessage.value ||
      isFetchChecking.value ||
      isChecking.value)
  ) {
    return true;
  }
  // 2. Existed account
  return !!(
    !firstName.value ||
    !lastName.value ||
    firstNameError.value ||
    lastNameError.value
  );
});
</script>

<template>
  <teleport to="body">
    <div
      class="absolute w-full inset-0 overflow-hidden flex-center z-50"
      style="background: rgba(0, 0, 0, 0.7)"
    >
      <div
        class="
          relative
          h-auto
          shadow-lg
          bg-white
          mx-auto
          rounded-md
          flex flex-col
        "
        style="max-height: 80%; max-width: 50rem"
        :style="
          errorMessage && !isLoading ? 'max-width: 1000px' : 'max-width: 50rem'
        "
      >
        <div class="h-full text-left flex flex-col overflow-y-auto">
          <!--Body-->
          <div
            class="
              h-full
              max-h-full
              flex
              bg-white
              overflow-x-hidden overflow-y-auto
              small-scrollbar
              rounded-md
              relative
            "
          >
            <div v-if="isLoading" class="flex-1">
              <SynLocalLoading />
            </div>
            <div v-else class="flex-1 overflow-y-auto small-scrollbar p-16">
              <div class="font-semibold text-lg text-center pb-4">
                {{
                  $t('SIGN_UP_RECEIVED_INVITATION_FROM_ORGANIZATION') ||
                  'You have just received an invitation to join the organization'
                }}
              </div>
              <div class="text-center space-x-1">
                <span class="font-semibold text-current">{{
                  inviteeInfo?.name || 'Some one'
                }}</span>
                <span v-if="inviteeInfo?.email" class="text-current-600 italic"
                  >({{ inviteeInfo.email }})</span
                >
                <span class="text-gray-600">
                  {{
                    $t('INVITATION_LABEL_TITLE') ||
                    'has invited you to use Fiine with them, in a workspace called'
                  }}
                </span>
                <span class="font-semibold text-current">
                  {{ organizationInfo?.name }}
                </span>
              </div>
              <div
                class="
                  mt-8
                  p-4
                  rounded-lg
                  border border-dashed border-orange-600
                  flex-center flex-col
                "
              >
                <div v-if="errorMessage" class="flex-1 items-center p-4">
                  <InviteUserError :error-code="errorResponseCode" />

                  <SynButton
                    class="w-40 m-auto"
                    :label="$t('COMMON_LABEL_CLOSE')"
                    @click="$emit('onClose')"
                  />
                </div>
                <template v-else>
                  <span>
                    <SynAvatar
                      :src="organizationInfo?.logoUrl"
                      :name="organizationInfo?.name"
                      custom-class="w-12 h-12"
                    />
                  </span>
                  <span class="mt-4 font-semibold">
                    {{ organizationInfo?.name }}
                  </span>
                  <!-- <span class="text-sm text-gray-600">
                    Software Development
                  </span> -->
                  <span class="text-sm text-gray-600">
                    {{
                      $t('INVITATION_LABEL_EXPIRE_ON') ||
                      'The invitation will expire on'
                    }}:
                    <span class="text-red-500">{{
                      $filters.dayjs(
                        invitationInfo.tokenExpirationDate,
                        'MMM DD, YYYY'
                      )
                    }}</span>
                  </span>
                  <form>
                    <!-- FORM LOGIN -->
                    <section class="mt-4 w-full">
                      <div
                        class="
                          relative
                          flex flex-col
                          min-w-0
                          break-words
                          w-full
                          mb-6
                          rounded-lg
                          border-0
                          lg:items-center
                        "
                      >
                        <div class="flex-auto w-full">
                          <!-- <form @submit.prevent="onSubmit"> -->
                          <div class="relative flex space-x-3 mb-3 mt-3">
                            <SynFormInput :error-message="firstNameError">
                              <SynInput
                                v-if="isInited"
                                v-model="firstName"
                                class="w-full"
                                autofocus
                                input-type="text"
                                :max-length="50"
                                :placeholder="
                                  $t('USERMANAGEMENT_TABLE_FIRST_NAME') + '*'
                                "
                                :error-message="firstNameError"
                              />
                            </SynFormInput>
                            <SynFormInput :error-message="lastNameError">
                              <SynInput
                                v-if="isInited"
                                v-model="lastName"
                                class="w-full"
                                input-type="text"
                                :max-length="50"
                                :placeholder="
                                  $t('USERMANAGEMENT_TABLE_LAST_NAME') + '*'
                                "
                                :error-message="lastNameError"
                              />
                            </SynFormInput>
                          </div>
                          <div v-if="accountExisted">
                            <div
                              v-if="isSentViaPhone"
                              class="
                                relative
                                w-full
                                mb-3
                                flex
                                items-center
                                px-4
                                py-2
                                space-x-4
                                bg-gray-200
                                rounded-md
                              "
                            >
                              <SynIcon name="email" class="fill-green-500" />
                              <span>
                                {{ email }}
                              </span>
                            </div>
                            <div
                              v-if="isSentViaEmail"
                              class="
                                relative
                                w-full
                                mb-3
                                flex
                                items-center
                                px-4
                                py-2
                                space-x-4
                                bg-gray-200
                                rounded-md
                              "
                            >
                              <SynIcon name="phone" class="fill-green-500" />
                              <span>
                                {{ phoneNumber }}
                              </span>
                            </div>
                          </div>
                          <template v-else>
                            <div
                              v-if="isSentViaPhone"
                              class="relative w-full mb-3"
                            >
                              <SynFormInput
                                :error-message="emailError"
                                :is-checking="isFetchChecking"
                                :is-valid="emailIsValid"
                              >
                                <SynInput
                                  v-if="isInited"
                                  v-model="email"
                                  class="w-full"
                                  icon-prefix="email"
                                  input-type="text"
                                  :max-length="50"
                                  :placeholder="$t('LOGIN_FORM_EMAIL') + '*'"
                                  :error-message="emailError"
                                  :is-disabled="isSentViaEmail"
                                  @input="onChangeEmail"
                                />
                              </SynFormInput>
                            </div>
                            <div v-if="isSentViaEmail" class="relative mb-3">
                              <SynFormInput
                                :is-checking="isCheckingPhone"
                                :is-valid="validationResultPhone.isValid"
                                :error-message="validationResultPhone.message"
                              >
                                <AtomPhoneInput
                                  v-if="isInited"
                                  :value="phoneNumber"
                                  :disabled="isSentViaPhone"
                                  @change="onChangePhone"
                                />
                              </SynFormInput>
                            </div>
                          </template>
                          <!-- </form> -->
                        </div>
                      </div>
                    </section>
                  </form>
                  <div class="flex-center space-x-2">
                    <div
                      v-if="hasError"
                      class="
                        bg-orange-100
                        px-4
                        py-1
                        pt-2
                        rounded
                        shadow
                        text-orange-500 text-sm
                      "
                    >
                      <span
                        class="text-orange-500 text-sm"
                        v-html="
                          $t(hasErrorMessage, {
                            orgName: hasErrorMessageOrgName || '',
                          })
                        "
                      >
                      </span>
                    </div>

                    <SynButton
                      class="w-40"
                      color="red"
                      @click="onRejectInvitation"
                    >
                      <span class="text-white">
                        {{ $t('COMMON_LABEL_REJECT') || 'Reject' }}
                      </span>
                    </SynButton>

                    <SynButton
                      class="w-40 m-auto"
                      :is-loading="isFetchingSignup || isFetchingJoin"
                      :disabled="isDisabledButton"
                      :label="$t('COMMON_LABEL_JOIN_NOW')"
                      @click="onFetchJoinNow"
                    />
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>
