import { getFileExtension } from '@/ui/hooks/fileHook';
import AwsS3Class from '@/ui/plugins/awss3/AwsS3Class';
import {
    getKindOfFileByType,
    ModuleSourceFile,
} from '@/ui/plugins/awss3/AwsS3Type';

export class AttachmentServiceClass {
    module: ModuleSourceFile;
    awsS3Class: AwsS3Class;

    constructor(module: ModuleSourceFile) {
        this.module = module;
        this.awsS3Class = new AwsS3Class(module);
    }

    async upload(fileData: any, contentType: string): Promise<any> {
        try {
            const fileName = fileData?.name;
            const extension = `.${
                getFileExtension(fileName, contentType)?.toLowerCase() || 'doc'
            }`;
            const kindOfFile = getKindOfFileByType(contentType);

            const uploadRes = await this.awsS3Class.upload(
                fileData,
                contentType,
                kindOfFile,
                extension
            );

            return Promise.resolve({
                ...uploadRes,
                fileName,
                extension,
                size: fileData?.size,
                documentType: kindOfFile.toUpperCase(),
                module: this.module,
                type: contentType,
                path: uploadRes.Key,
            });
        } catch (error) {
            Promise.reject(error);
        }
    }

    uploads(fileArray: any[]) {
        const promiseAll: any[] = [];
        for (const file of fileArray) {
            const contentType = file.type || file.contentType;
            promiseAll.push(this.upload(file, contentType));
        }

        return Promise.all(promiseAll);
    }

    remove(filePath, contentType) {
        const kindOfFile = getKindOfFileByType(contentType);

        return this.awsS3Class.remove(filePath, kindOfFile);
    }

    removes(fileArray: any[]) {
        const promiseAll: any[] = [];
        for (const file of fileArray) {
            const contentType = file.type || file.contentType;
            const filePath = file.path;

            promiseAll.push(this.remove(filePath, contentType));
        }

        return Promise.all(promiseAll);
    }
}
