import { TaskSchedule } from '@/domain/entities/task/TaskPresentClass';
// Generated by https://quicktype.io

import { TaskLife } from '@/domain/enums/taskEnum';

export interface UpdateMultipleTaskPayload {
    taskIds: number[];
    actionType: number;
    extraData: ExtraData;
}

export interface ExtraData {
    scheduleTime?: string;
    important?: boolean;
    priority?: number;
    taskLife?: TaskLife;
    commentInput?: any;
    // change assignee
    groupId?: number;
    assigneeId?: number;
    collaboratorIds?: number[];
    domainId?: number;
    projectId?: number;
    subprojectId?: number;
}

// Generated by https://quicktype.io

export interface TaskCollaborator {
    memberRoleId: string;
    memberUserId: number;
}
export interface TaskResponsible {
    id: number;
    groupId: number;
    assigneeId: number;
    collaborators?: TaskCollaborator[];
}

export interface TaskUserRole {
    id: string;
    roleName: string;
    index: number;
    code?: string;
    isSystem?: boolean;
}

// Generated by https://quicktype.io

export interface ITaskUserAffectedByRole {
    taskCount: number;
    taskIds: number[];
    memberCount: number;
}

// Generated by https://quicktype.io

export interface IDeleteTaskUserRolePayload {
    id: string;
    deleteOption?: number;
    moveToId: string;
}

// Generated by https://quicktype.io

export interface IUpdateCollaboratorPayload {
    collaboratorIds: number[];
    taskId: number;
}

// Generated by https://quicktype.io

export interface ITaskTodoList {
    id?: number;
    taskId: number;
    taskTodos: ITaskTodo[];
    organizationId?: number;
    totalTaskTodo?: number;
    totalTaskTodoFinished?: number;
    creationTime?: string;
    updateTime?: string;
}

export interface ITaskTodo {
    id: string;
    title: string;
    description: string;
    isDraft?: boolean;
    todoItems: ITaskTodoItem[];
}

export interface ITaskTodoItem {
    id: string;
    title: string;
    shortDescription: string;
    description: string;
    status: ITaskTodoItemStatus;
    index: number;
}
export enum ITaskTodoItemStatus {
    PENDING = 'PENDING',
    FINISHED = 'FINISHED',
}

export enum ETaskListModule {
    USER = 'U',
    GROUP = 'G',
    DOMAIN = 'D',
    I_ASSIGNED = 'IA',
    WAITING_MY_APPROVAL = 'WMA',
    PROJECT = 'P',
    SUB_PROJECT = 'SP',
}
export enum ECustomFilterSourceType {
    USER = 'U',
    GROUP = 'G',
    DOMAIN = 'D',
    I_ASSIGNED = 'IA',
    WAITING_MY_APPROVAL = 'WMA',
    PROJECT = 'P',
    SUB_PROJECT = 'SP',
    OVERVIEW_DOMAIN = 'OVERVIEW_DOMAIN',
    OVERVIEW_PROJECT = 'OVERVIEW_PROJECT',
    OVERVIEW_SUB_PROJECT = 'OVERVIEW_SUB_PROJECT',
}

// Generated by https://quicktype.io
export interface ITaskStatisticOverview {
    assigneeId?: number;
    all: number;
    finishedInTime: number;
    finishedPastDue: number;
    overdueDate: number;
    undefinedState: number;
    inProcessState: number;
    pendingState: number;
    finishedState: number;
    archivedState: number;
    cancelledState: number;
    duplicatedState: number;
    todoState: number;
    draftState: number;
    urgentImportant: number;
    urgent: number;
    important: number;
    cool: number;
}

export interface IUserDay {
    doItToday: boolean;
    finishItToday: boolean;
    planType: number;
    overdue: null;
    code: string;
    scheduleOptionKey: number;
    scheduleDateValue: string;
    currentUserDate: null;
    otherTime: null;
    otherTimes: null;
}

export enum TaskApprovalStatus {
    NOT_REQUESTED = 1,
    WAITING = 2,
    APPROVED = 3,
    REJECTED = 4,
}
export interface ITaskApprover {
    taskId: number;
    userId: number;
    organizationId: number;
    approvalStatus: number;
    approvalDate: string | null;
}
export enum EActionOnTask {
    FINISH = 'FINISH',
    DISCUSS = 'DISCUSS',
    REMINDER_TASK = 'REMINDER_TASK',
    CLONE = 'CLONE',
    SET_REPEAT = 'SET_REPEAT',
    WORKFLOW = 'WORKFLOW',
    COPY_LINK = 'COPY_LINK',
    SHARE = 'SHARE',
    SEND_TO_WHITEBOARD = 'SEND_TO_WHITEBOARD',
    SNAPSHOT = 'SNAPSHOT',
    PIN = 'PIN',
    APPROVAL = 'APPROVAL',
    RELOAD = 'RELOAD',
    EXPAND = 'EXPAND',
    VIEW_DETAIL = 'VIEW_DETAIL',
    LOCK_UPDATE_DEADLINE = 'LOCK_UPDATE_DEADLINE',
    SUB_TASK = 'SUB_TASK',
    ASSIGN_USER_LIST = 'ASSIGN_USER_LIST',
    REMOVE = 'REMOVE',
    PROVIDE_EVIDENCE_WHEN_FINISH = 'PROVIDE_EVIDENCE_WHEN_FINISH',
}

// Generated by https://quicktype.io

export interface ISubTaskDetail {
    id: number;
    code: string;
    name: string;
    groupId: number;
    isGroupMember: boolean;
    domainId: number;
    projectId: number;
    subprojectId: number;
    taskType: number;
    isPrivate: boolean;
    taskLife: number;
    originalEstimationTime: number;
    scheduleTime: string;
    scheduleByHour: boolean;
    scheduleTimezone: string;
    startTime: string;
    isPlannedToTreat: boolean;
    isPlannedToFinish: boolean;
    ownerTimezone: string;
    taskSchedule: TaskSchedule;
    assigneeId: number;
    assignorId: number;
    priority: number;
    creatorId: number;
    parentId: number;
    creationTime: string;
    lastModifierId: number;
    lastModificationTime: string;
    finisherId: number;
    finishedTime: string;
    attachmentCount: number;
    noteCount: number;
    description: string;
    important: boolean;
    isDeleted: boolean;
    timezone: string;
    totalComment: number;
    totalSubTask: number;
    isRepeat: boolean;
    taskTodoItemTotal: number;
    taskTodoItemTotalFinished: number;
    taskTodoTotal: number;
    taskTodoTotalFinished: number;
    workFlowTaskId: string;
    workflowId: string;
    workflowStepId: string;
    pinnedColor: string;
    pinnedIndex: number;
    collaborators: TaskCollaborator[];
    shareList: any[];
    approvalEnabled: boolean;
    approvalStatus: number;
    isViewable?: boolean;
}
