<script setup lang="ts">
import SynTh from '@/ui/common/molecules/SynTableCustom/SynTh.vue';
import { computed } from 'vue';
import dayjs from 'dayjs';
// import SynAnimation from '@/ui/common/atoms/SynAnimation.vue';
import {
  isShowTaskDetailGlobal,
  taskCodeGlobal,
} from '@/ui/modules/task/task-global-state';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import { sumBy } from 'lodash';
import appStore from '@/store/app';

const props = defineProps<{
  overviewList: [];
  isLoading: boolean;
}>();

const emit = defineEmits(['chooseUser']);

const _appStore = appStore();

const dateList = computed(() => (props.overviewList as any)[0]?.workInfo);
const lang = computed(() => _appStore.language);

const formatDecimal = (duration) => {
  const hours = Math.floor(duration);
  const minutes = Math.floor((duration % 1) * 60);
  const durationObj = dayjs.duration({ hours, minutes });
  return hours > 0
    ? durationObj.format('H[h] m[m]')
    : durationObj.format('m[m]');
};

// const formatDate = (date) => {
//   return dayjs(date).format('DD/MM/YYYY');
// };

const onOpenTaskDetail = (taskCode) => {
  taskCodeGlobal.value = taskCode;
  isShowTaskDetailGlobal.value = true;
};

const formatMsToHourMinute = (pauseList) => {
  const ms = sumBy(pauseList, function (o: any) {
    return o.duration;
  });
  const hours = Math.floor(ms / 3600000); // 1 giờ = 3600000 ms
  const minutes = Math.floor((ms % 3600000) / 60000); // 1 phút = 60000 ms
  return `${hours}h ${minutes}m`;
};

const chooseUser = (userId) => {
  emit('chooseUser', userId);
};
</script>

<template>
  <div v-if="isLoading" class="w-full h-full flex justify-center">
    <!--    <SynAnimation name="insider-loading" stype="width: 150px; height: 150px;" />-->
    <SynIcon
      name="spinner"
      class="mt-5 fill-current border-none animate-spin"
      custom-class="w-7 h-7"
    />
  </div>
  <syn-table-custom v-else class="rounded" style="width: 100%">
    <template #header>
      <thead class="sticky top-0" style="z-index: 1">
        <SynTr :is-show-border="false">
          <SynTh rowspan="2" :label="$t('COMMON_LABEL_MEMBERS')"></SynTh>
          <template v-for="item in dateList" :key="item.workingDate">
            <SynTh
              colspan="2"
              is-center
              :label="$filters.dayjsFormatFullTime(item.workingDate, lang)"
              class="border-l border-gray-150"
            ></SynTh>
          </template>
        </SynTr>
        <SynTr>
          <template v-for="item in dateList" :key="item.workingDate">
            <SynTh
              :label="$t('CALENDAR_LABEL_TIME')"
              class="border-l border-gray-150 font-normal"
              is-center
            ></SynTh>
            <SynTh
              :label="$t('LABEL_TOTAL_TASK')"
              class="font-normal"
              is-center
            ></SynTh>
          </template>
        </SynTr>
      </thead>
    </template>
    <template #body>
      <!--      <syn-table-tr-loading v-if="isLoading">-->
      <!--        <div v-for="item in 15" :key="item" class="table-cell p-2">-->
      <!--          <div class="p-2 w-3/4 bg-gray-200 rounded-md"></div>-->
      <!--        </div>-->
      <!--      </syn-table-tr-loading>-->
      <template v-for="item in overviewList" :key="item.userId">
        <syn-tr
          class="hover:bg-gray-50 cursor-pointer"
          @click="chooseUser(item.userId)"
        >
          <SynTd style="min-width: 250px">
            <SynAvatar
              :src="item?.avatarUrl"
              :name="item?.fullName"
              has-name
              custom-class="h-8 w-8"
            />
          </SynTd>
          <template
            v-for="workInfo in item.workInfo"
            :key="workInfo.workingDate"
          >
            <SynTd
              is-show-border
              class="border-l border-gray-150"
              style="min-width: 80px"
            >
              <div class="flex-center">
                <div
                  v-if="
                    workInfo.principle?.principle?.duration &&
                    workInfo.principle?.principle?.start &&
                    workInfo.principle?.principle?.stop
                  "
                  class="relative"
                >
                  <VigDropdown class="relative" trigger="mouseenter">
                    <template #dropdown-toggle>
                      <div
                        class="
                          px-1.5
                          py-1
                          rounded-md
                          flex-center
                          bg-blue-100
                          text-blue-800
                        "
                      >
                        <span>{{
                          formatDecimal(workInfo.principle?.principle?.duration)
                        }}</span>
                      </div>
                    </template>
                    <template #dropdown-menu>
                      <div
                        class="
                          w-max
                          bg-white
                          rounded-md
                          flex
                          item-center
                          justify-center
                          space-x-2
                          p-3
                          text-sm
                        "
                      >
                        <div
                          class="
                            border
                            flex
                            items-center
                            rounded-full
                            bg-white
                            py-1
                            px-1
                          "
                        >
                          <div
                            class="
                              w-6
                              h-6
                              bg-current-500
                              flex-center
                              rounded-full
                            "
                          >
                            <SynIcon
                              name="start"
                              custom-class="w-3 h-3 fill-white text-white"
                            />
                          </div>
                          <span class="mx-2">
                            <SynLabelDateTime
                              format="time"
                              :datetime="workInfo.principle?.principle?.start"
                            />
                          </span>
                        </div>

                        <div
                          class="
                            border
                            flex
                            items-center
                            rounded-full
                            bg-white
                            py-1
                            px-1
                          "
                        >
                          <div
                            class="
                              w-6
                              h-6
                              bg-orange-500
                              flex-center
                              rounded-full
                            "
                          >
                            <SynIcon
                              name="pause"
                              custom-class="w-3 h-3 fill-white text-white"
                            />
                          </div>
                          <span class="mx-2">
                            {{
                              formatMsToHourMinute(
                                workInfo.principle?.principlePauseDetails
                              )
                            }}
                          </span>
                        </div>

                        <div
                          class="
                            border
                            flex
                            items-center
                            rounded-full
                            bg-white
                            py-1
                            px-1
                          "
                        >
                          <div
                            class="w-6 h-6 bg-gray-800 flex-center rounded-full"
                          >
                            <SynIcon
                              name="stop-video"
                              custom-class="w-3 h-3 fill-white text-white"
                            />
                          </div>
                          <span class="mx-2">
                            <SynLabelDateTime
                              format="time"
                              :datetime="workInfo.principle?.principle?.stop"
                            />
                          </span>
                        </div>
                      </div>
                    </template>
                  </VigDropdown>
                </div>
                <span v-else></span>
              </div>
            </SynTd>
            <SynTd is-show-border style="min-width: 80px">
              <div class="flex-center">
                <div
                  v-if="
                    workInfo.taskPlanned?.filter((e) => e.isPlannedToTreat)
                      .length > 0
                  "
                  class="relative w-full"
                >
                  <div class="flex-center">
                    <VigDropdown class="relative" trigger="mouseenter">
                      <template #dropdown-toggle>
                        <div
                          class="
                            bg-red-100
                            flex-center
                            cursor-pointer
                            py-1
                            px-2
                            rounded-md
                            text-sm text-red-800
                          "
                        >
                          {{
                            workInfo.taskPlanned?.filter(
                              (e) => e.isPlannedToTreat
                            ).length
                          }}
                        </div>
                      </template>
                      <template #dropdown-menu>
                        <div
                          class="
                            w-max
                            bg-white
                            rounded-md
                            flex flex-col
                            item-center
                            justify-center
                            overflow-y-auto
                            small-scrollbar
                          "
                          style="max-height: 400px"
                        >
                          <div
                            v-for="item in workInfo.taskPlanned?.filter(
                              (e) => e.isPlannedToTreat
                            )"
                            :key="item"
                            class="
                              py-2
                              px-3
                              border-b
                              cursor-pointer
                              dropdown-item
                              hover:bg-gray-50
                            "
                            @click="onOpenTaskDetail(item.taskCode)"
                          >
                            <div class="text-current-500">
                              <span class="text-xs font-bold">
                                {{ item.taskCode }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </template>
                    </VigDropdown>
                  </div>
                </div>
                <span v-else></span>
              </div>
            </SynTd>
          </template>
        </syn-tr>
      </template>
    </template>
  </syn-table-custom>
</template>
