<script setup lang="ts">
import { inject, ref, onMounted, computed } from 'vue';
import ShapeNoteEntity from '@/ui/components/drawer/entities/ShapeNoteEntity';
import PROVIDER_IDS, {
  TDesktopDrawer,
  TDesktopDrawerShape,
} from '@/application/constants/system/provider-ids.const';
import UploadPreview from '@/ui/components/file-viewer/UploadPreview.vue';
import CreateNoteModal from '@/ui/modules/task/detail/CreateNoteModal.vue';
import ModalMultipleFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewer.vue';
import useDrawNote from '@/ui/modules/task/general/note-logic';
import ShapeActions from '@/ui/components/drawer/components/ShapeActions.vue';

const props = defineProps<{
  shapeEntity: ShapeNoteEntity;
}>();

const emit = defineEmits<{
  (e: 'updateData'): void;
}>();

const desktopDrawerShapeInjected = inject<TDesktopDrawerShape>(
  PROVIDER_IDS.DESKTOP_DRAWER_SHAPE
);
const desktopDrawerInjected = inject<TDesktopDrawer>(
  PROVIDER_IDS.DESKTOP_DRAWER
);

const currentNoteData = computed<any>(() => {
  return props?.shapeEntity?.sourceData;
});

// const clickTargetPosition = ref<any>({ x: 0, y: 0 });

const onMouseDown = (event) => {
  // clickTargetPosition.value = event;
  desktopDrawerShapeInjected?.onMouseDown(event);
};
// const onMouseUp = (event) => {
//   if (
//     clickTargetPosition.value.x !== event?.x ||
//     clickTargetPosition.value.y !== event?.y
//   )
//     return;

//   onOpenFiles();
// };

const isPreview = ref<boolean>(false);
const modalMultipleFileViewerRef = ref<any>(null);
const isAutoEdit = ref<boolean>(false);
const onOpenFiles = (isEdit) => {
  isPreview.value = true;

  isAutoEdit.value = !!isEdit;
};
const onModalPreviewReady = () => {
  if (isAutoEdit.value)
    setTimeout(() => {
      if (
        modalMultipleFileViewerRef.value &&
        typeof modalMultipleFileViewerRef.value?.updateCurrentViewMode ==
          'function'
      )
        modalMultipleFileViewerRef.value?.updateCurrentViewMode('EDIT_NOTE');
    });
};

const currentDrawNote = ref<any>({
  addNote: (note) => {
    // update this shape
    props.shapeEntity?.updateSourceData(note);
    desktopDrawerInjected?.desktopDrawerInstance.value?.updateShape(
      props.shapeEntity?.id,
      'SOURCE_DATA',
      note
    );

    desktopDrawerInjected?.addMoreHistory();

    emit('updateData');
  },
});

const {
  isCreateNote,
  currentNote,
  isLoadingDrawData,
  onSaveNote,
  // onCreateNote,
  onCancelNote,
} = useDrawNote(currentDrawNote);

onMounted(() => {
  currentNote.value.drawData = props.shapeEntity?.sourceData?.drawData;
});

// const onDblClick = () => {
//   console.log('🚀 Tictop ~ currentNote.value:', currentNote.value);
//   onCreateNote();
// };

const onRemoveAttachment = () => {
  desktopDrawerInjected?.desktopDrawerInstance.value.removeShapeById(
    props.shapeEntity?.id,
    () => {
      desktopDrawerInjected?.addMoreHistory();
    }
  );
  isPreview.value = false;
};

const handleClickRemove = () => {
  desktopDrawerInjected?.desktopDrawerInstance.value?.removeShapeById(
    props.shapeEntity?.id,
    () => {
      desktopDrawerInjected?.addMoreHistory();
    }
  );
  desktopDrawerInjected?.addMoreHistory();
};

const onSelectResize = (event) => {
  desktopDrawerShapeInjected?.onMouseDown(event, 'RESIZE');
};
</script>
<template>
  <div
    class="flex-center relative"
    :style="{
      width: `${shapeEntity?.width}px`,
      height: `${shapeEntity?.height}px`,
    }"
  >
    <div
      class="flex-center"
      style="pointer-events: all"
      @mousedown.left="onMouseDown"
      @dblclick="onOpenFiles(false)"
    >
      <UploadPreview
        custom-class="m-0"
        :file="currentNoteData"
        :style="{
          width: `${shapeEntity?.width}px`,
          height: `${shapeEntity?.height}px`,
          margin: 0,
        }"
        image-size="original"
        size="auto"
        is-hidden-close
      />
    </div>
  </div>
  <div
    class="w-full h-full absolute inset-0 rounded-md drawer-shape-mask"
    @mousedown.left="onMouseDown"
  >
    <ShapeActions
      class="absolute top-0 right-0"
      :source-data="shapeEntity?.sourceData"
      :action-list="['EDIT', 'ATTACH_TO_TASK', 'DETAIL', 'REMOVE']"
      @on-click-edit="onOpenFiles(true)"
      @on-click-detail="onOpenFiles(false)"
      @on-click-remove="handleClickRemove"
    />
    <span
      class="
        absolute
        bottom-0.5
        right-0.5
        pointer-events-auto
        h-4
        w-4
        border-r-4 border-b-4
      "
      style="cursor: nw-resize"
      @mousedown.stop.prevent="onSelectResize"
    >
    </span>
  </div>

  <CreateNoteModal
    v-if="isCreateNote"
    :draw-note-data="{
      drawData: shapeEntity?.sourceData?.drawData,
      isLoading: isLoadingDrawData,
      images: [],
      originFile: '',
      readonly: false,
    }"
    @on-close="onCancelNote"
    @on-save="
      (data) =>
        onSaveNote({
          ...currentNote,
          ...data,
        })
    "
  />

  <ModalMultipleFileViewer
    v-if="isPreview"
    ref="modalMultipleFileViewerRef"
    :start-index="0"
    :files="[
      {
        ...shapeEntity?.sourceData,
        isNote: true,
        contentType: 'image/png',
      },
    ]"
    :has-task-actions="false"
    :has-image-actions="false"
    disabled-download
    @on-ready="onModalPreviewReady"
    @on-close="isPreview = false"
    @on-save-note="onSaveNote"
    @on-remove="onRemoveAttachment"
  >
    <template #title>
      <span></span>
    </template>
  </ModalMultipleFileViewer>
</template>
