<script setup lang="ts">
import { computed } from 'vue';
import dayjs from 'dayjs';

const props = defineProps<{
  event: any;
}>();

const startDate = computed(() => {
  return props.event?.startDate;
});

const startTime = computed(() => {
  return props.event?.metadata?.allDay ? null : props.event?.startDate;
});

const endDate = computed(() => {
  const isEndAfterStart = dayjs(props.event?.endDate).isAfter(
    props.event?.startDate
  );

  const isOneAllDay =
    props.event?.metadata?.allDay &&
    dayjs(props.event?.endDate)
      .add(-1, 'day')
      .isSame(props.event?.startDate, 'day');

  const isTimeInSameDay =
    !props.event?.metadata?.allDay &&
    dayjs(props.event?.endDate).isSame(props.event?.startDate, 'day');

  return props.event?.endDate &&
    isEndAfterStart &&
    !isOneAllDay &&
    !isTimeInSameDay
    ? _processEndDate(props.event)
    : null;
});

const endTime = computed(() => {
  const isEndAfterStart = dayjs(props.event?.endDate).isAfter(
    props.event?.startDate
  );

  return props.event?.endDate &&
    isEndAfterStart &&
    !props.event?.metadata?.allDay
    ? props.event?.endDate
    : null;
});

const _processEndDate = (event) => {
  if (!event?.metadata?.allDay) return event?.endDate;

  return dayjs(props.event?.endDate).add(-1, 'day').toDate();
};
</script>

<template>
  <div class="flex-center gap-1">
    <SynLabelDateTime
      v-if="startDate"
      format="date"
      ignore-timezone
      :datetime="startDate"
    />
    <SynLabelDateTime
      v-if="startTime"
      format="time"
      ignore-timezone
      :datetime="startTime"
    />
    <span v-if="endDate || endTime">-</span>
    <SynLabelDateTime
      v-if="endDate"
      format="date"
      ignore-timezone
      :datetime="endDate"
    />
    <SynLabelDateTime
      v-if="endTime"
      format="time"
      ignore-timezone
      :datetime="endTime"
    />
  </div>
</template>

<style scoped></style>
