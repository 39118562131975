import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { openNotification, AlertType } from '@/ui/hooks/commonHook';

import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
import { ResponseStatusCode } from '@/ui/common/constants/constant';
import {
    settingGlobalModal,
    ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import Qs from 'qs';

const debug = import.meta.env.NODE_ENV !== 'production';

const customeErrorCodes = [
    ResponseStatusCode.LoginFailed,
    ResponseStatusCode.LoginBlocked,
    ResponseStatusCode.SendPhoneCodeBlocked,
    ResponseStatusCode.CaptchaCheckFailed,
    ResponseStatusCode.InvalidSessionInfo,
    ResponseStatusCode.SendVerificationFailed,
    ResponseStatusCode.CodeInvalid,
    ResponseStatusCode.InviteTokenInvalid,
    ResponseStatusCode.InviteTokenExpired,
    ResponseStatusCode.InviteTokenCanceled,
    ResponseStatusCode.InviteUserAttached,
    ResponseStatusCode.InviteJoinedByOtherMethods,
];
// create an axios instance
const service = axios.create({
    baseURL: `${import.meta.env.VITE_TICTOP_SERVER}/api/ticket/`,
    timeout: debug ? 200000 : 20000, // request timeout
    paramsSerializer: (params) =>
        Qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true }),
});

// request interceptor
service.interceptors.request.use(
    async (config) => {
        const token = getLocalStorage(StorageConstant.TICKET_TOKEN);
        const requestTime = Date.now();
        // do something before request is sent
        config.headers =
            token && typeof token == 'string'
                ? {
                      ...config.headers,
                      Authorization: 'Bearer ' + token,
                      'x-request-time': requestTime,
                  }
                : {
                      ...config.headers,
                      'x-request-time': requestTime,
                  };

        return config;
    },
    (error) => {
        // do something with request error
        return Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */
    async (response: any) => {
        const { success, error } = response.data;
        if (success) {
            const action = translate(response?.config?.action);
            if (action) {
                openNotification({
                    title: translate('COMMON_LABEL_SUCCESS'),
                    body: action,
                });
            }
            return response.data;
        }

        const errorCode = error?.code;
        switch (errorCode) {
            case 403:
            case ResponseStatusCode.SuspiciousRequest:
            case ResponseStatusCode.LoginFailed:
                // location.reload();
                break;
            case ResponseStatusCode.OrganizationInactive:
                {
                    settingGlobalModal({
                        type: 'notification',
                        title: '',
                        content:
                            'This account is inactive, please contact Fiine system administrator or login with other organization.',
                        confirmable: true,
                    });

                    await ask();
                    // location.reload();
                }
                break;
            case ResponseStatusCode.WaitingActivationOrganization:
                {
                    settingGlobalModal({
                        type: 'notification',
                        title: '',
                        content: translate('COMMON_LABEL_ALMOST_THERE_SUB'),
                        confirmable: true,
                    });

                    await ask();
                    // location.reload();
                }
                break;

            default: {
                if (!customeErrorCodes.includes(errorCode ?? 0) && debug) {
                    openNotification({
                        type: AlertType.error,
                        title: translate('COMMON_LABEL_ERROR'),
                        body: error?.message,
                    });
                }
                // new ErrorResponseClass(errorCode)
                return Promise.reject(response.data);
            }
        }
        return Promise.reject(response.data);
    },
    async (error) => {
        if (error && error.response) {
            const { status } = error.response;
            // 401: Token expired;
            switch (status) {
                case 401:
                    {
                        openNotification({
                            type: AlertType.error,
                            title: translate('COMMON_LABEL_ERROR'),
                            body: translate('COMMON_ERROR_401'),
                            duration: 3000,
                        });
                    }
                    break;
                // case 444: {
                //   await handleRefreshToken(store);
                //   error.config.headers['Authorization'] =
                //     'Bearer ' + getLocalStorage(StorageConstant.TOKEN);
                //   const res = await axios.request(error.config);

                //   return res.data;
                // }

                case 404:
                    {
                        // router.push({ name: 'Notfound' });
                    }
                    break;

                default:
                    break;
            }
        }

        return Promise.reject(error);
    }
);

export default class BaseRequestTicket {
    request: (config: AxiosRequestConfig) => AxiosPromise<any>;
    constructor() {
        this.request = service;
    }
}
