import { defineStore } from 'pinia';
// import GroupService from '@/infrastructures/group/GroupService';
import storeId from '@/base/store/storeId';
// import calendarsOverviewStore from '@/store/calendars/calendars-overview';
import {
    getLocalStorage,
    StorageConstant,
    setLocalStorage,
} from '@/ui/hooks/storageHook';
import PrincipleService from '@/application/services/PrincipleService';
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';

type State = {
    isLoading: boolean;
    welcomeImage: string;
    language: string;
    currentTabId: string;
    isOpenMenuBar: boolean;
    differentTime: number;
};

export default defineStore({
    id: storeId.app,
    state: () =>
        ({
            language: getLocalStorage(StorageConstant.LANGUAGE),
            isLoading: true,
            welcomeImage: `${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/background/normal/bg17.png`,
            currentTabId: Date.now().toString(),
            isOpenMenuBar: true,
            differentTime: 0,
        } as State),
    getters: {},
    actions: {
        setIsLoading(data) {
            this.isLoading = data;
        },
        setLanguage(data) {
            this.language = data;
        },
        setImageDefault(image) {
            this.welcomeImage = image;
        },
        setOpenMenuBar(value?) {
            this.isOpenMenuBar =
                value !== undefined ? value : !this.isOpenMenuBar;
        },
        setNewBrowserTab() {
            const tabIds = getLocalStorage(StorageConstant.TICTOP_TAB_IDS);

            setLocalStorage(
                StorageConstant.TICTOP_TAB_IDS,
                `${tabIds}-${this.currentTabId}`
            );
        },
        removeCurrentBrowserTab() {
            const tabIds = getLocalStorage(StorageConstant.TICTOP_TAB_IDS);

            setLocalStorage(
                StorageConstant.TICTOP_TAB_IDS,
                tabIds ? tabIds.replace(`-${this.currentTabId}`, '') : ''
            );
        },
        getMainBrowserTab() {
            const tabIds = getLocalStorage(StorageConstant.TICTOP_TAB_IDS);

            return tabIds ? tabIds.split('-').pop() : '';
        },
        getCurrentTime() {
            return new Date().getTime() + this.differentTime;
        },
        async getCurrentServerTime() {
            const res: any =
                await PrincipleService.getInstance().getCurrentTime();

            if (res?.result) {
                this.differentTime =
                    new Date(res.result).getTime() - new Date().getTime();
            } else {
                this.differentTime = 0;
            }

            return res?.result;
        },
    },
});
