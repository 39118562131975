import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import { AttachmentInTask } from '@/domain/entities/PresentClass/RemoteConfigClass';
import {
    getRemoteConfigValueByKey,
    RemoteConfigKey,
} from '@/ui/plugins/firebases/firebase-remote-config';
import { Value } from '@firebase/remote-config';

export default defineStore({
    id: storeId.remoteConfig,
    state: () => ({
        publicEncryptKey: '',
        encodeUserId: false,
        encodeGroupId: false,
        verificationPhoneProvider: '',
        configAttachmentInTask: new AttachmentInTask({}),
        webTaskUploadMaxFilesize: 25,
        allowExtensionDefault:
            '.jpg, .jpeg, .png, .svg,.gif, .bmp, .txt, .rtf, .pdf, .doc, .docx, .ppt, .pptx, .csv, .xls, .xlsx, .xml, .js, .json, .mp3, .mp4, .oga, .ogv, .mpeg, .zip, .rar, .7z, .msg',
        webTaskUploadContentTypes:
            '.jpg, .jpeg, .png, .svg,.gif, .bmp, .txt, .rtf, .pdf, .doc, .docx, .ppt, .pptx, .csv, .xls, .xlsx, .xml, .js, .json, .mp3, .mp4, .oga, .ogv, .mpeg, .zip, .rar, .7z, .msg',
        webChatUploadContentTypes: '*',
        webChatUploadTotalMaxFilesize: 25,
        webDomainCreateRealtimePermission: '13',
        webOrganizationPlanShowExceedWarning: true,
        webShowHelpFunction: true,
        webShowHelpTopVideo: true,
        webShowHelpFqa: true,
        webShowHelpVideos: true,
        webShowHelpWhatNew: true,
        folderS3Background: 'normal',
        paymentOfferStartOptions: 'ALL' as
            | 'ALL'
            | 'ONLY_TRIAL'
            | 'ONLY_PAY_NOW',
        workspaceTypeSelect: { company: true, team: true, personal: true },
        workspaceTypeSelectRegister: {
            company: true,
            team: true,
            personal: true,
            limit_personal: false,
        },
        totalOrganization: '5000',
        webTaskHiddenCreateFormBottom: true,
    }),
    getters: {
        getVerificationPhoneProvider(state): string {
            return state.verificationPhoneProvider;
        },
        getConfigAttachmentInTask(state): AttachmentInTask {
            return state.configAttachmentInTask;
        },
    },
    actions: {
        fetchRemoteConfigs() {
            try {
                const configString = getRemoteConfigValueByKey(
                    RemoteConfigKey.ConfigAttachmentInTask
                ).asString();

                if (configString)
                    this.configAttachmentInTask = JSON.parse(configString);

                this.webTaskUploadContentTypes = getRemoteConfigValueByKey(
                    RemoteConfigKey.WebTaskUploadContentTypes
                ).asString();

                this.webTaskUploadMaxFilesize =
                    getRemoteConfigValueByKey(
                        RemoteConfigKey.WebTaskUploadMaxFilesize
                    ).asNumber() || 25;

                this.webChatUploadContentTypes =
                    getRemoteConfigValueByKey(
                        RemoteConfigKey.WEB_CHAT_UPLOAD_CONTENT_TYPES
                    ).asString() || '*';

                this.webChatUploadTotalMaxFilesize = getRemoteConfigValueByKey(
                    RemoteConfigKey.WEB_CHAT_UPLOAD_MAX_FILESIZE
                ).asNumber();

                this.webDomainCreateRealtimePermission =
                    getRemoteConfigValueByKey(
                        RemoteConfigKey.WEB_DOMAIN_CREATE_REALTIME_PERMISSION
                    ).asString();

                this.webOrganizationPlanShowExceedWarning =
                    getRemoteConfigValueByKey(
                        RemoteConfigKey.web_organization_plan_show_exceed_warning
                    ).asBoolean();

                this.webShowHelpFunction = getRemoteConfigValueByKey(
                    RemoteConfigKey.WebShowHelpFunction
                ).asBoolean();
                this.webShowHelpTopVideo = getRemoteConfigValueByKey(
                    RemoteConfigKey.WebShowHelpTopVideo
                ).asBoolean();
                this.webShowHelpFqa = getRemoteConfigValueByKey(
                    RemoteConfigKey.WebShowHelpFqa
                ).asBoolean();
                this.webShowHelpVideos = getRemoteConfigValueByKey(
                    RemoteConfigKey.WebShowHelpVideos
                ).asBoolean();
                this.webShowHelpWhatNew = getRemoteConfigValueByKey(
                    RemoteConfigKey.WebShowHelpWhatNew
                ).asBoolean();
                this.folderS3Background = getRemoteConfigValueByKey(
                    RemoteConfigKey.FolderS3Background
                ).asString();
                this.paymentOfferStartOptions = getRemoteConfigValueByKey(
                    RemoteConfigKey.payment_offer_start_options
                ).asString() as 'ALL' | 'ONLY_TRIAL' | 'ONLY_PAY_NOW';

                this.workspaceTypeSelect = getRemoteConfigValueByKey(
                    RemoteConfigKey.workspace_type_select
                ).asString()
                    ? JSON.parse(
                          getRemoteConfigValueByKey(
                              RemoteConfigKey.workspace_type_select
                          ).asString()
                      )
                    : {};
                this.workspaceTypeSelectRegister = getRemoteConfigValueByKey(
                    RemoteConfigKey.workspace_type_select_register
                ).asString()
                    ? JSON.parse(
                          getRemoteConfigValueByKey(
                              RemoteConfigKey.workspace_type_select_register
                          ).asString()
                      )
                    : {};
                this.webTaskHiddenCreateFormBottom = getRemoteConfigValueByKey(
                    RemoteConfigKey.web_task_hidden_create_form_bottom
                ).asBoolean();
                this.totalOrganization = getRemoteConfigValueByKey(
                    RemoteConfigKey.total_organization
                ).asString();
            } catch (error) {
                console.log('🚀 Tictop ~ error:', error);
            }
        },
        getRemoteConfig(key): Value {
            return getRemoteConfigValueByKey(key);
        },
        getPublicEncryptKey(): string {
            const isEncode = getRemoteConfigValueByKey(
                RemoteConfigKey.PublicEncryptKey
            );
            return isEncode.asString();
        },
        getEncodeUserId(): boolean {
            const isEncode = getRemoteConfigValueByKey(
                RemoteConfigKey.EncodeUserId
            );
            return isEncode.asBoolean();
        },
        getEncodeGroupId(): boolean {
            const isEncode = getRemoteConfigValueByKey(
                RemoteConfigKey.EncodeGroupId
            );
            return isEncode.asBoolean();
        },
    },
});
