import CalendarRepository from '@/application/repositories/CalendarRepository';
import {
    CalendarsEventFilter,
    CalendarsSystemEventsFilter,
} from '@/domain/entities/calendars/CalendarsOverviewFiltersClass';
// import {AttachmentServiceClass} from './attachment/attachment-service';
import { ModuleSourceFileEnum } from '@/ui/plugins/awss3/AwsS3Type';
import AttachmentClass from '@/application/services/attachment/AttachmentClass';

export default class CalendarService {
    private static instance: CalendarService;
    _calendarRepository: CalendarRepository;

    constructor() {
        this._calendarRepository = CalendarRepository.getInstance();
    }

    public static getInstance(): CalendarService {
        if (!CalendarService.instance) {
            // Get from local storage
            CalendarService.instance = new CalendarService();
        }

        return CalendarService.instance;
    }

    async getRangeEvents(data: CalendarsEventFilter): Promise<any> {
        return await this._calendarRepository.getRangeEvents(data);
    }

    async getSystemEvents(data: CalendarsSystemEventsFilter): Promise<any> {
        return await this._calendarRepository.getSystemEvents(data);
    }

    async addEvent(data): Promise<any> {
        return await this._calendarRepository.addEvent(data);
    }

    async updateEvent(data): Promise<any> {
        return await this._calendarRepository.updateEvent(data);
    }

    async updateTimeEvent(data): Promise<any> {
        return await this._calendarRepository.updateTimeEvent(data);
    }

    getDetail(id): Promise<any> {
        return this._calendarRepository.getDetail(id);
    }

    getSystemEventDetail(id): Promise<any> {
        return this._calendarRepository.getSystemEventDetail(id);
    }

    deleteEvent(id): Promise<any> {
        return this._calendarRepository.deleteEvent(id);
    }

    stopReminder(id): Promise<any> {
        return this._calendarRepository.stopReminder(id);
    }

    snoozeReminder(id, time): Promise<any> {
        return this._calendarRepository.snoozeReminder(id, time);
    }

    uploadFileInEvent(files: any[]) {
        if (!files || files.length == 0) return Promise.reject(false);

        const promiseUpload = [] as any[];
        files.forEach((file) => {
            const newFile = new AttachmentClass(
                ModuleSourceFileEnum.calendar,
                file
            );
            promiseUpload.push(newFile.upload());
        });
        return Promise.all(promiseUpload).then();

        // const newAwsS3Class = new AttachmentClass(ModuleSourceFileEnum.calendar, files);
        // console.log('uploadFileInEvent newAwsS3Class', newAwsS3Class);
        // return newAwsS3Class.uploads(files);
    }

    removeFileEvent(files: any[]): Promise<any> {
        if (!files || files.length == 0) return Promise.reject(false);

        const promiseUpload = [] as any[];
        files.forEach((file) => {
            const newFile = new AttachmentClass(
                ModuleSourceFileEnum.calendar,
                file
            );
            promiseUpload.push(newFile.remove());
        });
        return Promise.all(promiseUpload).then();

        // const newAwsS3Class = new AttachmentClass(ModuleSourceFileEnum.calendar, files);
        //
        // return newAwsS3Class.removes(files);
    }
}
