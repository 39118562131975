<script setup lang="ts">
import TaskCreateForm from '../general/TaskCreateForm.vue';
import { isOpenCreateTaskLocal } from '@/ui/modules/task/task-global-state';
import taskTabView from '@/store/task/task-tab-view';
import { ETaskViewType } from '@/application/constants/task.const';
import { computed } from 'vue';

defineProps({
  isShowApart: { type: Boolean, default: false },
  assigneeId: { type: Number, default: null },
  isGroup: { type: Boolean, default: false },
  groupId: { type: Number, default: null },
  groupDomains: { type: Array, default: () => [] },
});

const customizeView = computed<ETaskViewType>(
  () => taskTabView().customizeView
);
const onOpenInterface = () => {
  if (isOpenCreateTaskLocal.value) return;
  // 1. Check if in user's page & assignId !== currentUserId => reset
  isOpenCreateTaskLocal.value = true;
  // 3. reset data
};

const saveDraftAndClose = () => {
  isOpenCreateTaskLocal.value = false;
};

const onCreateSuccess = () => {
  saveDraftAndClose();
};

defineExpose({
  onOpenInterface,
});
</script>

<template>
  <teleport to="body">
    <div
      :class="isOpenCreateTaskLocal ? 'block' : 'hidden'"
      class="fixed h-full w-full bg-black opacity-50 z-40 top-0"
      @click="saveDraftAndClose"
    ></div>
  </teleport>
  <!-- End Backdrop -->
  <TaskCreateForm
    v-if="customizeView == ETaskViewType.LIST"
    :is-open-full="isOpenCreateTaskLocal"
    :is-group="isGroup"
    :assignee-id="assigneeId"
    :group-id="groupId"
    :is-show-apart="isShowApart"
    @on-open-interface="onOpenInterface"
    @on-create-success="onCreateSuccess"
  />
</template>
