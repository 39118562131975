<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted, watchEffect } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { isValidPhoneNumber, stringNomalize } from '@/ui/hooks/commonFunction';
import organizationStore from '@/store/organization';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { companySizeRange, teamSizeRange } from '@/ui/common/constants/org';
import { bucketTictop } from '@/ui/plugins/awss3/AwsS3Config';
import {
  putObjectToAwsS3,
  awsS3Delete,
} from '@/ui/plugins/awss3/aws-s3-services';
import { getFileType, getFileExtension } from '@/ui/hooks/fileHook';
import UploadAvatarIcon from '@/ui/components/photo-upload/UploadAvatarIcon.vue';
import inputValueComp from '@/ui/common/composables/form/input-value-comp';
import appStore from '@/store/app';
import { CountryCode } from 'libphonenumber-js';
import atomFormInputComposables from '@/ui/common/atoms/SynInput/atom-form-input-composables';
import TictopWebSocket, {
  USER_REG_EVENT,
} from '@/ui/plugins/websocket/ws-instance';
import permissionStore from '@/store/permission';
import {
  StorageConstant,
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from '@/ui/hooks/storageHook';
import { AuthenticateSelectOrganization } from '@/application/services/authenticate/authenticate-service';
import myProfileStore from '@/store/auth/my-profile';

const props = withDefaults(
  defineProps<{
    customClass?: string;
    isSubmitting: boolean;
    doNotNeedToCheckPersonal: boolean;
  }>(),
  {
    customClass: '',
    doNotNeedToCheckPersonal: false,
  }
);

const emit = defineEmits<{
  (e: 'onCancel'): void;
  (e: 'onSubmit', data: { mode: number; data: any }): void;
}>();

let ws: TictopWebSocket;

const _appStore = appStore();
const _organizationStore = organizationStore();
const currentOrganizationInfo = computed<any>(
  () => _organizationStore.organizationInfo
);
const userProfile = computed(() => myProfileStore().myProfile);

const isPersonalModel = computed<boolean>(
  () => permissionStore().organizationModel === 'PERSONAL'
);

const organizationByIds = computed(() => {
  return _organizationStore.organizationByIds;
});

const myPersonalWorkspace = computed<any>(() => {
  return Object.values(organizationByIds.value)?.find(
    (org: any) => org?.model == 'PERSONAL'
  );
});

const isHasPersonalModel = computed<boolean>(() => {
  if (props.doNotNeedToCheckPersonal) return false;

  return !!myPersonalWorkspace.value?.id;
});
const appLanguage = computed(() => appStore().language);

const defaultValueByLanguage = {
  en: {
    companyName: 'My Company',
    teamName: 'My Team',
    groupName: 'My Group',
    personalName: 'My workspace',
  },
  fr: {
    companyName: 'Mon entreprise',
    teamName: 'Mon équipe',
    groupName: 'Mon groupe',
    personalName: 'Espace de travail personnel',
  },
  vi: {
    companyName: 'Công ty của tôi',
    teamName: 'Team của tôi',
    groupName: 'Nhóm của tôi',
    personalName: 'Không gian cá nhân',
  },
};

const currentStep = ref(1);
const organizationModels = computed(() =>
  _organizationStore.organizationModels?.filter(
    (model) => model.code !== 'FAMILY'
  )
);
const organizationTypes = computed(() => _organizationStore.organizationTypes);
const organizationConfigs = computed(
  () => _organizationStore.organizationConfigs
);
const configByType = computed(
  () => organizationConfigs.value[infoPayload.value.type]
);
const positionPayload = ref([] as any[]);
const taskTypePayload = ref([] as any[]);
const taskDomainPayload = ref([] as any[]);

const infoPayload = ref({
  name: '',
  logo: '',
  model: 'COMPANY',
  type: '',
  size: '11-20',
  address: '',
  addressDetail: '',
  phone: '',
  websiteUrl: '',
  taxCode: '',
  subType: '',
});
const formRef = ref();

const memberSizeRangeByMode = computed<any>(() => {
  if (infoPayload.value?.model == 'TEAM') return teamSizeRange;
  if (isShowFullSize.value) return companySizeRange;
  return teamSizeRange?.slice(0, 4);
});

const updateDefaultValueByLanguage = () => {
  const currentLanguage = appLanguage.value || 'en';

  if (infoPayload.value.model !== 'PERSONAL') {
    if (!isEditedName.value) organizationName.value = '';
    setTimeout(() => {
      nameError.value = '';
    });
    return;
  }
  switch (infoPayload.value.model) {
    // case 'COMPANY':
    //   organizationName.value =
    //     defaultValueByLanguage[currentLanguage]?.companyName;
    //   break;
    // case 'TEAM':
    //   organizationName.value =
    //     defaultValueByLanguage[currentLanguage]?.teamName;
    //   break;
    // case 'GROUP':
    //   organizationName.value =
    //     defaultValueByLanguage[currentLanguage]?.groupName;
    // break;
    case 'PERSONAL':
      organizationName.value =
        defaultValueByLanguage[currentLanguage]?.personalName;
      break;
    default:
      organizationName.value =
        defaultValueByLanguage[currentLanguage]?.companyName;

      break;
  }

  nameError.value = '';
};

onMounted(async () => {
  ws = new TictopWebSocket(
    USER_REG_EVENT.PAGE_INPUT_ORGANIZATION_INFO_INIT,
    null,
    {
      ...userProfile?.value,
      model: 'PERSONAL',
    }
  );

  await _organizationStore.getOrganizationConfigDefault();

  updateDefaultValueByLanguage();
});

onUnmounted(async () => {
  ws?.close();
});

const TemplateByModeConstant = {
  name: {
    key: 'name',
    translateKey: '',
    placeholderTranslateKey: '',
  },
  address: {
    key: 'address',
    translateKey: '',
    placeholderTranslateKey: '',
  },
  phone: {
    key: 'phone',
    translateKey: '',
    placeholderTranslateKey: '',
  },
  website: {
    key: 'website',
    translateKey: '',
    placeholderTranslateKey: '',
  },
  taxCode: {
    key: 'taxCode',
    translateKey: '',
    placeholderTranslateKey: '',
  },
  size: {
    key: 'size',
    translateKey: '',
    placeholderTranslateKey: '',
  },
};

const templateByModel = computed(() => {
  let temp = [] as any[];
  switch (infoPayload.value.model) {
    case 'COMPANY':
      temp = [
        TemplateByModeConstant.name,
        TemplateByModeConstant.address,
        TemplateByModeConstant.phone,
        TemplateByModeConstant.website,
        TemplateByModeConstant.taxCode,
        TemplateByModeConstant.size,
      ];
      break;
    case 'TEAM':
      temp = [TemplateByModeConstant.name, TemplateByModeConstant.size];
      break;
    case 'GROUP':
      temp = [TemplateByModeConstant.name, TemplateByModeConstant.size];
      break;
    case 'PERSONAL':
      temp = [];
      break;
    default:
      temp = [
        TemplateByModeConstant.name,
        TemplateByModeConstant.address,
        TemplateByModeConstant.phone,
        TemplateByModeConstant.website,
        TemplateByModeConstant.taxCode,
        TemplateByModeConstant.size,
      ];
      break;
  }

  return temp;
});

const placeholderByType = computed(() => {
  let placeholderKey = 'ORG_SETUP_PLACEHOLDER_NAME_COMPANY';
  switch (infoPayload.value?.model) {
    case 'TEAM':
      placeholderKey = 'ORG_SETUP_PLACEHOLDER_NAME_TEAM';

      break;
    case 'GROUP':
      placeholderKey = 'ORG_SETUP_PLACEHOLDER_NAME_GROUP';

      break;
    case 'PERSONAL':
      placeholderKey = 'ORG_SETUP_PLACEHOLDER_NAME_PERSONAL';

      break;

    default:
      break;
  }
  return translate(placeholderKey);
});
const typeError = ref('');
const avatarFile = ref();
const stepSetup = ref({
  1: {
    key: 'type',
    label: 'ORGANIZATION_LABEL_TYPE',
    buttonNext: 'COMMON_LABEL_NEXT',
    title: 'FIRSTTIME_SETUP_PERSONAL_ORGANIZATION',
    subTitle: 'FIRSTTIME_SETUP_PERSONAL_ORGANIZATION_SUB',
    idButton: 'signup-step4-team',
    isActive: false,
    isFinished: false,
  },
  2: {
    key: 'taskDomain',
    label: 'TASK_CREATE_FORM_DOMAIN',
    buttonNext: 'COMMON_LABEL_FINISH',
    buttonBack: 'COMMON_LABEL_PREVIOUS',
    title: 'FIRSTTIME_SETUP_PERSONAL_ORGANIZATION',
    subTitle: 'FIRSTTIME_SETUP_PERSONAL_ORGANIZATION_SUB',
    idButton: 'signup-step5-domain',
    // subTitle:
    //   'Settings and describe the types of work in your organization, this will do the job in the organization becomes clearer',
    // exampleTitle: 'eg. design UI, Study, Coding, Marketing….',
    isActive: false,
    isFinished: false,
  },
});

const { value: organizationName, errorMessage: nameError } = inputValueComp(
  (value) => {
    if (!value?.trim()) return 'COMMON_THIS_FIELD_REQUIRED';
    return '';
  }
);

// Phone

const organizationPhone = ref('');
const {
  isTyping: isTypingPhone,
  isChecking: isCheckingPhone,
  validationResult: validationResultPhone,
  onValidation: onValidationPhone,
  reset: onResetValidationPhone,
} = atomFormInputComposables([
  () => {
    return !resultPhoneObject.value?.isValid && organizationPhone.value
      ? 'COMMON_INVALID_PHONE_NUMBER'
      : '';
  },
  (value) => {
    const isValid = !organizationPhone.value || isValidPhoneNumber(value);
    return isValid ? '' : 'COMMON_INVALID_PHONE_NUMBER';
  },
  (value) => {
    const isValid =
      !organizationPhone.value ||
      (value.startsWith('+') &&
        ['+33', '+84', '+1', '+81', '+82'].includes(value?.slice(0, 3)));
    return isValid ? '' : 'COMMON_INVALID_PHONE_NUMBER';
  },
]);

const isDisableNextButton = computed(() => {
  const disableStepOne =
    currentStep.value == 1 && !organizationName.value.trim();
  const disableStepTwo = currentStep.value == 2 && !infoPayload.value?.type;
  return disableStepOne || disableStepTwo || isTypingPhone.value;
});
const labelNameByType = computed(() => {
  if (infoPayload.value.model == 'COMPANY')
    return translate('ORGANIZATION_LABEL_NAME_COMPANY');
  if (infoPayload.value.model == 'TEAM')
    return translate('ORGANIZATION_LABEL_NAME_TEAM');
  if (infoPayload.value.model == 'GROUP')
    return translate('ORGANIZATION_LABEL_NAME_GROUP');
  return translate('ORG_SETUP_NAME_PERSONAL');
});

const onBack = () => {
  switch (currentStep.value) {
    case 1:
      emit('onCancel');
      break;

    case 2:
      onBackStep();

      break;
  }
};

const onClickNext = async () => {
  switch (currentStep.value) {
    case 1:
      onNextStep();
      break;
    case 2:
      {
        if (!infoPayload.value?.type) {
          return (typeError.value = translate('WELCOME_SETTING_TYPE_REQUIRED'));
        }

        emit('onSubmit', {
          mode: 2,
          data: {
            ...infoPayload.value,
            name: organizationName.value,
            phone: organizationPhone.value,
          },
        });
      }
      break;
  }
};

const onNextStep = () => {
  currentStep.value = 2;
};

const onBackStep = () => {
  currentStep.value = 1;
};

const isShowOtherType = ref(false);
const onChangeOrganizationType = (type, index) => {
  infoPayload.value.type = type;
  typeError.value = '';

  isShowOtherType.value = index == organizationTypes.value?.length - 1;
};

watchEffect(() => {
  if (!configByType.value) return;
  positionPayload.value = configByType.value['POSITION'] || [];
  taskTypePayload.value = configByType.value['TASK_TYPE'] || [];
  taskDomainPayload.value = configByType.value['DOMAIN'] || [];
});

const organizationNameRef = ref<any>(null);
const onChangeOrgMode = (item) => {
  infoPayload.value.model = item.code;
  switch (item.code) {
    case 'COMPANY':
      infoPayload.value.size = '11-20';
      break;
    case 'TEAM':
      infoPayload.value.size = '6-10';
      break;
    case 'GROUP':
      infoPayload.value.size = '2-5';
      break;
  }
  updateDefaultValueByLanguage();

  organizationNameRef.value?.focus();
};

const isAddAvatar = ref(false);

const onUploadAvatar = async (file) => {
  avatarFile.value = file;
  isAddAvatar.value = !isAddAvatar.value;
  const oldPath = infoPayload.value?.logo;
  if (oldPath) {
    awsS3Delete({
      Bucket: bucketTictop.UserAvatar,
      Key: oldPath,
    });
  }

  const fileName = stringNomalize(file.name, true).toString();
  const extension = getFileExtension(fileName)?.toLowerCase();
  const filePath = `${
    currentOrganizationInfo.value?.id
  }/original/${uuidv4()}.${extension}`;
  const s3Payload = {
    Bucket: bucketTictop.UserAvatar,
    Key: filePath,
    Body: file,
    ContentType: file.type || file.mimeType || getFileType(file),
  };
  infoPayload.value.logo = filePath;
  await putObjectToAwsS3(s3Payload);
};

const onAddressSelect = (value) => {
  infoPayload.value.addressDetail = JSON.stringify(value);
};

const handleLoginSuccess = async (result) => {
  if (!result) return;

  getLocalStorage(StorageConstant.HAVE_AVATAR) &&
    removeLocalStorage(StorageConstant.HAVE_AVATAR);

  setLocalStorage(StorageConstant.HAS_LOGIN, 'true');
};

const goToPersonalWorkspace = async () => {
  if (!myPersonalWorkspace.value?.id) return;

  _appStore.setIsLoading(true);
  const authenSelectOrganization = new AuthenticateSelectOrganization(
    myPersonalWorkspace.value?.id
  );
  const res = await authenSelectOrganization.login(true);

  handleLoginSuccess(res);
};

const onFinishWithPersonalMode = async () => {
  emit('onSubmit', {
    mode: 1,
    data: {
      name: organizationName.value,
      logo: infoPayload.value?.logo,
      model: infoPayload.value?.model,
    },
  });
};

const resultPhoneObject = ref<{
  country?: CountryCode;
  countryCallingCode: string;
  nationalNumber: string;
  number: string;
  isValid: boolean;
  formattedNumber: string;
}>({
  countryCallingCode: '',
  nationalNumber: '',
  number: '',
  isValid: true,
  formattedNumber: '',
});
const onChangePhone = (phoneObj: {
  country?: CountryCode;
  countryCallingCode: string;
  nationalNumber: string;
  number: string;
  isValid: boolean;
  formattedNumber: string;
}) => {
  onResetValidationPhone();
  resultPhoneObject.value = phoneObj;
  organizationPhone.value = phoneObj?.number || '';

  if (phoneObj?.number?.length == 12) onValidationPhone(phoneObj.number);
};

const isEditedName = ref<boolean>(false);
const onChangeOrgName = () => {
  isEditedName.value = true;
};

const onBlurPhone = () => {
  if (!organizationPhone.value || validationResultPhone.value?.isValid) return;

  onValidationPhone(organizationPhone.value);
};

const isShowFullSize = ref<boolean>(false);
</script>

<template>
  <div
    class="
      h-full
      px-8
      w-full
      mx-auto
      border-current
      flex flex-col
      justify-between
      bg-white
      rounded-2xl
      overflow-hidden
      small-scrollbar
    "
    :class="customClass"
  >
    <div class="flex-1 flex flex-col space-y-2 items-center text-gray-800">
      <div
        class="font-medium text-center"
        style="font-size: 1.75rem; line-height: 2rem"
      >
        {{ $t(stepSetup[currentStep]['title']) }}
      </div>

      <div class="text-gray-400 text-center">
        {{ $t(stepSetup[currentStep]['subTitle']) }}
      </div>

      <!-- Body -->
      <section>
        <template v-if="currentStep == 1">
          <UploadAvatarIcon
            class="mt-6"
            :file="avatarFile"
            :name="organizationName?.trim() || infoPayload?.model"
            size="medium"
            :text-style="`font-size: 2.5rem; font-weight: 600;`"
            type-upload="GROUP"
            type="group"
            z-index="z-50"
            :is-update-success="isAddAvatar"
            @upload-avatar="onUploadAvatar"
          />
          <div class="flex-center space-x-2 mt-6">
            <template v-for="item in organizationModels" :key="item">
              <div
                class="
                  flex-center
                  rounded-full
                  px-3
                  py-1
                  cursor-pointer
                  text-sm
                "
                :class="
                  infoPayload['model'] === item.code
                    ? 'text-white bg-current-500  '
                    : 'border border-gray-300'
                "
                style="min-width: 4rem"
                @click="onChangeOrgMode(item)"
              >
                {{ item.name }}
              </div>
            </template>
            <AtomTooltip>
              <template #trigger>
                <div class="flex-center">
                  <SynIcon
                    name="question"
                    custom-class="ml-2 h-5 w-5 fill-current cursor-pointer"
                  />
                </div>
              </template>
              <template #body>
                <div
                  class="
                    p-4
                    w-max
                    text-left text-sm
                    bg-white
                    flex flex-col
                    space-y-2
                    rounded
                  "
                  style="max-width: 24rem"
                >
                  <span class="text-current font-semibold">
                    {{
                      $t('SETUP_ORGANIZATION_INFORMATION_TITLE') ||
                      'Choose model'
                    }}
                  </span>
                  <ul
                    class="pl-4 flex flex-col space-y-2"
                    style="list-style: circle"
                  >
                    <li>
                      {{
                        $t('SETUP_ORGANIZATION_INFORMATION') ||
                        'The Domain can be projects, workgroups, or customers... corresponding to your operating model.'
                      }}
                    </li>
                    <li>
                      {{
                        $t('SETUP_ORGANIZATION_INFORMATION_2') ||
                        'Ex: BOOK PROJECT, FINANCE PROJECT, OUTSOURCE, MARKETING...'
                      }}
                    </li>
                  </ul>
                </div>
              </template>
            </AtomTooltip>
          </div>
          <form
            ref="formRef"
            action="#"
            class="mt-6 flex flex-col space-y-3"
            style="width: 22rem"
          >
            <!--  NAME -->
            <div
              v-if="
                templateByModel.some(
                  (temp) => temp.key == TemplateByModeConstant.name.key
                )
              "
              class="w-full flex flex-col space-y-1"
            >
              <div
                v-if="infoPayload?.model !== 'PERSONAL'"
                class="flex items-center text-sm pr-4 w-44 text-gray-600"
              >
                <span>
                  {{ labelNameByType }}
                </span>
                <span class="text-red-500 pl-1">*</span>
              </div>

              <SynFormInput :error-message="nameError">
                <SynInput
                  ref="organizationNameRef"
                  v-model="organizationName"
                  custom-class="text-sm"
                  autofocus
                  :placeholder="placeholderByType"
                  :error-message="nameError"
                  @update:model-value="onChangeOrgName"
                />
              </SynFormInput>
            </div>

            <!-- PERSONAL -->
            <template v-if="infoPayload?.model == 'PERSONAL'">
              <span
                v-if="isHasPersonalModel"
                class="text-center italic text-sm text-orange-500 pt-4"
              >
                {{ $t('SETUP_ORGANIZATION_PERSONAL_EXISTED') }}
              </span>
              <span
                v-else
                class="text-center italic text-sm text-gray-500 pt-4"
              >
                {{ $t('SETUP_ORGANIZATION_PERSONAL_DESCRIPTION') }}
              </span>
            </template>

            <!--  ADDRESS -->
            <div
              v-if="
                templateByModel.some(
                  (temp) => temp.key == TemplateByModeConstant.address.key
                )
              "
              class="my-2 flex flex-col space-y-1"
            >
              <div class="flex items-center text-sm pr-4 w-44 text-gray-600">
                <span>{{ $t('USERMANAGEMENT_TABLE_ADDRESS') }}</span>
              </div>
              <SynFormInput>
                <VigAddressBox
                  v-model="infoPayload.address"
                  :placeholder="$t('COMMON_LABEL_PLACEHOLDER_ENTER_ADDRESS')"
                  class="vig-input text-sm"
                  @on-address-select="onAddressSelect"
                />
              </SynFormInput>
            </div>

            <!--  PHONE        -->
            <div
              v-if="
                templateByModel.some(
                  (temp) => temp.key == TemplateByModeConstant.phone.key
                )
              "
              class="my-2 flex flex-col space-y-1"
            >
              <div class="flex items-center text-sm pr-4 w-44 text-gray-600">
                <span>{{ $t('USERMANAGEMENT_FORM_PHONE') }}</span>
              </div>
              <SynFormInput
                :is-checking="isCheckingPhone"
                :is-valid="
                  organizationPhone !== null &&
                  organizationPhone !== '' &&
                  validationResultPhone.isValid
                "
                :error-message="validationResultPhone.message"
              >
                <AtomPhoneInput
                  :value="organizationPhone"
                  input-class="text-sm"
                  :input-options="{
                    placeholder: $t('COMMON_LABEL_COMPANY_MOBILE_PHONE'),
                  }"
                  :auto-format="false"
                  :auto-default-country="false"
                  @change="onChangePhone"
                  @blur="onBlurPhone"
                />
              </SynFormInput>
            </div>

            <!--  WEBSITE        -->
            <!-- <div
              v-if="
                templateByModel.some(
                  (temp) => temp.key == TemplateByModeConstant.website.key
                )
              "
              class="my-2 flex flex-col space-y-1"
            >
              <div class="flex items-center text-sm pr-4 w-44 text-gray-600">
                <span>{{ $t('USERMANAGEMENT_TABLE_WEBSITE') }}</span>
              </div>
              <SynInput
                v-model="infoPayload.websiteUrl"
                custom-class="text-sm"
                :placeholder="$t('COMMON_LABEL_PLACEHOLDER_ENTER_WEBSITE')"
              />
            </div> -->

            <!--  TAX CODE        -->
            <!-- <div
              v-if="
                templateByModel.some(
                  (temp) => temp.key == TemplateByModeConstant.taxCode.key
                )
              "
              class="my-2 flex flex-col"
            >
              <div class="flex items-center text-sm pr-4 w-44 text-gray-600">
                <span>{{ $t('USERMANAGEMENT_TABLE_TAXCODE') }}</span>
              </div>
              <SynInput
                v-model="infoPayload.taxCode"
                custom-class="text-sm"
                :placeholder="$t('COMMON_LABEL_PLACEHOLDER_ENTER_TAXCODE')"
              />
            </div> -->

            <!--  SIZE        -->
            <div
              v-if="
                templateByModel.some(
                  (temp) => temp.key == TemplateByModeConstant.size.key
                )
              "
              class="my-2 flex flex-col space-y-1"
            >
              <div class="flex items-center text-sm pr-4 w-44 text-gray-600">
                <span>
                  {{
                    $t(
                      infoPayload.model == 'TEAM'
                        ? 'ORGANIZATION_LABEL_TEAM_SIZE'
                        : 'ORGANIZATION_LABEL_ORGANIZATION_SIZE'
                    )
                  }}
                </span>
              </div>

              <SynFormInput>
                <div
                  class="flex-center flex-wrap space-x-1"
                  style="width: 22rem"
                >
                  <template
                    v-for="range in memberSizeRangeByMode"
                    :key="range.key"
                  >
                    <div
                      class="
                        w-max
                        flex-center
                        rounded-full
                        px-3
                        py-1.5
                        cursor-pointer
                        text-xs
                        mb-2
                      "
                      :class="
                        infoPayload.size === range.value
                          ? 'text-white bg-current-500  '
                          : 'border border-gray-300'
                      "
                      style="min-width: 4rem"
                      @click="infoPayload.size = range.value"
                    >
                      {{ range.key }}
                    </div>
                  </template>
                  <div
                    v-if="infoPayload?.model == 'COMPANY' && !isShowFullSize"
                    class="
                      w-max
                      flex-center
                      rounded-full
                      px-3
                      py-1.5
                      cursor-pointer
                      text-xs
                      mb-2
                      border border-gray-400
                    "
                    style="min-width: 4rem"
                    @click="isShowFullSize = true"
                  >
                    {{ $t('COMMON_LABEL_OTHER') || 'More' }}
                  </div>
                </div>
              </SynFormInput>
            </div>
          </form>
        </template>

        <template v-if="currentStep == 2">
          <div class="text-center pt-8 font-semibold text-xl">
            {{
              $t('ORGANIZATION_SETUP_MAIN_ACTIVITY') ||
              'What is your main activity?'
            }}
          </div>
          <div class="flex-center w-full flex-wrap p-4">
            <div
              v-for="(item, index) in organizationTypes"
              :key="index"
              class="w-max p-2"
            >
              <SynCheckboxLabel
                class="text-base py-1.5"
                :is-check="infoPayload.type === item.code"
                :text="item.name"
                @click="onChangeOrganizationType(item.code, index)"
              />
            </div>
          </div>
          <div class="flex-center">
            <SynInput
              v-if="isShowOtherType"
              v-model="infoPayload.subType"
              class="w-96"
              :max-length="100"
              :placeholder="$t('SETUP_ORGANIZATION_TELL_US_YOUR_BUSINESS')"
            />
          </div>
          <div v-if="typeError" class="text-red-400">* {{ typeError }}</div>
        </template>
      </section>
    </div>

    <div class="pt-8 w-full flex justify-between items-center">
      <SynButton
        v-if="stepSetup[currentStep]['buttonBack']"
        :label="$t(stepSetup[currentStep]['buttonBack'])"
        type-outline
        @click="onBack"
      />
      <span v-else></span>

      <template v-if="infoPayload.model == 'PERSONAL'">
        <SynButton
          v-if="isPersonalModel && !doNotNeedToCheckPersonal"
          id="signup-step4-personal"
          :label="$t('COMMON_LABEL_GOT_IT')"
          @click="$emit('onCancel')"
        />
        <SynButton
          v-else-if="isHasPersonalModel"
          id="signup-step4-personal"
          :is-loading="isSubmitting"
          :label="
            $t('COMMON_LABEL_GO_TO', {
              destination: $t('COMMON_LABEL_MY_WORKSPACE'),
            })
          "
          @click="goToPersonalWorkspace"
        />
        <SynButton
          v-else
          id="signup-step4-personal"
          :disabled="isDisableNextButton || isHasPersonalModel"
          :is-loading="isSubmitting"
          :label="$t('TASK_DETAIL_FINISH')"
          @click="onFinishWithPersonalMode"
        />
      </template>
      <template v-else>
        <SynButton
          :id="stepSetup[currentStep]['idButton']"
          :disabled="isDisableNextButton"
          :is-loading="isSubmitting"
          :label="$t(stepSetup[currentStep]['buttonNext'])"
          @click="onClickNext"
        />
      </template>
    </div>
  </div>
</template>
