import { serverTimestamp, Timestamp } from 'firebase/firestore';

import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import myProfileStore from '@/store/auth/my-profile';
import storeId from '@/base/store/storeId';
import userStore from '@/store/user';
import groupStore from '@/store/group';
import commonStore from '@/store/common/commonStore';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import {
    genDirectConversationId,
    genGroupConversationId,
} from '@/ui/plugins/firebases/firestore-database/constants';
import ChatService from '@/application/services/ChatService';
import $constants from '@/ui/plugins/constants';
import { isOpenTaskDrawer } from '@/ui/modules/task/task-global-state';
import { TaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import DayoffDetailClass from '@/domain/entities/dayoff/DayoffDetailClass';
import { ConversationValidCode } from '@/domain/enums/chat-enums';
import ConversationCollectionModel from '@/ui/plugins/firebases/firestore-database/models/ConversationCollectionModel';
import ConversationDocModel from '@/ui/plugins/firebases/firestore-database/models/ConversationDocModel';
import MessageCollectionModel from '@/ui/plugins/firebases/firestore-database/models/MessageCollectionModel';
import {
    ask,
    settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import taskDrawerStore from '@/store/task/drawer';
import {
    activeAndFocusOnConversationId,
    isForceActive,
} from '@/ui/modules/messaging/chat-widget/chat-widget-public-state';
import { MESSAGES_PAGE_SIZE } from '@/ui/modules/messaging/chat-panel/chat-panel-config';
import { chain, find, keyBy, last, orderBy, sortBy, uniq } from 'lodash';
import { ignoreUnicode } from '@/ui/plugins/utils';
import { formatDate } from '@/ui/plugins/filters';
import chatExternalStore from '@/store/chat/chat-external';
import { ChatConversationModel } from '@/application/models/chat/ChatConversationModel';
import chatSupportingStore from '@/store/chat/chat-supporting';
import { encryptUserId } from '@/ui/plugins/crypto/crypto';
import { arrToObj, getFullName } from '@/ui/helpers/utils';
import organizationStore from '@/store/organization';
import { NotificationSettingFunction } from '@/ui/common/constants/constant';
import { UserChatInfo } from '@/application/models/chat/UserChatInfo';
import UserService from '@/application/services/UserService';
import { stringNomalize } from '@/ui/hooks/commonFunction';
import { formatTaskCode } from '@/ui/hooks/taskHook';
import { getGroupShortName } from '@/application/constants/chat/chat.const';

type ChatState = {
    userConversations: any;
    userConversationIds: any[];
    bubbleChats: any[];
    bubbleChatActive: any;
    chatAboutTask: TaskDetailClass | null;
    chatAboutDayoff: DayoffDetailClass | null;
    chatAboutEvent: any;
    chatAboutResources: any;
    uploadingFiles: any;
    draftMessages: any;
    newLineMessageByConversationIds: any;
    isFocusChatPanel: boolean;
    idConversationActive: any;
    conversationActive: any;
    lastSelectedConversation: any;
    unSeenConversationsCount: number;
    messagesSendError: any[];
    messagesSendPending: any[];
    isFormChanged: boolean;
    isClearActionForm: boolean;
    mutedConversations: any;
    hasBubbleChatAddNew: boolean;
    conversationsDraft: { [id: string]: ChatConversationModel };
    hasVisibleTaskListConversationIds: string[];
    usersChatInfo: UserChatInfo[] | null;
};

export default defineStore({
    id: storeId.chat,
    state: () =>
        ({
            userConversations: {},
            userConversationIds: [],
            bubbleChats: [],
            bubbleChatActive: null,
            chatAboutTask: null,
            chatAboutDayoff: null,
            chatAboutEvent: null,
            chatAboutResources: null,
            uploadingFiles: {},
            draftMessages: {},
            newLineMessageByConversationIds: {},
            isFocusChatPanel: true,
            idConversationActive: '',
            conversationActive: {},
            lastSelectedConversation: {},
            unSeenConversationsCount: 0,
            messagesSendError: [],
            messagesSendPending: [],
            isFormChanged: false,
            isClearActionForm: false,
            mutedConversations: {},
            hasBubbleChatAddNew: false,
            conversationsDraft: {},
            hasVisibleTaskListConversationIds: [],
            usersChatInfo: null,
        } as ChatState),
    getters: {
        allUsers: () => {
            const myProfile = myProfileStore().myProfile;
            const _userStore = userStore();

            return chain(_userStore.allUsers)
                .map((user: any) => {
                    const conversationId = genDirectConversationId(
                        myProfile?.id,
                        user?.id
                    );
                    return {
                        ...user,
                        isUser: true,
                        isActive: true,
                        conversationId,
                    };
                })
                .value();
        },
        allGroups: () => {
            const _groupStore = groupStore();

            return chain(Object.values(_groupStore.allGroupByIds || {}))
                .concat(Object.values(_groupStore.allGroupChatByIds || {}))
                .uniqBy('id')
                .map((group: any) => {
                    const conversationId = genGroupConversationId(group?.id);
                    return {
                        ...group,
                        avatar: group?.avatarUrl || group?.avatar,
                        isUser: false,
                        isGroup: true,
                        conversationId,
                    };
                })
                .value();
        },
        conversationById: (state) => {
            const userConversations = (state.userConversationIds || []).reduce(
                (obj, converId) => {
                    const userConver = state.userConversations[converId];
                    const myUserId = myProfileStore().myProfile?.id;

                    let chatInfo;

                    if (
                        userConver?.externalChat ||
                        userConver?.supportingChat
                    ) {
                        chatInfo = { isActive: true, isAllowed: true };
                    } else if (userConver?.isGroup) {
                        chatInfo = state.usersChatInfo?.find(
                            (info) =>
                                info?.isGroup &&
                                genGroupConversationId(info?.id) === converId
                        ) || { isDeleted: true };
                    } else {
                        chatInfo = state.usersChatInfo?.find(
                            (info) =>
                                !info?.isGroup &&
                                genDirectConversationId(info?.id, myUserId) ===
                                    converId
                        ) || { isDeleted: true };
                    }

                    obj[converId] = {
                        ...(userConver || {}),
                        ...(chatInfo || {}),
                        id: converId,
                        contactId: chatInfo?.id,
                        isDeactive: !chatInfo?.isActive,
                        isDisallowed: !chatInfo?.isAllowed,
                        isTaskDisallowed: !chatInfo?.isTaskAllowed,
                        isGroupChatDisallowed:
                            chatInfo?.isGroup && !chatInfo?.isGroupChatAllowed,
                    };
                    obj[converId] = {
                        ...obj[converId],
                        shortName: getGroupShortName(obj[converId]),
                    };

                    return obj;
                },
                {}
            );

            return {
                ...userConversations,
                ...state.conversationsDraft,
                ...chatExternalStore().externalConversationsDraft,
                ...chatSupportingStore().supportingConversationsDraft,
            };
        },
        getMember: (state) => (conversId, userId) => {
            if (!userId) return null;

            const conversation = state.userConversations[conversId];

            const member = conversation?.members
                ? conversation?.members[userId]
                : null;

            const user = find(
                userStore().allUsers,
                (u) => `${u?.id}` === `${userId}`
            );

            return { ...member, ...user };
        },
        getContacts() {
            return {
                ...keyBy(this.allUsers, 'conversationId'),
                ...keyBy(this.allGroups, 'conversationId'),
            };
        },
        getIsFocusChatPanel: (state) => {
            return state.isFocusChatPanel;
        },
        getIdConversationActive: (state) => {
            return state.idConversationActive;
        },
        getConversationByContact() {
            return (contact: any, isGroup: boolean) => {
                if (isGroup) {
                    return {
                        id: genGroupConversationId(contact?.id),
                        name: contact?.name,
                        avatar: contact?.avatarUrl || contact?.avatar,
                        isGroup: true,
                    };
                }

                const myUserId = myProfileStore().myProfile?.id;
                return {
                    id: genDirectConversationId(
                        myUserId,
                        contact?.userId || contact?.id
                    ),
                    name: contact?.name,
                    avatar: contact?.avatarUrl || contact?.avatar,
                };
            };
        },

        getConversationInfo(state) {
            return (converId) => {
                const converInfo =
                    this.conversationById[converId] ||
                    state.conversationsDraft[converId] ||
                    chatExternalStore().externalConversationsDraft[converId] ||
                    chatSupportingStore().supportingConversationsDraft[
                        converId
                    ];

                if (converInfo?.isGroup && converInfo?.contactId) {
                    const groupInfo = this.allGroups?.find(
                        (group) => group?.id === converInfo?.contactId
                    );
                    converInfo.groupUsers = groupInfo?.groupUsers;
                }

                return converInfo;
            };
        },
        getConversationMembers() {
            return (converId) => {
                const converInfo = this.getConversationInfo(converId);

                if (converInfo?.externalChat || converInfo?.supportingChat) {
                    return converInfo?.members;
                }

                const allUserByIds = userStore().allUserByIds || {};

                Object.keys(converInfo?.members)?.forEach((memberId) => {
                    converInfo.members[memberId] = {
                        ...converInfo?.members[memberId],
                        ...allUserByIds[memberId],
                    };
                });

                return converInfo?.members;
            };
        },
        getConversationMentionsList() {
            return (converInfo) => {
                if (!converInfo?.isGroup || converInfo?.externalChat) {
                    return [];
                }

                if (converInfo?.supportingChat) {
                    const myUserId = myProfileStore().myProfile?.id;
                    const members = converInfo?.members || {};
                    const isMeSupporter = members[myUserId]?.isSupporter;
                    if (!isMeSupporter) return [];

                    return chain(Object.values(members))
                        .sortBy('name')
                        .map((user: any) => ({
                            id: `@${user?.name?.trim()}`,
                            userId: user?.userId,
                            name: user?.name,
                            avatar: user?.avatarUrl || user?.avatar,
                            link: `/messages/${converInfo?.id}`,
                            isInGroup: true,
                            // isDirectMessage: isDirectMess
                        }))
                        .value();
                }

                // const fullUrlArray = window.location.href?.split('/#');
                // const baseDomainUrl = fullUrlArray?.length
                //     ? fullUrlArray[0]
                //     : import.meta.env.VITE_DOMAIN_DEFAULT;
                const baseDomainUrl = import.meta.env.VITE_DOMAIN_DEFAULT;

                const groupUsers = chain(converInfo?.groupUsers)
                    .sortBy('firstName')
                    .map((user: any) => ({
                        id: `@${user?.firstName?.trim()}`,
                        userId: user?.userId,
                        name: getFullName(user),
                        avatar: user?.avatarUrl || user?.avatar,
                        link: `${baseDomainUrl}/tasks/users/${encryptUserId(
                            user?.userId
                        )}`,
                        isInGroup: true,
                        // isDirectMessage: isDirectMess
                    }))
                    .value();

                return [
                    {
                        id: `@${translate('COMMON_LABEL_GROUP')}`,
                        userId: converInfo?.id,
                        name: converInfo?.name,
                        avatar: converInfo?.avatarUrl || converInfo?.avatar,
                        link: `${baseDomainUrl}/tasks/groups/${converInfo?.id}`,
                        isInGroup: true,
                        // isDirectMessage: false,
                        mentionGroup: true,
                    },
                    ...groupUsers,
                ];
            };
        },
        isExternalChat() {
            return (converId) =>
                this.getConversationInfo(converId)?.externalChat;
        },
        isSupportingChat() {
            return (converId) =>
                this.getConversationInfo(converId)?.supportingChat;
        },
        isMeSupporter() {
            return (converInfo) => {
                const myOrgId = organizationStore().organizationInfo?.id;
                const myUserId = myProfileStore().myProfile?.id;
                const myMemberInfo = converInfo?.members
                    ? converInfo?.members[myUserId]
                    : null;

                return (
                    myMemberInfo?.isSupporter &&
                    `${myMemberInfo?.supportingOrgId}` === `${myOrgId}`
                );
            };
        },
    },
    actions: {
        setIsFormChanged(value) {
            this.isFormChanged = value;
        },

        async checkPermissionConversation(conversationId: string) {
            if (!conversationId) return ConversationValidCode.UNDEFINED;

            const isGroupConversion = conversationId.startsWith('G-');

            if (isGroupConversion) {
                const ConversationDocClass = new ConversationDocModel(
                    conversationId
                );
                const doc = await ConversationDocClass.getDetail();

                if (!doc?.exists()) return ConversationValidCode.UNDEFINED;

                const currentConversation = doc?.data();

                if (currentConversation?.conversationDeleted) {
                    return ConversationValidCode.OPPONENT_DEACTIVE;
                }

                const _groupStore = groupStore();
                const groupId = conversationId.split('-').pop();

                if (!groupId) return ConversationValidCode.HAS_NOT_PERMISSION;

                return _groupStore.myGroupByIds[groupId]?.isMember
                    ? ConversationValidCode.VALID
                    : ConversationValidCode.HAS_NOT_PERMISSION;
            } else {
                const myProfile = myProfileStore().myProfile;

                const opponentId = conversationId
                    .split('-')
                    .filter((o) => o !== myProfile?.id?.toString())
                    .toString();

                const detailOpponent = userStore().getDetailByUserId(
                    parseInt(opponentId)
                );
                if (
                    !conversationId
                        .split('-')
                        .some((o) => o == myProfile?.id?.toString()) ||
                    !detailOpponent
                ) {
                    return ConversationValidCode.HAS_NOT_PERMISSION;
                }

                if (
                    this.userConversations[conversationId]?.isDeactive ||
                    detailOpponent?.isDeactive
                ) {
                    return ConversationValidCode.OPPONENT_DEACTIVE;
                }

                return ConversationValidCode.VALID;
            }
        },

        updateListMessagesError(messageId, isError) {
            if (!isError) {
                this.messagesSendPending.push(messageId);
            } else {
                this.messagesSendPending = this.messagesSendPending.filter(
                    (el) => el !== messageId
                );
            }
        },

        setIsFocusChatPanel(value) {
            this.isFocusChatPanel = value;
        },

        async setIdConversationActive(value) {
            this.idConversationActive = value;
        },

        async setLastSelectedConversation(value) {
            this.lastSelectedConversation = value;
        },

        setConversationActive(value) {
            this.conversationActive = value;
        },

        addUserConversation(conversId, conversation) {
            if (!this.userConversations[conversId]) {
                this.userConversationIds = [
                    ...this.userConversationIds,
                    conversId,
                ];
            }

            this.userConversations = {
                ...this.userConversations,
                [conversId]: conversation,
            };
        },

        updateUserConversation(conversId, conversation) {
            this.userConversations[conversId] = conversation;
        },

        deleteUserConversation(conversId) {
            this.userConversationIds = (this.userConversationIds || []).filter(
                (id) => id !== conversId
            );

            const userConversations = { ...this.userConversations };
            delete userConversations[conversId];
            this.userConversations = userConversations;
        },

        clearUserConversations() {
            this.userConversations = {};
            this.userConversationIds = [];
        },

        addBubbleChat(chatInfo, isActive = true) {
            if (this.bubbleChats?.every((chat) => chat?.id !== chatInfo?.id)) {
                this.bubbleChats = [...this.bubbleChats, chatInfo];
                if (this.bubbleChats?.length == 8) this.bubbleChats.shift();
            }
            if (isActive) this.activeBubbleChat(chatInfo);
        },

        async handleCheckFormHaveAction() {
            this.isClearActionForm = false;

            if (this.isFormChanged) {
                settingGlobalModal({
                    type: 'confirm',
                    title: '',
                    content:
                        translate('COMMON_LABEL_UNSAVED_CHANGES') ||
                        'Các thay đổi chưa được lưu?',
                    cancelLabel:
                        translate(
                            'CREATE_NEW_ORGANIZATION_NOTIFICATION_STAY_HERE'
                        ) || 'Stay here',
                    confirmLabel:
                        translate('COMMON_LABEL_DISCARD_AND_CONTINUE') ||
                        'Discard and continue',
                    confirmable: true,
                    closeable: true,
                });
                const confirmed = await ask();
                this.isClearActionForm = confirmed;
                return confirmed;
            }

            return true;
        },

        async getPreviewLinkInMessage(link) {
            const _commonStore = commonStore();
            const res = _commonStore.getPreviewLinkInfo(link);
            return res;
        },

        async activeBubbleChat(chatInfo) {
            const conversationId = chatInfo?.id;

            if (
                (this.isFormChanged && !this.isClearActionForm) ||
                !conversationId
            ) {
                return;
            }
            // chatPanelList.value = [conversationId];
            this.bubbleChatActive = chatInfo;
            isForceActive.value = true;

            this.isFocusChatPanel = true;
            this.idConversationActive = conversationId;
            isOpenTaskDrawer.value = false;
        },

        closeBubbleChat() {
            activeAndFocusOnConversationId.value = '';
            this.bubbleChatActive = null;
            isForceActive.value = false;
        },
        clearLastSelectedConversation() {
            this.lastSelectedConversation = null;
        },

        removeBubbleChat(chatInfo) {
            this.bubbleChats = (this.bubbleChats || []).filter(
                (chat) => chat?.id !== chatInfo?.id
            );

            if (this.bubbleChatActive?.id === chatInfo?.id) {
                this.bubbleChatActive = null;
                isForceActive.value = false;
            }
        },

        removeBubbleChatAll() {
            this.bubbleChatActive = null;
            this.bubbleChats = [];
            isForceActive.value = false;
        },

        clearBubbleChats() {
            this.bubbleChats = [];
            this.bubbleChatActive = null;
            isForceActive.value = false;
        },

        async setChatAboutTask(task) {
            let taskCustom = task;
            if (task?.notification_id) {
                taskCustom = await taskDrawerStore().getDetailById(
                    parseInt(task?.taskId.toString())
                );
            }
            this.chatAboutTask = new TaskDetailClass(taskCustom);
        },

        setChatAboutDayoff(dayoff) {
            this.chatAboutDayoff = new DayoffDetailClass(dayoff);
        },

        setChatAboutEvent(event) {
            this.chatAboutEvent = event;
        },

        setChatAboutResources(event) {
            this.chatAboutResources = event;
        },

        checkExistedConversation(userId) {
            const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);
            const myProfile = myProfileStore().myProfile;

            if (!organizationId || !myProfile?.id) return;

            const conversationId = genDirectConversationId(
                myProfile.id,
                userId
            );

            _verifyToCreateConversation(conversationId, myProfile, userId);
            return conversationId;
        },

        addMessageToUserMultiple(messages) {
            const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);
            const myProfile = myProfileStore().myProfile;

            if (!organizationId || !myProfile?.id) return;

            return new ConversationCollectionModel().addMultipleMessage(
                messages,
                myProfile.id
            );
        },

        processMessageFilesName(converId, message) {
            return _processMessageFilesName(converId, message);
        },

        genFileName(type, ext, converId) {
            return _genFileName(type, ext, converId);
        },

        async addMessage(organizationId, conversationId, userId, message) {
            let _newMsgDoc;
            const newMsg: any = {
                id: '',
                status: 1,
                createdBy: `${userId}`,
                createdDate: serverTimestamp(),
                updatedBy: `${userId}`,
                updatedDate: serverTimestamp(),
            };
            try {
                // console.log('🚀 ~ addMessage ~ newMsg', newMsg);
                // if (conversationId.toString().startsWith('G-')) {
                //   const myGroupByIds = groupStore().myGroupByIds;
                //
                //   const groupId = conversationId.toString().split('-').pop();
                //   newMsg.groupInfo = {
                //     name: myGroupByIds[groupId]?.name,
                //   };
                // }

                if (message?.text) newMsg.text = message.text;

                if (message?.comment) newMsg.comment = message.comment;

                if (message?.task) {
                    newMsg.task = _prepareMessageTask(message?.task);
                }

                if (message?.dayoff) {
                    newMsg.dayoff = _prepareMessageDayoff(message?.dayoff);
                }

                if (message?.eventData) {
                    newMsg.eventData = _prepareMessageEventCalendar(
                        message?.eventData
                    );
                }

                if (message?.call) {
                    newMsg.call = _prepareMessageCall(message.call);
                }

                let uploadMsgFiles;
                if (message?.files?.length) {
                    const { filesObj, uploadFiles } = _prepareMessageFiles(
                        organizationId,
                        message.files
                    );

                    newMsg.files = filesObj;
                    uploadMsgFiles = uploadFiles;
                }

                if (message?.replyTo) newMsg.replyTo = message.replyTo;

                if (
                    message?.isForwarded !== undefined &&
                    message?.isForwarded !== null
                ) {
                    newMsg.isForwarded = message.isForwarded;
                }

                if (message?.mentionUserIds?.length > 0) {
                    newMsg.mentionUserIds = message.mentionUserIds;
                }

                if (message?.linkObjects?.length > 0) {
                    newMsg.linkObjects = message.linkObjects;
                }

                _newMsgDoc = ChatService.getNewMessageDoc(
                    organizationId,
                    conversationId
                );

                // Upload message files
                if (uploadMsgFiles?.length) {
                    await Promise.all(
                        uploadMsgFiles.map(
                            async (uploadFile) =>
                                (newMsg.files[uploadFile.fileId].url =
                                    await this.uploadMessageFile(
                                        organizationId,
                                        uploadFile
                                    ))
                        )
                    );
                }

                ChatService.addMessage(
                    organizationId,
                    conversationId,
                    _newMsgDoc?.id,
                    newMsg
                );
            } catch (error) {
                this.messagesSendError.push(_newMsgDoc.id);
                console.log('error', error);
            }

            return { ...newMsg, id: _newMsgDoc.id };
        },

        async uploadMessageFile(organizationId, uploadFile) {
            if (!uploadFile) return null;

            this.uploadingFiles = {
                ...this.uploadingFiles,
                [uploadFile.fileId]: {
                    uploadStatus: $constants.UPLOAD.STATUS.UPLOADING,
                },
            };

            try {
                const path = await ChatService.uploadMessageFile(
                    organizationId,
                    uploadFile.fileId,
                    uploadFile.file
                );

                this.uploadingFiles = {
                    ...this.uploadingFiles,
                    [uploadFile.fileId]: {
                        uploadStatus: $constants.UPLOAD.STATUS.UPLOAD_SUCCESS,
                    },
                };

                return path;
            } catch (e) {
                this.uploadingFiles = {
                    ...this.uploadingFiles,
                    [uploadFile.fileId]: {
                        uploadStatus: $constants.UPLOAD.STATUS.UPLOAD_FAIL,
                    },
                };
                throw e;
            }
        },

        updateDraftMessage(conversationId, message) {
            if (!message) return;
            this.draftMessages[conversationId] = message;
        },

        async forwardMessage(organizationId, conversationId, userId, message) {
            const newMsg: any = {
                status: 1,
                isForwarded: true,
                createdBy: `${userId}`,
                createdDate: serverTimestamp(),
                updatedBy: `${userId}`,
                updatedDate: serverTimestamp(),
            };

            if (message?.text) newMsg.text = message.text;

            if (message?.comment) newMsg.comment = message.comment;

            if (message?.task) newMsg.task = message.task;

            if (message?.dayoff) {
                newMsg.dayoff = _prepareMessageDayoff(message?.dayoff);
            }

            if (message?.eventData) {
                newMsg.eventData = _prepareMessageEventCalendar(
                    message?.eventData
                );
            }

            if (message?.mentionUserIds?.length > 0) {
                newMsg.mentionUserIds = message.mentionUserIds;
            }

            if (message?.linkObjects?.length > 0) {
                newMsg.linkObjects = message.linkObjects;
            }
            // Forward files
            const msgFiles = Object.values<any>(message.files || {});

            if (msgFiles.length) {
                const newFiles = await Promise.all(
                    msgFiles.map(async (file) => {
                        const newFileId = uuidv4();
                        const newFile = { ...file, id: newFileId };
                        delete newFile.url_full;

                        newFile.url = await ChatService.forwardMessageFile(
                            organizationId,
                            newFileId,
                            file
                        );

                        return newFile;
                    })
                );

                newMsg.files = arrToObj(newFiles, 'id');
            }

            // Send message
            let _newMsgDoc;
            try {
                _newMsgDoc = await new MessageCollectionModel(
                    conversationId
                ).addMessage(newMsg);
            } catch (error) {
                this.messagesSendError.push(_newMsgDoc.id);
                console.log('error', error);
            }

            return { ...newMsg, id: _newMsgDoc.id };
        },

        updateMuteConversation(data) {
            const myUserId = myProfileStore().myProfile?.id;

            this.mutedConversations = Object.entries(data || {})
                .filter(
                    ([key, value]: [string, any]) =>
                        key &&
                        value?.function ===
                            NotificationSettingFunction.Messaging
                )
                .reduce((obj, [key, value]: [string, any]) => {
                    const [type, id] = key.split('-');

                    let conversationId;
                    switch (type) {
                        case 'personal':
                            conversationId = genDirectConversationId(
                                myUserId,
                                id
                            );
                            break;
                        case 'group':
                            conversationId = genGroupConversationId(id);
                            break;
                        case 'supporting':
                            conversationId = id;
                            break;
                    }

                    if (conversationId) {
                        obj[conversationId] = {
                            ...value,
                            muteId: key,
                        };
                    }

                    return obj;
                }, {});
        },

        async checkConversationCreated(orgId, converId): Promise<boolean> {
            const doc = await ChatService.getConversation(orgId, converId);

            if (doc?.exists() && doc?.data()?.createdDate) return true;

            const myProfile = myProfileStore().myProfile;
            const isGroup = converId.startsWith('G-');

            const info = {
                isGroup,
                isSelf:
                    genDirectConversationId(myProfile?.id, myProfile?.id) ===
                    converId,
            } as any;

            const members = [] as any[];

            // Group chat
            if (info.isGroup) {
                const allMyGroups = groupStore().allMyGroups;
                const groupInfo: any = (allMyGroups || []).find(
                    (group) => genGroupConversationId(group?.id) === converId
                );

                if (!groupInfo) return false;

                info.name = groupInfo.name;
                info.avatar =
                    groupInfo.avatarThumnailUrl || groupInfo.avatarUrl;

                groupInfo.groupUsers?.forEach((user) => {
                    members.push({
                        id: user?.userId,
                        name:
                            user?.name ||
                            `${user?.lastName} ${user?.firstName}`,
                        avatar: user?.avatarThumbnailUrl || user?.avatarUrl,
                    });
                });
            }
            // User chat
            else {
                const allUser = userStore().listShortInfosUser;
                const userInfo = (allUser || []).find(
                    (user) =>
                        genDirectConversationId(myProfile?.id, user?.id) ===
                        converId
                );

                if (!userInfo) return false;

                members.push({
                    id: myProfile?.id,
                    name: myProfile?.fullName,
                    avatar:
                        myProfile?.avatarThumbnailUrl || myProfile?.avatarUrl,
                });

                if (!info.isSelf) {
                    members.push({
                        id: userInfo?.id,
                        name: userInfo?.name,
                        avatar:
                            userInfo?.profilePictureThumbsUrl ||
                            userInfo?.profilePictureUrl,
                    });
                }
            }

            await new ConversationCollectionModel().add(
                converId,
                info,
                members,
                myProfile?.id
            );

            return true;
        },

        async autoGetNewMessages(conversationId, lastMsgId): Promise<any> {
            const messageCollectionModel = new MessageCollectionModel(
                conversationId
            );

            const snapshot = await messageCollectionModel.getMessagesFromLocal(
                null,
                null,
                1
            );

            const lastMsgFromCache = snapshot?.size
                ? snapshot.docs[snapshot.size - 1]
                : null;
            if (lastMsgFromCache?.id === lastMsgId) return;

            return await messageCollectionModel.getMessagesFromServer(
                null,
                lastMsgFromCache,
                MESSAGES_PAGE_SIZE
            );
        },

        sortReactionsList(reactions) {
            const totalReaction = (reaction) =>
                reactions.filter((r) => r?.createdBy === reaction?.createdBy)
                    .length;

            return orderBy(
                reactions,
                [totalReaction, 'createdDate', 'id'],
                ['desc', 'desc', 'asc']
            );
        },

        async createGroupChat(group, userId) {
            const conversationId = genGroupConversationId(group?.id);

            const conversationModel = new ConversationCollectionModel();

            const info: any = {
                name: group?.name,
                avatar: group?.avatar,
                isGroup: true,
            };

            if (group?.chatAboutTaskId) {
                info.chatAboutTaskId = group.chatAboutTaskId;
                info.chatAboutTaskCode = group.chatAboutTaskCode;
            }

            const members = group?.groupUsers?.map((user) => ({
                id: user?.userId,
                name: user?.name || `${user?.lastName} ${user?.firstName}`,
                avatar: user?.avatarThumbnailUrl || user?.avatarUrl,
            }));

            await conversationModel.add(conversationId, info, members, userId);

            return {
                ...info,
                id: conversationId,
                contactId: group?.id,
                createdDate: new Date(),
            };
        },

        genGroupChatName(members, chatAboutTask) {
            if (chatAboutTask) {
                let taskName = chatAboutTask.name || '';
                if (taskName.length > 53) {
                    taskName = `${taskName.slice(0, 50)}...`;
                }

                return `${formatTaskCode(chatAboutTask.code)} - ${taskName}`;
            }

            const membersName = chain(members)
                .map((mem) => last(mem?.name?.split(' ')) || mem?.firstName)
                .orderBy([(name) => stringNomalize(name?.charAt(0))], ['asc'])
                .value();

            let name = stringNomalize(
                membersName.map((firstName) => firstName?.charAt(0)).join('')
            );

            if (membersName.length > 3) {
                name += ` - ${membersName.join('-')}`;
            }

            return name.substring(0, 50);
        },

        autoAddCurrentUserChat() {
            const myProfile = myProfileStore().myProfile;

            const converId = genDirectConversationId(
                myProfile?.id,
                myProfile?.id
            );

            this.userConversations[converId] = {
                ...this.userConversations[converId],
                id: converId,
                name: myProfile?.fullName,
                avatar: myProfile?.avatarUrl,
            };

            if (!this.userConversationIds.includes(converId)) {
                this.userConversationIds.push(converId);
            }
        },

        setSeenUserConversation(orgId, converId) {
            const userId = myProfileStore().myProfile?.id;

            return ChatService.setSeenUserConversation(orgId, userId, converId);
        },

        verifyExistedConversationOfContact(contact, isGroup) {
            const myUserId = myProfileStore().myProfile?.id;

            const converId = isGroup
                ? genGroupConversationId(contact?.id)
                : genDirectConversationId(contact?.id, myUserId);

            // Get conversation by external user
            const existedConver = this.conversationById[converId]
                ? {
                      ...this.conversationById[converId],
                      id: converId,
                  }
                : null;

            // If has conversation, go to it
            if (existedConver) return existedConver;

            // If no conversation, create a draft
            this.conversationsDraft[converId] = _prepareConversationDraft(
                {
                    ...contact,
                    isGroup,
                    id: converId,
                    contactId: contact?.id,
                },
                this
            );

            return this.conversationsDraft[converId];
        },

        verifyExistedConversation(conversation) {
            const converId = conversation?.id;

            // Get conversation
            const existedConver = this.conversationById[converId]
                ? {
                      ...this.conversationById[converId],
                      id: converId,
                  }
                : null;

            // If has conversation, go to it
            if (existedConver) return existedConver;

            // If no conversation, create a draft
            this.conversationsDraft[converId] = _prepareConversationDraft(
                conversation,
                this
            );

            return this.conversationsDraft[converId];
        },

        toggleShowTaskListInChat(conversationId: string) {
            if (
                this.hasVisibleTaskListConversationIds?.includes(conversationId)
            ) {
                this.hasVisibleTaskListConversationIds =
                    this.hasVisibleTaskListConversationIds?.filter(
                        (id) => id !== conversationId
                    );
            } else {
                this.hasVisibleTaskListConversationIds.push(conversationId);
            }
        },

        async getUsersGroupsForChat(orgId = null, refresh = false) {
            if (this.usersChatInfo && !refresh) return this.usersChatInfo;

            const res = await UserService.getInstance().getUsersGroupsForChat(
                orgId
            );

            return (this.usersChatInfo = res?.result || []);
        },
    },
});

const _verifyToCreateConversation = async (
    conversationId,
    myProfile,
    userId
) => {
    if (!myProfile.id) return;

    const doc = await new ConversationDocModel(conversationId).getDetail();

    if (!doc?.exists() || !doc?.data()?.createdDate) {
        const info = {
            isGroup: false,
            isSelf: `${userId}` === `${myProfile.id}`,
        };

        const members: any[] = [];

        const allUser = userStore().listShortInfosUser;
        const userInfo = (allUser || []).find(
            (user) => `${user?.id}` === `${userId}`
        );

        if (!userInfo) return;

        members.push({
            id: myProfile.id,
            name: myProfile.fullName,
            avatar: myProfile.avatarThumbnailUrl || myProfile.avatarUrl,
        });

        if (!info.isSelf) {
            members.push({
                id: userInfo?.id,
                name: userInfo?.name,
                avatar:
                    userInfo?.profilePictureThumbsUrl ||
                    userInfo?.profilePictureUrl,
            });
        }
        new ConversationCollectionModel().add(
            conversationId,
            info,
            members,
            myProfile.id
        );
    }
};

const _prepareMessageFiles = (organizationId, files) => {
    const uploadFiles: any[] = [];

    const filesObj = (files || []).reduce((obj, file, index) => {
        const fileId = uuidv4();
        const contentType = file?.type || '';
        const size = file?.size || 0;
        const name = file?.name || '';
        const url = file?.url || URL.createObjectURL(file);

        obj[fileId] = { name, size, contentType, url, index };

        uploadFiles.push({ fileId, file });

        return obj;
    }, {});

    return { filesObj, uploadFiles };
};

const _prepareMessageCall = (call) => {
    return {
        ...call,
        createdDate: Timestamp.fromDate(new Date(call.createdDate)),
    };
};

const _prepareMessageTask = (task) => {
    return {
        taskSchedule: task.taskSchedule || 0,
        id: task.id || '',
        code: task.code || '',
        assigneeId: task.assigneeId || 0,
        groupId: task.groupId || 0,
        scheduleTime: task?.scheduleTime || '',
        taskLife: task.taskLife || 0,
        urgency: task.urgency || 0,
        priority: task.priority || 0,
        important: task.important || false,
        creatorId: task.creatorId || 0,
        domainId: task.domainId || 0,
        name: task.name || '',
    };
};

const _prepareMessageDayoff = (dayoff) => {
    return {
        id: dayoff.id || '',
        type: dayoff.type || '',
        status: dayoff.status || '',
        startDate: dayoff.startDate || '',
        endDate: dayoff.endDate || '',
        requestNote: dayoff.requestNote || '',
        userId: dayoff.userId || '',
        dateOffs: dayoff.dateOffs || [],
        usedDay: dayoff.usedDay || [],
        exception: dayoff.exception || {},
    };
};

const _prepareMessageEventCalendar = (eventData) => {
    return {
        id: eventData.id || '',
        title: eventData.title || '',
        startDate: eventData.startDate || '',
        endDate: eventData.endDate || '',
        type: eventData.type || '',
        createdBy: eventData.createdBy || '',
        participantIds: eventData.metadata.participantIds || [],
        meetingType: eventData.metadata.meetingType || 0,
        description: eventData.description || '',
        workspace: eventData.metadata.workspace || '',
    };
};

const _processMessageFilesName = (converId, message) => {
    if (!message?.files?.length) return;

    const pastedPhotoIdxs = message.files.reduce((arr, file, index) => {
        if (file?.isPasted && file?.name === 'image.jpeg') arr.push(index);
        return arr;
    }, []);

    const fileName = _genFileName('Photo', 'jpeg', converId);

    if (pastedPhotoIdxs.length === 1) {
        const photoIdx = pastedPhotoIdxs[0];
        const msgFile = message.files[photoIdx];
        message.files[photoIdx] = new File([msgFile], fileName, {
            type: msgFile?.type,
        });
    } else {
        pastedPhotoIdxs.forEach((photoIdx, index) => {
            const msgFile = message.files[photoIdx];
            const msgFileName = fileName.replace(
                '.jpeg',
                ` (${index + 1}).jpeg`
            );
            message.files[photoIdx] = new File([msgFile], msgFileName, {
                type: msgFile?.type,
            });
        });
    }
};

const _genFileName = (type, ext, converId) => {
    let chatName;
    const _userStore = userStore();
    const _groupStore = groupStore();

    if (converId?.startsWith('G-')) {
        const allMyGroups = _groupStore.allMyGroups;
        const groupInfo = (allMyGroups || []).find(
            (group) => genGroupConversationId(group?.id) === converId
        );

        chatName = ignoreUnicode(groupInfo?.name, false);
    } else {
        let memberIds = converId?.split('-');
        memberIds = uniq(memberIds);

        const members = keyBy(_userStore.listShortInfosUser, 'id');
        let memberName = memberIds.map((memberId) =>
            members[memberId]?.name?.split(' ').pop()
        );
        memberName = sortBy(memberName);
        chatName = ignoreUnicode(memberName.join('-'), false);
    }

    const time = formatDate(new Date(), 'YYYY-MM-DD hh.mm A');

    return `${type}-Chat ${chatName} ${time}.${ext}`;
};

const _prepareConversationDraft = (conversation, state) => {
    if (!conversation) return conversation;

    const myUserId = myProfileStore().myProfile?.id;
    const converId = conversation.id;
    let chatInfo;

    if (conversation?.isGroup) {
        chatInfo = state.usersChatInfo?.find(
            (info) =>
                info?.isGroup && genGroupConversationId(info?.id) === converId
        ) || { isDeleted: true };
    } else {
        chatInfo = state.usersChatInfo?.find(
            (info) =>
                !info?.isGroup &&
                genDirectConversationId(info?.id, myUserId) === converId
        ) || { isDeleted: true };
    }

    return {
        ...conversation,
        id: converId,
        contactId: chatInfo?.id,
        isDeactive: !chatInfo?.isActive,
        isDisallowed: !chatInfo?.isAllowed,
        isTaskDisallowed: !chatInfo?.isTaskAllowed,
        isGroupChatDisallowed:
            chatInfo?.isGroup && !chatInfo?.isGroupChatAllowed,
    };
};
