1
<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import calendarStore from '@/store/calendar';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { CalendarMeetingType } from '@/ui/common/constants/calendar';
import UploadPreview from '@/ui/components/file-viewer/UploadPreview.vue';
import SynDeleteModal from '@/ui/common/molecules/SynModal/SynDeleteModal.vue';
import ModalMultipleFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewer.vue';
import CallButtons from '@/ui/modules/video-call/components/CallButtons.vue';
import ModalNewChat from '@/ui/modules/messaging/modal-new-chat/ModalNewChat.vue';
import { IEventDetail } from '@/application/types/calendar/event.types';
import GoogleUserInfo from '@/ui/components/google/GoogleUserInfo.vue';
import { GoogleService } from '@/domain/enums/google.enum';
import { copyToClipBroard } from '@/ui/hooks/commonFunction';
import { CalendarSourceId } from '@/domain/enums/CalendarEnum';
import { openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import CalendarEventDate from '@/ui/modules/calendar/detail-view-event/CalendarEventDate.vue';
import CalendarEventCreator from '@/ui/modules/calendar/detail-view-event/CalendarEventCreator.vue';
import CalendarEventParticipants from '@/ui/modules/calendar/detail-view-event/CalendarEventParticipants.vue';
import CalendarEventNotif from '@/ui/modules/calendar/detail-view-event/CalendarEventNotif.vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import InformationPopupFromLink from '@/ui/components/popup/InformationPopupFromLink.vue';
import CalendarEventRepeat from '@/ui/modules/calendar/detail-view-event/CalendarEventRepeat.vue';
import EventCreateModal from '@/ui/modules/calendar/event/EventCreateModal.vue';

dayjs.extend(utc);

const props = defineProps({
  event: {
    default: () => {},
    type: Object,
  },
  eventId: {
    default: '',
    type: String,
  },
  customClass: {
    default: 'p-4',
    type: String,
  },
});

const onRemoveAtt = () => {
  console.log('File: DetailViewMeeting.vue - L: 34 - ');
};

watch(
  () => props.event,
  () => {
    initData();
  }
);

watch(
  () => props.eventId,
  () => {
    initData();
  }
);

const emit = defineEmits(['cancel', 'edit', 'onUpdate']);

const _calendarStore = calendarStore();

const eventId = computed(() => props.event?.id || props.eventId);
const eventDetail = computed<IEventDetail | null>(() => {
  if (!_calendarStore.calendarDetailCurrent) return null;

  const eventData = { ..._calendarStore.calendarDetailCurrent };
  if (!eventData.startDate) return eventData;

  if (props.event?.start) {
    const duration = eventData.endDate
      ? dayjs(eventData.endDate).diff(eventData.startDate, 'minutes')
      : 0;

    const startUtc = dayjs(eventData.startDate).local();

    eventData.startDate = eventData.allDay
      ? props.event?.start
      : dayjs(props.event?.start)
          .local()
          .set('hours', startUtc.get('hours'))
          .set('minutes', startUtc.get('minutes'))
          .toISOString();

    eventData.endDate = dayjs(eventData.startDate)
      .add(duration, 'minutes')
      .toISOString();
  }

  return eventData;
});
const eventDetailEdit = computed<IEventDetail | null>(() => {
  if (!_calendarStore.calendarDetailCurrent) return null;

  return { ..._calendarStore.calendarDetailCurrent };
});

const isGoogleCalendarEvent = computed<boolean>(() => {
  return eventDetail.value?.sourceId === CalendarSourceId.googleCalendar;
});
const isEdit = ref(false);
const isLoading = ref(false);
const isLoadData = ref(false);
const currentFileIndex = ref(0);
const isPreview = ref(false);
const isLoadingRemove = ref(false);
const isRemoveEvent = ref(false);
const currentUserId = getCurrentUserId();
const isNewChat = ref();

const onChatAboutTask = () => {
  isNewChat.value = false;
  emit('cancel');
};

// FILES
const attachedFiles = computed(() => {
  if (!eventDetail.value?.metadata?.attachments?.files) return [];
  return eventDetail.value?.metadata?.attachments?.files
    ?.filter((attachment) => !String(attachment['type']).includes('audio'))
    .map((o) => {
      o.contentType = o.type;
      return o;
    });
});

const attachedAudios = computed(() => {
  if (!eventDetail.value?.metadata?.attachments?.files) return [];
  return eventDetail.value?.metadata?.attachments?.files
    ?.filter((attachment) => String(attachment['type']).includes('audio'))
    .map((o) => {
      o.contentType = o.type;
      return o;
    });
});

const initData = async () => {
  isLoading.value = true;
  await _calendarStore.getDetail(eventId.value);
  isLoading.value = false;
};

onMounted(() => {
  initData();
});

const genAudioUrl = (record) => {
  return URL.createObjectURL(record);
};

const onOpenFiles = (file, index) => {
  if (eventDetail.value?.sourceId === CalendarSourceId.googleCalendar) {
    return window.open(file?.fileUrl, '_blank');
  }

  currentFileIndex.value = index;
  isPreview.value = true;
};

const onRemove = async () => {
  isLoadingRemove.value = true;
  try {
    await _calendarStore.deleteEvent(eventDetail.value.id);
    isLoadingRemove.value = false;
  } catch (e) {
    isLoadingRemove.value = false;
  }
  emit('cancel', true);
};

const onUpdate = async (isUpdate) => {
  if (isUpdate) {
    isLoadData.value = isUpdate;
    isLoading.value = true;
    await _calendarStore.getDetail(eventId.value);
    isLoading.value = false;
    isEdit.value = false;
    emit('onUpdate', isEdit.value);
  }
};

const onEdit = () => {
  isEdit.value = true;
  // emit('edit', isEdit.value);
};

const onCancel = async () => {
  isEdit.value = false;
  // emit('edit', isEdit.value);
  isLoadData.value = true;
  isLoading.value = true;
  await _calendarStore.getDetail(eventId.value);
  isLoading.value = false;
};

const eventIcon = computed<string>(() => {
  if (isGoogleCalendarEvent.value) return 'GoogleCalendar';
  return eventDetail.value?.metadata?.meetingType ===
    CalendarMeetingType.OnlineMeeting
    ? 'Video'
    : 'Event';
});

const eventTitle = computed<string>(() => {
  if (isGoogleCalendarEvent.value) return 'COMMON_LABEL_EVENT';
  return eventDetail.value?.metadata?.meetingType ===
    CalendarMeetingType.OnlineMeeting
    ? 'COMMON_LABEL_MEETING'
    : 'COMMON_LABEL_EVENT';
});

const isEditable = computed<boolean>(() => {
  return eventDetail.value?.sourceId !== CalendarSourceId.googleCalendar;
});

const onCopyLinkClick = (link) => {
  const copied = copyToClipBroard(link);
  if (copied) {
    openNotification({
      body: translate('COMMON_LABEL_COPIED'),
      duration: 2000,
    });
  }
};

const informationPopupRef = ref<any>(null);
const onHoverTextMessage = async (event) => {
  if (
    informationPopupRef.value &&
    typeof informationPopupRef.value?.onHoverTextMessage == 'function'
  ) {
    informationPopupRef.value?.onHoverTextMessage(event);
  }
};

const onMouseLeaveTextMessage = (event) => {
  if (
    informationPopupRef.value &&
    typeof informationPopupRef.value?.onMouseLeaveTextMessage == 'function'
  ) {
    informationPopupRef.value?.onMouseLeaveTextMessage(event);
  }
};
</script>
<template>
  <div class="h-full" :class="customClass" style="width: 40rem">
    <div
      v-if="isLoading"
      class="flex items-center space-y-2 pt-4 pb-8 px-4 flex-col"
    >
      <div class="flex items-center space-x-2 w-full">
        <div class="rounded-full bg-gray-200 animate-pulse w-8 h-4"></div>
        <div class="rounded-full bg-gray-200 animate-pulse w-full h-4"></div>
      </div>
      <div class="flex items-center space-x-2 w-full">
        <div class="rounded-full animate-pulse w-8 h-4"></div>
        <div class="rounded-md bg-gray-200 animate-pulse w-full h-10"></div>
      </div>
    </div>

    <div v-else class="px-2 pb-4 flex flex-col">
      <template v-if="!eventDetail">
        <div class="flex-center flex-col space-y-4">
          <syn-animation
            name="searchNotFound"
            stype="width: 150px; height:150px"
          />
          <span class="font-semibold text-2xl text-current-500">{{
            $t('COMMON_SOMETHING_WRONG') || 'Something wrong'
          }}</span>
          <div class="text-gray-600">
            {{
              $t('COMMON_LABEL_PAGE_NOT_FOUND_BODY', {
                name: $t('CALENDAR_FILTER_TITLE_TYPE_EVENT'),
              })
            }}
          </div>
        </div>
      </template>
      <template v-if="eventDetail /*&& !isEdit*/">
        <!-- ACTION -->
        <div class="flex items-center justify-between">
          <div class="flex-center gap-2">
            <SynIcon :name="eventIcon" custom-class="w-5 h-5 fill-purple-500" />
            <span class="font-semibold text-purple-500">{{
              $t(eventTitle)
            }}</span>
          </div>

          <div class="flex items-center gap-1">
            <VigButton
              v-vig-tooltip="$t('EVENT_DISCUSS_ABOUT_WITH')"
              ghost
              color="gray"
              icon="Message"
              @click.stop="isNewChat = true"
            />
            <template
              v-if="
                isEditable &&
                /*!isEdit &&*/
                currentUserId === eventDetail?.createdBy
              "
            >
              <VigButton
                v-vig-tooltip="$t('TASK_DETAIL_EDIT')"
                ghost
                color="gray"
                icon="Edit"
                @click.stop="onEdit"
              />
              <VigButton
                v-vig-tooltip="$t('TASK_DETAIL_REMOVE')"
                ghost
                color="gray"
                icon="Trash"
                @click.stop="isRemoveEvent = true"
              />
            </template>
          </div>
        </div>

        <!-- TITLE -->
        <div class="text-2xl mt-1">
          {{ eventDetail?.title }}
        </div>

        <!--TIME & CREATOR-->
        <div class="flex items-center justify-between gap-4 mt-3">
          <!--TIME-->
          <div class="flex-center gap-4">
            <SynIcon name="Calendars" class="fill-gray-500" />
            <CalendarEventDate :event="eventDetail" />
          </div>

          <!--CREATOR-->
          <div>
            <div class="text-gray-500 text-2xs text-right">
              {{ $t('COMMON_LABEL_CREATED_BY') }}
            </div>
            <CalendarEventCreator :event="eventDetail" />
          </div>
        </div>

        <!-- REPEAT -->
        <CalendarEventRepeat :event="eventDetail" class="mt-3" />

        <!-- MEETING ONLINE -->
        <div
          v-if="
            eventDetail.metadata?.meetingType ===
              CalendarMeetingType.OnlineMeeting &&
            currentUserId === eventDetail?.createdBy
          "
          class="flex-center border-b border-t py-2 mt-4"
        >
          <div class="flex items-center space-x-2">
            <call-buttons
              :duration-time-call="eventDetail?.metadata?.meetingDuration"
              :list-receivers="eventDetail?.metadata?.participantIds"
            ></call-buttons>
          </div>
        </div>

        <!-- DURATION MEETING -->
        <div
          v-if="
            eventDetail.metadata?.meetingType ===
              CalendarMeetingType.OnlineMeeting &&
            eventDetail.metadata?.meetingDuration
          "
          class="flex items-center gap-4 mt-3"
        >
          <div :title="$t('COMMON_LABEL_ESTIMATE_TIME')" class="flex-center">
            <SynIcon name="Timer" class="fill-gray-500" />
          </div>
          <div class="text-sm">
            <span>{{ $t('COMMON_LABEL_ESTIMATE_TIME') + ': ' }}</span>
            <span class="font-medium text-current-600">{{
              eventDetail.metadata?.meetingDuration +
              '  ' +
              $t('COMMON_LABEL_MINUTE')
            }}</span>
          </div>
        </div>

        <!-- LOCATION -->
        <div
          v-if="
            eventDetail.metadata?.meetingType ===
              CalendarMeetingType.PhysicalMeeting &&
            eventDetail.metadata?.workspace
          "
          class="flex items-start gap-4 mt-3"
        >
          <div :title="$t('CALENDAR_LABEL_WORK_SPACE')" class="flex-center">
            <SynIcon name="location" class="fill-gray-500" />
          </div>
          <div class="text-sm">
            {{ eventDetail.metadata?.workspace }}
          </div>
        </div>

        <!-- DESCRIPTION -->
        <div
          v-if="eventDetail?.description"
          class="flex items-start gap-4 mt-3"
        >
          <div :title="$t('COMMON_LABEL_WRITE')" class="flex-center py-0.5">
            <SynIcon name="align-left" class="fill-gray-500" />
          </div>
          <div
            class="text-sm msg-text"
            @mouseover="onHoverTextMessage"
            @mouseleave="onMouseLeaveTextMessage"
            v-html="eventDetail?.description"
          ></div>
        </div>

        <InformationPopupFromLink
          ref="informationPopupRef"
          :custom-class="'border border-current overflow-hidden'"
        />

        <!-- ATTACHMENTS -->
        <div
          v-if="attachedAudios?.length > 0 || attachedFiles?.length > 0"
          class="flex items-start gap-4"
        >
          <div
            :title="$t('TASK_DETAIL_LABEL_ATTACHMENT')"
            class="flex-center py-4"
          >
            <SynIcon name="Attach" class="fill-gray-500" />
          </div>
          <div
            class="
              flex
              items-center
              gap-1.5
              pt-3
              pb-1
              pr-2
              overflow-hidden
              small-scrollbar
            "
            style="max-height: 25vh"
          >
            <UploadPreview
              v-for="(file, index) in attachedFiles.filter((e) => !e.isDeleted)"
              :key="file"
              :file="file"
              readonly
              custom-class=""
              @on-select="onOpenFiles(file, index)"
              @remove="onRemoveAtt()"
            />
            <VigAudio
              v-for="record in attachedAudios?.filter((o) => !o?.noteAudioId)"
              :key="record"
              :path="record.src || record.fileUrl || genAudioUrl(record)"
              type="view"
            />
          </div>
        </div>

        <!-- PARTICIPANT -->
        <CalendarEventParticipants :event="eventDetail" class="mt-3" />

        <!-- NOTICE -->
        <CalendarEventNotif :event="eventDetail" class="mt-3" />

        <!--GOOGLE CALENDAR INFO-->
        <div v-if="isGoogleCalendarEvent" class="mt-6">
          <div class="flex items-center gap-4 mb-3">
            <div class="text-xs italic">
              {{ $t('CALENDAR_SYNCHRONIZE_WITH_GOOGLE_CALENDAR') }}
            </div>
            <div class="flex-1 border-b-2 border-dashed"></div>
          </div>
          <div class="flex items-center justify-between">
            <!-- GOOGLE USER -->
            <GoogleUserInfo
              :user="eventDetail.synchronization?.syncAccount"
              :service="GoogleService.CALENDAR"
            />
            <!-- SYNCHRONIZE -->
            <div class="text-sm">
              <div class="text-2xs text-gray-500 text-right">
                {{ $t('CALENDAR_LAST_SYNCHRONIZATION') }}
              </div>

              <SynLabelDateTime
                format="date_time"
                :datetime="eventDetail.synchronization?.lastSyncDate"
              />
            </div>
          </div>
          <div
            v-if="eventDetail.synchronization?.googleMeetLink"
            class="flex items-center gap-3 mt-4"
          >
            <SynIcon name="GoogleMeet" custom-class="w-8 h-8" />
            <div>
              <div>
                <a
                  :href="eventDetail.synchronization?.googleMeetLink"
                  target="_blank"
                >
                  <VigButton padding="px-2 py-1" class="text-sm"
                    >{{ $t('CALENDAR_GOOGLE_MEET_JOIN') }}
                  </VigButton>
                </a>
              </div>
              <div class="flex items-center gap-2">
                <div class="text-xs text-gray-500">
                  {{ eventDetail.synchronization?.googleMeetLink }}
                </div>
                <VigButton
                  v-vig-tooltip="$t('TASK_DETAIL_COPY_LINK')"
                  ghost
                  color="gray"
                  padding="p-1"
                  @click="
                    onCopyLinkClick(eventDetail.synchronization?.googleMeetLink)
                  "
                >
                  <SynIcon name="duplicate" custom-class="w-3 h-3" />
                </VigButton>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!--      <template v-if="eventDetail && isEdit">-->
      <!--        <FormMeeting-->
      <!--          max-height="400px"-->
      <!--          :date-start="formatDate(eventDetail.startDate).datetime"-->
      <!--          :title="eventDetail?.title"-->
      <!--          :is-edit="isEdit"-->
      <!--          @on-update="(value) => onUpdate(value)"-->
      <!--          @cancel="(value) => onCancel(value)"-->
      <!--        ></FormMeeting>-->
      <!--      </template>-->
    </div>
  </div>

  <EventCreateModal
    v-if="isEdit"
    :default-data="eventDetailEdit"
    :is-edit="true"
    class="flex-1 overflow-hidden"
    @on-create-success="onCancel"
    @on-update="onUpdate"
    @cancel="onCancel"
  />

  <modal-multiple-file-viewer
    v-if="isPreview"
    :start-index="currentFileIndex"
    :files="attachedFiles"
    @on-close="isPreview = false"
  />
  <syn-delete-modal
    v-if="isRemoveEvent"
    :content="eventDetail?.title"
    :type-content="$t('CALENDAR_FILTER_TITLE_TYPE_EVENT') + ' - '"
    :title="$t('CALENDAR_REMOVE_EVENT_CONTENT')"
    @cancel="(value) => (isRemoveEvent = value)"
    @on-save="onRemove()"
  ></syn-delete-modal>

  <ModalNewChat
    v-if="isNewChat"
    :title="$t('EVENT_DISCUSS_ABOUT_WITH') || 'Discuss about this event'"
    :attached-event="eventDetail"
    @on-close="isNewChat = false"
    @on-conversation-click="onChatAboutTask()"
  />
</template>
