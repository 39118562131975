<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import chatStore from '@/store/chat';
import {
  isShowTaskDetailGlobal,
  taskCodeGlobal,
} from '@/ui/modules/task/task-global-state';
import {
  activeAndFocusOnConversationId,
  isForceActive,
} from '@/ui/modules/messaging/chat-widget/chat-widget-public-state';
import focusOnConversationComposables from '@/ui/composables/chat/focus-on-conversation-composables';
import ChatPanel from '@/ui/modules/messaging/chat-panel/ChatPanel.vue';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import { getCurrentOrganizationId } from '@/ui/hooks/storageHook';
import { ChatMessageAction } from '@/domain/enums/chat-enums';
import {
  dayoffIdGlobal,
  eventIdGlobal,
  isShowDayoffDetail,
} from '@/ui/app-global-state';
import chatExternalStore from '@/store/chat/chat-external';
import chatSupportingStore from '@/store/chat/chat-supporting';
import ChatInfoHeader from '@/ui/modules/messaging/chat-info-header/ChatInfoHeader.vue';
import ChatBubbleAdd from '@/ui/modules/messaging/chat-widget/chat-bubble-add/ChatBubbleAdd.vue';
import ChatBubble from '@/ui/modules/messaging/chat-widget/chat-bubble/ChatBubble.vue';
import TaskCardList from '@/ui/modules/task/board/task-card/TaskCardList.vue';
// import taskDrawerStore from '@/store/task/drawer';
import myProfileStore from '@/store/auth/my-profile';

const _useRoute = useRoute();
const _chatStore = chatStore();
const _chatExternalStore = chatExternalStore();
const _chatSupportingStore = chatSupportingStore();
const _currentOrgId = getCurrentOrganizationId();

const contacts = computed(() => _chatStore.getContacts);
const allUsers = computed(() => _chatStore.allUsers);
const bubbleChats = computed(() => _chatStore.bubbleChats);
const hasVisibleTaskListConversationIds = computed(
  () => _chatStore.hasVisibleTaskListConversationIds
);
const isShowTaskListInChat = computed<boolean>(() => {
  if (!bubbleChatActive.value) return false;

  return hasVisibleTaskListConversationIds.value?.includes(
    bubbleChatActive.value?.id
  );
});
const bubbleChatActive = computed(() => {
  const converId = _chatStore.bubbleChatActive?.id;
  const converInfo =
    _chatStore.getConversationInfo(converId) || _chatStore.bubbleChatActive;

  return converInfo
    ? {
        ..._chatStore.bubbleChatActive,
        organizationId: converInfo?.externalChat
          ? _chatExternalStore.externalOrgId
          : converInfo?.supportingChat
          ? _chatSupportingStore.supportingOrgId
          : _currentOrgId,
      }
    : null;
});

const conversationActiveType = computed<'USER' | 'GROUP'>(() => {
  if (!bubbleChatActive.value) return 'USER';
  return bubbleChatActive.value?.id?.startsWith('G-') ? 'GROUP' : 'USER';
});
const taskListOwnerId = computed<string | number>(() => {
  if (!bubbleChatActive.value) return '';
  if (bubbleChatActive.value?.id?.startsWith('G-')) {
    return bubbleChatActive.value?.id?.split('-')?.pop();
  }

  return bubbleChatActive.value?.id
    ?.split('-')
    .filter((o) => o !== myProfileStore().myProfile?.id?.toString())
    .toString();
});

const hasBubbleChatAddNew = computed(() => _chatStore.hasBubbleChatAddNew);
const activeConverInfo = computed(() =>
  _chatStore.getConversationInfo(bubbleChatActive.value?.id)
);
const activeConverContact = computed<any>(() =>
  contacts.value ? contacts.value[bubbleChatActive.value?.id] : null
);

const chatWidgetRef = ref(null as any);

focusOnConversationComposables(
  chatWidgetRef,
  () => {
    activeAndFocusOnConversationId.value = '';
    isForceActive.value = false;
  },
  bubbleChatActive
);

const bubbleChatsVisible = computed(
  () => !_useRoute.path?.includes('/messages')
);

const conversationById = computed(() => _chatStore.conversationById);

const chatPanelRef = ref();

const chatMsgAllowedActions = computed(() =>
  [
    ChatMessageAction.REPLY,
    ChatMessageAction.FORWARD,
    ChatMessageAction.REACTION,
    ChatMessageAction.PIN,
    ChatMessageAction.COPY,
    ChatMessageAction.SET_BACKGROUND,
    ChatMessageAction.DELETE,
    ChatMessageAction.DOWNLOAD_FILE,
    ChatMessageAction.CREATE_TASK,
    ChatMessageAction.REMINDER,
  ].concat(
    activeConverInfo.value?.externalChat ||
      activeConverInfo.value?.supportingChat
      ? []
      : [ChatMessageAction.USER_ACTIONS]
  )
);

watch(
  () => _chatStore.chatAboutTask,
  (currValue) => {
    chatPanelRef.value?.setChatAboutTask(currValue);
  }
);

watch(
  () => _chatStore.chatAboutDayoff,
  (currValue) => {
    chatPanelRef.value?.setChatAboutDayoff(currValue);
  }
);

watch(
  () => _chatStore.chatAboutEvent,
  (currValue) => {
    chatPanelRef.value?.setChatAboutEvent(currValue);
  }
);

watch(
  () => _chatStore.chatAboutResources,
  (currValue) => {
    chatPanelRef.value?.setMessage(currValue);
  }
);

watch(
  () => _chatStore.bubbleChatActive,
  () => {
    activeAndFocusOnConversationId.value = `${bubbleChatActive?.value?.id}`;
  }
);

onMounted(() => {});

const onBubbleChatClick = async (chat) => {
  const discardAndContinue = await _chatStore.handleCheckFormHaveAction();

  if (!discardAndContinue) return;
  onFocusOnConversation(chat);
  _chatStore.activeBubbleChat(chat);
};

const onBubbleChatRemove = (chat) => {
  const conversationId = chat?.id;
  if (!conversationId) return;

  activeAndFocusOnConversationId.value = '';
  isForceActive.value = false;
  _chatStore.removeBubbleChat(chat);
};

const onBubbleChatRemoveAll = () => {
  activeAndFocusOnConversationId.value = '';
  isForceActive.value = false;

  _chatStore.removeBubbleChatAll();
};

const onOpenNoteClick = () => {
  chatPanelRef.value?.openNote();
};

const onFocusOnConversation = (chat) => {
  activeAndFocusOnConversationId.value = `${chat?.id}`;
};

const onMessageReply = (message) => {
  chatPanelRef.value?.replyMessage(message);
};

const onMessageViewFile = (file) => {
  chatPanelRef.value?.onViewFile(bubbleChatActive.value?.id, file);
};

const onMessageTaskClick = (taskCode) => {
  taskCodeGlobal.value = taskCode;
  isShowTaskDetailGlobal.value = true;

  // taskDrawerStore().pushCurrentIds({
  //   id: task?.id,
  //   code: task?.code,
  //   name: task?.name,
  //   tabType: 'DETAIL',
  // });
};

const onMessageDayoffClick = (dayoffId) => {
  dayoffIdGlobal.value = dayoffId;
  isShowDayoffDetail.value = true;
};

const onMessageEventClick = (eventId) => {
  eventIdGlobal.value.isShow = true;
  eventIdGlobal.value.id = eventId;
};

const onSetUserConversationSeenAll = (converId) => {
  return _chatStore.setSeenUserConversation(_currentOrgId, converId);
};

const elementId = `CHAT_WIDGET_${Date.now()}`;

const styleString = computed<string>(() => {
  return `max-height: 700px;
        max-width: ${isShowTaskListInChat.value ? 1000 : 500}px;
        min-height: 500px;
        min-width: 400px;
        height: 75vh;
        width: ${isShowTaskListInChat.value ? 65 : 36}vw;
        box-shadow: 0 0 18px rgba(47, 48, 48, 0.18);`;
});

const onCloseTaskListInChat = (conversationId) => {
  _chatStore.toggleShowTaskListInChat(conversationId);
};
</script>

<template>
  <div
    v-if="bubbleChatsVisible"
    :id="elementId"
    ref="chatWidgetRef"
    v-element-active="{
      isForceOpen: isForceActive,
      id: elementId,
    }"
    :class="
      !bubbleChatActive
        ? 'fixed right-3 z-20'
        : 'fixed right-3 z-20 flex flex-col items-end'
    "
    :style="{
      bottom: !bubbleChatActive ? '8.75rem' : '0.75rem',
    }"
  >
    <!--CHAT BUBBLES-->
    <div
      :class="
        !bubbleChatActive
          ? 'flex flex-col-reverse items-center justify-center space-y-2 space-y-reverse'
          : 'flex flex-row-reverse items-end space-x-2 space-x-reverse'
      "
    >
      <ChatBubbleAdd v-if="!bubbleChats?.length && hasBubbleChatAddNew" />

      <ChatBubble
        v-for="chat in bubbleChats"
        :key="chat"
        :conversation="conversationById[chat?.id]"
        :is-active="chat?.id === bubbleChatActive?.id"
        @on-bubble-chat-click="onBubbleChatClick(chat)"
        @on-bubble-chat-remove="onBubbleChatRemove(chat)"
      />

      <div class="w-6 h-6 rounded-full flex-center">
        <SynIcon
          v-if="bubbleChats?.length >= 2"
          :title="$t('COMMON_LABEL_CLOSE_ALL') || 'Close all'"
          has-action
          class="hover:bg-red-400 hover:fill-white bg-gray-100"
          custom-class="h-4 w-4 "
          name="close-bold"
          @click.stop="onBubbleChatRemoveAll"
        />
      </div>
    </div>

    <!--CHAT PANEL-->
    <div
      v-if="bubbleChatActive?.id"
      :style="styleString"
      class="rounded-2xl flex flex-col overflow-hidden mt-3"
      :class="[activeAndFocusOnConversationId ? 'bg-white' : 'bg-gray-50']"
      @click="onFocusOnConversation(bubbleChatActive)"
    >
      <ChatInfoHeader
        is-chat-widget
        :organization-id="bubbleChatActive?.organizationId"
        :conversation-id="bubbleChatActive?.id"
        :conversation="activeConverInfo"
        :contact="activeConverContact"
        @on-open-note-click="onOpenNoteClick"
        @on-message-reply="onMessageReply"
        @on-message-view-file="onMessageViewFile"
        @on-message-task-click="onMessageTaskClick"
      />
      <div
        v-permission-screen="{
          screenCode: ALL_SCREENS.COMMON.MESSAGE.WIDGET,
        }"
        class="flex-1 h-full overflow-hidden flex"
      >
        <ChatPanel
          ref="chatPanelRef"
          size="sm"
          :organization-id="bubbleChatActive?.organizationId"
          :conversation-id="bubbleChatActive?.id"
          :conversation="activeConverInfo"
          :users="allUsers"
          :contact="activeConverContact"
          :actual-unseen="activeConverInfo?.unSeen"
          :allowed-actions="chatMsgAllowedActions"
          @on-message-task-click="onMessageTaskClick"
          @on-message-dayoff-click="onMessageDayoffClick"
          @on-message-event-click="onMessageEventClick"
          @on-set-user-conversation-seen-all="onSetUserConversationSeenAll"
        />
        <!-- TASK LIST -->
        <div
          v-if="isShowTaskListInChat && taskListOwnerId"
          class="border-l border-gray-300"
          style="width: 25rem"
        >
          <TaskCardList
            :type="conversationActiveType"
            :owner-id="taskListOwnerId"
            @on-close="onCloseTaskListInChat(bubbleChatActive?.id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
