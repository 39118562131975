import { defineStore } from 'pinia';
import dayjs from 'dayjs';
import storeId from '@/base/store/storeId';
import TaskService from '@/application/services/task/TaskService';
import { arrayOrderBy, cloneDeep, uniq } from '@/ui/hooks/commonFunction';
import { getUrgencyValue } from '@/ui/hooks/taskHook';
import { TaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import { TaskLife } from '@/domain/enums/taskEnum';
import { ignoreUnicode } from '@/ui/plugins/utils';
import myProfileStore from '@/store/auth/my-profile';

const mapKeyForFilter = {
    creatorId: 'id',
    priority: 'key',
    urgency: 'id',
    domainId: 'id',
};

export default defineStore({
    id: storeId.startWorking,
    state: () => ({
        isLoading: true,
        isLoadMore: false,
        planToTreatTasksDefault: [] as any[],
        planToFinishTasksDefault: [] as any[],
        planToTreatTasks: [] as any[],
        planToFinishTasks: [] as any[],
        sortList: [] as any[],
        filterDataByKey: {},
        typeStatus: 'LIST',
        keySearch: null,
        keySearchToFinish: null,
        _taskService: TaskService.getInstance(),
    }),
    getters: {
        sortDataOrderByKey() {
            let result = {};
            const sortLength = this.sortList?.length;
            if (sortLength > 0) {
                const isShowIndex = sortLength > 1;
                result = this.sortList.reduce(
                    (previousValue, currentValue: any, index) => {
                        previousValue = {
                            ...previousValue,
                            ...{
                                [currentValue.key]: {
                                    sortIndex: isShowIndex ? index + 1 : null,
                                    orderBy: currentValue.orderBy,
                                },
                            },
                        };
                        return previousValue;
                    },
                    {}
                );
            }
            return result;
        },
    },
    actions: {
        async fetchListTask() {
            const res = await this._taskService.getTaskListPlannedToTreat();
            this.planToTreatTasks = res.map((o) => {
                o.taskName = ignoreUnicode(o.name);
                return o;
            });
            this.planToTreatTasksDefault = this.planToTreatTasks;
            this.setFilterDataOrigin('');
            // this.planToFinishTasks = this.planToTreatTasks
            //   .filter((el: any) => el.isPlannedToTreat == true)
            //   .map((u: any) => {
            //     u.isSelected = u.isPlannedToFinish;
            //     return u;
            //   });
        },
        updateListTaskToFinish() {
            this.selectedTask();
            // console.log(' 123:', this.planToFinishTasks);
            // this.setFilterDataOrigin('SELECT_TASKS_TO_FINISH');
        },
        updateTodoListAfterCreate(newTask) {
            if (newTask.assigneeId !== myProfileStore().myProfile?.id) return;

            if (newTask?.id) {
                this.planToTreatTasks = [
                    {
                        ...newTask,
                        isPlannedToTreat: newTask?.taskSchedule?.doItToday,
                        isPlannedToFinish: newTask?.taskSchedule?.finishItToday,
                        urgency: getUrgencyValue(
                            newTask.priority,
                            newTask.important
                        ),
                    },
                    ...this.planToTreatTasks,
                ];
            }

            this.planToFinishTasks = this.planToTreatTasks
                .filter((el: any) => el.isPlannedToTreat == true)
                .map((u: any) => {
                    u.isSelected = u.isPlannedToFinish;
                    return u;
                });
            this.planToTreatTasksDefault = [...this.planToTreatTasks];
        },

        selectedTask() {
            if (this.planToTreatTasksDefault.length > 0) {
                this.planToFinishTasks = this.planToTreatTasksDefault
                    .filter((el) => el.isPlannedToTreat)
                    .map((u) => {
                        u.isSelected = u.isPlannedToFinish;
                        return u;
                    });
            }
            this.planToFinishTasksDefault = this.planToFinishTasks;
        },

        // onClickSort(key, name, status) {
        //   this.genSortDataList({ key, name });
        //
        //   const iterate = this.sortList.map((o: any) => o.key),
        //     order = this.sortList.map((o: any) => o.orderBy);
        //   if (status == 'SELECT_TASKS') {
        //     this.planToTreatTasks = arrayOrderBy(
        //       this.planToTreatTasks,
        //       iterate,
        //       order
        //     );
        //   }
        //   if (status == 'SELECT_TASKS_TO_FINISH') {
        //     this.planToFinishTasks = arrayOrderBy(
        //       this.planToFinishTasks,
        //       iterate,
        //       order
        //     );
        //   }
        // },
        genSortDataList(sortObj, status) {
            const { key, name } = sortObj;
            let orderBy = 'asc';
            let sortData = [] as any;
            const currentSortData = this.sortList as any;
            const indexExisted = currentSortData.findIndex((o) => o.key == key);
            if (indexExisted > -1) {
                const currentOrderBy = currentSortData[indexExisted].orderBy;
                if (currentOrderBy == 'asc') {
                    sortData = [];
                } else {
                    orderBy = currentOrderBy == 'desc' ? 'asc' : 'desc';
                    sortData = [{ key, name, orderBy }];
                }
            } else {
                sortData = [{ key, name, orderBy: 'desc' }];
            }
            this.sortList = sortData;
            this.onSortFilteredTasks(status);
        },

        onSortFilteredTasks(status) {
            if (this.sortList?.length > 0) {
                const iterate = [...this.sortList.map((o: any) => o.key)];

                const order = [...this.sortList.map((o: any) => o.orderBy)];
                if (status == 'SELECT_TASKS') {
                    this.planToTreatTasks = arrayOrderBy(
                        this.planToTreatTasks,
                        iterate,
                        order
                    );
                }
                if (status == 'SELECT_TASKS_TO_FINISH') {
                    this.planToFinishTasks = arrayOrderBy(
                        this.planToFinishTasks,
                        iterate,
                        order
                    );
                }
            } else {
                if (status == 'SELECT_TASKS') {
                    this.planToTreatTasks = arrayOrderBy(
                        this.planToTreatTasks,
                        'urgency',
                        'desc'
                    );
                }
                if (status == 'SELECT_TASKS_TO_FINISH') {
                    this.planToFinishTasks = arrayOrderBy(
                        this.planToFinishTasks,
                        'urgency',
                        'desc'
                    );
                }
            }
        },

        setFilterDataByKey(key, filters) {
            switch (key) {
                case 'scheduleTime':
                    this.filterDataByKey[key] = filters;
                    break;

                default:
                    {
                        this.filterDataByKey[key] = this.filterDataByKey[
                            key
                        ].map((o) => {
                            const isFilter = filters.some((f) => f.id == o.id);
                            o.selected = isFilter;
                            return o;
                        });
                    }
                    break;
            }
        },

        setCreatorId(status) {
            if (
                this.planToFinishTasks.length > 0 &&
                status == 'SELECT_TASKS_TO_FINISH'
            )
                return uniq(
                    this.planToFinishTasks.map((task) => task.creatorId)
                )
                    .filter((o) => o)
                    .map((o) => {
                        return {
                            id: o,
                            selected: false,
                        };
                    });
            else
                return uniq(this.planToTreatTasks.map((task) => task.creatorId))
                    .filter((o) => o)
                    .map((o) => {
                        return {
                            id: o,
                            selected: false,
                        };
                    });
        },

        setDomainId(status) {
            if (
                this.planToFinishTasks.length > 0 &&
                status == 'SELECT_TASKS_TO_FINISH'
            )
                return uniq(
                    this.planToFinishTasks.map((task) => task.domainId)
                ).map((o) => {
                    return {
                        id: o,
                        selected: false,
                    };
                });
            else
                return uniq(
                    this.planToTreatTasks.map((task) => task.domainId)
                ).map((o) => {
                    return {
                        id: o,
                        selected: false,
                    };
                });
        },

        setFilterDataOrigin(status) {
            this.filterDataByKey = {
                urgency: [
                    {
                        id: 4,
                        selected: false,
                    },
                    {
                        id: 3,
                        selected: false,
                    },
                    {
                        id: 2,
                        selected: false,
                    },
                    {
                        id: 1,
                        selected: false,
                    },
                ],
                creatorId: this.setCreatorId(status),
                domainId: this.setDomainId(status),
            };
        },
        resetFilterDataOrigin(key) {
            switch (key) {
                case 'scheduleTime':
                    {
                        this.filterDataByKey[key] = {
                            start: null,
                            end: null,
                        };
                    }
                    break;

                default:
                    {
                        this.filterDataByKey[key] = this.filterDataByKey[
                            key
                        ].map((o) => {
                            o.selected = false;
                            return o;
                        });
                    }
                    break;
            }
        },

        resetFilterToTreatTask() {
            this.planToTreatTasks = this.planToTreatTasksDefault;
        },

        resetFilterToFinish() {
            this.planToFinishTasks = this.planToFinishTasksDefault;
        },

        onFilterTaskOnColumn(status) {
            const filterData = cloneDeep(this.filterDataByKey);
            let filteredData =
                status == 'SELECT_TASKS'
                    ? [...this.planToTreatTasksDefault]
                    : [...this.planToFinishTasksDefault];
            if (filterData) {
                Object.entries(filterData).forEach(([key, value]: any) => {
                    switch (key) {
                        case 'scheduleTime':
                            {
                                if (!value?.start && !value?.end) return;
                                filteredData = [
                                    ...(filteredData?.filter((t) => {
                                        return (
                                            (dayjs(t[key]).isSame(
                                                value?.start,
                                                'date'
                                            ) ||
                                                dayjs(t[key]).isAfter(
                                                    value?.start,
                                                    'date'
                                                )) &&
                                            (dayjs(t[key]).isSame(
                                                value?.end,
                                                'date'
                                            ) ||
                                                dayjs(t[key]).isBefore(
                                                    value?.end,
                                                    'date'
                                                ))
                                        );
                                    }) || []),
                                ];
                            }
                            break;

                        default:
                            {
                                const mapKey = mapKeyForFilter[key] || 'id';
                                const selectedFilter = (value as any).filter(
                                    (o) => o.selected
                                );
                                if (selectedFilter.length == 0) return;
                                filteredData = [
                                    ...(filteredData?.filter((t) =>
                                        selectedFilter?.some(
                                            (f) =>
                                                f[mapKey] == t[key] ||
                                                (!f[mapKey] && !t[key])
                                        )
                                    ) || []),
                                ];
                            }
                            break;
                    }
                });
            }
            if (status == 'SELECT_TASKS')
                this.planToTreatTasks = filteredData.filter((o) => o.id);
            if (status == 'SELECT_TASKS_TO_FINISH')
                this.planToFinishTasks = filteredData.filter((o) => o.id);
        },

        handleFinishTask(task, index) {
            this.planToTreatTasks.splice(index, 1);
            this.planToTreatTasksDefault = this.planToTreatTasks;
            const indexFinish = this.planToFinishTasks.findIndex(
                (el: any) => el.id == task.id
            );
            this.planToFinishTasks.splice(indexFinish, 1);
            new TaskDetailClass(task).changeState(TaskLife.Finished);
        },

        handleSelectedTask(task) {
            const indexTask = this.planToTreatTasksDefault.findIndex(
                (o) => o.id == task.id
            );
            if (indexTask == -1) return;
            this.planToTreatTasksDefault[indexTask].isPlannedToTreat =
                !task.isPlannedToTreat;
        },

        handleSelectedTaskToFinish(task) {
            const indexTask = this.planToFinishTasksDefault.findIndex(
                (o) => o.id == task.id
            );
            if (indexTask == -1) return;
            this.planToFinishTasksDefault[indexTask].isSelected =
                !task.isSelected;
        },
    },
});
