<script setup lang="ts">
// *** IMPORTS ***
import { VideoRecordingModel } from '@/domain/entities/video-recording/VideoRecordingModel';
import { onBeforeMount, onUnmounted, ref, watch } from 'vue';

// *** PROPS, EMITS ***
const props = defineProps<{
  recording: VideoRecordingModel;
}>();

// *** PRIVATE VARIABLES ***
let refreshTimer;

// *** COMPOSABLES ***

// *** REFS ***
const recordingDuration = ref();

// *** COMPUTED ***

// *** WATCHES ***
watch(
  () => props.recording,
  () => _processRecordingDuration()
);

// *** HOOKS ***
onBeforeMount(() => {
  _processRecordingDuration();
});

onUnmounted(() => {
  clearInterval(refreshTimer);
});

// *** HANDLER FUNCTIONS ***

// *** PRIVATE FUNCTIONS ***
const _processRecordingDuration = () => {
  _calculateRecordingDuration();

  clearInterval(refreshTimer);

  refreshTimer = setInterval(() => _calculateRecordingDuration(), 1000);
};

const _calculateRecordingDuration = () => {
  if (!props.recording?.recordingStartDate) return;

  if (props.recording?.recordingEndDate) {
    clearInterval(refreshTimer);

    return (recordingDuration.value =
      new Date(props.recording?.recordingEndDate).getTime() -
      new Date(props.recording.recordingStartDate).getTime());
  }

  recordingDuration.value =
    new Date().getTime() -
    new Date(props.recording.recordingStartDate).getTime();
};

// *** EXPOSES ***
</script>

<template>
  <div class="flex-center gap-2 rounded-full px-3 py-1 bg-black bg-opacity-30">
    <div class="w-3 h-3 rounded-full bg-red-500"></div>
    <div class="text-white">{{ $filters.duration(recordingDuration) }}</div>
  </div>
</template>

<style scoped></style>
