<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { isValidEmail, isValidPhoneNumber } from '@/ui/hooks/commonFunction';
// import organizationStore from '@/store/organization';
import {
  clearLocalStorage,
  getLocalStorage,
  StorageConstant,
} from '@/ui/hooks/storageHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import loginPageStore from '@/store/un-authenticate/login-store';
import GetCode from './GetCode.vue';
import SettingLanguageSmall from '@/ui/components/setting/SettingLanguageSmall.vue';
import {
  AuthenticateType,
  ErrorNotificationLogin,
} from '@/ui/common/constants/constant';
import {
  destroyGoogleCaptcha,
  initGoogleCaptcha,
} from '@/ui/components/layouts/logic/init-google-capcha';
import {
  AuthenticateEmailCode,
  AuthenticatePhoneCode,
  AuthenticateUserPassword,
} from '@/application/entities/un-authenticate/LoginEntitiesClass';
// Nen chuyen sang man hinh moi, tranh conflic goi co token
import { AuthenticateSelectOrganization } from '@/application/services/authenticate/authenticate-service';
import { AuthenticateCaseEnum } from '@/application/entities/authenticate/AuthenticateEnum';
import atomFormInputComposables from '@/ui/common/atoms/SynInput/atom-form-input-composables';
import LoginMethodTabs from '@/ui/modules/login/LoginMethodTabs.vue';
import { CountryCode } from 'libphonenumber-js';
import SynExplain from '@/ui/common/molecules/SynModal/SynExplain.vue';
import { useRouter, useRoute } from 'vue-router';
import SynAvatar from '@/ui/common/molecules/SynAvatar/SynAvatar.vue';
import {
  EPlanId,
  getUiByPlanSubState,
} from '@/application/constants/plan.const';
import { EPlanState } from '@/application/types/plan.types';
import AttachOrganizationModal from '@/ui/modules/organization/setup/AttachOrganizationModal.vue';

const router = useRouter();
const route = useRoute();
// const _organizationStore = organizationStore();
const _loginPageStore = loginPageStore();

const showPWD = ref(false);
const isShowModalError = ref(false);
const isLoadingRefresh = ref(false);

// const linkWebsite = computed(() => {
//   const location = getLocalStorage(StorageConstant.LOCATION);
//   return location == 'EU' ? 'http://tictop.eu' : 'http://fiine.vn';
// });
// const linkWebsiteLabel = computed(() => {
//   return linkWebsite.value?.split('://').pop();
// });

const linkWebsite = ref('http://fiine.vn');
const linkWebsiteLabel = ref('fiine.vn') as any;

const initLocation = () => {
  const location = getLocalStorage(StorageConstant.LOCATION);
  linkWebsite.value = location == 'EU' ? 'http://tictop.eu' : 'http://fiine.vn';
  linkWebsiteLabel.value = linkWebsite.value?.split('://').pop();
};

initLocation();

onMounted(() => {
  // clearLocalStorage({ isSwitchingOrg: false });
  _loginPageStore.phone ? (inputPhone.value = _loginPageStore.phone) : '';

  window.addEventListener('storage', async (event) => {
    if (event.key == StorageConstant.HAS_LOGIN && event.newValue == 'true') {
      location.reload();
    }
  });

  if (
    router?.currentRoute.value?.query?.type &&
    router?.currentRoute.value?.query?.value_login
  ) {
    handleCheckLoginFromForgotPwd(
      router?.currentRoute.value?.query?.type,
      router?.currentRoute.value?.query?.value_login
    );
  }
});

const handleCheckLoginFromForgotPwd = (type, value) => {
  // console.log('handleCheckLoginFromForgotPwd', type, value);
  currentLoginMethod.value = type;
  statusLogin.value.FORM_INFO.active = false;
  statusLogin.value.FORM_PASSWORD.active = false;
  statusLogin.value.FORM_GET_CODE.active = true;
  if (type == 'EMAIL') inputEmail.value = value;
  else inputPhone.value = value;
  onSendCode(false);
};

onUnmounted(() => {
  destroyGoogleCaptcha();
});

const statusLogin = ref({
  FORM_INFO: {
    active: true,
    labelBtn: 'COMMON_LABEL_NEXT',
  },
  FORM_PASSWORD: {
    active: false,
    labelBtn: 'COMMON_MODULE_LOGIN',
  },
  FORM_GET_CODE: {
    active: false,
    labelBtn: '',
  },
});
const errorPassword = ref('');
const errorCode = ref('');
const isBlockLoginByCode = ref(false);
const isBlockSendPhoneCode = ref(false);

const inputPassword = ref('' as string);

const onInputPassword = () => {
  errorPassword.value = '';
};

let lastCheckingEmail;

const inputEmail = ref('' as string);

const onInputEmail = (value) => {
  errorCode.value = '';
  onResetValidationEmail();

  if (!value || !isValidEmail(value)) return;

  isBlockLoginByCode.value = false;
  isBlockSendPhoneCode.value = false;

  lastCheckingEmail = value;

  onValidationEmail(value);
};

const onBlurEmail = () => {
  if (!inputEmail.value || validationResultEmail.value?.isValid) return;

  onValidationEmail(inputEmail.value);
};

// Email
const {
  isTyping: isTypingEmail,
  isChecking: isCheckingEmail,
  validationResult: validationResultEmail,
  onValidation: onValidationEmail,
  reset: onResetValidationEmail,
} = atomFormInputComposables([
  (value) => {
    return isValidEmail(value) ? '' : 'COMMON_INVALID_EMAIL';
  },
  async (value) => {
    const resCheckExist = await _loginPageStore.checkEmail(value);

    const isValid =
      resCheckExist?.value === lastCheckingEmail && resCheckExist?.existed;

    return isValid ? '' : 'COMMON_LABEL_REQUIRE_EMAIL_NOT_EXISTED';
  },
]);

const inputPhone = ref('' as string);

// Phone
const {
  isTyping: isTypingPhone,
  isChecking: isCheckingPhone,
  validationResult: validationResultPhone,
  onValidation: onValidationPhone,
  reset: onResetValidationPhone,
} = atomFormInputComposables([
  () => {
    return resultPhoneObject.value?.isValid
      ? ''
      : 'COMMON_INVALID_PHONE_NUMBER';
  },
  (value) => {
    const isValid = isValidPhoneNumber(value);
    return isValid ? '' : 'COMMON_INVALID_PHONE_NUMBER';
  },
  (value) => {
    const isValid =
      value.startsWith('+') &&
      ['+33', '+84', '+1', '+81', '+82'].includes(value?.slice(0, 3));
    return isValid ? '' : 'COMMON_INVALID_PHONE_NUMBER';
  },
  async (value) => {
    const resCheckExistPhone = await _loginPageStore.checkPhone(value);
    return !resCheckExistPhone.existed
      ? 'COMMON_LABEL_REQUIRE_PHONE_NO_REGISTERED'
      : '';
  },
]);

const currentLoginMethod = ref<'PHONE' | 'EMAIL'>('PHONE');

const onClickSignUp = () => {
  _loginPageStore.phone = inputPhone.value;
};

const onRefreshPage = async () => {
  isLoadingRefresh.value = true;
  clearLocalStorage({ isSwitchingOrg: false });
  await window.location.reload();
  isLoadingRefresh.value = false;
};

const onPrevious = () => {
  errorCode.value = '';
  if (statusLogin.value['FORM_PASSWORD'].active) {
    statusLogin.value['FORM_PASSWORD'].active = false;
    statusLogin.value['FORM_INFO'].active = true;
  } else if (statusLogin.value['FORM_GET_CODE'].active) {
    statusLogin.value['FORM_GET_CODE'].active = false;
    statusLogin.value['FORM_INFO'].active = true;
  }
  resetSelectOrganization();
};

const resetSelectOrganization = () => {
  isSelectOrganization.value = false;
  myOrganizations.value = [];
  selectedOrgId.value = 0;
};

const onChangeMethodLogin = async () => {
  errorCode.value = '';
  inputPassword.value = '';
  errorPassword.value = '';
  statusLogin.value['FORM_PASSWORD'].active = true;
  statusLogin.value['FORM_GET_CODE'].active = false;
};

// HANDLER FORM PHONE/EMAIL
const hasPWD = ref(false);
const onCheckPassword = async () => {
  if (isDisabledCheckExistPasswordButton.value) return;
  // console.log(
  //   '🚀 Hyrin ~ onCheckPassword ~ isDisabledCheckExistPasswordButton.value',
  //   isDisabledCheckExistPasswordButton.value
  // );

  inputPassword.value = '';
  isSubmitLogin.value = true;

  if (currentLoginMethod.value == 'EMAIL') {
    try {
      const resCheckHas = await _loginPageStore.checkUserHasPasswordByEmail(
        inputEmail.value
      );
      if (resCheckHas) {
        hasPWD.value = true;
        statusLogin.value['FORM_INFO'].active = false;
        statusLogin.value['FORM_PASSWORD'].active = true;
        isSubmitLogin.value = false;
      } else {
        await onSendCode(false);
        isSubmitLogin.value = false;
      }
    } catch (error) {
      isSubmitLogin.value = false;
    }
  } else if (currentLoginMethod.value == 'PHONE') {
    try {
      const res = await _loginPageStore.checkUserHasPasswordByPhone(
        inputPhone.value
      );
      if (res) {
        hasPWD.value = true;
        statusLogin.value['FORM_INFO'].active = false;
        statusLogin.value['FORM_PASSWORD'].active = true;
        isSubmitLogin.value = false;
      } else {
        await onSendCode(false);

        isSubmitLogin.value = false;
      }
    } catch (error) {
      isSubmitLogin.value = false;
    }
  }
};

const resetCaptcha = async (type) => {
  if (type == 'send') {
    await onSendCode(false);
  } else {
    await onResendCode();
  }
};

const isGetCode = ref(false);
const sessionInfo = ref('');
const currentLanguage = ref('');
const isSubmitLogin = ref(false);

const onSendCode = async (_isGetCode) => {
  if (isBlockLoginByCode.value || isBlockSendPhoneCode.value) return;

  isSubmitLogin.value = true;
  errorCode.value = '';
  try {
    if (currentLoginMethod.value == 'PHONE') {
      const capchaTokenText = await initGoogleCaptcha(
        'g-recaptcha_login',
        'login-form-capcha-container'
      );

      if (!capchaTokenText) return;

      const result = await _loginPageStore.sendVerificationCodeByPhoneNumber({
        phoneNumber: inputPhone.value,
        type: 'sign_in',
        captchaToken: capchaTokenText,
        locale: currentLanguage.value || 'vi',
      });
      sessionInfo.value = result.sessionInfo;
    } else {
      await _loginPageStore.sendVerificationCodeByEmail({
        email: inputEmail.value,
        type: 'sign_in',
        locale: currentLanguage.value || 'vi',
      });
    }
    statusLogin.value['FORM_INFO'].active = false;
    statusLogin.value['FORM_PASSWORD'].active = false;
    statusLogin.value['FORM_GET_CODE'].active = true;
    isGetCode.value = _isGetCode;
    isSubmitLogin.value = false;
  } catch (error: any) {
    if (error?.error?.message == ErrorNotificationLogin.CAPTCHA_CHECK_FAILED) {
      await resetCaptcha('send');
    } else if (
      error?.error?.message == ErrorNotificationLogin.SEND_VERIFICATION_FAILED
    ) {
      parserErrorMessage(error);
      isShowModalError.value = true;
      isSubmitLogin.value = false;
    } else {
      isSubmitLogin.value = false;
      parserErrorMessage(error);
    }
  }
};

const onResendCode = async () => {
  if (isBlockLoginByCode.value || isBlockSendPhoneCode.value) return;
  isSubmitLogin.value = true;
  errorCode.value = '';
  const res = ref();
  try {
    if (currentLoginMethod.value == 'EMAIL') {
      res.value = await _loginPageStore.reSendVerificationCodeByEmail({
        email: inputEmail.value,
        type: 'sign_in',
        locale: currentLanguage.value || 'vi',
      });
      isSubmitLogin.value = false;
    } else {
      const capchaTokenText = await initGoogleCaptcha(
        'g-recaptcha_login',
        'login-form-capcha-container'
      );
      if (!capchaTokenText) return;

      res.value = await _loginPageStore.reSendVerificationCodeByPhoneNumber({
        phoneNumber: inputPhone.value,
        captchaToken: capchaTokenText,
        type: 'sign_in',
        locale: currentLanguage.value || 'vi',
      });
      sessionInfo.value = res.value?.result.sessionInfo;
      isSubmitLogin.value = false;
    }
  } catch (response: any) {
    switch (response.error.message) {
      case ErrorNotificationLogin.CAPTCHA_CHECK_FAILED:
        await resetCaptcha('resend');
        break;
      case ErrorNotificationLogin.SEND_VERIFICATION_FAILED:
        parserErrorMessage(response);
        isShowModalError.value = true;
        isSubmitLogin.value = false;
        break;
      default:
        isSubmitLogin.value = false;
        parserErrorMessage(response);
        break;
    }
  }
};

const parserErrorMessage = (response) => {
  const responseMsg = response.error.message;
  isBlockLoginByCode.value =
    responseMsg == ErrorNotificationLogin.LOGIN_BY_CODE_BLOCKED;
  isBlockSendPhoneCode.value =
    responseMsg == ErrorNotificationLogin.SEND_PHONE_CODE_BLOCKED;
  errorCode.value = translate(ErrorNotificationLogin[responseMsg]);
  isSubmitLogin.value = false;
};

const onSignInWithPassword = async () => {
  isSubmitLogin.value = true;
  let acountName = inputPhone.value;
  if (currentLoginMethod.value == 'EMAIL') acountName = inputEmail.value;
  try {
    const authenUserPass = new AuthenticateUserPassword(
      acountName,
      inputPassword.value,
      AuthenticateType.EmailPhonePassword,
      acountName,
      route?.query?.inviteCode?.toString()
    );
    const res = await authenUserPass.login();

    isSubmitLogin.value = false;
    handleSelectOrganization(res);
  } catch (response: any) {
    errorPassword.value = ErrorNotificationLogin[response.error.message];
    isSubmitLogin.value = false;
  }
};

const isLoadingGetCode = ref(false);

const onLoginWithCodePhone = async (code) => {
  errorCode.value = '';
  isLoadingGetCode.value = true;
  try {
    const authenPhoneCode = new AuthenticatePhoneCode(
      inputPhone.value,
      code,
      sessionInfo.value,
      route?.query?.inviteCode?.toString()
    );

    const res = await authenPhoneCode.login();

    isLoadingGetCode.value = false;

    handleSelectOrganization(res);
  } catch (response) {
    parserErrorMessage(response);
    isLoadingGetCode.value = false;
  }
};

const onLoginWithCodeEmail = async (code) => {
  try {
    isLoadingGetCode.value = true;
    const authenEmailCodeClass = new AuthenticateEmailCode(
      inputEmail.value,
      code,
      route?.query?.inviteCode?.toString()
    );
    const res = await authenEmailCodeClass.login();
    isLoadingGetCode.value = false;

    handleSelectOrganization(res);
  } catch (response) {
    parserErrorMessage(response);
    isLoadingGetCode.value = false;
  }
};

const isSelectOrganization = ref(false);
const myOrganizations = ref([] as any[]);

const handleSelectOrganization = async (result) => {
  if (
    !result ||
    result.caseKey !== AuthenticateCaseEnum.SelectOrganizationRequired
  )
    return;

  isSelectOrganization.value = true;
  myOrganizations.value = result?.organizations;
};

const resultPhoneObject = ref<{
  country?: CountryCode;
  countryCallingCode: string;
  nationalNumber: string;
  number: string;
  isValid: boolean;
  formattedNumber: string;
}>({
  countryCallingCode: '',
  nationalNumber: '',
  number: '',
  isValid: true,
  formattedNumber: '',
});

const onBlurPhone = () => {
  if (!inputPhone.value || validationResultPhone.value?.isValid) return;

  onValidationPhone(inputPhone.value);
};

const onChangePhone = (phoneObj: {
  country?: CountryCode;
  countryCallingCode: string;
  nationalNumber: string;
  number: string;
  isValid: boolean;
  formattedNumber: string;
}) => {
  onResetValidationPhone();
  resultPhoneObject.value = phoneObj;
  if (!phoneObj || !phoneObj.number) {
    inputPhone.value = '';
    return;
  }

  inputPhone.value = phoneObj.number;

  isBlockLoginByCode.value = false;
  isBlockSendPhoneCode.value = false;

  if (phoneObj?.number?.length == 12) onValidationPhone(phoneObj.number);

  const local = phoneObj.countryCallingCode?.toLowerCase();
  currentLanguage.value = local == 'vn' ? 'vi' : local;
};

const selectedOrgId = ref(0 as number);
const onSelectOrganization = async (org) => {
  selectedOrgId.value = org.id;

  onLoginAfterSelectOrganization();
};

const onLoginAfterSelectOrganization = async () => {
  if (!selectedOrgId.value) return;
  const authenSelectOrganization = new AuthenticateSelectOrganization(
    selectedOrgId.value
  );
  isSubmitLogin.value = true;
  const res = await authenSelectOrganization.login();
  handleSelectOrganization(res);
  isSubmitLogin.value = false;
};

const isDisabledCheckExistPasswordButton = computed(
  () =>
    (currentLoginMethod.value == 'EMAIL' &&
      (isTypingEmail.value || !validationResultEmail.value.isValid)) ||
    (currentLoginMethod.value == 'PHONE' &&
      (isTypingPhone.value || !validationResultPhone.value.isValid))
);

const isOpenAttachOrg = ref(false);

const onAttachOrgSuccess = (code, nextAction) => {
  console.log('🚀 Tictop ~ code:', code);

  isOpenAttachOrg.value = false;
  router.push({
    name: nextAction,
    query: {
      inviteCode: code,
    },
  });
};
</script>

<template>
  <div class="flex-center flex-col h-full w-full">
    <!-- HEADER -->
    <section class="top-0 px-4 py-4 flex justify-between w-full h-max">
      <div class="cursor-pointer flex-center">
        <div
          v-if="!statusLogin['FORM_INFO'].active"
          class="w-8 h-8 rounded-full hover:bg-current-50 flex-center"
        >
          <SynIcon
            name="back"
            class="fill-current text-current cursor-pointer"
            custom-class="w-5 h-5"
            @click="onPrevious"
          />
        </div>
        <div v-else class="flex-center space-x-2 ml-8 cursor-pointer">
          <div class="w-3 h-3 flex-center">
            <SynIcon
              name="global"
              custom-class="fill-current text-current w-3 h-3"
            />
          </div>
          <a
            class="text-sm hover:text-current font-medium"
            :href="linkWebsite"
            target="_blank"
          >
            {{ `www.${linkWebsiteLabel}` }}
          </a>
        </div>
      </div>
      <SettingLanguageSmall />
    </section>

    <!-- BODY -->
    <section
      id="login-form-capcha-container"
      class="w-full flex-center flex-col flex-1 min-h-0"
    >
      <div class="flex flex-col mb-4">
        <syn-logo class-logo="h-14" class-label="w-32" />
      </div>
      <!-- SELECT ORG -->
      <section
        v-if="isSelectOrganization"
        class="flex-1 min-h-0 flex-center flex-col space-y-2 p-4 w-2/3"
      >
        <div class="font-roboto font-thin text-3xl text-center pb-4">
          {{ $t('ORGANIZATION_LABEL_SELECT_ORGANIZATION') }}:
        </div>
        <div
          class="
            flex-1
            overflow-auto
            small-scrollbar
            flex flex-col
            space-y-2
            w-full
            p-2
          "
          style="max-height: 20rem; height: max-content"
        >
          <button
            v-for="(org, index) in myOrganizations"
            :key="index"
            class="
              rounded-md
              relative
              grid grid-cols-9
              hover:bg-current-50 hover:bg-opacity-100
              bg-current-50 bg-opacity-40
              cursor-pointer
              p-2
              w-full
              space-x-3
            "
            :disabled="isSubmitLogin"
            :class="[
              selectedOrgId === org.id
                ? 'border border-current ring ring-current ring-opacity-30 hover:bg-current-50 hover:text-gray-500 text-current-400'
                : 'text-gray-500',
            ]"
            :style="{
              cursor: isSubmitLogin ? 'wait' : '',
            }"
            @click="onSelectOrganization(org)"
          >
            <div class="flex-center col-span-1">
              <SynAvatar
                custom-class="w-10 h-10"
                :has-name="false"
                :src="org?.logoUrl"
                :name="org.name"
              />
            </div>
            <div class="flex-1 flex-col space-y-0.5 col-span-8">
              <div class="flex items-center justify-between w-full">
                <span class="font-medium truncate">
                  {{ org.name }}
                </span>

                <AtomPremiumLabel
                  v-if="
                    org?.planId == EPlanId.PRO || org?.planId == EPlanId.VIP
                  "
                  :label="org?.planId"
                />
                <SynTag
                  v-else
                  size="small"
                  class="bg-current-50 text-current-500 rounded-full"
                >
                  <span class="px-2">
                    {{ org?.planId }}
                  </span>
                </SynTag>
              </div>
              <div
                v-if="org?.typeName"
                class="w-full flex text-xs text-gray-400"
              >
                {{ org?.typeName }}
              </div>
              <div
                v-if="
                  org?.planId !== EPlanId.FREE && org?.planId !== EPlanId.BASIC
                "
                class="text-left text-xs italic truncate"
                :class="`${getUiByPlanSubState(org)?.style?.colorText}`"
              >
                {{
                  $t(getUiByPlanSubState(org)?.message?.shortContent, {
                    remainDays: org?.planRemainDays,
                    planId: org?.planId,
                  })
                }}
              </div>
              <div
                v-else-if="org?.stateCode == EPlanState.INIT"
                class="text-left text-xs italic truncate text-current-500"
              >
                {{ $t('PAYMENT_LABEL_SELECT_PLAN') }}
              </div>
            </div>
          </button>
        </div>
        <!-- <div class="text-center w-full pt-4">
          <SynButton
            class="w-full m-auto"
            :is-loading="isSubmitLogin"
            :disabled="!selectedOrgId"
            :label="$t(statusLogin['FORM_INFO'].labelBtn)"
            @click="onLoginAfterSelectOrganization"
          />
        </div> -->
      </section>
      <!-- LOGIN -->
      <section v-else class="w-full md:w-2/3 max-w-[28rem]">
        <div
          class="
            flex
            content-center
            text-center
            justify-center
            w-full
            my-1
            font-roboto font-thin
            text-3xl
            mb-8
          "
        >
          {{
            !isGetCode
              ? $t('LOGIN_LABEL_HEADER')
              : $t('LOGIN_LABEL_HEADER_VERIFY_CODE')
          }}
        </div>

        <!-- FORM LOGIN -->
        <section v-if="statusLogin['FORM_INFO'].active">
          <LoginMethodTabs v-model="currentLoginMethod" />

          <div
            class="
              relative
              flex flex-col
              min-w-0
              break-words
              w-full
              mb-6
              rounded-lg
              border-0
              lg:items-center
            "
          >
            <div class="flex-auto py-2 pt-0 w-full">
              <div v-show="currentLoginMethod == 'EMAIL'">
                <!-- EMAIL -->
                <div class="relative w-full mb-3 mt-3">
                  <SynFormInput
                    :is-checking="isCheckingEmail"
                    :is-valid="validationResultEmail.isValid"
                    :error-message="
                      errorCode ? errorCode : validationResultEmail.message
                    "
                  >
                    <div
                      class="
                        relative
                        text-gray-600
                        focus-within:text-gray-800
                        w-full
                        rounded-md
                        py-0.4
                      "
                    >
                      <VigInputEmail
                        id="login-email"
                        v-model="inputEmail"
                        class="
                          bg-white
                          w-full
                          h-10
                          focus:outline-none
                          pr-6
                          pl-12
                          text-sm
                          rounded-md
                          vig-input
                          placeholder-gray-500 placeholder-opacity-75
                        "
                        autofocus
                        :placeholder="$t('SIGN_UP_LABEL_HEADER_EMAIL')"
                        @keydown.enter="onCheckPassword"
                        @update:model-value="onInputEmail"
                        @blur="onBlurEmail"
                      />
                      <div
                        class="
                          absolute
                          inset-y-0
                          left-4
                          flex
                          items-center
                          h-full
                          focus:outline-none focus:shadow-outline
                        "
                      >
                        <SynIcon
                          name="Email"
                          custom-class="w-4 h-4 fill-gray-500 text-gray-500"
                        />
                      </div>
                    </div>
                  </SynFormInput>
                </div>
              </div>
              <div v-show="currentLoginMethod == 'PHONE'">
                <!-- PHONE -->
                <div class="relative w-full mb-3 mt-3">
                  <SynFormInput
                    :is-checking="isCheckingPhone"
                    :is-valid="validationResultPhone.isValid"
                    :error-message="
                      errorCode ? errorCode : validationResultPhone.message
                    "
                  >
                    <AtomPhoneInput
                      id="login-phone"
                      autofocus
                      :value="inputPhone"
                      :input-options="{
                        placeholder: $t('COMMON_LABEL_MOBILE_PHONE'),
                      }"
                      @change="onChangePhone"
                      @blur="onBlurPhone"
                      @enter="onCheckPassword"
                    />
                  </SynFormInput>
                </div>
              </div>
            </div>
            <div class="text-center w-full pt-4">
              <SynButton
                class="w-full m-auto"
                :is-loading="isSubmitLogin"
                :disabled="isDisabledCheckExistPasswordButton"
                :label="$t(statusLogin['FORM_INFO'].labelBtn)"
                @click="onCheckPassword"
              />
              <div class="pt-4 text-base flex-center gap-2">
                <span class="text-gray-500 text-sm">{{
                  $t('COMMON_LABEL_OR')
                }}</span>
                <router-link :to="{ name: 'Register' }">
                  <span
                    class="
                      font-medium
                      text-current-600
                      cursor-pointer
                      hover:text-current-200
                      text-sm
                    "
                    @click="onClickSignUp"
                  >
                    {{ $t('COMMON_LABEL_SIGN_UP') }}
                  </span>
                </router-link>
                <span>-</span>
                <span
                  class="
                    font-medium
                    text-current-600
                    cursor-pointer
                    hover:text-current-200
                    text-sm
                  "
                  @click="isOpenAttachOrg = true"
                >
                  {{ $t('COMMON_LABEL_JOIN_THE_ORGANIZATION') }}
                </span>
              </div>
            </div>
          </div>
        </section>

        <!-- PASSWORD -->
        <section v-if="statusLogin['FORM_PASSWORD'].active">
          <div
            v-if="inputEmail?.length > 0 || inputPhone?.length > 0"
            class="flex-center pb-3 text-sm text-gray-400"
          >
            <span v-if="currentLoginMethod === 'EMAIL'">{{
              `${$t('LOGIN_FORM_EMAIL')}: ${inputEmail}`
            }}</span>
            <span v-if="currentLoginMethod === 'PHONE'">{{
              `${$t('COMMON_LABEL_PHONE')}: ${inputPhone}`
            }}</span>
          </div>
          <div class="relative w-full mb-3">
            <SynFormInput :error-message="errorPassword">
              <SynInput
                id="login-password"
                v-model="inputPassword"
                class="w-full"
                autofocus
                icon-prefix="lock"
                :icon-right="showPWD ? 'eye' : 'eye-close'"
                :input-type="showPWD ? 'text' : 'password'"
                :placeholder="$t('LOGIN_FORM_PASSWORD')"
                :error-message="errorPassword"
                @on-action="showPWD = !showPWD"
                @enter="onSignInWithPassword"
                @update:model-value="onInputPassword"
              />
            </SynFormInput>
          </div>

          <div class="w-full flex justify-between">
            <div class="flex-center space-x-2">
              <small
                :class="
                  isBlockLoginByCode || isBlockSendPhoneCode
                    ? 'cursor-not-allowed text-gray-400'
                    : 'text-current cursor-pointer hover:text-current-700'
                "
                @click="onSendCode(false)"
              >
                {{ $t('COMMON_LABEL_LOGIN_WITH_CODE') }}
              </small>
              <SynExplain
                :label-answer="$t('EXPLAIN_LOGIN_CODE_LABEL')"
                :min-width="'w-56 text-sm text-center'"
              />
            </div>
            <router-link
              :to="{
                name: 'ForgotPassword',
                query: { email: inputEmail, phone: inputPhone },
              }"
            >
              <small class="text-current cursor-pointer hover:text-current-700">
                {{ $t('COMMON_LABEL_FOGOT_PASSWORD') }}
              </small>
            </router-link>
          </div>
          <div class="w-full mt-4">
            <SynButton
              class="w-full m-auto"
              :is-loading="isSubmitLogin"
              :disabled="!inputPassword || inputPassword.length < 6"
              :label="$t(statusLogin['FORM_PASSWORD'].labelBtn)"
              @click="onSignInWithPassword"
            />
            <small
              v-if="isBlockLoginByCode || isBlockSendPhoneCode"
              class="text-red-900"
            >
              {{ errorCode }}
            </small>
          </div>
        </section>

        <!-- CODE -->
        <section v-if="statusLogin['FORM_GET_CODE'].active" class="w-full">
          <GetCode
            type="login"
            :code-type="currentLoginMethod"
            :phone="inputPhone"
            :email="inputEmail"
            :has-password="hasPWD"
            :error-code="errorCode"
            :block-code-login="isBlockLoginByCode"
            :block-sending-code="isBlockSendPhoneCode"
            :is-loading="isLoadingGetCode"
            :is-loading-resend="isSubmitLogin"
            @change-login="onChangeMethodLogin"
            @resend-code="onResendCode"
            @on-verify="
              (value) =>
                currentLoginMethod === 'PHONE'
                  ? onLoginWithCodePhone(value)
                  : onLoginWithCodeEmail(value)
            "
          ></GetCode>
        </section>
      </section>
    </section>

    <section
      v-if="statusLogin['FORM_INFO'].active"
      class="flex justify-center bottom-0 py-4 w-full"
    >
      <SynLinkDownload class="font-medium" />
    </section>
  </div>

  <SynQuestionModal
    :visible="isShowModalError"
    :show-confirm-btn="false"
    :delete-label="$t('COMMON_LABEL_LOGIN_AGAIN')"
    :is-loading="isLoadingRefresh"
    header-text="Oops!!"
    show-delete-btn
    @cancel="isShowModalError = false"
    @on-confirm="onRefreshPage"
  >
    <template #content>
      <SynAnimation name="oops" stype="width: 200px;" :loop="false" />
      <div class="text-center text-red-700">
        {{ errorCode }}
      </div>
    </template>
  </SynQuestionModal>
  <AttachOrganizationModal
    v-if="isOpenAttachOrg"
    is-not-authenticated
    @on-success="onAttachOrgSuccess"
    @on-cancel="isOpenAttachOrg = false"
  />
</template>
