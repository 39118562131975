// Generated by https://quicktype.io

import { v4 as uuidv4 } from 'uuid';
import WorkflowRepository from '@/application/repositories/WorkflowRepository';
import {
    ITaskTodo,
    TaskApprovalStatus,
    ITaskApprover,
} from '@/application/types/task/task.types';
import { EWorkflowTaskStepStatus } from '@/application/types/workflow/workflow.types';
import { IWorkflowStep } from '../workflow/WorkflowStepEntity';
import { uploadFilesToAws } from '@/application/services/attachment/AttachmentClass';
import { uploadNotesToAws } from '@/application/services/attachment/NoteClass';
import { ModuleSourceFileEnum } from '@/ui/plugins/awss3/AwsS3Type';

// Generated by https://quicktype.io

export interface ITaskWorkflowStep extends IWorkflowStep {
    taskId: number;
    taskCode: string;
    taskWorkflowId: number;
    status: EWorkflowTaskStepStatus;
    relatedTask?: any;
    collaborators?: { userId: number; roleId: string }[];
}

export default class TaskWorkflowStepEntity implements ITaskWorkflowStep {
    id: string;
    taskId: number;
    taskCode: string;
    code: string;
    isStart: boolean;
    taskWorkflowId: number;
    name: string;
    status: EWorkflowTaskStepStatus;
    stepOrder: number;
    typeId?: number;
    assigneeId?: number;
    groupId?: number;
    domainId?: number;
    departmentId?: number;
    startTime?: string;
    scheduleByHour?: boolean;
    scheduleTime?: string;
    scheduleTimezone?: string;
    scheduleDayOption: number;
    priority: number;
    important: boolean;
    taskDescription: string;
    files: any[];
    notes: any[];
    todoLists?: ITaskTodo[];
    collaborators?: { userId: number; roleId: string }[];

    totalFiles?: number;
    totalNotes?: number;
    totalTodoLists?: number;
    updateFields?: string[];

    level: number;
    levelIndex: number;
    previousStepIds: string[];
    nextStepIds: string[];
    relatedTask?: any;

    // Approval
    approvalEnabled?: boolean;
    approvalStatus?: TaskApprovalStatus;
    approverApprovalDate?: any;
    approverApprovalStatus?: number;
    approverId?: number;
    approvers?: ITaskApprover[];
    nextSteps: {
        id: string;
        condition: {
            id: string;
            name: string;
            description: string;
            index: number;
        };
    }[];

    constructor(data) {
        this.id = data?.id || uuidv4();
        this.taskId = data?.taskId;
        this.code = data?.code;
        this.isStart = !!data?.isStart;
        this.taskCode = data?.taskCode || '';
        this.taskWorkflowId = data?.taskWorkflowId;
        this.stepOrder = data?.stepOrder;
        this.name = data?.name;
        this.status = data?.status;
        this.typeId = data?.typeId;
        this.assigneeId = data?.assigneeId;
        this.groupId = data?.groupId;
        this.domainId = data?.domainId;
        this.startTime = data?.startTime;
        this.scheduleByHour = !!data?.scheduleByHour;
        this.scheduleTime = data?.scheduleTime;
        this.scheduleTimezone = data?.scheduleTimezone;
        this.scheduleDayOption = data?.scheduleDayOption;
        this.priority = data?.priority;
        this.important = data?.important;
        this.taskDescription = data?.taskDescription;
        this.files = data?.files;
        this.notes = data?.notes;
        this.todoLists = data?.todoLists || [];
        this.collaborators = data?.collaborators || [];

        this.totalFiles = data?.totalFiles || data?.files?.length;
        this.totalNotes = data?.totalNotes || data?.notes?.length;
        this.totalTodoLists = data?.totalTodoLists || data?.todoLists?.length;
        this.updateFields = data?.updateFields;

        this.level = data?.level;
        this.levelIndex = data?.levelIndex;
        this.previousStepIds = data?.previousStepIds;
        this.nextStepIds = data?.nextStepIds;
        this.relatedTask = data?.relatedTask;

        this.approvalEnabled = data?.approvalEnabled;
        this.approvalStatus = data?.approvalStatus;
        this.approverApprovalDate = data?.approverApprovalDate;
        this.approverApprovalStatus = data?.approverApprovalStatus;
        this.approverId = data?.approverId;
        this.approvers = data?.approvers;
        this.nextSteps = data?.nextSteps;
    }

    async updateTaskWorkflowStep() {
        await Promise.all([this.uploadFiles(), this.uploadNotes()]);
        return WorkflowRepository.getInstance().updateTaskWorkflowStep(
            this.taskWorkflowId,
            this.id,
            this
        );
        // return WorkflowRepository.getInstance().updateTaskStepData(
        //     this.id,
        //     this.taskProperty
        // );
    }

    completeTaskWorkflowStep() {
        return WorkflowRepository.getInstance().completeTaskWorkflowStep(
            this.taskId,
            this.id
        );
    }
    reopenTaskWorkflowStep() {
        return WorkflowRepository.getInstance().reopenTaskWorkflowStep(
            this.taskId,
            this.id
        );
    }

    async uploadFiles(isClone?: boolean) {
        this.files = await uploadFilesToAws(
            ModuleSourceFileEnum.task,
            this.files,
            {
                isClone: !!isClone,
            }
        );
    }
    async uploadNotes(isClone?: boolean) {
        this.notes = await uploadNotesToAws(this.notes, {
            isClone: !!isClone,
        });
    }

    saveAttachment(isClone?: boolean) {
        return Promise.all([
            this.uploadFiles(isClone),
            this.uploadNotes(isClone),
        ]);
    }
}
