<script setup lang="ts">
import { ref } from 'vue';
import CalendarSyncSettingsModal from '@/ui/modules/calendar/calendar-sync/CalendarSyncSettingsModal.vue';
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';

const isOpenSyncConfigModal = ref<boolean>();
const syncSettingsEdit = ref<any>();

const onSyncConfigClick = () => {
  isOpenSyncConfigModal.value = true;
};
</script>

<template>
  <VigDropdown>
    <template #dropdown-toggle>
      <VigButton ghost color="gray" icon="DotsVertical" />
    </template>
    <template #dropdown-menu>
      <VigButton
        v-permission-function="{
          functionCode: ALL_FUNCTIONS.CALENDAR.SYNC,
          needToShowPremiumIcon: true,
          onContinue: () => {
            onSyncConfigClick();
          },
        }"
        ghost
        color="gray"
        class="dropdown-item"
        @click="onSyncConfigClick"
      >
        <SynIcon name="GoogleCalendar" class="mr-2" />
        <span class="text-sm font-normal pr-3">{{
          $t('CALENDAR_SYNC_LABEL')
        }}</span>
      </VigButton>
    </template>
  </VigDropdown>

  <CalendarSyncSettingsModal
    v-if="isOpenSyncConfigModal"
    :sync-settings="syncSettingsEdit"
    @on-close="isOpenSyncConfigModal = false"
  />
</template>

<style scoped></style>
