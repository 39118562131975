import { createApp, h } from 'vue';
import TaskCalendarEventItem from '@/ui/pages/tasks/calendar/TaskCalendarEventItem.vue';
import { TaskCalendarViewClass } from '@/domain/entities/full-calendar';
import { ETaskListModule } from '@/application/types/task/task.types';

export function renderTaskEventComponent(
    data: TaskCalendarViewClass,
    sourceType: ETaskListModule
) {
    const appItem = createApp({
        render() {
            return h(
                TaskCalendarEventItem,
                {
                    data,
                    sourceType,
                },
                () => []
            );
        },
    });
    const tmpEl = document.createElement('div');
    const mountedApp = appItem.mount(tmpEl);
    const el = document.createElement('div');
    el.innerHTML = mountedApp.$el.outerHTML;
    return el;
}
