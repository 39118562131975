import { ref } from 'vue';

export default function stepperComposables(
    stepData: any,
    currentStepDefault: string | number
) {
    const stepperByKey = ref(stepData);
    const previousStep = ref('');
    const currentStep = ref(currentStepDefault);
    const updateCurrentStep = (step) => {
        if (!step) return;
        previousStep.value = `${currentStep.value}`;
        currentStep.value = step;
    };

    const onHandleGoToPreviousStep = () => {
        const currentStepData = stepperByKey.value[currentStep.value];
        if (
            currentStepData &&
            !currentStepData?.previous &&
            typeof currentStepData?.onClose == 'function'
        )
            currentStepData?.onClose();

        if (
            currentStepData?.previousAction &&
            typeof currentStepData?.previousAction == 'function'
        ) {
            currentStepData?.previousAction();
            stepperByKey.value[currentStep.value].isActive = false;
        }

        if (currentStepData?.previous) {
            if (stepperByKey.value[currentStepData?.previous]) {
                stepperByKey.value[currentStepData?.previous].isFinished =
                    false;
                stepperByKey.value[currentStepData?.previous].isActive = true;
            }
            updateCurrentStep(currentStepData?.previous);
        }
    };

    const onHandleGoToNextStep = (data?) => {
        const currentStepData = stepperByKey.value[currentStep.value];
        if (
            currentStepData?.nextAction &&
            typeof currentStepData?.nextAction == 'function'
        )
            currentStepData?.nextAction(data);

        if (stepperByKey.value[currentStep.value]) {
            stepperByKey.value[currentStep.value].isActive = false;
            stepperByKey.value[currentStep.value].isFinished = true;
        }
        if (currentStepData?.next && stepperByKey.value[currentStepData?.next])
            stepperByKey.value[currentStepData?.next].isActive = true;
        updateCurrentStep(currentStepData?.next);
    };

    const onSkipTo = (key) => {
        const currentStepData = stepperByKey.value[currentStep.value];
        if (
            currentStepData?.nextAction &&
            typeof currentStepData?.nextAction == 'function'
        )
            currentStepData?.nextAction();

        stepperByKey.value[currentStep.value].isActive = false;
        stepperByKey.value[currentStep.value].isFinished = true;

        stepperByKey.value[key].isActive = true;
        updateCurrentStep(key);
    };

    const updateStepById = (id, step) => {
        stepperByKey.value[id] = {
            ...stepperByKey.value[id],
            ...step,
        };
    };
    return {
        previousStep,
        currentStep,
        stepperByKey,
        onHandleGoToPreviousStep,
        onHandleGoToNextStep,
        onSkipTo,
        updateCurrentStep,
        updateStepById,
    };
}
