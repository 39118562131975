export interface VideoRecordingModel {
    id?: string;
    token?: string;
    organizationId?: number;
    userId?: number;
    region?: string;
    meeting?: any;
    attendee?: any;
    mediaUrl?: string;
    status?: VideoRecordingStatus;
    recordingStartDate?: Date;
    recordingEndDate?: Date;
    createdDate?: Date;
    createdBy?: number;
    updatedDate?: Date;
    updatedBy?: number;
}

export enum VideoRecordingStatus {
    Created = 'CREATED',
    Recording = 'RECORDING',
    MediaProcessing = 'MEDIA_PROCESSING',
    MediaProcessed = 'MEDIA_PROCESSED',
}
