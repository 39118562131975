import { computed } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import appStore from '@/store/app';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/en';
import 'dayjs/locale/vi';
import 'dayjs/locale/fr';
import localeData from 'dayjs/plugin/localeData';

export type FormatType =
    | 'date'
    | 'time'
    | 'date_time'
    | 'date_day'
    | 'day_month';
export default function datetimeComposables() {
    const FORMAT_BY_LANGUAGE = {
        date: {
            en: 'MMM DD, YYYY',
            vi: 'DD MMM YYYY',
            fr: 'DD MMM YYYY',
        },
        time: {
            en: 'hh:mm A',
            vi: 'HH[h]mm',
            fr: 'HH[:]mm',
        },
        date_time: {
            en: 'MMM DD, YYYY hh:mm A',
            vi: 'DD MMM YYYY, HH[h]mm',
            fr: 'DD MMM YYYY HH[:]mm',
        },
        date_day: {
            en: 'dddd MMM DD, YYYY',
            vi: 'dddd DD MMM YYYY',
            fr: 'dddd DD MMM YYYY',
        },
        day_month: {
            en: 'MMM DD',
            vi: 'DD MMM',
            fr: 'DD MMM',
        },
    };
    dayjs.extend(utc);
    dayjs.extend(tz);

    const myTimezoneOffset = computed(() => myProfileStore().getTimezone);
    const myTimezone = computed(() => myProfileStore().timezone);
    const timezone = computed(() => parseInt(myTimezoneOffset.value) || 0);
    const language = computed(() => _appStore.language);
    const _genFormatString = (format: FormatType) => {
        const lang = language.value || 'en';

        return FORMAT_BY_LANGUAGE[format][lang];
    };

    const _appStore = appStore();

    dayjs.extend(localeData);
    dayjs.locale(language.value);

    const formatDate = (
        originDate: any,
        format: FormatType,
        configs: {
            isIgnoreTimezone: boolean;
            timezone?: number;
        }
    ) => {
        const dateValue =
            configs?.isIgnoreTimezone || typeof originDate == 'number'
                ? dayjs(new Date(originDate))
                : dayjs(new Date(originDate)).add(
                      configs.timezone || timezone.value,
                      'hour'
                  );

        const formatStr = _genFormatString(format);

        return dateValue.locale(language.value).format(formatStr);
    };
    const formatDateTime = (
        originDate: any,
        format: FormatType,
        configs: {
            isIgnoreTimezone: boolean;
            timezone?: string;
        }
    ) => {
        const dateValue =
            configs?.isIgnoreTimezone || typeof originDate == 'number'
                ? dayjs(new Date(originDate))
                : dayjs.tz(
                      new Date(originDate),
                      configs.timezone || myTimezone.value
                  );

        const formatStr = _genFormatString(format);

        return dateValue.locale(language.value).format(formatStr);
    };

    return {
        formatDate,
        formatDateTime,
        myTimezone,
    };
}
