<script setup lang="ts">
import { ref, watch, onMounted, onUnmounted } from 'vue';
import {
  ITaskTodo,
  ITaskTodoList,
  ITaskTodoItem,
} from '@/application/types/task/task.types';
import taskDetailStore from '@/store/task/detail';
import delayExecuteSomething from '@/ui/composables/app/delay-execute-something';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = defineProps<{
  taskId?: number;
  todoList: ITaskTodo;
  readonly?: boolean;
  organizationId?: number;
  showAddNewButton?: boolean;
  isAutoFocus?: boolean;
}>();
const emit = defineEmits(['onAddNew', 'onSave', 'onRemoveSuccess']);

const COMPONENT_ID = `task-todo-list-detail_${props.todoList?.id}`;

const _taskDetailStore = taskDetailStore();
const { isWaiting, handleDelayExecuteSomeThings, handleClearTimeout } =
  delayExecuteSomething(3000, 20000);

const todoListPayload = ref<ITaskTodo>(props.todoList);

const initComponent = () => {
  isEditTitle.value = false;
  todoListPayload.value = props.todoList;
};

const stopBubbleDragEvent = () => {
  const divEl = document.getElementById(COMPONENT_ID);
  if (!divEl) return;

  divEl?.addEventListener('dragenter', (event) => {
    event.stopPropagation();
  });
};
const addEventHover = () => {
  const divEl = document.getElementById(COMPONENT_ID);
  if (!divEl) return;

  divEl?.addEventListener('mouseover', () => {
    divEl.classList.add('task-todo-list-detail-container');
  });
  divEl?.addEventListener('mouseleave', () => {
    divEl.classList.remove('task-todo-list-detail-container');
  });
};

onMounted(() => {
  stopBubbleDragEvent();
  addEventHover();
  // if (props.isAutoFocus) onFocusTo(0);
});

const atomChecklistRef = ref<any>(null);
watch(
  () => props.todoList?.id,
  () => {
    console.log('🚀 Tictop ~ props.todoList?.id:', props.todoList?.id);
    initComponent();

    setTimeout(() => {
      if (atomChecklistRef.value && atomChecklistRef.value?.initComponent)
        atomChecklistRef.value?.initComponent();
    });
  }
);

const isEditTitle = ref<boolean>(false);
const isOpenNotification = ref<boolean>(false);
let timeOut;
const onOpenAutoSaveNotification = () => {
  isOpenNotification.value = true;
  if (timeOut) clearTimeout(timeOut);

  timeOut = setTimeout(() => {
    isOpenNotification.value = false;
  }, 3 * 1000);
};
const onCloseNotification = () => {
  isOpenNotification.value = false;
  if (timeOut) clearTimeout(timeOut);
};

const onSave = async (hiddenNotification?) => {
  if (props.taskId) {
    handleClearTimeout(false);
    onCloseNotification();

    const newPayload: ITaskTodoList = {
      taskId: props.taskId,
      taskTodos: [todoListPayload.value],
    };

    await _taskDetailStore.createOrUpdateTaskCheckList(
      newPayload,
      props.organizationId
    );

    if (!hiddenNotification) onOpenAutoSaveNotification();
  }

  emit('onSave', todoListPayload.value);
};

const onRemove = async () => {
  if (!props.taskId || !todoListPayload.value?.id) return;

  settingGlobalModal({
    type: 'notification',
    title: '',
    content:
      translate('TASK_TODO_LIST_CONFIRM_REMOVE', {
        name: todoListPayload.value?.title || '',
      }) || 'Do you want to remove this tab',
    confirmLabel: translate('COMMON_LABEL_CONFIRM'),
    confirmable: true,
    closeable: true,
    cancelLabel: translate('COMMON_LABEL_CANCEL'),
  });
  const answer = await ask();
  if (answer) {
    _taskDetailStore.removeTaskCheckList(
      {
        taskId: props.taskId,
        taskTodoIds: [todoListPayload.value?.id],
      },
      props.organizationId
    );

    emit('onRemoveSuccess');
  }
};

const updateItemIndex = () => {
  if (todoListPayload.value?.todoItems?.length == 0) return;

  todoListPayload.value.todoItems.forEach((element, index) => {
    element.index = index;
  });

  return onSave(true);
};

const revertItemList = ref<ITaskTodoItem[]>([]);
const isOpenRevertWarning = ref<boolean>(false);
const closeRevertWarning = () => {
  isOpenRevertWarning.value = false;
  revertItemList.value = [];

  updateItemIndex();
};
const openRevertWarning = () => {
  isOpenRevertWarning.value = true;
  if (atomProcessCountdownRef.value?.reset)
    atomProcessCountdownRef.value?.reset();
};

const onUndoRemove = () => {
  if (revertItemList.value?.length == 0) return;

  revertItemList.value.forEach((item) => {
    const oldIndex = item?.index;

    todoListPayload.value.todoItems?.splice(oldIndex, 0, item);
  });

  closeRevertWarning();
  onSave();
};

const removeItem = async (removedItem, isAbleRollback) => {
  if (!removedItem?.id) return;

  _taskDetailStore.removeItemsInTaskCheckList(
    {
      taskId: props.taskId,
      taskTodoId: props.todoList?.id,
      todoItemIds: [removedItem?.id],
    },
    props.organizationId
  );

  // if (isNeedUpdateIndex) updateItemIndex();

  if (!isAbleRollback) return;
  if (removedItem) revertItemList.value.push(removedItem);

  openRevertWarning();
};

const atomProcessCountdownRef = ref<any>();
const onMouseoverRevertWarning = () => {
  if (atomProcessCountdownRef.value?.pause)
    atomProcessCountdownRef.value?.pause();
};
const onMouseleaveRevertWarning = () => {
  if (atomProcessCountdownRef.value?.play)
    atomProcessCountdownRef.value?.play();
};

onUnmounted(() => {});

const onUpdateItemName = () => {
  handleDelayExecuteSomeThings(Date.now(), async () => {
    await onSave();
  });
};

const onBlurCheckListTitleInput = () => {
  isEditTitle.value = false;
  onSave();
};
const onBlurAnItem = () => {
  if (isWaiting.value) onSave();
};

initComponent();
defineExpose({
  initComponent,
});
</script>
<template>
  <section
    :id="COMPONENT_ID"
    class="
      flex-1
      min-h-0
      w-full
      h-full
      flex flex-col
      gap-1
      border
      rounded-md
      border-gray-300
      relative
    "
  >
    <span
      class="
        absolute
        -top-2
        -right-2
        z-10
        w-6
        h-6
        rounded-full
        flex-center
        hover:text-black hover:bg-gray-300
        bg-gray-100
        cursor-pointer
        task-todo-list-detail_hover-to-show
      "
      @click.stop="onRemove"
    >
      <SynIcon name="trash" custom-class="w-4 h-4 fill-red-500" />
    </span>
    <div class="w-full flex-center">
      <SynInput
        v-if="isEditTitle"
        v-model="todoListPayload.title"
        autofocus
        :style="`border: 0px; background: #e0f1f2 `"
        @blur="onBlurCheckListTitleInput"
        @enter="isEditTitle = false"
      />
      <div
        v-else
        class="w-full rounded px-4 py-2 bg-gray-501"
        :class="{ 'cursor-pointer hover:bg-gray-100': !readonly }"
        @click="isEditTitle = !readonly"
      >
        <span
          class="text-overflow-hidden-line"
          :class="{
            'text-gray-300': !todoListPayload?.title,
          }"
        >
          {{ todoListPayload.title || 'Title' }}
        </span>
      </div>

      <SynButton
        v-if="showAddNewButton && !readonly"
        :label="$t('TASK_DETAIL_ADD_CHECKLIST')"
        name-icon="plus"
        @click="$emit('onAddNew')"
      />
    </div>

    <div class="flex-1 min-h-0 flex flex-col overflow-hidden relative">
      <!-- ITEM -->
      <AtomChecklist
        ref="atomChecklistRef"
        v-model="todoListPayload.todoItems"
        :readonly="readonly"
        @update:model-value="onUpdateItemName"
        @remove-item="removeItem"
        @blur-an-item="onBlurAnItem"
      />
    </div>
  </section>

  <teleport v-if="isWaiting" to="body">
    <div
      class="
        w-max
        fixed
        z-100
        bottom-20
        right-4
        flex-center flex-col
        space-y-1
        rounded
        px-2
        py-1
        shadow
        border-l-4 border-green-500
        text-sm
        bg-white
      "
    >
      <div class="flex-center space-x-2 relative">
        <SynIcon
          name="spinner"
          class="fill-current border-none animate-spin"
          custom-class="w-4 h-4"
        />
      </div>
    </div>
  </teleport>
  <teleport v-else-if="isOpenRevertWarning" to="body">
    <div
      class="
        w-max
        fixed
        z-100
        bottom-20
        right-4
        flex-center flex-col
        space-y-1
        rounded
        px-2
        py-1
        shadow
        border-l-4 border-green-500
        text-sm
        bg-white
      "
      @mouseover="onMouseoverRevertWarning"
      @mouseleave="onMouseleaveRevertWarning"
    >
      <div class="flex-center space-x-2 relative">
        <span class="text-green-500">
          {{
            $t('TASK_TODO_LIST_REVERT_WARNING_TITLE', {
              totalItem: Math.max(1, revertItemList?.length),
            })
          }}
        </span>

        <span
          class="px-1 rounded border border-gray-300 cursor-pointer"
          @click.stop="onUndoRemove"
        >
          {{ $t('COMMON_LABEL_UNDO') }}
        </span>
        <span
          class="
            absolute
            -top-3
            -right-3
            z-10
            w-4
            h-4
            rounded-full
            flex-center
            hover:text-black hover:bg-gray-300
            bg-gray-100
            cursor-pointer
          "
          @click.stop="isOpenRevertWarning = false"
        >
          <SynIcon name="close" custom-class="w-3 h-3" />
        </span>
      </div>
      <AtomProcessCountdown
        ref="atomProcessCountdownRef"
        is-auto-run
        :percent="0"
        :speed="50"
        color="#22c55e"
        @on-time-out="closeRevertWarning"
      />
    </div>
  </teleport>
  <teleport v-else-if="isOpenNotification" to="body">
    <div
      class="
        w-max
        fixed
        z-100
        bottom-20
        right-4
        flex-center
        rounded
        px-2
        py-1
        shadow
        border-l-4 border-green-500
        text-sm
        bg-white
      "
    >
      <div class="flex-center space-x-2 relative">
        <span class="text-green-500">
          {{ $t('TASK_TODO_LIST_CHANGES_HAVE_BEEN_SAVED') }}
        </span>
      </div>
    </div>
  </teleport>
</template>

<style lang="scss" scoped>
.task-todo-list-detail_hover-to-show {
  visibility: hidden;
}

.task-todo-list-detail-container:hover .task-todo-list-detail_hover-to-show {
  visibility: unset;
}
</style>
