<script setup lang="ts">
import { v4 as uuidv4 } from 'uuid';
import { useResizeObserver } from '@vueuse/core';
import { onMounted, onUnmounted, ref, computed } from 'vue';
import taskDetailStore from '@/store/task/detail';
import {
  ITaskTodo,
  ITaskTodoItemStatus,
} from '@/application/types/task/task.types';
import TaskTodoListCreate from '@/ui/modules/task/components/check-list/TaskTodoListCreate.vue';
import TaskTodoListDetail from '@/ui/modules/task/components/check-list/TaskTodoListDetail.vue';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
import taskStore from '@/store/task';
import { ITaskConfigs } from '@/application/types/task/task-configs.types';

const CURRENT_COMPONENT_ID = 'task-todo-list-all';
const props = defineProps<{
  taskId?: number;
  taskCheckList?: any;
  todoList?: any;
  readonly?: boolean;
  isSharing?: boolean;
  organizationId?: number;
}>();
const emit = defineEmits([
  'update:totalTodoList',
  'onCreateSuccess',
  'onSave',
  'onScrollToBottom',
]);

const _taskDetailStore = taskDetailStore();
const _taskStore = taskStore();

const taskConfigs = computed<ITaskConfigs>(() => {
  return _taskStore.dataConfigs;
});
const maxTodoList = computed<number>(() => {
  return taskConfigs.value?.checkList?.maxTotalItems || 5;
});

const multipleTodoList = ref<ITaskTodo[]>([]);

const isLoading = ref<boolean>(false);

const taskTodoListAllRef = ref<any>();
useResizeObserver(taskTodoListAllRef, (entries) => {
  const entry = entries[0];
  // const { width, height } = entry.contentRect
  // text.value = `width: ${width}, height: ${height}`
  const hasVerticalScrollbar =
    entry?.target?.scrollHeight > entry?.target?.clientHeight;

  console.log('🚀 Tictop ~ hasVerticalScrollbar:', hasVerticalScrollbar);
  if (hasVerticalScrollbar) {
    stopBubbleEvent();
  } else {
    openBubbleEvent();
  }
});

const initComponent = async (taskId?) => {
  isLoading.value = true;

  const currentTaskId = taskId || props.taskId;

  if (props.taskCheckList) {
    multipleTodoList.value = props.taskCheckList?.taskTodos || [];
  } else if (props.todoList) {
    multipleTodoList.value = props.todoList || [];
  } else if (!props.isSharing) {
    const res = await _taskDetailStore.getTaskCheckListByTaskId(
      currentTaskId,
      props.organizationId
    );

    multipleTodoList.value = res?.result?.taskTodos || [];

    emit('onSave', multipleTodoList.value);
  }
  isLoading.value = false;

  emit('update:totalTodoList', multipleTodoList.value?.length);

  if (currentTaskId) onLoadCache(currentTaskId);
};

const taskTodoListDetailRef = ref<any>();

const isAddNew = ref<boolean>(false);
const onClickNewTab = () => {
  if (multipleTodoList.value?.length == maxTodoList.value) return;

  isAddNew.value = true;

  const lang = getLocalStorage(StorageConstant.LANGUAGE);
  const namePrefix =
    lang == 'en'
      ? 'Check list'
      : lang == 'fr'
      ? 'Liste de contrôle'
      : 'Việc cần làm';

  const newTodoList = {
    id: uuidv4(),
    title: `${namePrefix}${
      multipleTodoList.value?.length > 0
        ? ' ' + multipleTodoList.value?.length
        : ''
    }`,
    description: '',
    isDraft: true,
    todoItems: [
      {
        id: uuidv4(),
        title: '',
        shortDescription: '',
        description: '',
        status: ITaskTodoItemStatus.PENDING,
        index: 0,
      },
    ],
  };

  multipleTodoList.value = [...multipleTodoList.value, newTodoList];

  emit('onScrollToBottom');
  onUpdateCache();
};

const removeTodoListParent = (todoList) => {
  multipleTodoList.value = multipleTodoList.value?.filter(
    (o) => o.id !== todoList?.id
  );
  onUpdateCache();
};

const onCancelCreate = (currentTodoList) => {
  removeTodoListParent(currentTodoList);

  onUpdateCache();
};

const onCreateSuccess = (newTodoList) => {
  if (!newTodoList) return;
  const index = multipleTodoList.value?.findIndex(
    (o) => o.id == newTodoList?.id
  );

  multipleTodoList.value[index].isDraft = false;

  onUpdateCache();
  emit('onCreateSuccess', multipleTodoList.value);
};

const stopPropagation = (event) => {
  console.log('🚀 Tictop ~ event:', event);
  // event.stopPropagation();
};

const stopBubbleEvent = () => {
  const divEl = document.getElementById(CURRENT_COMPONENT_ID);
  if (!divEl) return;
  divEl.addEventListener('scroll', (event) => {
    stopPropagation(event);
  });
  divEl.addEventListener('wheel', (event) => {
    stopPropagation(event);
  });
  divEl.addEventListener('mousewheel', (event) => {
    stopPropagation(event);
  });
};
const openBubbleEvent = () => {
  const divEl = document.getElementById(CURRENT_COMPONENT_ID);
  if (!divEl) return;
  divEl.removeEventListener('scroll', stopPropagation);
  divEl.removeEventListener('wheel', stopPropagation);
  divEl.removeEventListener('mousewheel', stopPropagation);
};

onMounted(() => {
  initComponent();

  // stopBubbleEvent();
});
onUnmounted(() => {
  openBubbleEvent();
});

const onLoadCache = (taskId) => {
  const currentTaskCacheState = _taskDetailStore.cacheStateById[taskId];

  if (currentTaskCacheState?.draftTodoList?.length > 0) {
    multipleTodoList.value = [
      ...currentTaskCacheState?.draftTodoList,
      ...multipleTodoList.value,
    ];
  }
};
const onUpdateCache = () => {
  _taskDetailStore.setCacheState(props.taskId, {
    draftTodoList: multipleTodoList.value?.filter(
      (todoList) => todoList?.isDraft
    ),
  });
  emit('update:totalTodoList', multipleTodoList.value?.length);
};

const updateTodoListFromCreate = (todoList) => {
  const index = multipleTodoList.value?.findIndex((o) => o?.id == todoList?.id);
  if (index > -1) {
    multipleTodoList.value[index] = todoList;
    onUpdateCache();
  }
};

const onSaveDetail = (newTodoList) => {
  const index = multipleTodoList.value?.findIndex(
    (o) => o?.id == newTodoList?.id
  );
  if (index > -1) {
    multipleTodoList.value[index] = newTodoList;
    emit('onSave', multipleTodoList.value);
  }
};
const onRemoveSuccess = (newTodoList) => {
  if (!newTodoList) return;

  multipleTodoList.value = multipleTodoList.value?.filter(
    (t) => t?.id !== newTodoList?.id
  );

  onUpdateCache();
  emit('onSave', multipleTodoList.value);
};

defineExpose({
  onClickNewTab,
  initComponent,
});
</script>
<template>
  <div :id="CURRENT_COMPONENT_ID" class="w-full h-full">
    <template v-if="multipleTodoList?.length > 0 && !isLoading">
      <div
        ref="taskTodoListAllRef"
        class="flex-1 min-h-0 flex flex-col gap-2"
        style="max-height: 100%"
      >
        <template
          v-for="currentTodoList in multipleTodoList"
          :key="currentTodoList?.id"
        >
          <TaskTodoListCreate
            v-if="currentTodoList?.isDraft"
            :task-id="taskId"
            :todo-list="currentTodoList"
            :readonly="readonly"
            :organization-id="organizationId"
            @on-cancel="onCancelCreate(currentTodoList)"
            @on-create-success="onCreateSuccess(currentTodoList)"
            @update:todo-list="updateTodoListFromCreate"
            @on-add-new="onClickNewTab"
          />
          <TaskTodoListDetail
            v-else
            ref="taskTodoListDetailRef"
            :task-id="taskId"
            :todo-list="currentTodoList"
            :readonly="readonly"
            :organization-id="organizationId"
            @on-add-new="onClickNewTab"
            @on-save="onSaveDetail"
            @on-remove-success="onRemoveSuccess(currentTodoList)"
          />
        </template>

        <div class="w-full flex items-center bg-white z-10 py-1">
          <AtomButton
            class="w-max"
            icon-name="plus"
            type="OUT_LINE"
            color="current"
            size="md"
            custom-class-icon="h-3 w-3"
            :label="$t('TASK_DETAIL_ADD_CHECKLIST')"
            @click="onClickNewTab"
          />
        </div>
      </div>
    </template>
  </div>
</template>
