<script setup lang="ts">
import { computed, reactive, ref } from 'vue';
import userStore from '@/store/user';
import ScopeTree from '@/ui/components/scope/ScopeTree.vue';

const props = defineProps<{
  userId: number;
  isEditable?: boolean;
}>();

enum EViewMode {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

const allUserDetailByIds = computed<any>(() => {
  return _userStore.allUserDetailByIds;
});

const loadingData = reactive<{
  init: boolean;
}>({ init: true });

const domainsDefault = ref([] as any);
const domains = ref([] as any);
const initComponent = async () => {
  if (
    allUserDetailByIds.value &&
    Object.prototype.hasOwnProperty.call(allUserDetailByIds.value, props.userId)
  ) {
    const userDetail = allUserDetailByIds.value[props.userId];

    domainsDefault.value = userDetail.domains;
  } else {
    loadingData.init = true;
    const result = await _userStore.getDomainListById(props.userId);
    domainsDefault.value = result;
    _userStore.allUserDetailByIds = {
      ..._userStore.allUserDetailByIds,
      [props.userId]: {
        ..._userStore.allUserDetailByIds[props.userId],
        domains: result,
      },
    };
  }
  domains.value = domainsDefault.value;
  loadingData.init = false;
};

const _userStore = userStore();
const viewMode = ref<EViewMode>(EViewMode.VIEW);

const onChangeViewMode = (newMode: EViewMode) => {
  viewMode.value = newMode;
};

const isLoadingScope = ref(false);

// scope
const onSaveScope = async () => {
  isLoadingScope.value = true;
  var domain = [] as any;
  var projectid = [] as any;

  domains.value.forEach((el) => {
    if (el.checked && el.anyProjects) {
      domain.push({
        domainId: el.domainId,
        anyProjects: true,
      });
    }
    if (el.checked && !el.anyProjects) {
      domain.push({
        domainId: el.domainId,
        anyProjects: false,
      });
    }

    el.projectScopeList.forEach((elchild) => {
      if (elchild.checked) {
        projectid.push(elchild.projectId);
      }
      elchild.subProject.forEach((elsub) => {
        if (elsub.checked) {
          projectid.push(elsub.projectId);
        }
      });
    });
  });
  var dataScope = {
    domains: domain,
    projectIds: projectid,
    userId: props.userId,
  };
  await _userStore.saveUserScopesDto(dataScope);
  await _userStore.getDomainListById(props.userId || 0);
  _userStore.allUserDetailByIds[props.userId].domains = _userStore.domains;
  domainsDefault.value = _userStore.allUserDetailByIds[props.userId].domains;
  isLoadingScope.value = false;

  onChangeViewMode(EViewMode.VIEW);
};

const onChooseDomain = (domain) => {
  domains.value = domain;
};

initComponent();
</script>
<template>
  <div v-permission-model class="w-1/2 flex flex-1 flex-col h-full">
    <div
      class="
        flex
        bg-current-50
        justify-between
        py-2
        pl-4
        rounded-t-md
        items-center
      "
    >
      <p class="font-semibold text-current-500">
        {{ $t('USERMANAGEMENT_LABEL_DOMAIN') }}
      </p>
      <div v-if="isEditable" class="mr-4 flex items-center rounded-md">
        <div v-if="viewMode == 'EDIT'" class="text-xs flex space-x-2 mr-4">
          <SynButton
            v-vig-tooltip="$t('COMMON_LABEL_CANCEL')"
            type-text
            color="gray"
            :label="$t('COMMON_LABEL_CANCEL')"
            :disabled="isLoadingScope"
            @click="onChangeViewMode(EViewMode.VIEW)"
          />
          <SynButton
            v-vig-tooltip="$t('COMMON_LABEL_SAVE')"
            :label="$t('COMMON_LABEL_SAVE')"
            :is-loading="isLoadingScope"
            :disabled="isLoadingScope"
            @click="onSaveScope"
          />
        </div>
        <div
          v-else
          v-vig-tooltip="$t('COMMON_LABEL_EDIT')"
          class="
            hover:bg-current-100
            p-2
            rounded-full
            flex
            items-center
            cursor-pointer
            justify-center
          "
          @click="onChangeViewMode(EViewMode.EDIT)"
        >
          <SynIcon name="edit" class="fill-current" is-active />
        </div>
      </div>
    </div>
    <div
      class="
        p-2
        bg-white
        border
        text-sm
        border-gray-100
        h-full
        overflow-y-auto
        small-scrollbar
        rounded-b-md
      "
    >
      <div v-if="loadingData.init" class="flex-center space-x-3">
        <div class="p-2 w-16 bg-gray-200 animate-pulse rounded-full"></div>
        <div class="p-2 w-full bg-gray-200 animate-pulse rounded-full"></div>
      </div>
      <div v-else class="flex flex-col h-full pb-4">
        <div v-if="!domains?.find((el) => el.checked) && viewMode !== 'EDIT'">
          <SynAnimation
            name="searchNotFound"
            stype="width: 100px; height: 100px"
            label="No scopes"
          />
        </div>
        <ScopeTree
          v-else
          :domains="domainsDefault"
          is-show-project
          :is-edit="viewMode == EViewMode.EDIT"
          :is-disabled="viewMode !== EViewMode.EDIT"
          @click-domain="(domain) => onChooseDomain(domain)"
          @click-all-project="(domain) => onChooseDomain(domain)"
          @click-project="(domain) => onChooseDomain(domain)"
          @click-sub-project="(domain) => onChooseDomain(domain)"
        />
      </div>
    </div>
  </div>
</template>
