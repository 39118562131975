<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { iconPlans } from '@/ui/common/constants/plans';
import SynButton from '@/ui/common/atoms/SynButton/SynButton.vue';
import plansStore from '@/store/plans';
import { IPlanDetail } from '@/application/interfaces/PaymentInterfaces';
import { IOrgCurrentPlan, EPlanState } from '@/application/types/plan.types';
import {
  getUiByPlanState,
  UI_BY_PLAN_ID,
} from '@/application/constants/plan.const';
import calculatePaymentPurchaseCompos from '@/ui/composables/plans/plan-detail-calculate-demo';
import paymentStore from '@/store/payment';
import { EPlanId } from '@/application/constants/plan.const';
import { EPlanAction } from '@/application/constants/plan.const';
import OrganizationPlan from '@/domain/entities/payment/OrganizationPlan';
import organizationStore from '@/store/organization';
import { EPaymentPurpose } from '@/application/types/payment.types';

const props = defineProps<{
  defaultTotalUsers: number;
  isUpgrading?: boolean;
}>();

defineEmits<{
  (e: 'onCompare'): void;
}>();

const _planStore = plansStore();
const _paymentStore = paymentStore();
const currentOrgPlan = computed<IOrgCurrentPlan>(
  () => _planStore.currentOrgPlan
);

const allPlanByIds = computed<any>(() => _planStore.allPlanByIds);
const currentPlanInfo = computed<IPlanDetail>(
  () => allPlanByIds.value[currentOrgPlan.value?.planId]
);

const uiByState = computed(() => {
  if (!currentOrgPlan.value) return {};

  return getUiByPlanState(
    currentOrgPlan.value.planId,
    currentOrgPlan.value.stateCode
  );
});
// const uiBySubState = computed(() => {
//   const subState = currentOrgPlan.value.subStateCode || 'DEFAULT';
//   if (
//     !uiByState.value ||
//     !Object.prototype.hasOwnProperty.call(uiByState.value, subState)
//   )
//     return null;

//   return uiByState.value[subState];
// });
const defaultTotalUserBy = computed<string>(() => {
  return uiByState.value?.defaultTotalUserBy;
});

const customClassBody = ref('');
const customClassHeader = ref('');

const isOverflowing = ref<boolean>(false);
onMounted(() => {
  getCustomClass(currentOrgPlan.value?.planId);
  totalUsers.value = props.defaultTotalUsers;

  billingPeriodId.value = discountByPeriods.value?.at(-1)?.id || 1;
  const currentPeriod = discountByPeriods.value.find(
    (o) =>
      o.planId == currentOrgPlan.value?.planId &&
      o.period == currentOrgPlan.value?.period
  );

  if (currentPeriod?.id) billingPeriodId.value = currentPeriod?.id;

  const planInfoDescriptionElm = document.getElementById(
    'plan-info_description'
  );
  if (planInfoDescriptionElm) {
    isOverflowing.value =
      planInfoDescriptionElm.clientWidth < planInfoDescriptionElm.scrollWidth ||
      planInfoDescriptionElm.clientHeight < planInfoDescriptionElm.scrollHeight;
  }
});

watch(
  () => props.defaultTotalUsers,
  () => {
    totalUsers.value = props.defaultTotalUsers;
  }
);
const getCustomClass = (planId) => {
  switch (planId) {
    case EPlanId.PRO:
      customClassBody.value = 'border-x border-b border-current-100';
      customClassHeader.value =
        'bg-current-350 text-white border-x border-t border-current-100';
      break;
    case EPlanId.VIP:
      customClassBody.value = 'border-x border-b border-current-200';
      customClassHeader.value =
        'border-x border-t border-current-200 bg-current-400 text-white';
      break;
    default:
      break;
  }
};

const isCreatingToken = computed<boolean>(
  () => _paymentStore.isCreatingPaymentToken
);

const makeNewPayment = async () => {
  // _paymentStore.checkAndMakeNewPayment({
  //   planId: currentOrgPlan.value?.planId,
  //   purpose: uiByState.value?.action?.defaultActionKey || null,
  //   period: billingPeriodInfo.value?.period,
  //   totalUsers: totalUsers.value,
  // });
  paymentStore().isCreatingPaymentToken = true;

  await new OrganizationPlan(
    _planStore.currentOrgPlan,
    organizationStore().organizationInfo,
    _paymentStore.paymentConfigs,
    _planStore.planCapacityByKeys,
    _planStore.capacityByPlanIds
  ).createPayment({
    planId: currentOrgPlan.value?.planId,
    purpose:
      uiByState.value?.action?.defaultActionKey == EPlanAction.EXTEND
        ? EPaymentPurpose.EXTEND
        : EPaymentPurpose.PURCHASE,
    period: billingPeriodInfo.value?.period,
    totalUsers: totalUsers.value,
  });

  paymentStore().isCreatingPaymentToken = false;
};
const styleByPlanId = computed<any>(() => {
  return UI_BY_PLAN_ID[currentPlanInfo.value?.id]?.style;
});

const titleFeaturePlan = (planId) => {
  switch (planId) {
    case EPlanId.PRO:
      return 'SETTING_PLAN_SMART_TITLE_DES';
    case EPlanId.VIP:
      return 'SETTING_PLAN_EXCELLENT_TITLE_DES';
    default:
      return 'SETTING_PLAN_SMART_TITLE_DES';
  }
};

const {
  billingPeriodId,
  billingPeriodInfo,
  discountByPeriods,
  totalUsers,
  calculatedPricing,
} = calculatePaymentPurchaseCompos(currentPlanInfo.value?.id, true);

const isOpenFeatureDetail = ref<boolean>(false);

const onGotoWebContact = () => {
  window.open('https://fiine.vn/contact', '_blank');
};
</script>

<template>
  <div
    class="
      h-full
      w-full
      rounded-md
      flex flex-col
      shadow-lg
      border
      relative
      bg-white
    "
    style="max-width: 30rem"
  >
    <!-- item header -->
    <section
      class="relative p-6 flex-center space-x-4 rounded-t-md w-full"
      :style="{
        color: styleByPlanId?.text,
        backgroundColor: styleByPlanId?.background,
        height: '10rem',
      }"
    >
      <div
        v-if="
          currentOrgPlan.stateCode === EPlanState.TRIAL ||
          currentOrgPlan.stateCode === EPlanState.OVERDUE_TRIAL
        "
        class="absolute"
        style="right: -1.4rem; top: -0.6rem"
      >
        <div class="flex-center relative">
          <SynIcon name="tag" custom-class="h-28" />
          <span
            class="absolute text-white text-center font-semibold"
            style="
              top: 33%;
              left: 8%;
              font-size: 0.7rem;
              transform: rotate(47deg);
              width: 8rem;
            "
          >
            {{
              $t(
                currentOrgPlan.stateCode == EPlanState.OVERDUE_TRIAL
                  ? 'PLAN_STATE_LABEL_OVERDUE_TRIAL'
                  : 'PLAN_STATE_LABEL_TRIAL'
              )
            }}
          </span>
        </div>
      </div>
      <div class="flex-center p-2">
        <SynIcon
          :name="iconPlans[currentPlanInfo?.id]"
          custom-class="w-24 h-24"
        />
      </div>
      <div class="flex-1 min-w-0 h-full flex flex-col space-y-2 items-start">
        <span class="md:text-3xl text-2xl font-bold uppercase">
          {{ currentPlanInfo?.name }}
        </span>
        <div
          id="plan-info_description"
          class="
            w-full
            flex-1
            min-h-0
            overflow-hidden
            md:text-sm
            text-xs
            relative
          "
        >
          <p v-vig-tooltip="currentPlanInfo?.description" class="leading-6">
            {{ currentPlanInfo?.description }}
          </p>
          <span
            v-if="isOverflowing"
            class="absolute bottom-0 right-0 md:text-sm text-xs px-1"
            :style="{
              backgroundColor: styleByPlanId?.background,
            }"
            >...</span
          >
        </div>
      </div>

      <div
        v-if="!currentPlanInfo?.isActive"
        class="absolute"
        style="right: -1.4rem; top: -0.6rem"
      >
        <div class="flex-center relative">
          <SynIcon name="tag" custom-class="h-28" />
          <span
            class="absolute text-white text-center font-bold"
            style="
              top: 30%;
              left: 24%;
              font-size: 0.7rem;
              transform: rotate(46deg);
            "
          >
            COMING SOON
          </span>
        </div>
      </div>

      <span
        class="
          flex-center
          space-x-2
          absolute
          bottom-0
          right-0
          p-2
          cursor-pointer
        "
        @click="isOpenFeatureDetail = !isOpenFeatureDetail"
      >
        <span class="flex-center w-3.5 h-3.5 rounded-full border border-white">
          <SynIcon name="check" class="fill-gray-200" />
        </span>
        <span v-if="isOpenFeatureDetail" class="text-xs text-gray-200">
          {{ $t('PLAN_LABEL_VIEW_LESS') || 'View less' }}
        </span>
        <span v-else class="text-xs text-gray-200">
          {{ $t('PLAN_LABEL_VIEW_DETAIL_PLAN') || 'View detail plan' }}
        </span>
      </span>
    </section>
    <!-- item body -->
    <section
      class="
        flex-1
        min-h-0
        flex flex-col
        space-y-4
        p-6
        overflow-y-auto
        small-scrollbar
        relative
      "
    >
      <div class="flex flex-col items-baseline text-sm">
        <span class="text-center text-gray-700 italic">
          {{ $t('COMMON_LABEL_BASE_PRICE') || 'Base price' }}:
        </span>

        <div class="flex items-end justify-center">
          <span class="text-current-500 font-semibold text-2xl">
            {{ $filters.currency(currentPlanInfo.basePrice, 'vi') }}
          </span>
          /<span
            class="text-gray-800 italic"
            v-html="$t('COMMOM_LABEL_UNIT_PRICE_PER_USER_MONTH')"
          >
          </span>
        </div>
      </div>
      <!-- features -->
      <div
        v-if="
          currentPlanInfo?.planFeatures &&
          currentPlanInfo?.planFeatures?.length > 0
        "
        class="pb-4 pt-2 flex flex-1 min-h-0 flex-col"
      >
        <span
          class="font-semibold text-current-700"
          v-html="
            $t(titleFeaturePlan(currentPlanInfo?.id), {
              planId: currentPlanInfo?.id == 'VIP' ? 'PRO' : '',
            })
          "
        >
        </span>
        <div
          class="
            min-h-0
            overflow-auto
            small-scrollbar
            flex flex-col
            py-4
            space-y-4
          "
        >
          <div
            v-for="des in currentPlanInfo.planFeatures?.slice(0, 3)"
            :key="des?.id"
            class="flex space-x-2 items-start"
          >
            <div class="w-4 h-4 flex-center pt-1">
              <div class="w-4 h-4 flex-center rounded-full bg-current-50">
                <SynIcon
                  name="check"
                  custom-class="w-3 h-3 fill-current text-current-500"
                />
              </div>
            </div>
            <span class="font-normal text-gray-500 text-sm italic">
              {{ des?.name }}
            </span>
          </div>
        </div>
      </div>
      <!-- calculate pricing -->
      <div class="flex flex-col space-y-2">
        <div class="flex-center">
          <AtomLabelSwitch
            v-model="billingPeriodId"
            :options="discountByPeriods?.slice(0, 3)"
          >
            <template #label="{ item }">
              <span>
                {{ item?.period }}
                {{
                  item?.unit == 'YEAR'
                    ? $t('COMMON_LABEL_YEAR', {
                        suffix: item?.period > 1 ? 's' : '',
                      })
                    : $t('COMMON_LABEL_MONTH', {
                        suffix: item?.period > 1 ? 's' : '',
                      })
                }}
              </span>
            </template>
            <template #suffix="{ key, active }">
              <AtomStarburst
                v-if="
                  (discountByPeriods?.find((o) => o.id === key)?.discount ||
                    0) > 0
                "
                class="absolute h-6 w-6 -top-4"
                :class="
                  active
                    ? ' bg-orange-500 text-white text-xs'
                    : ' bg-orange-400 text-white text-xs'
                "
              >
                -{{
                  (discountByPeriods?.find((o) => o.id === key)?.discount ||
                    0) * 100
                }}%
              </AtomStarburst>
            </template>
          </AtomLabelSwitch>
        </div>
        <div class="flex flex-col space-y-1">
          <div class="flex items-end justify-center">
            <span class="text-current-500 font-semibold text-3xl">
              {{
                $filters.currency(
                  calculatedPricing?.priceActualForOneUserInOneMonth,
                  'vi'
                )
              }}
            </span>
            /
            <span
              class="text-gray-500 italic"
              v-html="$t('COMMOM_LABEL_UNIT_PRICE_PER_USER_MONTH')"
            >
            </span>
          </div>

          <div class="flex-center pt-4 gap-1">
            <div class="text-center text-xs text-gray-700 italic">
              {{
                $t('PLAN_LABEL_FOR_TOTAL_USER', {
                  totalUsers,
                  totalMonths: billingPeriodInfo?.period,
                })
              }}
            </div>
            <AtomHelpDropdown
              v-if="defaultTotalUserBy == 'USAGE'"
              title="CURRENT_PLAN_TOTAL_USER_FOR_PAYMENT_DEFAULT_NOTE_TITLE"
            >
              <template #content>
                <ul class="pl-4" style="list-style: circle">
                  <li
                    v-html="
                      $t(
                        'CURRENT_PLAN_TOTAL_USER_FOR_PAYMENT_DEFAULT_NOTE_BODY_01',
                        {
                          totalUsers: totalUsers,
                        }
                      )
                    "
                  ></li>
                  <li
                    v-html="
                      $t(
                        'CURRENT_PLAN_TOTAL_USER_FOR_PAYMENT_DEFAULT_NOTE_BODY_02'
                      ) || ''
                    "
                  ></li>
                </ul>
              </template>
            </AtomHelpDropdown>
          </div>
          <div class="flex-center space-x-2">
            <span
              v-if="calculatedPricing?.discountPercent > 0"
              class="flex space-x-2 items-center text-gray-700"
            >
              <span class="line-through text-current-350">
                {{
                  $filters.currency(
                    calculatedPricing?.lastTotalAmountNotDiscount,
                    'vi'
                  )
                }}
              </span>
              <span
                class="p-1 bg-orange-200 text-orange-600 rounded-full text-sm"
              >
                - {{ calculatedPricing?.discountPercent }}%
              </span>
            </span>
            <span class="font-semibold">
              {{ $filters.currency(calculatedPricing?.lastTotalAmount, 'vi') }}
            </span>
          </div>
        </div>
      </div>

      <dialog
        :open="isOpenFeatureDetail"
        class="absolute top-0 left-0 w-full h-full p-6"
        style="margin: 0"
      >
        <span
          class="absolute top-3 right-2 cursor-pointer"
          @click="isOpenFeatureDetail = false"
        >
          <SynIcon name="close" custom-class="w-4 h-4 text-gray-500" />
        </span>

        <!-- features -->
        <div
          v-if="
            currentPlanInfo?.planFeatures &&
            currentPlanInfo?.planFeatures?.length > 0
          "
          class="pb-4 flex flex-1 min-h-0 flex-col"
        >
          <span
            class="font-semibold text-current-800"
            v-html="
              $t(titleFeaturePlan(currentPlanInfo?.id), {
                planId: currentPlanInfo?.id == 'VIP' ? 'PRO' : '',
              })
            "
          >
          </span>
          <div
            class="
              min-h-0
              overflow-auto
              small-scrollbar
              flex flex-col
              py-4
              space-y-2
            "
          >
            <div
              v-for="des in currentPlanInfo.planFeatures"
              :key="des"
              class="flex space-x-2 items-start"
            >
              <div class="w-4 h-4 flex-center pt-1">
                <div class="w-4 h-4 flex-center rounded-full bg-current-50">
                  <SynIcon
                    name="check"
                    custom-class="w-3 h-3 fill-current text-current-500"
                  />
                </div>
              </div>
              <span class="font-normal text-gray-500 text-sm italic">
                {{ des?.name }}
              </span>
            </div>
          </div>
        </div>
      </dialog>
    </section>
    <!-- actions -->
    <div class="p-6 pt-4">
      <div class="flex-center flex-col">
        <SynButton
          v-if="uiByState?.action?.defaultActionKey === 'CONTACT'"
          :label="$t('SETTING_PLAN_PAYMENT_CONTACT_SUPPORT_TITLE')"
          class="w-2/3 mb-4"
          @click="onGotoWebContact"
        />
        <SynButton
          v-else-if="
            currentOrgPlan?.planId !== EPlanId.FREE &&
            currentOrgPlan?.planId !== EPlanId.BASIC
          "
          :label="
            $t(
              uiByState?.action?.defaultActionKey == EPlanAction.EXTEND
                ? 'PAYMENT_EXTEND'
                : 'PAYMENT_LABEL_PURCHASE'
            )
          "
          :is-loading="isUpgrading || isCreatingToken"
          :disabled="currentOrgPlan?.isLifeTime"
          class="w-2/3 mb-4"
          @click="makeNewPayment"
        />
        <span
          class="underline text-center text-current-600 cursor-pointer italic"
          @click="$emit('onCompare')"
        >
          {{ $t('PAYMENT_SELECT_ANOTHER_PLAN') || 'Select another plan' }}
        </span>
      </div>
    </div>
  </div>
</template>
