<script setup lang="ts">
import { ref, computed } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import { AdminType } from '@/ui/common/constants/constant';
import { ignoreUnicode } from '@/ui/plugins/utils';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';
import ButtonAvatars from '@/ui/modules/avatars/ButtonAvatars.vue';
import userStore from '@/store/user';
import groupStore from '@/store/group';
import { GroupMemberRole } from '@/ui/common/constants/constant';
import { ICreateOrUpdateGroupPayload } from '@/application/repositories/GroupRepository';
import { EGroupType } from '@/application/types/group/group.types';
import useStepper from '@/ui/composables/app/stepper-composables';
import AlertModal from '@/ui/components/modals/AlertModal.vue';
import GroupUpdateMemberList from '@/ui/modules/group/components/GroupUpdateMemberList.vue';

const emit = defineEmits(['cancel', 'createSuccess']);

const _userStore = userStore();
const _groupStore = groupStore();

enum STEPPER_LIST {
  INFO_GROUP = 'INFO_GROUP',
  SCOPE_GROUP = 'SCOPE_GROUP',
  INVITE_USER_GROUP = 'INVITE_USER_GROUP',
}
const {
  currentStep,
  stepperByKey,
  onHandleGoToPreviousStep,
  onHandleGoToNextStep,
} = useStepper(
  {
    [STEPPER_LIST.INFO_GROUP]: {
      key: STEPPER_LIST.INFO_GROUP,
      label: 'COMMON_LABEL_INFORMATION',
      next: STEPPER_LIST.SCOPE_GROUP,
      previous: '',
      isActive: true,
      isFinished: false,
      previousText: 'COMMON_LABEL_CANCEL',
      confirmText: 'COMMON_LABEL_NEXT',
      nextAction: () => {
        // check name not null
      },
      previousAction: () => {
        onCancel();
      },
    },
    [STEPPER_LIST.SCOPE_GROUP]: {
      key: STEPPER_LIST.SCOPE_GROUP,
      label: 'TASK_CREATE_GROUP_FORM_SCOPE_GROUP',
      previous: STEPPER_LIST.INFO_GROUP,
      next: STEPPER_LIST.INVITE_USER_GROUP,
      isActive: false,
      isFinished: false,
      previousText: 'COMMON_LABEL_PREVIOUS',
      confirmText: 'COMMON_LABEL_NEXT',
      nextAction: () => {
        // must select at least a domain
      },
      previousAction: () => {},
    },
    [STEPPER_LIST.INVITE_USER_GROUP]: {
      key: STEPPER_LIST.INVITE_USER_GROUP,
      label: 'TASK_TABLE_LABEL_MEMBERS',
      previous: STEPPER_LIST.SCOPE_GROUP,
      next: '',
      isActive: false,
      isFinished: false,
      previousText: 'COMMON_LABEL_PREVIOUS',
      confirmText: 'COMMON_LABEL_FINISH',
      nextAction: () => {
        onSave();
      },
      previousAction: () => {},
    },
  },
  STEPPER_LIST.INFO_GROUP
);

const domainsDefault = computed(() => _groupStore.domains);
// const userList = ref([]);
const keySearchScope = ref('');
// const domains = ref([]);
const myProfile = computed(() => myProfileStore().myProfile);
const file = ref();
const fileName = computed(() => _groupStore.fileName);
const isLoadFinishGroup = ref(false);
const newGroupPayload = ref<ICreateOrUpdateGroupPayload>({
  name: '',
  isPrivate: false,
  description: '',
  avatar: '',
  type: EGroupType.NORMAL,
  memberInfos: [
    {
      userId: myProfile.value?.id,
      role: GroupMemberRole.Admin,
    },
  ],
  domainIds: [],
});
// const isAddAvatar = ref(false);
const checkRequired = ref(false);

const domains = computed(() => {
  const searchTxt = ignoreUnicode(keySearchScope.value);

  if (!searchTxt) return domainsDefault.value;

  return (domainsDefault.value || []).filter(
    (domain) =>
      domain?.domainName &&
      ignoreUnicode(domain?.domainName).includes(searchTxt)
  );
});

const initData = async () => {
  let myId = getCurrentUserId();

  const myRole = MyOrganizationSingleton.getInstance().getUserRole();

  await _groupStore.getDomainListById(myId, true);
  await _userStore.getShortInfosUser();

  if (myRole === AdminType.Admin) myId = 0;
};

initData();

const checkDomain = (id, value) => {
  let indexDomains = domains.value?.findIndex((el) => el.domainId == id);
  let indexDomainsDefault = domainsDefault.value?.findIndex(
    (el) => el.domainId == id
  );
  domains.value[indexDomains].checked = value;
  domainsDefault.value[indexDomainsDefault].checked = value;
};

const onCancel = () => {
  emit('cancel', false);
};

const checkMemberValid = () => {
  if (
    !newGroupPayload.value.memberInfos ||
    newGroupPayload.value.memberInfos?.length == 0
  )
    return false;
  if (
    newGroupPayload.value.memberInfos.filter(
      (e) => e.role == GroupMemberRole.Admin
    ).length == 0
  ) {
    showAlertRemainOneAdmin.value = true;
    return false;
  }

  return true;
};

const onSave = async () => {
  if (isLoadFinishGroup.value || !checkMemberValid()) return;

  isLoadFinishGroup.value = true;

  const listDomain = domainsDefault.value
    ?.filter((o) => o.checked)
    .map((domain) => domain.domainId);

  newGroupPayload.value.domainIds = listDomain;

  if (file.value) {
    await _groupStore.uploadAvatarAws(file.value);
    newGroupPayload.value.avatar = fileName.value;
  }

  const res = await _groupStore.createAndUpdate(newGroupPayload.value);

  isLoadFinishGroup.value = false;

  emit('createSuccess', res?.result);
  emit('cancel');
};

const fileObj = ref();
const getFile = (files) => {
  fileObj.value = files;
  file.value = files.file;
};

const isDisabledSummit = computed(() => {
  return (
    isLoadFinishGroup.value ||
    (currentStep.value === STEPPER_LIST.INFO_GROUP &&
      newGroupPayload.value?.name === '') ||
    (currentStep.value === STEPPER_LIST.SCOPE_GROUP &&
      domainsDefault.value?.filter((el) => el.checked).length === 0) ||
    (currentStep.value === STEPPER_LIST.INVITE_USER_GROUP &&
      newGroupPayload.value?.memberInfos?.length === 0)
  );
});

const showAlertRemainOneAdmin = ref(false);
</script>

<template>
  <SynModal
    style-body="p-0 flex flex-row flex-wrap"
    z-index="z-50"
    container-class="w-[48rem] h-4/5"
    disable-click-outside
    @cancel="onCancel"
  >
    <template #header>
      <div class="w-full flex flex-col space-y-4">
        <div class="text-base min-w-max">
          {{ $t('TASK_CREATE_GROUP_FORM_CREATE_GROUP') }}
        </div>
        <SynStepper
          key-label="label"
          class=""
          :steps="Object.values(stepperByKey)"
        />
      </div>
    </template>
    <template #body>
      <!-- GROUP'S INFORMATION -->
      <div
        v-if="currentStep == STEPPER_LIST.INFO_GROUP"
        class="w-full flex flex-col items-center pt-4 justify-center"
      >
        <!-- <upload-avatar-icon
          :file="file"
          :name="newGroupPayload?.name"
          type-upload="GROUP"
          z-index="z-50"
          :is-update-success="isAddAvatar"
          @upload-avatar="onChooseAvatar"
        /> -->

        <div class="flex-center w-32 h-32 hover-to-show__parent cursor-pointer">
          <SynAvatar
            :src="fileObj?.preview ? fileObj?.preview : ''"
            :name="newGroupPayload?.name"
            is-hidden-name
            is-view-image
            custom-class="w-32 h-32"
          />
          <ButtonAvatars
            size="w-1/3 h-1/3"
            is-edit
            type="GROUP"
            :avatar="fileObj?.preview ? fileObj?.preview : ''"
            @get-file="(obj) => getFile(obj)"
          />
        </div>

        <div class="w-2/3 mt-6 flex flex-wrap space-y-4">
          <div class="flex w-full items-center justify-center space-x-4">
            <div class="w-full">
              <div class="w-32 font-medium text-current-800">
                {{ $t('TASK_CREATE_GROUP_FORM_GROUP_NAME') }}
              </div>
              <SynFormInput
                :error-message="
                  checkRequired && newGroupPayload.name?.trim() === ''
                    ? 'TASK_CREATE_FORM_TASK_CREATE_FORM_IS_REQUIRED'
                    : ''
                "
              >
                <SynInput
                  v-model="newGroupPayload.name"
                  class="w-full"
                  style-name="max-height: 500px;"
                  autofocus
                  placeholder="e.g. fiine-dev"
                  :error-message="
                    checkRequired && newGroupPayload.name?.trim() === ''
                      ? $t('TASK_CREATE_FORM_TASK_CREATE_FORM_IS_REQUIRED')
                      : ''
                  "
                  :max-length="80"
                  @enter="onHandleGoToNextStep"
                />
              </SynFormInput>
            </div>
          </div>

          <div class="flex w-full justify-center space-x-4">
            <div class="w-full">
              <div class="w-32 font-medium text-current-800">
                {{ $t('COMMON_LABEL_DESCRIPTION') }}
              </div>
              <SynInputArea
                v-model="newGroupPayload.description"
                class="w-full"
                style-name="max-height: 500px;"
                :max-length="150"
                :placeholder="
                  $t('GROUP_LABEL_PLACE_HOLDER_DES_GROUP') || 'Description'
                "
                @enter="onHandleGoToNextStep"
              />
            </div>
          </div>

          <!-- status -->
          <div class="flex w-full justify-center space-x-4">
            <div class="w-full flex flex-wrap space-x-4">
              <div
                class="
                  py-2
                  px-2
                  w-full
                  bg-gray-50
                  rounded-md
                  flex
                  justify-between
                  space-x-2
                "
              >
                <div class="flex items-center space-x-4">
                  <SynIcon name="private" custom-class="h-8 w-8 fill-current" />
                  <div class="flex flex-wrap">
                    <label class="font-medium text-current-800">{{
                      $t('GROUP_TASKS_PRIVATE')
                    }}</label>
                    <label class="text-xs w-full text-gray-500">{{
                      $t('GROUP_CREATE_SUB_STATUS_MAKE_PRIVATE')
                    }}</label>
                  </div>
                </div>
                <AtomSwitch
                  v-model="newGroupPayload.isPrivate"
                  @change="newGroupPayload.isPrivate"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- GROUP'S DOMAIN -->
      <template v-if="currentStep == STEPPER_LIST.SCOPE_GROUP">
        <div class="pt-3 px-2 flex-center">
          <div class="w-[34rem] flex p-2 flex-col overflow-hidden max-h-52">
            <div>
              <span class="font-medium">
                {{ $t('GROUP_CREATE_LABEL_CHOOSE_DOMAIN') }}
              </span>
            </div>
            <div
              class="
                grid grid-cols-3
                py-2
                gap-2
                flex-wrap
                h-full
                small-scrollbar
                overflow-auto
              "
            >
              <div
                v-for="domainItem in domains?.filter((el) => el.checked)"
                :key="domainItem"
                class="
                  flex
                  justify-between
                  items-center
                  space-x-4
                  py-2
                  pr-2
                  pl-4
                  text-sm
                  rounded-full
                  bg-current-50 bg-opacity-50
                "
              >
                <span class="w-max truncate">{{ domainItem?.domainName }}</span>
                <div
                  class="
                    rounded-full
                    hover:bg-current-100
                    cursor-pointer
                    w-4
                    h-4
                    flex-center
                  "
                  @click.stop="
                    checkDomain(domainItem?.domainId, !domainItem.checked)
                  "
                >
                  <SynIcon name="cancel" custom-class="w-2 h-2 fill-gray-500" />
                </div>
              </div>
            </div>
            <div class="px-1 py-2">
              <VigSearchBox v-model="keySearchScope" autofocus />
            </div>
          </div>
        </div>
        <div class="px-2 flex-1 w-full overflow-hidden flex-center">
          <div class="h-full w-[34rem] small-scrollbar overflow-auto px-2">
            <div
              v-for="domain in domains"
              :key="domain"
              class="
                flex
                items-center
                py-2
                cursor-pointer
                hover:bg-gray-100
                px-2
                rounded-md
              "
              @click="checkDomain(domain?.domainId, !domain.checked)"
            >
              <SynIcon
                name="checkbox"
                class="fill-current"
                :custom-class="'w-4 h-4 mr-2'"
                :is-active="domain.checked"
              />
              <span class="font-normal">
                {{ domain.domainName }}
              </span>
            </div>
          </div>
        </div>
      </template>

      <!-- GROUP'S MEMBER -->
      <template v-if="currentStep == STEPPER_LIST.INVITE_USER_GROUP">
        <GroupUpdateMemberList v-model="newGroupPayload.memberInfos" />
      </template>
    </template>

    <template #footer>
      <div class="pr-4 flex py-4 justify-end space-x-2">
        <SynButton
          v-if="stepperByKey[currentStep].previousText"
          type-outline
          :label="$t(stepperByKey[currentStep].previousText)"
          :disabled="isLoadFinishGroup"
          @click="onHandleGoToPreviousStep"
        />
        <SynButton
          v-if="stepperByKey[currentStep].confirmText"
          :is-loading="isLoadFinishGroup"
          :disabled="isDisabledSummit"
          :label="$t(stepperByKey[currentStep].confirmText)"
          @click="onHandleGoToNextStep"
        />
      </div>
    </template>
  </SynModal>
  <AlertModal
    v-if="showAlertRemainOneAdmin"
    :title-text="$t('COMON_NOT_ALLOW_PERFORM_ACTION')"
    :sub-title-text="$t('GROUP_UPDATE_ADMIN_NOTALLOW')"
    container-class="w-1/4"
    name-icon="warning"
    :container-color="'current'"
    @cancel="showAlertRemainOneAdmin = false"
  />
</template>
