<script setup lang="ts">
import { computed, ref } from 'vue';
import { AdminType } from '@/ui/common/constants/constant';
import { ChatConversationModel } from '@/application/models/chat/ChatConversationModel';
import ActionExtend from '@/ui/modules/messaging/chat-action/ActionExtend.vue';
import ChatCallButton from '@/ui/modules/messaging/chat-info-header/chat-call-button/ChatCallButton.vue';
import { activeAndFocusOnConversationId } from '@/ui/modules/messaging/chat-widget/chat-widget-public-state';
import ModalNotificationSetting from '@/ui/components/notification/ModalNotificationSetting.vue';
import notificationSettingStore from '@/store/notificationSetting/notificationSettingStore';
import groupStore from '@/store/group';
import overviewGroupStore from '@/store/dashboard/overview-group-store';
import chatStore from '@/store/chat';

const props = defineProps<{
  conversation: ChatConversationModel;
  contact: any;
  conversationId: string;
}>();

defineEmits([
  'onOpenCreateTask',
  'onGotoTaskClick',
  'onGotoChatClick',
  'onShowFilesClick',
  'onShowPinnedMessagesClick',
  'onOpenNoteClick',
  'onChatPanelClose',
  'onViewGroupMember',
  'onViewGroupSetting',
  'onChatViewDetail',
  'onViewTaskList',
]);
const _chatStore = chatStore();
const _groupStore = groupStore();

const isChatDisabled = computed(
  () =>
    props.conversation?.isDeleted ||
    props.conversation?.isDeactive ||
    props.conversation?.isDisallowed
);

const _notificationSettingStore = notificationSettingStore();
const notificationSetting = computed(() => {
  return props.conversationId
    ? _chatStore.mutedConversations[props.conversationId]
    : undefined;
});
const initGroupNotification = async () => {
  if (!props.conversation?.isGroup) return;

  await _notificationSettingStore.getSettingNotification(
    props.contact?.id,
    'GROUP'
  );
};

const initComponent = () => {
  if (props.conversation?.isGroup) {
    initGroupNotification();
  }
};

const isShowNotificationSetting = ref(false);

const onChangeNotification = async () => {
  initGroupNotification();
  isShowNotificationSetting.value = false;
};

const toggleNotification = async () => {
  const groupId = props.contact?.id;
  if (!groupId) return;
  if (notificationSetting.value) {
    const enableResult =
      await _notificationSettingStore.enableSettingNotification(
        `group-${groupId}`
      );
    if (enableResult) {
      const groupId = props.contact?.id;
      _groupStore.setValueSettingGroup({}, groupId);
      overviewGroupStore().updateNotificationByGroupId({}, groupId);
      initGroupNotification();
    }
  } else {
    isShowNotificationSetting.value = true;
  }
};

initComponent();
</script>

<template>
  <div
    class="w-full flex items-center justify-between p-2"
    :class="{
      'bg-gray-100 text-gray-800': !activeAndFocusOnConversationId,
      'bg-current-500 text-white': activeAndFocusOnConversationId,
    }"
  >
    <div class="flex-center space-x-2">
      <SynAvatarStatusActive
        :id="conversation?.contactId"
        :avatar="
          (conversation?.avatarUrl || conversation?.avatar)?.replace(
            'original',
            'mini'
          )
        "
        :name="conversation?.name"
        :is-user="!conversation?.isGroup"
        :is-visitor="conversation?.adminType === AdminType.Visitor"
        :is-deactive="conversation?.isDeactive"
        :is-deleted="conversation?.isDeleted"
        :is-disallowed="conversation?.isDisallowed"
        :short-name="conversation?.shortName"
        class="text-sm font-semibold"
        custom-class="h-8 w-8"
      >
        <template v-if="!contact?.isUser" #information-more>
          <SynListAvatar
            class="pl-2 py-0.5"
            :users="contact?.groupUsers"
            :max-length="5"
            custom-class="w-5 h-5 border-none"
          />
        </template>
      </SynAvatarStatusActive>

      <button
        v-vig-tooltip="$t('CHAT_MINI_GO_TO_CHAT_SCREEN')"
        class="w-8 h-8 rounded-full flex-center shrink-0"
        :class="
          activeAndFocusOnConversationId
            ? 'hover:bg-white fill-white hover:bg-opacity-30'
            : 'hover:bg-white fill-gray-500'
        "
        @click="$emit('onGotoChatClick', contact)"
      >
        <SynIcon name="Redirect" />
      </button>
    </div>

    <div class="flex-center">
      <ChatCallButton
        v-if="!isChatDisabled && !conversation?.isGroupChatDisallowed"
        :contact="contact"
        :custom-class="
          activeAndFocusOnConversationId
            ? 'rounded hover:bg-white fill-white hover:bg-opacity-30'
            : 'rounded hover:bg-white fill-gray-500'
        "
      />

      <ActionExtend
        :is-chat-disabled="isChatDisabled"
        :current-contact-active="contact"
        :conversation-id="conversationId"
        :conversation="conversation"
        is-chat-widget
        :is-turning-off-notification="!!notificationSetting"
        @on-goto-task-click="$emit('onGotoTaskClick')"
        @on-open-create-task="$emit('onOpenCreateTask')"
        @on-show-files-click="$emit('onShowFilesClick')"
        @on-show-pinned-messages-click="$emit('onShowPinnedMessagesClick')"
        @on-open-note-click="$emit('onOpenNoteClick')"
        @on-view-group-member="$emit('onViewGroupMember')"
        @on-view-group-setting="$emit('onViewGroupSetting')"
        @on-chat-view-detail="$emit('onGotoChatClick')"
        @on-view-task-list="$emit('onViewTaskList')"
        @on-toggle-notification="toggleNotification"
      >
        <button
          v-vig-tooltip="$t('COMMON_LABEL_OTHER')"
          class="w-8 h-8 rounded-full flex-center"
          :class="
            activeAndFocusOnConversationId
              ? 'hover:bg-white fill-white hover:bg-opacity-30'
              : 'hover:bg-white fill-gray-500'
          "
        >
          <SynIcon name="DotsVertical" />
        </button>
      </ActionExtend>

      <button
        v-vig-tooltip="$t('COMMON_LABEL_HIDDEN')"
        class="w-8 h-8 rounded-full flex-center"
        :class="
          activeAndFocusOnConversationId
            ? 'hover:bg-white fill-white hover:bg-opacity-30'
            : 'hover:bg-white fill-gray-500'
        "
        @click="$emit('onChatPanelClose')"
      >
        <SynIcon name="Minus" :custom-class="['w-6']" />
      </button>
    </div>
  </div>

  <ModalNotificationSetting
    v-if="isShowNotificationSetting"
    :ref-id="contact?.id"
    scope="GROUP"
    :name="conversation?.name || ''"
    :function-default="'MESSAGING'"
    @change-notification="onChangeNotification"
    @cancel="isShowNotificationSetting = false"
  />
</template>
