<script setup lang="ts">
// *** IMPORTS ***
import { onMounted, ref, watch } from 'vue';
import {
  bindVideoElement,
  shareScreenVideoTileId,
} from '@/ui/plugins/awss3/aws-chime';

// *** PROPS, EMITS ***

// *** PRIVATE VARIABLES ***

// *** COMPOSABLES ***

// *** REFS ***
const videoContainerRef = ref<HTMLDivElement>();
const videoRef = ref<HTMLVideoElement>();

// *** COMPUTED ***

// *** WATCHES ***
watch(
  () => shareScreenVideoTileId.value,
  () => _bindVideoElement()
);

// *** HOOKS ***
onMounted(() => {
  _bindVideoElement();
});

// *** HANDLER FUNCTIONS ***

// *** PRIVATE FUNCTIONS ***
const _bindVideoElement = () => {
  if (!shareScreenVideoTileId.value || !videoRef.value) return;

  bindVideoElement(shareScreenVideoTileId.value, videoRef.value);
};

// *** EXPOSES ***
</script>

<template>
  <div
    ref="videoContainerRef"
    class="
      w-full
      h-full
      flex-center
      bg-black bg-opacity-30
      rounded-2xl
      overflow-hidden
    "
  >
    <video ref="videoRef" class="h-full"></video>
  </div>
</template>

<style scoped></style>
