<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35.998"
    height="36"
    viewBox="0 0 35.998 36"
  >
    <path
      id="Icon_awesome-sun"
      data-name="Icon awesome-sun"
      d="M18,11.25A6.75,6.75,0,1,0,24.75,18,6.761,6.761,0,0,0,18,11.25Zm17.325,5.66-6.659-3.326,2.355-7.059a1.216,1.216,0,0,0-1.54-1.54L22.423,7.341,19.09.675a1.217,1.217,0,0,0-2.18,0L13.584,7.334,6.518,4.978a1.216,1.216,0,0,0-1.54,1.54l2.355,7.059L.675,16.91a1.217,1.217,0,0,0,0,2.18l6.659,3.326L4.978,29.482a1.216,1.216,0,0,0,1.54,1.54l7.059-2.355L16.9,35.325a1.217,1.217,0,0,0,2.18,0l3.326-6.659,7.059,2.355a1.216,1.216,0,0,0,1.54-1.54l-2.355-7.059L35.311,19.1a1.219,1.219,0,0,0,.014-2.187ZM24.363,24.363a9,9,0,1,1,0-12.727A9.009,9.009,0,0,1,24.363,24.363Z"
    />
  </svg>
</template>
