<template>
  <template v-if="isLoading">
    <SynLoading />
  </template>
  <template v-else>
    <component :is="layout">
      <router-view />
    </component>
    <SynConfirmModal />
    <CurrentPlanInformationGlobalModal />
  </template>
  <Notification />
</template>

<script>
import { defineComponent, computed, onMounted, onUnmounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import DashboardLayout from '@/ui/components/layouts/DashboardLayout.vue';
import TaskDetailLayout from '@/ui/components/layouts/task/TaskDetailLayout.vue';
import EmptyLayout from '@/ui/components/layouts/EmptyLayout.vue';
import LoginLayout from '@/ui/components/layouts/LoginLayout.vue';
import FirsttimeLayout from '@/ui/components/layouts/FirsttimeLayout.vue';
import SetupTeamLayout from '@/ui/components/layouts/SetupTeamLayout.vue';
import ProfileLayout from '@/ui/components/layouts/ProfileLayout.vue';
import EmbedLayout from '@/embed/pages/layouts/EmbedLayout.vue';
import EmbedChatLayout from '@/embed/pages/layouts/EmbedChatLayout.vue';
import TicketLayout from '@/ticket/pages/layouts/TicketLayout.vue';
import { initializeGoogleApis } from '@/ui/plugins/google/place';
import { initializeTwemoji } from '@/ui/plugins/twemoji/twemoji';
import systemStore from '@/store/system';
import Notification from '@/ui/components/notification/Notification.vue';
import CurrentPlanInformationGlobalModal from '@/ui/modules/settings/payments/overview/CurrentPlanInformationGlobalModal.vue';
import { detectLanguageFromBrowserClient } from '@/ui/app-global-state';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import { initializeLottiePlayer } from '@/ui/plugins/lottie-player/lottie-player';
import { initializeCalHeatmap } from '@/ui/plugins/cal-heatmap/cal-heatmap';
import useAppFavicon from '@/ui/composables/app/app-favicon-composable';

export default defineComponent({
  name: 'App',
  components: {
    DashboardLayout,
    EmptyLayout,
    FirsttimeLayout,
    SetupTeamLayout,
    ProfileLayout,
    LoginLayout,
    TaskDetailLayout,
    EmbedLayout,
    EmbedChatLayout,
    TicketLayout,
    Notification,
    CurrentPlanInformationGlobalModal,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const _systemStore = systemStore();
    const isLoading = computed(() => _systemStore.isLoading);
    const layout = computed(
      () => `${router.currentRoute.value.meta.layout}-layout`
    );

    useAppFavicon();
    initializeGoogleApis();
    initializeTwemoji();
    initializeLottiePlayer();
    initializeCalHeatmap();

    const initData = async () => {
      await _systemStore.initData(router);

      const location = getLocalStorage(StorageConstant.LOCATION);
      detectLanguageFromBrowserClient(location);
    };

    onMounted(() => {
      _systemStore.onListenDeploymentSystemFirebase(router, route);
      _systemStore.onListenStateAnnouncementDeploy();
    });

    onUnmounted(() => {
      _systemStore.offListenDeploymentSystemFirebase();
    });

    initData();

    return {
      layout,
      isLoading,
    };
  },
});

// Google Tag Manager
(function (w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != 'dataLayer' ? '&l=' + l : '';
  j.async = true;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-TMN3RH3');
</script>

<script></script>

<style>
@import 'cropperjs/dist/cropper.min.css';

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.fade-enter-active {
  transition: opacity 0.5s ease-in;
}

.fade-leave-active {
  transition: opacity 0.1s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.small-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.small-scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
}

.small-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.small-scrollbar:hover::-webkit-scrollbar-thumb {
  background: #888;
}

.small-scrollbar:hover::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.small-scrollbar {
  scrollbar-color: white white;
  scrollbar-width: thin;
}

.small-scrollbar:hover {
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.ck-mentions::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.v-html a {
  text-decoration: underline;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
