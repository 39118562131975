<script setup lang="ts">
import { ref, computed } from 'vue';
// import { useRoute } from 'vue-router';
// import myProfileStore from '@/store/auth/my-profile';
import {
  getUserPathFirebase,
  setDataFirebase,
} from '@/ui/plugins/firebases/firebaseRealtimeDatabase';

import { getCurrentUserId } from '@/ui/hooks/storageHook';
import favoriteStore from '@/store/favorite/favoriteStore';

const props = withDefaults(
  defineProps<{
    shapeType?: 'BUTTON' | 'DEFAULT';
    refId: number | string;
    type?: 'member' | 'group' | 'domain' | 'menu';
    customSize?: string;
    isHiddenUnFavorite?: boolean;
    isButton?: boolean;
    readonly?: boolean;
    updateImmediately?: boolean;
    iconName?: string;
  }>(),
  {
    shapeType: 'BUTTON',
    type: 'member',
    customSize: 'w-4 h-4',
    isButton: true,
    iconName: 'Heart',
  }
);
const _favoriteStore = favoriteStore();
const myFavorites = computed(() => _favoriteStore.getMyFavorites);
const isFavorite = computed(() => {
  return (
    myFavorites.value?.some(
      (fa) =>
        fa?.type?.toLowerCase() == props.type?.toLowerCase() &&
        fa?.id == props.refId
    ) ||
    (props.type == 'menu' &&
      _favoriteStore.favoriteMenus?.some((fa) => fa?.id == props.refId))
  );
});

const userId = ref();
const extendClass = ref('');
let timeOut;
userId.value = getCurrentUserId();

const handleFavorite = async () => {
  if (props?.readonly) return;

  const path = getUserPathFirebase(userId.value, 'favorites');
  if (!path) return;

  let favoriteUserPath = `${path}/${props?.type}_${props?.refId}`;
  if (!isFavorite.value) {
    extendClass.value = '';
    console.log('🚀 Tictop ~ extendClass:', {
      favoriteUserPath,
      refId: props?.refId,
      type: props?.type,
      index:
        props?.type == 'menu'
          ? _favoriteStore.favoriteMenus.length
          : myFavorites.value?.length,
    });
    await setDataFirebase(favoriteUserPath, {
      refId: props?.refId,
      type: props?.type,
      index:
        props?.type == 'menu'
          ? _favoriteStore.favoriteMenus.length
          : myFavorites.value?.length,
    });
  } else {
    if (props.updateImmediately) {
      await setDataFirebase(favoriteUserPath, null);
    } else {
      extendClass.value = 'animate-pulse';

      if (timeOut) {
        clearTimeout(timeOut);
        extendClass.value = '';
        timeOut = undefined;
        return;
      }

      timeOut = setTimeout(async () => {
        await setDataFirebase(favoriteUserPath, null);
        extendClass.value = '';
        timeOut = undefined;
      }, 3000);
    }
  }
};

const isShowHeartIcon = computed<boolean>(() => {
  return !props.isHiddenUnFavorite || isFavorite.value;
});
</script>

<template>
  <div
    v-if="shapeType == 'BUTTON'"
    class="
      flex-center
      rounded-full
      w-7
      h-7
      bg-white
      border border-current-50
      shrink-0
    "
    :class="
      readonly ? '' : 'cursor-pointer hover:bg-current-50 hover:fill-current'
    "
    @click.stop="handleFavorite"
  >
    <SynIcon
      v-if="isShowHeartIcon"
      :title="
        !isFavorite
          ? $t('COMMON_LABEL_ADD_FAVORITE')
          : $t('COMMON_LABEL_REMOVE_FAVORITE')
      "
      :name="iconName"
      :custom-class="`w-4 h-4 ${customSize}`"
      :is-active="isFavorite"
      color="current"
      class="fill-current text-current-500"
      :class="extendClass"
    />
  </div>
  <div
    v-else-if="isShowHeartIcon"
    :title="
      !isFavorite
        ? $t('COMMON_LABEL_ADD_FAVORITE')
        : $t('COMMON_LABEL_REMOVE_FAVORITE')
    "
    class="flex-center w-max divide-x rounded-full"
    @click.stop="handleFavorite"
  >
    <SynIcon
      :name="iconName"
      :custom-class="customSize"
      :is-active="isFavorite"
      color="current"
      class="fill-current text-current-500"
      :class="extendClass"
    />
  </div>
</template>

<style scoped lang="scss"></style>
