<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import dayjs from 'dayjs';
import interactionPlugin from '@fullcalendar/interaction';
import taskListStore from '@/store/task/task-list-store';
import { ETaskListModule } from '@/application/types/task/task.types';
import { keySearch } from '@/ui/modules/task/table/task-list-state';
import { stringNomalize } from '@/ui/hooks/commonFunction';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import taskStore from '@/store/task';
import { GANTT_TABLE_COLUMN_LIST } from '@/application/constants/task.const';
import localConfigsStore from '@/store/local-configs';
import { TaskCalendarViewClass } from '@/domain/entities/full-calendar';
import { renderTaskEventComponent } from './rendering-task-event-component';
import taskDrawerStore from '@/store/task/drawer';
import { TaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import UnscheduleTasksDrawer from '@/ui/pages/tasks/calendar/UnscheduleTasksDrawer.vue';
import { isOpenDrawer } from '@/ui/pages/tasks/calendar/unschedule-tasks-drawer-state';
import {
  isOpenCreateTaskDrawer,
  newTaskDefaultPayload,
  onCreateGlobalSuccess,
} from '@/ui/modules/task/task-global-state';
import taskListRealtime from '@/ui/modules/task/composables/task-list-realtime';
import appStore from '@/store/app';
import { getCurrentUserId } from '@/ui/hooks/storageHook';

const props = defineProps<{
  ownerId: number | string;
  sourceType: ETaskListModule;
}>();
const _taskDrawerStore = taskDrawerStore();

const DATE_FORMAT = 'YYYY-MM-DD';
const _taskListStore = taskListStore();
const _localConfigsStore = localConfigsStore();

const localConfigs = computed<any>(() => _localConfigsStore.localConfigs);

const allTaskByIds = computed<any>(() => {
  return taskStore().allTaskByIds;
});
const taskAllList = computed(() => {
  return _taskListStore.taskAllList
    ?.map((t) => {
      return allTaskByIds.value[t?.id];
    })
    ?.filter((o) => o?.scheduleTime);
});
const unscheduleTasks = computed(() => {
  return _taskListStore.taskAllList
    ?.map((t) => {
      return allTaskByIds.value[t?.id];
    })
    ?.filter((o) => !o?.scheduleTime);
});

const taskCalendarEvents = computed(() => {
  let resultList = [...taskAllList.value];

  if (keySearch.value)
    resultList = resultList?.filter(
      (t) =>
        stringNomalize(t.name?.toLowerCase()).includes(
          stringNomalize(keySearch.value?.toLowerCase())
        ) ||
        stringNomalize(t.code?.toLowerCase()).includes(
          stringNomalize(keySearch.value?.toLowerCase())
        ) ||
        stringNomalize(t.displayName?.toLowerCase()).includes(
          stringNomalize(keySearch.value?.toLowerCase())
        )
    );

  return resultList;
});

let timer;
watch(
  () => keySearch.value,
  () => {
    timer && clearTimeout(timer);
    // 3. start timeout
    timer = setTimeout(() => {
      if (taskCalendarEvents.value?.length > 0) {
        updateTaskInChart();
      }
    }, 250);
  }
);

const isLoading = computed(() => _taskListStore.isLoading || false);

const isLoadingChart = ref<boolean>(true);

const FULL_CALENDAR_VIEW_MODE = {
  DAY_GRID_MONTH: 'dayGridMonth',
  DAY_GRID_WEEK: 'dayGridWeek',
  DAY_GRID_DAY: 'dayGridDay',
  LIST_WEEK: 'listWeek',
};
const VIEW_MODE_LIST = {
  [FULL_CALENDAR_VIEW_MODE.DAY_GRID_MONTH]: {
    key: FULL_CALENDAR_VIEW_MODE.DAY_GRID_MONTH,
    nameCode: 'GANTT_VIEW_BY_MONTH',
  },
  [FULL_CALENDAR_VIEW_MODE.DAY_GRID_WEEK]: {
    key: FULL_CALENDAR_VIEW_MODE.DAY_GRID_WEEK,
    nameCode: 'GANTT_VIEW_BY_WEEK',
  },
  // [FULL_CALENDAR_VIEW_MODE.DAY_GRID_DAY]: {
  //   key: FULL_CALENDAR_VIEW_MODE.DAY_GRID_DAY,
  //   nameCode: 'GANTT_VIEW_BY_DAY',
  // },
  // [FULL_CALENDAR_VIEW_MODE.LIST_WEEK]: {
  //   key: FULL_CALENDAR_VIEW_MODE.LIST_WEEK,
  //   nameCode: 'CALENDAR_TITLE_LIST',
  // },
};

const openTaskDetail = (task) => {
  _taskDrawerStore.pushCurrentIds({
    id: task.id,
    name: task.name,
    code: task.code,
    tabType: 'DETAIL',
  });
};

const calendarInstance = ref<any>(null);
const changeStartEndTime = async (event) => {
  const task = event?.extendedProps;
  const start = event?.start;
  const end = event?.end || event?.start;

  const updatedTask = new TaskDetailClass(task);

  await updatedTask.changeStartEndTime({
    startTime: dayjs(start).startOf('day').utc(true).format(),
    scheduleTime: dayjs(end).startOf('day').utc(true).format(),
    scheduleByHour: false,
  });

  taskStore().updateAllTaskByIds(updatedTask.id, updatedTask);
};

const updateEventSources = () => {
  if (!calendarInstance.value) return;
  const eventSources = calendarInstance.value.getEventSources();
  if (eventSources?.length > 0)
    eventSources.forEach((eventSource) => {
      eventSource.remove();
    });

  calendarInstance.value.addEventSource(
    taskCalendarEvents.value.map((data) => {
      return new TaskCalendarViewClass({
        ...data,
        title: data?.name,
        start: dayjs(
          data?.startTime ? data?.startTime : data?.scheduleTime
        ).format(DATE_FORMAT),
        end: dayjs(data?.scheduleTime).format(DATE_FORMAT),
        extendedProps: data,
        editable: true,
        eventResizableFromStart: true,
      });
    })
  );
};

const genarateNewTaskPayload = (scheduleTime) => {
  if (props.sourceType == ETaskListModule.DOMAIN)
    return {
      domainId: parseInt(props.ownerId?.toString()) || 1,
      scheduleTime,
    };

  if (props.sourceType == ETaskListModule.GROUP)
    return {
      groupId: parseInt(props.ownerId?.toString()) || 1,
      scheduleTime,
    };

  return {
    assigneeId: parseInt(props.ownerId?.toString()) || getCurrentUserId(),
    scheduleTime,
  };
};

const embedAddButton = (startDate, endDate) => {
  let currentDate = startDate;

  while (dayjs(endDate).diff(dayjs(currentDate), 'd') >= 0) {
    const dateKey = dayjs(currentDate).format('YYYY-MM-DD');
    currentDate = dayjs(currentDate).add(1, 'day');
    const element = document.querySelector(`[data-date="${dateKey}"]`);

    if (element) {
      element.classList.add('date-td');
      const button = document.createElement('button');
      button.innerHTML = '<span>&#43;</span>';
      button.classList.add('my-custom-button');

      // Append the button to the event element
      element.appendChild(button);

      // Add click event listener to the button (optional)
      button.addEventListener('click', function () {
        newTaskDefaultPayload.value = genarateNewTaskPayload(dateKey);

        onCreateGlobalSuccess.value = (data) => {
          if (data?.domainId !== props.ownerId) return;
        };

        isOpenCreateTaskDrawer.value = true;
      });
    }
  }
};

const initCalendarView = () => {
  let calendarEl = document.getElementById('task-calendar-view-id');
  calendarInstance.value = new Calendar(calendarEl, {
    locale: appStore().language,
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    editable: true,
    droppable: true,
    resources: [
      // your resource list
    ],
    events: [],
    dayMaxEventRows: 3,
    fixedWeekCount: false,
    firstDay: 1,
    // dropAccept: '.task-calendar-view-item_accept-drop',
    // eventAllow: function (dropInfo, draggedEvent) {
    //   if (
    //     !draggedEvent?.extendedProps ||
    //     !draggedEvent?.extendedProps ||
    //     !draggedEvent?.extendedProps?.id
    //   )
    //     return false;

    //   return new TaskDetailClass(
    //     draggedEvent?.extendedProps
    //   ).canTaskChangeDeadline();
    // },
    eventContent: function (arg) {
      const extendedProps = arg.event.extendedProps;
      const itemEvent = renderTaskEventComponent(
        extendedProps as TaskCalendarViewClass,
        props.sourceType
      );

      itemEvent.style.width = '100%';
      // itemEvent.style.marginBottom = '0.25rem';

      return { domNodes: [itemEvent] };
    },
    eventClick: function (info) {
      openTaskDetail(info.event.extendedProps);
    },
    eventDrop: function (info) {
      const draggedEvent = info?.event;
      const canChange = new TaskDetailClass(
        draggedEvent?.extendedProps
      ).canTaskChangeDeadline();
      if (!canChange) {
        updateEventSources();
        return;
      }

      const allEvents = calendarInstance.value.getEvents();
      const currentEventList = allEvents?.filter(
        (event) => event?.id == draggedEvent?.id
      );

      if (
        currentEventList?.length > 1 &&
        draggedEvent?.remove &&
        typeof draggedEvent?.remove == 'function'
      )
        draggedEvent?.remove();

      changeStartEndTime(draggedEvent);
    },
    eventResize: function (info) {
      const event = info?.event;
      const canChange = new TaskDetailClass(
        event?.extendedProps
      ).canTaskChangeDeadline();
      if (!canChange) {
        if (info?.revert) info?.revert();
        return;
      }
      changeStartEndTime(info?.event);
    },
    eventReceive: async function (info) {
      const event = info?.event;
      const canChange = new TaskDetailClass(
        event?.extendedProps
      ).canTaskChangeDeadline();
      if (!canChange) {
        if (info?.revert) info?.revert();
        return;
      }

      await changeStartEndTime(event);

      if (event.remove && typeof event.remove == 'function') event.remove();

      isOpenDrawer.value = true;
    },
    eventDragStart: function () {
      isOpenDrawer.value = false;
    },
    dateClick: function (info) {
      console.log('🚀 Tictop ~ info:', info);
      // change to day mode
    },
    datesSet: function (dateInfo) {
      embedAddButton(dateInfo?.start, dateInfo?.end);
    },
    eventDragStop: function (dateInfo) {
      console.log('🚀 Tictop ~ dateInfo:', dateInfo);
    },
  });
  calendarInstance.value.render();
  viewTitle.value = calendarInstance.value?.currentData?.viewTitle;

  isLoadingChart.value = false;

  updateEventSources();
};

const columnList = ref<any>([]);
const { onListenFirebase, onListenMultiTask } = taskListRealtime();

const getFirebaseKey = () => {
  if (props.sourceType == ETaskListModule.DOMAIN) return 'domain';

  if (props.sourceType == ETaskListModule.GROUP) return 'group';

  return 'user';
};

onMounted(async () => {
  onListenFirebase(getFirebaseKey(), props.ownerId, () => {
    updateEventSources();
  });
  onListenMultiTask(getFirebaseKey(), props.ownerId, () => {
    updateEventSources();
  });

  columnList.value = Object.values(
    localConfigs.value['task']?.ganttChartColumn || GANTT_TABLE_COLUMN_LIST
  );

  const ownerId = parseInt(props.ownerId?.toString());
  _taskListStore.currentOwner = {
    id: ownerId,
    type: props.sourceType,
  };
  _taskListStore.initFilterPayload(ownerId, props.sourceType);

  await _taskListStore.fetchTasks(false);

  if (!taskCalendarEvents.value) {
    isLoadingChart.value = false;
    return;
  }

  initCalendarView();
});

const updateTaskInChart = () => {
  if (!taskCalendarEvents.value || isLoading.value || isLoadingChart.value)
    return;

  initCalendarView();
};

const currentViewMode = ref<string>(FULL_CALENDAR_VIEW_MODE.DAY_GRID_MONTH);

const viewModeList = computed<any>(() => {
  return Object.values(VIEW_MODE_LIST)?.filter(
    (item) => item?.key !== currentViewMode.value
  );
});
const onChangeViewMode = (viewMode) => {
  if (!calendarInstance.value) return;
  currentViewMode.value = viewMode;
  // ganttChartDetail.value.change_view_mode(viewMode);

  const today = dayjs().toDate();

  calendarInstance.value.setOption(
    'dayMaxEventRows',
    viewMode == FULL_CALENDAR_VIEW_MODE.DAY_GRID_MONTH ? 3 : false
  );

  calendarInstance.value.changeView(viewMode, today);

  viewTitle.value = calendarInstance.value?.currentData?.viewTitle;
};

const viewTitle = ref<string>('');
const onNext = () => {
  calendarInstance.value.next();
  viewTitle.value = calendarInstance.value?.currentData?.viewTitle;
};

const onPrevious = () => {
  calendarInstance.value.prev();
  viewTitle.value = calendarInstance.value?.currentData?.viewTitle;
};

const gotoToday = () => {
  calendarInstance.value.today();
  viewTitle.value = calendarInstance.value?.currentData?.viewTitle;
};
const gotoFirstData = () => {
  if (taskCalendarEvents.value?.length > 0) {
    const firstEvent = taskCalendarEvents.value[0];
    if (firstEvent?.scheduleTime) {
      calendarInstance.value.gotoDate(firstEvent?.scheduleTime);
      viewTitle.value = calendarInstance.value?.currentData?.viewTitle;
    }
  }
};

const isShowWeekends = ref<any>(true);

const toggleShowWeekends = () => {
  calendarInstance.value.setOption(
    'hiddenDays',
    isShowWeekends.value ? [] : [0]
  );
};
</script>

<template>
  <div
    class="flex-1 min-h-0 w-full h-full flex flex-col relative gap-2"
    @click="isOpenDrawer = false"
  >
    <div
      v-if="isLoadingChart"
      class="w-full h-full z-100 absolute top-0 left-0 cursor-wait"
    ></div>
    <div class="flex items-center justify-between px-4">
      <div class="flex-center gap-2">
        <span class="capitalize font-semibold text-current-600">
          {{ viewTitle }}
        </span>
        <span
          class="
            h-8
            w-8
            hover:bg-current-50
            cursor-pointer
            rounded-full
            flex-center
          "
          @click="onPrevious"
        >
          <SynIcon name="previous" custom-class="h-3 w-3" />
        </span>
        <span
          class="
            h-8
            w-8
            hover:bg-current-50
            cursor-pointer
            rounded-full
            flex-center
          "
          @click="onNext"
        >
          <SynIcon name="next" custom-class="h-3 w-3" />
        </span>
      </div>
      <div class="flex-center space-x-2">
        <span
          class="
            h-7
            flex-center
            px-2
            text-xs
            border
            rounded
            cursor-pointer
            hover:text-current-500
          "
          @click="gotoToday"
        >
          <span>
            {{ $t('COMMON_LABEL_TODAY') }}
          </span>
        </span>

        <VigDropdown
          placement="bottom-start"
          :arrow="false"
          class="hover:text-current bg-white shadow-none"
        >
          <template #dropdown-toggle>
            <div
              class="
                h-7
                flex-center
                border
                rounded
                cursor-pointer
                hover:text-current-500
              "
            >
              <span class="px-2 text-xs">
                {{ $t(VIEW_MODE_LIST[currentViewMode].nameCode) }}
              </span>
            </div>
          </template>
          <template #dropdown-menu>
            <SynDropdownMenu class="right-0 min-w-[11rem]">
              <div
                v-for="view in viewModeList"
                :key="view.key"
                class="dropdown-item list-li"
                @click="onChangeViewMode(view.key)"
              >
                <div class="flex items-center">
                  <span class="pl-2">
                    {{ $t(view.nameCode) }}
                  </span>
                </div>
              </div>
              <div class="dropdown-item list-li">
                <div class="flex items-center justify-between w-full">
                  <span class="pl-2">
                    {{ $t('TASK_LABEL_SHOW_WEEKENDS') }}
                  </span>
                  <AtomSwitch
                    v-model="isShowWeekends"
                    size="small"
                    @change="toggleShowWeekends"
                  />
                </div>
              </div>
            </SynDropdownMenu>
          </template>
        </VigDropdown>
        <div class="flex-center">
          <div class="h-5 border-l-2"></div>
        </div>

        <span
          class="
            text-xs
            flex-center
            space-x-1
            rounded
            py-1
            px-2
            border
            text-end
            cursor-pointer
            hover:text-current-500
          "
          @click="gotoFirstData"
        >
          {{
            `${$t('COMMON_LABEL_TOTAL')} : ${taskCalendarEvents?.length || 0}`
          }}
        </span>
        <div
          class="
            h-7
            flex-center
            border
            rounded
            cursor-pointer
            hover:text-current-500
          "
          @click.stop="isOpenDrawer = !isOpenDrawer"
        >
          <span class="px-2 text-xs">
            {{ $t('COMMON_LABEL_UNSCHEDULED') }}
            <span v-if="unscheduleTasks?.length > 0">
              : {{ unscheduleTasks?.length }}
            </span>
          </span>
        </div>
      </div>
    </div>

    <div
      v-if="!taskCalendarEvents"
      class="
        absolute
        top-0
        left-0
        w-full
        h-full
        flex
        items-center
        flex-col
        pt-24
      "
    >
      <VigImage
        :path="`https://d1tvqetrcurhzb.cloudfront.net/common/images/all-task-mylist.png`"
        :custom-class="`h-40 w-auto`"
      />
      <span
        class="text-current-700 pt-8"
        style="font-size: 1.15rem"
        v-html="$t('TASK_LIST_DONT_HAVE_ALL_TASK_TITLE')"
      ></span>
    </div>

    <div
      id="task-calendar-view-id"
      class="w-full h-full relative overflow-x-auto overflow-y-hidden"
    ></div>
    <UnscheduleTasksDrawer :unschedule-tasks="unscheduleTasks" />
  </div>
</template>
<style lang="scss">
.date-td {
  @apply relative;
}
.my-custom-button {
  display: none;
  @apply absolute bottom-1 right-1 h-6 w-6 justify-center
    items-center rounded-full bg-current text-white z-10 pb-0.5;
}

.date-td:hover .my-custom-button {
  display: flex;
}
</style>
