import { computed } from 'vue';
import { useRoute } from 'vue-router';
import chatStore from '@/store/chat';
import notificationStore from '@/store/notification/notification.store';
import userStore from '@/store/user';
import myProfileStore from '@/store/auth/my-profile';
import UserDocModel from '@/ui/plugins/firebases/firestore-database/models/UserDocModel';
import UserConversationCollectionModel from '@/ui/plugins/firebases/firestore-database/models/UserConversationCollectionModel';
import { activeAndFocusOnConversationId } from '@/ui/modules/messaging/chat-widget/chat-widget-public-state';
import { chain } from 'lodash';
import {
    checkAutoShowChatWidget,
    checkShowChatWidget,
} from '@/ui/hooks/system-config/chat.system-config';

export default function initChatStore() {
    const DELAY_UPDATE_CACHE = 3000;
    const MAX_DELAY_UPDATE_CACHE = 30000;

    const _useRoute = useRoute();
    const _chatStore = chatStore();
    const _notificationStore = notificationStore();
    const _userStore = userStore();

    const myProfile = computed(() => myProfileStore().myProfile);
    const userConversations = computed(() => _chatStore.userConversations);

    const _autoNewMsgConversations: {
        [id: string]: { timer: any; startTime: Date | null };
    } = {};

    let _unsubscribeUserConversationInfo,
        _unsubscribeConversations,
        _unSeenConversations,
        _isInitBubbleChat,
        _initBubbleChatTimer;

    const subscribeUserConversationInfo = () => {
        const userId = myProfile?.value?.id;
        const orgId = myProfile?.value?.organizationId;
        if (!orgId || !userId) return;

        _unsubscribeConversations = new UserDocModel(
            userId
        ).subscribleDataChange((data) => {
            _unSeenConversations = data?.unSeenConversations || {};
            const unSeenConverIds = Object.keys(_unSeenConversations).filter(
                (converId) => _unSeenConversations[converId]
            );
            _chatStore.unSeenConversationsCount = unSeenConverIds.length;

            unSeenConverIds.forEach((converId) => {
                _verifyToOpenBubbleChat(
                    converId,
                    userConversations.value[converId]
                );
            });

            _notificationStore.sendNotificationToDesktopApp('');
        });
    };

    const subscribeUserConversations = () => {
        _isInitBubbleChat = false;

        const userId = myProfile?.value?.id;
        const orgId = myProfile?.value?.organizationId;
        if (!orgId || !userId) return;

        _unsubscribeUserConversationInfo = new UserConversationCollectionModel(
            userId
        ).subscribleDataChange((docChanges) => {
            docChanges?.forEach((change) => {
                const conversationId = change.doc.id;
                const conversationDetail = change.doc.data();

                switch (change.type) {
                    case 'added':
                        {
                            _chatStore.addUserConversation(
                                conversationId,
                                conversationDetail
                            );
                            _verifyToOpenBubbleChat(
                                conversationId,
                                conversationDetail
                            );

                            if (
                                activeAndFocusOnConversationId.value !==
                                    conversationId &&
                                conversationDetail?.unSeen > 0
                            ) {
                                _checkToAutoGetNewMessages(
                                    conversationId,
                                    conversationDetail?.lastMessageId
                                );
                            }
                        }
                        break;
                    case 'modified':
                        {
                            _chatStore.updateUserConversation(
                                conversationId,
                                conversationDetail
                            );
                            _verifyToOpenBubbleChat(
                                conversationId,
                                conversationDetail
                            );

                            if (
                                activeAndFocusOnConversationId.value !==
                                    conversationId &&
                                conversationDetail?.unSeen > 0
                            ) {
                                _checkToAutoGetNewMessages(
                                    conversationId,
                                    conversationDetail?.lastMessageId
                                );
                            }
                        }
                        break;
                    case 'removed':
                        {
                            _chatStore.deleteUserConversation(conversationId);
                            // Remove bubble chat
                            _chatStore.removeBubbleChat({ id: conversationId });
                        }
                        break;
                }
            });

            _checkToInitBubbleChats();
        });
    };

    const clearUserConversations = () => {
        _chatStore.clearUserConversations();
        _unsubscribeUserConversationInfo && _unsubscribeUserConversationInfo();
        _unsubscribeConversations && _unsubscribeConversations();
    };

    const _verifyToOpenBubbleChat = (conversationId, conversation) => {
        if (
            !_unSeenConversations ||
            !_unSeenConversations[conversationId] ||
            _useRoute?.path?.includes('/messages') ||
            !checkShowChatWidget()
        ) {
            return;
        }
        if (
            conversation?.unSeen &&
            !conversation?.closed &&
            conversation?.name &&
            _chatStore.bubbleChats?.every((chat) => chat?.id !== conversationId)
        ) {
            _chatStore.addBubbleChat(
                {
                    id: conversationId,
                    name: conversation?.name,
                    avatar: conversation?.avatar,
                },
                false
            );
        }
    };

    const _checkToAutoGetNewMessages = (conversationId, lastMessageId) => {
        const currentTime = new Date();

        const doAction = (conversationId, lastMessageId) => {
            _chatStore
                .autoGetNewMessages(conversationId, lastMessageId)
                .then()
                .catch();
            _autoNewMsgConversations[conversationId].startTime = null;
        };

        // If greater than max delay time, do action
        if (_autoNewMsgConversations[conversationId]?.startTime) {
            const { startTime } = _autoNewMsgConversations[conversationId];

            if (
                startTime &&
                currentTime.getTime() - startTime?.getTime() >
                    MAX_DELAY_UPDATE_CACHE
            ) {
                return doAction(conversationId, lastMessageId);
            }
        }

        // Else, reset timer
        _autoNewMsgConversations[conversationId] =
            _autoNewMsgConversations[conversationId] || {};

        clearTimeout(_autoNewMsgConversations[conversationId].timer);

        _autoNewMsgConversations[conversationId].timer = setTimeout(() => {
            doAction(conversationId, lastMessageId);
        }, DELAY_UPDATE_CACHE);

        _autoNewMsgConversations[conversationId].startTime = currentTime;
    };

    // Auto add 2 bubbles chat on page load
    const _checkToInitBubbleChats = () => {
        if (_isInitBubbleChat || !checkAutoShowChatWidget()) return;

        clearTimeout(_initBubbleChatTimer);

        _initBubbleChatTimer = setTimeout(() => {
            _isInitBubbleChat = true;

            const numOfBubbles = _chatStore.bubbleChats?.length || 0;

            if (numOfBubbles >= 2) return;

            const chatContacts: any = _chatStore.getContacts || {};

            const converNotClosed = (conversId) =>
                !userConversations.value[conversId]?.closed &&
                userConversations.value[conversId]?.name;

            const externalChat = (conversId) =>
                userConversations.value[conversId]?.externalChat;

            const supportingChat = (conversId) =>
                userConversations.value[conversId]?.supportingChat;

            const converContactActive = (conversId) =>
                chatContacts[conversId]?.isActive &&
                !chatContacts[conversId]?.isDeactive;

            const notExistedBubble = (conversId) =>
                _chatStore.bubbleChats?.every((chat) => chat?.id !== conversId);

            const newBubbles = chain(_chatStore.userConversationIds)
                .filter(
                    (conversId) =>
                        converNotClosed(conversId) &&
                        (externalChat(conversId) ||
                            supportingChat(conversId) ||
                            converContactActive(conversId)) &&
                        notExistedBubble(conversId)
                )
                .orderBy(
                    [
                        (conversId) =>
                            userConversations.value[conversId]
                                ?.lastMessageTime ||
                            userConversations.value[conversId]?.createdDate ||
                            0,
                    ],
                    ['desc']
                )
                .slice(0, 2 - numOfBubbles)
                .value();

            newBubbles.reverse();
            newBubbles.forEach((conversId) => {
                _chatStore.addBubbleChat(
                    {
                        id: conversId,
                        name: userConversations.value[conversId]?.name,
                        avatar: userConversations.value[conversId]?.avatar,
                    },
                    false
                );
            });

            _chatStore.hasBubbleChatAddNew =
                !_chatStore.bubbleChats?.length &&
                _userStore.allActiveUsers?.some((user) => !user?.isMe);
        }, 500);
    };

    return {
        subscribeUserConversationInfo,
        subscribeUserConversations,
        clearUserConversations,
    };
}
