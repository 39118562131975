<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import {
  DayoffDateSession,
  DayoffExceptionType,
  DayoffExceptionReason,
} from '@/domain/enums/DayoffEnums';
import dayjs from 'dayjs';
import TimePickerSelect from '@/ui/components/calendars/event-attribute/TimePickerSelect.vue';
import DayoffExceptionTypeAtom from '@/ui/components/dayoff/atoms/DayoffExceptionTypeAtom.vue';
import myProfileStore from '@/store/auth/my-profile';
import DateoffExceptionClass from '@/domain/entities/dayoff/DateoffExceptionClass';
import DayoffExceptionReasonAtom from '@/ui/components/dayoff/atoms/DayoffExceptionReasonAtom.vue';

const props = defineProps<{
  startDateModel: number;
  exceptionDataModel: any;
  requestReasonModel: DayoffExceptionReason;
  requestNoteModel: string;
}>();

const emit = defineEmits([
  'update:startDateModel',
  'update:requestReasonModel',
  'update:requestNoteModel',
  'update:exceptionDataModel',
]);
const requestNoteModel = ref(props.requestNoteModel);
const currentDaySession = ref();
const myTimezone = computed(() => myProfileStore().getTimezone);
const exceptionData = ref(new DateoffExceptionClass(props.exceptionDataModel));
const dayoffExceptionReasonList = computed(() => {
  if (exceptionData.value.type === DayoffExceptionType.START_LATE)
    return DayoffExceptionReason;

  return [
    DayoffExceptionReason.SICK,
    DayoffExceptionReason.URGENT_WORK,
    DayoffExceptionReason.OTHERS,
  ];
});
const minSelectedTime = computed(() => {
  const currentDay = new Date(props.startDateModel);
  const diffTimeUtc = (myTimezone.value || 0) * 60 * 60 * 1000;
  return exceptionData.value?.daySession == DayoffDateSession.AFTERNOON
    ? Date.UTC(
        currentDay.getUTCFullYear(),
        currentDay.getUTCMonth(),
        currentDay.getUTCDate(),
        12,
        0,
        0,
        0
      ) - diffTimeUtc
    : 0;
});
const maxSelectedTime = computed(() => {
  const currentDay = new Date(props.startDateModel);
  const diffTimeUtc = (myTimezone.value || 0) * 60 * 60 * 1000;
  return exceptionData.value?.daySession == DayoffDateSession.AFTERNOON
    ? 0
    : Date.UTC(
        currentDay.getUTCFullYear(),
        currentDay.getUTCMonth(),
        currentDay.getUTCDate(),
        12,
        0,
        0,
        0
      ) - diffTimeUtc;
});

const initData = () => {
  updateExceptionTime();
};

const updateExceptionTime = () => {
  const currentDay = new Date(props.startDateModel);
  const currentStartTime = new Date(exceptionData.value.startTime);
  const currentEndTime = new Date(exceptionData.value.endTime);
  exceptionData.value.startTime = Date.UTC(
    currentDay.getUTCFullYear(),
    currentDay.getUTCMonth(),
    currentDay.getUTCDate(),
    currentStartTime.getUTCHours(),
    currentStartTime.getUTCMinutes(),
    0,
    0
  );
  exceptionData.value.endTime = Date.UTC(
    currentDay.getUTCFullYear(),
    currentDay.getUTCMonth(),
    currentDay.getUTCDate(),
    currentEndTime.getUTCHours(),
    currentEndTime.getUTCMinutes(),
    0,
    0
  );
};

const onChangeExceptionType = () => {
  emit('update:exceptionDataModel', exceptionData.value);
};

const onChangeDayoffRange = (timestamp) => {
  emit('update:startDateModel', timestamp);
  updateExceptionTime();
};

const onChangeExceptionSession = (session) => {
  if (currentDaySession.value == session) return;
  emit('update:exceptionDataModel', exceptionData.value);
  currentDaySession.value = session;
  const offset =
    session === DayoffDateSession.MORNING
      ? 12 * 60 * 60 * 1000
      : -12 * 60 * 60 * 1000;

  exceptionData.value.startTime += offset;

  if (exceptionData.value.type === DayoffExceptionType.BREAK_TIME) {
    exceptionData.value.endTime += offset;
  }
};

onMounted(() => {
  currentDaySession.value = exceptionData.value.daySession;
});

initData();
</script>
<template>
  <!-- Select date -->
  <div
    class="
      flex
      items-center
      justify-between
      space-x-2
      rounded
      p-2
      bg-current-50
    "
  >
    <div class="flex flex-col space-y-2">
      <div class="flex items-center space-x-2">
        <SynRadioTag
          v-for="(exceptionType, index) in DayoffExceptionType"
          :key="index"
          v-model="exceptionData.type"
          :value="exceptionType"
          radio-name="dayoff-create-form_reason"
          custom-class="rounded"
          custom-active-class="bg-current-50"
          custom-inactive-class="opacity-70"
          @change="onChangeExceptionType"
        >
          <DayoffExceptionTypeAtom
            :is-active="exceptionData.type == exceptionType"
            :exception-type="exceptionType"
          />
        </SynRadioTag>
      </div>
      <div class="flex items-center space-x-2">
        <v-date-picker
          :model-value="startDateModel"
          :locale="$locale"
          :model-config="{ type: 'number' }"
          :masks="{ input: 'DD-MM-YYYY' }"
          :popover="{ visibility: 'click' }"
          color="current"
          :attributes="[
            {
              key: 'today',
              dot: {
                color:
                  dayjs(new Date()).format('MM-DD-YYYY') ==
                  dayjs(startDateModel).format('MM-DD-YYYY')
                    ? 'yellow'
                    : 'current',
                class:
                  dayjs(new Date()).format('MM-DD-YYYY') ==
                  dayjs(startDateModel).format('MM-DD-YYYY')
                    ? 'mb-1 z-70'
                    : '',
              },
              dates: new Date(),
            },
          ]"
          :first-day-of-week="2"
          @update:model-value="onChangeDayoffRange"
        >
          <template #default="{ inputValue, inputEvents }">
            <div class="flex justify-start items-center space-x-2">
              <div class="flex flex-col space-y-2">
                <input
                  :value="inputValue"
                  class="
                    w-full
                    border
                    px-2
                    py-1
                    rounded
                    focus:outline-none focus:border-current
                  "
                  v-on="inputEvents"
                />
              </div>
            </div>
          </template>
        </v-date-picker>
        <AtomLabelSwitchSimple
          v-model="exceptionData.daySession"
          :left-key="DayoffDateSession.MORNING"
          :right-key="DayoffDateSession.AFTERNOON"
          @change="onChangeExceptionSession"
        >
          <template #left>AM</template>
          <template #right>PM</template>
        </AtomLabelSwitchSimple>

        <div class="bg-white">
          <TimePickerSelect
            v-model="exceptionData.startTime"
            :current-date="startDateModel"
            :step="5"
            :min-time="minSelectedTime"
            :max-time="
              exceptionData.type === DayoffExceptionType.BREAK_TIME
                ? Math.min(exceptionData.endTime, maxSelectedTime)
                : maxSelectedTime
            "
            :timezone="myTimezone"
            @update:model-value="
              (value) =>
                $emit('update:exceptionDataModel', {
                  ...exceptionData,
                  startTime: value,
                })
            "
          />
        </div>
        <template v-if="exceptionData.type == DayoffExceptionType.BREAK_TIME">
          <span> ~ </span>
          <div class="bg-white">
            <TimePickerSelect
              v-model="exceptionData.endTime"
              :current-date="startDateModel"
              :step="5"
              :min-time="
                exceptionData.type === DayoffExceptionType.BREAK_TIME
                  ? Math.max(exceptionData.startTime, minSelectedTime)
                  : minSelectedTime
              "
              :max-time="maxSelectedTime"
              :timezone="myTimezone"
              @update:model-value="
                (value) =>
                  $emit('update:exceptionDataModel', {
                    ...exceptionData,
                    endTime: value,
                  })
              "
            />
          </div>
        </template>
      </div>
    </div>
  </div>

  <!-- REASON -->
  <div class="flex flex-col space-y-2">
    <!-- reason type -->
    <div class="flex space-x-2">
      <SynRadioTag
        v-for="reason in dayoffExceptionReasonList"
        :key="reason"
        :model-value="requestReasonModel"
        :value="reason"
        radio-name="dayoff-create-form_reason"
        custom-class="rounded"
        custom-active-class="bg-current-50"
        custom-inactive-class="opacity-70"
        @update:model-value="
          (value) => $emit('update:requestReasonModel', value)
        "
      >
        <DayoffExceptionReasonAtom
          :is-active="requestReasonModel === reason"
          :reason="reason"
        />
      </SynRadioTag>
    </div>
    <!-- reason note -->
    <div>
      <SynInputArea
        v-model="requestNoteModel"
        input-type="text"
        :placeholder="
          $t('DAYOFF_CREATE_FORM_NOTE_PLACEHOLDER') ||
          'Note more detail in here...'
        "
        @update:model-value="(value) => $emit('update:requestNoteModel', value)"
      />
    </div>
  </div>
</template>
