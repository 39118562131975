<script setup>
import { onMounted, ref } from 'vue';
import fileStorageStore from '@/store/fileStorage';
import FileCard from '../files-list/file-card/FileCard.vue';
import FileRow from '../files-list/file-row/FileRow.vue';
// import ModalFileViewerWithInfo from '../modal-file-viewer/ModalFileViewerWithInfo';
import FilesFilter from '@/ui/modules/ged/filter/button-filter/FilesFilter.vue';
import axios from 'axios';
import { saveAs } from 'file-saver';
import AlertModal from '@/ui/components/modals/AlertModal.vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { copyToClipBroard } from '@/ui/hooks/commonFunction';
import $constants from '@/ui/plugins/constants';
import FileDetailPanel from '@/ui/modules/ged/file-detail-panel/FileDetailPanel.vue';
import ModalMultipleFileViewerInfo from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewerInfo.vue';
import SynModalNoFocus from '@/ui/common/molecules/SynModal/SynModalNoFocus.vue';
import SynTh from '@/ui/common/molecules/SynTableCustom/SynTh.vue';
import SynTabs from '@/ui/common/molecules/SynTab/SynTabs.vue';
import ModalSaveToResource from '@/ui/modules/ged/folder/modal-save-to-resource/ModalSaveToResource.vue';
import FileSearchItem from '@/ui/modules/ged/files-list/file-search-item/FileSearchItem.vue';
import FileStorageService from '@/application/services/FileStorageService';

const props = defineProps({
  filterConditions: {
    type: Object,
    default: null,
  },
});

defineEmits(['onClose', 'onFileInfoClick']);

const _fileStorageStore = fileStorageStore();

const fileFilters = ref({});
const filesList = ref();
const filesShown = ref();
const filesTotal = ref();
const fileShowViewer = ref();
const fileShowDetail = ref();
const isViewByList = ref();
const isLoading = ref();
const canShowMore = ref();
const alertMessage = ref();
const indexCurrentFile = ref(0);
const saveToResource = ref(false);
const saveSelectedFile = ref({});
const currentTab = ref('FILES');
const typeFiles = ref({
  FILES: {
    key: 'FILES',
    label: 'GED_FILTER_FILES',
    isActive: true,
  },
  IMAGE: {
    key: 'IMAGE',
    label: 'GED_FILTER_IMAGE',
    isActive: false,
  },
  VIDEO: {
    key: 'VIDEO',
    label: 'GED_FILTER_VIDEO',
    isActive: false,
  },
  DOCUMENT: {
    key: 'DOCUMENT',
    label: 'GED_FILTER_DOCUMENT',
    isActive: false,
  },
  AUDIO: {
    key: 'AUDIO',
    label: 'GED_FILTER_AUDIO',
    isActive: false,
  },
  LINK: {
    key: 'LINK',
    label: 'GED_FILTER_LINK',
    isActive: false,
  },
});
const orderBy = ref(null);
const sortBy = ref(null);

onMounted(() => {
  fileFilters.value = { ...fileFilters.value, ...props.filterConditions };
  _getFiles();
});

const changeTab = (key) => {
  typeFiles.value[currentTab.value].isActive = false;
  typeFiles.value[key].isActive = true;
  currentTab.value = key;

  switch (key) {
    case 'FILES':
      onChangeTypeFile({
        fileTypes: ['FILES'],
      });
      break;
    case 'AUDIO':
      onChangeTypeFile({
        fileTypes: ['AUDIO'],
      });
      break;
    case 'VIDEO':
      onChangeTypeFile({
        fileTypes: ['VIDEO'],
      });
      break;
    case 'IMAGE':
      onChangeTypeFile({
        fileTypes: ['IMAGE'],
      });
      break;
    case 'DOCUMENT':
      onChangeTypeFile({
        fileTypes: ['DOCUMENT'],
      });
      break;
    case 'LINK':
      onChangeTypeFile({
        fileTypes: ['LINK'],
      });
      break;
    default:
      break;
  }
};

// const sortField = {
//   sortBy: null,
//   orderBy: null
// }

// const objectSortName = {
//   name: {
//     sortName: 'name',
//     orderBy: null,
//   },
//   date: {
//     sortName: 'creation_time',
//     orderBy: null,
//   },
//   size: {
//     sortName: 'size',
//     orderBy: null,
//   },
// };

const checkOrderBy = (order, sortName) => {
  switch (order) {
    case null:
      sortBy.value = sortName;
      orderBy.value = 'asc';
      _getFiles();
      break;
    case 'asc':
      sortBy.value = sortName;
      orderBy.value = 'desc';
      _getFiles();
      break;
    case 'desc':
      sortBy.value = null;
      orderBy.value = null;
      _getFiles();
      break;
    default:
      break;
  }
};

const onSortFiles = (sortName, order) => {
  if (sortBy.value !== sortName) {
    sortBy.value = sortName;
    orderBy.value = null;
    checkOrderBy(orderBy.value, sortBy.value);
  } else {
    checkOrderBy(order, sortName);
  }
};

const onChangeTypeFile = async (filter, refresh = true) => {
  orderBy.value = null;
  sortBy.value = null;
  const params = {
    ...fileFilters.value,
    ...filter,
    pageSize: 50,
    sortBy: sortBy.value,
    orderBy: orderBy.value,
    pageIndex: refresh ? 0 : filesList.value?.length,
  };

  const { files, total } = await _fileStorageStore.searchFiles(params);

  if (refresh) {
    filesList.value = files;
  } else {
    filesList.value = filesList.value
      ?.concat(files || [])
      .filter(
        (file, index, arr) =>
          arr.findIndex((item) => item?.id === file?.id) === index
      );
  }

  filesTotal.value = total;
  filesShown.value = filesList.value?.length;
  canShowMore.value = filesList.value?.length < total;
};

const onFilterApply = (filter) => {
  fileFilters.value = { ...fileFilters.value, ...filter };
  _getFiles();
};

const onLoadMore = () => {
  if (canShowMore.value && !isLoading.value) _getFiles(false);
};

const onFileCopyLinkClick = (file) => {
  copyToClipBroard(file?.link || file?.pathUrl);

  alertMessage.value = {
    title: translate('GED_FILE_COPY_MSG_TITLE'),
    content: translate('GED_FILE_COPY_MSG_CONTENT'),
  };
};

const onFileClick = (file) => {
  if (file?.contentType === $constants.FILE.TYPE.LINK) {
    window.open(file?.link, '_blank').focus();
  } else {
    fileShowViewer.value = file;
    indexCurrentFile.value = filesList.value.findIndex(
      (f) => f?.id === file?.id
    );
    if (indexCurrentFile.value === -1) indexCurrentFile.value = null;
  }
};

const onFileDownloadClick = (file) => {
  return axios
    .get(file?.pathUrl, {
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    })
    .then((res) => {
      saveAs(res.data, file?.name);
    });
};

const onSaveToResourceClick = (file) => {
  saveToResource.value = true;
  saveSelectedFile.value = file;
};

const onFinishSaveToResourceEvent = () => {
  saveToResource.value = false;
  saveSelectedFile.value = {};
};

const onFileDetailRenamed = (renamedFile) => {
  if (fileShowDetail.value) {
    fileShowDetail.value.displayName = renamedFile?.name;
  }
};

const onGetSuggestions = async (searchText) => {
  if (!searchText?.trim()) return [];

  const params = {
    ...fileFilters.value,
    keyword: searchText?.trim(),
    fileTypes: [currentTab.value],
    sortBy: 'name',
    orderBy: 'asc',
    pageSize: 10,
    pageIndex: 0,
  };

  const res = await FileStorageService.getInstance().getFiles(params);

  return res?.result?.files;
};

const onSearchItemSelect = async (item) => {
  await _getFiles();

  if (item?.id) onFileClick(item);
};

const _getFiles = async (refresh = true) => {
  const params = {
    ...fileFilters.value,
    fileTypes: [currentTab.value],
    pageSize: 50,
    sortBy: sortBy.value,
    orderBy: orderBy.value,
    pageIndex: refresh ? 0 : filesList.value?.length,
  };

  const { files, total } = await _fileStorageStore.searchFiles(params);

  if (refresh) {
    filesList.value = files;
  } else {
    filesList.value = filesList.value
      ?.concat(files || [])
      .filter(
        (file, index, arr) =>
          arr.findIndex((item) => item?.id === file?.id) === index
      );
  }

  filesTotal.value = total;
  filesShown.value = filesList.value?.length;
  canShowMore.value = filesList.value?.length < total;
};

const onChangeViewMode = () => {
  isViewByList.value = !isViewByList.value;
};
</script>

<template>
  <SynModalNoFocus
    is-hidden-footer
    is-hidden-header
    disable-element-active
    z-index="z-40"
    container-class="w-11/12 2xl:w-9/12"
    container-style="height: 95vh; max-height:unset"
    style-body="pt-4 flex flex-col relative"
    @cancel="$emit('onClose')"
  >
    <template #body>
      <SynTabs
        :option-status="typeFiles"
        is-header
        positon="center"
        class="overflow-hidden pb-4"
        style-tab="width: 100%; padding: 0rem 2rem;"
        custom-header-class="flex-1"
        @change-tab="(key) => changeTab(key)"
      >
        <template #header>
          <span class="font-medium text-xl text-gray-600">{{
            $t('SIDEBAR_LABEL_RESOURCES')
          }}</span>
        </template>
        <template #prefix>
          <div class="w-max flex items-center justify-end space-x-2">
            <div class="w-72 max-w-xs">
              <VigTypeahead
                v-model:search-text="fileFilters.keyword"
                input-class="text-sm"
                placement="bottom-end"
                :placeholder="$t('GED_SEARCH_LABEL')"
                :get-suggestions-fn="onGetSuggestions"
                @on-item-select="onSearchItemSelect"
              >
                <template #item="slotProps">
                  <FileSearchItem :file="slotProps?.item" />
                </template>
              </VigTypeahead>
            </div>
            <FilesFilter
              :filter="fileFilters"
              :is-filter-type="false"
              @on-apply-filter="onFilterApply"
            />
            <vig-button
              v-vig-tooltip="
                $t(
                  isViewByList
                    ? 'COMMON_LABEL_VIEW_BY_GRID'
                    : 'COMMON_LABEL_VIEW_BY_LIST'
                )
              "
              ghost
              color="gray"
              padding="p-0"
              rounded="rounded"
              class="w-9 h-9"
              @click="onChangeViewMode"
            >
              <SynIcon
                :name="isViewByList ? 'Grid2' : 'List'"
                custom-class="w-5 h-5"
              />
            </vig-button>
          </div>
        </template>
        <template #tabBody>
          <div class="h-full flex">
            <div class="flex-1 overflow-auto flex flex-col pt-3">
              <!--TOTAL FILES-->
              <div v-if="filesShown" class="px-3 mb-1 text-sm text-gray-500">
                {{
                  $t('GED_FILE_PER_TOTAL', {
                    file: filesShown,
                    total: filesTotal,
                  }) || `${filesShown}/${filesTotal} file(s)`
                }}
              </div>
              <!--FILES LIST-->
              <div class="flex-1 overflow-auto">
                <div
                  ref="filesListRef"
                  v-scroll-infinite="onLoadMore"
                  class="h-full overflow-auto small-scrollbar px-3 pb-10"
                >
                  <div
                    v-if="!isViewByList"
                    class="grid auto-rows-max gap-3"
                    :style="`grid-template-columns: repeat(
                        auto-fill,
                        minmax(8rem, 1fr)
                      );`"
                  >
                    <FileCard
                      v-for="file in filesList"
                      :key="file"
                      :file="file"
                      :show-checkbox="false"
                      @on-file-click="onFileClick(file)"
                      @on-view-info-click="fileShowDetail = file"
                      @on-copy-link-click="onFileCopyLinkClick(file)"
                      @on-download-click="onFileDownloadClick(file)"
                      @on-save-to-resource-click="onSaveToResourceClick(file)"
                    />
                  </div>

                  <table
                    v-if="isViewByList && filesList?.length > 0"
                    class="w-full table-fixed"
                  >
                    <thead>
                      <SynTr>
                        <SynTh class="w-16"></SynTh>
                        <SynTh
                          is-sort
                          :label="$t('GED_LINK_NAME')"
                          :order-by="sortBy === 'name' ? orderBy : null"
                          @on-sort="onSortFiles('name', orderBy)"
                        ></SynTh>
                        <SynTh
                          is-sort
                          :label="$t('GED_CREATOR_NAME')"
                          class="w-44"
                        ></SynTh>
                        <SynTh
                          is-sort
                          class="w-44"
                          :label="$t('TASK_EXPORT_COLUMNS_CREATION_DATE')"
                          :order-by="
                            sortBy === 'creation_time' ? orderBy : null
                          "
                          @on-sort="onSortFiles('creation_time', orderBy)"
                        ></SynTh>
                        <SynTh
                          v-if="currentTab !== 'LINK'"
                          is-sort
                          is-right
                          class="w-28"
                          :order-by="sortBy === 'size' ? orderBy : null"
                          :label="$t('COMMON_LABEL_SIZE')"
                          @on-sort="onSortFiles('size', orderBy)"
                        ></SynTh>
                        <SynTh class="w-0"></SynTh>
                        <SynTh class="w-0"></SynTh>
                      </SynTr>
                    </thead>
                    <tbody>
                      <FileRow
                        v-for="file in filesList"
                        :key="file"
                        :file="file"
                        :show-checkbox="false"
                        :filter="fileFilters"
                        @on-file-click="onFileClick(file)"
                        @on-view-info-click="fileShowDetail = file"
                        @on-copy-link-click="onFileCopyLinkClick(file)"
                        @on-download-click="onFileDownloadClick(file)"
                        @on-save-to-resource-click="onSaveToResourceClick(file)"
                      />
                    </tbody>
                  </table>

                  <div v-if="isLoading" class="px-3 py-2 flex-center">
                    <SynIcon
                      name="Spinner"
                      class="w-5 h-5 fill-gray animate-spin"
                    />
                  </div>

                  <div
                    v-if="!isLoading && !filesList?.length"
                    class="px-3 py-2 h-full w-full flex-center flex-col"
                  >
                    <syn-animation
                      name="no-ticket-data"
                      stype="width: 240px; height: 240px;"
                    ></syn-animation>
                    <em class="text-gray-600">{{
                      $t('GED_NO_FILE_MSG') || 'There is no file'
                    }}</em>
                  </div>

                  <div
                    v-if="!isLoading && canShowMore"
                    class="px-3 py-2 mt-2 flex-center"
                  >
                    <button
                      class="
                        px-3
                        py-1
                        rounded-full
                        bg-gray-200
                        hover:bg-gray-300
                      "
                      @click="onLoadMore"
                    >
                      {{ $t('GED_SHOW_MORE') || 'Show more' }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!--FILE INFO-->
            <div v-if="fileShowDetail" class="w-80 h-full border-l shadow">
              <FileDetailPanel
                :file="fileShowDetail"
                @on-close-click="fileShowDetail = null"
                @on-rename="onFileDetailRenamed"
              />
            </div>
          </div>
        </template>
      </SynTabs>
    </template>
  </SynModalNoFocus>

  <ModalMultipleFileViewerInfo
    v-if="fileShowViewer && indexCurrentFile !== null"
    :start-index="indexCurrentFile"
    :files="filesList"
    :editable="false"
    @on-close="fileShowViewer = null"
    @on-info-click="
      $emit('onFileInfoClick', fileShowViewer);
      fileShowViewer = null;
    "
  >
  </ModalMultipleFileViewerInfo>

  <AlertModal
    v-if="alertMessage"
    :title-text="alertMessage?.title"
    :sub-title-text="alertMessage?.content"
    name-icon="warning"
    container-class="w-5/6 md:w-4/6 lg:w-3/6 xl:w-2/6"
    @cancel="alertMessage = null"
  />

  <ModalSaveToResource
    v-if="saveToResource"
    :file="saveSelectedFile"
    @on-close="onFinishSaveToResourceEvent()"
  />
</template>
