import { remoteConfig } from '@/ui/plugins/firebases/init-app';
import { getValue } from 'firebase/remote-config';

export const RemoteConfigKey = {
    EncodeUserId: 'web_encode_user_id',
    EncodeGroupId: 'web_encode_group_id',
    PublicEncryptKey: 'web_public_encrypt_key',
    ConfigAttachmentInTask: 'web_config_attachment_in_task',
    WebTaskUploadContentTypes: 'web_task_upload_content_types',
    WebTaskUploadMaxFilesize: 'web_task_upload_max_filesize',
    CommonAwsMessagingPathPhoto: 'common_aws_messaging_path_photo',
    CommonAwsMessagingPathFile: 'common_aws_messaging_path_file',
    CommonAwsMessagingPathMedia: 'common_aws_messaging_path_media',
    CommonChatAudioDurationLimit: 'common_chat_audio_duration_limit',
    CommonChatFileAudioSizeLimit: 'common_chat_file_audio_size_limit',
    CommonChatFileImageSizeLimit: 'common_chat_file_image_size_limit',
    CommonChatFileOfficeSizeLimit: 'common_chat_file_office_size_limit',
    CommonChatFileVideoSizeLimit: 'common_chat_file_video_size_limit',
    CommonChatFileSizeLimit: 'common_chat_file_size_limit',
    CommonChatFileQuantityLimit: 'common_chat_file_quantity_limit',
    CommonChatNoteQuantityLimit: 'common_chat_note_quantity_limit',
    WebVideoCallOpenMode: 'web_video_call_open_mode',
    WebGroupTaskPageSize: 'web_group_task_page_size',
    WebUserTaskPageSize: 'web_user_task_page_size',
    WEB_CHAT_UPLOAD_CONTENT_TYPES: 'web_chat_upload_content_types',
    WEB_CHAT_UPLOAD_MAX_FILESIZE: 'web_chat_upload_max_filesize',
    WEB_DOMAIN_CREATE_REALTIME_PERMISSION:
        'web_domain_create_realtime_permission',
    web_organization_plan_show_exceed_warning:
        'web_organization_plan_show_exceed_warning',
    WebShowHelpFunction: 'web_show_help_function',
    WebShowHelpTopVideo: 'web_show_help_top_video',
    WebShowHelpFqa: 'web_show_help_fqa',
    WebShowHelpVideos: 'web_show_help_videos',
    WebShowHelpWhatNew: 'web_show_help_what_new',
    FolderS3Background: 'folder_s3_background',
    payment_offer_start_options: 'payment_offer_start_options',
    workspace_type_select: 'workspace_type_select',
    workspace_type_select_register: 'workspace_type_select_register',
    total_organization: 'total_organization',
    web_task_hidden_create_form_bottom: 'web_task_hidden_create_form_bottom',
};

export const getRemoteConfigValueByKey = (key) => {
    return getValue(remoteConfig, key);
};
