<script setup lang="ts">
import dayjs from 'dayjs';
import DateInput from '@/ui/common/atoms/date-pickers/DateInput.vue';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue: any;
    title?: string;
    placeholder?: string;
    config?: any;
    masks?: any;
    isRange?: boolean;
    minDate?: Date | string | number;
    maxDate?: Date | string | number;
    isDisabled?: boolean;
    autofocus?: boolean;
    isRequired?: boolean;
    customInputClass?: string;
    customClass?: string;
    timezone?: string;
  }>(),
  {
    // config: { type: 'string', mask: 'YYYY/MM/DD' },
    config: {},
    masks: {
      input: 'DD/MM/YYYY',
    },
    timezone: 'utc',
  }
);

defineEmits<{
  (e: 'update:modelValue', value: Date): void;
  (e: 'enter', value: string | number): void;
}>();

const attributes = computed(() => [
  {
    key: 'today',
    highlight: {
      fillMode: dayjs().isSame(props.modelValue, 'day') ? 'solid' : 'outline',
    },
    dates: new Date(),
  },
]);
</script>
<template>
  <v-date-picker
    :model-value="modelValue"
    :locale="$locale"
    :attributes="attributes"
    :model-config="config"
    color="current"
    :timezone="timezone"
    :min-date="minDate"
    :max-date="maxDate"
    :popover="{ visibility: 'focus' }"
    :is-range="isRange"
    :masks="masks"
    :first-day-of-week="2"
    @update:model-value="(value) => $emit('update:modelValue', value)"
  >
    <template #default="{ inputValue, inputEvents }">
      <div v-if="isRange" class="flex justify-center items-center space-x-2">
        <div class="flex flex-col">
          <span class="text-xs text-gray-800">
            {{ $t('COMMON_LABEL_FROM_DATE') || 'From date' }}:
          </span>
          <DateInput
            :model-value="inputValue.start"
            :autofocus="autofocus"
            :input-events="inputEvents.start"
            :is-disabled="isDisabled"
            :custom-class="`${customInputClass} w-full rounded`"
            @enter="(value) => $emit('enter', value)"
          />
        </div>
        <div class="flex flex-col">
          <span class="text-xs text-gray-800">
            {{ $t('COMMON_LABEL_TO_DATE') || 'To date' }}:
          </span>
          <DateInput
            :model-value="inputValue.end"
            :input-events="inputEvents.end"
            :is-disabled="isDisabled"
            :autofocus="autofocus"
            :custom-class="`${customInputClass} w-full rounded`"
            @enter="(value) => $emit('enter', value)"
          />
        </div>
      </div>
      <div v-else class="relative border rounded" :class="customClass">
        <div class="flex flex-col">
          <span v-if="title" class="text-xs text-gray-800"> {{ title }}: </span>
          <DateInput
            :model-value="inputValue"
            :placeholder="placeholder"
            :input-events="inputEvents"
            :min-date="minDate"
            :max-date="maxDate"
            :is-disabled="isDisabled"
            :is-required="isRequired"
            :autofocus="autofocus"
            :custom-class="`${customInputClass} w-full rounded`"
            @on-clear="$emit('update:modelValue', null)"
            @enter="(value) => $emit('enter', value)"
          />
        </div>
      </div>
    </template>
  </v-date-picker>
</template>

<style>
.vc-current {
  --vc-accent-50: #e0f1f2;
  --vc-accent-100: #b1dcde;
  --vc-accent-200: #e0f1f2; /*#7fc6c9;*/
  --vc-accent-300: #61bbbd;
  --vc-accent-400: #008d8d;
  --vc-accent-500: #008e8e;
  --vc-accent-600: #4bb0b2; /*#018180;*/
  --vc-accent-700: #037270;
  --vc-accent-800: #076260;
  --vc-accent-900: #094642;
}
</style>
