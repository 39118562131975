<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import UserById from '@/ui/components/user/UserById.vue';
import ChangeAssign from '@/ui/modules/task/detail/ChangeAssign.vue';
import { getConfigScheduleColor } from '@/ui/hooks/taskHook';
import taskStore from '@/store/task';
import GroupById from '@/ui/components/group/GroupById.vue';
import DeadlineInput from '@/ui/modules/task/components/deadline/DeadlineInput.vue';
import domainStore from '@/store/scope';
import ModalNewChat from '@/ui/modules/messaging/modal-new-chat/ModalNewChat.vue';
import { ScheduleDayOptions, TaskLife } from '@/domain/enums/taskEnum';

import UploadDropZone from '@/ui/modules/ged/upload/upload-drop-zone/UploadDropZone.vue';
import UpdateDomainModal from '@/ui/modules/task/detail/UpdateDomainModal.vue';
import commentLogic from '@/ui/modules/task/general/comment-logic';
import DomainProjectById from '@/ui/modules/domains/component/DomainProjectById.vue';
import UpdateDomain from '@/ui/modules/task/detail/UpdateDomain.vue';
import TaskDescriptionInput from '@/ui/common/plugins/ckeditor/TaskDescriptionInput.vue';
import TaskDetailField from '@/ui/modules/task/components/TaskDetailField.vue';
import chatStore from '@/store/chat';
import ModalDiscussChatTask from '@/ui/modules/messaging/modal-discuss-chat/ModalDiscussChatTask.vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
// import TaskDetailFieldIcon from '@/ui/modules/task/components/TaskDetailFieldIcon.vue';
import { TaskTemplateEntity } from '@/domain/entities/task/TaskTemplateEntity';
import TaskStateStepper from '@/ui/modules/task/components/TaskStateStepper.vue';
import SetPinTask from '@/ui/modules/task/components/pin/SetPinTask.vue';
import AttachmentInput from '@/ui/components/forms/AttachmentInput.vue';
import { ITaskTodo } from '@/application/types/task/task.types';
import { IConfigType } from '@/application/types/task/task-configs.types';
import { translate } from '@/ui/plugins/i18n/myi18n';
import settingStore from '@/store/setting';
import ManageCollaborator from '@/ui/modules/task/detail/assign/ManageCollaborator.vue';
import UpdateDeadlineTime from '@/ui/modules/task/detail/UpdateDeadlineTime.vue';
import myProfileStore from '@/store/auth/my-profile';
// import { IUpdateDeadlineForm } from '@/domain/entities/TaskType';

const props = withDefaults(
  defineProps<{
    isPlaceModal?: boolean;
    isDoNotNeedAddUserInfo?: boolean;
    isHiddenTitle?: boolean;
    isHiddenExactDate?: boolean;
    isUpdateDomainOnly?: boolean;
    taskTemplate?: TaskTemplateEntity;
    elementList?: (
      | 'NAME'
      | 'URGENCY'
      | 'STATE'
      | 'PIN'
      | 'RESPONSIBLE'
      | 'COLLABORATORS'
      | 'DOMAIN'
      | 'PLAN_TODAY'
      | 'DEADLINE'
      | 'DEADLINE_TIME'
      | 'DESCRIPTION'
      | 'ATTACHMENT'
      | 'CHECK_LIST'
    )[];
    viewMode?: 'VIEW' | 'EDIT' | 'ADD';
    isWorkflow?: boolean;
    isHiddenStartTime?: boolean;
  }>(),
  {
    elementList: () => [],
  }
);

const emit = defineEmits<{
  (e: 'update:taskTemplate', data: TaskTemplateEntity): void;
  (e: 'onClose'): void;
}>();

const _taskStore = taskStore();
const _domainStore = domainStore();
const _settingStore = settingStore();
const currentTaskTemplate = ref<TaskTemplateEntity>(
  new TaskTemplateEntity(props.taskTemplate) || ({} as TaskTemplateEntity)
);

const updatePropData = () => {
  emit('update:taskTemplate', currentTaskTemplate.value);
};
const _chatStore = chatStore();

const isLoadingInit = ref(false);
const isAttachmentLoading = ref(false);
const errorCode = ref('');

const taskDescriptionRef = ref<any>(null);
const myCommentValid = ref(true);
const {
  descriptionInputRef,
  isInputComment,
  commentObjectCreate,
  onOpenComment,
  onSaveComment,
  onCancelComment,
  onFocus,
} = commentLogic(
  true,
  (value) => {
    taskDescriptionRef.value?.onInsertText(value);
  },
  props.isDoNotNeedAddUserInfo
);

const isLoadingDataTask = ref({
  detail: false,
  files: false,
  comment: false,
});

const init = async () => {
  try {
    isLoadingInit.value = true;
    isLoadingDataTask.value.detail = true;
    errorCode.value = '';
    onCloseEditName();
    onCloseEditDescription();

    currentTaskTemplate.value = new TaskTemplateEntity(props.taskTemplate);
    isLoadingDataTask.value.detail = false;
    isLoadingInit.value = false;

    isLoadingDataTask.value.files = true;
    // isAttachmentLoading.value = true;
    // await currentTaskTemplate.value.getAttachmentData();
    isLoadingDataTask.value.files = false;
    if (_domainStore.allDomains.length == 0)
      _domainStore.fetchAllDomainProjects();
  } catch (error: any) {
    const code = error?.error?.code;
    isLoadingDataTask.value.detail = false;
    isLoadingDataTask.value.files = false;
    errorCode.value = code
      ? `COMMON_ERROR_BY_CODE_${code}`
      : 'COMMON_ERROR_BY_CODE_6403';
  } finally {
    isLoadingDataTask.value.detail = false;
    isLoadingDataTask.value.files = false;
    isLoadingInit.value = false;
    isAttachmentLoading.value = false;
  }
};

const isEditMode = computed<boolean>(() => {
  return props.viewMode == 'EDIT' || props.viewMode == 'ADD';
});

// Assign

const onOpenChangeAssign = () => {
  if (!isEditMode.value) return;
};

const onClickToBody = () => {
  onCancelAssign();

  onCloseManageCollaborator();
};
const onCancelAssign = () => {
  if (isOpenUpdateDomainModal.value || isEditMode.value) return;
};

const changeAssignPayload = ref({} as any);
const fixConflictDomain = ref({
  domain: false,
  project: false,
  subProject: false,
} as any);

const onChangeAssign = async (group, user, { domain, project, subProject }) => {
  changeAssignPayload.value = {
    groupId: group?.id,
    assigneeId: user?.userId,
  };

  fixConflictDomain.value = { domain, project, subProject };
  if (domain || project || subProject) {
    onOpenUpdateDomainModal();
    return;
  }
  onChangeAssignee();
};

const onChangeAssignee = async () => {
  const isOnlyChangeUser =
    changeAssignPayload.value?.groupId == currentTaskTemplate.value?.groupId &&
    changeAssignPayload.value?.assigneeId !==
      currentTaskTemplate.value?.assigneeId;
  const promiseAll: any[] = [
    currentTaskTemplate.value.changeAssignee(
      changeAssignPayload.value?.groupId,
      changeAssignPayload.value?.assigneeId
    ),
  ];
  if (
    !changeAssignPayload.value?.assigneeId &&
    currentTaskTemplate.value.taskSchedule.doItToday
  ) {
    promiseAll.push(currentTaskTemplate.value.doItToday(false));
  }
  await onChangedTaskDetail(Promise.all(promiseAll));

  if (isOnlyChangeUser) onCancelAssign();

  updatePropData();
};

const onDoItToday = async (value) => {
  onChangedTaskDetail(currentTaskTemplate.value.doItToday(value));
};

const onFinishItToday = async (value) => {
  onChangedTaskDetail(currentTaskTemplate.value.finishItToday(value));

  // onUpdateDeadline({
  //   otherTimeKey: null,
  //   scheduleOptionKey: value
  //     ? ScheduleDayOptions.OnDay
  //     : ScheduleDayOptions.Nothing,
  //   scheduleTime: null,
  // });

  // TODO
  // if (
  //   value &&
  //   currentTaskTemplate.value.taskRepeat?.repeatType !== ETaskRepeatType.None
  // )
  //   updateStartDateForRepeat(new Date());
};

const isUpdatingTask = ref(false);
const editingName = ref('');

const onChangedTaskDetail = async (changeMethod: Promise<any>) => {
  isUpdatingTask.value = true;
  await changeMethod;
  isUpdatingTask.value = false;

  return true;
};

const isEditingName = ref<boolean>(false);

const onRenameTask = async () => {
  isEditingName.value = false;

  if (!editingName.value) return;
  await onChangedTaskDetail(
    currentTaskTemplate.value.rename(editingName.value)
  );
};

const onOpenEditName = () => {
  editingName.value = currentTaskTemplate.value?.name;
  isEditingName.value = true;
  isInputComment.value = false;
  onCloseEditDescription();
  onCancelAssign();
};

const onCloseEditName = () => {
  isEditingName.value = false;
};

const onChangeUrgency = async (urgency) => {
  await onChangedTaskDetail(currentTaskTemplate.value.changeUrgency(urgency));

  updatePropData();
};

const isNewChat = ref();
const objectDiscussTaskFast = ref({
  contactId: null,
  type: '',
  conversationId: '',
  task: currentTaskTemplate.value,
} as any);

const onOpenSendFast = (obj) => {
  if (!props.isPlaceModal) return;
  _chatStore.setIdConversationActive(obj?.conversationId);
  objectDiscussTaskFast.value.contactId = obj?.contactId;
  objectDiscussTaskFast.value.type = obj?.type;
  objectDiscussTaskFast.value.conversationId = obj?.conversationId;
  objectDiscussTaskFast.value.task = currentTaskTemplate.value;
};

const onChatAboutTask = () => {
  isNewChat.value = false;
  emit('onClose');
};

// Description
const onChangeDescription = (newDes) => {
  let newDescription;
  onSaveComment((data) => (newDescription = data?.content));

  if (typeof newDes == 'string') newDescription = newDes;

  onChangedTaskDetail(
    currentTaskTemplate.value.changeDescription(newDescription)
  );

  updatePropData();
};

const onOpenEditDescription = () => {
  onOpenComment(currentTaskTemplate.value?.description);

  onCloseEditName();
  onCancelAssign();
};

const onCloseEditDescription = () => {
  onCancelComment();
};

onMounted(() => {
  init();
});

const scheduleRef = ref(null as any);

const onSaveDeadline = (deadlineDate) => {
  onChangedTaskDetail(currentTaskTemplate.value.changeDeadline(deadlineDate));
  scheduleRef.value?.onForceClose();

  updatePropData();
};
// const onSaveStartTime = (startTime) => {
//   currentTaskTemplate.value.startTime = startTime;

//   updatePropData();
// };

const onCancelDeadline = () => {
  scheduleRef.value?.onForceClose();
};

// const onOpenEditDomain = () => {
//   onCancelAssign();
// };

const changeAssignRef = ref<any>(null);

const onSaveDomain = (domainData) => {
  onChangedTaskDetail(currentTaskTemplate.value.changeDomain(domainData));

  changeAssignRef.value?.initData && changeAssignRef.value?.initData();

  updatePropData();
};

const isChangeState = ref({
  isLoading: false,
  state: currentTaskTemplate.value.taskLife,
});

const onChangeTaskState = async (state: TaskLife) => {
  isChangeState.value.isLoading = true;
  isChangeState.value.state = state;
  await onChangedTaskDetail(currentTaskTemplate.value.changeState(state));
  isChangeState.value.isLoading = false;
};

const onCheckPermission = async () => {
  if (isEditMode.value) return true;
};

const attachmentInputRef = ref<any>(null);

const onPasteFiles = async (event) => {
  const isEditMode = await onCheckPermission();
  if (!isEditMode) return;
  if (attachmentInputRef.value?.onPasteFiles)
    attachmentInputRef.value?.onPasteFiles(event);
};

const isDraging = ref(false);

const dragOver = (value) => {
  isDraging.value = value;
};

const onDropImage = async (e) => {
  isDraging.value = false;
  const isEditMode = await onCheckPermission();
  if (!isEditMode) return;
  const files = e.dataTransfer.files;

  if (attachmentInputRef.value?.onAddFiles)
    attachmentInputRef.value?.onAddFiles(files);
};
const taskDataConfigScheduleDayOptions = computed<IConfigType[]>(() => {
  return (
    _taskStore.dataConfigs?.scheduleDayOptions
      ?.filter((o) => o?.active && o.key !== ScheduleDayOptions.OtherTime)
      ?.map((o) => {
        let customText = '';
        switch (o?.key) {
          case ScheduleDayOptions.OnDay:
            customText = translate(`COMMON_LABEL_DEADLINE_SAME_DAY`);
            break;
          case ScheduleDayOptions.D1:
          case ScheduleDayOptions.D2:
          case ScheduleDayOptions.D3:
            customText = translate(`COMMON_LABEL_DEADLINE_AFTER_DAY`, {
              totalDays: o?.key || '',
              suffix: o?.key > 1 ? 's' : '',
            });
            break;

          default:
            customText = o.text;
            break;
        }
        return {
          ...o,
          text: customText,
        };
      }) || []
  );
});

const getConfigScheduleName = (data) => {
  const index = taskDataConfigScheduleDayOptions.value?.findIndex(
    (o) => o?.key == data?.scheduleOptionKey
  );
  if (index > -1)
    return taskDataConfigScheduleDayOptions.value[data?.scheduleOptionKey]
      ?.text;

  return '';
};

const isOpenUpdateDomainModal = ref(false);

const onOpenUpdateDomainModal = () => {
  isOpenUpdateDomainModal.value = true;
  onCancelAssign();
};

const onCloseUpdateDomainModal = () => {
  isOpenUpdateDomainModal.value = false;
  onCancelAssign();
};

const onSaveDomainAfterChangeGroup = async (domainData) => {
  const isOnlyChangeUser =
    changeAssignPayload.value?.groupId == currentTaskTemplate.value?.groupId &&
    changeAssignPayload.value?.assigneeId !==
      currentTaskTemplate.value?.assigneeId;

  const promiseAll = [
    currentTaskTemplate.value.changeDomain(domainData),
    currentTaskTemplate.value.changeAssignee(
      changeAssignPayload.value?.groupId,
      changeAssignPayload.value?.assigneeId
    ),
  ];

  if (
    !changeAssignPayload.value?.assigneeId &&
    currentTaskTemplate.value?.taskSchedule.doItToday
  ) {
    promiseAll.push(currentTaskTemplate.value.doItToday(false));
  }

  await onChangedTaskDetail(Promise.all(promiseAll));

  updatePropData();

  changeAssignPayload.value = {};
  isOpenUpdateDomainModal.value = false;

  if (isOnlyChangeUser) onCloseUpdateDomainModal();
};

const taskDetailRef = ref(null as any);

document.getElementById('tagOptionActivity')?.addEventListener('scroll', () => {
  console.log('scroll');
});

const getTaskTemplate = () => {
  return currentTaskTemplate.value;
};

const onChangePin = async () => {};
const onUnpin = async () => {};

const onUpdateDescription = (value) => {
  currentTaskTemplate.value.description = value;

  updatePropData();
};

const updateAttachments = (attachments: { files: any[]; notes: any[] }) => {
  currentTaskTemplate.value.files = attachments?.files;
  currentTaskTemplate.value.notes = attachments?.notes;
  updatePropData();
};
const updateChecklist = (checklist: ITaskTodo[] | null) => {
  currentTaskTemplate.value.taskTodos = checklist;
  currentTaskTemplate.value.updatedTodoList = true;
  updatePropData();
};

const updateDomainRef = ref<any>(null);

const reset = () => {
  currentTaskTemplate.value = new TaskTemplateEntity(props.taskTemplate || {});

  setTimeout(() => {
    if (updateDomainRef.value?.initDomain) updateDomainRef.value?.initDomain();
  });
};

const multipleAssigneeSetting = computed(
  () =>
    _settingStore._organizationSetting?.taskManagement?.multipleAssigneeSetting
);

const isEditCollaborator = ref<boolean>(false);
const onChangeCollaborator = async (collaborators) => {
  const payload = collaborators?.map((user) => {
    return {
      ...user,
      memberUserId: user?.id || user?.userId,
    };
  });
  currentTaskTemplate.value.addCollaboratorsInTask(payload);
  updatePropData();
};
const onRemoveCollaborator = async (userId) => {
  currentTaskTemplate.value.deleteCollaboratorsInTask(userId);
  updatePropData();
};

const myTimezone = computed(() => myProfileStore().timezone);

const changeDeadlineTime = async ({ dateTime, timezone }) => {
  currentTaskTemplate.value.scheduleTimezone = timezone;
  currentTaskTemplate.value.scheduleByHour = true;
  currentTaskTemplate.value.scheduleTime = dateTime;
  updatePropData();
};
const onRemoveScheduleTime = async ({ dateTime }) => {
  currentTaskTemplate.value.scheduleTimezone = '';
  currentTaskTemplate.value.scheduleByHour = false;
  currentTaskTemplate.value.scheduleTime = dateTime;
  updatePropData();
};

const manageCollaboratorRef = ref<any>(null);

const onCloseManageCollaborator = () => {
  if (
    manageCollaboratorRef.value?.onCloseEditMode &&
    typeof manageCollaboratorRef.value?.onCloseEditMode == 'function'
  )
    manageCollaboratorRef.value?.onCloseEditMode();
};

defineExpose({
  getTaskTemplate,
  reset,
});
</script>

<template>
  <!-- HAVE TASK   -->
  <div
    v-drop-zone="
      elementList?.includes('ATTACHMENT')
        ? { isDragging: dragOver, onDrop: onDropImage }
        : {}
    "
    v-bind="$attrs"
    class="
      w-full
      flex-1
      min-h-0
      flex flex-col
      text-sm
      bg-white
      relative
      rounded-t
    "
    @paste="elementList?.includes('ATTACHMENT') ? onPasteFiles : () => {}"
    @click="onClickToBody"
  >
    <div
      v-if="isLoadingDataTask?.detail"
      class="absolute w-full z-50 h-full flex-center bg-gray-50 bg-opacity-30"
    >
      <syn-icon
        custom-class="animate-spin w-4 h-4 fill-current"
        name="spinner"
      />
    </div>

    <UploadDropZone
      v-if="isDraging"
      class="absolute w-full top-0 left-0 right-0 bottom-0 z-20"
    />

    <section
      id="tagOptionActivity"
      ref="taskDetailRef"
      class="
        flex-1
        min-h-0
        flex flex-col
        space-y-2.5
        relative
        overflow-y-auto
        small-scrollbar
      "
    >
      <!-- HEADER ACTION -->
      <section
        v-if="elementList?.includes('STATE') || elementList?.includes('PIN')"
        class="
          w-full
          flex
          items-center
          justify-between
          py-2
          px-4
          border-b border-gray-100
        "
      >
        <div class="flex-center space-x-3 text-xs"></div>

        <!-- STEPPER STATE -->
        <section
          v-if="elementList?.includes('STATE')"
          class="flex-1"
          :class="
            [TaskLife.Todo, TaskLife.InProcess, TaskLife.Finished]?.includes(
              currentTaskTemplate?.taskLife
            )
              ? 'flex-center'
              : 'ml-2'
          "
        >
          <TaskStateStepper
            :task-detail="currentTaskTemplate"
            :is-loading="isLoadingInit"
            :object-loading="isChangeState"
            :readonly="isEditMode"
            @on-change-state="onChangeTaskState"
          />
        </section>

        <!-- OPTION -->
        <div
          v-if="elementList?.includes('PIN')"
          class="flex items-center space-x-1"
        >
          <template v-if="isLoadingInit">
            <div
              v-for="item in 3"
              :key="item"
              class="w-8 h-8 rounded bg-gray-100 animate-pulse"
            ></div>
          </template>
          <template v-else>
            <SetPinTask
              :current-pin-code="currentTaskTemplate?.pinnedColor"
              @on-change-pin="onChangePin"
              @on-unpin="onUnpin"
            />
          </template>
        </div>
      </section>
      <!-- NAME TASK -->
      <section
        v-if="elementList?.includes('NAME')"
        class="flex items-start space-x-2 justify-between text-xl px-2 pb-2"
      >
        <div
          v-if="isEditingName"
          class="flex-1 flex-center flex-col space-x-2 relative z-20"
        >
          <SynInputArea
            v-model="editingName"
            :disabled-enter-warning="
              $t('TASK_NAME_SHOULD_BE_WRITTEN_SEAMLESSLY') ||
              'The task name should be written seamlessly'
            "
            autofocus
            max-length="200"
          />
          <div
            class="w-full flex items-center justify-end space-x-1 text-xs px-2"
          >
            <SynButton
              :label="$t('COMMON_LABEL_CLOSE') || 'Close'"
              type-text
              color="gray"
              custom-class="px-2 py-1 rounded"
              @click.stop="onCloseEditName"
            />
            <SynButton
              :label="$t('COMMON_LABEL_SAVE') || 'Save'"
              custom-class="px-2 py-1 rounded"
              :disabled="!editingName"
              @click.stop="onRenameTask"
            />
          </div>
        </div>
        <div
          v-else
          class="
            flex-1 flex
            items-center
            space-x-2
            px-2
            py-2
            rounded
            cursor-text
            w-2/3
            hover:bg-gray-100
            hover-to-show__parent
            text-gray-800
          "
        >
          <span
            v-encode-html="{ text: currentTaskTemplate?.name }"
            class="break-words font-medium"
          >
          </span>
          <SynIcon
            v-if="isEditMode"
            name="edit"
            is-active
            class="
              hover-to-show__children
              fill-current
              cursor-pointer
              absolute
              hover:fill-current-800
              top-2
              right-1
            "
            :title="$t('COMMON_LABEL_EDIT') || 'Edit'"
            @click.stop="onOpenEditName"
          />
        </div>
      </section>

      <!-- CREATOR +  ASSIGNMENT -->
      <div class="w-full px-4 pt-4 pb-1 flex items-center">
        <span
          v-if="!isHiddenTitle"
          class="text-xs w-28 text-gray-500 flex items-center h-8"
        >
          {{ $t('TASK_LABEL_ASSIGNEE') }}
        </span>
        <div class="flex-1 flex items-center space-x-1">
          <section
            class="w-full flex items-center gap-2 flex-wrap justify-between"
            :class="isEditMode ? 'w-full' : ''"
          >
            <!-- ASSIGNEE -->
            <TaskDetailField
              v-if="
                (isEditMode ||
                  currentTaskTemplate.groupId ||
                  currentTaskTemplate.assigneeId) &&
                elementList?.includes('RESPONSIBLE')
              "
              :is-editable="isEditMode"
              :style="!isEditMode ? '' : 'flex: 70%;'"
            >
              <template #fieldName>
                <span>{{ $t('TASK_LABEL_ASSIGNEE') }}</span>
              </template>
              <template #body>
                <div style="max-width: 26rem; min-width: 10rem">
                  <ChangeAssign
                    v-if="isEditMode"
                    ref="changeAssignRef"
                    v-permission="'FunctionTaskAssign'"
                    class="text-xs"
                    is-update-instant
                    is-workflow
                    :group-id="currentTaskTemplate.groupId || 0"
                    :user-id="currentTaskTemplate.assigneeId || 0"
                    :domain-id="currentTaskTemplate.domainId || 0"
                    :project-id="currentTaskTemplate.projectId || 0"
                    :subproject-id="currentTaskTemplate.subprojectId || 0"
                    can-remove-all
                    is-hidden-warning
                    @on-cancel="onCancelAssign"
                    @on-save="onChangeAssign"
                    @focus="onCloseManageCollaborator"
                  />
                  <div
                    v-else
                    class="
                      flex-center
                      space-x-1
                      relative
                      rounded
                      text-xs
                      font-medium
                      px-2
                      py-2
                    "
                    :class="
                      isEditMode
                        ? 'hover:bg-current-50 hover:bg-opacity-60 cursor-pointer'
                        : ''
                    "
                    @click.stop="onOpenChangeAssign"
                  >
                    <div
                      v-if="currentTaskTemplate?.groupId"
                      class=""
                      :style="
                        currentTaskTemplate?.assigneeId
                          ? 'flex: 50%'
                          : 'flex: 100%'
                      "
                    >
                      <GroupById
                        :group-id="currentTaskTemplate?.groupId"
                        avatar-class="w-6 h-6 text-xs"
                      />
                    </div>
                    <span
                      v-if="
                        currentTaskTemplate?.assigneeId &&
                        currentTaskTemplate?.groupId
                      "
                      class="px-2 text-gray-500"
                      >→</span
                    >
                    <div
                      v-if="currentTaskTemplate?.assigneeId"
                      class=""
                      :style="
                        currentTaskTemplate?.groupId
                          ? 'flex: 50%'
                          : 'flex: 100%'
                      "
                    >
                      <UserById
                        is-show-first-name
                        avatar-class="w-6 h-6 text-xs"
                        :user-id="currentTaskTemplate?.assigneeId"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </TaskDetailField>

            <!-- COLLABORATOR -->
            <template
              v-if="
                multipleAssigneeSetting?.systemEnable &&
                multipleAssigneeSetting?.organizationEnable &&
                !currentTaskTemplate?.isPrivate &&
                ((viewMode == 'VIEW' &&
                  currentTaskTemplate?.collaborators?.length > 0) ||
                  viewMode !== 'VIEW') &&
                elementList?.includes('COLLABORATORS')
              "
            >
              <TaskDetailField>
                <template #fieldName>
                  <span>{{ $t('COMMON_LABEL_COLLABORATORS') }}</span>
                </template>
                <template #body>
                  <div class="px-2">
                    <ManageCollaborator
                      ref="manageCollaboratorRef"
                      v-model:is-edit-mode="isEditCollaborator"
                      class="flex-1"
                      :group-id="currentTaskTemplate.groupId || 0"
                      :assignee-id="currentTaskTemplate.assigneeId || 0"
                      :collaborators="currentTaskTemplate.collaborators"
                      :readonly="viewMode == 'VIEW'"
                      @on-add="onChangeCollaborator"
                      @on-remove="onRemoveCollaborator"
                    />
                  </div>
                </template>
              </TaskDetailField>
            </template>
          </section>
        </div>
      </div>

      <!-- DOMAIN  -->
      <div
        v-if="elementList?.includes('DOMAIN')"
        class="w-full px-4 flex items-center"
      >
        <span
          v-if="!isHiddenTitle"
          class="text-xs w-28 text-gray-500 flex items-center h-8"
          >{{ $t('DOMAIN_LABEL_TITLE') }}</span
        >
        <div class="flex-1 flex items-center space-x-1">
          <div class="flex space-x-1 text-xs">
            <UpdateDomain
              v-if="isEditMode"
              ref="updateDomainRef"
              :task-detail="currentTaskTemplate"
              :is-update-domain-only="isUpdateDomainOnly"
              @save="onSaveDomain"
            />
            <DomainProjectById
              v-else-if="currentTaskTemplate.domainId"
              :domain-id="currentTaskTemplate.domainId"
              :project-id="currentTaskTemplate.projectId"
              :sub-project-id="currentTaskTemplate.subprojectId"
            />
          </div>
        </div>
      </div>

      <!--  PLAN TODAY -->
      <div
        v-if="
          elementList?.includes('PLAN_TODAY') &&
          currentTaskTemplate.assigneeId &&
          currentTaskTemplate.taskLife !== TaskLife.Finished
        "
        class="w-full px-4 flex items-center"
      >
        <span class="text-xs w-28 text-gray-500 flex items-center h-8">{{
          $t('TASK_FILTER_LABEL_PLANNED')
        }}</span>
        <div class="flex flex-col">
          <div class="flex items-center rounded-md px-1 space-x-4 py-1">
            <div class="flex items-center space-x-2">
              <AtomSwitch
                v-model="currentTaskTemplate.taskSchedule.doItToday"
                :disabled="!isEditMode"
                :is-loading="isUpdatingTask"
                @change="onDoItToday"
              >
                <span class="text-current-800 text-xs">
                  {{ $t('TASK_CREATE_FORM_DOING_TODAY') || 'Do today' }}
                </span>
              </AtomSwitch>
            </div>
            <div class="flex items-center space-x-2">
              <AtomSwitch
                v-model="currentTaskTemplate.taskSchedule.finishItToday"
                :disabled="!isEditMode"
                :is-loading="isUpdatingTask"
                @change="onFinishItToday"
              >
                <span class="text-current-800 text-xs">
                  {{ $t('TASK_CREATE_FORM_FINISH_TODAY') || 'Finish today' }}
                </span>
              </AtomSwitch>
            </div>
          </div>
        </div>
      </div>
      <!-- URGENCY  -->
      <div
        v-if="elementList?.includes('URGENCY')"
        class="w-full px-4 flex items-center"
      >
        <span
          v-if="!isHiddenTitle"
          class="text-xs w-28 text-gray-500 flex items-center h-8"
          >{{ $t('TASK_TABLE_LABEL_URGENCY') || 'Urgency' }}</span
        >
        <div class="flex-1 flex items-center">
          <div v-if="isEditMode" class="flex items-center flex-wrap">
            <div
              v-for="(item, index) in [4, 3, 2, 1]"
              :key="index"
              class="
                cursor-pointer
                rounded-full
                pr-2
                pl-1
                py-1
                hover:bg-opacity-100
                mr-0.5
                mt-0.5
              "
              :class="
                currentTaskTemplate.urgency === item
                  ? 'bg-current text-white font-medium'
                  : 'border border-current-50 hover:bg-current-50'
              "
              @click="onChangeUrgency(item)"
            >
              <PriorityTask is-label :urgency="item" size="mini" />
            </div>
          </div>
          <PriorityTask
            v-else
            style="min-width: 8rem"
            :urgency="currentTaskTemplate.urgency"
            is-label
            size="mini"
          />
        </div>
      </div>

      <!--  DEADLINE -->
      <div
        v-if="elementList?.includes('DEADLINE')"
        class="w-full px-4 flex items-center"
      >
        <span
          v-if="!isHiddenTitle"
          class="text-xs w-28 text-gray-500 flex items-center h-8"
          >{{ $t('TASK_TABLE_LABEL_DEADLINE') }}</span
        >
        <div class="flex gap-2" :class="isEditMode ? 'flex-col' : ''">
          <div class="flex-1 flex items-center">
            <DeadlineInput
              v-if="isEditMode"
              :schedule-key="
                currentTaskTemplate?.taskSchedule?.scheduleOptionKey
              "
              :other-time="currentTaskTemplate.taskSchedule.otherTime"
              :schedule="currentTaskTemplate.scheduleTime"
              :user-id="currentTaskTemplate.assigneeId"
              :group-id="currentTaskTemplate.groupId"
              :is-hidden-exact-date="isHiddenExactDate"
              view-mode="EDIT"
              :start-time="currentTaskTemplate.startTime"
              :is-hidden-start-time="isHiddenStartTime"
              @cancel="onCancelDeadline"
              @save="onSaveDeadline"
            />
            <div
              v-else
              :class="[
                getConfigScheduleColor(currentTaskTemplate?.taskSchedule),
                isEditMode ? 'cursor-pointer' : '',
              ]"
              class="rounded text-xs px-1 py-0.5"
              style="min-width: 8rem"
            >
              <span v-if="getConfigScheduleName(currentTaskTemplate)">
                {{ getConfigScheduleName(currentTaskTemplate) }}
              </span>
              <SynLabelDateTime
                v-else-if="currentTaskTemplate.scheduleTime"
                format="date"
                :datetime="currentTaskTemplate.scheduleTime"
              />
              <span v-else>
                {{ $t('TASK_DETAIL_LABEL_NO_SCHEDULE') || 'No schedule' }}
              </span>
            </div>
          </div>
          <template v-if="elementList?.includes('DEADLINE_TIME')">
            <UpdateDeadlineTime
              v-if="isEditMode"
              is-editable
              :schedule-time="currentTaskTemplate?.scheduleTime"
              :schedule-by-hour="currentTaskTemplate?.scheduleByHour"
              :schedule-timezone="currentTaskTemplate?.scheduleTimezone"
              :my-timezone="myTimezone"
              @on-change="changeDeadlineTime"
              @on-remove-time="onRemoveScheduleTime"
            />
            <SynLabelDateTime2
              v-else-if="currentTaskTemplate?.scheduleByHour"
              format="time"
              class="text-xs"
              :datetime="currentTaskTemplate?.scheduleTime"
              :timezone="currentTaskTemplate?.scheduleTimezone"
            />
          </template>
        </div>
      </div>

      <!-- DESCRIPTION -->
      <section
        v-if="elementList?.includes('DESCRIPTION')"
        class="w-full px-4 flex items-start"
      >
        <span class="text-xs w-28 text-gray-500 flex items-center h-8">
          {{ $t('COMMON_LABEL_DESCRIPTION') || 'Description' }}
        </span>

        <div class="flex-1">
          <div v-if="isInputComment" ref="descriptionInputRef" class="relative">
            <TaskDescriptionInput
              ref="taskDescriptionRef"
              v-model="commentObjectCreate.content"
              v-model:is-valid="myCommentValid"
              :current-task="currentTaskTemplate"
              :is-action="false"
              @on-focus="onFocus"
              @on-close="onCloseEditDescription"
              @update:model-value="onUpdateDescription"
              @on-save="onChangeDescription"
            />
          </div>
          <div
            v-else
            class="
              whitespace-pre-wrap
              rounded
              border border-transparent
              inline-block
              w-full
              hover-to-show__parent
              leading-6
            "
            :class="[
              isEditMode && !currentTaskTemplate.description
                ? 'cursor-pointer'
                : 'cursor-text',
              currentTaskTemplate.description
                ? 'p-1 pl-1 hover:border-gray-300'
                : 'px-2 py-1',
            ]"
            :style="
              currentTaskTemplate.description && isEditMode
                ? 'padding-top: 12px'
                : ''
            "
            @click.stop="
              isEditMode &&
                !currentTaskTemplate.description &&
                onOpenEditDescription()
            "
          >
            <span
              class="task-detail_description break-words text-gray-500"
              v-html="
                currentTaskTemplate.description ||
                $t('COMMON_LABEL_PLACEHOLDER_ADD_DESCRIPTION') ||
                'Add description ...'
              "
            ></span>
            <SynIcon
              v-if="isEditMode"
              name="edit"
              is-active
              class="
                hover-to-show__children
                absolute
                top-1
                right-1
                fill-current
                cursor-pointer
                hover:fill-current-800
              "
              :title="$t('COMMON_LABEL_EDIT') || 'Edit'"
              @click.stop="onOpenEditDescription"
            />
          </div>
        </div>
      </section>

      <!-- ATTACHMENT -->
      <template v-if="elementList?.includes('ATTACHMENT')">
        <AttachmentInput
          ref="attachmentInputRef"
          :original-data="currentTaskTemplate"
          :files="currentTaskTemplate?.files"
          :notes="currentTaskTemplate?.notes"
          :readonly="!isEditMode"
          :is-hidden-checklist="!elementList?.includes('CHECK_LIST')"
          @update:attachments="updateAttachments"
          @update:checklist="updateChecklist"
        />
      </template>
    </section>

    <!-- END -->
  </div>
  <!--  </template>-->

  <ModalNewChat
    v-if="isNewChat"
    :title="
      $t('COMMON_LABEL_DISCUSS_ABOUT_WITH') || 'Discuss about this task with'
    "
    :attached-task="currentTaskTemplate"
    :is-block-bubble-chat="isPlaceModal"
    @on-close="isNewChat = false"
    @on-conversation-click="onChatAboutTask"
    @open-send-fast="onOpenSendFast"
  />

  <ModalDiscussChatTask
    v-if="
      isPlaceModal &&
      objectDiscussTaskFast?.contactId &&
      objectDiscussTaskFast?.type
    "
    :user-id="
      objectDiscussTaskFast?.contactId && objectDiscussTaskFast?.type === 'USER'
        ? objectDiscussTaskFast?.contactId
        : null
    "
    :group-id="
      objectDiscussTaskFast?.contactId &&
      objectDiscussTaskFast?.type === 'GROUP'
        ? objectDiscussTaskFast?.contactId
        : null
    "
    :current-task="objectDiscussTaskFast?.task"
    :conversation-id="objectDiscussTaskFast?.conversationId"
    @on-close="
      objectDiscussTaskFast.contactId = null;
      objectDiscussTaskFast.type = '';
    "
  />

  <UpdateDomainModal
    v-if="isOpenUpdateDomainModal"
    :task-detail="{
      ...currentTaskTemplate,
      assigneeId: changeAssignPayload.assigneeId,
      groupId: changeAssignPayload.groupId,
      domainId: fixConflictDomain.domain ? null : currentTaskTemplate.domainId,
      projectId:
        fixConflictDomain.domain || fixConflictDomain.project
          ? null
          : currentTaskTemplate.projectId,
      subprojectId:
        fixConflictDomain.domain ||
        fixConflictDomain.project ||
        fixConflictDomain.subProject
          ? null
          : currentTaskTemplate.subprojectId,
    }"
    :is-updated="isUpdatingTask"
    @cancel="onCloseUpdateDomainModal"
    @save="onSaveDomainAfterChangeGroup"
  />
</template>

<style lang="scss">
.new-sticker {
  transform: perspective(10px) rotateX(1deg);
}

.task-detail_description {
  a {
    @apply text-current-500;
  }

  a:hover {
    @apply underline;
  }
}

#tagOptionActivity:not([data-scroll='0']) {
  background-color: transparent !important;
}
</style>
