import { IDomainDetail } from '@/domain/entities/domains/DomainDetailClass';
import DomainService from '@/application/services/DomainService';
import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import AuthService from '@/application/services/AuthService';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';
import {
    getCurrentUserId,
    setLocalStorage,
    StorageConstant,
} from '@/ui/hooks/storageHook';
import CatalogService from '@/application/services/CatalogService';
import UserService from '@/application/services/UserService';
import { AdminType } from '@/ui/common/constants/constant';
import { IMyProfile } from '@/application/types/user/my-profile.types';
import TaskService from '@/application/services/task/TaskService';
import { IUserDay } from '@/application/types/task/task.types';
import dayjs from 'dayjs';

export default defineStore({
    id: storeId.AUTH_MY_PROFILE,
    state: () => ({
        _unauthService: AuthService.getInstance(),
        myProfile: {} as IMyProfile,
        allMyPenddingInvitation: [] as any,
        myTaskInformation: {
            totalPinned: 0,
        },
        myDayList: [] as IUserDay[],
        myDomains: [] as IDomainDetail[],
        myToday: '' as string,
    }),
    getters: {
        isVisitor(state): boolean {
            return state.myProfile?.adminType == AdminType.Visitor;
        },
        isAdmin(state): boolean {
            return state.myProfile?.adminType == AdminType.Admin;
        },
        isOwner(state): boolean {
            return state.myProfile?.adminType == AdminType.Owner;
        },
        isUser(state): boolean {
            return state.myProfile?.adminType == AdminType.User;
        },
        getTimezone(state) {
            return state.myProfile?.utc || '0';
        },
        timezone(state) {
            if (parseInt(state.myProfile?.utc) == 7) return 'Asia/Ho_Chi_Minh';
            if (
                parseInt(state.myProfile?.utc) == 1 ||
                parseInt(state.myProfile?.utc) == 2
            )
                return 'Europe/Paris';
            return 'Asia/Ho_Chi_Minh';
        },
        getMyDepartmentIds(state) {
            return state.myProfile?.departmentIds || [];
        },
        myDepartment(state) {
            return state.myProfile?.departmentInfo?.length > 0
                ? state.myProfile?.departmentInfo[0]
                : {};
        },
    },
    actions: {
        async fetchCurrentLoginUser() {
            try {
                const res =
                    await AuthService.getInstance().getCurrentLoginInformations();
                const userInfo = res?.result;
                // setLocalStorage(StorageConstant.TIMEZONE, userInfo?.utc);
                // setLocalStorage(StorageConstant.ROLE, userInfo?.adminType);

                MyOrganizationSingleton.setUserRole(userInfo?.adminType);
                MyOrganizationSingleton.setUserId(userInfo?.id);

                MyOrganizationSingleton.setOrganizationSettingProgress(
                    userInfo?.currentSettingProgress
                );
                setLocalStorage(StorageConstant.CurrentEmail, userInfo?.email);

                this.myProfile = {
                    ...userInfo,
                    fullName: `${userInfo.lastName} ${userInfo.firstName}`,
                    avatarUrl: `${userInfo.avatarUrl}`,
                };
                return true;
            } catch (error) {
                console.log('🚀 Tictop ~ error:', error);
            }
        },
        async fetchCurrentCatalogUser() {
            const res: any =
                await CatalogService.getInstance().getUserProfile();
            const userInfo = res?.result;
            this.myProfile = { ...this.myProfile, ...userInfo };
        },
        async updateInfoCurrentUser() {
            const res: any = await UserService.getInstance().getDetail(
                getCurrentUserId()
            );
            const userInfo = res?.result;
            this.myProfile = {
                ...this.myProfile,
                ...userInfo,
                fullName: `${userInfo.lastName} ${userInfo.firstName}`,
                lastName: `${userInfo.lastName}`,
                firstName: `${userInfo.firstName}`,
                utc: `${userInfo.utc}`,
                address: `${userInfo.address}`,
                identityCard: `${userInfo.identityCard}`,
                adminType: `${userInfo.adminType}`,
                birthday: `${userInfo.birthday}`,
                avatarUrl: `${userInfo.avatarUrl}`,
            };
        },
        async getAllMyPendingInvitation() {
            const res =
                await CatalogService.getInstance().getAllMyPendingInvitation();

            this.allMyPenddingInvitation = res?.result;
        },
        updateTotalPinnedTasks(total) {
            this.myTaskInformation.totalPinned = total;
        },
        async getUserTaskSchedule() {
            const res = await TaskService.getInstance().getUserTaskSchedule(
                this.myProfile?.id
            );
            this.myDayList = res['result'];
            const onDay = this.myDayList?.find((o) => o?.code == 'OnDay');

            this.myToday =
                onDay?.scheduleDateValue || dayjs().utc().format('YYYY-MM-DD');
        },

        async getMyDomains() {
            const res: any =
                await DomainService.getInstance().getDomainScopeListByUserId(
                    this.myProfile?.id
                );
            this.myDomains = (res?.result || []).map((domain) => {
                return {
                    ...domain,
                    id: domain?.domainId,
                    name: domain?.domainName,
                };
            });
        },
    },
});
