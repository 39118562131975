<script setup lang="ts">
import { ref } from 'vue';
import favoriteStore from '@/store/favorite/favoriteStore';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import SortAble from '@/ui/components/draggable/SortAble.vue';
import { IUserFavorite } from '@/application/types/common/favarite.types';
import SearchTaskOwners from '@/ui/components/search/SearchTaskOwners.vue';
import {
  getUserPathFirebase,
  setDataFirebase,
} from '@/ui/plugins/firebases/firebaseRealtimeDatabase';
import { openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { orderBy } from 'lodash';

const _favoriteStore = favoriteStore();

const emit = defineEmits(['cancel']);

const isLoading = ref(false);
const userId = ref();
userId.value = getCurrentUserId();

const myFavorites = ref<IUserFavorite[]>([]);

const initData = () => {
  myFavorites.value = orderBy(
    _favoriteStore.getMyFavorites
      ?.filter((o) => o?.id)
      ?.map((o) => {
        return {
          ...o,
          index: o.index,
          refId: o?.refId,
          type:
            (o.favoriteType || o.type)?.toLowerCase() == 'member'
              ? 'USER'
              : (o.favoriteType || o.type)?.toUpperCase(),
        };
      }),
    'index',
    'asc'
  );
};

initData();

const addMyFavoriteItem = (dataItem: any) => {
  const type =
    dataItem?.type == 'USER' ? 'member' : dataItem?.type?.toLowerCase();

  myFavorites.value.push({
    ...dataItem,
    index: myFavorites.value?.length,
    refId: dataItem?.id,
    type,
    name: dataItem?.name,
    avatarUrl: dataItem?.avatarUrl || dataItem?.avatar,
    isPrivate: dataItem?.isPrivate,
    code: dataItem?.code,
  });
};

const removeList = ref<any[]>([]);
const removeMyFavoriteItem = (dataItem: any) => {
  removeList.value = [...removeList.value, dataItem];

  myFavorites.value = myFavorites.value?.filter(
    (f) => f?.refId !== dataItem.refId
  );

  myFavorites.value.forEach((item, index) => {
    item.index = index;
  });
};

const isChangeFavorite = ref({
  change: false,
  drag: false,
});

const onSave = async () => {
  isLoading.value = true;
  if (removeList.value && removeList.value?.length > 0)
    removeList.value.forEach((element) => {
      const path = getUserPathFirebase(userId.value, 'favorites');
      const favoriteUserPath = `${path}/${element?.type?.toLowerCase()}_${
        element?.refId
      }`;
      setDataFirebase(favoriteUserPath, null);
    });

  if (myFavorites.value && myFavorites.value?.length > 0)
    myFavorites.value.forEach((element) => {
      const path = getUserPathFirebase(userId.value, 'favorites');
      const favoriteUserPath = `${path}/${element?.type?.toLowerCase()}_${
        element?.refId
      }`;
      setDataFirebase(favoriteUserPath, {
        refId: element?.refId,
        type: element?.type,
        index: element?.index,
      });
    });

  _favoriteStore.setNewUserFavorites(myFavorites.value);
  openNotification({
    title: translate('COMMON_LABEL_SUCCESS'),
    body:
      translate('FAVORITE_LABEL_NOTIFICATION_UPDATE_SUCCESS') ||
      'Favourites update successful!',
  });

  isLoading.value = false;
  isChangeFavorite.value.change = false;
  isChangeFavorite.value.drag = false;
  emit('cancel', false);
};

const onCancel = () => {
  isChangeFavorite.value.change = false;
  isChangeFavorite.value.drag = false;
  initData();
  emit('cancel', false);
};
const updateItemIndex = (indexByIds?) => {
  if (myFavorites.value?.length == 0) return;
  myFavorites.value.forEach((element, index) => {
    const stepIndex = indexByIds
      ? indexByIds[`my-favorite-${element?.refId}`]
      : index;
    element.index = stepIndex;
  });
};
const onChangeDrag = (event) => {
  const childrenList = event?.target?.children;
  if (!childrenList || childrenList?.length == 0) return;

  let indexByIds = {};

  for (let index = 0; index < childrenList.length; index++) {
    const stepId = childrenList[index]?.id;
    indexByIds[stepId] = index;
  }

  updateItemIndex(indexByIds);
};
</script>

<template>
  <SynModal
    :container-class="'w-1/2 h-4/5'"
    style-body="p-0 flex flex-row flex-wrap"
    @cancel="onCancel"
  >
    <template #header>
      <div>
        <span>{{ $t('SIDEBAR_LABEL_MY_FAVORITES') }}</span>
      </div>
    </template>
    <template #body>
      <div class="pl-2 flex-1 overflow-hidden grid grid-cols-2 gap-3">
        <!--  UNSELECTED LIST -->
        <div
          id="intersection-root"
          class="h-full overflow-hidden px-2 border-r"
        >
          <div class="flex justify-between items-center space-x-4 w-full">
            <span>{{ $t('FAVORITE_LABEL_ADD_LIST_MY_FAVORITE') }}</span>
          </div>
          <SearchTaskOwners
            :owner-type-list="['USER', 'GROUP']"
            is-hidden-action-item
            is-select-multiple
            is-filter-when-selected
            is-hidden-selected-users
            :selected-ids="
              myFavorites?.map((fav) => `${fav?.type}_${fav?.refId}`)
            "
            @on-choose-owner="addMyFavoriteItem"
          />
        </div>

        <!-- SELECTED LIST -->
        <div class="h-full small-scrollbar overflow-auto pr-2">
          <div class="flex flex-col pl-3">
            <span>{{ $t('FAVORITE_LABEL_LIST_MY_FAVORITE') }}</span>
            <span class="text-sm text-gray-500 font-light">{{
              $t('FAVORITE_LABEL_SUB_ADD_LIST_MY_FAVORITE')
            }}</span>
          </div>
          <template v-if="myFavorites.length < 1">
            <div class="pt-16">
              <syn-animation
                name="emptyBox"
                stype="width: 150px; height: 150px"
              />
            </div>
            <div class="bg-gray-50 py-4 rounded-lg m-2">
              <div class="text-center w-full text-sm px-1 text-gray-500">
                {{ $t('SIDEBAR_LABEL_FAVORITE_MESSAGE') }}
              </div>
            </div>
          </template>

          <template v-else>
            <!-- FAVORITES -->
            <SortAble @on-end-sort="onChangeDrag">
              <template #list-item>
                <template v-for="item in myFavorites" :key="item">
                  <div
                    v-if="item?.refId"
                    :id="`my-favorite-${item?.refId}`"
                    class="
                      hover-to-show__parent
                      flex
                      items-center
                      justify-between
                      cursor-move
                      rounded-md
                      hover:bg-gray-100
                      p-2
                    "
                  >
                    <div class="flex space-x-2 items-center">
                      <div class="w-8 h-8 relative">
                        <SynAvatar
                          :src="
                            item.avatarUrl?.replace('original', 'mini') || ''
                          "
                          :type="item?.type === 'member' ? 'user' : item?.type"
                          :name="item.name"
                          custom-class="w-8 h-8"
                        />
                        <div
                          v-if="item.isPrivate"
                          class="
                            absolute
                            bottom-0
                            bg-white
                            rounded-full
                            flex-center
                            w-4
                            h-4
                            right-0
                          "
                        >
                          <SynIcon
                            class="fill-current"
                            custom-class="w-2.5 h-2.5"
                            :name="
                              item.code == 'DELETED_GROUP'
                                ? 'cancel'
                                : item.isPrivate
                                ? 'private'
                                : ''
                            "
                          />
                        </div>
                      </div>
                      <div class="flex space-x-3 items-center">
                        <span
                          class="
                            text-sm
                            hidden
                            md:flex
                            text-overflow-hidden-line
                          "
                        >
                          {{ item.name }}
                        </span>
                        <div>
                          <SynIcon
                            v-if="item?.type == 'group'"
                            name="group"
                            is-active
                            custom-class="w-4 h-4 fill-gray-500"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="
                        hover-to-show__children
                        flex
                        items-center
                        space-x-4
                      "
                    >
                      <div
                        class="cursor-pointer"
                        :title="$t('COMMON_LABEL_DRAG_ITEM')"
                      >
                        <SynIcon
                          name="drag"
                          custom-class="w-4 h-4 text-gray-600 fill-gray-500"
                        />
                      </div>

                      <div
                        class="
                          flex-center
                          hover:bg-red-100
                          rounded-full
                          w-8
                          h-8
                          cursor-pointer
                        "
                        @click.stop="removeMyFavoriteItem(item)"
                      >
                        <SynIcon
                          name="trash"
                          is-active
                          custom-class="w-4 h-4 fill-red-500 text-red-400"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </SortAble>
          </template>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="
          pb-4
          px-4
          pt-2
          flex
          justify-end
          items-center
          cursor-pointer
          space-x-2
        "
      >
        <SynButton
          :label="$t('COMMON_LABEL_CANCEL')"
          type-text
          color="gray"
          @click="onCancel"
        />
        <SynButton
          :is-loading="isLoading"
          :label="$t('COMMON_LABEL_CONFIRM')"
          @click="onSave"
        />
      </div>
    </template>
  </SynModal>
</template>
