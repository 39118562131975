import { ref, computed } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import { formatDate } from '@/ui/hooks/commonFunction';
import { IUploadPreviewPropFile } from '@/ui/components/file-viewer/update-preview-type';

export default function commentLogic(
    isUpdatedTask: boolean,
    onChangeCallback,
    isDoNotNeedAddUserInfo
) {
    const descriptionInputRef = ref(null as any);

    const commentObjectCreate = ref({
        content: '',
        attachments: {
            files: [],
            notes: [],
        },
        mentionUserIds: [],
        mentionGroup: false,
    });
    const attachmentsInDescription = ref<IUploadPreviewPropFile[]>([]);
    const isInputComment = ref(false);
    const isEditedComment = ref(false);
    const userProfile = computed(() => myProfileStore().myProfile);

    const onOpenComment = (
        defaultValue: string,
        attachments?: any[],
        isLoadFromCache?: boolean
    ) => {
        commentObjectCreate.value.content = defaultValue;
        attachmentsInDescription.value = attachments || [];
        isInputComment.value = true;

        if (isLoadFromCache) isEditedComment.value = true;

        setTimeout(() => {
            descriptionInputRef.value?.focus();

            descriptionInputRef.value?.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest',
            });
        });
    };

    const isDontNeedAddUserInfo = () => {
        return (
            isEditedComment.value || !isUpdatedTask || isDoNotNeedAddUserInfo
        );
    };
    // const isEdittingCurrentText = (endPosition, currentTextLength) => {
    //   return endPosition < currentTextLength - 1;
    // };

    // const concatDescriptionWithUserInfo = (
    //   cursorPosition,
    //   currentTextLength,
    //   event
    // ) => {
    //   const editor = `${userProfile.value.fullName}(${formatDate(
    //     new Date(),
    //     'MMM DD, YYYY HH:mm'
    //   )}): `;

    //   const startStr = myComment.value.substring(0, cursorPosition);
    //   const endStr = myComment.value.substring(cursorPosition, currentTextLength);

    //   myComment.value = startStr + editor + endStr;

    //   const selectionPosition = cursorPosition + editor.length;

    //   event.target.setSelectionRange(selectionPosition, selectionPosition);
    // };

    const onFocus = () => {
        if (isDontNeedAddUserInfo()) return;

        isEditedComment.value = true;

        // concatDescriptionWithUserInfo(cursorPosition, currentTextLength, event);
        onChangeCallback(
            `${userProfile.value.fullName}(${formatDate(
                new Date(),
                'MMM DD, YYYY HH:mm'
            )}): `
        );
    };
    const onClickOutSideComment = () => {
        if (!isInputComment.value) return;
        isInputComment.value = false;
    };
    const onSaveComment = (callback) => {
        callback &&
            callback(commentObjectCreate.value, attachmentsInDescription.value);

        onCancelComment();
        // onReadAllTagAttributes();
    };
    // const onReadAllTagAttributes = () => {
    //   const temp = document.createElement('div');
    //   temp.innerHTML = formData.value.description;

    //   const results = temp.getElementsByTagName('span') ?? [];
    //   Array.from(results).forEach((item) => {
    //     formData.value.taggedMembers.push(item.getAttribute('data-user-id'));
    //   });
    // };
    const onCancelComment = () => {
        isInputComment.value = false;
        isEditedComment.value = false;
    };
    return {
        descriptionInputRef,
        isInputComment,
        commentObjectCreate,
        attachmentsInDescription,
        onOpenComment,
        isEditedComment,
        onFocus,
        onClickOutSideComment,
        onSaveComment,
        onCancelComment,
    };
}
