<script setup lang="ts">
import { computed, ref } from 'vue';
import { genColorByName } from './colorByAlphabet';
import $constants from '@/ui/plugins/constants';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import { getShortName } from '@/ui/common/molecules/SynAvatar/syn-avatar.const';

const props = withDefaults(
  defineProps<{
    src?: string;
    name?: string;
    subName?: string;
    hasName?: boolean;
    isOnCall?: boolean;
    size?: string;
    customClass?: string;
    customStyle?: string;
    ring?: string;
    type?: 'user' | 'group' | 'domain' | 'department';
    shape?: string;
    isViewImage?: boolean;
    isDeactive?: boolean;
    isNotCloseDropdownParent?: boolean;
    shortName?: string;
  }>(),
  {
    size: 'mini',
    customClass: 'h-8 w-8',
    type: 'user',
    shape: 'circle',
    isDeactive: false,
  }
);

const textAva = computed(() => {
  if (!props?.name) return '';
  if (props?.shortName) return props.shortName;

  return getShortName(props.name);
});

const nameIconDefault = computed(() => {
  switch (props.type) {
    case 'user':
      return 'profile';
    case 'group':
      return 'group';
    case 'domain':
      return 'project';
    case 'department':
      return 'department';
    default:
      return 'project';
  }
});

const viewImage = ref('');

const openDocument = (src) => {
  if (!props.isViewImage) return;
  Object.entries($constants.IMAGE.SIZE).forEach(([, value]) => {
    if (src?.includes(value)) {
      viewImage.value =
        src?.replace(`/${value}/`, `/${$constants.IMAGE.SIZE.ORIGINAL}/`) ||
        src;
    }
  });
};
const closePreview = () => {
  viewImage.value = '';
};
</script>

<template>
  <div v-bind="$attrs" class="relative flex items-center">
    <!--  AVATAR  -->
    <div
      class="relative shadow truncate"
      :class="[
        customClass,
        ring,
        shape === 'circle' ? 'rounded-full' : 'rounded',
      ]"
      :style="customStyle"
    >
      <VigImage
        v-if="src"
        class="w-full h-full object-cover overflow-hidden"
        :class="[
          isNotCloseDropdownParent ? 'do-not-close-dropdown' : '',
          shape === 'circle' ? 'rounded-full' : 'rounded',
        ]"
        :custom-class="customClass"
        :path="src"
        error-type="AVATAR"
        :size="size"
        @click="openDocument(src)"
      />
      <div
        v-else
        class="object-cover flex-center text-white font-thin"
        :class="`${customClass} ${ring} ${
          shape === 'circle' ? 'rounded-full' : 'rounded'
        } ${type !== 'domain' ? 'shadow' : 'border border-current'}`"
        :style="{
          backgroundColor:
            type !== 'domain'
              ? textAva
                ? genColorByName(textAva)
                : '#4bb0b2'
              : '#ffff',
          fontSize: size === 'mini' ? '0.7rem' : '1rem',
        }"
      >
        <span
          v-if="textAva && type !== 'domain'"
          class="uppercase no-underline overflow-hidden"
        >
          {{ textAva }}
        </span>
        <SynIcon
          v-else
          :name="nameIconDefault"
          is-active
          :custom-class="
            type !== 'domain'
              ? 'w-1/2 h-1/2 fill-white text-white'
              : 'w-1/2 h-1/2 fill-current text-current'
          "
          custom-style="min-width: 0.75rem"
        />
      </div>
    </div>

    <div
      v-if="hasName && name"
      class="flex-1 min-w-0 flex flex-col items-start pl-2"
    >
      <slot name="name">
        <span class="truncate w-full">{{ name }}</span>
      </slot>
      <slot name="subName"></slot>
    </div>

    <div
      v-if="isDeactive"
      class="
        absolute
        -bottom-1
        -right-1
        w-1/2
        h-1/2
        my-1
        border border-red-500
        bg-white
        rounded-full
        text-red-500
        flex-center
        font-medium
      "
    >
      <span class="pb-0.5">-</span>
    </div>

    <div
      v-if="isOnCall"
      class="
        w-full
        h-full
        rounded-full
        bg-current-400 bg-opacity-40
        flex-center
        absolute
        top-0
      "
      :class="shape === 'circle' ? 'rounded-full' : 'rounded'"
    >
      <SynIcon custom-class="w-5 h-5 fill-white" name="phone" />
    </div>
  </div>

  <SynPreviewDoc
    v-if="isViewImage && viewImage"
    :src="viewImage"
    is-image
    @close-preview="closePreview"
  />
</template>
