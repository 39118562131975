<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    name: string;
    stype?: string;
    // Autoplay animation on load.
    autoplay?: boolean;
    // Show controls
    controls?: boolean;
    hover?: boolean;
    loop?: boolean;
    mode?: 'normal' | 'bounce';
    background?: string;
    speed?: number;
    title?: string;
    label?: string;
    isHtmlText?: boolean;
  }>(),
  {
    speed: 1,
    background: 'transparent',
    mode: 'normal',
    loop: true,
    autoplay: true,
    stype: 'width: 400px; height: 400px;',
    name: 'loading',
    title: '',
    label: '',
    isHtmlText: false,
  }
);
const linkFile = await import(
  '@/ui/components/animations/' + props.name + '.json'
);
const randomId = Math.random();

const init = () => {
  let animationContainer = document.getElementById('animation-container');
  let animation = document.getElementById('animation');
  if (animationContainer && animation) {
    animationContainer.id = 'animation-container-' + randomId;
    animation.id = 'animation-' + randomId;
  }
};
init();
</script>

<template>
  <div class="flex-center flex-wrap" style="max-width: 100%; max-height: 100%">
    <lottie-player
      :id="`syn-animation-${randomId}`"
      :src="linkFile"
      :style="stype"
      :autoplay="autoplay"
      :loop="loop"
      :controls="controls"
      :mode="mode"
      :background="background"
      :speed="speed"
    />
    <div
      v-if="label"
      class="
        font-normal
        text-gray-600 text-md
        w-full
        text-center
        pt-4
        flex-center flex-col
      "
    >
      <span
        v-if="title && isHtmlText"
        class="text-current-700"
        style="font-size: 1.15rem"
        v-html="title"
      ></span>
      <span
        v-if="title && !isHtmlText"
        class="text-current-700"
        style="font-size: 1.15rem"
        >{{ title }}</span
      >
      <span v-if="label && !isHtmlText" class="text-sm text-gray-500 py-2">{{
        label
      }}</span>
      <span
        v-if="label && isHtmlText"
        class="text-sm text-gray-500 py-2"
        v-html="label"
      ></span>
    </div>
  </div>
</template>
