<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import ConfirmBeforeSetupOrganization from '@/ui/modules/organization/setup/ConfirmBeforeSetupOrganization.vue';
import ConfirmAttachOrganization from '@/ui/modules/organization/setup/ConfirmAttachOrganization.vue';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';
import AuthenticateClass from '@/application/entities/authenticate/AuthenticateClass';
// import SelectOrganization from '../organization/SelectOrganization.vue';
import { AuthenticateSelectOrganization } from '@/application/services/authenticate/authenticate-service';
import myProfileStore from '@/store/auth/my-profile';
import SetupOrganizationModal from '@/ui/modules/organization/setup/SetupOrganizationModal.vue';
import { SettingProgressEnum } from '@/ui/common/constants/constant';
// import PeddingInvitationModal from '@/ui/modules/organization/setup/PeddingInvitationModal.vue';
import AcceptInvitationModal from '@/ui/modules/member/accept-invitation/AcceptInvitationModal.vue';
import organizationStore from '@/store/organization';
import OrganizationCard from '@/ui/modules/organization/components/OrganizationCard.vue';
import {
  settingGlobalModal,
  ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
  StorageConstant,
} from '@/ui/hooks/storageHook';
import settingStore from '@/store/setting';
import { gotoDefaultPageByUserRole } from '@/ui/hooks/commonHook';
import permissionStore from '@/store/permission';
import CatalogService from '@/application/services/CatalogService';
import SettingLanguage from '@/ui/components/setting/SettingLanguage.vue';
import { loginAgain } from '@/ui/hooks/commonHook';
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';
import AttachOrganizationModal from '@/ui/modules/organization/setup/AttachOrganizationModal.vue';
import AuthService from '@/application/services/AuthService';
import SelectOrganizationModelModal from '@/ui/modules/organization/setup/SelectOrganizationModelModal.vue';
import SettingOrganizationProcessBModal from '@/ui/modules/organization/setup/SettingOrganizationProcessBModal.vue';
import SettingOrganizationProcessCModal from '@/ui/modules/organization/setup/SettingOrganizationProcessCModal.vue';

const _myProfileStore = myProfileStore();

const srcLogoLabel = `${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/logo_brand.svg`;
const userProfile = computed(() => myProfileStore().myProfile);

const handleLogout = async () => {
  loginAgain();
};

const isLoading = ref<boolean>(true);
const myProfile = computed<any>(() => {
  return _myProfileStore.myProfile;
});
const currentOrganizationList = ref<any>([]);

const currentRegisterStep = computed<SettingProgressEnum>(() => {
  return myProfile.value?.currentStep;
});

const registerWithProcess = ref<{
  currentProcess: 'A' | 'B' | 'C' | 'D' | null;
  allowPersonal: boolean;
}>({
  currentProcess: null,
  allowPersonal: true,
});

const getCurrentSubscriptionProcess = async () => {
  const res = await AuthService.getInstance().getCurrentSubscriptionProcess();

  registerWithProcess.value = res?.result;
};
const initComponent = async () => {
  getCurrentSubscriptionProcess();
  await myProfileStore().fetchCurrentCatalogUser();

  currentOrganizationList.value =
    await organizationStore().getUserOrganizations();

  if (
    !currentOrganizationList.value ||
    currentOrganizationList.value?.length == 0
  ) {
    isLoading.value = false;
    organizationStore().getOrganizationConfigDefault();
    return;
  }

  switch (currentRegisterStep.value) {
    case SettingProgressEnum.SelectInvitation:
      getAllMyPendingInvitation();

      break;

    default:
      handleRedirectToDashboardLayout();
      break;
  }
};

const allMyPendingInvitation = ref<any>();
const getAllMyPendingInvitation = async () => {
  const res = await CatalogService.getInstance().getAllMyPendingInvitation();

  allMyPendingInvitation.value = res?.result;
  isLoading.value = false;
};
// const _organizationStore = organizationStore();
const currentStep = ref('');
const settingProgress = computed<SettingProgressEnum | null>(
  () =>
    MyOrganizationSingleton.getInstance().getOrganizationSettingProgress() as SettingProgressEnum
);

const createSuccess = ref(false);

const onFinishSetup = async (result) => {
  // setupOrgRef.value?.updateFetching(true);
  // const result = await _organizationStore.saveOrganizationSetting(payload);

  // setupOrgRef.value?.updateFetching(false);

  await new AuthenticateClass().handleSuccess(result);

  createSuccess.value = true;
};

const onCloseCongaturation = () => {
  location.reload();
};

const onChangeOrganization = async (orgInfo, switchImmediately = false) => {
  const orgId = orgInfo?.id;
  if (!orgInfo || !orgId) return;

  settingGlobalModal({
    type: 'confirm',
    title: '',
    content: `<span>
    <span class="">${translate(
      'COMMON_LABEL_SWITCH_ORGANIZATION_CONFIRM'
    )}</span>
    <br /> 
    <span class="text-current font-semibold">${orgInfo.name}</span>
    </span>`,
    confirmable: true,
    closeable: true,
  });
  const confirmed = switchImmediately ? true : await ask();
  if (confirmed) {
    // switch org
    const authenSelectOrganization = new AuthenticateSelectOrganization(orgId);
    const res = await authenSelectOrganization.login();

    handleLoginSuccess(res);
  }
};
const handleLoginSuccess = async (result) => {
  if (!result) return;

  getLocalStorage(StorageConstant.HAVE_AVATAR) &&
    removeLocalStorage(StorageConstant.HAVE_AVATAR);
  history.replaceState({}, '', '/');
  setLocalStorage(StorageConstant.HAS_LOGIN, 'true');

  window.location.reload();
};

const onRejectOrAcceptSuccess = (token, result) => {
  allMyPendingInvitation.value = allMyPendingInvitation.value?.filter(
    (invitation) => invitation?.token !== token
  );

  if (result?.organizations)
    currentOrganizationList.value = result?.organizations;

  if (
    allMyPendingInvitation.value?.length == 0 &&
    currentOrganizationList.value?.length == 1
  )
    onChangeOrganization(currentOrganizationList.value[0], true);
};

const isShowCreateNewOrganization = computed(() => {
  return (
    !isLoading.value &&
    settingProgress.value !== SettingProgressEnum.SettingFinished &&
    settingProgress.value !== SettingProgressEnum.SettingOrganization &&
    currentOrganizationList.value?.length == 0 &&
    allMyPendingInvitation.value?.length == 0
  );
});

const _organizationStore = organizationStore();
const router = useRouter();
const _settingStore = settingStore();

const handleRedirectToDashboardLayout = async () => {
  await Promise.all([
    permissionStore().getPermissionsByUser(),
    _organizationStore.getOrganizationInfo(''),
    _settingStore.getOrganizationSettings(),
  ]);

  gotoDefaultPageByUserRole(router);
};

const isOpenAttachOrg = ref(false);
const isOpenSetupOrg = ref(false);

const onAttachOrgSuccess = async (organization) => {
  isLoading.value = true;
  isOpenAttachOrg.value = false;
  isOpenSetupOrg.value = false;

  if (!organization?.organizationId) return;
  const authenSelectOrganization = new AuthenticateSelectOrganization(
    parseInt(organization?.organizationId.toString())
  );
  const res = await authenSelectOrganization.login();

  handleLoginSuccess(res);
};

initComponent();
</script>

<template>
  <SynLoading v-if="isLoading" />

  <div v-else class="flex flex-1 flex-col h-screen font-roboto">
    <header
      class="
        h-16
        flex flex-wrap
        justify-between
        items-center
        px-6
        bg-white
        border-b-2
        shadow-xl
      "
    >
      <div class="flex items-center justify-center min-w-min space-x-4">
        <syn-img :src="srcLogoLabel" class="w-32 cursor-pointer" />
      </div>
      <div class="flex-center">
        <div>
          <SynAvatar
            class="cursor-pointer text-current-800 pr-2"
            :src="userProfile.avatarUrl?.replace('original', 'mini')"
            :name="`${userProfile?.lastName ? userProfile?.lastName : ''} ${
              userProfile?.firstName ? userProfile?.firstName : ''
            }`"
            custom-class="w-8 h-8"
            has-name
          />
        </div>

        <VigDropdown>
          <template #dropdown-toggle>
            <SynIcon
              name="dots-vertical"
              has-action
              custom-class="h-5 w-5 hover:text-current"
            />
          </template>

          <template #dropdown-menu>
            <SynDropdownMenu class="right-0 w-44">
              <setting-language is-setting-organization />
              <SynDropdownItem
                class="text-red-500 dropdown-item"
                @click="handleLogout"
              >
                <div class="flex items-center">
                  <SynIcon name="logout" />
                  <span class="pl-2">{{
                    $t('COMMON_LABEL_LOGOUT') || 'Log out'
                  }}</span>
                </div>
              </SynDropdownItem>
            </SynDropdownMenu>
          </template>
        </VigDropdown>
      </div>
    </header>
    <div class="flex flex-1 overflow-hidden relative bg-gray-50">
      <main
        class="
          relative
          w-full
          overflow-x-hidden overflow-y-hidden
          flex flex-col
        "
      >
        <div class="relative h-full m-2 flex flex-col">
          <div
            v-if="
              !currentStep ||
              currentStep == 'CONFIRM_SETUP' ||
              currentStep == 'CONFIRM_ATTACH'
            "
            class="
              h-full
              w-full
              bg-white
              rounded
              mx-auto
              text-center
              flex
              items-center
              shadow
              flex-col
              overflow-auto
              small-scrollbar
              p-8
            "
          >
            <template v-if="currentOrganizationList.length > 0">
              <div>
                <span class="text-lg font-semibold">
                  {{ $t('COMMON_LABEL_WORKSPACE') }}
                </span>
              </div>
              <div
                class="
                  p-4
                  w-full
                  h-full
                  grid
                  auto-rows-max
                  overflow-hidden
                  gap-4
                "
                style="
                  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
                  max-width: 80rem;
                "
              >
                <OrganizationCard
                  v-for="orgInfo in currentOrganizationList"
                  :key="orgInfo.id"
                  :org-info="orgInfo"
                  :members="orgInfo?.members"
                  @on-select="onChangeOrganization(orgInfo)"
                />
              </div>
            </template>
            <div v-else class="w-full flex-center flex-col py-8">
              <div>
                <SynAnimation name="welcomeOrg" stype="width: 300px;" />
              </div>

              <div class="flex-center w-full flex-col gap-4">
                <span
                  class="text-4xl text-current uppercase font-medium mt-8"
                  style="text-shadow: rgb(26 151 147) 3px 0px 0px"
                >
                  {{ $t('COMMON_LABEL_WELCOME', { data: 'Tictop' }) }}
                </span>
                <p class="text-2xl font-thin">
                  {{ $t('YOU_SHOULD_SETUP_ORGANIZATION') }}
                </p>
                <div class="flex items-center justify-between gap-2">
                  <SynButton
                    :title="
                      $t('COMMON_LABEL_CREATE') || 'Create a new workspace'
                    "
                    class="rounded-full w-80 bg-current"
                    @click="isOpenSetupOrg = true"
                  >
                    <div class="flex-center space-x-2">
                      <SynIcon
                        name="Plus"
                        custom-class="w-3 h-3 fill-white text-white"
                      />
                      <span class="text-white text-sm">
                        {{
                          $t('COMMON_LABEL_CREATE') || 'Create a new workspace'
                        }}
                      </span>
                    </div>
                  </SynButton>
                  <SynButton
                    :title="
                      $t(
                        'CREATE_NEW_ORGANIZATION_NEW_WORKSPACE_JOIN_WITH_CODE'
                      ) || 'Join with code'
                    "
                    type-outline
                    class="rounded-full w-80 border-3"
                    @click="isOpenAttachOrg = true"
                  >
                    <div class="flex-center space-x-2">
                      <SynIcon name="link" custom-class="w-4 h-4" />
                      <span class="text-sm">
                        {{
                          $t(
                            'CREATE_NEW_ORGANIZATION_NEW_WORKSPACE_JOIN_WITH_CODE'
                          ) || 'Join with code'
                        }}
                      </span>
                    </div>
                  </SynButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>

  <ConfirmBeforeSetupOrganization
    v-if="currentStep == 'CONFIRM_SETUP'"
    @on-cancel="currentStep = ''"
    @on-confirm="currentStep = 'SETUP'"
    @on-attach-organization="currentStep = 'CONFIRM_ATTACH'"
  />
  <ConfirmAttachOrganization
    v-if="currentStep == 'CONFIRM_ATTACH'"
    @on-cancel="currentStep = ''"
    @on-confirm="currentStep = 'CONFIRM_SETUP'"
  />

  <SynCongraturation
    v-if="createSuccess"
    v-vig-tooltip="$t('COMMON_LABEL_CONGRATURATION')"
    :title="$t('COMMON_LABEL_CONGRATURATION')"
    :sub-title="$t('COMMON_LABEL_CREATE_ORG_SUCCESS')"
    @on-close="onCloseCongaturation"
  />
  <SetupOrganizationModal
    v-if="isShowCreateNewOrganization"
    @on-finish="onFinishSetup"
  />

  <!-- <PeddingInvitationModal
    v-for="(invitation, index) in allMyPendingInvitation"
    :key="index"
    :invitation="invitation"
  /> -->

  <template v-if="currentRegisterStep == SettingProgressEnum.SelectInvitation">
    <AcceptInvitationModal
      v-for="(invitation, index) in allMyPendingInvitation"
      :key="index"
      :token="invitation?.token"
      location="HOME_PAGE"
      @on-reject-success="
        (invi, result) => onRejectOrAcceptSuccess(invitation?.token, result)
      "
      @on-accept-success="
        (invi, result) => onRejectOrAcceptSuccess(invitation?.token, result)
      "
    />
  </template>

  <SelectOrganizationModelModal
    v-if="
      isOpenSetupOrg &&
      (registerWithProcess.currentProcess == 'A' ||
        registerWithProcess.currentProcess == 'D')
    "
    type-key="workspace_type_select_register"
    do-not-need-to-check-personal
    :not-allow-personal="!registerWithProcess.allowPersonal"
    is-home-page
    @on-cancel="registerWithProcess.currentProcess = null"
  />
  <SettingOrganizationProcessBModal
    v-if="isOpenSetupOrg && registerWithProcess.currentProcess == 'B'"
    type-key="workspace_type_select_register"
    do-not-need-to-check-personal
    :not-allow-personal="!registerWithProcess.allowPersonal"
    @on-cancel="registerWithProcess.currentProcess = null"
  />
  <SettingOrganizationProcessCModal
    v-if="isOpenSetupOrg && registerWithProcess.currentProcess == 'C'"
    type-key="workspace_type_select_register"
    do-not-need-to-check-personal
    :not-allow-personal="!registerWithProcess.allowPersonal"
    @on-cancel="registerWithProcess.currentProcess = null"
  />
  <AttachOrganizationModal
    v-if="isOpenAttachOrg"
    @on-success="onAttachOrgSuccess"
    @on-cancel="isOpenAttachOrg = false"
  />
</template>
