export const initializeCalHeatmap = () => {
    const script = document.createElement('SCRIPT');
    script.setAttribute(
        'src',
        '/js/plugins/cal-heatmap-3.3.10/cal-heatmap.min.js'
    );
    // script.setAttribute('crossorigin', 'anonymous');

    document.body.appendChild(script);
};
