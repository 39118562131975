import { createApp, h } from 'vue';
import { TaskUrgency } from '@/ui/hooks/taskHook';
import ItemEvent from '@/ui/components/calendars/event-item/ItemEvent.vue';

import Video from '@/ui/components/icons/Video.vue';
// import Timer from '@/ui/components/icons/Timer.vue';
import Calendars from '@/ui/components/icons/Calendars.vue';
import Event from '@/ui/components/icons/Event.vue';
import High from '@/ui/components/icons/High.vue';
import Square from '@/ui/components/icons/Square.vue';

import {
    CalendarType,
    CalendarMeetingType,
} from '@/ui/common/constants/calendar';
import { EventDetailItemClass } from '@/domain/entities/calendars/CalendarsClass';
import AlarmClock from '@/ui/components/icons/AlarmClock.vue';
import GoogleCalendar from '@/ui/components/icons/GoogleCalendar.vue';
import { CalendarSourceId } from '@/domain/enums/CalendarEnum';

// export function renderIcon(icon) {
//   let component;
//   switch (icon) {
//     case 'video':
//       component = Video;
//       break;
//     case 'timer':
//       component = Timer;
//       break;
//     case 'location':
//       component = Location;
//       break;
//     default:
//       break;
//   }
//   const appIcon = createApp({
//     render() {
//       return h(SynIcon, { name: component, isActive: true }, [
//         h(component, {}),
//       ]);
//     },
//   });

//   let tmpEl = document.createElement('div');
//   const mountedApp = appIcon.mount(tmpEl);
//   let iconEl = document.createElement('div');
//   iconEl.innerHTML = mountedApp.$el.outerHTML;
//   return iconEl;
// }

// export function renderItemEvent(
//   type,
//   srcAvatar,
//   nameUser,
//   titleEvent,
//   urgency,
//   meetingType,
//   startDate,
//   allDay,
//   system
// ) {
//   const componentIcon = getComponent(type, urgency, meetingType);
//   const appItem = createApp({
//     render() {
//       return h(
//         ItemEvent,
//         {
//           typeEvent: type,
//           srcAvatar: srcAvatar,
//           nameUser: nameUser,
//           titleEvent: titleEvent,
//           urgency: urgency,
//           nameIcon: componentIcon,
//           isActive: true,
//           startDate: startDate,
//           allDay: allDay,
//           system: system,
//         },
//         [h(componentIcon, {})]
//       );
//     },
//   });
//   const tmpEl = document.createElement('div');
//   const mountedApp = appItem.mount(tmpEl);
//   const el = document.createElement('div');
//   el.innerHTML = mountedApp.$el.outerHTML;
//   return el;
// }
export function renderItemEvent(data: EventDetailItemClass) {
    const componentIcon = getComponent(
        data.type,
        data.urgency,
        data.meetingType,
        data.sourceId
    );
    const appItem = createApp({
        render() {
            return h(
                ItemEvent,
                {
                    typeEvent: data.type,
                    srcAvatar: data.srcAvatar,
                    nameUser: data.nameUser,
                    titleEvent: data.titleEvent,
                    urgency: data.urgency,
                    nameIcon: componentIcon,
                    isActive: true,
                    startDate: data.startDate,
                    endDate: data.endDate,
                    allDay: data.allDay,
                    system: data.system,
                },
                () => [h(componentIcon, {})]
            );
        },
    });
    const tmpEl = document.createElement('div');
    const mountedApp = appItem.mount(tmpEl);
    const el = document.createElement('div');
    el.innerHTML = mountedApp.$el.outerHTML;
    return el;
}

export function getComponent(type, urgency, meetingType, sourceId) {
    if (sourceId == CalendarSourceId.googleCalendar) return GoogleCalendar;

    let component;

    switch (type) {
        case CalendarType.Task:
            {
                switch (urgency) {
                    case TaskUrgency.UrgentImportant.key:
                        component = High;
                        break;
                    case TaskUrgency.Urgent.key:
                        component = High;
                        break;
                    case TaskUrgency.Important.key:
                        component = Square;
                        break;
                    default:
                        break;
                }
            }
            break;
        case CalendarType.Meeting:
            if (meetingType == CalendarMeetingType.OnlineMeeting) {
                component = Video;
            } else if (meetingType == CalendarMeetingType.PhysicalMeeting) {
                component = Event;
            }
            break;

        case CalendarType.Reminder:
            // component = Timer;
            component = AlarmClock;
            break;
        case CalendarType.Event:
            component = Calendars;
            break;
        default:
            component = Event;
            break;
    }
    return component;
}
