import { getDatabase, onValue, ref } from 'firebase/database';

class OrgUserRecordingsRepository {
    subscribeRecording(
        orgId: number,
        userId: number,
        recordingId: string,
        cb: (val) => void
    ) {
        return onValue(this._getRef(orgId, userId, recordingId), (snapshot) =>
            cb(snapshot?.val())
        );
    }

    private _getRef(orgId: number, userId: number, recordingId: string) {
        return ref(
            getDatabase(),
            `organization_user_recordings/${orgId}/${userId}/${recordingId}`
        );
    }
}

export default new OrgUserRecordingsRepository();
