import ChatFileRepository from '@/application/repositories/chat/ChatFileRepository';
import ChatMessageRepository from '@/application/repositories/chat/ChatMessageRepository';
import ChatConversationRepository from '@/application/repositories/chat/ChatConversationRepository';
import UserConversationRepository from '@/application/repositories/chat/UserConversationRepository';
import { ChatMessageModel } from '@/application/models/chat/ChatMessageModel';
import { QuerySnapshot } from '@firebase/firestore';
import {
    ChatConversationMemberModel,
    ChatConversationModel,
} from '@/application/models/chat/ChatConversationModel';

import { IDayOffFromMessage } from '@/application/types/calendar/day-off.types';
import { IEventFromMessage } from '@/application/types/calendar/event.types';
import { ECalendarFromMessageCategory } from '@/application/types/calendar/day-off.types';

class ChatService {
    //<editor-fold desc="CONVERSATION">

    getConversation(orgId: number, converId: string) {
        return ChatConversationRepository.getConversation(orgId, converId);
    }

    getNewConversationId(orgId: number | string) {
        return ChatConversationRepository.getNewConversationId(orgId);
    }

    addConversation(
        orgId: number,
        converId: string,
        converInfo: ChatConversationModel,
        userId: number
    ) {
        return ChatConversationRepository.addConversation(
            orgId,
            converId,
            converInfo,
            userId
        );
    }

    //</editor-fold>

    //<editor-fold desc="USER CONVERSATION">

    getOrgUserConversationSupporting(orgId: number, userId: number) {
        return UserConversationRepository.getOrgUserConversationSupporting(
            orgId,
            userId
        );
    }

    subscribeUserConversations(
        orgId: number,
        userId: number,
        onNext: (snapshot) => void
    ) {
        return UserConversationRepository.subscribeUserConversations(
            orgId,
            userId,
            onNext
        );
    }

    subscribeUserData(orgId: number, userId: number, onNext: (doc) => void) {
        return UserConversationRepository.subscribeUserData(
            orgId,
            userId,
            onNext
        );
    }

    updateUserData(orgId: number, userId: number, updData: any) {
        return UserConversationRepository.updateUserData(
            orgId,
            userId,
            updData
        );
    }

    setSeenUserConversation(orgId: number, userId: number, converId: string) {
        return UserConversationRepository.setSeenUserConversation(
            orgId,
            userId,
            converId
        );
    }

    resetUserUnSeenConversations(orgId: number, userId: number) {
        return UserConversationRepository.resetUserUnSeenConversations(
            orgId,
            userId
        );
    }

    setConversationArchived(
        orgId: number,
        userId: number,
        converId: string,
        archived: boolean
    ): Promise<any> {
        return UserConversationRepository.setConversationArchived(
            orgId,
            userId,
            converId,
            archived
        );
    }

    getPinnedConversations(orgId: number, userId: number): Promise<any> {
        return UserConversationRepository.getPinnedConversations(orgId, userId);
    }

    setConversationPinned(
        orgId: number,
        userId: number,
        converId: string,
        pinned: boolean
    ): Promise<any> {
        return UserConversationRepository.setConversationPinned(
            orgId,
            userId,
            converId,
            pinned
        );
    }

    updateConversationsPinnedIndex(
        orgId: number,
        userId: number,
        convers: any[]
    ): Promise<any> {
        return UserConversationRepository.updateConversationsPinnedIndex(
            orgId,
            userId,
            convers
        );
    }

    //</editor-fold>

    //<editor-fold desc="MESSAGE">

    getMessagesFromLocal(
        orgId: number,
        converId: string,
        startAfterVal?: any,
        endBeforeVal?: any,
        pageSize?: number
    ): Promise<any> {
        return ChatMessageRepository.getMessagesFromLocal(
            orgId,
            converId,
            startAfterVal,
            endBeforeVal,
            pageSize
        );
    }

    getMessagesFromServer(
        orgId: number,
        converId: string,
        startAfterVal?: any,
        endBeforeVal?: any,
        pageSize?: number
    ) {
        return ChatMessageRepository.getMessagesFromServer(
            orgId,
            converId,
            startAfterVal,
            endBeforeVal,
            pageSize
        );
    }

    subscribeAddedMessages(
        orgId: number,
        converId: string,
        pageSize: number,
        onNext: (snapshot: QuerySnapshot<any>) => void
    ) {
        return ChatMessageRepository.subscribeAddedMessages(
            orgId,
            converId,
            pageSize,
            onNext
        );
    }

    subscribeUpdatedMessages(
        orgId: number,
        converId: string,
        pageSize: number,
        onNext: (snapshot: QuerySnapshot<any>) => void
    ) {
        return ChatMessageRepository.subscribeUpdatedMessages(
            orgId,
            converId,
            pageSize,
            onNext
        );
    }

    getNewMessageDoc(orgId: number, converId: string): any {
        return ChatMessageRepository.getNewMessageDoc(orgId, converId);
    }

    addMessage(
        orgId: number,
        converId: string,
        msgId: string,
        message: ChatMessageModel
    ): Promise<void> {
        return ChatMessageRepository.addMessage(
            orgId,
            converId,
            msgId,
            message
        );
    }

    addTaskCreatedFromMessage(
        orgId: number | string,
        converId: string,
        msgId: string,
        taskInfo: any,
        userId: number
    ): Promise<any> {
        return ChatMessageRepository.addTaskCreatedFromMessage(
            orgId,
            converId,
            msgId,
            taskInfo,
            userId
        );
    }

    addCreatedCalendarToMessage(
        orgId: number | string,
        converId: string,
        msgId: string,
        metaData: any,
        userId: number
    ): Promise<any> {
        let payloadData: any = {};

        switch (metaData?.category) {
            case ECalendarFromMessageCategory.DAY_OFF:
                payloadData = {
                    id: metaData?.id,
                    category: metaData?.category,
                    userId: metaData?.userId,
                    startDate: metaData?.startDate,
                    endDate: metaData?.endDate,
                    startDateSession: metaData?.startDateSession,
                    endDateSession: metaData?.endDateSession,
                    requestDate: metaData?.requestDate,
                    requestDay: metaData?.requestDay,
                    usedDay: metaData?.usedDay,
                    type: metaData?.type,
                    requestReason: metaData?.requestReason,
                    requestNote: metaData?.requestNote,
                    exception: metaData?.exception,
                    createdDate: metaData?.createdDate,
                    createdBy: metaData?.createdBy,
                } as IDayOffFromMessage;
                break;
            case ECalendarFromMessageCategory.EVENT:
            case ECalendarFromMessageCategory.REMINDER:
                payloadData = {
                    id: metaData?.id,
                    category: metaData?.category,
                    allDay: metaData?.allDay,
                    assigneeId: metaData?.assigneeId,
                    meetingType: metaData?.meetingType,
                    notificationType: metaData?.notificationType,
                    repeatType: metaData?.repeatType,
                    calendarId: metaData?.calendarId,
                    sourceId: metaData?.sourceId,
                    sourceType: metaData?.sourceType,
                    title: metaData?.title,
                    description: metaData?.description,
                    type: metaData?.type,
                    startDate: metaData?.startDate,
                    endDate: metaData?.endDate,
                    createdDate: metaData?.createdDate,
                    createdBy: metaData?.createdBy,
                } as IEventFromMessage;
                break;

            default:
                break;
        }

        return ChatMessageRepository.addCreatedCalendarToMessage(
            orgId,
            converId,
            msgId,
            payloadData,
            userId
        );
    }

    removeMessage(
        orgId: number,
        converId: string,
        msgId: string,
        userId: number
    ): Promise<any> {
        return ChatConversationRepository.removeMessage(
            orgId,
            converId,
            msgId,
            userId
        );
    }

    uploadMessageFile(orgId: number, fileId: string, file: any): Promise<any> {
        return ChatFileRepository.uploadMessageFile(orgId, fileId, file);
    }

    forwardMessageFile(
        orgId: number,
        fileId: string,
        msgFile: any
    ): Promise<any> {
        return ChatFileRepository.forwardMessageFile(orgId, fileId, msgFile);
    }

    //</editor-fold>

    //<editor-fold desc="MESSAGE PINNED">

    subscribePinnedMessages(
        orgId: number,
        converId: string,
        onNext: (snapshot: QuerySnapshot<any>) => void
    ) {
        return ChatConversationRepository.subscribePinnedMessages(
            orgId,
            converId,
            onNext
        );
    }

    //</editor-fold>

    //<editor-fold desc="MEMBERS">

    leaveConversation(
        orgId: number | string,
        converId: string,
        userId: number
    ): Promise<any> {
        return ChatConversationRepository.leaveConversation(
            orgId,
            converId,
            userId
        );
    }

    //</editor-fold>

    //<editor-fold desc="MEMBERS TYPING">
    subscribeTypingMembers(
        orgId: number,
        converId: string,
        onNext: (snapshot: QuerySnapshot<any>) => void
    ) {
        return ChatConversationRepository.subscribeTypingMembers(
            orgId,
            converId,
            onNext
        );
    }

    setMemberIsTyping(
        orgId: number,
        converId: string,
        userId: number,
        isTyping: boolean
    ) {
        return ChatConversationRepository.setMemberIsTyping(
            orgId,
            converId,
            userId,
            isTyping
        );
    }

    //</editor-fold>

    //<editor-fold desc="GROUP CHAT">

    uploadGroupChatAvatar(
        orgId: number,
        fileId: string,
        file: any
    ): Promise<any> {
        return ChatFileRepository.uploadGroupChatAvatar(orgId, fileId, file);
    }

    //</editor-fold>

    //<editor-fold desc="SUPPORTING CHAT">

    getOrgSupportingInfo(orgId: string): Promise<any> {
        return ChatConversationRepository.getOrgSupportingInfo(orgId);
    }

    addConversationSupporters(
        orgId: string,
        converId: string,
        supporters: ChatConversationMemberModel[],
        userId: number
    ): Promise<any> {
        return ChatConversationRepository.addConversationSupporters(
            orgId,
            converId,
            supporters,
            userId
        );
    }

    //</editor-fold>
}

export default new ChatService();
