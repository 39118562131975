<script setup lang="ts">
import { ref, onMounted, computed, onUnmounted, watch } from 'vue';
import CalHeatMap from 'cal-heatmap';
import { getDynamicPosition } from '@/ui/hooks/commonFunction';
import dayoffStatisticStore from '@/store/dayoff/dayoff-statistic';
import {
  DayoffDateSession,
  DayoffType,
  DayoffExceptionType,
} from '@/domain/enums/DayoffEnums';
import StatisticOfDay from '@/ui/components/calendars/statistics-card/StatisticOfDay.vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
// import localeVi from '@/ui/modules/calendar/calendar-statistic/locale-vi.js';

const props = defineProps({
  userId: {
    type: [String, Number],
    default: '',
  },
  dayoffUsed: {
    type: Array,
    default: () => [],
  },
  statisticDayoff: {
    type: Array,
    default: () => [],
  },
  totalDayoff: {
    type: Number,
    default: 0,
  },
});

// const emit = defineEmits(['onPreviousCalendar', 'onNextCalendar']);

watch(
  () => props.dayoffUsed,
  () => {
    refCalHeatMap.value.update(dataStatisticDayoffByDate?.value);
  }
);

const _dayoffStatisticStore = dayoffStatisticStore();

const refCalHeatMap = ref();
// const totalDayoffInYear = 12;
const currentDateChoose = ref({});
const isShowTooltip = ref(false);
const isFocusInCard = ref(false);
const currentYear = ref(new Date().getFullYear());
const cardPosition = ref({ x: 0, y: 0 });
const cardDayoffSize = {
  width: 300,
  height: 75,
};

const parserDataType = computed(() => _dayoffStatisticStore.parserDataType);
const dataStatisticDayoffByDate = computed(() => props.dayoffUsed || []);

const objectdataStatisticDayoffByDate = computed(() => {
  if (!props.dayoffUsed?.length) return {};

  let data: any = props.dayoffUsed;
  let obj = {};

  for (let d in data) {
    const utc = -(new Date(data[d].date).getTimezoneOffset() / 60);
    const startDayTime = new Date(data[d].date).getTime() - utc * 3600000;

    let dateObj = startDayTime / 1000;
    if (!obj[dateObj]) {
      obj[dateObj] = {};
      obj[dateObj]['date'] = data[d].date;
    }
    obj[dateObj]['data'] = [...(obj[dateObj]['data'] || []), data[d]];
  }

  return obj;
});

onMounted(() => {
  init();
  handleHoverEvent();
  mapLocale();
});

const monthMapVi = {
  Jan: 'LABEL_JANUARY',
  Feb: 'LABEL_FEBRUARY',
  Mar: 'LABEL_MARCH',
  Apr: 'LABEL_APRIL',
  May: 'LABEL_MAY',
  Jun: 'LABEL_JUNE',
  Jul: 'LABEL_JULY',
  Aug: 'LABEL_AUGUST',
  Sep: 'LABEL_SEPTEMBER',
  Oct: 'LABEL_OCTOBER',
  Nov: 'LABEL_NOVEMBER',
  Dec: 'LABEL_DECEMBER',
};

const mapLocale = () => {
  const cal = document.querySelectorAll('#cal-heatmap .graph-label');
  cal?.forEach((e) => {
    const month = e?.innerHTML;
    if (month && Object.keys(monthMapVi).includes(month)) {
      e.innerHTML = translate(monthMapVi[month]);
    }
  });
};

const handleHoverEvent = () => {
  let rect = document.querySelectorAll(
    '#cal-heatmap .cal-heatmap-container .graph-rect'
  );
  rect?.forEach((el) => {
    el.addEventListener('click', () => {
      setTimeout(() => {
        const targetPosition = el?.getBoundingClientRect();
        cardPosition.value = getDynamicPosition({
          x: targetPosition.left,
          y: targetPosition.top,
          width: cardDayoffSize.width,
          height: cardDayoffSize.height,
          padding: 10,
        });
        isShowTooltip.value = true;
      }, 100);
    });
  });
};

const onClickOutSideCard = () => {
  isShowTooltip.value = false;
  isFocusInCard.value = false;
};

const setValueByDaySession = (daySession, type, exception: any = null) => {
  let arrayValue = parserDataType.value[type]['value'];

  if (type != DayoffType.EXCEPTION) {
    if (daySession === DayoffDateSession.ALL_DAY) return arrayValue[0];
    return arrayValue[1];
  }
  if (type === DayoffType.EXCEPTION && exception) {
    switch (exception?.type) {
      case DayoffExceptionType.STOP_EARLY:
        return arrayValue[0];
      case DayoffExceptionType.START_LATE:
        return arrayValue[1];
      case DayoffExceptionType.BREAK_TIME:
        return arrayValue[2];
      default:
        return arrayValue[0];
    }
  }
};

const parserData = (data: any) => {
  let stats = {};
  for (let d in data) {
    let value = setValueByDaySession(
      data[d].daySession,
      data[d].type,
      data[d]?.exception
    );
    stats[data[d].date / 1000] =
      stats[data[d].date / 1000] && stats[data[d].date / 1000] <= value
        ? stats[data[d].date / 1000]
        : value;
  }
  return stats;
};

const init = () => {
  if (refCalHeatMap.value) refCalHeatMap.value.destroy();

  refCalHeatMap.value = new CalHeatMap();

  refCalHeatMap.value.init({
    itemSelector: '#cal-heatmap',
    start: new Date(currentYear.value, 0, 1).getTime(),
    domain: 'month',
    subDomain: 'day',
    displayLegend: false,
    range: 12,
    cellSize: 15,
    cellRadius: 0.5,
    domainGutter: 2,
    cellPadding: 2,
    legend: [1, 2, 3, 4, 5, 6, 7],
    data: dataStatisticDayoffByDate.value,
    afterLoadData: parserData,
    subDomainTitleFormat: {
      empty: '{date}',
      filled: '{date}',
    },
    domainLabelFormat: '%b',
    legendHorizontalPosition: 'right',
    previousSelector: '#cal-heatmap-PreviousDomain-selector',
    nextSelector: '#cal-heatmap-NextDomain-selector',
    subDomainTextFormat: function (date) {
      let datetime = new Date(date).getTime() / 1000;
      let obj = objectdataStatisticDayoffByDate.value[datetime];
      if (obj && obj?.data?.length > 1) return obj?.data?.length;
      return '';
    },
    onClick: function (date) {
      onClickDate(date);
    },
  });
};

onUnmounted(() => {
  refCalHeatMap.value.destroy();
});

// const onPreviousCalendar = async () => {
//   currentYear.value--;
//   emit('onPreviousCalendar', {
//     type: 'year',
//     value: currentYear.value,
//   });
//   isLoading.value = true;
//   await init();
//   handleHoverEvent();
//   isLoading.value = false;
//   // refCalHeatMap.value.previous(11);
// };

// const onNextCalendar = async () => {
//   currentYear.value++;
//   emit('onNextCalendar', {
//     type: 'year',
//     value: currentYear.value,
//   });
//   isLoading.value = true;
//   await init();
//   handleHoverEvent();
//   isLoading.value = false;
//   // refCalHeatMap.value.next(11);
// };

const onClickDate = async (date) => {
  console.log(date, '--------');
  let datetime = new Date(date).getTime() / 1000;
  currentDateChoose.value = objectdataStatisticDayoffByDate.value[datetime];
};
</script>

<template>
  <div class="flex flex-col w-full">
    <slot name="infomation-statistic"></slot>
    <!-- CALENDAR HEATMAP -->
    <div class="relative flex-center p-5 rounded">
      <div
        id="cal-heatmap-yaxislabels"
        class="text-2xs flex items-start flex-col text-gray-400"
      >
        <div class="graph-label">
          <pre>{{ $t('COMMON_LABEL_MON') }}</pre>
        </div>
        <div class="graph-label text-white">
          <pre>{{ $t('COMMON_LABEL_TUE') }}</pre>
        </div>
        <div class="graph-label">
          <pre>{{ $t('COMMON_LABEL_WED') }}</pre>
        </div>
        <div class="graph-label text-white">
          <pre>{{ $t('COMMON_LABEL_THU') }}</pre>
        </div>
        <div class="graph-label">
          <pre>{{ $t('COMMON_LABEL_FRI') }}</pre>
        </div>
        <div class="graph-label text-white">
          <pre>{{ $t('COMMON_LABEL_SAT') }}</pre>
        </div>
        <div class="graph-label">
          <pre>{{ $t('COMMON_LABEL_SUN') }}</pre>
        </div>
      </div>

      <div id="cal-heatmap"></div>
    </div>
  </div>

  <teleport to="body">
    <div
      v-if="isShowTooltip && currentDateChoose"
      v-click-outside="onClickOutSideCard"
      class="
        absolute
        top-0
        left-0
        z-100
        bg-white
        rounded
        shadow-md
        border border-gray-100
      "
      :style="[
        `top: ${cardPosition.y}px`,
        `left: ${cardPosition.x}px`,
        'height: max-content',
        'width: 350px',
      ]"
    >
      <StatisticOfDay
        :datas="currentDateChoose?.data"
        :date="currentDateChoose?.date"
      />
    </div>
  </teleport>
</template>

<style>
#cal-heatmap-yaxislabels {
  float: left;
  margin-right: 10px;
  margin-bottom: 25px;
  padding-left: 15px;
}

#cal-heatmap-yaxislabels > * {
  margin-bottom: 1px;
  display: block;
}

.cal-heatmap-container .q1 {
  background-color: #076260 !important;
  fill: #076260 !important;
}

.cal-heatmap-container .q2 {
  background-color: #4bb0b2 !important;
  fill: #4bb0b2 !important;
}

.cal-heatmap-container .q3 {
  background-color: #3b82f6 !important;
  fill: #3b82f6 !important;
}

.cal-heatmap-container .q4 {
  background-color: #93c5fd !important;
  fill: #93c5fd !important;
}

.cal-heatmap-container .q6 {
  background-color: #ea580c !important;
  fill: #ea580c !important;
}

.cal-heatmap-container .q5 {
  background-color: #facc15 !important;
  fill: #facc15 !important;
}

.cal-heatmap-container .q7 {
  background-color: #b91c1c !important;
  fill: #b91c1c !important;
}

.cal-heatmap-container .subdomain-text {
  font-size: 8px;
  fill: #fff !important;
  pointer-events: none;
}
</style>
