<script setup lang="ts">
import SetRepeats from '@/ui/modules/task/detail/SetRepeats.vue';
import { ref, computed } from 'vue';
import { ETaskRepeatType } from '@/application/interfaces/tasks/task-interfaces';

import {
  ITaskRepeat,
  ETaskMonthlySubRepeatTypeModel,
} from '@/application/interfaces/tasks/task-interfaces';
defineProps<{
  taskRepeat: any;
  startDate: any;
  originTemplate: any;
  isUpdateTemplate?: boolean;
}>();

defineEmits<{
  (e: 'onSave', data: any): void;
  (e: 'cancel'): void;
}>();

const setRepeatsRef = ref<any>(null);
const errorMessage = ref({
  startDate: '',
  endDate: '',
});
const onSaveRepeat = () => {
  if (isEmptyStartDate()) {
    errorMessage.value.startDate =
      'COMMON_LABEL_ERROR_MESSAGE_START_DATE_REPEAT_TASK';
  }
  if (isEmptyEndDate()) {
    errorMessage.value.endDate =
      'COMMON_LABEL_ERROR_MESSAGE_END_DATE_REPEAT_TASK';
  }
  if (isEmptyStartDate() || isEmptyEndDate()) {
    return;
  }

  setRepeatsRef.value?.onSave();
};

const isEmptyEndDate = () => {
  return (
    !endDate.value &&
    repeatType.value !== ETaskRepeatType.None &&
    repeatEndKey.value == 2 //set end date
  );
};

const isEmptyStartDate = () => {
  return !startDate.value && repeatType.value !== ETaskRepeatType.None;
};

const endDate = ref();
const startDate = ref();
const repeatEndKey = ref();
const repeatType = ref<ETaskRepeatType>();
// const isDisableInfiniteRepeat = ref<boolean>();
const taskRepeatData = ref<ITaskRepeat>({
  repeatType: ETaskRepeatType.None,
  subRepeatType: {
    weeklyOptions: [0],
    monthlyOptions: ETaskMonthlySubRepeatTypeModel.SpecificDayEachMonth,
    byMonth: [],
    byMonthday: [],
  },
  startDate: new Date(),
  endDate: undefined,
  triggerOn: null,
});
const updateData = (data) => {
  taskRepeatData.value = data;
  startDate.value = data?.startDate;
  endDate.value = data?.endDate;
  repeatType.value = data?.repeatType;
  repeatEndKey.value = data?.repeatEndKey;
  // isDisableInfiniteRepeat.value = data?.isDisableInfiniteRepeat;
  if (!isEmptyStartDate()) errorMessage.value.startDate = '';
  if (!isEmptyEndDate()) errorMessage.value.endDate = '';
};

const isDisableButton = computed<boolean>(() => {
  if (repeatType.value == ETaskRepeatType.None) return false;
  return (
    !startDate.value ||
    (repeatType.value == ETaskRepeatType.Weekly &&
      (!taskRepeatData.value?.subRepeatType?.weeklyOptions ||
        taskRepeatData.value?.subRepeatType?.weeklyOptions?.length == 0))
  );
});
</script>
<template>
  <SynModal
    z-index="z-50"
    container-class="w-max"
    :container-style="'max-height: 95%;min-height: 24rem; min-width: 24rem; overflow: hidden; position: unset;'"
    disable-click-outside
    is-hidden-close
    header-class="p-4 border-b"
    footer-class="border-t"
    style-body-string="position: static; overflow: hidden; padding: 0"
    :is-disable-button="isDisableButton"
    :cancel-text="$t('COMMON_LABEL_CANCEL')"
    :confirm-text="$t('COMMON_LABEL_SAVE')"
    @cancel="$emit('cancel')"
    @confirm="onSaveRepeat"
  >
    <template #header>
      <div class="font-semibold text-current-800">
        {{ $t('COMMON_LABEL_TASK_RECURRENCE') || 'Task recurrence' }}
      </div>
    </template>
    <template #body>
      <SetRepeats
        ref="setRepeatsRef"
        :task-repeat="taskRepeat"
        :start-date="startDate"
        :edit-mode="taskRepeat?.templateId ? 'EDIT' : 'CREATE'"
        :origin-template="originTemplate"
        :is-update-template="isUpdateTemplate"
        :error-message-end-date="errorMessage.endDate"
        :error-message-start-date="errorMessage.startDate"
        trigger-for-update="click"
        @on-save="(value) => $emit('onSave', value)"
        @update-data="updateData"
      />
    </template>
  </SynModal>
</template>
