import { v4 as uuidv4 } from 'uuid';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';
import {
    BucketObject,
    genBucketNameByModule,
    KindOfFile,
    ModuleSourceFile,
} from './AwsS3Type';
import { awsS3Delete, uploadFile, copyFile } from './aws-s3-services';

export default class AwsS3Class {
    module: ModuleSourceFile;
    bucketObj: BucketObject;
    organizationId: number;

    constructor(module: ModuleSourceFile) {
        this.module = module;
        this.bucketObj = genBucketNameByModule(module);
        this.organizationId =
            MyOrganizationSingleton.getInstance().activeOrganization;
    }

    upload(
        fileDataBody: Blob | Buffer,
        contentType: string,
        kindOfFile: KindOfFile,
        extension: string,
        options?: {
            tagging?: string;
            isBase64?: boolean;
        }
    ) {
        let sourceKey;

        const fileId = uuidv4();

        switch (kindOfFile) {
            case 'PHOTO':
                sourceKey = `${this.organizationId}/original/${fileId}.jpeg`;
                break;
            case 'MEDIA':
                sourceKey = `${this.organizationId}/media/${fileId}.${extension}`;
                break;

            default:
                sourceKey = `${this.organizationId}/file/${fileId}.${extension}`;
                break;
        }

        const bucketName = this.bucketObj[kindOfFile?.toUpperCase()];

        return uploadFile({
            Bucket: bucketName,
            Key: sourceKey,
            Body: fileDataBody,
            ContentType: contentType,
            ACL: 'public-read',
            Tagging: options?.tagging,
            ContentEncoding: options?.isBase64 ? 'base64' : undefined,
        });
    }
    async clone(
        source,
        kindOfFile: KindOfFile,
        extension: string,
        options?: {
            bucket?: string;
            tagging?: string;
        }
    ) {
        let newSourceKey;

        const fileId = uuidv4();

        switch (kindOfFile) {
            case 'PHOTO':
                newSourceKey = `${this.organizationId}/original/${fileId}.jpeg`;
                break;
            case 'MEDIA':
                newSourceKey = `${this.organizationId}/media/${fileId}.${extension}`;
                break;

            default:
                newSourceKey = `${this.organizationId}/file/${fileId}.${extension}`;
                break;
        }

        const bucketName = this.bucketObj[kindOfFile?.toUpperCase()];

        await copyFile({
            Bucket: bucketName,
            CopySource: encodeURI(
                `/${options?.bucket || bucketName}/${source}`
            ),
            Key: newSourceKey,
            ACL: 'public-read',
            Tagging: options?.tagging,
        });
        return {
            Bucket: bucketName,
            Key: newSourceKey,
        };
    }

    remove(fileKey, kindOfFile) {
        return awsS3Delete({
            Bucket: this.bucketObj[kindOfFile],
            Key: fileKey,
        });
    }
}
