<script setup lang="ts">
import { computed, ref, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import RegisterSelectAccountType from '@/ui/modules/organization/setup/RegisterSelectAccountType.vue';
import {
  EOrganizationModel,
  IOrganizationInfoPayload,
} from '@/application/types/organization/organization.type';
import stepperComposables from '@/ui/composables/app/stepper-composables';
import organizationStore from '@/store/organization';
import myProfileStore from '@/store/auth/my-profile';
import Register from '@/ui/pages/auth/Register.vue';
import RegisterDefineOrganization from '@/ui/modules/organization/setup/RegisterDefineOrganization.vue';
import RegisterCompleteOrganizationDetail from '@/ui/modules/organization/setup/RegisterCompleteOrganizationDetail.vue';
import RegisterCompletePersonalDetail from '@/ui/modules/organization/setup/RegisterCompletePersonalDetail.vue';
import AuthenticateClass, {
  ILoginResult,
} from '@/application/entities/authenticate/AuthenticateClass';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';
import appStore from '@/store/app';
import AttachOrganizationModal from '@/ui/modules/organization/setup/AttachOrganizationModal.vue';

const props = defineProps<{
  notAllowPersonal?: boolean;
}>();

const router = useRouter();
const route = useRoute();
const inviteCode = computed(() => route.query?.inviteCode?.toString() || '');
const _organizationStore = organizationStore();
enum REGISTER_STEP_KEYS {
  FIRST_STEP = 'FIRST_STEP',
  CREATING_ACCOUNT = 'CREATING_ACCOUNT',
  ORGANIZATION_SETUP = 'ORGANIZATION_SETUP',
  ORGANIZATION_FINISH = 'ORGANIZATION_FINISH',
  PERSONAL_SETUP = 'PERSONAL_SETUP',
}
const isCreateFetching = ref(false);
const loginInfo = ref<ILoginResult | null>(null);
const defaultNameByLang = {
  en: 'My workspace',
  fr: 'Espace de travail personnel',
  vi: 'Không gian cá nhân',
};
const onSubmit = async () => {
  isCreateFetching.value = true;
  const currentLanguage = appLanguage.value || 'en';
  const data = {
    ...infoPayload.value,
    name: infoPayload.value?.name || defaultNameByLang[currentLanguage],
    model: infoPayload.value?.model || EOrganizationModel.PERSONAL,
    category: infoPayload.value?.subCategory
      ? infoPayload.value?.subCategory
      : infoPayload.value?.category,
    type: infoPayload.value?.type || 'OTHER',
  };
  await _organizationStore.updateAndFinishSetupOrganization(data, true);
  if (loginInfo.value && loginInfo.value?.jwtToken)
    await new AuthenticateClass().handleSuccess({
      ...loginInfo.value,
      inviteCode: inviteCode.value,
    });

  isCreateFetching.value = false;
  //   emit('onFinish', data);
};

const isShowErrorCompanyModel = ref<boolean>(false);

const {
  previousStep,
  currentStep,
  stepperByKey,
  updateCurrentStep,
  onHandleGoToPreviousStep,
  onHandleGoToNextStep,
} = stepperComposables(
  {
    [REGISTER_STEP_KEYS.FIRST_STEP]: {
      key: REGISTER_STEP_KEYS.FIRST_STEP,
      previous: '',
      previousAction: () => {},
      nextAction: () => {
        if (infoPayload.value.personalType == 1) {
          updateCurrentStep(REGISTER_STEP_KEYS.ORGANIZATION_SETUP);
        } else {
          infoPayload.value.model = EOrganizationModel.PERSONAL;

          updateCurrentStep(REGISTER_STEP_KEYS.CREATING_ACCOUNT);
        }
      },
      buttonBack: '',
      buttonNext: 'COMMON_LABEL_NEXT',
    },
    [REGISTER_STEP_KEYS.CREATING_ACCOUNT]: {
      key: REGISTER_STEP_KEYS.CREATING_ACCOUNT,
      previous: REGISTER_STEP_KEYS.FIRST_STEP,
      next: REGISTER_STEP_KEYS.PERSONAL_SETUP,
      previousAction: () => {},
      nextAction: () => {},
      buttonBack: '',
      buttonNext: 'COMMON_LABEL_NEXT',
    },
    [REGISTER_STEP_KEYS.ORGANIZATION_SETUP]: {
      key: REGISTER_STEP_KEYS.ORGANIZATION_SETUP,
      previous: REGISTER_STEP_KEYS.FIRST_STEP,
      next: REGISTER_STEP_KEYS.ORGANIZATION_FINISH,
      previousAction: () => {},
      nextAction: () => {
        infoPayload.value.model =
          infoPayload.value?.category == 'NON-PROFIT_ORGANIZATIONS' &&
          _organizationStore.organizationModels?.some(
            (m) => m?.code == 'NON-PROFIT_ORGANIZATIONS'
          )
            ? EOrganizationModel.NON_PROFIT_ORGANIZATION
            : infoPayload.value?.category == 'TEAMS' &&
              _organizationStore.organizationModels?.some(
                (m) => m?.code == 'TEAM'
              )
            ? EOrganizationModel.TEAM
            : EOrganizationModel.COMPANY;
      },
      buttonBack: props.notAllowPersonal ? '' : 'COMMON_LABEL_PREVIOUS',
      buttonNext: 'COMMON_LABEL_NEXT',
    },
    [REGISTER_STEP_KEYS.ORGANIZATION_FINISH]: {
      key: REGISTER_STEP_KEYS.ORGANIZATION_FINISH,
      previous: REGISTER_STEP_KEYS.ORGANIZATION_SETUP,
      previousAction: () => {
        isCreateFetching.value = false;
      },
      nextAction: () => {
        if (infoPayload.value?.model) {
          updateCurrentStep(REGISTER_STEP_KEYS.CREATING_ACCOUNT);
        } else {
          isShowErrorCompanyModel.value = true;
        }
      },
      buttonBack: 'COMMON_LABEL_PREVIOUS',
      buttonNext: 'COMMON_LABEL_NEXT',
    },
    [REGISTER_STEP_KEYS.PERSONAL_SETUP]: {
      key: REGISTER_STEP_KEYS.PERSONAL_SETUP,
      previous: REGISTER_STEP_KEYS.FIRST_STEP,
      previousAction: () => {
        isCreateFetching.value = false;
      },
      nextAction: () => {
        const currentLanguage = appStore().language || 'en';
        const defaultNameByLang = {
          en: 'My workspace',
          fr: 'Espace de travail personnel',
          vi: 'Không gian cá nhân',
        };
        infoPayload.value.name = defaultNameByLang[currentLanguage];
        onSubmit();
      },
      buttonBack: 'COMMON_LABEL_PREVIOUS',
      buttonNext: 'COMMON_LABEL_FINISH',
    },
  },
  REGISTER_STEP_KEYS.FIRST_STEP
);

const checkToNextStepInitial = () => {
  // Register and attach to an organization
  if (inviteCode.value) {
    infoPayload.value.personalType = 2;
    onHandleGoToNextStep();
    return;
  }

  if (props.notAllowPersonal) {
    infoPayload.value.personalType = 1;
    onHandleGoToNextStep();
  }
};
onBeforeMount(() => {
  console.log('🚀 Tictop ~ inviteCode.value 12 123 2:', inviteCode.value);
  checkToNextStepInitial();
});

const linkWebsite = ref('http://tictop.vn');
const linkWebsiteLabel = ref('tictop.vn') as any;
const isEuLocation = ref(false);
const initLocation = () => {
  const location = getLocalStorage(StorageConstant.LOCATION);
  linkWebsite.value =
    location == 'EU' ? 'http://tictop.eu' : 'http://tictop.vn';
  linkWebsiteLabel.value = linkWebsite.value?.split('://').pop();

  isEuLocation.value = location == 'EU';
};

const infoPayload = ref<IOrganizationInfoPayload>({
  name: '',
  logo: '',
  model: EOrganizationModel.PERSONAL,
  type: '',
  size: '',
  address: '',
  addressDetail: '',
  phone: '',
  websiteUrl: '',
  taxCode: '',
  subType: '',
  personalType: undefined,
  knowAppFrom: undefined,
});

const isDisableNextButton = computed(() => {
  if (currentStep.value == REGISTER_STEP_KEYS.FIRST_STEP) {
    return !infoPayload.value.personalType;
  }
  if (currentStep.value == REGISTER_STEP_KEYS.ORGANIZATION_SETUP) {
    return !infoPayload.value?.category || !infoPayload.value?.size;
  }
  if (currentStep.value == REGISTER_STEP_KEYS.ORGANIZATION_FINISH) {
    return !infoPayload.value?.name?.trim();
  }
  if (currentStep.value == REGISTER_STEP_KEYS.PERSONAL_SETUP) {
    return false;
  }

  return false;
});

const defaultValueByLanguage = {
  en: {
    companyName: 'My Company',
    teamName: 'My Team',
    groupName: 'My Group',
    personalName: 'My workspace',
  },
  fr: {
    companyName: 'Mon entreprise',
    teamName: 'Mon équipe',
    groupName: 'Mon groupe',
    personalName: 'Espace de travail personnel',
  },
  vi: {
    companyName: 'Công ty của tôi',
    teamName: 'Team của tôi',
    groupName: 'Nhóm của tôi',
    personalName: 'Không gian cá nhân',
  },
};
const appLanguage = computed(() => appStore().language);
const createdAccountSuccess = async (loginResult: ILoginResult) => {
  loginInfo.value = loginResult;
  MyOrganizationSingleton.setInstance(
    loginResult.organizations,
    loginResult.organizationId,
    loginResult.jwtToken,
    loginResult.refreshToken,
    loginResult.numberOfLogins,
    loginResult.organizationSettingProgress ||
      loginResult.currentSettingProgress
  );
  myProfileStore().fetchCurrentLoginUser();
  console.log('🚀 Tictop ~ previousStep.value:', previousStep.value);
  if (previousStep.value == REGISTER_STEP_KEYS.FIRST_STEP) {
    const currentLanguage = appLanguage.value || 'en';
    infoPayload.value.name =
      defaultValueByLanguage[currentLanguage]?.personalName;
    infoPayload.value.type = 'OTHER';
    updateCurrentStep(REGISTER_STEP_KEYS.PERSONAL_SETUP);
  } else if (previousStep.value == REGISTER_STEP_KEYS.ORGANIZATION_FINISH) {
    onSubmit();
  }
};
const onBackFromRegisterForm = () => {
  if (previousStep.value) updateCurrentStep(previousStep.value);
};

const isOpenAttachOrg = ref<boolean>(false);

const onAttachOrgSuccess = (code, nextAction) => {
  isOpenAttachOrg.value = false;
  router.push({
    name: nextAction,
    query: {
      inviteCode: code,
    },
  });
};
initLocation();
</script>
<template>
  <Register
    v-if="currentStep == REGISTER_STEP_KEYS.CREATING_ACCOUNT"
    is-process-d
    :can-back="!inviteCode"
    :default-phone-number="infoPayload?.phone"
    :is-show-select-position="infoPayload.model != EOrganizationModel.PERSONAL"
    @on-back="onBackFromRegisterForm"
    @created-account-success="createdAccountSuccess"
  />
  <div
    v-else
    class="
      flex-center
      content-center
      items-center
      justify-center
      h-full
      w-full
      flex-col
    "
  >
    <!-- HEADER -->
    <section class="flex justify-between w-full p-2">
      <div class="cursor-pointer flex-center">
        <div class="flex-center space-x-2 ml-8 cursor-pointer">
          <div class="w-3 h-3 flex-center">
            <SynIcon
              name="global"
              custom-class="fill-current text-current w-3 h-3"
            />
          </div>
          <a
            class="text-sm hover:text-current font-medium"
            :href="linkWebsite"
            target="_blank"
          >
            {{ `www.${linkWebsiteLabel}` }}
          </a>
        </div>
      </div>
      <SettingLanguageSmall />
    </section>

    <!-- BODY -->
    <section class="flex-1 min-h-0 small-scrollbar w-full flex-center flex-col">
      <div class="flex flex-col mb-4">
        <syn-logo class-logo="h-14" class-label="w-32" />
      </div>
      <section
        class="min-w-[32rem]"
        :style="{
          width:
            currentStep == REGISTER_STEP_KEYS.ORGANIZATION_FINISH
              ? '26rem'
              : '66.66667%',
        }"
      >
        <template v-if="currentStep == REGISTER_STEP_KEYS.FIRST_STEP">
          <!-- REGISTER_STEP_KEYS.FIRST_STEP -->
          <RegisterSelectAccountType v-model="infoPayload.personalType" />
        </template>
        <template
          v-else-if="currentStep == REGISTER_STEP_KEYS.ORGANIZATION_SETUP"
        >
          <!-- REGISTER_STEP_KEYS.ORGANIZATION_SETUP -->

          <div class="w-full">
            <RegisterDefineOrganization
              v-model:size="infoPayload.size"
              v-model:category="infoPayload.category"
              v-model:sub-category="infoPayload.subCategory"
              v-model:type="infoPayload.type"
              v-model:domain-name="infoPayload.domainName"
              :organization-model="infoPayload.model"
              is-process-d
            />
          </div>
        </template>
        <template v-if="currentStep == REGISTER_STEP_KEYS.ORGANIZATION_FINISH">
          <!-- REGISTER_STEP_KEYS.FIRST_STEP -->
          <RegisterCompleteOrganizationDetail
            v-model:model="infoPayload.model"
            v-model:name="infoPayload.name"
            v-model:address="infoPayload.address"
            v-model:address-detail="infoPayload.addressDetail"
            v-model:type="infoPayload.type"
            v-model:domain-name="infoPayload.domainName"
            v-model:website-url="infoPayload.websiteUrl"
            v-model:phone="infoPayload.phone"
            :is-show-error-company-model="isShowErrorCompanyModel"
            @update:model="isShowErrorCompanyModel = false"
          />
        </template>
        <template v-else-if="currentStep == REGISTER_STEP_KEYS.PERSONAL_SETUP">
          <RegisterCompletePersonalDetail
            v-model:know-app-from="infoPayload.knowAppFrom"
            v-model:address="infoPayload.address"
            is-hidden-select-position
          />
        </template>
        <div class="text-center pt-6 w-full">
          <div class="flex-center gap-2 px-8 w-full">
            <AtomButton
              v-if="stepperByKey[currentStep]['buttonBack']"
              type="TEXT"
              color="gray"
              class="w-1/2"
              :label="$t(stepperByKey[currentStep]['buttonBack'])"
              @click="onHandleGoToPreviousStep"
            />
            <AtomButton
              :label="$t(stepperByKey[currentStep]['buttonNext'])"
              :disabled="isDisableNextButton"
              :is-loading="isCreateFetching"
              class="flex-1"
              @click="onHandleGoToNextStep"
            />
          </div>
          <div class="mt-4 flex items-baseline justify-center gap-2">
            <label class="text-gray-500 text-sm">{{
              $t('COMMON_LABEL_QUESTION_LOGIN') + ' '
            }}</label>
            <RouterLink :to="{ name: 'Login' }">
              <label
                class="
                  font-medium
                  text-current-600
                  cursor-pointer
                  hover:text-current-200
                  text-sm
                "
              >
                {{ $t('COMMON_MODULE_LOGIN') }}
              </label>
            </RouterLink>
          </div>
        </div>
      </section>
    </section>

    <!-- LINK DOWNLOAD  -->
    <section class="flex justify-center py-4 w-full">
      <SynLinkDownload class="font-medium" />
    </section>
  </div>

  <AttachOrganizationModal
    v-if="isOpenAttachOrg"
    is-not-authenticated
    @on-success="onAttachOrgSuccess"
    @on-cancel="isOpenAttachOrg = false"
  />
</template>
