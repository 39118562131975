<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { isOpenDrawer } from '@/ui/pages/tasks/calendar/unschedule-tasks-drawer-state';

import { Draggable } from '@fullcalendar/interaction';
import { formatTaskCode } from '@/ui/hooks/taskHook';
import { ignoreUnicode } from '@/ui/plugins/utils';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';

const props = defineProps<{
  unscheduleTasks: any[];
}>();

onMounted(() => {
  const containerEl = document.getElementById('draggable-el-container');

  new Draggable(containerEl, {
    itemSelector: '.draggable-el-item',
    eventDragStart: function () {
      isOpenDrawer.value = false;
      console.log('🚀 Tictop ~ isDragging:');
    },
  });
});

const searchText = ref('');

const filteredUnscheduleTasks = computed(() => {
  let result = props.unscheduleTasks;
  if (searchText.value) {
    const searchTxt = ignoreUnicode(searchText.value);
    result = result.filter(
      (data) => data?.name && ignoreUnicode(data?.name).includes(searchTxt)
    );
  }

  return arrayOrderBy(result, ['code', 'name'], ['asc', 'asc']);
});
</script>

<template>
  <div
    id="draggable-el-container"
    ref="tasksDrawerRef"
    class="
      fixed
      right-0
      bottom-0
      z-40
      transition
      duration-300
      transform
      bg-white
      shadow
    "
    :class="[
      isOpenDrawer ? 'translate-x-0 ease-out' : 'translate-x-full ease-in',
    ]"
    style="width: 320px; max-width: 100%; height: calc(100% - 184px)"
    @click.stop="() => {}"
  >
    <div class="w-full h-full flex flex-col">
      <div
        class="p-2 bg-gray-50 relative flex items-center gap-2 cursor-pointer"
        @click="isOpenDrawer = false"
      >
        <span class="text-sm font-semibold">
          {{ $t('COMMON_LABEL_UNSCHEDULED_TASKS') }}
        </span>

        <SynIcon name="CloseDrawer" />
      </div>
      <div class="flex-1 min-h-0 overflow-auto small-scrollbar relative">
        <div class="pt-2 px-1 w-[290px] sticky top-0 bg-white z-10">
          <VigSearchBox
            v-model="searchText"
            input-class="text-sm"
            style="width: 100%"
            autofocus
            :placeholder="$t('COMMON_LABEL_SEARCH')"
          />
        </div>
        <div
          v-for="task in filteredUnscheduleTasks"
          :key="task?.id"
          class="hover-to-show__parent draggable-el-item cursor-move"
          :data-event="JSON.stringify({ extendedProps: task })"
        >
          <div class="w-full flex items-center justify-start relative py-2">
            <div class="flex-center shrink-0" style="width: 2rem">
              <span class="flex-center hover-to-show__children">
                <SynIcon name="dragable" class="fill-gray-500" />
              </span>
            </div>
            <div class="flex-1 flex items-start gap-1 text-sm truncate">
              <span class="text-gray-500">
                {{ formatTaskCode(task?.code) }}
              </span>
              <span
                v-vig-tooltip="task?.name"
                class="truncate"
                :style="{
                  color: '#000',
                }"
              >
                {{ task?.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
