<script setup lang="ts">
import { computed, ref } from 'vue';
import { ITaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import ChangeAssign from '@/ui/modules/task/detail/ChangeAssign.vue';
import { getConfigScheduleColor } from '@/ui/hooks/taskHook';
import GroupById from '@/ui/components/group/GroupById.vue';
import UserById from '@/ui/components/user/UserById.vue';
import bindingInterfaceTaskComposables from '@/ui/composables/task/binding-interface-task';
import commonActionsTaskComposables from '@/ui/composables/task/common-actions-task';
import UpdateDomainModal from '@/ui/modules/task/detail/UpdateDomainModal.vue';
import myProfileStore from '@/store/auth/my-profile';
import UpdateDeadline from '@/ui/modules/task/detail/UpdateDeadline.vue';
import UpdateDeadlineTime from '@/ui/modules/task/detail/UpdateDeadlineTime.vue';
import { TaskApprovalStatus } from '@/application/types/task/task.types';
import { TaskLife } from '@/domain/enums/taskEnum';
import TaskApprovalShortInfo from '@/ui/modules/task/approval/TaskApprovalShortInfo.vue';
import TaskSetApprovalModal from '@/ui/modules/task/approval/TaskSetApprovalModal.vue';
import {
  settingGlobalModal,
  ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import TaskApprovalModal from '@/ui/modules/task/approval/TaskApprovalModal.vue';

const props = withDefaults(
  defineProps<{
    task: ITaskDetailClass;
    readonly?: boolean;
    actionList?: string[];
  }>(),
  {
    actionList: () => ['DEADLINE', 'ASSIGNED', 'CLONE'],
  }
);
defineEmits<{
  (e: 'onClone', task: any): void;
}>();

const myTimezone = computed(() => myProfileStore().timezone);

const { isEditable, getConfigScheduleName } = bindingInterfaceTaskComposables(
  props.task
);

const {
  taskDetail,
  isOpenUpdateDomainModal,
  isOpenRequestConfirmModal,
  viewModeProvideEvidence,
  onSaveDeadline,
  onUpdateStartTime,
  changeDeadlineTime,
  onChangeAssign,
  onCloseUpdateDomainModal,
  onSaveDomainAfterChangeGroup,
  onChangeTaskState,
  onChangeApprovalStatus,
} = commonActionsTaskComposables({
  currentTask: props?.task,
  currentTaskId: props?.task?.id,
});

const changeAssignRef = ref(null as any);

const isOpenChangeAssign = ref(false);
const onCancelAssign = () => {
  if (props.task?.id) {
    changeAssignRef.value?.onForceClose();
  }
  if (isOpenUpdateDomainModal.value) return;
  isOpenChangeAssign.value = false;
};

const isOpenUpdateDeadline = ref(false);
const scheduleRef = ref(null as any);

const onCancelDeadline = () => {
  isOpenUpdateDeadline.value = false;
  scheduleRef.value?.onForceClose();
};

const onChangeDeadline = (data) => {
  onSaveDeadline(data);

  onCancelDeadline();
};

const onChangeDeadlineTime = ({ dateTime, timezone }) => {
  changeDeadlineTime({
    scheduleTime: dateTime,
    scheduleByHour: true,
    scheduleTimezone: timezone,
  });
};

const onRemoveScheduleTime = async ({ dateTime }) => {
  changeDeadlineTime({
    scheduleTime: dateTime,
    scheduleByHour: false,
    scheduleTimezone: '',
  });
};

const onToggleFinish = (value) => {
  if (props.readonly) {
    settingGlobalModal({
      type: 'notification',
      title: translate('DECIDE_PAYMENT_HAS_VOUCHER_CONFIRM_SELECT_TRIAL_TITLE'),
      content:
        translate('TASK_LABEL_YOU_ARE_NOT_ALLOWED_TO_UPDATE_THIS_TASK') ||
        'You are on another call',
      confirmLabel: translate('COMMON_LABEL_CLOSE'),
      confirmable: true,
    });
    ask();
    return;
  }
  if (value) {
    onChangeTaskState(TaskLife.Finished);
  }
};
const isOpenApprovalModal = ref<boolean>(false);
const unMaskAsApproval = async () => {
  taskDetail.value.setTaskApproval({
    approvalEnabled: false,
    approverIds: [],
  });
};

const onCancelApprovalRequest = async () => {
  taskDetail.value.cancelRequestTaskApproval();
};

const onSetApprover = async (approverIds) => {
  isOpenApprovalModal.value = false;
  taskDetail.value.setTaskApproval({
    approvalEnabled: true,
    approverIds,
  });
};
</script>
<template>
  <div
    class="flex-center gap-2"
    :class="isEditable ? '' : 'cursor-not-allowed'"
  >
    <template v-if="actionList?.includes('CLONE') && !readonly">
      <span
        v-vig-tooltip="$t('SUB_TASK_LABEL_ACTION_DUPLICATE')"
        class="h-6 w-6 flex-center"
        @click="$emit('onClone', task)"
      >
        <span
          class="
            h-6
            w-6
            rounded-full
            border border-dashed
            flex-center
            hover:bg-current-50
            cursor-pointer
            hover-to-show__children
          "
        >
          <!-- {{ $t('TASK_DETAIL_LABEL_NO_SCHEDULE') || 'No schedule' }} -->
          <SynIcon name="Duplicate" custom-class="h-3 w-3 fill-gray-500" />
        </span>
      </span>
    </template>
    <template v-if="actionList?.includes('APPROVAL') && !readonly">
      <TaskApprovalShortInfo
        :approval-enabled="taskDetail?.approvalEnabled"
        :approval-status="taskDetail?.approvalStatus"
        :approvers="taskDetail?.approvers"
        :approver-id="taskDetail?.approverId"
        :readonly="!isEditable"
        is-show-sticker
        @on-edit="isOpenApprovalModal = true"
        @on-remove="unMaskAsApproval"
        @on-cancel-approval-request="onCancelApprovalRequest"
      />
    </template>
    <template v-if="actionList?.includes('FINISH')">
      <div
        v-if="
          !taskDetail?.approvalEnabled ||
          !taskDetail?.approvalStatus ||
          taskDetail?.approvalStatus !== TaskApprovalStatus.WAITING
        "
        :title="$t('TASK_UPDATE_MULTIPLE_SET_FINISHED') || 'Planned'"
        class="w-8 h-8 flex-center flex-col rounded-full group"
        :class="readonly ? 'cursor-not-allowed' : 'cursor-pointer'"
        @click="
          onToggleFinish(
            taskDetail?.taskLife === TaskLife.Finished ? false : true
          )
        "
      >
        <div class="flex-1 py-1">
          <SynIcon
            name="set-finished"
            :custom-class="
              taskDetail?.taskLife === TaskLife.Finished
                ? 'fill-green-500 w-4 h-4 group-hover:w-8 group-hover:fill-green-500'
                : 'fill-gray-500 w-4 h-4 group-hover:w-8 group-hover:fill-green-500'
            "
          />
        </div>
      </div>
    </template>
    <!-- DEADLINE -->
    <template v-if="actionList?.includes('DEADLINE')">
      <template v-if="isEditable && !readonly">
        <VigDropdown
          ref="scheduleRef"
          children-class-list="vc-day-content vc-focusable"
          hide-on-click="toggle"
          @on-dropdown-open="isOpenUpdateDeadline = true"
          @on-dropdown-close="isOpenUpdateDeadline = false"
        >
          <template #dropdown-toggle>
            <div
              v-vig-tooltip="$t('SUB_TASK_LABEL_ACTION_SET_A_DEADLINE')"
              class="flex-center hover-to-show__parent w-max cursor-pointer"
            >
              <span
                v-if="!taskDetail?.scheduleTime"
                class="h-6 w-6 flex-center"
              >
                <span
                  class="
                    h-6
                    w-6
                    rounded-full
                    border border-dashed
                    flex-center
                    hover:bg-current-50
                    hover-to-show__children
                  "
                >
                  <!-- {{ $t('TASK_DETAIL_LABEL_NO_SCHEDULE') || 'No schedule' }} -->
                  <SynIcon
                    name="Unschedule"
                    custom-class="h-3 w-3 fill-gray-500"
                  />
                </span>
              </span>

              <SynTag
                v-else
                :class="[getConfigScheduleColor(taskDetail?.taskSchedule)]"
                class="bg-white flex-center hover:bg-current-50"
                size="small"
              >
                <span
                  v-if="
                    taskDetail?.assigneeId && getConfigScheduleName(taskDetail)
                  "
                >
                  {{ getConfigScheduleName(taskDetail) }}
                </span>
                <SynLabelDateTime
                  v-else-if="taskDetail?.scheduleTime"
                  format="date"
                  :datetime="taskDetail?.scheduleTime"
                />
                <span v-else>
                  {{ $t('TASK_DETAIL_LABEL_NO_SCHEDULE') || 'No schedule' }}
                </span>
              </SynTag>
            </div>
          </template>
          <template #dropdown-menu>
            <div class="p-2 w-[25rem]">
              <UpdateDeadline
                v-if="isOpenUpdateDeadline"
                :schedule-key="taskDetail?.taskSchedule?.scheduleOptionKey"
                :other-time="taskDetail?.taskSchedule.otherTime"
                :schedule="taskDetail?.scheduleTime"
                :schedule-timezone="taskDetail?.scheduleTimezone"
                :user-id="taskDetail?.assigneeId"
                :group-id="taskDetail?.groupId"
                :start-time="taskDetail?.startTime"
                @cancel="onCancelDeadline"
                @save="onChangeDeadline"
                @on-change-start-time="onUpdateStartTime"
              >
                <template #scheduleTime>
                  <UpdateDeadlineTime
                    v-if="!taskDetail?.scheduleByHour"
                    :schedule-time="taskDetail?.scheduleTime"
                    :schedule-by-hour="taskDetail?.scheduleByHour"
                    :schedule-timezone="taskDetail?.scheduleTimezone"
                    :my-timezone="myTimezone"
                    @on-change="onChangeDeadlineTime"
                    @on-remove-time="onRemoveScheduleTime"
                  />
                </template>
              </UpdateDeadline>
            </div>
          </template>
        </VigDropdown>
        <div>
          <UpdateDeadlineTime
            v-if="taskDetail?.scheduleByHour"
            :class="[getConfigScheduleColor(taskDetail?.taskSchedule)]"
            :schedule-time="taskDetail?.scheduleTime"
            :schedule-by-hour="taskDetail?.scheduleByHour"
            :schedule-timezone="taskDetail?.scheduleTimezone"
            :my-timezone="myTimezone"
            @on-change="onChangeDeadlineTime"
            @on-remove-time="onRemoveScheduleTime"
          />
        </div>
      </template>
      <template v-else>
        <SynTag
          v-if="taskDetail?.scheduleTime"
          :class="[getConfigScheduleColor(taskDetail?.taskSchedule)]"
          class="bg-white flex-center hover:bg-current-50"
          size="small"
        >
          <span v-if="taskDetail?.assigneeId && getConfigScheduleName(task)">
            {{ getConfigScheduleName(task) }}
          </span>
          <SynLabelDateTime
            v-else-if="taskDetail?.scheduleTime"
            format="date"
            :datetime="taskDetail?.scheduleTime"
          />
          <span v-else>
            {{ $t('TASK_DETAIL_LABEL_NO_SCHEDULE') || 'No schedule' }}
          </span>
        </SynTag>
      </template>
    </template>

    <!-- RESPONSIBLE -->
    <template v-if="actionList?.includes('ASSIGNED')">
      <VigDropdown
        v-if="isEditable && !readonly"
        ref="changeAssignRef"
        :hide-on-click="'outside'"
        @on-dropdown-open="isOpenChangeAssign = true"
        @on-dropdown-close="isOpenChangeAssign = false"
      >
        <template #dropdown-toggle>
          <div class="flex-center hover-to-show__parent cursor-pointer">
            <UserById
              v-if="taskDetail?.assigneeId"
              is-hidden-name
              avatar-class="w-5 h-5 text-xs"
              :user-id="taskDetail?.assigneeId"
            />
            <GroupById
              v-else-if="taskDetail?.groupId"
              :group-id="taskDetail?.groupId"
              avatar-class="w-5 h-5 text-xs"
              is-hidden-name
            />
            <SynAvatar v-else custom-class="w-5 h-5" />
          </div>
        </template>
        <template #dropdown-menu>
          <div class="p-2" style="width: 28rem">
            <ChangeAssign
              v-if="isOpenChangeAssign"
              v-permission="'FunctionTaskAssign'"
              class="text-xs"
              is-update-instant
              is-multi-assign
              :group-id="taskDetail?.groupId || 0"
              :user-id="taskDetail?.assigneeId || 0"
              :domain-id="taskDetail?.domainId || 0"
              :project-id="taskDetail?.projectId || 0"
              :subproject-id="taskDetail?.subprojectId || 0"
              :collaborators="taskDetail?.collaborators"
              @on-cancel="onCancelAssign"
              @on-save="onChangeAssign"
            />
          </div>
        </template>
      </VigDropdown>
      <template v-else>
        <div class="flex-center">
          <UserById
            v-if="taskDetail?.assigneeId"
            :user-id="taskDetail?.assigneeId"
            is-hidden-name
            is-show-action-user
            avatar-class="w-5 h-5"
          />
          <GroupById
            v-else-if="taskDetail?.groupId"
            :group-id="taskDetail?.groupId"
            avatar-class="w-5 h-5 text-xs"
            is-hidden-name
          />
          <SynAvatar v-else custom-class="w-5 h-5" />
        </div>
      </template>
    </template>
  </div>

  <UpdateDomainModal
    v-if="isOpenUpdateDomainModal"
    :task-detail="{
      ...task,
      assigneeId: taskDetail?.assigneeId,
      groupId: taskDetail?.groupId,
      domainId: null,
      projectId: null,
      subprojectId: null,
    }"
    @cancel="onCloseUpdateDomainModal"
    @save="onSaveDomainAfterChangeGroup"
  />

  <TaskSetApprovalModal
    v-if="isOpenApprovalModal"
    :task="taskDetail"
    @on-close="isOpenApprovalModal = false"
    @on-save="onSetApprover"
  />

  <TaskApprovalModal
    v-if="isOpenRequestConfirmModal"
    :task="task"
    :approval-status="TaskApprovalStatus.WAITING"
    :view-mode="viewModeProvideEvidence"
    @on-close="isOpenRequestConfirmModal = false"
    @confirm="
      (comment) => onChangeApprovalStatus(TaskApprovalStatus.WAITING, comment)
    "
  />
</template>
