<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g id="Group_3054" data-name="Group 3054" transform="translate(0 0)">
      <path
        id="Path_1014"
        data-name="Path 1014"
        d="M8.541,8.176a1.249,1.249,0,1,1-.972-.786L9.514,4.982a.625.625,0,0,1,.972.785Zm-.8,6.563c-.122.007-.246.011-.369.011A6.125,6.125,0,1,1,13.5,8.511a.625.625,0,1,0,1.25-.023A7.375,7.375,0,0,0,8,1.277V.625a.625.625,0,0,0-1.25,0v.651A7.375,7.375,0,0,0,2.16,13.84,7.327,7.327,0,0,0,7.375,16c.148,0,.3,0,.443-.013a.625.625,0,0,0-.074-1.248Zm3.888-13.2,1.686,1.4a.625.625,0,0,0,.8-.962L12.43.582a.625.625,0,1,0-.8.962ZM1.033,3.087a.622.622,0,0,0,.395-.141l1.717-1.4a.625.625,0,0,0-.79-.969L.638,1.978a.625.625,0,0,0,.4,1.109ZM16,12.25a1.105,1.105,0,0,1-.535.952L11.13,15.837a1.114,1.114,0,0,1-1.692-.952V9.614a1.114,1.114,0,0,1,1.692-.952L15.465,11.3A1.105,1.105,0,0,1,16,12.25Zm-1.376,0L10.687,9.857v4.786Z"
      />
    </g>
  </svg>
</template>
