<script setup lang="ts">
import { ChatMessageModel } from '@/application/models/chat/ChatMessageModel';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { chain } from 'lodash';
import ChatMessage from '@/ui/modules/messaging/chat-panel/chat-message/ChatMessage.vue';
import userStore from '@/store/user';
import { formatDate } from '@/ui/plugins/filters';
import ModalMessageForward from '@/ui/modules/messaging/chat-panel/modal-message-forward/ModalMessageForward.vue';
import { prepareMessage } from '@/ui/plugins/firebases/firestore-database/constants';
import { ChatMessageAction } from '@/domain/enums/chat-enums';
import { ChatConversationModel } from '@/application/models/chat/ChatConversationModel';
import ChatService from '@/application/services/ChatService';

const props = defineProps<{
  organizationId: number;
  conversationId: string;
  conversation: ChatConversationModel;
}>();

defineEmits([
  'onClose',
  'onMessageReply',
  'onMessageViewFile',
  'onMessageTaskClick',
]);

const _userStore = userStore();

const pinnedMessages = ref<ChatMessageModel[]>();
const forwardingMessage = ref<ChatMessageModel>();

const allUserByIds = computed(() => _userStore.allUserByIds);

const chatMsgAllowedActions = [
  ChatMessageAction.REPLY,
  ChatMessageAction.FORWARD,
  ChatMessageAction.PIN,
  ChatMessageAction.COPY,
  ChatMessageAction.DOWNLOAD_FILE,
  ChatMessageAction.CREATE_TASK,
];

let _unsubscribePinnedMessages: any;

watch(
  () => props.conversationId,
  () => _subscribePinnedMessages()
);

onMounted(() => {
  _subscribePinnedMessages();
});

onUnmounted(() => {
  if (_unsubscribePinnedMessages) _unsubscribePinnedMessages();
});

const _subscribePinnedMessages = () => {
  if (_unsubscribePinnedMessages) _unsubscribePinnedMessages();

  _unsubscribePinnedMessages = ChatService.subscribePinnedMessages(
    props.organizationId,
    props.conversationId,
    (snapshot) => {
      pinnedMessages.value = chain(snapshot?.docs)
        .map((doc) => ({ ...doc?.data(), id: doc?.id }))
        .map((msg) => prepareMessage(msg?.id, msg))
        .orderBy(['pinnedDate'], ['desc'])
        .value();
    }
  );
};
</script>

<template>
  <SynModal
    z-index="z-50"
    container-class="w-11/12 md:w-1/2 xl:w-1/3 min-h-[50vh]"
    style-body="p-0 flex flex-col"
    :disable-click-outside="true"
    :is-hidden-footer="true"
    @cancel="$emit('onClose')"
  >
    <template #header>
      <div>{{ $t('CHAT_MESSAGES_PINNED') || 'Pinned messages' }}</div>
    </template>

    <template #body>
      <div class="px-3 overflow-auto small-scrollbar">
        <div
          v-for="message in pinnedMessages"
          :key="message?.id"
          class="border-t pt-2 pb-1 relative group hover:bg-gray-50"
        >
          <ChatMessage
            :organization-id="organizationId"
            :conversation-id="conversationId"
            :conversation="conversation"
            :message="message"
            :message-id="message?.id"
            :members="allUserByIds"
            :is-show-pinned-only="true"
            :is-first-in-group="true"
            :is-last-in-group="true"
            :allowed-actions="chatMsgAllowedActions"
            @on-message-forward="forwardingMessage = message"
            @on-reply-click="$emit('onMessageReply', message)"
            @on-file-click="$emit('onMessageViewFile', $event)"
            @on-task-click="$emit('onMessageTaskClick', $event)"
            @on-message-create-task="$emit('onClose')"
          />

          <div class="absolute top-2 right-2 text-xs">
            {{ formatDate(message?.pinnedDate, 'MMM DD, YYYY, hh:mm A') }}
          </div>

          <div
            v-if="false"
            class="absolute bottom-2 right-2 invisible group-hover:visible"
          >
            <VigButton
              light
              color="current"
              rounded="rounded-3xl"
              class="text-sm"
            >
              <span>Đi tới tin nhắn</span>
              <SynIcon name="ChevronRight" custom-class="w-3 h-3" />
            </VigButton>
          </div>
        </div>
      </div>

      <div
        v-if="!pinnedMessages?.length"
        class="px-4 py-16 flex flex-col items-center gap-3"
      >
        <VigImage
          class="h-48"
          path="https://d1tvqetrcurhzb.cloudfront.net/common/images/no-message-pin.png"
        />
        <div class="text-gray-500">
          {{
            $t('CHAT_NO_MESSAGES_PINNED') || 'There are no pinned messages yet'
          }}
        </div>
      </div>
    </template>
  </SynModal>

  <ModalMessageForward
    v-if="forwardingMessage"
    :message="forwardingMessage"
    @on-close="forwardingMessage = null"
  />
</template>
