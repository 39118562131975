<script setup lang="ts">
import { onMounted } from 'vue';
import whiteboardStore from '@/store/whiteboard';

// const whiteboardLink = computed<string>(() => {
//   const currentWhiteboardId = whiteboardStore().currentWhiteboardId;
//   if (currentWhiteboardId) return `/whiteboards/detail/${currentWhiteboardId}`;
//   return `/whiteboards`;
// });
onMounted(() => {
  whiteboardStore().getAllWhiteboardByUser();
});
</script>
<template>
  <router-view></router-view>
</template>
