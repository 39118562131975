<script setup lang="ts">
import { computed, ref } from 'vue';
import { onToggleSettingPage } from '@/ui/hooks/useSidebar';
import { useRoute, useRouter } from 'vue-router';
import authStore from '@/store/auth';
import {
  getCurrentUserId,
  getLocalStorage,
  StorageConstant,
} from '@/ui/hooks/storageHook';
import PersonalSidebarMessage from './PersonalSidebarMessage.vue';
import PersonalSidebarResources from './PersonalSidebarResources.vue';
import PersonalSidebarFavorites from './PersonalSidebarFavorites.vue';
import PersonalSidebarCalendars from './PersonalSidebarCalendars.vue';
import { decryptUserId, decryptGroupId } from '@/ui/plugins/crypto/crypto';
import PersonalSidebarItem from './PersonalSidebarItem.vue';
import CreateGroupTask from '@/ui/modules/group/create/CreateGroupTask.vue';
import CallingStatus from '@/ui/modules/video-call/components/CallingStatus.vue';
import appStore from '@/store/app';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import HelpSupportIcon from '@/ui/modules/contact-help/helps/HelpSupportIcon.vue';
import remoteConfigStore from '@/store/remoteConfig';
import myProfile from '@/store/auth/my-profile';
// import {AdminType} from '@/ui/common/constants/constant';
import SubMenuSideBar from '@/ui/components/layouts/sidebar/SubMenuSideBar.vue';
import { dashboardMenuList } from '@/ui/app-global-state';
import ChatSupportButton from '@/ui/modules/messaging/chat-support/chat-support-button/ChatSupportButton.vue';
import organizationStore from '@/store/organization';
import AlertVerifyEmail from '@/ui/components/auth/AlertVerifyEmail.vue';
// import whiteboardStore from '@/store/whiteboard';
import { isOpenTaskDrawer } from '@/ui/modules/task/task-global-state';
import taskListStore from '@/store/task/task-list-store';
import TotalPinnedTasksGlobal from '@/ui/modules/task/pinned/TotalPinnedTasksGlobal.vue';
import permissionStore from '@/store/permission';
import settingStore from '@/store/setting';
import PersonalUpgradeModal from '@/ui/modules/organization/PersonalUpgradeModal.vue';

defineProps<{
  isTaskDetailLayout?: boolean;
}>();

const route = useRoute();
const router = useRouter();
const _authStore = authStore();
const _appStore = appStore();
const _remoteConfigStore = remoteConfigStore();
const _myProfile = myProfile();
const _organizationStore = organizationStore();
const _taskListStore = taskListStore();
const _settingStore = settingStore();

const location = getLocalStorage(StorageConstant.LOCATION);
const webShowHelpFunction = computed(
  () => _remoteConfigStore.webShowHelpFunction
);

const isProfile = computed(() => router.currentRoute.value.meta.isProfile);
const isOpenMenuBar = computed(() => _appStore.isOpenMenuBar);
const currentUserId = computed(() => decryptUserId(route.query?.userId));
const currentGroupId = computed(() => decryptGroupId(route.query?.groupId));
const myCallingStatus = computed(() => _authStore.callingStatus);
const myCurrentCallId = computed(() => myCallingStatus.value?.callId);
const organizationInfo = computed(() => _organizationStore.organizationInfo);
const isAdmin = computed(() => _myProfile.isAdmin || _myProfile.isOwner);
const isUser = computed(() => _myProfile.isUser);
// const isVisitor = computed(() => _myProfile.isVisitor);
const currentLinkActive = computed(() => {
  return route.name ? route.name.toString().toLowerCase() : '';
});

const currentActive = computed(() => {
  if (route.name === 'Tasks') {
    return currentGroupId.value
      ? `group_${currentGroupId.value}`
      : `user_${currentUserId.value ?? getCurrentUserId()}`;
  }
  // console.log('route', route.name ? route.name.toString().toLowerCase() : '');
  return route.name ? route.name.toString().toLowerCase() : '';
});

const isPositionHomePage = computed(
  () =>
    currentActive.value === 'overviewmembers' ||
    currentActive.value === 'overviewgroups' ||
    (currentActive?.value === 'overviewdomains' &&
      !domainManagement.value.showOnMenu) ||
    currentActive?.value === 'overviewcreatedtasks' ||
    currentActive?.value === 'overviewfinishedtasks' ||
    currentActive?.value === 'overviewmonitorpage'
);
const isMyHomePage = computed(() => currentActive.value == 'mytasks');
const isAddGroup = ref(false);
const helpSupportButton = ref();

const linkHomeByAdminType = computed(() => {
  if (
    (_myProfile.isAdmin || _myProfile.isOwner) &&
    organizationInfo.value.members?.length >= 5
  )
    return `/dashboard/monitor`;
  if (_myProfile.isVisitor) return `/dashboard/groups`;
  return `/dashboard/members`;
});

// const whiteboardLink = computed<string>(() => {
//   const currentWhiteboardId = whiteboardStore().currentWhiteboardId;
//   if (currentWhiteboardId) return `/whiteboards/detail/${currentWhiteboardId}`;
//   return `/whiteboards`;
// });

const linkMyTasks = computed(() => {
  return `/tasks/my-tasks`;
});

const domainManagement = computed(
  () => _settingStore._organizationSetting.domainManagement
);

const gotoMyTasks = () => {
  isOpenTaskDrawer.value = false;
  _taskListStore.lastUpdatedTime = Date.now();
  router.push({ name: 'MyTasks' });
};

const onClickPinIcon = (event) => {
  event.preventDefault();
};

const isPersonalModel = computed(
  () => permissionStore().organizationModel === 'PERSONAL'
);

const isOpenUpgradeModal = ref<boolean>(false);
const onOpenUpgradeModal = () => {
  isOpenUpgradeModal.value = true;
};
</script>

<template>
  <div
    id="team_side_bar"
    class="flex flex-col h-full w-full transition duration-300 transform"
  >
    <CallingStatus v-if="myCurrentCallId" />
    <nav class="flex-1 h-full w-full">
      <!-- header sidebar -->
      <div
        class="
          h-full
          flex flex-col
          justify-between
          overflow-y-auto
          small-scrollbar
          relative
        "
      >
        <div class="flex-1 min-h-0 w-full relative">
          <div class="max-h-full w-full px-2 flex flex-col space-y-1">
            <div v-permission-model class="w-full">
              <SubMenuSideBar
                :list-sub-menu="dashboardMenuList"
                :current-link-active="currentLinkActive"
              >
                <template #menu-parent>
                  <PersonalSidebarItem
                    icon-name="home"
                    :is-active="isPositionHomePage"
                    :is-hidden-text="!isOpenMenuBar"
                    is-sub-menu
                    :item-name="$t('COMMON_MODULE_OVERVIEW') || 'Home'"
                    :link-to="linkHomeByAdminType"
                  />
                </template>
              </SubMenuSideBar>
            </div>
            <div v-if="isPersonalModel" class="w-full">
              <PersonalSidebarItem
                icon-name="note"
                :is-active="!!isMyHomePage"
                :is-hidden-text="!isOpenMenuBar"
                is-sub-menu
                :item-name="$t('TASKTAB_LABEL_MYTASK') || 'Home'"
                :link-to="linkMyTasks"
                @click="gotoMyTasks"
              >
                <template #suffix>
                  <div class="px-2 logo-label" @click.stop="onClickPinIcon">
                    <button
                      class="
                        w-full
                        space-x-1
                        rounded
                        flex-center
                        min-w-max
                        hover:border-current-500
                        hover:bg-current-50
                        hover:bg-opacity-50
                        hover:ring
                        hover:ring-current
                        hover:ring-opacity-30
                      "
                    >
                      <TotalPinnedTasksGlobal />
                    </button>
                  </div>
                </template>
              </PersonalSidebarItem>
            </div>
            <!-- whiteboard -->
            <!-- <div
              v-system-config="{
                functionCode: 'DRAFT_WHITEBOA_WHITEBOARD_FUNCTIONS_ALLOWED',
                functionValue: '1',
              }"
            >
              <PersonalSidebarItem
                icon-name="desktop"
                :is-active="currentActive.includes('whiteboard')"
                :is-hidden-text="!isOpenMenuBar"
                :item-name="$t('COMMON_LABEL_MY_DESKTOP') || 'My virtual desk'"
                :link-to="whiteboardLink"
              />
            </div> -->
            <!-- chat & call -->
            <PersonalSidebarMessage :current-active="currentActive" />
            <!-- domains -->
            <div
              v-if="(isAdmin || isUser) && domainManagement.showOnMenu"
              v-permission="'DomainOverview'"
              class="w-full hover-to-show__parent"
            >
              <PersonalSidebarItem
                icon-name="project"
                :is-active="
                  currentActive === 'overviewdomains' ||
                  currentActive === 'domaintasks'
                "
                :is-hidden-text="!isOpenMenuBar"
                :item-name="
                  $t('COMMON_MODULE_PROJECT_MANAGEMENT') || 'My Domains'
                "
                :link-to="`/dashboard/domains`"
              />
            </div>
            <!-- resource -->
            <PersonalSidebarResources :current-active="currentActive" />
            <!-- calendar -->
            <PersonalSidebarCalendars :current-active="currentActive" />
            <!-- favorite -->
            <PersonalSidebarFavorites :current-active="currentActive" />
          </div>
        </div>

        <div v-if="!isProfile" class="p-2">
          <AlertVerifyEmail is-on-side-bar />
        </div>
        <!-- footer sidebar -->
        <div>
          <div v-if="isPersonalModel && isAdmin" class="p-2">
            <div
              class="
                flex-center
                text-white text-sm
                font-medium
                space-x-2
                rounded
                px-2
                py-1.5
                w-full
                cursor-pointer
              "
              :style="`background-image: linear-gradient(
                      -225deg,
                      #008e8e 0%,
                      #00b0b8 100%
                    );`"
              @click="onOpenUpgradeModal"
            >
              <syn-icon name="crown" custom-class="w-4 h-4 fill-white" />
              <span v-if="isOpenMenuBar">{{ $t('PAYMENT_UPGRADE') }}</span>
            </div>
          </div>
          <div class="p-2"></div>
          <div
            class="flex items-stretch bg-white w-full relative shadow"
            :class="
              isOpenMenuBar
                ? 'justify-between'
                : 'justify-center flex-col-reverse space-y-2'
            "
          >
            <div
              class="hover:bg-current-50 cursor-pointer px-4 py-3 w-full"
              :class="isOpenMenuBar ? 'space-x-2 flex' : 'flex-center'"
              @click="onToggleSettingPage(true, $router, $route)"
            >
              <SynIcon custom-class="w-5 h-5 fill-gray-500" name="settings" />
              <div v-if="isOpenMenuBar" class="text-menu_collapse flex-1">
                <span class="text-sm font-semibold truncate text-gray-600">
                  {{ $t('SIDEBAR_LABEL_PREFERENCES') || 'Preferences' }}
                </span>
              </div>
            </div>
            <ChatSupportButton
              v-if="!isTaskDetailLayout"
              class="flex-center py-2 px-2.5 hover:bg-current-50"
            >
              <syn-icon
                name="ChatSupport"
                custom-class="w-6 h-6 fill-current"
              />
            </ChatSupportButton>
            <div
              v-if="
                !isTaskDetailLayout && webShowHelpFunction && location !== 'EU'
              "
              class="flex-center p-2 hover:bg-current-50 cursor-pointer"
              @click="helpSupportButton.onOpenShowHelpPanel"
            >
              <HelpSupportIcon
                ref="helpSupportButton"
                custom-class="fill-current"
              />
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>

  <CreateGroupTask v-if="isAddGroup" @cancel="isAddGroup = false" />
  <PersonalUpgradeModal
    v-if="isOpenUpgradeModal"
    @on-cancel="isOpenUpgradeModal = false"
  />
</template>
