<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import dayjs from 'dayjs';
import 'intro.js/minified/introjs.min.css';
import 'intro.js/themes/introjs-modern.css';
import '@/ui/plugins/firebases/init-app';
import initChatStore from './logic/init-chat-store';

import dashboardStore from '@/store/dashboard';
import taskDrawerStore from '@/store/task/drawer';
import systemStore from '@/store/system';
import appStore from '@/store/app';
import groupStore from '@/store/group';
import overviewGroupStore from '@/store/dashboard/overview-group-store';
import userStore from '@/store/user';
import settingStore from '@/store/setting';
import favoriteStore from '@/store/favorite/favoriteStore';
import domainStore from '@/store/scope';
import organizationStore from '@/store/organization';
import authStore from '@/store/auth';
import conferenceStore from '@/store/conference';
import remoteConfigStore from '@/store/remoteConfig';
import overviewTeamStore from '@/store/dashboard/overview-team-store';
import myProfileStore from '@/store/auth/my-profile';
import plansStore from '@/store/plans';
import taskStore from '@/store/task';
import taskCreateFormStore from '@/store/task/create-form';
import chatStore from '@/store/chat';

import OrganizationDynamicModel from '@/ui/plugins/firebases/realtime-database/model/OrganizationDynamicModel';
import UserDynamicModel from '@/ui/plugins/firebases/realtime-database/model/UserDynamicModel';
import { translate } from '@/ui/plugins/i18n/myi18n';

import RouterInstanceClass from '@/ui/router/RouterInstanceClass';
import customDocumentTitle from '@/ui/composables/app/document-title';

// import { diff } from '@/ui/hooks/hook-date';
import { hasRolePermission } from '@/ui/hooks/permission/permission-by-role';
import { isOpenUserBar } from '@/ui/hooks/useSidebar';
import { loginAgain, taskListRouteByType } from '@/ui/hooks/commonHook';
import {
  clearLocalStorage,
  getCurrentUserId,
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
  StorageConstant,
} from '@/ui/hooks/storageHook';

import { UserStatusEnum } from '@/domain/enums/UserStatusEnum';
// import { DomainActionType } from '@/domain/enums/domain-enums';
import { UserCallingStatus } from '@/domain/enums/VideoCallEnum';
import {
  AdminType,
  FavoriteType,
  SettingProgressEnum,
} from '@/ui/common/constants/constant';
import { stateDeployments } from '@/ui/common/constants/system';

import { IVoucherResponse } from '@/application/types/CommonTypes';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';
import { EPaymentPurpose } from '@/application/enums/PaymentEnums';

import {
  attachmentToTaskGlobal,
  isOpenTaskDrawer,
  isShowTaskDetailGlobal,
  taskCodeGlobal,
  taskIdGlobal,
} from '@/ui/modules/task/task-global-state';
import {
  checkBrowserPermission,
  dayoffIdGlobal,
  eventIdGlobal,
  gedIdGlobal,
  isCreateGroup,
  isOpenModalInvite,
  isShowDayoffDetail,
  isShowPageGedDetail,
  isShowPlanAndPricingOverviewModal,
} from '@/ui/app-global-state';

import { onListenMessage } from '@/ui/plugins/firebases/firebaseFcm';
import listenFirebaseRealtime from '@/ui/composables/app/listen-firebase-realtime';

import SettingSidebar from '@/ui/components/layouts/sidebar/SettingSidebar.vue';
import ChatWidget from '@/ui/modules/messaging/chat-widget/ChatWidget.vue';
import VideoRinging from '@/ui/modules/conference/Ringing.vue';
import ReminderAlert from '@/ui/modules/calendar/reminder/ReminderAlert.vue';
import DeploymentBar from '@/ui/modules/systems/deployment/DeploymentBar.vue';
import AnnouncementsNewDeployment from '@/ui/modules/systems/announcements/AnnouncementsNewDeployment.vue';
import ModalProfileUser from '@/ui/components/user/ModalProfileUser.vue';
import TaskAddAttachments from '@/ui/modules/task/create/TaskAddAttachments.vue';
import TaskDetailModal from '@/ui/modules/task/detail/TaskDetailModal.vue';
import ButtonAvatars from '@/ui/modules/avatars/ButtonAvatars.vue';
// import AlertVerifyEmail from '@/ui/components/auth/AlertVerifyEmail.vue';
import PersonalSidebar from '@/ui/components/layouts/sidebar/PersonalSidebar.vue';
import TictopHeader from './Header.vue';
import ModalProfileFirstLogin from '@/ui/modules/member/profile-first-login/ModalProfileFirstLogin.vue';
import GedPageModal from '@/ui/modules/ged/ged-modal/GedPageModal.vue';
import DayoffDetailModal from '@/ui/components/dayoff/DayoffDetailModal.vue';
import CreateGroupTask from '@/ui/modules/group/create/CreateGroupTask.vue';
import InviteUserModal from '@/ui/modules/member/invite-user/InviteUserModal.vue';
import SelectOrganizationModelModal from '@/ui/modules/organization/setup/SelectOrganizationModelModal.vue';
import ModalViewMeeting from '@/ui/modules/calendar/modal-view-event/ModalViewMeeting.vue';
import PaymentResult from '@/ui/modules/payments/PaymentResult.vue';
import DecidePaymentModal from '@/ui/modules/payments/DecidePaymentModal.vue';
import PlanAndPaymentNotificationBar from '@/ui/modules/plan/PlanAndPaymentNotificationBar.vue';
import ToogleCollapseMenu from '@/ui/components/general/ToogleCollapseMenu.vue';
import HelpPanel from '@/ui/modules/contact-help/helps/HelpPanel.vue';
import departmentStore from '@/store/department';
import RealtimeOrganizationSettings from '@/ui/components/realtimes/RealtimeOrganizationSettings.vue';
import RealtimeUserDepartment from '@/ui/components/realtimes/RealtimeUserDepartment.vue';
import SynLinkDownload from '@/ui/common/atoms/SynLinkDownload.vue';
import permissionStore from '@/store/permission';
import { EPlanState, IOrgCurrentPlan } from '@/application/types/plan.types';
import { diff } from '@/ui/hooks/hook-date';
import initDashboardMenu from '@/ui/components/layouts/logic/init-dashboard-menu';
import TictopWebSocket, {
  USER_REG_EVENT,
} from '@/ui/plugins/websocket/ws-instance';
import TaskDetailDrawer from '@/ui/modules/task/detail/TaskDetailDrawer.vue';
import taskListStore from '@/store/task/task-list-store';
import taskCustomConfigStore from '@/store/task/task-custom-config';
import localConfigsStore from '@/store/local-configs';
import TaskPinnedDrawer from '@/ui/modules/task/pinned/TaskPinnedDrawer.vue';
// import delayExecuteSomethingComposable from '@/ui/composables/app/delay-execute-something';
import TotalPinnedTasksGlobal from '@/ui/modules/task/pinned/TotalPinnedTasksGlobal.vue';
import paymentStore from '@/store/payment';
import statisticStore from '@/store/common/statistic.store';
import { checkShowChatWidget } from '@/ui/hooks/system-config/chat.system-config';
import workflowStore from '@/store/workflow';
import commonStore from '@/store/common/commonStore';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import driveDocumentPageStore from '@/store/driveDocument/drive-document-page';
import DriveDocumentPage from '@/ui/modules/drive-document/DriveDocumentPage.vue';
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';
import SettingOrganizationProcessBModal from '@/ui/modules/organization/setup/SettingOrganizationProcessBModal.vue';
import SettingOrganizationProcessCModal from '@/ui/modules/organization/setup/SettingOrganizationProcessCModal.vue';
import SettingOrganizationProcessDModal from '@/ui/modules/organization/setup/SettingOrganizationProcessDModal.vue';
import { EPlanId } from '@/application/constants/plan.const';
import NotificationService from '@/application/services/NotificationService';
import PlansOverviewModal from '@/ui/modules/settings/payments/overview/PlansOverviewModal.vue';
import tictopConfig from '@/application/constants/tictop-config.const';

const router = useRouter();
const route = useRoute();
const _systemStore = systemStore();
const _favoriteStore = favoriteStore();
const _conferenceStore = conferenceStore();
const _taskDrawerStore = taskDrawerStore();
const _taskStore = taskStore();
const _taskListStore = taskListStore();
const _taskCustomConfigStore = taskCustomConfigStore();
const _appStore = appStore();
const _groupStore = groupStore();
const _userStore = userStore();
const _settingStore = settingStore();
const _dashboardStore = dashboardStore();
const _domainStore = domainStore();
const _organizationStore = organizationStore();
const _remoteConfigStore = remoteConfigStore();
const _authStore = authStore();
const _planStore = plansStore();
const _chatStore = chatStore();
const _overviewTeamStore = overviewTeamStore();
const _departmentStore = departmentStore();
const _myProfileStore = myProfileStore();
const _localConfigsStore = localConfigsStore();
const _paymentStore = paymentStore();
const _statisticStore = statisticStore();
const _workflowStore = workflowStore();
const _commonStore = commonStore();
const _drivePageStore = driveDocumentPageStore();
const _notifService = NotificationService.getInstance();

let _hadRealtimeGroupActivity: boolean = false;

const { initMenus } = initDashboardMenu();

const myProfile = computed<any>(() => _myProfileStore.myProfile);
const departmentManagementEnabled = computed(
  () => _settingStore._organizationSetting.departmentManagement.enabled
);

let ws: TictopWebSocket;

const { changeByRouter } = customDocumentTitle();

changeByRouter(route?.name?.toString());

_appStore.setNewBrowserTab();

window.addEventListener('beforeunload', function () {
  _appStore.removeCurrentBrowserTab();
});

// const isProfile = computed(() => router.currentRoute.value.meta.isProfile);
const imageDefault = computed(() => _appStore.welcomeImage);
const isLoading = computed(() => _appStore.isLoading);
const isShowAnnouncementsNewDeployment = computed(
  () => _systemStore.isShowAnnouncementsNewDeployment
);
const isHiddenSidebar = computed(() => _dashboardStore.isHiddenSidebar);
const userProfile = computed(() => myProfileStore().myProfile);
const isInstallApp = computed(() => _organizationStore.isInstallMobileApp);
const organizationInfo = computed(() => _organizationStore.organizationInfo);
const currentOrganizationPlan = computed<IOrgCurrentPlan>(
  () => _planStore.currentOrgPlan
);
const keyDeploymentActive = computed(() => _systemStore.keyDeploymentActive);
const deploymentInfo = computed(() => _systemStore.deploymentInfo);
const stateAnnouncementNewDeploy = ref(
  JSON.parse(localStorage.getItem(StorageConstant.NEW_DEPLOY) || 'false')
);
const userId = computed(() => getCurrentUserId());
const isSettingMenu = computed(
  () => router.currentRoute.value.meta.isSettingMenu
);
const isShowTaskDrawer = computed(
  () => router.currentRoute.value.meta.isShowTaskDrawer
);
const isFreePlan = computed(
  () =>
    currentOrganizationPlan.value?.planId == EPlanId.FREE ||
    currentOrganizationPlan.value?.planId == EPlanId.PERSONAL ||
    currentOrganizationPlan.value?.planId == EPlanId.BASIC
);
const webOrganizationPlanShowExceedWarning = computed(
  () => _remoteConfigStore.webOrganizationPlanShowExceedWarning
);
const folderS3Background = computed(
  () => _remoteConfigStore.folderS3Background
);

const drivePageIsVisible = computed<any>(() => _drivePageStore.pageIsVisible);

const numberOfLogins = ref(0);
const registerWithProcess = ref<{
  currentProcess: 'A' | 'B' | 'C' | 'D' | null;
  allowPersonal: boolean;
}>({
  currentProcess: null,
  allowPersonal: true,
});
const isOpenCongratulation = ref(false);
const isShowChatWidget = computed(
  () => route?.name !== 'Messages' && checkShowChatWidget()
);
const isDashboardGroupsPage = computed(() => route?.name == 'OverviewGroups');
const originOrganizationId = ref(0);
const currentActiveOrganizationId = ref(0);
const isShowPaymentResult = ref(false);
const paymentDetail = ref({
  actionName: EPaymentPurpose.Upgrade,
  currentPlan: '',
  previousPlan: '',
  billingCycle: '',
  expireDate: 0,
  members: 0,
  needToReloadPage: true,
});
const addVoucherStatus = ref<{
  isOpen: boolean;
  title: string;
  body: string;
  subBody: string;
  type: 'SUCCESS' | 'ERROR';
} | null>(null);

watch(
  () => isSettingMenu.value,
  () => {
    isOpenUserBar.value = !!isSettingMenu.value;
  }
);

watch(
  () => folderS3Background.value,
  () => {
    initImageData();
  }
);

watch(
  () => route.name,
  (currVal) => {
    changeByRouter(route?.name?.toString());
    if (currVal?.toString().toUpperCase() !== 'MESSAGES') {
      _dashboardStore.setSidebarHiddenState(false);
    }
  }
);

// INIT DATA
const {
  subscribeUserConversationInfo,
  subscribeUserConversations,
  clearUserConversations,
} = initChatStore();

const initDashboardData = async () => {
  try {
    _appStore.setIsLoading(true);
    await myProfileStore().fetchCurrentLoginUser();
    await _settingStore.getOrganizationSettings();
    // get permission by user and by organization model
    await Promise.all([
      permissionStore().getPermissionsByUser(),
      _organizationStore.getOrganizationInfo(''),
    ]);

    RouterInstanceClass.getInstance().generateRoutes();
    await RouterInstanceClass.getInstance().generateDynamicRoutes();

    const promise = [
      _dashboardStore.getMemberOnday(),
      _userStore.getShortInfosUser(),
      _departmentStore.getAll(),
      _userStore.getDeactivatedUsers(),
      _groupStore.fetchMyGroups(),
      _groupStore.getAllDeletedGroup(),
      _groupStore.getGroupChatByUser(),
      _chatStore.getUsersGroupsForChat(),
      _taskStore.getCurrentTaskCycle(),
      // _groupStore.updateNotificationInListGroup(),
      _appStore.getCurrentServerTime(),
      _taskStore.getDataConfig(),
      _planStore.initData(),
      _organizationStore.checkUserInstallApp(),
      _settingStore.getAllTimezoneSettings(),
      _taskCustomConfigStore.getUserCustomColumnTask(),
      _statisticStore.getMyStatistics(),
      _userStore.getUserIdentity(),
      _taskStore.getCurrentTaskCycleForChat(),
      _workflowStore.getAllStepType(),
      _workflowStore.getWorkflowsStepsShortInfo(),
      _commonStore.getCurrentUserSetting(true),
      myProfileStore().getUserTaskSchedule(),
      myProfileStore().getMyDomains(),
    ];

    if (
      hasRolePermission(AdminType.Admin) ||
      hasRolePermission(AdminType.Owner)
    ) {
      promise.push(_paymentStore.getAvailableVouchers());
    }

    await Promise.all(promise);
    await _domainStore.fetchAllDomainProjects(); // Need have group's data

    initMenus();

    if (permissionStore().organizationModel !== 'PERSONAL') {
      Promise.all([_planStore.getAllPlanCapacities()]);
    }

    numberOfLogins.value =
      MyOrganizationSingleton.getInstance().getNumberOfLogin();

    onListenDataFromFirebase();

    getUserCallHistories();

    _remoteConfigStore.fetchRemoteConfigs();
    _localConfigsStore.loadAllLocalConfigs();
    originOrganizationId.value =
      MyOrganizationSingleton.getInstance().activeOrganization;
    currentActiveOrganizationId.value =
      MyOrganizationSingleton.getInstance().activeOrganization;
    _appStore.setIsLoading(false);

    const accountSettingProcess =
      MyOrganizationSingleton.getInstance().getOrganizationSettingProgress();
    if (
      accountSettingProcess &&
      accountSettingProcess == SettingProgressEnum.SettingOrganization
    ) {
      // const res =
      //   await AuthService.getInstance().getCurrentSubscriptionProcess();

      // settingOrganizationType.value = res?.result;

      const currentProcess =
        organizationInfo.value?.decideTime == 11
          ? 'A'
          : organizationInfo.value?.decideTime == 12
          ? 'B'
          : organizationInfo.value?.decideTime == 13
          ? 'C'
          : organizationInfo.value?.decideTime == 14
          ? 'D'
          : 'A';

      registerWithProcess.value.currentProcess = currentProcess;

      registerWithProcess.value.allowPersonal =
        permissionStore().tictopConfigs[
          tictopConfig.SYSTEM_SUBSCRIP_WEB_ENTRY_ALLOW_PERSONAL_FOR_P
        ]?.includes(currentProcess);

      ws.send(USER_REG_EVENT.PAGE_INPUT_ORGANIZATION_INFO_INIT, null, {
        ...userProfile?.value,
      });
    } else {
      _initFunctionSetAvatar();
    }

    // Chat
    subscribeUserConversationInfo();
    subscribeUserConversations();
    _appStore.setIsLoading(false);
  } catch (error) {
    console.log('🚀 Tictop ~ init data error:', error);
    // _appStore.setIsLoading(false);
  }
};

const getUserCallHistories = () => {
  _conferenceStore.getHistories();
};

const initImageData = async () => {
  let img = `${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/background/${
    folderS3Background.value
  }/${Math.floor(Math.random() * 14) + 1}.png`;
  _appStore.setImageDefault(img);
};

const prepareDataForTask = () => {
  taskCreateFormStore().loadAllCache();
  _taskDrawerStore.getOriginDataFromLocalStorage();
};
// const prepareDataForChat = () => {
//   chatPanelStore().loadAllMessageCaches();
// };

//FIREBASE
onMounted(async () => {
  ws = new TictopWebSocket();

  prepareDataForTask();
  // prepareDataForChat();
  initImageData();
  if (isSettingMenu.value) isOpenUserBar.value = true;
  onListenMessage();
});

let { unsubscribeCallbacks } = listenFirebaseRealtime();
// const { handleDelayExecuteSomeThings } = delayExecuteSomethingComposable();

const listFirebaseOrganizationInfoChanges = async () => {
  const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);

  if (!organizationId) return '';
  const organizationUserWDStatusFbClass = new OrganizationDynamicModel(
    `usersWorkingStatus`
  );
  unsubscribeCallbacks.value.push(
    organizationUserWDStatusFbClass.onChange((data) => {
      _dashboardStore.updateUserWorkingStatusRealtime(data);
    })
  );
};
const listenLatestWorkflowActivity = async () => {
  const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);

  if (!organizationId) return '';
  const fbClass = new OrganizationDynamicModel(`latestWorkflowActivity`);
  unsubscribeCallbacks.value.push(
    fbClass.onChange(() => {
      _workflowStore.getWorkflowsStepsShortInfo();
    })
  );
};

const handleLogout = () => {
  _appStore.setIsLoading(true);

  loginAgain();
};
const listFirebaseUserWDStatus = async () => {
  const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);

  if (!organizationId) return '';
  const organizationUserWDStatusFbClass = new OrganizationDynamicModel(
    `latestInformationChanges`
  );

  unsubscribeCallbacks.value.push(
    organizationUserWDStatusFbClass.onChange(async (data) => {
      if (Date.now() - data?.actionAt > 10 * 1000) return;

      if (
        ['MEMBER_DELETED', 'MEMBER_DEACTIVE', 'MEMBER_CHANGED_ROLE'].includes(
          data?.actionType
        ) &&
        data?.userId == getCurrentUserId()
      ) {
        clearLocalStorage({
          isSwitchingOrg: false,
        });
        settingGlobalModal({
          type: 'notification',
          title: '',
          content: translate(
            data?.actionType == 'MEMBER_DEACTIVE'
              ? 'USER_LABEL_NOTIFICATION_DE_ACTIVE'
              : data?.actionType == 'MEMBER_DELETED'
              ? 'USER_LABEL_NOTIFICATION_REMOVE'
              : 'USER_LABEL_NOTIFICATION_MEMBER_CHANGED_ROLE',
            {
              organizationName: organizationInfo.value?.name || '',
            }
          ),
          confirmable: true,
          closeable: false,
          iconColor: 'red',
        });

        await ask();
        handleLogout();

        return;
      }

      Promise.all([
        _userStore.getShortInfosUser(),
        _userStore.getDeactivatedUsers(),
        _groupStore.getAllGroup(),
        _chatStore.getUsersGroupsForChat(null, true),
      ]);
    })
  );
};
const onListenMyTaskInformation = async () => {
  const muteNotificationFbClass = new UserDynamicModel(
    `user_${userId.value}/myTaskInformation/totalPinned`
  );
  unsubscribeCallbacks.value.push(
    muteNotificationFbClass.onChange((data) => {
      _myProfileStore.updateTotalPinnedTasks(data || 0);
    })
  );
};

const onListenDataFromFirebase = () => {
  onListenFavoriteRealtime();
  onListenOrganizationUserStatus();
  onListenGroupActivityFirebase();
  onListenUserCallStatusFirebase();
  listFirebaseUserWDStatus();
  onListenDomainActivityFirebase();
  onListenMuteNotification();
  onListenOrganizationMembers();
  onListenMyTaskInformation();
  listFirebaseOrganizationInfoChanges();
  listenLatestWorkflowActivity();

  if (
    hasRolePermission(AdminType.Admin) ||
    hasRolePermission(AdminType.Owner)
  ) {
    onListenPaymentActivityFirebase();
  }
};

const onListenGroupActivityFirebase = () => {
  const organizationLatestGroupActivityFbClass = new OrganizationDynamicModel(
    `latestGroupActivity`
  );

  unsubscribeCallbacks.value.push(
    organizationLatestGroupActivityFbClass.onChange((data) => {
      if (_hadRealtimeGroupActivity) {
        const groupId = data?.id;

        if (groupId) {
          switch (data?.action) {
            case 'CREATE':
            case 'UPDATE':
              _groupStore.updateMyGroupsFromRealtime(groupId);
              break;
            case 'DELETE':
              _groupStore.updateMyGroupsAfterDelete(groupId);
              break;
            default:
              break;
          }
        }

        _chatStore.getUsersGroupsForChat(null, true);

        if (isDashboardGroupsPage.value) {
          overviewGroupStore().getOverviewGroupInfo(1, true);
        }
      }

      _hadRealtimeGroupActivity = true;
    })
  );
};

const onListenDomainActivityFirebase = () => {
  const latestDomainActivityFbClass = new OrganizationDynamicModel(
    `latestDomainActivity`
  );
  unsubscribeCallbacks.value.push(
    latestDomainActivityFbClass.onChange(() => {
      // const actionTime = data?.actionTime;
      // const actionType = data?.actionType;
      // const domainProjectId = data?.id;

      // if (!domainProjectId || actionType !== DomainActionType.AddNew) return;

      _domainStore.fetchAllDomainProjects();
    })
  );
};

const onListenMuteNotification = () => {
  const muteNotificationFbClass = new UserDynamicModel(
    `user_${userId.value}/muteNotifications`
  );
  unsubscribeCallbacks.value.push(
    muteNotificationFbClass.onChange((data) => {
      _chatStore.updateMuteConversation(data || {});
    })
  );
};

const onListenOrganizationMembers = () => {
  const fbClass = new OrganizationDynamicModel(`latestOverviewActivity`);
  unsubscribeCallbacks.value.push(
    fbClass.onChange(() => {
      _userStore.getShortInfosUser();
    })
  );
};

const onListenPaymentActivityFirebase = () => {
  const latestPaymentActivityFbClass = new OrganizationDynamicModel(
    `latestPaymentActivity`
  );

  unsubscribeCallbacks.value.push(
    latestPaymentActivityFbClass.onChange((data) => {
      if (!data) return;

      const actionName = data?.action;
      const members = data?.premiumMembers;
      const actionTime = data?.actionTime;
      const currentPlan = data?.currentPlan;
      const validTo = data?.validTo;

      if (Date.now() - actionTime > 5 * 60 * 1000) return;

      if (
        (actionName == EPaymentPurpose.Upgrade &&
          currentPlan !== currentOrganizationPlan.value?.planId) ||
        (actionName == EPaymentPurpose.BuyMore &&
          members &&
          members > currentOrganizationPlan.value?.members) ||
        ((actionName == EPaymentPurpose.Extend ||
          actionName == EPaymentPurpose.APPLY_LICENSE ||
          actionName == EPaymentPurpose.PURCHASE ||
          actionName == EPaymentPurpose.Renew) &&
          diff(validTo, currentOrganizationPlan.value?.endDate, 'day') > 0) ||
        (actionName == EPaymentPurpose.PURCHASE &&
          (currentOrganizationPlan.value?.planId == EPlanId.FREE ||
            currentOrganizationPlan.value?.planId == EPlanId.BASIC))
      ) {
        if (actionName == EPaymentPurpose.BuyMore) {
          _planStore.getAllPlanCapacities();
          return;
        }

        paymentDetail.value = {
          ...data,
          actionName: data?.action,
          expireDate: data?.validTo,
          needToReloadPage:
            actionName == EPaymentPurpose.PURCHASE ||
            actionName == EPaymentPurpose.Renew ||
            actionName == EPaymentPurpose.APPLY_LICENSE,
        };

        isShowPaymentResult.value = true;
      }
    })
  );
};

const onListenUserCallStatusFirebase = () => {
  const fbUserCallStatusClass = new OrganizationDynamicModel(
    `userStatus/${getCurrentUserId()}`
  );

  unsubscribeCallbacks.value.push(
    fbUserCallStatusClass.onChange((data) => {
      let callingStatus: UserCallingStatus | null = null;

      if (data?.includes(UserStatusEnum.OnCall)) {
        const splitedData = data.split(':');
        if (!splitedData || splitedData.length <= 2) return;

        callingStatus = {
          id: userId.value,
          status: 'on_call',
          joinToken: splitedData[1],
          callId: splitedData[2],
        };
      }

      _authStore.setCallingStatus(callingStatus);
    })
  );
};

const onListenOrganizationUserStatus = () => {
  const organizationUserStatusFbClass = new OrganizationDynamicModel(
    `userStatus`
  );

  unsubscribeCallbacks.value.push(
    organizationUserStatusFbClass.onChildAdded((childData, childKey) => {
      handleRealtimeOrganizationUserStatusUpdated(childKey, childData);
    }),
    organizationUserStatusFbClass.onChildChanged((childData, childKey) => {
      handleRealtimeOrganizationUserStatusUpdated(childKey, childData);
    })
  );
};

const handleRealtimeOrganizationUserStatusUpdated = (key, value) => {
  _dashboardStore.updateUserStatusList(key, value);
};

const onListenFavoriteRealtime = async () => {
  const userFavoritesFbClass = new UserDynamicModel(
    `user_${userId.value}/favorites`
  );

  if (!userFavoritesFbClass.dataRef) return;
  unsubscribeCallbacks.value.push(
    userFavoritesFbClass.onChildAdded(async (addFavorite) => {
      if (!addFavorite || !addFavorite.type || !addFavorite.refId) {
        return;
      }

      switch (addFavorite.type) {
        case FavoriteType.Member:
          {
            _userStore.updateFavoriteUserById(addFavorite?.refId, true);
            let memberInfo = _dashboardStore.getDetailUserInfo(
              addFavorite?.refId
            );

            if (memberInfo) {
              _favoriteStore.addDataFavorite(addFavorite, {
                ...memberInfo,
                ...{
                  name: `${memberInfo?.lastName} ${memberInfo?.firstName}`,
                  favoriteType: FavoriteType.Member,
                },
              });
            } else {
              _favoriteStore.addDataFavorite(addFavorite, {});
            }
          }
          break;
        case FavoriteType.Group:
          {
            _groupStore.updateFavoriteGroupById(addFavorite?.refId, true);
            let groupInfo = await _groupStore.getDataGroup(addFavorite?.refId);

            if (groupInfo) {
              _favoriteStore.addDataFavorite(addFavorite, {
                ...groupInfo,
                ...{
                  favoriteType: FavoriteType.Group,
                },
              });
            } else {
              _favoriteStore.addDataFavorite(addFavorite, {});
            }
          }
          break;
        case FavoriteType.Domain:
          {
            const allDomainByIds = _domainStore.domainByIds;
            const domainDetail = allDomainByIds[addFavorite?.refId];

            _favoriteStore.addDataFavorite(addFavorite, {
              ...(domainDetail || {}),
              favoriteType: FavoriteType.Domain,
            });
          }
          break;
        case FavoriteType.Menu:
          {
            _favoriteStore.addFavoriteMenu(addFavorite);
          }
          break;
        default:
          break;
      }
    }),
    userFavoritesFbClass.onChildRemoved((removeData) => {
      _favoriteStore.removeDataFavorite(removeData);
      _userStore.updateFavoriteUserById(removeData?.refId, false);
      _groupStore.updateFavoriteGroupById(removeData?.refId, false);
    })
  );
};

onMounted(async () => {
  if (isSettingMenu.value) isOpenUserBar.value = true;

  if (getLocalStorage(StorageConstant.IS_INVITING) == '1') {
    isOpenModalInvite.value = true;

    removeLocalStorage(StorageConstant.IS_INVITING);
  }

  if (deploymentInfo.value?.state == stateDeployments.DEPLOYING?.state) {
    await _systemStore.handleCheckRouterDeployment(
      deploymentInfo.value,
      keyDeploymentActive.value,
      router,
      route
    );
  }

  // prepareDataForChat();
  prepareDataForTask();
  await initImageData();
  await onListenMessage();

  checkBrowserPermission();

  window.addEventListener('storage', async (event) => {
    switch (event.key) {
      case StorageConstant.LANGUAGE:
        translate(getLocalStorage(StorageConstant.LANGUAGE));
        break;
      case StorageConstant.ACTIVE_ORG:
        currentActiveOrganizationId.value = parseInt(event.newValue || '');
        break;
      case StorageConstant.NEW_DEPLOY:
        stateAnnouncementNewDeploy.value = JSON.parse(
          event.newValue || 'false'
        );
        break;
      default:
        break;
    }
  });
});

onUnmounted(() => {
  ws?.close();

  // Chat
  clearUserConversations();
});

// ACTIONS IN DASHBOARD
const onSetupMyOrgSuccess = (data) => {
  registerWithProcess.value.currentProcess = null;

  if (data?.model === 'PERSONAL') {
    isOpenCongratulation.value = true;
  } else {
    router.push('/');
  }
};

const onCloseCongratulation = () => {
  isOpenCongratulation.value = false;

  const storageVoucherInfo = getLocalStorage(StorageConstant.VOUCHER_INFO);

  if (!storageVoucherInfo) return;

  const voucherInfo: IVoucherResponse = JSON.parse(
    getLocalStorage(StorageConstant.VOUCHER_INFO)
  ) as IVoucherResponse;

  if (!voucherInfo) return;

  const isSuccess = voucherInfo.status == 'APPLY_SUCCESS';

  addVoucherStatus.value = {
    isOpen: true,
    title: '',
    body: isSuccess
      ? translate('APPLY_SUCCESS', {
          voucherCode: voucherInfo.voucherCode,
          planId: voucherInfo.planId,
          month: `0${voucherInfo.planTime}`,
          timeEnd: dayjs(voucherInfo.endDate).format('DD/MM/YYYY'),
        })
      : translate('VOUCHER_CANNOT_USE_SUCCESS'),
    subBody: translate(`${voucherInfo.status}_SUB`),
    type: isSuccess ? 'SUCCESS' : 'ERROR',
  };
};

const onReloadPage = () => {
  _appStore.setIsLoading(true);
  history.replaceState({}, '', '/');
  window.location.reload();
};

const chooseUser = async (user) => {
  if (!user || !user?.id) return;
  isOpenTaskDrawer.value = false;
  router.replace(taskListRouteByType('USER', user.id));
};
const gotoMyTasks = () => {
  isOpenTaskDrawer.value = false;
  _taskListStore.lastUpdatedTime = Date.now();
  router.push({
    name: 'MyTasks',
    query: {
      tab: 1,
    },
  });
};

// FUNCTION AVATAR
let timerShowAvatar: any;

const onCancelUploadProfileFirstLogin = async (value) => {
  numberOfLogins.value += 1;
  isOpenModalView.value = value;
  isOpenSetAvatar.value = value;
  MyOrganizationSingleton.setNumberOfLogin(numberOfLogins.value);

  await myProfileStore().fetchCurrentLoginUser();

  _overviewTeamStore.getOverviewTeamInfo(undefined, true);
};

const _initFunctionSetAvatar = () => {
  if (
    myProfileStore().myProfile?.avatarUrl ||
    myProfileStore().myProfile?.avatarUrlThumbnails ||
    getLocalStorage(StorageConstant.HAVE_AVATAR) === '1'
  ) {
    return;
  }

  setLocalStorage(StorageConstant.HAVE_AVATAR, 1);
  if (timerShowAvatar) clearTimeout(timerShowAvatar);
  timerShowAvatar = setTimeout(() => {
    isOpenSetAvatar.value = true;
  }, 2000);
};

// UPLOAD AVATAR
const isOpenModalView = ref(false);
const isOpenSetAvatar = ref(false);
const isFetchPending = computed(() => _overviewTeamStore.isFetchPending);

const openModalView = (value) => {
  isOpenModalView.value = value;
};

const onReloadPendingUser = () => {
  isOpenModalInvite.value = false;
  _overviewTeamStore.setFetchPendingKey(!isFetchPending.value);
};

// COLLAPSE MENU
const isOpenMenuBar = computed(() => _appStore.isOpenMenuBar);

initDashboardData();

const isOpenDecidePaymentModal = computed<boolean>(() => {
  return (
    !isShowPaymentResult.value &&
    currentOrganizationPlan.value?.stateCode === EPlanState.INIT &&
    myProfile.value?.currentSettingProgress ==
      SettingProgressEnum.SettingFinished
  );
});

const onSelectedTrial = () => {
  onCloseCongratulation();
};

const sendChatSupportForOrganization = () => {
  isShowPaymentResult.value = false;
  _notifService.sendChatSupportForOrganization();
};
</script>

<template>
  <SynLoading v-if="isLoading" />

  <div v-else id="dashboard-main" class="flex w-screen h-screen font-roboto">
    <!--SIDEBAR-->
    <div
      v-if="!isHiddenSidebar"
      class="
        sidebar
        h-full
        flex flex-col
        relative
        shadow-r
        z-10
        hover-to-show__parent_menu
      "
      :class="[
        !isOpenUserBar ? 'bg-white' : 'bg-current-800 rounded-tr-2xl',
        !isOpenMenuBar ? ' content_collapse' : 'w-64',
      ]"
    >
      <RouterLink to="/" class="w-full">
        <div
          class="flex items-center w-full px-3"
          :class="!isOpenUserBar ? '' : 'rounded-tr-2xl'"
          style="height: 52px"
        >
          <div class="relative flex content-center justify-center items-center">
            <SynIcon name="tictop-logo" custom-class="h-9" />
            <div
              v-if="!isFreePlan"
              class="
                flex-center
                rounded-full
                w-5
                h-5
                absolute
                -right-3
                bottom-0
                logo-label
              "
              :class="!isOpenMenuBar ? 'opacity-100' : 'opacity-0'"
            >
              <SynIcon
                name="crown"
                custom-class="fill-white text-white w-4 h-4"
              />
            </div>
            <!-- <SynIcon name="tictop-logo-premium" custom-class="h-7" /> -->
          </div>
          <div
            class="flex flex-col w-max truncate pl-2 logo-label"
            :class="[
              isFreePlan ? '' : 'flex-1',
              !isOpenMenuBar ? 'opacity-0' : 'opacity-100',
            ]"
          >
            <div class="w-full flex items-center space-x-2">
              <SynIcon
                v-if="isFreePlan"
                name="tictop-logo-label"
                custom-class="w-16"
              />
              <SynIcon
                v-else
                name="tictop-logo-label-premium"
                custom-class="w-16"
              />
              <AtomPremiumLabel v-if="!isFreePlan" :label="'PRO'" />
            </div>
            <div
              class="min-w-max tracking-widest text-xs font-thin"
              :class="!isOpenUserBar ? 'text-current-700' : 'text-white'"
            >
              Working is fun!
            </div>
          </div>
        </div>
      </RouterLink>
      <!-- User Avatar -->
      <div v-if="!isOpenUserBar" class="flex-1 flex flex-col min-h-0">
        <div class="flex items-center px-2 py-4">
          <div class="flex hover-to-show__parent cursor-pointer">
            <SynAvatarStatusActive
              :id="userProfile?.id || userProfile?.userId"
              :avatar="userProfile?.avatarUrl"
              is-hidden-name
              :name="`${userProfile?.lastName ? userProfile?.lastName : ''} ${
                userProfile?.firstName ? userProfile?.firstName : ''
              }`"
              custom-class="h-12 w-12 text-gray-600"
              is-user
              is-update-avatar
              @click="openModalView(true)"
            />
            <ButtonAvatars
              size="w-2/5 h-2/5"
              :user-id="userProfile?.id || userProfile?.userId"
              :avatar="userProfile.avatarUrl"
            />
          </div>
          <div
            class="flex-1 flex flex-col pl-4 truncate logo-label"
            :class="isOpenMenuBar ? 'opacity-100' : 'opacity-0'"
          >
            <div
              class="
                flex
                items-center
                w-full
                justify-between
                space-x-1
                relative
              "
            >
              <span class="text-current-800 font-medium">
                {{
                  `${userProfile?.lastName ? userProfile?.lastName : ''} ${
                    userProfile?.firstName ? userProfile?.firstName : ''
                  }`
                }}
              </span>
              <vig-dropdown
                class="hover:text-current shadow-none"
                placement="bottom-end"
              >
                <template #dropdown-toggle>
                  <div class="flex-center">
                    <SynIcon
                      name="dots-vertical"
                      custom-class="h-4 w-4 hover:text-current cursor-pointer"
                    />
                  </div>
                </template>
                <template #dropdown-menu>
                  <SynDropdownMenu class="right-0 w-44">
                    <router-link :to="{ name: 'Profile' }">
                      <syn-dropdown-item class="dropdown-item">
                        <div class="flex items-center">
                          <SynIcon
                            name="profile"
                            custom-class="w-4 h-4 fill-gray-500 text-gray-400"
                          />
                          <span class="pl-2">
                            {{ $t('COMMON_LABEL_PROFILE') || 'Profile' }}
                          </span>
                        </div>
                      </syn-dropdown-item>
                    </router-link>
                  </SynDropdownMenu>
                </template>
              </vig-dropdown>
            </div>
            <div class="flex items-center space-x-2 py-1">
              <button
                v-if="isOpenMenuBar"
                class="
                  font-semibold
                  py-2
                  px-2
                  capitalize
                  space-x-1
                  rounded
                  flex-center
                  min-w-max
                  border-current-100
                  hover:border-current-500
                  border
                  text-current-500
                  hover:bg-current-50 hover:bg-opacity-50
                  fill-current
                  relative
                  w-max
                "
                @click="gotoMyTasks"
              >
                <SynIcon name="note" custom-class="w-4 h-4" />
                <span class="text-xs">
                  {{ $t('TASKTAB_LABEL_MYTASK') }}
                </span>
                <!-- <div
                v-if="totalNotificationMyTask !== 0"
                class="
                  absolute
                  -right-3
                  -top-3
                  rounded-full
                  bg-white
                  p-0.5
                  flex-center
                "
              >
                <div
                  class="
                    rounded-full
                    bg-red-500
                    text-white text-center
                    w-5
                    h-5
                    flex
                    justify-center
                    items-center
                  "
                  style="font-size: 11px"
                >
                  {{ totalNotificationMyTask }}
                </div>
              </div> -->
              </button>
              <TotalPinnedTasksGlobal />
            </div>
          </div>
        </div>

        <div
          v-if="!isOpenMenuBar"
          class="px-2 mb-4 logo-label -mt-2"
          :class="!isOpenMenuBar ? 'opacity-100' : 'opacity-0'"
        >
          <button
            class="
              font-semibold
              w-full
              py-2
              px-2
              capitalize
              space-x-1
              rounded
              flex-center
              min-w-max
              border-current-100
              hover:border-current-500
              border
              text-current-500
              hover:bg-current-50
              hover:bg-opacity-50
              hover:ring
              hover:ring-current
              hover:ring-opacity-30
              fill-current
              relative
            "
            @click="chooseUser(userProfile)"
          >
            <SynIcon name="note" custom-class="w-4 h-4" />
          </button>
        </div>
        <div
          v-if="!isOpenMenuBar"
          class="px-2 mb-4 logo-label -mt-2"
          :class="!isOpenMenuBar ? 'opacity-100' : 'opacity-0'"
        >
          <button
            class="
              w-full
              space-x-1
              rounded
              flex-center
              min-w-max
              border-current-100
              hover:border-current-500
              border
              hover:bg-current-50
              hover:bg-opacity-50
              hover:ring
              hover:ring-current
              hover:ring-opacity-30
            "
          >
            <TotalPinnedTasksGlobal />
          </button>
        </div>
        <!-- INSTALL APP -->
        <!--        <div v-if="!isInstallApp" class="w-full flex-center pb-2 px-2">-->
        <div v-if="!isInstallApp" class="w-full pb-2 px-2">
          <div
            class="
              border border-current-50
              rounded
              px-2
              py-2
              w-full
              bg-current-50 bg-opacity-30
            "
          >
            <SynLinkDownload
              class="
                px-0.5
                w-max
                text-sm
                font-serif
                text-current-600
                italic
                tracking-wider
              "
              :is-button-download="false"
              trigger="click"
              :is-text-download="isOpenMenuBar"
            />
          </div>
        </div>

        <div class="flex-1 flex flex-col relative min-h-0">
          <PersonalSidebar />
        </div>
      </div>
      <div v-else class="flex-1 relative bg-white">
        <SettingSidebar class="h-full" />
      </div>

      <ToogleCollapseMenu
        class="absolute z-50 -right-2 top-3 hover-to-show__children_menu"
      />
    </div>

    <div class="flex flex-col flex-1 overflow-hidden h-full">
      <DeploymentBar />
      <AnnouncementsNewDeployment
        v-if="isShowAnnouncementsNewDeployment || stateAnnouncementNewDeploy"
      />

      <TictopHeader />
      <main
        class="
          flex-1
          relative
          h-full
          flex flex-col
          w-full
          overflow-x-hidden overflow-y-hidden
          bg-gray-50
        "
      >
        <div class="relative h-full flex flex-col">
          <!--          <AlertVerifyEmail v-if="!isProfile" />-->
          <PlanAndPaymentNotificationBar />

          <div
            class="flex-1 h-full w-full min-h-0 overflow-hidden flex flex-col"
          >
            <!--MAIN CONTENT-->
            <div
              class="w-full flex-center"
              :class="{ 'h-full': !drivePageIsVisible }"
            >
              <slot />
            </div>

            <DriveDocumentPage
              class="flex-1 overflow-hidden w-full"
              :class="{ invisible: !drivePageIsVisible }"
            />
          </div>
          <TaskDetailDrawer v-if="isShowTaskDrawer" />
          <TaskPinnedDrawer v-if="isShowTaskDrawer" />
        </div>
      </main>
    </div>

    <HelpPanel />
  </div>
  <ChatWidget v-if="!isLoading && isShowChatWidget" />

  <VideoRinging />

  <ReminderAlert />

  <ModalProfileFirstLogin
    v-if="!isOpenDecidePaymentModal && isOpenSetAvatar"
    :profile-id="userId"
    @cancel="(value) => onCancelUploadProfileFirstLogin(value)"
  />

  <ModalProfileUser
    v-if="isOpenModalView"
    :profile-id="userProfile?.userId || userProfile?.id"
    @cancel="isOpenModalView = false"
  />

  <img
    width="0"
    height="0"
    class="hidden"
    :src="imageDefault"
    :onError="initImageData"
  />

  <TaskAddAttachments v-if="attachmentToTaskGlobal.isOpen" />

  <TaskDetailModal
    v-if="isShowTaskDetailGlobal && (taskCodeGlobal || taskIdGlobal)"
    :task-id="taskIdGlobal"
    :task-code="taskCodeGlobal"
    @close="
      isShowTaskDetailGlobal = false;
      taskCodeGlobal = '';
      taskIdGlobal = 0;
    "
  />

  <DayoffDetailModal
    v-if="isShowDayoffDetail && dayoffIdGlobal"
    :id="dayoffIdGlobal"
    z-index="z-50"
    @close="
      isShowDayoffDetail = false;
      dayoffIdGlobal = '';
    "
  />

  <ModalViewMeeting
    v-if="eventIdGlobal && eventIdGlobal?.isShow && eventIdGlobal?.id"
    :event-id="eventIdGlobal?.id"
    @cancel="
      eventIdGlobal.id = '';
      eventIdGlobal.isShow = false;
    "
  />

  <CreateGroupTask v-if="isCreateGroup" @cancel="isCreateGroup = false" />

  <InviteUserModal
    v-if="isOpenModalInvite"
    @on-invite-success="onReloadPendingUser"
    @on-cancel="onReloadPendingUser"
    @on-close="onReloadPendingUser"
  />

  <GedPageModal
    v-if="isShowPageGedDetail && gedIdGlobal"
    :id-resoucer="gedIdGlobal"
    @close="
      isShowPageGedDetail = false;
      gedIdGlobal = {
        id: '',
        isGroup: false,
        folderId: '',
        notification: {},
      };
    "
  />

  <SelectOrganizationModelModal
    v-if="registerWithProcess.currentProcess == 'A'"
    type-key="workspace_type_select_register"
    do-not-need-to-check-personal
    :not-allow-personal="!registerWithProcess.allowPersonal"
    @on-cancel="registerWithProcess.currentProcess = null"
    @on-finish="onSetupMyOrgSuccess"
  />
  <SettingOrganizationProcessBModal
    v-if="registerWithProcess.currentProcess == 'B'"
    type-key="workspace_type_select_register"
    do-not-need-to-check-personal
    :not-allow-personal="!registerWithProcess.allowPersonal"
    @on-cancel="registerWithProcess.currentProcess = null"
    @on-finish="onSetupMyOrgSuccess"
  />
  <SettingOrganizationProcessCModal
    v-if="registerWithProcess.currentProcess == 'C'"
    type-key="workspace_type_select_register"
    do-not-need-to-check-personal
    :not-allow-personal="!registerWithProcess.allowPersonal"
    @on-cancel="registerWithProcess.currentProcess = null"
    @on-finish="onSetupMyOrgSuccess"
  />
  <SettingOrganizationProcessDModal
    v-if="registerWithProcess.currentProcess == 'D'"
    type-key="workspace_type_select_register"
    do-not-need-to-check-personal
    :not-allow-personal="!registerWithProcess.allowPersonal"
    @on-cancel="registerWithProcess.currentProcess = null"
    @on-finish="onSetupMyOrgSuccess"
  />

  <SynCongraturation
    v-if="isOpenCongratulation"
    :title="$t('COMMON_LABEL_CONGRATURATION')"
    id-button="signup-complete"
    :sub-title="$t('YOU_HAVE_SUCCESSFULLY_CREATED_YOUR_WORKSPACE')"
    @on-close="onCloseCongratulation"
  />

  <AtomResultNotification
    v-if="addVoucherStatus?.isOpen"
    :title="addVoucherStatus.title"
    id-button="signup-complete"
    :type="addVoucherStatus.type"
    :sub-title="addVoucherStatus.body || ''"
    @on-close="addVoucherStatus = null"
  >
    <template #subBody>
      <span v-if="addVoucherStatus.subBody" class="text-center">
        <span v-if="addVoucherStatus.type == 'SUCCESS'">
          {{ addVoucherStatus.subBody }}
        </span>
        <span v-else class="italic"> ({{ addVoucherStatus.subBody }}) </span>
      </span>
    </template>
  </AtomResultNotification>

  <PaymentResult
    v-if="isShowPaymentResult && webOrganizationPlanShowExceedWarning"
    :action-name="paymentDetail?.actionName"
    :current-plan-id="paymentDetail.currentPlan"
    :previous-plan-id="paymentDetail.previousPlan"
    :billing-cycle="paymentDetail.billingCycle"
    :expire-date="paymentDetail.expireDate"
    :total-users="paymentDetail.members"
    :need-to-reload-page="paymentDetail.needToReloadPage"
    @cancel="sendChatSupportForOrganization"
  />
  <DecidePaymentModal
    v-if="isOpenDecidePaymentModal && !isLoading"
    @on-selected-trial="onSelectedTrial"
  />

  <SynModal
    v-if="!isLoading && originOrganizationId !== currentActiveOrganizationId"
    disable-click-outside
    disable-esc-to-close
    container-class="w-max"
    style="max-width: 90%"
    container-style="min-width: 28rem"
    is-hidden-header
    is-hidden-close
    :confirm-text="$t('COMMON_LABEL_CONFIRM')"
    :cancel-text="''"
    z-index="z-100"
    @confirm="onReloadPage"
  >
    <template #body>
      <div class="flex-center flex-col h-full">
        <div class="w-full flex items-center justify-center flex-col">
          <syn-animation name="infomation" stype="width: 75px;" />

          <span
            class="text-base text-center font-normal px-4 break-normal"
            style="font-size: 17px"
          >
            {{ $t('COMMON_LABEL_SWITCH_ORGANIZATION_ALERT') }}
          </span>
        </div>
      </div>
    </template>
  </SynModal>

  <template v-if="!isLoading">
    <RealtimeOrganizationSettings />
    <RealtimeUserDepartment v-if="departmentManagementEnabled" />
  </template>
  <PlansOverviewModal
    v-if="isShowPlanAndPricingOverviewModal"
    @on-close="isShowPlanAndPricingOverviewModal = false"
  />
</template>

<style>
/*@keyframes widthChange {*/
/*  0% {*/
/*    width: 5rem;*/
/*  }*/

/*  100% {*/
/*    width: 16rem;*/
/*  }*/
/*}*/

/*@keyframes widthChangeClose {*/
/*  0% {*/
/*    width: 16rem;*/
/*  }*/

/*  100% {*/
/*    width: 5rem;*/
/*  }*/
/*}*/

.content_collapse {
  width: 4rem;
}

.sidebar {
  transition: width 0.3s ease-out;
}

.logo-label {
  transition: 0.2s ease-in-out !important;
}

/*.content_collapse[data-isShowMenu='true'] {*/
/*  width: 16rem;*/
/*  animation-name: widthChange;*/
/*  animation-duration: 0.5s;*/
/*}*/

/*.content_collapse[data-isShowMenu='false'] {*/
/*  width: 5rem;*/
/*  animation-name: widthChangeClose;*/
/*  animation-duration: 0.5s;*/
/*}*/
</style>
