<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import fileStorageStore from '@/store/fileStorage';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { useRoute, useRouter } from 'vue-router';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import $constants from '@/ui/plugins/constants';
import groupStore from '@/store/group';
import userStore from '@/store/user';
import notificationSettingStore from '@/store/notificationSetting/notificationSettingStore';
import FolderBreadcrumb from '@/ui/modules/ged/folder/folder-breadcrumb/FolderBreadcrumb.vue';
import FileDetailPanel from '../file-detail-panel/FileDetailPanel.vue';
import FilesList from '../files-list/FilesList.vue';
import GedFiltersToolbar from './filters-toolbar/GedFiltersToolbar.vue';
import ModalFolderSelect from '../folder/modal-folder-select/ModalFolderSelect.vue';
import UploadDropZone from '../upload/upload-drop-zone/UploadDropZone.vue';
import FilesUpload from '../upload/files-upload/FilesUpload.vue';
import NotificationSettings from '../settings/notification-settings/NotificationSettings.vue';
import {
  AlertType,
  closeNotification,
  openNotification,
} from '@/ui/hooks/commonHook';
import myProfileStore from '@/store/auth/my-profile';
import { cloneDeep } from '@/ui/hooks/commonFunction';
import GedFilterCategoriesButton from '@/ui/modules/ged/ged-page/filter-categories/GedFilterCategoriesButton.vue';
import { AdminType } from '@/ui/common/constants/constant';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import GedPageHeader from '@/ui/modules/ged/ged-page/ged-page-header/GedPageHeader.vue';
import GedPageHeaderTrash from '@/ui/modules/ged/ged-page/ged-page-header/GedPageHeaderTrash.vue';
import { ResourceTab } from '@/domain/enums/resource-enums';

const props = withDefaults(
  defineProps<{
    fixedMember?: any;
    defaultCategory?: string;
    isModal?: boolean;
    isMyResource?: boolean;
  }>(),
  {
    defaultCategory: $constants.FILE.CATEGORY.STORAGE,
  }
);

const emit = defineEmits(['onFolderChanged']);

const _route = useRoute();
const _router = useRouter();
const _fileStorageStore = fileStorageStore();
const _groupStore = groupStore();
const _userStore = userStore();
const _notificationSettingStore = notificationSettingStore();

const myProfile = computed(() => myProfileStore().myProfile);
const allMyGroups = computed(() =>
  _groupStore.allMyGroups?.filter((group) => {
    return group?.isMember;
  })
);
const allGroupByIds = computed(() => _groupStore.allGroupByIds);
const _members = computed(() => _userStore.listShortInfosUser);

const allUsers = computed(() => {
  let members = cloneDeep(_members.value) || [];
  if (fileFilters.value?.category !== $constants.FILE.CATEGORY.STORAGE) {
    const specialUser = {
      id: null,
      name: translate('GED_FILTER_ALL'),
      nameCode: 'GED_FILTER_ALL',
    };
    if (!members.some((e) => e.id === null)) {
      members.unshift({ ...specialUser });
    }
  } else {
    members = members.filter(
      (e) => e.id !== null && e.adminType !== AdminType.Visitor
    );
  }
  return members;
});

const filesSelected = ref([] as any);
const filesListRef = ref();
const fileFilters = ref({
  category: null,
  userId: null,
  groupId: null,
  domainId: null,
  project: null,
  subProject: null,
  keyword: null,
  pageSize: 50,
  select: null,
  onlyDeletedFiles: false,
} as any);
const fileShowDetail = ref();
const filesUploadRef = ref();
const isMoveToFolder = ref();
const currentLocationMove = ref();
const moveToIgnoredFolderIds = ref();
const moveToConfirmFunction = ref();
const fileShowNotification = ref();
const isShowNotificationSetting = ref(false);
const resourceElement = ref();

const filesTotal = computed(() => _fileStorageStore.totalFiles);
const filesShown = computed(() => _fileStorageStore.files?.length);

const memberSelected = ref();
const _folderSelected = ref();
const folderSelected = computed(() => {
  if (!_folderSelected.value) return null;

  let storageName;
  if (fileFilters.value?.groupId) {
    const currGroup = (allMyGroups.value || []).find(
      (group) => group?.id === fileFilters.value.groupId
    );
    storageName = currGroup?.name;
  } else if (fileFilters.value?.userId) {
    const currUser = (allUsers.value || []).find(
      (user) => user?.id === fileFilters.value.userId
    );
    storageName = currUser?.name;
  }

  return {
    ..._folderSelected.value,
    storageName,
  };
});

const isSelectionMode = ref(false);

const isViewByList = computed(() => _fileStorageStore.isViewList);

const showDeletePreventMsg = ref(null as any);
const isDraggingFilesUpload = ref(false);

const onChangeView = () => {
  _fileStorageStore.changeView();

  if (!isViewByList.value) {
    fileFilters.value.sortBy = null;
    fileFilters.value.orderBy = null;

    _getFiles();
  }
};

const screenCode = computed<string>(() => {
  if (fileFilters.value?.groupId) {
    if (fileFilters.value?.category == $constants.FILE.CATEGORY.STORAGE) {
      return ALL_SCREENS.GROUP.RESOURCE.STORAGE;
    }
    if (fileFilters.value?.category == $constants.FILE.CATEGORY.TASK) {
      return ALL_SCREENS.GROUP.RESOURCE.TASK;
    }
    if (fileFilters.value?.category == $constants.FILE.CATEGORY.CHAT) {
      return ALL_SCREENS.GROUP.RESOURCE.MESSAGE;
    }
  }

  if (
    fileFilters.value?.userId &&
    fileFilters.value?.userId == myProfile.value?.id
  ) {
    if (fileFilters.value?.category == $constants.FILE.CATEGORY.STORAGE) {
      return ALL_SCREENS.LOGIN_USER.RESOURCE.STORAGE;
    }
    if (fileFilters.value?.category == $constants.FILE.CATEGORY.TASK) {
      return ALL_SCREENS.LOGIN_USER.RESOURCE.TASK;
    }
    if (fileFilters.value?.category == $constants.FILE.CATEGORY.CHAT) {
      return ALL_SCREENS.LOGIN_USER.RESOURCE.MESSAGE;
    }
  }

  if (fileFilters.value?.category == $constants.FILE.CATEGORY.STORAGE) {
    return ALL_SCREENS.USER.RESOURCE.STORAGE;
  }
  if (fileFilters.value?.category == $constants.FILE.CATEGORY.TASK) {
    return ALL_SCREENS.USER.RESOURCE.TASK;
  }
  if (fileFilters.value?.category == $constants.FILE.CATEGORY.CHAT) {
    return ALL_SCREENS.USER.RESOURCE.MESSAGE;
  }

  return ALL_SCREENS.USER.RESOURCE.STORAGE;
});

watch(
  () => _route.query,
  (query) => {
    const isChangeViewTrash =
      fileFilters.value.onlyDeletedFiles !== (_route.query?.view === 'TRASH');
    const isChangeFolder = query.folder !== folderSelected.value?.id;

    // Case: Change View Trash by query
    if (isChangeViewTrash) {
      fileFilters.value.onlyDeletedFiles = _route.query?.view === 'TRASH';

      if (isChangeFolder) {
        onFolderFromUrlChange(query.folder);
      } else {
        _processViewTrashChanged();
      }
    }
    // Case: Change Folder by query
    else if (isChangeFolder) onFolderFromUrlChange(query.folder);
  }
);

watch(
  () => props.fixedMember,
  () => {
    _initPage();
  }
);

onMounted(() => {
  // Check View Trash in query
  if (_route.query?.view === 'TRASH' && !fileFilters.value.onlyDeletedFiles) {
    fileFilters.value.onlyDeletedFiles = true;
    return _processViewTrashChanged();
  }

  // Check Folder in query
  if (_route.query?.folder) {
    return onFolderFromUrlChange(_route.query?.folder);
  }

  _initPage();
});

const onFolderFromUrlChange = async (folderId) => {
  fileFilters.value.keyword = null;

  if (!folderId) {
    _folderSelected.value = null;

    fileFilters.value.folderId = null;

    return _getFiles();
  }

  try {
    const res = await _fileStorageStore.getFolder(folderId);

    const folder = res?.result;

    if (!res.result) {
      openNotification({
        type: AlertType.error,
        title: translate('COMMON_LABEL_ERROR'),
        body: translate('GED_FOLDER_NOT_FOUND_MSG'),
      });

      return emit('onFolderChanged', null);
    }

    if (folder?.relatedGroup) {
      memberSelected.value = { id: folder?.relatedGroup, isGroup: true };
      fileFilters.value.groupId = folder?.relatedGroup;
      fileFilters.value.userId = null;
      fileFilters.value.domainId = null;
      fileFilters.value.projectId = null;
      fileFilters.value.subProjectId = null;
    } else if (folder?.relatedDomain) {
      memberSelected.value = { id: folder?.relatedDomain, isDomain: true };
      fileFilters.value.domainId = folder?.relatedDomain;
      fileFilters.value.projectId = folder?.relatedProject;
      fileFilters.value.subProjectId = folder?.relatedSubProject;
      fileFilters.value.userId = null;
      fileFilters.value.groupId = null;
    } else if (
      folder?.relatedMembers?.some(
        (memberId) => memberId === myProfile.value?.id
      )
    ) {
      memberSelected.value = { id: myProfile.value?.id };
      fileFilters.value.userId = myProfile.value?.id;
      fileFilters.value.groupId = null;
      fileFilters.value.domainId = null;
      fileFilters.value.projectId = null;
      fileFilters.value.subProjectId = null;
    }

    fileFilters.value.category = folder?.category;
    fileFilters.value.folderId = folder?.id;

    _folderSelected.value = folder;

    _getFiles();
  } catch (e: any) {
    if (!e?.success && e?.error?.message === 'PERMISSION_DENIED') {
      openNotification({
        type: AlertType.error,
        title: translate('COMMON_LABEL_ERROR'),
        body: translate('GED_FOLDER_ACCESS_DENIED_MSG'),
      });

      return emit('onFolderChanged', null);
    }
  }
};

const onFilterUpdate = (filter) => {
  fileFilters.value = filter;

  fileFilters.value.folderId = null;
  fileFilters.value.sortBy = null;
  fileFilters.value.orderBy = null;

  _folderSelected.value = null;
  emit('onFolderChanged', null);

  _getFiles();
};

const onMemberChange = (member) => {
  fileFilters.value.userId =
    member?.isGroup || member?.isDomain ? null : member?.id;
  fileFilters.value.groupId = member?.isGroup ? member?.id : null;
  fileFilters.value.domainId = member?.isDomain ? member?.id : null;
  fileFilters.value.projectId = null;
  fileFilters.value.subProjectId = null;
  fileFilters.value.folderId = null;
  fileFilters.value.sortBy = null;
  fileFilters.value.orderBy = null;

  _folderSelected.value = null;
  emit('onFolderChanged', null);

  if (fileFilters.value.domainId) {
    fileFilters.value.category = $constants.FILE.CATEGORY.STORAGE;
  }

  _getFiles();
};

const onCategoryChange = (categoryId) => {
  fileFilters.value.folderId = null;
  fileFilters.value.sortBy = null;
  fileFilters.value.orderBy = null;

  _folderSelected.value = null;
  emit('onFolderChanged', null);

  if (categoryId !== $constants.FILE.CATEGORY.STORAGE) {
    if (fileFilters.value.userId === myProfile.value?.id) {
      memberSelected.value = { id: null, isGroup: false };
      fileFilters.value.userId = null;
    }
  } else {
    if (!memberSelected.value.id) {
      memberSelected.value = { id: myProfile.value?.id, isGroup: false };
      fileFilters.value.userId = myProfile.value?.id;
    }
  }

  _getFiles();
};

const onViewTrashChange = () => {
  const query: any = {
    resourceTab: ResourceTab.Files,
  };

  if (fileFilters.value.onlyDeletedFiles) query.view = 'TRASH';

  _router.push({ query });

  _processViewTrashChanged();
};

const onSearch = async (text, selectedFile) => {
  fileFilters.value.keyword = text;

  await _getFiles();

  if (selectedFile) filesListRef.value?.clickFile(selectedFile);
};

const onApplyFilter = (filter) => {
  fileFilters.value = { ...fileFilters.value, ...(filter || {}) };

  _getFiles();
};

const onReloadClick = () => {
  _getFiles();
};

const onFilesLoadMore = () => {
  _getFiles(false);
};

const onFilesSortChange = (sortData) => {
  fileFilters.value = { ...fileFilters.value, ...(sortData || {}) };

  _getFiles(true, false);
};

const onSelectionToggle = (value) => {
  filesListRef.value.setSelectionMode(value);
};

const onSelectionAllToggle = (value) => {
  filesListRef.value.setSelectionAll(value);
};

const onMoveToTrashClick = async (files) => {
  if (!files?.length) return;

  if (
    files?.some(
      (file) => file?.sourceFrom !== $constants.FILE.SOURCE_FROM.STORAGE
    )
  ) {
    settingGlobalModal({
      type: 'notification',
      title: translate('COMMON_LABEL_ALERT'),
      content: translate('GED_DEL_PREVENT_MSG_LOCKED'),
      confirmable: true,
    });
    return ask();
  }

  if (files?.some((file) => file?.systemType)) {
    settingGlobalModal({
      type: 'notification',
      title: translate('COMMON_LABEL_ALERT'),
      content: translate('GED_SYSTEM_FOLDER_PREVENT_MSG'),
      confirmable: true,
    });
    return ask();
  }

  await _fileStorageStore.moveFilesToTrash(files);

  filesListRef.value.setSelectionMode(false);
  fileShowDetail.value = null;

  openNotification({
    type: AlertType.success,
    title: translate('COMMON_LABEL_SUCCESS'),
    body: translate('GED_MOVED_TO_TRASH_NUM_FILE_MSG', {
      number: files?.length,
    }),
    onClick: (notif) => {
      closeNotification(notif);

      fileFilters.value.onlyDeletedFiles = true;
      onViewTrashChange();
    },
  });
};

const onRestoreFromTrashClick = async (files) => {
  if (!files?.length) return;

  await _fileStorageStore.restoreFilesFromTrash(files);

  filesListRef.value.setSelectionMode(false);
  fileShowDetail.value = null;

  openNotification({
    type: AlertType.success,
    title: translate('COMMON_LABEL_SUCCESS'),
    body: translate('GED_RESTORED_NUM_FILE_MSG', {
      number: files?.length,
    }),
  });
};

const onDeletePermanentlyClick = async (files) => {
  if (!files?.length) return;

  settingGlobalModal({
    type: 'confirm',
    title: '',
    content: translate('GED_DEL_PERMANENT_NUM_FILE_MSG', {
      number: files?.length,
    }),
    confirmLabel: translate('COMMON_LABEL_CONFIRM'),
    confirmable: true,
    closeable: true,
  });

  const confirmed = await ask();

  if (confirmed) {
    await _fileStorageStore.deleteFiles(files);

    filesListRef.value.setSelectionMode(false);
    fileShowDetail.value = null;
  }
};

const onEmptyTrashConfirm = async () => {
  await _fileStorageStore.deleteAllFilesFromTrash();

  filesListRef.value.setSelectionMode(false);
  fileShowDetail.value = null;
};

const onMoveToFolderClick = () => {
  if (
    (filesSelected.value || [])?.some(
      (file) => file?.sourceFrom !== $constants.FILE.SOURCE_FROM.STORAGE
    )
  ) {
    settingGlobalModal({
      type: 'notification',
      title: translate('COMMON_LABEL_ALERT'),
      content: translate('GED_FILE_MOVE_TO_PREVENT_MSG'),
      confirmable: true,
    });
    return ask();
  }

  if (filesSelected.value?.some((file) => file?.systemType)) {
    settingGlobalModal({
      type: 'notification',
      title: translate('COMMON_LABEL_ALERT'),
      content: translate('GED_SYSTEM_FOLDER_PREVENT_MSG'),
      confirmable: true,
    });
    return ask();
  }

  moveToIgnoredFolderIds.value = (filesSelected.value || [])
    .filter((file) => file?.contentType === $constants.FILE.TYPE.FOLDER)
    .map((file) => file?.id);

  moveToConfirmFunction.value = (location) =>
    _fileStorageStore.moveFilesToFolder(filesSelected.value, location);

  currentLocationMove.value = {
    groupId: fileFilters.value?.groupId,
    domainId: fileFilters.value?.domainId,
    projectId: fileFilters.value?.projectId,
    subProjectId: fileFilters.value?.subProjectId,
    folderId: fileFilters.value?.folderId,
    folder: folderSelected.value,
  };

  isMoveToFolder.value = true;
};

const onMoveToFolderProcessed = async () => {
  _getFiles();

  filesListRef.value.setSelectionMode(false);
  fileShowDetail.value = null;

  isMoveToFolder.value = false;
};

const onUploadClick = (event) => {
  if (filesUploadRef.value?.uploadFiles(null)) event.preventDefault();
};

const onUploadFilesSelected = (files) => {
  filesUploadRef.value?.uploadFiles(files);
};

const onUploaded = () => {
  _getFiles();
};

const onFolderAdded = () => {
  _getFiles();
};

const onFolderClick = (folder) => {
  if (folder?.deleted) {
    settingGlobalModal({
      type: 'notification',
      title: translate('GED_FOLDER_IN_TRASH_TITLE'),
      content: translate('GED_FOLDER_IN_TRASH_CONTENT'),
      confirmable: true,
    });

    return ask();
  }

  _folderSelected.value = folder;
  emit('onFolderChanged', folder?.id);

  fileFilters.value.folderId = folder?.id;
  fileFilters.value.keyword = null;

  _getFiles();

  _router.push({
    ..._route,
    query: { ..._route.query, folder: folder?.id },
  });
};

const onFileMoveToFolderClick = (file) => {
  moveToIgnoredFolderIds.value =
    file?.contentType === $constants.FILE.TYPE.FOLDER ? [file?.id] : null;

  moveToConfirmFunction.value = (location) =>
    _fileStorageStore.moveFilesToFolder([file], location);

  currentLocationMove.value = {
    groupId: fileFilters.value?.groupId,
    domainId: fileFilters.value?.domainId,
    projectId: fileFilters.value?.projectId,
    subProjectId: fileFilters.value?.subProjectId,
    folderId: fileFilters.value?.folderId,
    folder: folderSelected.value,
  };

  isMoveToFolder.value = true;
};

const onNotificationSettingClick = async (folder) => {
  if (!folder.turnOnNotification) {
    const result =
      (await _notificationSettingStore.setFolderSettingNotification(
        folder.id,
        {
          add: true,
          modify: true,
          move: true,
          delete: true,
        },
        false,
        true
      )) as any;

    _getFiles();

    if (result?.result) {
      openNotification({
        title: translate('COMMON_LABEL_SUCCESS'),
        body: translate('NOTIFICATION_GED_CHANGE_NOTIFICATION_SETTING_SUCCESS'),
      });
    } else {
      openNotification({
        type: AlertType.error,
        title: translate('COMMON_LABEL_ERROR'),
        body: translate('NOTIFICATION_GED_CHANGE_NOTIFICATION_SETTING_ERROR'),
      });
    }
    return;
  }
  fileShowNotification.value = folder;
  isShowNotificationSetting.value = true;
};

const onChangeNotificationSetting = () => {
  fileShowNotification.value = {};
  isShowNotificationSetting.value = false;
  _getFiles();
};

const onFileDetailRenamed = (renamedFile) => {
  if (fileShowDetail.value) fileShowDetail.value.name = renamedFile?.name;
};

const onFilesDragging = (isDragging) => {
  isDraggingFilesUpload.value = isDragging;
};

const onFilesDrop = (event) => {
  const droppedFiles = Array.from(event.dataTransfer.files).filter(
    (file: any) => file && file.type
  );

  filesUploadRef.value?.uploadFiles(droppedFiles);
};

const onFilesPaste = (event) => {
  if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
    return;
  }

  const items = (event.clipboardData || event.originalEvent.clipboardData)
    .items;

  // const pastedFiles = Array.from(items)
  //   .filter((item) => item && item.type)
  //   .map((item) => item.getAsFile());

  const pastedFiles = Array.from(items)
    .filter((item: any) => item && item.type)
    .map((item: any) => {
      const file = item.getAsFile();
      if (file.name === 'image.png' && file.type === 'image/png') {
        const fileData = new File([file], 'image.jpeg', {
          type: 'image/jpeg',
        });
        return fileData;
      } else {
        return file;
      }
    });

  filesUploadRef.value?.uploadFiles(pastedFiles);
};

const isPermissionViewFile = ref(false);
const isCheckHavePermission = () => {
  if (
    fileFilters.value?.groupId &&
    allGroupByIds.value[fileFilters.value?.groupId]?.isMember
  ) {
    return true;
  }

  switch (fileFilters.value?.category) {
    case $constants.FILE.CATEGORY.TASK:
    case $constants.FILE.CATEGORY.STORAGE:
      return true;
    case $constants.FILE.CATEGORY.CHAT:
      return !(
        fileFilters.value?.groupId &&
        !allGroupByIds.value[fileFilters.value.groupId]?.isMember
      );
    default:
      return !!(
        fileFilters.value?.userId ||
        (fileFilters.value?.groupId &&
          allGroupByIds.value[fileFilters.value.groupId]?.isMember) ||
        (fileFilters.value?.groupId &&
          !allGroupByIds.value[fileFilters.value.groupId]?.isMember &&
          myProfile.value?.adminType === 1)
      );
  }
};

const _initPage = () => {
  if (props.fixedMember) {
    memberSelected.value = props.fixedMember;

    fileFilters.value.userId =
      props.fixedMember?.isGroup || props.fixedMember?.isDomain
        ? null
        : props.fixedMember?.id;

    fileFilters.value.groupId = props.fixedMember?.isGroup
      ? props.fixedMember?.id
      : null;

    fileFilters.value.domainId = props.fixedMember?.isDomain
      ? props.fixedMember?.id
      : null;
    fileFilters.value.projectId = props.fixedMember?.isDomain
      ? props.fixedMember?.projectId
      : null;
    fileFilters.value.subProjectId = props.fixedMember?.isDomain
      ? props.fixedMember?.subProjectId
      : null;
  } else {
    memberSelected.value = { id: myProfile.value?.id, isGroup: false };

    fileFilters.value.userId = myProfile.value?.id;
    fileFilters.value.groupId = null;
  }

  fileFilters.value.category = props.defaultCategory;

  _getFiles();
};

const _getFiles = async (refresh = true, loading = true) => {
  isPermissionViewFile.value = isCheckHavePermission();

  if (!isPermissionViewFile.value) return;

  const params = { ...fileFilters.value };

  // Default sort
  if (!params.sortBy) {
    // Default sort in Trash
    if (params.onlyDeletedFiles) {
      params.sortBy = 'deleted_date';
      params.orderBy = 'desc';
    }
    // Default sort in Resources
    else {
      params.sortBy = 'name';
      params.orderBy = 'asc';
    }
  }

  await _fileStorageStore.getFiles(params, refresh, loading);

  if (refresh) {
    filesListRef.value?.scrollTop();
    filesListRef.value?.setSelectionMode(false);
  }
};

const _processViewTrashChanged = () => {
  fileFilters.value.category = $constants.FILE.CATEGORY.STORAGE;
  fileFilters.value.groupId = null;
  fileFilters.value.domainId = null;
  fileFilters.value.projectId = null;
  fileFilters.value.subProjectId = null;
  fileFilters.value.folderId = null;
  fileFilters.value.sortBy = null;
  fileFilters.value.orderBy = null;

  _folderSelected.value = null;
  emit('onFolderChanged', null);

  // Open Trash
  if (fileFilters.value.onlyDeletedFiles) {
    memberSelected.value = { id: null, isGroup: false };
    fileFilters.value.userId = null;

    return _getFiles();
  }

  // Close Trash
  return _initPage();
};

const setFolderFromUrl = (folderId) => {
  if (folderId === folderSelected.value?.id) return;

  onFolderFromUrlChange(folderId);
};

defineExpose({
  setFolderFromUrl,
});
</script>

<template>
  <div
    id="resource-page"
    :ref="resourceElement"
    class="h-full focus:outline-none overflow-auto flex flex-col"
    tabindex="0"
  >
    <GedPageHeaderTrash
      v-if="fileFilters.onlyDeletedFiles"
      @on-close-click="
        fileFilters.onlyDeletedFiles = false;
        onViewTrashChange();
      "
      @on-empty-trash-confirm="onEmptyTrashConfirm"
    />
    <GedPageHeader
      v-else-if="!fixedMember || isModal"
      v-model:selected-member="memberSelected"
      v-model:selected-category-id="fileFilters.category"
      v-model:is-view-trash="fileFilters.onlyDeletedFiles"
      :file-filters="fileFilters"
      @update:selected-member="onMemberChange"
      @update:selected-category-id="onCategoryChange"
      @update:is-view-trash="onViewTrashChange"
      @update:file-filters="onFilterUpdate"
    />
    <!--CONTENT-->
    <div class="flex-1 overflow-auto pt-0 rounded-tl">
      <div class="h-full overflow-auto bg-white flex" @paste="onFilesPaste">
        <div class="flex-1 overflow-auto flex flex-col">
          <!--FOLDERS BREADCRUMB-->
          <div
            class="mb-2 flex items-center w-full"
            :class="!!fixedMember ? 'border-b border-gray-100' : ''"
          >
            <div
              v-if="!!fixedMember && !fixedMember.isDomain && !isModal"
              class="flex items-center"
            >
              <GedFilterCategoriesButton
                v-model:selected-category-id="fileFilters.category"
                :selected-member="memberSelected"
                @update:selected-category-id="onCategoryChange"
              />
            </div>
            <FolderBreadcrumb
              v-if="folderSelected"
              :element="resourceElement"
              :class="
                !fixedMember && !isModal
                  ? 'border-b border-gray-100 w-full'
                  : ''
              "
              :folder="folderSelected"
              @on-folder-click="onFolderClick"
            />
          </div>
          <div
            v-permission-screen="{
              screenCode: screenCode,
              isListenUpdated: true,
            }"
            class="flex-1 overflow-hidden flex flex-col"
          >
            <!--TOOLBAR-->
            <GedFiltersToolbar
              :is-selection-mode="isSelectionMode"
              :is-show-action="!isModal"
              :is-view-by-list="isViewByList"
              :files-selected="filesSelected"
              :file-filters="fileFilters"
              :selected-folder="folderSelected"
              @on-search="onSearch"
              @on-selection-mode="onSelectionToggle"
              @on-selection-all="onSelectionAllToggle"
              @on-move-to-trash-click="onMoveToTrashClick(filesSelected)"
              @on-restore-click="onRestoreFromTrashClick(filesSelected)"
              @on-delete-permanently-click="
                onDeletePermanentlyClick(filesSelected)
              "
              @on-move-click="onMoveToFolderClick"
              @on-apply-filter="onApplyFilter"
              @on-view-by-list="onChangeView"
              @on-reload-click="onReloadClick"
              @on-upload-click="onUploadClick"
              @on-upload-files-selected="onUploadFilesSelected"
              @on-folder-added="onFolderAdded"
            />

            <template v-if="!isPermissionViewFile">
              <div class="px-3 pb-2 pt-8 flex-center flex-col">
                <SynAnimation
                  name="private"
                  stype="width: 100px; height: 100px"
                />
                <span class="text-gray-600 pt-3">{{
                  $t('GED_FOLDER_ACCESS_DENIED_MSG')
                }}</span>
              </div>
            </template>
            <template v-else>
              <!--TOTAL FILES-->
              <div v-if="filesShown" class="px-3 my-1 text-xs text-gray-500">
                {{
                  $t('GED_FILE_PER_TOTAL', {
                    file: filesShown,
                    total: filesTotal,
                  }) || `${filesShown}/${filesTotal} file(s)`
                }}
              </div>

              <!--LIST FILES-->
              <div
                v-drop-zone="{
                  isDragging: onFilesDragging,
                  onDrop: onFilesDrop,
                }"
                class="flex-1 overflow-auto relative"
              >
                <FilesList
                  ref="filesListRef"
                  :is-view-by-list="isViewByList"
                  :filter="fileFilters"
                  @on-file-info-click="fileShowDetail = $event"
                  @on-files-selected="filesSelected = $event"
                  @on-selection-mode="isSelectionMode = $event"
                  @on-folder-click="onFolderClick"
                  @on-move-to-click="onFileMoveToFolderClick"
                  @on-notification-setting-click="onNotificationSettingClick"
                  @on-move-to-trash-click="onMoveToTrashClick([$event])"
                  @on-restore-click="onRestoreFromTrashClick([$event])"
                  @on-delete-permanently-click="
                    onDeletePermanentlyClick([$event])
                  "
                  @on-load-more="onFilesLoadMore"
                  @on-sort-change="onFilesSortChange"
                />

                <UploadDropZone
                  v-if="isDraggingFilesUpload"
                  class="absolute top-0 left-0 right-0 bottom-0"
                />
              </div>
            </template>
          </div>
        </div>

        <div v-if="fileShowDetail" class="w-80 h-full border-l shadow">
          <FileDetailPanel
            :file="fileShowDetail"
            @on-close-click="fileShowDetail = null"
            @on-rename="onFileDetailRenamed"
          />
        </div>
      </div>
    </div>

    <FilesUpload
      ref="filesUploadRef"
      :file-filters="fileFilters"
      @on-uploaded="onUploaded"
    />

    <SynQuestionModal
      class="z-50"
      :visible="showDeletePreventMsg"
      :header-text="$t('COMMON_LABEL_ALERT')"
      :content-text="showDeletePreventMsg"
      :show-cancel-btn="false"
      :show-confirm-btn="true"
      :show-delete-btn="false"
      @on-confirm="showDeletePreventMsg = null"
      @cancel="showDeletePreventMsg = null"
    />

    <ModalFolderSelect
      v-if="isMoveToFolder"
      :current-location="currentLocationMove"
      :ignored-folder-ids="moveToIgnoredFolderIds"
      :confirm-function="moveToConfirmFunction"
      :function-name="$t('GED_FILE_MOVE_TO')"
      @on-close="isMoveToFolder = false"
      @on-confirm-processed="onMoveToFolderProcessed"
    />

    <notification-settings
      :folder="fileShowNotification"
      :ref-id="fileShowNotification?.id"
      scope="GROUP"
      :name="fileShowNotification?.name"
      :show-modal="isShowNotificationSetting"
      @change-notification="onChangeNotificationSetting"
      @cancel="(value) => (isShowNotificationSetting = false)"
    />
  </div>
</template>
