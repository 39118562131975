export default function clearAll() {
    const requireCommon = require.context(
        '../store',
        true,
        /[0-9a-z]\w+\.(ts|js)$/
    );

    requireCommon.keys().forEach((fileName: string) => {
        if (fileName == './ClearAll.ts') return;

        const componentConfig = requireCommon(fileName);

        if (
            !componentConfig ||
            !componentConfig.default ||
            typeof componentConfig.default !== 'function'
        )
            return;
        if (
            componentConfig.default() &&
            typeof componentConfig.default().$reset == 'function'
        )
            componentConfig.default().$reset();
    });
}
