<script setup lang="ts">
import { onMounted, ref } from 'vue';
import AtomKbdLabel from '@/ui/common/atoms/AtomLabels/AtomKbdLabel.vue';

const props = defineProps<{
  modelValue?: string;
  inputClass?: string;
  placeholder?: string;
  name?: string;
  autofocus?: boolean;
  totalResult?: number;
  currentIndex?: number;
  isBlurWhenEnter?: boolean;
  isShowShortCutKey?: boolean;
  keyCode?: string;
}>();

const emit = defineEmits([
  'update:modelValue',
  'update:currentIndex',
  'blur',
  'onClear',
  'enter',
  'onFocus',
  'onSearch',
  'onKeydown',
]);

let typingTimer;

const inputSearchRef = ref();

const onSearchClear = () => {
  emit('update:modelValue', null);
  emit('onClear');

  inputSearchRef.value?.focus();

  clearTimeout(typingTimer);
  emit('onSearch');
};

onMounted(() => {
  setTimeout(() => props.autofocus && inputSearchRef.value?.focus());
});

const onEnter = (event) => {
  props?.isBlurWhenEnter && event?.target?.blur();
  emit('enter', event);
};

const focus = () => {
  setTimeout(() => {
    inputSearchRef.value?.focus();
  });
};
const onChange = (event) => {
  emit('update:modelValue', event?.target?.value);
  emit('update:currentIndex', 0);

  clearTimeout(typingTimer);
  typingTimer = setTimeout(() => {
    emit('onSearch');
  }, 300);
};

const onScrollToCurrentItem = () => {
  const element = document.getElementById(
    `vig-search-box-item-id_${props.name ? props.name + '_' : ''}${
      props.currentIndex
    }`
  ) as HTMLDivElement;

  element?.scrollIntoView({ block: 'center' });
};

const onKeyDownSearch = () => {
  emit(
    'update:currentIndex',
    props.currentIndex && props.currentIndex >= (props.totalResult || 0) - 1
      ? 0
      : Math.min((props.currentIndex || 0) + 1, props.totalResult || 0)
  );

  setTimeout(onScrollToCurrentItem);
};

const onKeyUpSearch = () => {
  emit(
    'update:currentIndex',
    props.currentIndex == 0
      ? (props.totalResult || 1) - 1
      : Math.max((props.currentIndex || 0) - 1, 0)
  );

  setTimeout(onScrollToCurrentItem);
};

defineExpose({
  focus,
});
</script>

<template>
  <div class="relative">
    <input
      ref="inputSearchRef"
      :value="modelValue"
      type="text"
      :class="`vig-input py-1.5 px-8 rounded-full ${inputClass}`"
      :placeholder="placeholder || $t('COMMON_LABEL_SEARCH') || 'Search'"
      @input="onChange"
      @keydown.enter="onEnter"
      @keydown="$emit('onKeydown', $event)"
      @blur="$emit('blur')"
      @focus="$emit('onFocus')"
      @keyup.down="onKeyDownSearch"
      @keyup.up="onKeyUpSearch"
    />
    <SynIcon
      name="Search"
      class="absolute top-1/2 left-2.5 -mt-2"
      custom-class="w-4 h-4 fill-gray-500"
    />
    <button
      v-if="modelValue"
      class="
        absolute
        top-1/2
        right-2.5
        -mt-2.5
        w-5
        h-5
        flex-center
        rounded-full
        hover:bg-gray-200
      "
      @click.stop="onSearchClear"
    >
      <SynIcon
        name="Close"
        :custom-class="
          modelValue
            ? 'w-4 h-4 fill-red-500 text-red-500'
            : 'w-4 h-4 fill-gray-500 text-gray-500'
        "
      />
    </button>
    <div
      v-if="!modelValue && isShowShortCutKey"
      class="absolute top-1/2 right-3 text-2xs"
      style="margin-top: -11px"
    >
      <AtomKbdLabel :key-code="keyCode" />
    </div>
  </div>
</template>
