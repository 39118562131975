<script setup lang="ts">
import { computed, ref } from 'vue';
import { TASK_PIN_BY_CODE } from '@/application/constants/task.const';

const props = defineProps<{
  currentPinCode?: string;
}>();

const emit = defineEmits(['onChangePin', 'onUnpin']);

const currentPin = computed<any>(() => {
  if (!props.currentPinCode) return TASK_PIN_BY_CODE.UN_PIN;
  return TASK_PIN_BY_CODE[props.currentPinCode];
});
const isOpenDropdown = ref<boolean>(false);

const onChangePin = (pin) => {
  emit('onChangePin', pin);
};

const togglePin = () => {
  if (currentPin.value?.code == 'UN_PIN')
    emit('onChangePin', TASK_PIN_BY_CODE.PIN_GRAY);
  else emit('onUnpin');
};
</script>
<template>
  <div class="flex-center space-x-1 pin-task-current">
    <div
      class="
        flex-center
        rounded
        w-8
        h-8
        p-2
        hover:bg-gray-100
        cursor-pointer
        relative
      "
      :class="isOpenDropdown ? 'bg-gray-100' : ''"
      @click="togglePin"
    >
      <SynIcon
        name="pin"
        custom-class="w-5 h-5"
        :is-active="currentPin?.code !== 'UN_PIN'"
        :style="{
          fill: currentPin?.color,
        }"
      />
    </div>
    <div class="w-4 h-8 flex-center flex-col pin-task-current__hover-to-show">
      <VigDropdown
        @on-dropdown-open="isOpenDropdown = true"
        @on-dropdown-close="isOpenDropdown = false"
      >
        <template #dropdown-toggle>
          <div class="w-4 h-8 cursor-pointer rounded flex items-center">
            <SynIcon
              name="chevron-right"
              :custom-class="
                isOpenDropdown
                  ? 'w-2 h-2 fill-gray-500 -rotate-90'
                  : 'w-2 h-2 fill-gray-500 rotate-90'
              "
            />
          </div>
        </template>
        <template #dropdown-menu>
          <div class="w-max flex-center space-x-1 p-1">
            <template
              v-for="(pin, index) in Object.values(TASK_PIN_BY_CODE)?.filter(
                (pin) => pin.code !== 'UN_PIN' && pin.code !== currentPin?.code
              )"
              :key="index"
            >
              <div
                class="
                  flex-center
                  rounded-md
                  w-8
                  h-8
                  p-2
                  hover:bg-gray-100
                  cursor-pointer
                  dropdown-item
                "
                @click="onChangePin(pin)"
              >
                <SynIcon
                  name="pin"
                  is-active
                  custom-class="w-5 h-5"
                  :style="{
                    fill: pin?.color,
                  }"
                />
              </div>
            </template>
          </div>
        </template>
      </VigDropdown>
    </div>
  </div>
</template>
<style>
.pin-task-current__hover-to-show {
  visibility: hidden;
}
.pin-task-current:hover .pin-task-current__hover-to-show {
  visibility: visible;
}
</style>
