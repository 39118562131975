<script setup lang="ts">
// *** IMPORTS ***
import { onBeforeMount, onBeforeUnmount, ref } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import {
  VideoRecordingModel,
  VideoRecordingStatus,
} from '@/domain/entities/video-recording/VideoRecordingModel';
import VideoRecorderConfigs from '@/ui/modules/video-recorder/recorder-configs/VideoRecorderConfigs.vue';
import VideoRecording from '@/ui/modules/video-recorder/video-recording/VideoRecording.vue';
import VideoRecordingResult from '@/ui/modules/video-recorder/recording-result/VideoRecordingResult.vue';
import SynConfirmModal from '@/ui/common/molecules/SynModal/SynConfirmModal.vue';
import Notification from '@/ui/components/notification/Notification.vue';

// *** PROPS, EMITS ***

// *** PRIVATE VARIABLES ***
const _myProfileStore = myProfileStore();
let _isCloseConfirmed: boolean;

// *** COMPOSABLES ***

// *** REFS ***
const recording = ref<VideoRecordingModel | null>();

// *** COMPUTED ***

// *** WATCHES ***

// *** HOOKS ***
onBeforeMount(async () => {
  await Promise.all([_myProfileStore.fetchCurrentLoginUser()]);

  window.addEventListener('beforeunload', _handleBeforeUnload);
});

onBeforeUnmount(async () => {
  window.removeEventListener('beforeunload', _handleBeforeUnload);
});

// *** HANDLER FUNCTIONS ***
const onRecordingStatusChange = (status) => {
  console.log('---onRecordingStatusChange', status);
  if (recording.value) recording.value.status = status;

  console.log('---onRecordingStatusChange recording.value', recording.value);
};

const onRecorderCloseConfirm = () => {
  _isCloseConfirmed = true;
  window.close();
};

const onRecordingRestart = () => {
  recording.value = null;
  _isCloseConfirmed = false;
};

// *** PRIVATE FUNCTIONS ***
const _handleBeforeUnload = (event: any) => {
  if (recording.value && !_isCloseConfirmed) {
    event.preventDefault();
    event.returnValue = true;
  }
};

// *** EXPOSES ***
</script>

<template>
  <div class="w-screen h-screen bg-gray-800 relative">
    <VideoRecorderConfigs
      v-if="!recording"
      @on-recording-start="recording = $event"
    />

    <VideoRecording
      v-if="
        recording?.status === VideoRecordingStatus.Created ||
        recording?.status === VideoRecordingStatus.Recording
      "
      :recording="recording"
      @on-recording-stop="recording = $event"
    />

    <VideoRecordingResult
      v-if="
        recording?.status === VideoRecordingStatus.MediaProcessing ||
        recording?.status === VideoRecordingStatus.MediaProcessed
      "
      :recording="recording"
      @on-status-change="onRecordingStatusChange"
      @on-close-confirm="onRecorderCloseConfirm"
      @on-restart="onRecordingRestart"
    />
  </div>

  <SynConfirmModal />
  <Notification />
</template>

<style scoped></style>
