<script setup lang="ts">
import {
  formatTaskCode,
  getBgClass,
  getConfigScheduleColor,
  getDetailTaskUrl,
  taskStates,
} from '@/ui/hooks/taskHook';
import bindingInterfaceTaskComposables from '@/ui/composables/task/binding-interface-task';
import taskStore from '@/store/task';
import { computed } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import { PlanTypeEnum, TaskLife } from '@/domain/enums/taskEnum';
import UserByAction from '@/ui/components/user/UserByAction.vue';
import GroupById from '@/ui/components/group/GroupById.vue';
import DomainProjectById from '@/ui/modules/domains/component/DomainProjectById.vue';
import UpdateDeadlineTime from '@/ui/modules/task/detail/UpdateDeadlineTime.vue';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import { TaskApprovalStatus } from '@/application/types/task/task.types';
import TaskApprovalShortInfo from '@/ui/modules/task/approval/TaskApprovalShortInfo.vue';
import TaskService from '@/application/services/task/TaskService';
import ColumnFilter from '@/ui/modules/task/table/ColumnFilter.vue';
import taskListStore from '@/store/task/task-list-store';
import taskDrawerStore from '@/store/task/drawer';
import { isOpenTaskDrawer } from '@/ui/modules/task/task-global-state';

const props = withDefaults(
  defineProps<{
    type?: string;
    taskList: any[];
    selectedIdList?: any[];
    columnList?: any[];
    multipleSelectable?: boolean;
    sortDataOrderByKey?: any;
    isFilterableInColumn?: boolean;
    filterDataByKey?: any;
    isHiddenHeader?: boolean;
    isShowAddNewButton?: boolean;
    size?: string;
  }>(),
  {
    columnList: () => [
      'DOMAIN',
      'URGENCY',
      'DEADLINE',
      'TASK_LIFE',
      'PLANNING',
      'ASSIGNEE',
      'CREATOR',
      'CREATED_TIME',
      'GROUP',
    ],
  }
);

const emit = defineEmits<{
  (e: 'choose', task: any): void;
  (e: 'openDetail', task: any): void;
  (e: 'updateTaskItem', task: any): void;
  (e: 'reload'): void;
  (e: 'onLoadMore'): void;
  (e: 'onClickSort', key, name, orderFunction): void;
  (e: 'onClickHiddenColumn'): void;
  (e: 'onFilterByKeyOnColumn', key, filters): void;
  (e: 'onResetFilterOnColumn', key: string): void;
  (e: 'addNewTask'): void;
}>();

const isGroup = computed(() => props.type == 'group');
const isDomain = computed(() => props.type == 'domain');
const _taskStore = taskStore();

const myTimezone = computed(() => myProfileStore().timezone);
const isGroupSubTasks = computed(() => taskListStore().isGroupSubTasks);
const parentTaskByIds = computed(() => taskListStore().parentTaskByIds);

const getConfigScheduleName = (task) => {
  return _taskStore.getConfigScheduleName(task);
};

const { isTaskHasJustCreate } = bindingInterfaceTaskComposables(null);

const getConfigName = (sitKey, typeKey) => {
  return _taskStore.getConfigName(sitKey, typeKey);
};

const onRequestApproval = async (task, comment) => {
  try {
    await TaskService.getInstance().requestTaskApproval(
      task.id,
      comment,
      undefined
    );

    emit('updateTaskItem', {
      ...task,
      approvalStatus: TaskApprovalStatus.WAITING,
    });
  } catch (error) {
    console.log('🚀 Tictop ~ error:', error);
  }
};

const onClickToggleIcon = (taskId) => {
  taskListStore().parentTaskByIds[taskId] = {
    ...taskListStore().parentTaskByIds[taskId],
    isCollapse: !taskListStore().parentTaskByIds[taskId]?.isCollapse,
  };
};
const currentTaskId = computed(() => taskDrawerStore().currentTabId);
</script>
<template>
  <SynTableCustom>
    <template v-if="!isHiddenHeader" #header>
      <SynTh
        v-if="multipleSelectable && taskList?.length > 0"
        style="width: 6rem"
      />
      <SynTh
        v-if="isGroupSubTasks && taskList?.length > 0"
        style="width: 6rem"
      />

      <SynTh
        :label="$t('TASK_TABLE_LABEL_TASKS')"
        is-sort
        :order-data="sortDataOrderByKey?.name"
        style="min-width: 24rem"
        @on-sort="
          $emit('onClickSort', 'name', $t('TASK_TABLE_LABEL_TASKS'), 'name')
        "
      >
        <!-- ADD TASKS -->
        <div
          v-if="isShowAddNewButton"
          class="
            ml-2
            flex-center
            p-2
            bg-current-400
            rounded
            cursor-pointer
            hover:bg-current-500 hover:ring hover:ring-current-100
          "
          @click.stop="$emit('addNewTask')"
        >
          <SynIcon custom-class="fill-white w-3.5 h-3.5" name="plus" />
        </div>
        <template v-if="isFilterableInColumn && !isGroup" #custom>
          <ColumnFilter
            type="group"
            :data-filters="filterDataByKey['groupId']"
            @on-ok="(data) => $emit('onFilterByKeyOnColumn', 'groupId', data)"
            @on-reset-filter="$emit('onResetFilterOnColumn', 'groupId')"
          />
        </template>
      </SynTh>
      <SynTh
        v-if="columnList?.includes('DOMAIN')"
        :label="$t('TASK_CREATE_FORM_DOMAIN')"
        is-sort
        :order-data="sortDataOrderByKey?.domainId"
        @on-sort="
          $emit(
            'onClickSort',
            'domainId',
            $t('TASK_TABLE_LABEL_PROJECT'),
            'domainId'
          )
        "
      >
        <template v-if="isFilterableInColumn" #custom>
          <ColumnFilter
            v-if="isDomain"
            type="project"
            :data-filters="filterDataByKey['projectId']"
            @on-ok="(data) => $emit('onFilterByKeyOnColumn', 'projectId', data)"
            @on-reset-filter="$emit('onResetFilterOnColumn', 'projectId')"
          />
          <ColumnFilter
            v-else
            type="domain"
            :data-filters="filterDataByKey['domainId']"
            @on-ok="(data) => $emit('onFilterByKeyOnColumn', 'domainId', data)"
            @on-reset-filter="$emit('onResetFilterOnColumn', 'domainId')"
          />
        </template>
      </SynTh>
      <SynTh
        v-if="columnList?.includes('URGENCY')"
        :label="$t('TASK_TABLE_LABEL_URGENCY')"
        is-center
        is-sort
        :order-data="sortDataOrderByKey?.urgency"
        @on-sort="
          $emit(
            'onClickSort',
            'urgency',
            $t('TASK_TABLE_LABEL_URGENCY'),
            'urgency'
          )
        "
        ><template v-if="isFilterableInColumn" #custom>
          <ColumnFilter
            type="urgency"
            :data-filters="filterDataByKey['urgency']"
            @on-ok="(data) => $emit('onFilterByKeyOnColumn', 'urgency', data)"
            @on-reset-filter="$emit('onResetFilterOnColumn', 'urgency')"
          />
        </template>
      </SynTh>

      <SynTh
        v-if="columnList?.includes('CREATOR')"
        :label="$t('TASK_TABLE_LABEL_CREATOR')"
        is-center
        style="width: 5rem"
        is-sort
        :order-data="sortDataOrderByKey?.creatorId"
        @on-sort="
          $emit(
            'onClickSort',
            'creatorId',
            $t('TASK_TABLE_LABEL_REPORTER'),
            'creatorId'
          )
        "
      >
        <template #custom>
          <ColumnFilter
            v-if="isFilterableInColumn"
            type="user"
            :data-filters="filterDataByKey['creatorId']"
            @on-ok="(data) => $emit('onFilterByKeyOnColumn', 'creatorId', data)"
            @on-reset-filter="$emit('onResetFilterOnColumn', 'creatorId')"
          />
        </template>
      </SynTh>
      <SynTh
        v-if="columnList?.includes('DEADLINE')"
        :label="$t('TASK_TABLE_LABEL_DEADLINE')"
        is-sort
        :order-data="sortDataOrderByKey?.scheduleTime"
        position-text-header="justify-start"
        @on-sort="
          $emit(
            'onClickSort',
            'scheduleTime',
            $t('TASK_TABLE_LABEL_DEADLINE'),
            'scheduleTime'
          )
        "
      >
        <template v-if="isFilterableInColumn" #custom>
          <ColumnFilter
            type="date"
            is-nothing
            :data-filters="filterDataByKey['scheduleTime']"
            @on-ok="
              (data) => $emit('onFilterByKeyOnColumn', 'scheduleTime', data)
            "
            @on-reset-filter="$emit('onResetFilterOnColumn', 'scheduleTime')"
          />
        </template>
      </SynTh>
      <SynTh
        v-if="columnList?.includes('TASK_LIFE')"
        :label="$t('TASK_EXPORT_COLUMNS_STATUS')"
        is-sort
        :order-data="sortDataOrderByKey?.taskLife"
        @on-sort="
          $emit(
            'onClickSort',
            'taskLife',
            $t('TASK_TABLE_LABEL_TASKS'),
            (t) => {
              return t.taskLife == TaskLife.InProcess
                ? 1
                : t.taskLife == TaskLife.Todo
                ? 2
                : t.taskLife == TaskLife.Created
                ? 3
                : t.taskLife == TaskLife.Finished
                ? 4
                : taskStates[t.taskLife]?.index + 4;
            }
          )
        "
      >
        <template v-if="isFilterableInColumn" #custom>
          <ColumnFilter
            type="status"
            :data-filters="filterDataByKey['taskLife']"
            @on-ok="(data) => $emit('onFilterByKeyOnColumn', 'taskLife', data)"
            @on-reset-filter="$emit('onResetFilterOnColumn', 'taskLife')"
          />
        </template>
      </SynTh>
      <SynTh
        v-if="columnList?.includes('PLANNING')"
        :label="$t('TASK_FILTER_LABEL_PLANNED')"
      />
      <SynTh
        v-if="columnList?.includes('ASSIGNEE')"
        :label="$t('TASK_LABEL_ASSIGNEE')"
        is-center
        is-sort
        :order-data="sortDataOrderByKey?.assigneeId"
        position-text-header="justify-center"
        @on-sort="
          $emit(
            'onClickSort',
            'assigneeId',
            $t('TASK_LABEL_ASSIGNEE'),
            'assigneeId'
          )
        "
      >
        <template v-if="isFilterableInColumn" #custom>
          <ColumnFilter
            type="user"
            :data-filters="filterDataByKey['assigneeId']"
            @on-ok="
              (data) => $emit('onFilterByKeyOnColumn', 'assigneeId', data)
            "
            @on-reset-filter="$emit('onResetFilterOnColumn', 'assigneeId')"
          />
        </template>
      </SynTh>
      <SynTh
        v-if="columnList?.includes('CREATED_TIME')"
        :label="$t('TASK_TABLE_LABEL_CREATED_DATE')"
        is-sort
        :order-data="sortDataOrderByKey?.creationTime"
        position-text-header="justify-end"
        @on-sort="
          $emit(
            'onClickSort',
            'creationTime',
            $t('TASK_TABLE_LABEL_CREATED_DATE'),
            'creationTime'
          )
        "
      >
        <template v-if="isFilterableInColumn" #custom>
          <ColumnFilter
            type="date"
            :data-filters="filterDataByKey['creationTime']"
            @on-ok="
              (data) => $emit('onFilterByKeyOnColumn', 'creationTime', data)
            "
            @on-reset-filter="$emit('onResetFilterOnColumn', 'creationTime')"
          />
        </template>
      </SynTh>
      <SynTh />
    </template>

    <template #body>
      <!--STEP TASK-->
      <template v-for="task in taskList" :key="task?.id">
        <SynTr
          v-if="task?.isNotBelongThis"
          class="
            bg-white
            cursor-pointer
            hover:bg-gray-100 hover:text-current-500
            border-t border-gray-50
            hover-to-show__parent
            hover-to-hidden__parent
            h-[2.5rem]
            relative
          "
          :class="[
            currentTaskId == task?.id ? 'border-l-2' : '',
            isOpenTaskDrawer ? 'border-current' : 'border-gray-300',
          ]"
          @click="onClickToggleIcon(task?.id)"
        >
          <!-- MULTIPLE_SELECTABLE -->
          <SynTd
            v-if="multipleSelectable && taskList?.length > 0"
            style="width: 6rem"
          />

          <!-- IS_GROUP_SUB_TASKS -->
          <SynTd
            v-if="isGroupSubTasks && taskList?.length > 0"
            style="width: 6rem"
          >
            <span
              v-if="
                Object.prototype.hasOwnProperty.call(parentTaskByIds, task?.id)
              "
              class="w-5 h-5 flex-center"
              @click.stop="onClickToggleIcon(task?.id)"
            >
              <SynIcon
                name="sort-down"
                custom-class="w-2 h-2 fill-current"
                :class="
                  parentTaskByIds[task?.id]?.isCollapse ? '-rotate-90' : ''
                "
              />
            </span>
          </SynTd>
          <!--TASK NAME-->
          <SynTd
            class="min-w-[20rem] relative"
            :style="{
              maxWidth: size == 'sm' ? '20rem' : '30vw',
            }"
          >
            <div
              class="
                flex-1
                overflow-hidden
                flex flex-nowrap
                items-center
                gap-1.5
              "
            >
              <span class="text-xs text-gray-500">
                {{ formatTaskCode(task.code) }}
              </span>
              <span class="text-xs italic text-gray-600 truncate">
                {{
                  $t(
                    'TASK_LABEL_THIS_WORK_DOES_NOT_BELONG_TO_THIS_FIELD_PROJECT'
                  )
                }}
              </span>
              <span
                v-if="task?.subTasks?.length > 0"
                v-vig-tooltip="$t('COMMON_LABEL_SUB_TASK')"
                class="text-xs p-0.5 flex-center space-x-0.5 rounded"
                :style="{
                  fill: '#008e8e',
                  color: '#008e8e',
                }"
              >
                <SynIcon is-active name="SubTask" custom-class="w-3 h-3" />
                <template v-if="task?.subTasks?.length > 1">
                  <span>
                    {{ task?.subTasks?.length }}
                  </span>
                </template>
              </span>
            </div>
          </SynTd>
          <SynTd v-if="columnList?.includes('DOMAIN')"></SynTd>
          <SynTd v-if="columnList?.includes('URGENCY')"></SynTd>
          <SynTd v-if="columnList?.includes('CREATOR')"></SynTd>
          <SynTd v-if="columnList?.includes('DEADLINE')"></SynTd>
          <SynTd v-if="columnList?.includes('TASK_LIFE')"></SynTd>
          <SynTd v-if="columnList?.includes('PLANNING')"></SynTd>
          <SynTd v-if="columnList?.includes('ASSIGNEE')"></SynTd>
          <SynTd v-if="columnList?.includes('CREATED_TIME')"></SynTd>
          <SynTd v-if="columnList?.includes('CREATED_TIME')"></SynTd>
        </SynTr>
        <SynTr
          v-else
          class="
            bg-white
            cursor-pointer
            hover:bg-gray-100 hover:text-current-500
            border-t border-gray-50
            hover-to-show__parent
            hover-to-hidden__parent
            h-[2.5rem]
            border-l-4
          "
          :class="[
            isOpenTaskDrawer && currentTaskId == task?.id
              ? 'border-l-current'
              : currentTaskId == task?.id
              ? 'border-l-gray-300'
              : 'border-l-white hover:border-l-gray-100',
          ]"
          @click="$emit('openDetail', task)"
        >
          <!-- MULTIPLE_SELECTABLE -->
          <SynTd
            v-if="multipleSelectable && taskList?.length > 0"
            style="width: 6rem"
          />
          <!-- IS_GROUP_SUB_TASKS -->
          <SynTd
            v-if="isGroupSubTasks && taskList?.length > 0"
            style="width: 6rem"
          >
            <span
              v-if="
                Object.prototype.hasOwnProperty.call(
                  parentTaskByIds,
                  task?.id
                ) && task?.subTasks?.length > 0
              "
              class="w-5 h-5 flex-center"
              @click.stop="onClickToggleIcon(task?.id)"
            >
              <SynIcon
                name="sort-down"
                custom-class="w-2 h-2 fill-current"
                :class="
                  parentTaskByIds[task?.id]?.isCollapse ? '-rotate-90' : ''
                "
              />
            </span>
          </SynTd>

          <!--TASK NAME-->
          <SynTd
            class="min-w-[20rem] relative"
            :style="{
              maxWidth: size == 'sm' ? '20rem' : '30vw',
            }"
          >
            <a
              :title="task.name"
              :href="getDetailTaskUrl(task.code, false)"
              class="flex items-center gap-3"
              @click.prevent
            >
              <div
                class="
                  flex-1
                  overflow-hidden
                  flex flex-nowrap
                  items-center
                  gap-1.5
                "
              >
                <span class="text-xs text-gray-500">
                  {{ formatTaskCode(task.code) }}
                </span>
                <div
                  v-encode-html="{ text: task?.name }"
                  class="truncate"
                ></div>
                <SynIcon
                  v-if="task?.isPrivate"
                  name="private"
                  custom-class="w-2.5 h-2.5 fill-gray-500 min-w-[0.625rem]"
                />
                <SynIcon
                  v-if="task?.isRepeat"
                  name="recurrence2"
                  class="fill-gray-500 min-w-[1rem]"
                />
                <span
                  v-if="task?.subTasks?.length > 0"
                  v-vig-tooltip="$t('COMMON_LABEL_SUB_TASK')"
                  class="text-xs p-0.5 flex-center space-x-0.5 rounded"
                  :style="{
                    fill: '#008e8e',
                    color: '#008e8e',
                  }"
                >
                  <SynIcon is-active name="SubTask" custom-class="w-3 h-3" />
                  <template v-if="task?.subTasks?.length > 1">
                    <span>
                      {{ task?.subTasks?.length }}
                    </span>
                  </template>
                </span>
              </div>

              <div
                v-if="task?.groupId && columnList?.includes('GROUP')"
                class="min-w-[5rem] max-w-[30%]"
              >
                <GroupById
                  :group-id="task.groupId"
                  class="text-xs"
                  avatar-class="w-3 h-3"
                />
              </div>
            </a>

            <!--NEW LABEL-->
            <span
              v-if="isTaskHasJustCreate(task)"
              class="
                absolute
                top-0
                right-0
                text-[0.6rem]
                leading-4
                bg-current
                text-white
                px-1.5
                rounded-b-md
              "
            >
              {{ $t('COMMON_LABEL_NEW') }}
            </span>
          </SynTd>

          <!--DOMAIN-->
          <SynTd v-if="columnList?.includes('DOMAIN')">
            <DomainProjectById
              class="max-w-[10rem]"
              :domain-id="task?.domainId"
              :project-id="task?.projectId"
              :sub-project-id="task?.subprojectId"
            />
          </SynTd>
          <!--PRIORITY-->
          <SynTd v-if="columnList?.includes('URGENCY')" is-center>
            <PriorityTask
              :priority="task?.priority"
              :important="task?.important"
              size="mini"
              is-hidden-label
            />
          </SynTd>
          <!--CREATOR-->
          <SynTd v-if="columnList?.includes('CREATOR')" class="w-0 pl-0">
            <div v-if="task?.creatorId" class="flex-center">
              <UserByAction
                :user-id="task?.creatorId"
                is-hidden-name
                is-show-action-user
                avatar-class="w-7 h-7"
              />
            </div>
          </SynTd>

          <!--DEADLINE-->
          <SynTd v-if="columnList?.includes('DEADLINE')">
            <div class="flex items-center gap-1">
              <SynTag
                :class="[getConfigScheduleColor(task?.taskSchedule)]"
                class="border flex-center"
                size="small"
              >
                <span v-if="task.assigneeId && getConfigScheduleName(task)">
                  {{ getConfigScheduleName(task) }}
                </span>
                <SynLabelDateTime
                  v-else-if="task.scheduleTime"
                  format="date"
                  :datetime="task.scheduleTime"
                />
                <span v-else>
                  {{ $t('TASK_DETAIL_LABEL_NO_SCHEDULE') || 'No schedule' }}
                </span>
              </SynTag>
              <UpdateDeadlineTime
                v-if="task?.scheduleByHour"
                :class="[
                  getConfigScheduleColor(task?.taskSchedule),
                  'text-xs border rounded px-1 h-6 flex-center',
                ]"
                :schedule-time="task?.scheduleTime"
                :schedule-by-hour="task?.scheduleByHour"
                :schedule-timezone="task?.scheduleTimezone"
                :my-timezone="myTimezone"
                readonly
              />
            </div>
          </SynTd>

          <!--STATUS-->
          <SynTd v-if="columnList?.includes('TASK_LIFE')">
            <SynTag
              v-if="task.taskLife"
              class="rounded-full w-max px-2 text-xs"
              size="small"
              :class="[
                taskStates[task?.taskLife]?.background,
                taskStates[task?.taskLife]?.color,
              ]"
            >
              {{ $t(taskStates[task?.taskLife]?.nameCode) }}
            </SynTag>
          </SynTd>

          <!--PLANNING-->
          <SynTd v-if="columnList?.includes('PLANNING')">
            <SynTag
              v-if="
                task?.taskSchedule?.planType !== PlanTypeEnum.Nothing &&
                task?.taskLife !== TaskLife.Finished
              "
              class="font-normal"
              size="small"
              :class="getBgClass(task?.taskSchedule?.planType, 'planType')"
            >
              {{ getConfigName(task?.taskSchedule?.planType, 'planType') }}
            </SynTag>
          </SynTd>

          <!--ASSIGNEE-->
          <SynTd v-if="columnList?.includes('ASSIGNEE')" class="w-0 pl-0">
            <div v-if="task?.assigneeId" class="flex-center">
              <UserByAction
                :user-id="task?.assigneeId"
                is-hidden-name
                is-show-action-user
                avatar-class="w-5 h-5"
              />
            </div>
          </SynTd>

          <!--CREATE DATE-->
          <SynTd
            v-if="columnList?.includes('CREATED_TIME')"
            class="whitespace-nowrap text-xs"
          >
            <SynLabelDateTime format="date" :datetime="task?.creationTime" />
          </SynTd>

          <!--ACTIONS-->
          <SynTd></SynTd>
        </SynTr>

        <template
          v-if="
            task?.subTasks?.length > 0 && !parentTaskByIds[task?.id]?.isCollapse
          "
        >
          <SynTr
            v-for="subTask in task?.subTasks"
            :key="subTask?.id"
            class="
              bg-white
              cursor-pointer
              hover:bg-gray-100 hover:text-current-500
              border-t border-gray-50
              hover-to-show__parent
              hover-to-hidden__parent
              h-[2.5rem]
              border-l-4
            "
            :class="[
              isOpenTaskDrawer && currentTaskId == subTask?.id
                ? 'border-l-current'
                : currentTaskId == subTask?.id
                ? 'border-l-gray-300'
                : 'border-l-white hover:border-l-gray-100',
            ]"
            @click="$emit('openDetail', subTask)"
          >
            <SynTd
              v-if="multipleSelectable"
              class="relative whitespace-wrap h-full items-center"
              style="padding: 0"
            >
              <div class="flex-center">
                <div class="h-10 w-full flex-center">
                  <template v-if="subTask?.approvalEnabled">
                    <span
                      v-if="
                        subTask?.approvalStatus &&
                        subTask?.approvalStatus == TaskApprovalStatus.WAITING
                      "
                      class="text-xs pl-2"
                    >
                      {{ $t('TASK_LABEL_STAGE_WAITING_FOR_APPROVING') }}
                    </span>
                    <TaskApprovalShortInfo
                      v-else-if="
                        task?.approvalStatus == TaskApprovalStatus.NOT_REQUESTED
                      "
                      is-request-only
                      :task-detail="subTask"
                      :approval-enabled="subTask?.approvalEnabled"
                      :approval-status="subTask?.approvalStatus"
                      :approvers="subTask?.approvers"
                      :approver-id="subTask?.approverId"
                      @request-approval="
                        (comment) => onRequestApproval(subTask, comment)
                      "
                    />
                    <TaskApprovalShortInfo
                      v-else
                      :approval-enabled="subTask?.approvalEnabled"
                      :approval-status="subTask?.approvalStatus"
                      :approvers="subTask?.approvers"
                      :approver-id="subTask?.approverId"
                      readonly
                    />
                  </template>
                  <div
                    v-else
                    class="
                      group
                      h-8
                      w-8
                      flex-center
                      rounded-full
                      cursor-pointer
                    "
                    @click.stop="$emit('choose', subTask)"
                  >
                    <div
                      class="
                        cursor-pointer
                        h-4
                        w-4
                        flex-center
                        rounded
                        border border-current-300
                        group-hover:border-2
                      "
                      :class="{
                        'bg-current-300': selectedIdList?.includes(subTask?.id),
                      }"
                    >
                      <SynIcon
                        v-if="selectedIdList?.includes(subTask?.id)"
                        name="check"
                        class="text-white"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </SynTd>
            <SynTd v-if="isGroupSubTasks"> </SynTd>

            <!--TASK NAME-->
            <SynTd
              class="min-w-[20rem] relative"
              :style="{
                maxWidth: size == 'sm' ? '20rem' : '30vw',
              }"
            >
              <a
                :title="subTask?.name"
                :href="getDetailTaskUrl(subTask?.code, false)"
                class="flex items-center gap-3 pl-8"
                @click.prevent
              >
                <div
                  class="
                    flex-1
                    overflow-hidden
                    flex flex-nowrap
                    items-center
                    gap-1.5
                  "
                >
                  <span class="text-xs text-gray-500">
                    {{ formatTaskCode(subTask.code) }}
                  </span>
                  <div
                    v-encode-html="{ text: subTask?.name }"
                    class="truncate"
                  ></div>
                  <SynIcon
                    v-if="subTask?.isPrivate"
                    name="private"
                    custom-class="w-2.5 h-2.5 fill-gray-500 min-w-[0.625rem]"
                  />
                  <SynIcon
                    v-if="subTask?.isRepeat"
                    name="recurrence2"
                    class="fill-gray-500 min-w-[1rem]"
                  />
                </div>

                <div
                  v-if="subTask?.groupId && columnList?.includes('GROUP')"
                  class="min-w-[5rem] max-w-[30%]"
                >
                  <GroupById
                    :group-id="subTask.groupId"
                    class="text-xs"
                    avatar-class="w-3 h-3"
                  />
                </div>
              </a>

              <!--NEW LABEL-->
              <span
                v-if="isTaskHasJustCreate(subTask)"
                class="
                  absolute
                  top-0
                  right-0
                  text-[0.6rem]
                  leading-4
                  bg-current
                  text-white
                  px-1.5
                  rounded-b-md
                "
              >
                {{ $t('COMMON_LABEL_NEW') }}
              </span>
            </SynTd>

            <!--DOMAIN-->
            <SynTd v-if="columnList?.includes('DOMAIN')">
              <DomainProjectById
                class="max-w-[10rem]"
                :domain-id="subTask?.domainId"
                :project-id="subTask?.projectId"
                :sub-project-id="subTask?.subprojectId"
              />
            </SynTd>
            <!--PRIORITY-->
            <SynTd v-if="columnList?.includes('URGENCY')" is-center>
              <PriorityTask
                :priority="subTask?.priority"
                :important="subTask?.important"
                size="mini"
                is-hidden-label
              />
            </SynTd>
            <!--CREATOR-->
            <SynTd v-if="columnList?.includes('CREATOR')" class="w-0 pl-0">
              <div v-if="subTask?.creatorId" class="flex-center">
                <UserByAction
                  :user-id="subTask?.creatorId"
                  is-hidden-name
                  is-show-action-user
                  avatar-class="w-7 h-7"
                />
              </div>
            </SynTd>

            <!--DEADLINE-->
            <SynTd v-if="columnList?.includes('DEADLINE')">
              <div class="flex items-center gap-1">
                <SynTag
                  :class="[getConfigScheduleColor(subTask?.taskSchedule)]"
                  class="border flex-center"
                  size="small"
                >
                  <span
                    v-if="subTask.assigneeId && getConfigScheduleName(subTask)"
                  >
                    {{ getConfigScheduleName(subTask) }}
                  </span>
                  <SynLabelDateTime
                    v-else-if="subTask.scheduleTime"
                    format="date"
                    :datetime="subTask.scheduleTime"
                  />
                  <span v-else>
                    {{ $t('TASK_DETAIL_LABEL_NO_SCHEDULE') || 'No schedule' }}
                  </span>
                </SynTag>
                <UpdateDeadlineTime
                  v-if="subTask?.scheduleByHour"
                  :class="[
                    getConfigScheduleColor(subTask?.taskSchedule),
                    'text-xs border rounded px-1 h-6 flex-center',
                  ]"
                  :schedule-time="subTask?.scheduleTime"
                  :schedule-by-hour="subTask?.scheduleByHour"
                  :schedule-timezone="subTask?.scheduleTimezone"
                  :my-timezone="myTimezone"
                  readonly
                />
              </div>
            </SynTd>

            <!--STATUS-->
            <SynTd v-if="columnList?.includes('TASK_LIFE')">
              <SynTag
                v-if="subTask.taskLife"
                class="rounded-full w-max px-2 text-xs"
                size="small"
                :class="[
                  taskStates[subTask?.taskLife]?.background,
                  taskStates[subTask?.taskLife]?.color,
                ]"
              >
                {{ $t(taskStates[subTask?.taskLife]?.nameCode) }}
              </SynTag>
            </SynTd>

            <!--PLANNING-->
            <SynTd v-if="columnList?.includes('PLANNING')">
              <SynTag
                v-if="
                  subTask?.taskSchedule?.planType !== PlanTypeEnum.Nothing &&
                  subTask?.taskLife !== TaskLife.Finished
                "
                class="font-normal"
                size="small"
                :class="getBgClass(subTask?.taskSchedule?.planType, 'planType')"
              >
                {{ getConfigName(subTask?.taskSchedule?.planType, 'planType') }}
              </SynTag>
            </SynTd>

            <!--ASSIGNEE-->
            <SynTd v-if="columnList?.includes('ASSIGNEE')" class="w-0 pl-0">
              <div v-if="subTask?.assigneeId" class="flex-center">
                <UserByAction
                  :user-id="subTask?.assigneeId"
                  is-hidden-name
                  is-show-action-user
                  avatar-class="w-5 h-5"
                />
              </div>
            </SynTd>

            <!--CREATE DATE-->
            <SynTd
              v-if="columnList?.includes('CREATED_TIME')"
              class="whitespace-nowrap text-xs"
            >
              <SynLabelDateTime
                format="date"
                :datetime="subTask?.creationTime"
              />
            </SynTd>

            <!--ACTIONS-->
            <SynTd></SynTd>
          </SynTr>
        </template>
      </template>
    </template>
  </SynTableCustom>
  <div v-if="taskList?.length == 0" class="w-full">
    <syn-animation
      class="w-full flex-center"
      name="searchNotFound"
      stype="width: 100px; height: 100px;"
      :label="$t('COMMON_LABEL_ALERT_NO_TASK')"
    />
  </div>
</template>
