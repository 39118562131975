<script setup lang="ts">
// *** IMPORTS ***
import { onMounted, ref, watch } from 'vue';
import {
  activeCamera,
  activeMicrophone,
  cameras,
  initLocalDevices,
  microphones,
  selectCamera,
  selectMicrophone,
} from '@/ui/plugins/awss3/aws-chime';
import VideoRecordingRepository from '@/application/repositories/VideoRecordingRepository';
import { getNearestMediaRegion } from '@/application/api/chimeAws';

// *** PROPS, EMITS ***
// const props = defineProps<{
//   recording: VideoRecordingModel;
// }>();
const emit = defineEmits(['onRecordingStart']);

// *** PRIVATE VARIABLES ***

// *** COMPOSABLES ***

// *** REFS ***
const videoRef = ref<HTMLVideoElement>();
const microVolume = ref<number>();
const validationError = ref<string>();
const recordingStarting = ref<boolean>();

// *** COMPUTED ***

// *** WATCHES ***
watch(
  () => activeCamera.value,
  () => _bindVideoElement()
);

watch(
  () => activeMicrophone.value,
  () => _bindMicrophone()
);

// *** HOOKS ***
onMounted(async () => {
  await initLocalDevices();

  _bindVideoElement();
  _bindMicrophone();
});

// *** HANDLER FUNCTIONS ***
const onMicrophoneSelect = (value) => {
  selectMicrophone(value);
  validationError.value = '';
};

const onCameraSelect = (value) => {
  selectCamera(value);
  validationError.value = '';
};

const onStartRecordingClick = async () => {
  validationError.value = '';

  if (!activeMicrophone.value) {
    return (validationError.value = 'You must choose a microphone');
  }
  if (!activeCamera.value) {
    return (validationError.value = 'You must choose a camera');
  }

  recordingStarting.value = true;

  try {
    const region = await getNearestMediaRegion();

    const res: any =
      await VideoRecordingRepository.getInstance().createVideoRecording({
        region,
      });

    if (res?.result) emit('onRecordingStart', res?.result);
  } catch (e) {
    console.log(e);
  }

  recordingStarting.value = false;
};

// *** PRIVATE FUNCTIONS ***
const _bindVideoElement = async () => {
  if (!videoRef.value || !activeCamera.value) return;

  videoRef.value.srcObject?.getTracks()?.forEach((track) => track?.stop());

  const stream = await navigator.mediaDevices.getUserMedia({
    video: { deviceId: { exact: activeCamera.value.deviceId } },
  });

  videoRef.value.srcObject = stream;
  videoRef.value.play();
};

const _bindMicrophone = async () => {
  if (!activeMicrophone.value) return;

  const stream = await navigator.mediaDevices.getUserMedia({
    audio: { deviceId: { exact: activeMicrophone.value.deviceId } },
  });

  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  const source = audioContext.createMediaStreamSource(stream);

  const analyser = audioContext.createAnalyser();
  analyser.fftSize = 256;
  source.connect(analyser);

  const dataArray = new Uint8Array(analyser.frequencyBinCount);

  const updateVolume = () => {
    analyser.getByteFrequencyData(dataArray);

    let sum = 0;
    for (let i = 0; i < dataArray.length; i++) {
      sum += dataArray[i];
    }

    microVolume.value = ((sum / dataArray.length) * 20) / 100;

    requestAnimationFrame(updateVolume);
  };

  updateVolume();
};

// *** EXPOSES ***
</script>

<template>
  <div class="h-full flex-center overflow-auto small-scrollbar p-5">
    <div class="w-full max-w-[35rem]">
      <div class="text-3xl text-center text-current-500 mb-5">
        Video Recorder
      </div>

      <!--MICROPHONE-->
      <div class="flex gap-3 mb-2">
        <div class="w-[6rem] text-white">Microphone</div>
        <div class="flex-1 overflow-hidden">
          <VigSelect
            key-search="deviceId"
            key-label="label"
            toggle-class="w-full truncate mb-2"
            :searchable="false"
            :clearable="false"
            :label-value="activeMicrophone?.label"
            :origin-options="microphones"
            @on-choose="onMicrophoneSelect"
          />
          <div class="flex items-center justify-start space-x-1 mb-4">
            <div
              v-for="item in 20"
              :key="item"
              class="py-1 rounded-full min-w-[1rem]"
              :class="microVolume >= item ? 'bg-current-500' : 'bg-gray-300'"
            ></div>
          </div>
        </div>
      </div>

      <!--CAMERA-->
      <div class="flex gap-3 mb-2">
        <div class="w-[6rem] text-white">Camera</div>
        <div class="flex-1 overflow-hidden">
          <VigSelect
            key-search="deviceId"
            key-label="label"
            toggle-class="w-full truncate"
            :searchable="false"
            :clearable="false"
            :label-value="activeCamera?.label"
            :origin-options="cameras"
            @on-choose="onCameraSelect"
          />
        </div>
      </div>
      <div class="mb-4 w-full bg-gray-50 rounded-2xl overflow-hidden">
        <video ref="videoRef" class="w-full scale-x-[-1]"></video>
      </div>

      <div class="flex-center flex-col gap-2">
        <div
          v-if="validationError"
          class="text-sm leading-4 text-red-500 font-semibold"
        >
          {{ validationError }}
        </div>
        <VigButton
          rounded="rounded-full"
          class="text-lg"
          padding="px-6 py-2"
          :processing="recordingStarting"
          @click="onStartRecordingClick"
          ><span>Start recording</span>
        </VigButton>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
