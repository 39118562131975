<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue';
import { onChangeI18n } from '@/ui/plugins/i18n/myi18n';
import {
  StorageConstant,
  setLocalStorage,
  getLocalStorage,
} from '@/ui/hooks/storageHook';
import organizationStore from '@/store/organization';
import appStore from '@/store/app';
import { updateDeviceToken } from '@/ui/plugins/firebases/firebaseFcm';

defineProps<{
  isHiddenLabel?: boolean;
}>();

const _organizationStore = organizationStore();
const _appStore = appStore();
const currentLanguage = ref('');
onMounted(() => {
  currentLanguage.value = getLocalStorage(StorageConstant.LANGUAGE) || 'en';
});

watch(
  () => getLocalStorage(StorageConstant.LANGUAGE),
  () => {
    onChangeLanguage(getLocalStorage(StorageConstant.LANGUAGE));
  }
);

const onChangeLanguage = (key) => {
  currentLanguage.value = key;
  setLocalStorage(StorageConstant.LANGUAGE, key);
  _appStore.setLanguage(key);
  onChangeI18n(key);
  updateDeviceToken(key);
  _organizationStore.getOrganizationConfigDefault();
};

const currentLanguageFlag = computed(() => {
  if (!currentLanguage.value) return '';
  switch (currentLanguage.value) {
    case 'fr':
      return 'flag-france';
    case 'en':
      return 'flag-united-state';
    default:
      return 'flag-vietnam';
  }
});

const locationByDomain = computed(() =>
  getLocalStorage(StorageConstant.LOCATION)
);
</script>

<template>
  <VigDropdown>
    <template #dropdown-toggle>
      <div
        class="
          flex
          items-center
          space-x-2
          cursor-pointer
          border border-white
          hover:bg-current-50 hover:border-current-100
          px-4
          py-1
          rounded-md
          text-base
        "
      >
        <SynIcon :name="currentLanguageFlag" custom-class="h-6 w-6" />
        <span v-if="!isHiddenLabel">
          {{
            currentLanguage == 'fr'
              ? $t('SETTING_LANGUAGE_LABEL_SEARCH')
              : currentLanguage == 'en'
              ? $t('SETTING_LANGUAGE_LABEL_ENGLISH')
              : $t('SETTING_LANGUAGE_LABEL_VIETNAMESE')
          }}
        </span>
      </div>
    </template>
    <template #dropdown-menu>
      <div
        v-if="currentLanguage != 'fr'"
        class="
          dropdown-item
          flex
          items-center
          space-x-2
          cursor-pointer
          hover:bg-current-50
          pl-2
          pr-8
          py-2
          text-base
        "
        @click="onChangeLanguage('fr')"
      >
        <SynIcon name="flag-france" custom-class="h-6 w-6" />
        <span v-if="!isHiddenLabel">{{
          $t('SETTING_LANGUAGE_LABEL_SEARCH') || 'French'
        }}</span>
      </div>
      <div
        v-if="currentLanguage != 'vi' && locationByDomain !== 'EU'"
        class="
          dropdown-item
          flex
          items-center
          space-x-2
          cursor-pointer
          hover:bg-current-50
          pl-2
          pr-8
          py-2
          text-base
        "
        @click="onChangeLanguage('vi')"
      >
        <SynIcon name="flag-vietnam" custom-class="h-6 w-6" />
        <span v-if="!isHiddenLabel">
          {{ $t('SETTING_LANGUAGE_LABEL_VIETNAMESE') || 'Vietnamese' }}
        </span>
      </div>
      <div
        v-if="currentLanguage != 'en'"
        class="
          dropdown-item
          flex
          items-center
          space-x-2
          cursor-pointer
          hover:bg-current-50
          pl-2
          pr-8
          py-2
          text-base
        "
        @click="onChangeLanguage('en')"
      >
        <SynIcon name="flag-united-state" custom-class="h-6 w-6" />
        <span v-if="!isHiddenLabel">
          {{ $t('SETTING_LANGUAGE_LABEL_ENGLISH') || 'English' }}
        </span>
      </div>
    </template>
  </VigDropdown>
</template>
