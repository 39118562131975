import { createApp, h } from 'vue';
import { checkPermissionByScreen } from '@/ui/hooks/permission/permission-by-function';
import { EFunctionCode } from '@/ui/hooks/permission/mock.permission';
import BlockFunction from '@/ui/components/common/BlockFunction.vue';
import allScreens from '@/ui/hooks/permission/all-screens.json';
import { EScreenPermission } from '@/application/types/block-function.types';

export default {
    install: (app) => {
        app.config.globalProperties.$permissionScreen = (functionName) => {
            return checkPermissionByScreen(functionName);
        };
        app.provide('permissionScreen', (functionName) => {
            return checkPermissionByScreen(functionName);
        });
        app.directive('permission-screen', {
            mounted(element, binding) {
                if (!element) return;
                handleCheckPermission(element, binding);
            },
            updated(element, binding) {
                const isListenUpdated = binding.value?.isListenUpdated;
                if (!element || !isListenUpdated) return;
                handleCheckPermission(element, binding);
            },
        });

        app.config.globalProperties.$screens = allScreens;
    },
};

const renderBlockFunction = (isPage) => {
    const appItem = createApp({
        render() {
            return h(BlockFunction, {
                isPage,
            });
        },
    });
    const tmpEl = document.createElement('div');
    const mountedApp = appItem.mount(tmpEl);
    // const el = document.createElement('div');
    // el.innerHTML = mountedApp.$el.outerHTML;
    return mountedApp.$el;
};

const _appendElement = (parenElement, element) => {
    parenElement.classList.add('block-screen_container');
    parenElement.style.position = 'relative';
    parenElement.style.width = '100%';
    parenElement.style.height = '100%';
    parenElement.appendChild(element);
};

const _removeBlockElement = (parenElement) => {
    return new Promise(function (resolve) {
        parenElement.classList?.remove('block-screen_container');
        const blockChildComponents =
            parenElement.querySelectorAll('.block-function');

        if (
            blockChildComponents?.length > 0 &&
            parenElement &&
            parenElement !== null
        ) {
            blockChildComponents.forEach((element) => {
                parenElement?.removeChild(element);
            });
        }
        resolve(true);
    });
};

const handleCheckPermission = async (element, binding) => {
    if (!element || !binding.value) return;

    await _removeBlockElement(element);
    const screenCode = binding.value?.screenCode;
    const isPage = binding.value?.isPage;

    const permission = checkPermissionByScreen(screenCode as EFunctionCode);

    switch (permission) {
        case EScreenPermission.LOW_VISION:
            {
                const newElement = renderBlockFunction(isPage);

                // element.parentNode.replaceChild(newElement, element);
                _appendElement(element, newElement);
            }
            break;
        case null:
        case EScreenPermission.HIDDEN:
            {
                if (isPage) {
                    const newContainer = document.createElement('div');
                    const newElement = renderBlockFunction(isPage);

                    newContainer.setAttribute(
                        'class',
                        element?.classList?.value
                    );
                    newContainer.appendChild(newElement);

                    while (element.firstChild) {
                        element.firstChild.remove();
                    }

                    _appendElement(element, newElement);
                } else {
                    element.parentNode?.removeChild(element);
                }
            }
            break;

        default:
            break;
    }
};
