<script setup lang="ts">
import ModalDownloadFiles from '@/ui/modules/ged/download/ModalDownloadFiles.vue';
import { ref } from 'vue';
import { isFolder, isLink } from '@/ui/helpers/file-utils';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { saveAs } from 'file-saver';

const props = defineProps<{
  files: any[];
  confirmData?: {
    title: string;
    content: string;
  };
}>();

const filesToDownload = ref<any[]>();

const onFilesDownload = async (files) => {
  if (!files?.length) return;

  if (files?.some((file) => isFolder(file?.contentType))) {
    settingGlobalModal({
      type: 'notification',
      title: translate('COMMON_LABEL_ALERT'),
      content: translate('GED_DOWNLOAD_PREVENT_MSG_FOLDER'),
      confirmable: true,
    });
    return ask();
  }

  if (files?.some((file) => isLink(file?.contentType))) {
    settingGlobalModal({
      type: 'notification',
      title: translate('COMMON_LABEL_ALERT'),
      content: translate('GED_DOWNLOAD_PREVENT_MSG_LINK'),
      confirmable: true,
    });
    return ask();
  }

  if (props.confirmData?.title || props.confirmData?.content) {
    settingGlobalModal({
      type: 'notification',
      title: translate(props.confirmData?.title),
      content: translate(props.confirmData?.content),
      confirmable: true,
      confirmLabel: translate('GROUP_COMFIRM_BREAK_LINK_DEPARTMENT_BUTTON'),
      closeable: true,
    });
    const isConfirm = await ask();

    if (!isConfirm) return;
  }

  filesToDownload.value = files;
  console.log('🚀 Tictop ~ filesToDownload.value:', filesToDownload.value);
};

const onFilesDownloaded = async (files) => {
  if (!files?.length) return;

  saveAs(files[0], files[0].name);
};
</script>

<template>
  <div @click.stop="onFilesDownload(files)">
    <slot />

    <ModalDownloadFiles
      v-if="filesToDownload?.length"
      :files="filesToDownload"
      :is-create-zip="filesToDownload?.length > 1"
      @on-close="
        filesToDownload = [];
        onFilesDownloaded($event);
      "
    />
  </div>
</template>

<style scoped></style>
