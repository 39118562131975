export default {
    START_WO_FUNCTION_ALLOW_START_WORKING: '1',
    START_WO_FUNCTION_START_WORKING_SCREENS: {
        HELLO_SCREEN: 'HELLO_SCREEN',
        PLAN_TODAY_SCREEN: 'PLAN_TODAY_SCREEN',
        BONUS_SCREEN: 'BONUS_SCREEN',
    },
    START_WO_FUNCTION_START_WORKING_FUNCTIONS:
        'START_WO_FUNCTION_START_WORKING_FUNCTIONS',
    START_WO_START_WORKING_BY_CONDITIONS:
        'START_WO_START_WORKING_BY_CONDITIONS',

    CHAT_SYSTEM_C_CHAT_SUPPORT: 'CHAT_SYSTEM_C_CHAT_SUPPORT',
    CHAT_SYSTEM_C_WEB_CHAT_ALLOWED: 'CHAT_SYSTEM_C_WEB_CHAT_ALLOWED',
    CHAT_SYSTEM_C_MEMBER_EXTERNAL_LINK_ALLOWED:
        'CHAT_SYSTEM_C_MEMBER_EXTERNAL_LINK_ALLOWED',
    CHAT_SYSTEM_C_EXTERNAL_PATH: 'CHAT_SYSTEM_C_EXTERNAL_PATH',
    CHAT_TICTOP_C_EXTERNAL_CHAT_ALLOWED: 'CHAT_TICTOP_C_EXTERNAL_CHAT_ALLOWED',

    RESOURCE_ALLOW_ADD_GOOGLE_FILES: 'RESOURCE_ALLOW_ADD_GOOGLE_FILES',

    ADVANCED_COMMENT_ALLOW_TO_REPLY_COMMENT:
        'ADVANCED_COMMENT_ALLOW_TO_REPLY_COMMENT',

    ADVANCED_TIME_ADV_DEADLINE_TIME_LOCKING_ALLOWED:
        'ADVANCED_TIME_ADV_DEADLINE_TIME_LOCKING_ALLOWED',
    ADVANCED_TIME_ADV_DEADLINE_TIME_LOCKING_BY:
        'ADVANCED_TIME_ADV_DEADLINE_TIME_LOCKING_BY',
    SHOW_HIDE_THE_TASK_PERFORMANCE_MENU: 'SHOW_HIDE_THE_TASK_PERFORMANCE_MENU',

    ADVANCED_COMPLETI_SYSTEM_COMPLETION_PROOF_ACTIVA:
        'ADVANCED_COMPLETI_SYSTEM_COMPLETION_PROOF_ACTIVA',
    ADVANCED_COMPLETI_COMPLETION_PROOF_ACTIVATED:
        'ADVANCED_COMPLETI_COMPLETION_PROOF_ACTIVATED',
    ADVANCED_COMPLETI_REQUIRED_PROOF_TYPE:
        'ADVANCED_COMPLETI_REQUIRED_PROOF_TYPE',
    ADVANCED_COMPLETI_ALLOWED_TO_SKIP_COMPLETION_PRO:
        'ADVANCED_COMPLETI_ALLOWED_TO_SKIP_COMPLETION_PRO',

    ADVANCED_ASSIGNEE_SYSTEM_ENABLE_AUTOMATIC_CREATI:
        'ADVANCED_ASSIGNEE_SYSTEM_ENABLE_AUTOMATIC_CREATI',
    ADVANCED_ASSIGNEE_ENABLE_AUTOMATIC_CREATION_OF_S:
        'ADVANCED_ASSIGNEE_ENABLE_AUTOMATIC_CREATION_OF_S',
    ADVANCED_ASSIGNEE_ENABLING_PROOF_OF_COMPLETION_F:
        'ADVANCED_ASSIGNEE_ENABLING_PROOF_OF_COMPLETION_F',
    DRAFT_WORKFLOW_WORKFLOW_MULTI_BRANCHES_ALLOWE:
        'DRAFT_WORKFLOW_WORKFLOW_MULTI_BRANCHES_ALLOWE',
    DRAFT_VIDEO_RE_VIDEO_RECORDING_ALLOWED:
        'DRAFT_VIDEO_RE_VIDEO_RECORDING_ALLOWED',
    DRAFT_SYSTEM_HELP_BUTTON_ALLOWED: 'DRAFT_SYSTEM_HELP_BUTTON_ALLOWED',
    ADVANCED_CHAT_GROUP_CHAT_ABOUT_TASK_ALLOWED:
        'ADVANCED_CHAT_GROUP_CHAT_ABOUT_TASK_ALLOWED',
    ADVANCED_SUB_TASK_SUB_TASK_ALLOWED_SYSTEM:
        'ADVANCED_SUB_TASK_SUB_TASK_ALLOWED_SYSTEM',
    ADVANCED_SUB_TASK_SUB_TASK_ALLOWED: 'ADVANCED_SUB_TASK_SUB_TASK_ALLOWED',
    CHAT_TICTOP_C_LIMIT_THE_NUMBER_OF_PINNED_CON:
        'CHAT_TICTOP_C_LIMIT_THE_NUMBER_OF_PINNED_CON',
    ADVANCED_TIME_ADV_DEADLINE_TIME_LOCKING_ALLOWED_SYSTEM:
        'ADVANCED_TIME_ADV_DEADLINE_TIME_LOCKING_ALLOWED_SYSTEM',
    WHITEBOARD_FUNCTION: 'DRAFT_WHITEBOA_WHITEBOARD_FUNCTIONS_ALLOWED',
};

export enum EEvidenceType {
    IMAGE = 'IMAGE',
    DOCUMENT = 'DOCUMENT',
    NOTE = 'NOTE',
    COMMENT = 'COMMENT',
}
