<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted, watch, nextTick } from 'vue';
import dayjs from 'dayjs';
import { useRoute } from 'vue-router';
import { newTaskDefaultPayload } from '@/ui/modules/task/task-global-state';
import TaskDetail from '@/ui/modules/task/detail/TaskDetail.vue';
import taskDetailStore from '@/store/task/detail';
import chatStore from '@/store/chat';
import taskDrawerStore, { MAX_TOTAL_ITEM_IN_DRAWER } from '@/store/task/drawer';
import {
  isOpenCreateTaskLocal,
  isOpenCreateTaskGroup,
} from '@/ui/modules/task/task-global-state';
import TabContainer from '@/ui/common/molecules/SynTab/TabContainer.vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import SortAble from '@/ui/components/draggable/SortAble.vue';
import {
  isEdittingComment,
  isEdittingName,
} from '@/ui/modules/task/detail/task-detail-global-state';
import {
  settingGlobalModal,
  ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import taskMultiTabLogic from '@/ui/modules/task/detail/task-multi-tab-logic';
import { formatTaskCode } from '@/ui/hooks/taskHook';
import TaskCreateNew from '@/ui/modules/task/general/TaskCreateNew.vue';
import taskCreateFormStore from '@/store/task/create-form';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
// import dynamicPositionListTask from '@/ui/composables/app/dynamic-position-list-task';

defineProps<{
  elementRef?: any;
}>();

const emit = defineEmits(['onCreateTaskSuccess', 'isOpenDrawerMini']);

const _taskDetailStore = taskDetailStore();
const _chatStore = chatStore();
const _taskDrawerStore = taskDrawerStore();
const _taskCreateFormStore = taskCreateFormStore();

// const tasksDrawerRef = ref(props?.elementRef || null as any);
const isOpenCreateTaskDrawer = ref(false);
const isOpenTaskDrawer = ref(false);
const route = useRoute();

onMounted(() => {});

onUnmounted(() => {});

const errorCode = ref('');
const isDragingTab = ref(false);
const {
  isAddingTab,
  addTabName,
  addTabRef,
  onSearch,
  onCloseAddNew,
  onOpenAddNewTab,
  editingTabRef,
  editingTab,
  editingTabName,
  onOpenEditName,
  onSaveChange,
  onChangeDrag,
  onSwitchTab,
  onCloseEditName,
} = taskMultiTabLogic(
  errorCode,
  async (addTabName) => {
    const taskDetail = await _taskDetailStore.getDetailByCode(addTabName);
    _taskDrawerStore.pushCurrentIds({ ...taskDetail, tabType: 'DETAIL' });
  },
  async (editingTabName) => {
    const taskDetail = await _taskDetailStore.getDetailByCode(editingTabName);
    _taskDrawerStore.replaceCurrentIds(currentTabId.value, taskDetail);
  },
  (oldIndex, newIndex) => {
    _taskDrawerStore.onSortTabs(oldIndex, newIndex);
  },
  (tabId) => {
    if (currentTabId.value == tabId) return;

    _taskDrawerStore.updateCurrentTabId(tabId);
  }
);

const onSwitchToHiddenTab = (tabId) => {
  onSwitchTab(tabId);

  const oldIndex = allTabIds.value?.findIndex((id) => id == tabId);
  onChangeDrag(oldIndex, 0);
};

const onChangeTabName = () => {
  errorCode.value = '';
};

const confirmBeforeClose = async () => {
  settingGlobalModal({
    type: 'confirm',
    title: '',
    content:
      translate('TASK_CREATE_NEW_CONFIRM_BEFORE_ABORT') ||
      'Do you want to discard edits or continue editing?',
    cancelLabel: translate('COMMON_LABEL_NO') || 'No',
    confirmLabel: translate('COMMON_LABEL_YES_ABORT') || 'Yes abort',
    confirmable: true,
    closeable: true,
  });

  return ask();
};

// const onClickOutSideTaskDrawer = async (event) => {
//   // const taskDetailElm = document.getElementById('task-detail-page');
//   // const taskCreateNewElm = document.getElementById('task-create-new');
//   console.log('event', props.elementRef?.contains(event.target));
//   if (props.elementRef?.contains(event.target) || !isOpenTaskDrawer.value)
//     return;

//   if (isEdittingComment.value || isEdittingName.value) {
//     const confirmed = await confirmBeforeClose();
//     if (!confirmed) return;
//   }
//   isOpenTaskDrawer.value = false;
//   isOpenCreateTaskDrawer.value = false;
//   emit('isOpenDrawerMini', false);
//   onCloseAddNew();
// };

const onOpenDrawer = () => {
  isOpenTaskDrawer.value = !isOpenTaskDrawer.value;
  emit('isOpenDrawerMini', isOpenTaskDrawer.value);
  if (isOpenTaskDrawer.value && route.name !== 'Messages') {
    _chatStore.closeBubbleChat();
    isOpenCreateTaskLocal.value = false;
    isOpenCreateTaskGroup.value = false;
  }
  checkPositionTabTask();
};

const onOpenPanel = () => {
  isOpenTaskDrawer.value = true;
  emit('isOpenDrawerMini', isOpenTaskDrawer.value);
};

const onClosePanel = async () => {
  isOpenTaskDrawer.value = false;
  emit('isOpenDrawerMini', isOpenTaskDrawer.value);

  if (isEdittingComment.value || isEdittingName.value) {
    const confirmed = await confirmBeforeClose();
    if (!confirmed) return;
  }
};

const allTabIds = computed(() => _taskDrawerStore.allTabIds);
const recentlyTabIds = computed(() =>
  _taskDrawerStore.allTabIds?.slice(0, MAX_TOTAL_ITEM_IN_DRAWER)
);

const hiddenTabs = computed<any>(() => {
  if (!_taskDrawerStore.allTabIds || _taskDrawerStore.allTabIds?.length == 0)
    return [];
  return arrayOrderBy(
    _taskDrawerStore.allTabIds
      ?.slice(MAX_TOTAL_ITEM_IN_DRAWER - 1, allTabIds.value?.length || 0)
      ?.map((tabId) => allTabByIds.value[tabId]),
    [(tab) => (tab?.tabType === 'NEW' ? 1 : 2)],
    ['asc']
  );
});

const hiddenDraftTabs = computed<any>(
  () => hiddenTabs.value?.filter((tab) => tab?.tabType === 'NEW') || []
);

const allTabByIds = computed(() => _taskDrawerStore.allTabByIds);
const currentTabId = computed(() => _taskDrawerStore.currentTabId);
const allTabNewDraft = computed(() =>
  allTabByIds.value
    ? Object.values(allTabByIds.value)?.filter((o: any) => o.tabType === 'NEW')
    : []
);
const currentTabInfo = computed(() => {
  if (!currentTabId.value) return {};

  return _taskDrawerStore.allTabByIds[currentTabId.value];
});
const onCloseAllTab = async () => {
  // 1. Confirm before
  let confirmMessage = '';
  if (allTabIds.value?.length > 1)
    confirmMessage =
      translate('TASK_CONFIRM_CLOSE_ALL_TAB') ||
      'Do you want to close all tabs?';

  if (
    allTabIds.value?.length > 0 &&
    allTabIds.value?.filter(
      (tabId) => allTabByIds.value[tabId]?.tabType == 'NEW'
    )?.length > 0
  )
    confirmMessage =
      translate('TASK_CONFIRM_CLOSE_ALL_TAB_NEW') ||
      'You still have creation task in process. Do you want to close all tabs?';

  if (confirmMessage) {
    settingGlobalModal({
      type: 'confirm',
      title: '',
      content: confirmMessage,
      cancelLabel: translate('COMMON_LABEL_NO') || 'No',
      confirmLabel: translate('COMMON_LABEL_YES_ABORT') || 'Yes abort',
      confirmable: true,
      closeable: true,
    });
    const confirmed = await ask();

    if (!confirmed) return;
  }

  _taskDrawerStore.closeAllTab();

  isOpenTaskDrawer.value = false;
  emit('isOpenDrawerMini', isOpenTaskDrawer.value);
  isOpenCreateTaskDrawer.value = false;
};

const onCloseTab = async (tabId) => {
  if (allTabByIds.value[tabId]?.tabType === 'NEW') {
    const confirmed = await confirmBeforeClose();
    if (!confirmed) return;
  }
  _taskDrawerStore.onCloseTab(tabId);
  _taskCreateFormStore.removeCacheByLocalId(tabId);
};

const onOpenCreateNewTask = () => {
  onCloseAddNew();
};

const taskCreateNewRef = ref<any>();

watch(
  () => isOpenCreateTaskDrawer.value,
  () => {
    if (isOpenCreateTaskDrawer.value) onClickNewTab(true, false);
  }
);

const onClickNewTab = (isCreateNew = false, isClearDataDraft = true) => {
  onOpenAddNewTab(isCreateNew);
  _taskDrawerStore.currentTabId = `${Date.now()}`;

  if (isClearDataDraft) {
    newTaskDefaultPayload.value = undefined;
  }
  if (isCreateNew) {
    isOpenTaskDrawer.value = true;
    emit('isOpenDrawerMini', isOpenTaskDrawer.value);
    setTimeout(() => {
      taskCreateNewRef.value?.onFocusInputName();
    }, 300);
  }
};

// let elPanel = document.getElementById(elementId) as HTMLElement;
//
// elPanel?.addEventListener('keydown', (e) => {
//   if (e.ctrlKey && e.keyCode === 74) {
//     // console.log('CTRL + J');
//     e.preventDefault();
//     onClickNewTab(true);
//   }
// });

const onDblClickOnTab = async (tabInfo) => {
  onOpenEditName(tabInfo);
};

const onSaveEditTabName = async () => {
  // console.log(
  //   '🚀 Hyrin ~ onSaveEditTabName ~ editingTabName.value',
  //   editingTabName.value
  // );
  if (!editingTabName.value) return;
  const newTaskCode = editingTabName.value;
  if (currentTabInfo.value?.tabType === 'NEW') {
    const taskCode = newTaskCode?.split('-').pop() || '';

    settingGlobalModal({
      type: 'confirm',
      title: '',
      content:
        translate('TASK_CREATE_NEW_CONFIRM_BEFORE_ABORT_EDIT', {
          taskCode,
        }) || 'Do you want to discard edits or continue editing?',
      cancelLabel: translate('COMMON_LABEL_CANCEL') || 'Cancel',

      confirmLabel:
        translate('TASK_CREATE_NEW_GO_TO_TASK', { taskCode }) || 'Go to task',
      confirmable: true,
      closeable: true,
    });

    const confirmed = await ask();
    if (!confirmed) return;
  }

  onSaveChange(newTaskCode);
};

const checkPositionTabTask = async () => {
  await nextTick();
};
const isCreatingTask = ref(false);

const isShowHiddenTab = ref<boolean>(false);

const elementId = 'TASK_DETAIL_DRAWER_MINI';

defineExpose({
  onOpenDrawer,
  onOpenPanel,
  onClosePanel,
  onClickNewTab,
});
</script>

<template>
  <div
    :id="elementId"
    :ref="elementRef"
    v-element-active="{
      id: elementId,
      isActive: isOpenTaskDrawer,
    }"
    class="
      absolute
      right-0
      flex
      z-20
      transform
      bg-gray-200
      shadow
      transition-all
      duration-300
    "
    :class="[
      isOpenTaskDrawer ? 'ease-out' : 'ease-in',
      isCreatingTask ? 'cursor-wait' : '',
    ]"
    :style="`; max-width: 100%; translateX(0px); ${
      isOpenTaskDrawer ? 'width: 40rem;' : 'width: 0px'
    }; height : calc(100% - 134px); bottom: 74px`"
    @click.stop="() => {}"
  >
    <TabContainer>
      <template #header>
        <ul class="text-sm" style="max-height: 4rem">
          <SortAble
            v-model:is-draging="isDragingTab"
            :disabled="isAddingTab || !!editingTab || isCreatingTask"
            class="flex-1 flex flex-wrap list-tab-container"
            :options="{
              preventOnFilter: false,
              onMove: 1,
            }"
            @on-change-drag="onChangeDrag"
          >
            <template #prefix>
              <div
                v-if="isAddingTab"
                class="relative flex-center hover-to-show__parent float-left"
              >
                <input
                  ref="addTabRef"
                  v-model="addTabName"
                  placeholder="#code"
                  class="
                    w-20
                    outline-none
                    px-2
                    py-1
                    border-b-2
                    focus:border-current
                    rounded-t
                  "
                  @keyup.enter="onSearch"
                  @input="onChangeTabName"
                />
                <div
                  v-if="addTabName"
                  class="
                    hover-to-show__children
                    absolute
                    right-1
                    w-4
                    h-4
                    rounded-full
                    bg-white
                    flex-center
                    hover:text-black hover:bg-gray-300
                  "
                  @click.stop="
                    addTabName = '';
                    addTabRef?.focus();
                  "
                >
                  <SynIcon name="close" custom-class="w-3 h-3 " />
                </div>
              </div>
              <div
                v-else
                class="h-8 w-8 flex-center hover:bg-current-50"
                :class="[isCreatingTask ? 'cursor-wait' : 'cursor-pointer']"
                @click.stop="!isDragingTab && onClickNewTab(false)"
              >
                <SynIcon
                  class="fill-current"
                  custom-class="h-3 w-3"
                  name="plus"
                />
              </div>
            </template>
            <template #list-item>
              <li
                v-for="tabId in recentlyTabIds"
                :key="tabId"
                v-vig-tooltip="
                  allTabByIds[tabId]?.name || allTabByIds[tabId]?.taskName
                "
                :class="[
                  tabId == currentTabId && !isAddingTab
                    ? 'bg-white font-semibold text-current'
                    : 'text-current-900 bg-gray-200 hover:bg-gray-100',
                  isAddingTab ? 'li-created' : 'li-detail',
                ]"
                class="hover-to-show__parent flex-center shadow-sm"
                style="min-width: 5rem"
                @click="onSwitchTab(tabId)"
                @auxclick="onCloseTab(tabId)"
              >
                <span
                  v-if="currentTabId !== tabId"
                  class="h-2/3 border-l border-gray-300"
                ></span>
                <div
                  v-if="editingTab == tabId"
                  class="
                    flex-1
                    h-full
                    relative
                    flex-center
                    hover-to-show__parent
                  "
                >
                  <input
                    ref="editingTabRef"
                    v-model="editingTabName"
                    v-cusFocus="true"
                    placeholder="#code"
                    class="
                      w-20
                      outline-none
                      p-1
                      ring-1
                      focus:ring-current
                      rounded
                    "
                    @keyup.enter="onSaveEditTabName"
                    @blur="onCloseEditName"
                    @input="onChangeTabName"
                  />
                  <div
                    v-if="editingTabName"
                    class="
                      hover-to-show__children
                      absolute
                      right-1
                      w-4
                      h-4
                      rounded-full
                      bg-white
                      flex-center
                      hover:text-black hover:bg-gray-300
                    "
                    @click.stop="
                      editingTabName = '';
                      editingTabRef?.focus();
                    "
                  >
                    <SynIcon name="close" custom-class="w-3 h-3" />
                  </div>
                </div>
                <div
                  v-else
                  class="
                    flex-1 flex
                    items-center
                    justify-between
                    relative
                    py-1.5
                  "
                  style="min-width: 4rem"
                >
                  <span
                    class="px-2"
                    :class="[isCreatingTask ? 'cursor-wait' : 'cursor-pointer']"
                    @dblclick="onDblClickOnTab(allTabByIds[tabId])"
                  >
                    {{
                      allTabByIds[tabId]?.tabCode?.split('-')?.pop() ||
                      allTabByIds[tabId]?.tabCode
                    }}
                  </span>
                  <span
                    class="
                      w-4
                      h-4
                      rounded-full
                      bg-white
                      flex-center
                      hover:text-black hover:bg-gray-300
                    "
                    :class="[
                      tabId == currentTabId && !isAddingTab
                        ? 'mr-1'
                        : 'hover-to-show__children absolute right-1',
                      isCreatingTask ? 'cursor-wait' : 'cursor-pointer',
                    ]"
                    @click.stop="onCloseTab(tabId)"
                  >
                    <SynIcon name="close" custom-class="w-3 h-3" />
                  </span>

                  <span
                    v-if="
                      allTabByIds[tabId]?.tabType === 'NEW' &&
                      tabId !== currentTabId
                    "
                    class="
                      h-2
                      w-2
                      absolute
                      top-1
                      left-1
                      rounded-full
                      bg-orange-500
                    "
                  >
                  </span>
                </div>
              </li>
            </template>
          </SortAble>
          <div class="absolute right-0 bottom-0">
            <VigDropdown
              v-if="hiddenTabs?.length > 0"
              ref="hiddenTabDropdownRef"
              :arrow="false"
              :z-index="60"
              @on-dropdown-open="isShowHiddenTab = true"
              @on-dropdown-close="isShowHiddenTab = false"
            >
              <template #dropdown-toggle>
                <div
                  :title="`${hiddenTabs?.length} Other`"
                  class="
                    cursor-pointer
                    flex-center
                    space-x-2
                    shadow-sm
                    text-gray-700
                    bg-gray-200
                    hover:bg-gray-100
                    relative
                  "
                  style="min-width: 5rem; height: 2rem"
                >
                  <span
                    v-if="hiddenDraftTabs?.length > 0"
                    class="
                      absolute
                      top-1
                      left-1
                      w-1.5
                      h-1.5
                      bg-orange-500
                      ring-2 ring-orange-400 ring-opacity-30
                      rounded-full
                    "
                  ></span>
                  <span>
                    {{ $t('NOTIFICATION_LABEL_TYPE_OTHER') }} ({{
                      hiddenTabs?.length
                    }})
                  </span>
                  <SynIcon
                    :name="'sort-down'"
                    custom-class="h-2.5 w-2.5 fill-gray-500"
                    :class="!isShowHiddenTab ? '-rotate-90' : ''"
                  />
                </div>
              </template>
              <template #dropdown-menu>
                <div
                  class="
                    flex flex-col
                    rounded-md
                    space-y-1
                    overflow-auto
                    small-scrollbar
                  "
                  style="max-height: 620px; width: 20rem"
                >
                  <div
                    v-for="tab in hiddenTabs"
                    :key="tab?.id"
                    :title="tab?.name || tab?.taskName"
                    class="
                      flex flex-col
                      pl-4
                      py-1
                      hover:bg-gray-100
                      rounded
                      relative
                      cursor-pointer
                      hover-to-show__parent
                      dropdown-item
                    "
                    @click="onSwitchToHiddenTab(tab?.id)"
                  >
                    <span
                      class="
                        hover-to-show__children
                        absolute
                        right-1
                        flex-center
                        h-8
                        w-8
                        hover:bg-gray-200
                        rounded-full
                        cursor-pointer
                      "
                      @click.stop="onCloseTab(tab?.id)"
                    >
                      <SynIcon name="close" />
                    </span>
                    <div class="w-full flex items-center justify-between">
                      <span class="text-gray-700 font-semibold">
                        {{ tab?.tabCode?.split('-')?.pop() || tab?.tabCode }}
                      </span>
                    </div>
                    <div
                      class="w-full flex items-center justify-between space-x-2"
                    >
                      <div class="flex-1">
                        <span
                          class="
                            text-sm text-gray-400 text-overflow-hidden-line
                          "
                        >
                          {{ tab?.name || tab?.taskName }}
                        </span>
                      </div>
                      <span class="text-xs text-gray-400 w-max">
                        {{ dayjs(tab?.lastTimeAccess).fromNow() }}
                      </span>
                    </div>
                    <span
                      v-if="tab?.tabType === 'NEW' && tab?.id !== currentTabId"
                      class="
                        h-2
                        w-2
                        absolute
                        top-1
                        left-1
                        rounded-full
                        bg-orange-500
                      "
                    >
                    </span>
                  </div>
                </div>
              </template>
            </VigDropdown>
          </div>
        </ul>
      </template>
      <template #actions>
        <div class="flex-center">
          <div
            :title="$t('COMMON_LABEL_CLOSE') || 'Close'"
            class="
              w-6
              h-6
              rounded
              flex-center
              fill-gray-800
              hover:fill-gray-500 hover:bg-gray-300 hover:bg-opacity-50
            "
            :class="[isCreatingTask ? 'cursor-wait' : 'cursor-pointer']"
            @click.stop="onOpenDrawer"
          >
            <SynIcon name="minus-line" custom-class="w-2.5 h-2.5" />
          </div>
          <div
            :title="$t('COMMON_LABEL_CLOSE') || 'Close'"
            class="
              w-6
              h-6
              rounded
              flex-center
              fill-gray-600
              hover:fill-red-500 hover:bg-red-200 hover:bg-opacity-50
            "
            :class="[isCreatingTask ? 'cursor-wait' : 'cursor-pointer']"
            @click.stop="onCloseAllTab"
          >
            <SynIcon name="close" custom-class="w-4 h-4" />
          </div>
        </div>
      </template>
      <template #body>
        <template
          v-if="
            currentTabId &&
            !isAddingTab &&
            !errorCode &&
            isOpenTaskDrawer &&
            currentTabInfo?.tabType === 'DETAIL'
          "
        >
          <TaskDetail :task-id="currentTabInfo?.id" />
        </template>
        <div
          v-else-if="errorCode"
          class="h-full flex-center py-6 px-4 bg-opacity-40 rounded-xl flex-col"
        >
          <syn-animation
            name="searchNotFound"
            stype="width: 150px; height:150px"
          />
          <div class="flex flex-col space-y-4 pt-8 text-center">
            <span
              class="font-semibold text-2xl text-current-500"
              v-html="
                $t(errorCode, {
                  code: formatTaskCode(addTabName || editingTabName) || '',
                }) ||
                $t('TASK_LABEL_CAN_NOT_FIND_OUT_TASK') ||
                'Something wrong'
              "
            >
            </span>
            <span
              class="text-gray-500"
              v-html="
                $t('TASK_LABEL_NOT_FOUND', {
                  taskName: formatTaskCode(addTabName || editingTabName),
                }) || 'We are sorry.'
              "
            >
            </span>
            <div class="w-full flex-center">
              <SynButton type-text :label="$t('COMMON_LABEL_REFRESH')" />
            </div>
          </div>
        </div>
        <div v-else-if="isOpenTaskDrawer" class="h-full flex-center">
          <TaskCreateNew
            ref="taskCreateNewRef"
            :key="currentTabId"
            :local-id="currentTabId"
            is-create-task-on-start-working
            @on-open-interface="onOpenCreateNewTask"
            @on-cancel="onCloseTab(currentTabId)"
          />
          <!-- <syn-animation name="my-tasks" /> -->
        </div>
      </template>
    </TabContainer>
    <div
      v-vig-tooltip="$t('TASK_LABEL_VIEW_DETAIL_TASKS')"
      class="
        absolute
        -left-8
        w-8
        top-28
        bg-secondary
        text-current-800
        shadow-xl
        flex-center
        rounded-l-lg
      "
      :class="[isCreatingTask ? 'cursor-wait' : 'cursor-pointer']"
      style="height: 7.5rem"
      @click.stop="onOpenDrawer"
    >
      <div
        class="absolute transform -rotate-90 font-semibold flex items-center"
      >
        <span> Tasks </span>
      </div>

      <div
        v-if="allTabNewDraft?.length > 0"
        class="
          absolute
          top-8
          left-1.5
          w-1.5
          h-1.5
          bg-orange-500
          ring ring-2 ring-orange-400 ring-opacity-30
          rounded-full
        "
      ></div>

      <SynIcon
        custom-class="absolute top-4 w-2 h-2 fill-current transform"
        :class="isOpenTaskDrawer ? '' : 'rotate-180'"
        name="play"
      />

      <div
        v-vig-tooltip="{
          content: `
            <div class='px-2 py-2 space-y-0.5'>
               <div class='min-w-max space-x-2 text-center bg-white text-current-500 rounded flex items-center justify-between'>
                <span class='text-md'>${$t('TASK_CREATE_NEW_TITLE')}</span>
                <span class='bounceX h-6 w-6 flex-center rounded-full bg-current-50'>→</span>
              </div>
            </div>
`,
          arrow: true,
          placement: 'left',
          delay: 0,
          theme: 'light',
          animation: 'scale',
          interactive: true,
          showOnCreate: true,
          zIndex: 40,
          hideAfter: true,
        }"
        class="
          absolute
          bottom-1
          shadow
          flex-center
          w-6
          h-6
          rounded
          bg-orange-500
          hover:bg-orange-600
        "
        @click.stop="onClickNewTab(true)"
      >
        <SynIcon custom-class="w-2 h-2 fill-white text-white" name="plus" />
      </div>
    </div>
  </div>
</template>

<style>
.bounceX {
  animation: bounce_run 1s infinite;
}

@keyframes bounce_run {
  0%,
  100% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateX(25%);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.list-tab-container .li-detail:nth-child(8) {
  margin-right: 2.9rem;
}

.list-tab-container .li-created:nth-child(7) {
  margin-right: 2.9rem;
}
</style>
