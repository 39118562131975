<script setup lang="ts">
import { computed, onMounted, ref, inject, toRaw } from 'vue';
import localConfigsStore from '@/store/local-configs';
import { ITaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import AttachWorkflowDropdown from '@/ui/modules/task/workflow/AttachWorkflowDropdown.vue';
// import TaskWorkflowEntity from '@/domain/entities/task/TaskWorkflowEntity';
import { TaskLife } from '@/domain/enums/taskEnum';
import { TASK_ACTION_LIST_DEFAULT } from '@/application/constants/task.const';
import TaskActionItemApproval from '@/ui/modules/task/detail/action-on-task/TaskActionItemApproval.vue';
import {
  settingGlobalModal,
  ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import {
  EActionOnTask,
  TaskApprovalStatus,
} from '@/application/types/task/task.types';
import SearchTasksModal from '@/ui/modules/task/components/search/SearchTasksModal.vue';
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';
import WhiteboardListModal from '@/ui/modules/whiteboard/list/WhiteboardListModal.vue';
import whiteboardStore from '@/store/whiteboard';
import { isOpenTaskDrawer } from '@/ui/modules/task/task-global-state';

const props = withDefaults(
  defineProps<{
    currentTask: ITaskDetailClass;
    chatEnabled?: boolean;
    viewMode?: 'QUICK_ACTION' | 'DEFAULT';
    actionList?: string[];
    isHiddenRemove?: boolean;
    isHiddenTool?: boolean;
    readonly?: boolean;
    taskCode?: string;
    currentPinCode?: string;
  }>(),
  {
    viewMode: 'DEFAULT',
    actionList: () => [
      EActionOnTask.DISCUSS,
      EActionOnTask.WORKFLOW,
      EActionOnTask.CLONE,
      EActionOnTask.RELOAD,
      EActionOnTask.SET_REPEAT,
      EActionOnTask.SHARE,
      EActionOnTask.SEND_TO_WHITEBOARD,
      EActionOnTask.SNAPSHOT,
      EActionOnTask.PIN,
      EActionOnTask.COPY_LINK,
      EActionOnTask.APPROVAL,
      EActionOnTask.REMINDER_TASK,
      EActionOnTask.SUB_TASK,
    ],
  }
);

const emit = defineEmits<{
  (e: 'delete'): void;
  (e: 'attachWorkflow', data: any): void;
  (e: 'update:loading', value: boolean): void;
  (e: 'openApprovalModal'): void;
  (e: 'unMaskAsApproval'): void;
  (e: 'cancelApprovalRequest'): void;
  (e: 'selectAction', key: EActionOnTask): void;
  (e: 'removeAsSubTask'): void;
  (e: 'setParentTask', task: any): void;
}>();

const _localConfigsStore = localConfigsStore();

const isOpenMoreAction = ref(false);
const isShowQuickActionControl = ref(false);
const localConfigs = computed<any>(() => _localConfigsStore.localConfigs);
const currentShowingTools = computed<string[]>(
  () => localConfigs.value['task']?.showingTools || []
);
const latestChangeShowingToolsAt = computed<number>(
  () => localConfigs.value['task']?.latestChangeShowingToolsAt || 0
);

const checkTaskScreen = () => {
  if (!props.taskCode) return;
  const relativePath = `/tasks/${props?.taskCode}`;
  const host = window.location.href;
  return host.includes(relativePath);
};

const HIDDEN_ACTIONS_FOR_ASSIGN_LIST_USER_TASK = [
  EActionOnTask.WORKFLOW,
  EActionOnTask.CLONE,
  EActionOnTask.SET_REPEAT,
];
const actionOnTaskList = computed<any[]>(() => {
  let _actionList = [...TASK_ACTION_LIST_DEFAULT];
  if (!props.chatEnabled)
    _actionList = _actionList?.filter((o) => o?.key !== EActionOnTask.DISCUSS);

  if (props.currentTask?.isCreateListAssignees)
    _actionList = _actionList?.filter(
      (o) => !HIDDEN_ACTIONS_FOR_ASSIGN_LIST_USER_TASK.includes(o?.key)
    );

  _actionList = _actionList?.filter((func) => {
    if (!props.actionList?.includes(func?.key)) return false;

    if (
      !func.permissionList ||
      func.permissionList?.length == 0 ||
      !checkSystemConfig
    )
      return true;

    return checkSystemConfig(func.permissionList);
  });

  return _actionList;
});

const checkSystemConfig: Function | undefined = inject('checkSystemConfig');
onMounted(() => {
  // set default
  if (!latestChangeShowingToolsAt.value) {
    toggleShowTools('DISCUSS', false);
    toggleShowTools('SHARE', false);
    toggleShowTools('PIN', false);
  }
});

const quickActionOnTaskList = computed<any[]>(() => {
  let _actionList = actionOnTaskList.value?.filter(
    (action) =>
      (currentShowingTools.value?.some((key) => key == action?.key) ||
        (action?.key == 'SHARE' && props.currentTask?.sharedId) ||
        (action?.key == 'SNAPSHOT' &&
          props.currentTask?.taskSnapshotTotal > 0) ||
        (action?.key == 'PIN' && props.currentTask?.pinnedColor)) &&
      (!action.permissionList ||
        action.permissionList?.length == 0 ||
        !checkSystemConfig ||
        checkSystemConfig(action.permissionList))
  );
  if (props.actionList && props.actionList?.length > 0)
    _actionList = _actionList?.filter(
      (action) =>
        props.actionList?.some((k) => k == action?.key) &&
        (!action?.needCheckPermissionOnTask ||
          (action?.needCheckPermissionOnTask && !props.readonly))
    );

  if (props.actionList?.some((k) => k == EActionOnTask.EXPAND))
    _actionList.push({
      index: 99,
      quickActionIndex: 99,
      key: EActionOnTask.EXPAND,
      functionCode: null,
      iconName: 'expand3',
      labelKey: 'TASK_LABEL_VIEW_TASK_IN_MODAL_MODE',
      permissionList: [],
      needCheckPermissionOnTask: false,
      hasQuickAction: false,
      isIgnoreReadonly: true,
    });

  return arrayOrderBy(_actionList, ['quickActionIndex'], ['asc']) || [];
});
const defaultActionOnTaskList = computed<any[]>(() => {
  let _actionList = actionOnTaskList.value?.filter((action) => {
    if (action?.key == EActionOnTask.CLONE) return !checkTaskScreen();
    if (action?.key == EActionOnTask.FINISH)
      return (
        !props.readonly &&
        props.currentTask?.taskLife !== TaskLife.Finished &&
        !(
          props.currentTask?.approvalEnabled &&
          props.currentTask?.approvalStatus &&
          props.currentTask?.approvalStatus == TaskApprovalStatus.WAITING
        )
      );
    if (action?.key == EActionOnTask.PROVIDE_EVIDENCE_WHEN_FINISH)
      return !props.currentTask?.workFlowTaskId;
    if (action?.key == EActionOnTask.WORKFLOW)
      return !props.currentTask?.finishProofEnabled;

    return true;
  });
  if (props.actionList && props.actionList?.length > 0)
    _actionList = _actionList?.filter((action) =>
      props.actionList?.some(
        (k) =>
          k == action?.key &&
          (!action?.needCheckPermissionOnTask ||
            (action?.needCheckPermissionOnTask && !props.readonly)) &&
          (!props.readonly || (props.readonly && action?.isIgnoreReadonly))
      )
    );

  return arrayOrderBy(_actionList, ['index'], ['asc']) || [];
});
const taskActionToolList = computed<any[]>(() => {
  return actionOnTaskList.value?.filter((o) => o?.hasQuickAction);
});

const toggleShowTools = async (key, value) => {
  if (
    !value &&
    currentShowingTools.value?.length >= 3 &&
    key !== EActionOnTask.WORKFLOW
  ) {
    settingGlobalModal({
      type: 'notification',
      title: translate('TASK_LABEL_MAX_QUICK_ACTION_TITLE') || '',
      content: translate('TASK_LABEL_MAX_QUICK_ACTION_CONTENT') || '',
      confirmLabel: translate('COMMON_LABEL_GOT_IT'),
      closeable: false,
      confirmable: true,
    });
    await ask();
    return;
  }
  const newShowingTools = value
    ? currentShowingTools.value?.filter((v) => v !== key)
    : [...currentShowingTools.value, key];

  _localConfigsStore.setLocalConfigs('task', {
    showingTools: newShowingTools,
    latestChangeShowingToolsAt: Date.now(),
  });
};

const loadingData = ref<any>({
  workflow: false,
});
const attachWorkflow = async (workflow) => {
  loadingData.value.workflow = true;
  emit('update:loading', true);
  // const result = await new TaskWorkflowEntity({
  //   ...workflow,
  // }).attachToTask(props.currentTask?.id);
  emit('attachWorkflow', workflow);
  emit('update:loading', false);
  loadingData.value.workflow = false;
};

const ableAttachWorkflowToCurrentTask = computed<boolean>(() => {
  return (
    !props.currentTask?.workFlowTaskId &&
    [TaskLife.Created, TaskLife.Todo, TaskLife.InProcess].includes(
      props.currentTask?.taskLife
    ) &&
    !props.currentTask?.finishProofEnabled
  );
});

const isOpenSearchTaskModal = ref<boolean>(false);
const openSearchTaskModal = () => {
  isOpenSearchTaskModal.value = true;
};
const onChooseParentTask = (_task) => {
  emit('setParentTask', _task);

  isOpenSearchTaskModal.value = false;
};

const actionOnTaskBodyRef = ref<any>(null);
const isShowWhiteboardListModal = ref<boolean>(false);

const toggleShowToolDropdown = () => {
  isShowQuickActionControl.value = !isShowQuickActionControl.value;
  setTimeout(() => {
    if (actionOnTaskBodyRef.value)
      actionOnTaskBodyRef.value.scrollTop = isShowQuickActionControl.value
        ? actionOnTaskBodyRef.value.scrollHeight
        : 0;
  }, 100);
};

const onSelectAction = (actionKey) => {
  switch (actionKey) {
    case EActionOnTask.SEND_TO_WHITEBOARD:
      isShowWhiteboardListModal.value = true;
      break;

    default:
      emit('selectAction', actionKey);
      break;
  }
};

const onSelectWhiteboard = (whiteboard) => {
  console.log('🚀 Tictop ~ whiteboard:', whiteboard);
  isShowWhiteboardListModal.value = false;
  whiteboardStore().addAttachedTask({
    whiteboardId: whiteboard?.id,
    task: toRaw(props.currentTask),
  });

  isOpenTaskDrawer.value = false;
};
</script>
<template>
  <template v-if="viewMode == 'QUICK_ACTION'">
    <div class="flex-center space-x-1 hover-to-show__parent">
      <div
        v-for="(action, index) in quickActionOnTaskList"
        :key="index"
        v-vig-tooltip="$t(action?.labelKey)"
      >
        <template v-if="action?.key == 'WORKFLOW'">
          <AttachWorkflowDropdown
            v-if="ableAttachWorkflowToCurrentTask"
            :form-data="currentTask"
            :is-loading="loadingData?.workflow"
            @on-attach="attachWorkflow"
          />
        </template>
        <template v-else-if="action?.key == 'DISCUSS'">
          <slot name="discuss"></slot>
        </template>
        <template v-else-if="action?.key == 'PIN'">
          <slot name="pin"></slot>
        </template>
        <div
          v-else
          v-permission-function="{
            functionCode: action?.functionCode,
            needToShowPremiumIcon: false,
            onContinue: () => {
              $emit('selectAction', action?.key);
            },
          }"
          class="
            flex-center
            rounded
            w-8
            h-8
            p-2
            hover:bg-gray-100
            cursor-pointer
            relative
          "
          @click="$emit('selectAction', action?.key)"
        >
          <SynIcon class="fill-gray-500" :name="action?.iconName" />
          <span
            v-if="action?.key == 'SHARE' && currentTask?.sharedId"
            class="
              absolute
              top-0.5
              right-0.5
              w-2.5
              h-2.5
              rounded-full
              bg-green-500
            "
          >
          </span>
          <span
            v-if="
              action?.key == 'SNAPSHOT' &&
              props.currentTask?.taskSnapshotTotal > 0
            "
            class="absolute top-0 right-0 w-2.5 h-2.5 rounded-full bg-green-500"
          >
          </span>
        </div>
      </div>
    </div>
  </template>
  <VigDropdown
    v-else-if="defaultActionOnTaskList?.length > 0"
    :arrow="false"
    hide-on-click="toggle"
    placement="bottom-end"
    @on-dropdown-open="isOpenMoreAction = true"
    @on-dropdown-close="isOpenMoreAction = false"
  >
    <template #dropdown-toggle>
      <div
        class="
          w-8
          h-8
          rounded-md
          cursor-pointer
          p-2
          hover:bg-gray-100
          flex-center
        "
        :class="isOpenMoreAction ? 'bg-gray-100' : ''"
      >
        <SynIcon name="dots-vertical" custom-class="w-4 h-4" />
      </div>
    </template>
    <template #dropdown-menu>
      <div
        ref="actionOnTaskBodyRef"
        style="max-height: min(72vh, 600px)"
        class="overflow-auto small-scrollbar"
      >
        <div
          class="py-1 z-100 list-ul rounded cursor-pointer dropdown-item"
          style="min-width: 13rem"
        >
          <template
            v-for="action in defaultActionOnTaskList"
            :key="action?.key"
          >
            <template v-if="action?.key == EActionOnTask.APPROVAL">
              <TaskActionItemApproval
                :current-task="currentTask"
                :action="action"
                @open-approval-modal="$emit('openApprovalModal')"
                @un-mask-as-approval="$emit('unMaskAsApproval')"
                @cancel-approval-request="$emit('cancelApprovalRequest')"
              />
            </template>
            <div
              v-else
              v-permission-function="{
                functionCode: action?.functionCode,
                needToShowPremiumIcon: true,
                onContinue: () => {
                  $emit('selectAction', action?.key);
                },
              }"
              class="
                list-li
                flex
                text-gray-600
                justify-start
                items-center
                space-x-2
                text-sm
                relative
              "
              @click="onSelectAction(action?.key)"
            >
              <SynIcon class="fill-gray-500" :name="action?.iconName" />
              <span v-if="action?.key == EActionOnTask.PIN">
                {{
                  $t(
                    currentTask?.pinnedColor &&
                      currentTask?.pinnedColor !== 'UN_PIN'
                      ? 'CHAT_UNPIN'
                      : 'CHAT_PIN'
                  )
                }}
              </span>
              <span v-else-if="action?.key == EActionOnTask.FINISH">
                {{
                  $t(
                    currentTask?.approvalEnabled
                      ? 'COMMON_LABEL_APPROVAL_REQUEST'
                      : action?.labelKey
                  )
                }}
              </span>
              <span
                v-else-if="action?.key == EActionOnTask.LOCK_UPDATE_DEADLINE"
              >
                {{
                  $t(
                    currentTask?.scheduleTimeLocked
                      ? 'TASK_LABEL_UNLOCK_UPDATE_DEADLINE'
                      : 'TASK_LABEL_LOCK_UPDATE_DEADLINE'
                  )
                }}
              </span>
              <span v-else>
                {{ $t(action?.labelKey) }}
              </span>

              <span
                v-if="action?.key == 'SHARE' && currentTask?.sharedId"
                class="absolute right-1 w-2.5 h-2.5 rounded-full bg-green-500"
              >
              </span>
              <span
                v-if="
                  action?.key == 'SNAPSHOT' &&
                  props.currentTask?.taskSnapshotTotal > 0
                "
                class="absolute right-1 w-2.5 h-2.5 rounded-full bg-green-500"
              >
              </span>
            </div>
          </template>

          <template
            v-if="
              !readonly &&
              !currentTask?.totalSubTask &&
              actionList?.includes(EActionOnTask.SUB_TASK)
            "
          >
            <div
              v-if="currentTask?.parentId"
              v-permission-function="{
                functionCode: ALL_FUNCTIONS.TASK.SUB_TASK,
                needToShowPremiumIcon: true,
                onContinue: () => {
                  $emit('removeAsSubTask');
                },
              }"
              v-system-config="[
                {
                  functionCode: 'ADVANCED_SUB_TASK_SUB_TASK_ALLOWED',
                  functionValue: '1',
                },
                {
                  functionCode: 'ADVANCED_SUB_TASK_SUB_TASK_ALLOWED_SYSTEM',
                  functionValue: '1',
                },
              ]"
              class="
                list-li
                flex
                border-t
                shadow-sm
                justify-start
                items-center
                space-x-2
                text-sm
              "
              :class="isHiddenTool ? '' : 'border-b'"
              @click="$emit('removeAsSubTask')"
            >
              <SynIcon class="fill-gray-500" :name="'Convert'" />

              <span class="text-gray-500">
                {{ $t('COMMON_LABEL_SUB_TASK_REMOVE_AS_SUB_TASK') }}
              </span>
            </div>
            <div
              v-else
              v-permission-function="{
                functionCode: ALL_FUNCTIONS.TASK.SUB_TASK,
                needToShowPremiumIcon: true,
                onContinue: () => {
                  openSearchTaskModal();
                },
              }"
              v-system-config="[
                {
                  functionCode: 'ADVANCED_SUB_TASK_SUB_TASK_ALLOWED',
                  functionValue: '1',
                },
                {
                  functionCode: 'ADVANCED_SUB_TASK_SUB_TASK_ALLOWED_SYSTEM',
                  functionValue: '1',
                },
              ]"
              class="
                list-li
                flex
                border-t
                shadow-sm
                justify-start
                items-center
                space-x-2
                text-sm
              "
              :class="isHiddenTool ? '' : 'border-b'"
              @click="openSearchTaskModal"
            >
              <SynIcon class="fill-gray-500" :name="'Convert'" />

              <span class="text-gray-500">
                {{ $t('COMMON_LABEL_SUB_TASK_CONVERT_TO_SUB_TASK') }}
              </span>
            </div>
          </template>
          <div
            v-if="!readonly && !isHiddenRemove"
            class="
              list-li
              flex
              shadow-sm
              justify-start
              items-center
              space-x-2
              text-sm
            "
            :class="isHiddenTool ? '' : 'border-b'"
            @click="$emit('delete')"
          >
            <SynIcon class="fill-red-500" is-active name="trash" />
            <span class="text-red-500">
              {{ $t('COMMON_LABEL_DELETE') }}
            </span>
          </div>

          <!-- TOOLS -->
          <div
            v-if="!isHiddenTool"
            class="list-li flex justify-between items-center space-x-2 text-sm"
            :class="{
              'bg-gray-50 shadow-sm': isShowQuickActionControl,
            }"
            @click.stop="toggleShowToolDropdown"
          >
            <div class="flex-center space-x-2">
              <SynIcon class="fill-gray-500" is-active name="settings" />
              <span class="text-gray-600">
                {{ $t('TASK_DETAIL_ACTION_TOOLS_SHOW_OR_HIDE') }}
              </span>
            </div>

            <SynIcon
              :name="'sort-down'"
              custom-class="h-2 w-2 fill-gray-500"
              :class="!isShowQuickActionControl ? '-rotate-90' : ''"
              @click.stop="toggleShowToolDropdown"
            />
          </div>
        </div>

        <!-- TOOLS -->
        <div
          v-if="isShowQuickActionControl"
          class="
            py-1
            z-100
            list-ul
            rounded
            cursor-pointer
            dropdown-item
            bg-gray-50
          "
          style="min-width: 11rem"
        >
          <!-- CHAT ABOUT -->
          <div v-for="(action, index) in taskActionToolList" :key="index">
            <div
              v-permission-model
              class="
                list-li
                flex
                text-gray-600
                justify-between
                items-center
                space-x-2
                text-sm
              "
            >
              <div class="flex-center space-x-2">
                <SynIcon class="fill-gray-500" :name="action?.iconName" />
                <span>
                  {{ $t(action?.labelKey) }}
                </span>
              </div>
              <AtomSwitch
                :model-value="currentShowingTools?.includes(action?.key)"
                size="small"
                @click="
                  toggleShowTools(
                    action?.key,
                    currentShowingTools?.includes(action?.key)
                  )
                "
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </VigDropdown>
  <SearchTasksModal
    v-if="isOpenSearchTaskModal"
    :title="$t('SUB_TASK_SELECT_A_TASK_TO_MAKE_THIS_TASK_A_SUBTASK')"
    :filter-function="
      (t) => {
        return !t?.parentId && t?.id !== currentTask?.id;
      }
    "
    @on-choose="onChooseParentTask"
    @on-close="isOpenSearchTaskModal = false"
  />
  <WhiteboardListModal
    v-if="isShowWhiteboardListModal"
    @on-close="isShowWhiteboardListModal = false"
    @on-select="onSelectWhiteboard"
  />
</template>
