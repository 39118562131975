<script setup lang="ts">
import { ref, watch } from 'vue';
import { cloneDeep } from '@/ui/hooks/commonFunction';
import ProjectTag from '@/ui/modules/domains/component/ProjectTag.vue';

const props = withDefaults(
  defineProps<{
    domains?: any[];
    isShowSubProject?: boolean;
    isShowProject?: boolean;
    isEdit?: boolean;
    isDisabled?: boolean;
  }>(),
  {
    domains: () => [],
    isShowSubProject: true,
    isShowProject: true,
    isEdit: false,
    isDisabled: false,
  }
);

const emit = defineEmits([
  'clickDomain',
  'clickAllProject',
  'clickProject',
  'clickSubProject',
]);

const allDomain = ref({});

const initData = () => {
  allDomain.value = cloneDeep(props.domains);
};
initData();

watch(
  () => props.domains,
  () => {
    initData();
  }
);

const onSelectDomain = (index) => {
  allDomain.value[index].checked = !allDomain.value[index].checked;
  allDomain.value[index].anyProjects = !allDomain.value[index].anyProjects;
  emit('clickDomain', allDomain.value);
};

const onSelectAllProject = (index) => {
  allDomain.value[index].anyProjects = !allDomain.value[index].anyProjects;
  allDomain.value[index].projectScopeList.map((el) => {
    el.checked = false;
    el?.subProject ? el.subProject.map((sub) => (sub.checked = false)) : '';
  });
  if (allDomain.value[index]?.system) {
    allDomain.value[index].system = false;
  }
  emit('clickAllProject', allDomain.value);
};
const onSelectProject = (projectIndex, domainIndex) => {
  allDomain.value[domainIndex].anyProjects = false;
  allDomain.value[domainIndex].projectScopeList[projectIndex].checked =
    !allDomain.value[domainIndex].projectScopeList[projectIndex].checked;
  emit('clickProject', allDomain.value);
};

const onSelectSubProject = (subIndex, projectIndex, domainIndex) => {
  allDomain.value[domainIndex].projectScopeList[projectIndex].subProject[
    subIndex
  ].checked =
    !allDomain.value[domainIndex].projectScopeList[projectIndex].subProject[
      subIndex
    ].checked;
  emit('clickSubProject', allDomain.value);
};
</script>

<template>
  <!-- <div class="py-1 px-4 h-full overflow-y-auto small-scrollbar"> -->
  <!-- <div class="pt-2 w-full flex" v-if="isLoadingScope">
      <div class="px-4 py-2 h-full w-8 mr-4 bg-gray-200 rounded-md"></div>
      <div class="px-4 py-2 h-full w-full bg-gray-200 rounded-md"></div>
    </div> -->
  <!-- add domain -->
  <div class="p-2">
    <div v-for="(domain, index) in allDomain" :key="domain">
      <!-- domain -->
      <div
        v-if="isEdit || domain.checked"
        class="flex items-center space-x-2 py-1 cursor-pointer"
        @click="!isDisabled ? onSelectDomain(index) : ''"
      >
        <SynIcon
          name="checkbox"
          class="fill-current"
          :custom-class="isEdit ? 'w-4 h-4' : 'w-4 h-4 mr-2'"
          :has-action="isEdit"
          :is-active="domain.checked"
        />
        <span class="font-normal text-current-800">
          <!-- {{ domain.domainId }} -->
          {{ domain.domainName }}
        </span>
      </div>

      <!-- child domain -->
      <div v-if="domain.checked && isShowProject">
        <div
          v-if="isEdit || domain.anyProjects"
          class="flex items-center ml-5 cursor-pointer"
          @click="!isDisabled ? onSelectAllProject(index) : ''"
        >
          <div class="w-8 h-8 flex-center">
            <SynIcon
              name="checkbox"
              class="fill-blue-500"
              color="blue"
              :has-action="isEdit"
              :custom-class="isEdit ? 'w-4 h-4' : 'w-4 h-4'"
              :is-active="domain.anyProjects || domain.system"
            />
          </div>
          <span
            :title="$t('USERMANAGEMENT_LABEL_SELECT_ALL_PROJECT')"
            class="
              font-normal
              text-current-800 text-sm
              flex-1
              text-overflow-hidden-line
            "
          >
            {{ $t('USERMANAGEMENT_LABEL_SELECT_ALL_PROJECT') }}
          </span>
        </div>

        <div v-if="!domain.anyProjects">
          <template
            v-for="(project, indexProject) in domain.projectScopeList"
            :key="project.key"
          >
            <div v-if="isEdit || project.checked" class="ml-6 py-1">
              <div
                v-if="isEdit || project.checked"
                class="flex items-center space-x-2 cursor-pointer"
                @click="!isDisabled ? onSelectProject(indexProject, index) : ''"
              >
                <SynIcon
                  name="checkbox"
                  class="fill-blue-500"
                  color="blue"
                  :has-action="isEdit"
                  :custom-class="isEdit ? 'w-4 h-4' : 'w-4 h-4 mr-2'"
                  :is-active="project?.checked"
                />
                <span class="font-normal text-current-800">
                  <!-- {{ project.projectId }} -->
                  {{ project.projectName }}
                </span>
              </div>

              <!-- sub project -->
              <div v-if="project.checked && isShowSubProject">
                <div
                  v-for="(subproject, indexSub) in project.subProject"
                  :key="subproject?.key"
                >
                  <div
                    v-if="isEdit || subproject?.checked"
                    class="flex items-center ml-6 py-1 space-x-2 cursor-pointer"
                    @click="
                      !isDisabled
                        ? onSelectSubProject(indexSub, indexProject, index)
                        : ''
                    "
                  >
                    <SynIcon
                      name="checkbox"
                      style="fill: #fbbf24"
                      color="yellow"
                      :has-action="isEdit"
                      :custom-class="isEdit ? 'w-4 h-4' : 'w-4 h-4 mr-2'"
                      :is-active="subproject?.checked"
                    />
                    <ProjectTag type="subproject" />
                    <span class="font-normal text-current-800">
                      <!-- {{ subproject.projectId }} -->
                      {{ subproject?.projectName }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>
