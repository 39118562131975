<script setup lang="ts">
import { computed, ref, watch, onMounted, onUnmounted } from 'vue';
import { saveAs } from 'file-saver';
import axios from 'axios';
import JSZip from 'jszip';
import commentTaskComposable from '@/ui/composables/task/comment-task';
import taskCommentStore from '@/store/task/task-comment';
// import { getCurrentUserId } from '@/ui/hooks/storageHook';
import TaskCommentInput from '@/ui/common/plugins/ckeditor/TaskCommentInput.vue';
import MessageItem from '@/ui/modules/task/components/activity-comment/MessageItem.vue';
// import UserById from '@/ui/components/user/UserById.vue';
// import ModalFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalFileViewer.vue';
// import ModalMultipleFileViewerInfo from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewerInfo.vue';
// import taskDetailStore from '@/store/task/detail';
import { AlertType, openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import ModalMultipleFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewer.vue';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import TaskCommentBottom from '@/ui/modules/task/detail/TaskCommentBottom.vue';
import { chain } from 'lodash';
import TaskCommentReplyExpand from '@/ui/modules/task/detail/_commons/task-comments/TaskCommentReplyExpand.vue';
import permissionStore from '@/store/permission';
import systemConfig from '@/application/constants/system-config.const';
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';
import taskDetailStore from '@/store/task/detail';

const props = withDefaults(
  defineProps<{
    taskId: string | number;
    organizationId?: number;
    currentTask?: any;
    parentElementRef?: any;
    hasPermission?: boolean;
    readonly?: boolean;
    users?: any[];
  }>(),
  {
    hasPermission: true,
    users: () => [],
  }
);

const emits = defineEmits<{
  (e: 'onSaveSuccess', isNeedUpdateAttachments: boolean): void;
  (e: 'onSaveUpdateNote', noteData: any): void;
  (e: 'onFileInfoClick');
  (e: 'onReady');
  (e: 'onScrollTo', position: number);
  (e: 'deleteSuccess');
}>();

const _taskCommentStore = taskCommentStore();
const _taskDetailStore = taskDetailStore();

const highlightCommentIds = computed<string[]>(() => {
  return _taskDetailStore.highlightCommentIds;
});
// const currentId = getCurrentUserId();
const editCommentTaskPinnedInputRef = ref(null);

const listCommentByTaskId = computed(
  () => _taskCommentStore.listCommentByTaskId
);
const currentAttachmentList = computed(() => _taskCommentStore.listAttachment);

const fileShowViewer = ref();
const isPermissionDeleteAttachment = ref(false);

const primaryComments = computed(
  () => listCommentByTaskId.value?.filter((el) => !el?.replyTo) || []
);
const listCommentPinned = computed(
  () => listCommentByTaskId.value?.filter((el) => el?.pinned) || []
);
// const isShowCommentPinned = ref(false);

const repliesByComment = computed<{ [commentId: string]: any[] }>(() =>
  chain(listCommentByTaskId.value)
    .filter((cmt) => cmt?.replyTo)
    .groupBy('replyTo')
    .value()
);

const isAllowReply = computed(() => {
  const systemConfigs = permissionStore().allSystemConfigs || {};
  return (
    systemConfigs[systemConfig.ADVANCED_COMMENT_ALLOW_TO_REPLY_COMMENT] === '1'
  );
});

const indexCurrentFile = ref(0 as any);

const {
  commentTaskInputRef,
  // commentObjectCreate,
  currentCommentEdit,
  expandedRepliesByComment,
  isLoading,
  // resetData,
  getAllComments,
  // onCreateComment,
  onAddReactionComment,
  onSaveUpdateComment,
  onEditComment,
  onCloseEditComment,
  onDeleteComment,
  onPinComment,
  onRemoveReaction,
  onReplyCommentClick,
  setInputCommentReplyRefs,
} = commentTaskComposable(emits);

const handleCheckAndExpandParentComment = () => {
  Object.entries(repliesByComment.value).forEach(([parentId, childCmtList]) => {
    if (
      childCmtList?.some((childCmt) =>
        highlightCommentIds.value?.some((_id) => _id == childCmt?.id)
      )
    ) {
      expandedRepliesByComment.value[parentId] = true;
    }
  });
};

let clearHighlightTimeout;
onMounted(() => {
  handleCheckAndExpandParentComment();

  clearHighlightTimeout = setTimeout(() => {
    _taskDetailStore.updateHighlightCommentIds([]);
  }, 5 * 1000);
});
onUnmounted(() => {
  if (clearHighlightTimeout) clearTimeout(clearHighlightTimeout);
});

const initData = async (currentTaskId) => {
  if (!currentTaskId) return;
  await getAllComments(props.organizationId, currentTaskId);
  emits('onReady');
};

initData(props.taskId);

watch(
  () => props.taskId,
  (currentId) => {
    initData(currentId);
  }
);

const currentCommentId = ref<any>(null);
const onFileClick = async (file, cmtId, cmt) => {
  isPermissionDeleteAttachment.value = cmt?.createdBy == getCurrentUserId();

  currentCommentId.value = cmtId;
  await _taskCommentStore.getListAttachmentById(cmtId);

  fileShowViewer.value = file;
  indexCurrentFile.value = currentAttachmentList.value.findIndex(
    (f) => f.id === file?.id
  );
  if (indexCurrentFile.value === -1) indexCurrentFile.value = null;
};

const onFileClickByIndex = (index) => {
  fileShowViewer.value = currentAttachmentList?.value[index];
};

const onMessageDownload = (files) => {
  if (files.length < 1) return;

  if (files.length === 1) {
    return axios
      .get(files[0].pathUrl || files[0].url_full, { responseType: 'blob' })
      .then((res) => {
        saveAs(res.data, files[0].name);
      });
  } else {
    const zip = new JSZip();
    files.forEach((file) => {
      zip.file(
        file.name,
        axios(file.pathUrl || file.url_full, { responseType: 'blob' }).then(
          (res) => res?.data
        )
      );
    });
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `files-${new Date().getTime()}.zip`);
    });
  }
};

const listUserRelated = computed(() => [
  props?.currentTask?.creatorId,
  props?.currentTask?.assigneeId,
]);

const onClickTextComment = (event) => {
  const target = event?.target;

  if (
    target.getAttribute('data-type') == 'mention' &&
    target.getAttribute('data-mention-group') == 'true'
  ) {
    event.preventDefault();
    if (props.currentTask?.groupId) {
      let linkCurrent = target?.href?.split('groups')?.shift();
      window.open(
        `${linkCurrent}groups/${props.currentTask?.groupId}`,
        '_blank'
      );
    }
    if (!props?.currentTask?.groupId) {
      openNotification({
        type: AlertType.warning,
        title: translate(''),
        body: translate('COMMENT_ALERT_NOT_INCLUDE_GROUP', {
          code: props.currentTask?.code,
        }),
      });
    }
  }
};

const onRemoveAttachment = async (file) => {
  if (!file?.isNote) {
    settingGlobalModal({
      type: 'confirm',
      title: '',
      content:
        translate('COMMON_LABEL_QUESTION_REMOTE_FILE') ||
        'Do you want to remove this file?',
      confirmable: true,
      confirmLabel: translate('COMMON_LABEL_CONFIRM') || 'Confirm',
      closeable: true,
    });

    const confirmed = await ask();
    if (!confirmed) return;
  }

  const payload = {
    attachmentId: file?.id,
    attachmentType: file?.isNote ? 'NOTE' : 'FILE',
  };
  await _taskCommentStore.deleteAttachmentInComment(
    currentCommentId.value,
    payload
  );
  await _taskCommentStore.getListAttachmentById(currentCommentId.value);
  console.log('File: TaskComment.vue - L: 196 - ', currentAttachmentList.value);
  if (currentAttachmentList.value.length < 1) {
    indexCurrentFile.value = null;
  }
};

const isLoadingWhenUpdateNote = computed(() => _taskCommentStore.isLoading);

const onSaveUpdateNote = (noteData) => {
  _taskCommentStore.updateCommentAttachments(currentCommentId.value, {
    addedFiles: [],
    removedFiles: [],
    addedNotes: [],
    editedNotes: [noteData],
    removedNotes: [],
  });
  // emits('onSaveUpdateNote', noteData);
};

const onClickDelete = async (reply) => {
  await onDeleteComment(reply?.id);
  emits('deleteSuccess');
};
</script>

<template>
  <template v-if="isLoading?.get">
    <div class="flex flex-col space-y-2 w-full px-4 py-2">
      <div class="w-1/4 bg-gray-200 h-3 rounded-full animate-pulse"></div>
      <div class="w-2/3 bg-gray-200 h-3 rounded-full animate-pulse"></div>
    </div>
  </template>
  <template v-else>
    <div
      class="w-full h-full flex flex-col relative"
      @click="commentTaskInputRef?.onClose()"
    >
      <!--PINNED COMMENTS-->
      <div
        v-if="listCommentPinned?.length > 0"
        class="flex flex-col bg-orange-50 bg-opacity-70 h-full"
      >
        <MessageItem
          v-for="cmtPinned in listCommentPinned"
          :key="cmtPinned"
          :message="cmtPinned"
          :is-show-line-date="false"
          class="mb-1 w-full"
          bg-msg="bg-white bg-opacity-20"
          :is-edit="
            currentCommentEdit &&
            currentCommentEdit?.id === cmtPinned?.id &&
            currentCommentEdit?.isPinned
          "
          :is-loading="isLoading"
          :users="users"
          :readonly="readonly"
          @on-reaction-add="onAddReactionComment"
          @on-edit-comment="onEditComment({ ...cmtPinned, isPinned: true })"
          @on-remove-comment="onClickDelete(cmtPinned)"
          @on-pin-comment="onPinComment(cmtPinned?.id)"
          @on-file-click="
            ($event) => onFileClick($event, cmtPinned.id, cmtPinned)
          "
          @on-click-text-comment="onClickTextComment"
        >
          <template
            v-if="
              currentCommentEdit &&
              currentCommentEdit?.id === cmtPinned?.id &&
              currentCommentEdit?.isPinned
            "
            #input-edit
          >
            <TaskCommentInput
              ref="editCommentTaskPinnedInputRef"
              v-model="currentCommentEdit.content"
              v-model:mention-user-ids="currentCommentEdit.mentionUserIds"
              v-model:mention-group="currentCommentEdit.mentionGroup"
              edit-mode="EDIT"
              :current-task="currentTask"
              :group-id="currentTask?.groupId"
              :users-related="listUserRelated"
              :task-private="currentTask?.isPrivate"
              :files="cmtPinned?.attachments?.files"
              :notes="cmtPinned?.attachments?.notes"
              :is-loading-send="isLoading?.update"
              is-action-close
              @on-save="onSaveUpdateComment"
              @on-close="onCloseEditComment"
            />
          </template>
        </MessageItem>
      </div>

      <!--COMMENTS-->
      <div v-if="primaryComments?.length > 0" class="bg-gray-50">
        <template v-for="(cmt, index) in primaryComments" :key="cmt?.id">
          <MessageItem
            :message="cmt"
            :is-edit="
              currentCommentEdit &&
              currentCommentEdit?.id === cmt?.id &&
              !currentCommentEdit?.isPinned
            "
            :users="users"
            :readonly="readonly"
            :can-reply="isAllowReply"
            :is-highlight="highlightCommentIds?.some((id) => id == cmt?.id)"
            @on-reaction-add="onAddReactionComment"
            @on-edit-comment="onEditComment(cmt)"
            @on-remove-comment="onClickDelete(cmt)"
            @on-pin-comment="onPinComment(cmt?.id)"
            @on-reaction-remove="onRemoveReaction"
            @on-file-click="onFileClick($event, cmt.id, cmt)"
            @on-message-download="onMessageDownload"
            @on-click-text-comment="onClickTextComment"
            @on-reply-click="onReplyCommentClick(cmt?.id, index)"
          >
            <!--EDIT MODE-->
            <template
              v-if="
                currentCommentEdit &&
                currentCommentEdit?.id === cmt?.id &&
                !currentCommentEdit?.isPinned
              "
              #input-edit
            >
              <TaskCommentInput
                ref="editCommentTaskInputRef"
                v-model="currentCommentEdit.content"
                v-model:mention-user-ids="currentCommentEdit.mentionUserIds"
                v-model:mention-group="currentCommentEdit.mentionGroup"
                :current-task="currentTask"
                edit-mode="EDIT"
                is-auto-focus
                :group-id="currentTask?.groupId"
                :users-related="listUserRelated"
                :task-private="currentTask?.isPrivate"
                :files="currentCommentEdit?.attachments?.files"
                :notes="currentCommentEdit?.attachments?.notes"
                :is-loading-send="isLoading?.update"
                is-action-close
                @on-save="onSaveUpdateComment"
                @on-close="onCloseEditComment"
              />
            </template>

            <!--COMMENT REPLIES-->
            <template #replies>
              <TaskCommentReplyExpand
                :expanded="expandedRepliesByComment[cmt?.id]"
                :replies="repliesByComment[cmt?.id]"
                @on-expanded-toggle="
                  expandedRepliesByComment[cmt?.id] =
                    !expandedRepliesByComment[cmt?.id]
                "
              />
              <div v-if="expandedRepliesByComment[cmt?.id]" class="border-l">
                <MessageItem
                  v-for="reply in repliesByComment[cmt?.id]"
                  :key="reply?.id"
                  :message="reply"
                  :is-edit="
                    currentCommentEdit &&
                    currentCommentEdit?.id === reply?.id &&
                    !currentCommentEdit?.isPinned
                  "
                  :users="users"
                  :readonly="readonly"
                  :is-highlight="
                    highlightCommentIds?.some((id) => id == reply?.id)
                  "
                  @on-reaction-add="onAddReactionComment"
                  @on-edit-comment="onEditComment(reply)"
                  @on-remove-comment="onClickDelete(reply)"
                  @on-pin-comment="onPinComment(reply?.id)"
                  @on-reaction-remove="onRemoveReaction"
                  @on-file-click="
                    ($event) => onFileClick($event, reply.id, reply)
                  "
                  @on-message-download="onMessageDownload"
                  @on-click-text-comment="onClickTextComment"
                >
                  <template
                    v-if="
                      currentCommentEdit &&
                      currentCommentEdit?.id === reply?.id &&
                      !currentCommentEdit?.isPinned
                    "
                    #input-edit
                  >
                    <TaskCommentInput
                      ref="editCommentTaskInputRef"
                      v-model="currentCommentEdit.content"
                      v-model:mention-user-ids="
                        currentCommentEdit.mentionUserIds
                      "
                      v-model:mention-group="currentCommentEdit.mentionGroup"
                      :current-task="currentTask"
                      edit-mode="EDIT"
                      is-auto-focus
                      :group-id="currentTask?.groupId"
                      :users-related="listUserRelated"
                      :task-private="currentTask?.isPrivate"
                      :files="currentCommentEdit?.attachments?.files"
                      :notes="currentCommentEdit?.attachments?.notes"
                      :is-loading-send="isLoading?.update"
                      is-action-close
                      @on-save="onSaveUpdateComment"
                      @on-close="onCloseEditComment"
                    />
                  </template>
                </MessageItem>
                <div
                  v-if="isAllowReply"
                  :id="'input-reply-cmt-' + cmt?.id"
                  class="px-4 py-2"
                >
                  <TaskCommentBottom
                    :ref="setInputCommentReplyRefs(index)"
                    user-class="w-6 h-6"
                    :input-placeholder="$t('TASK_COMMENT_REPLY_ADD_')"
                    :task-id="taskId"
                    :current-task="currentTask"
                    :reply-to="cmt?.id"
                    :function-code="ALL_FUNCTIONS.TASK.COMMENT_REPLY"
                    @on-save-success="$emit('onSaveSuccess', false)"
                  />
                </div>
              </div>
            </template>
          </MessageItem>
        </template>
      </div>
    </div>
  </template>

  <ModalMultipleFileViewer
    v-if="fileShowViewer && indexCurrentFile !== null"
    :start-index="indexCurrentFile"
    :files="currentAttachmentList"
    :content-type="fileShowViewer?.contentType"
    :editable="isPermissionDeleteAttachment"
    :is-loading="isLoadingWhenUpdateNote"
    @on-file-click="onFileClickByIndex"
    @on-close="fileShowViewer = null"
    @on-save-note="onSaveUpdateNote"
    @on-remove="onRemoveAttachment"
  >
    <template #title>
      <span>
        {{ fileShowViewer?.fileName }}
      </span>
    </template>
  </ModalMultipleFileViewer>
</template>

<style scoped>
@import '@/ui/plugins/ckeditor/css/content-styles.scss';
</style>
