import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';

import BaseRepository from '@/base/repositories/BaseRepository';
import {
    ETaskRepeatType,
    ICustomTaskColumn,
    ICustomTaskFilter,
    ITaskReccurring,
    ITaskReminderInput,
    ITaskRepeat,
} from '../interfaces/tasks/task-interfaces';
import { translate } from '@/ui/plugins/i18n/myi18n';
import {
    ECustomFilterSourceType,
    ETaskListModule,
    IDeleteTaskUserRolePayload,
    ITaskTodoList,
    IUpdateCollaboratorPayload,
    TaskApprovalStatus,
    TaskResponsible,
    TaskUserRole,
    UpdateMultipleTaskPayload,
} from '../types/task/task.types';
import { ITaskWorkflowAttachPayload } from '@/domain/entities/task/TaskWorkflowEntity';

export default class TaskRepository {
    private static instance: TaskRepository;

    constructor() {}

    public static getInstance(): TaskRepository {
        if (!TaskRepository.instance) {
            // Get from local storage
            TaskRepository.instance = new TaskRepository();
        }

        return TaskRepository.instance;
    }

    getHistories(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetFeedbackTasks`,
            method: 'post',
            data,
        });
    }

    getCurrentTaskCycle(organizationId) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetCurrentTaskCycle`,
            params: { orgId: organizationId },
            method: 'get',
        });
    }

    clone(data: any): Promise<any> {
        return BaseRepository.requestWithAuthorize({
            url: `Task/CloneTask`,
            method: 'post',
            data,
        });
    }

    reopen(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/Reopen`,
            method: 'post',
            data,
        });
    }

    renameTask(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/RenameTask`,
            method: 'post',
            data,
        });
    }

    renameOrgTask(orgId, data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/RenameTask`,
            method: 'post',
            data,
            params: { orgId },
        });
    }

    updateNote(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/UpdateNote`,
            method: 'post',
            data,
        });
    }

    updateFiles(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/UpdateFiles`,
            method: 'post',
            data,
        });
    }

    changeDescription(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/ChangeDescription`,
            method: 'post',
            data,
        });
    }

    changeOrgTaskDescription(orgId, data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/ChangeDescription`,
            method: 'post',
            data,
            params: { orgId },
        });
    }

    changeAssigneeTask(data: TaskResponsible) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/ChangeAssigneeTask`,
            method: 'post',
            data,
        });
    }

    createTask(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/SaveOrUpdate`,
            method: 'post',
            data,
        });
    }

    getTaskWithFilter(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetTasksWithFilters`,
            method: 'post',
            data,
        });
    }

    getSharedTasksWithFilters(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetSharedTasksWithFilters`,
            method: 'post',
            data,
        });
    }

    getWorkflowTasksWithFilters(workflowId, data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetWorkflowTasksWithFilters`,
            method: 'post',
            data,
            params: { workflowId },
        });
    }

    getCollaboratorTask(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetCollaboratorTask`,
            method: 'post',
            data,
        });
    }

    getDoTodayTask(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetDoTodayTask`,
            method: 'post',
            data,
        });
    }

    getOverdueTask(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetOverdueTask`,
            method: 'post',
            data,
        });
    }

    getFinishedTask(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetFinishedTask`,
            method: 'post',
            data,
        });
    }

    getOtherTask(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetOtherTask`,
            method: 'post',
            data,
        });
    }

    getTasksByDomain(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetTasksByDomain`,
            method: 'post',
            data,
        });
    }

    getDetailTaskByCode(taskCode) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/getDetailTaskByCode?code=${taskCode}`,
            method: 'get',
        });
    }

    getOrgTaskDetailByCode(orgId, taskCode) {
        return BaseRepository.requestWithAuthorize({
            url: 'Task/getDetailTaskByCode',
            method: 'get',
            params: { orgId, code: taskCode },
        });
    }

    getDetailTaskById(taskId) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetDetailTask?id=${taskId}`,
            method: 'get',
        });
    }

    getOrgTaskDetail(orgId, taskId) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetDetailTask`,
            method: 'get',
            params: { orgId, id: taskId },
        });
    }

    getDetailTaskIncognitoById(taskId) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetDetailTaskIncognito?id=${taskId}`,
            method: 'get',
        });
    }

    getAttachmentNoteByTaskId(taskId) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/getAttachmentNoteByTaskId?id=${taskId}`,
            method: 'get',
        });
    }

    getOrgAttachmentNoteByTaskId(orgId, taskId) {
        return BaseRepository.requestWithAuthorize({
            url: 'Task/getAttachmentNoteByTaskId',
            method: 'get',
            params: { orgId, id: taskId },
        });
    }

    getDrawDataById(noteIdString) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetDrawDataById?id=${noteIdString}`,
            method: 'get',
        });
    }

    getDataConfig() {
        const lang = getLocalStorage(StorageConstant.LANGUAGE);
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetDataConfig?lang=${lang}`,
            method: 'get',
        });
    }

    getSubProjectsWithParentIds(parentIds) {
        return BaseRepository.requestWithAuthorize({
            url: `Project/GetSubProjectsWithParentIds?parentIds=${parentIds}`,
            method: 'get',
        });
    }

    changePriority(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/ChangePriority`,
            method: 'post',
            data,
        });
    }

    changeUrgency(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/ChangeUrgency`,
            method: 'post',
            data,
        });
    }

    changeOrgTaskUrgency(orgId, data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/ChangeUrgency`,
            method: 'post',
            data,
            params: { orgId },
        });
    }

    changeDomain(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/ChangeDomain`,
            method: 'post',
            data,
        });
    }

    changeOrgTaskDomain(orgId, data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/ChangeDomain`,
            method: 'post',
            data,
            params: { orgId },
        });
    }

    changePrivate(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/ChangePrivate`,
            method: 'post',
            data,
        });
    }

    changeOrgTaskPrivate(orgId, data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/ChangePrivate`,
            method: 'post',
            data,
            params: { orgId },
        });
    }

    changeEstimationTime(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/ChangeEstimationTime`,
            method: 'post',
            data,
        });
    }

    changeFinishedPercentage(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/ChangeFinishedPercentage`,
            method: 'post',
            data,
        });
    }

    changeType(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/ChangeType`,
            method: 'post',
            data,
        });
    }

    deleteTask(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/DeleteTask`,
            method: 'post',
            // action: ACTION.remove,
            data,
        });
    }

    finishTask(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/FinishTask`,
            method: 'post',
            // action: ACTION.finish,
            data,
        });
    }

    changeState(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/ChangeTaskState`,
            method: 'post',
            // action: ACTION.finish,
            data,
        });
    }

    changeOrgTaskState(orgId, data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/ChangeTaskState`,
            method: 'post',
            // action: ACTION.finish,
            data,
            params: { orgId },
        });
    }

    setPlannedTask(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/SetPlannedTask`,
            method: 'post',
            data,
        });
    }

    setOrgTaskPlanned(orgId, data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/SetPlannedTask`,
            method: 'post',
            data,
            params: { orgId },
        });
    }

    changeScheduleTime(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/ChangeScheduleTime`,
            method: 'post',
            data,
        });
    }
    changeStartEndTime(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/ChangeStartEndTime`,
            method: 'post',
            data,
        });
    }

    changeOrgTaskScheduleTime(orgId, data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/ChangeScheduleTime`,
            method: 'post',
            data,
            params: { orgId },
        });
    }

    setAssignee(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/SetAssigneeTask`,
            method: 'post',
            data,
        });
    }

    setOrgTaskAssignee(orgId, data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/SetAssigneeTask`,
            method: 'post',
            data,
            params: { orgId },
        });
    }

    getTaskListOverview() {
        return BaseRepository.requestWithAuthorize({
            url: `TaskPlan/GetTaskListOverview`,
            method: 'get',
        });
    }

    getTaskListPlannedToTreat() {
        return BaseRepository.requestWithAuthorize({
            url: `TaskPlan/GetTaskListPlannedToTreat`,
            method: 'get',
        });
    }

    saveTaskListPlannedToTreat(data) {
        return BaseRepository.requestWithAuthorize({
            url: `TaskPlan/SaveTaskListPlannedToTreat`,
            method: 'post',
            // action: ACTION.update,
            data,
        });
    }

    getTaskListPlannedToFinish() {
        return BaseRepository.requestWithAuthorize({
            url: `TaskPlan/GetTaskListPlannedToFinish`,
            method: 'get',
        });
    }

    updateTaskListPlannedToFinish(data) {
        return BaseRepository.requestWithAuthorize({
            url: `TaskPlan/UpdateTaskListPlannedToFinish`,
            method: 'post',
            data,
        });
    }

    saveTaskListPlannedToday(data) {
        return BaseRepository.requestWithAuthorize({
            url: `TaskPlan/SaveTaskListPlannedToday`,
            method: 'post',
            data,
        });
    }

    getStopWorkingDayTaskList() {
        return BaseRepository.requestWithAuthorize({
            url: `TaskPlan/GetStopWorkingDayTaskList`,
            method: 'get',
        });
    }

    getTaskOverviewStatistic() {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetTaskOverviewStatistic`,
            method: 'get',
        });
    }

    stopWorkingDay(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/StopWorkingDay`,
            method: 'post',
            data,
        });
    }

    updateTaskConfig(config) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/UpdateTaskConfig`,
            method: 'post',
            data: config,
        });
    }

    getUserTaskSchedule(assigneeId) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetUserTaskSchedule?assigneeId=${assigneeId}`,
            method: 'get',
        });
    }

    // Media
    uploadAws(files) {
        const formData = new FormData();
        formData.append('bucketType', '1');
        files.forEach((file) => {
            formData.append('files', file);
        });
        return BaseRepository.requestWithAuthorize({
            url: `Media/UploadAws`,
            method: 'post',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    //dashboard
    getFinishedTasks(departmentId?: number) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/getFinishedTasks`,
            method: 'get',
            params: {
                dptId: departmentId,
            },
        });
    }

    getFinishedTaskRankingByMember(departmentId?: number) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/getFinishedTaskRankingByMember`,
            method: 'get',
            params: {
                dptId: departmentId,
            },
        });
    }

    getFinishedTaskRankingByDomain(departmentId?: number) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/getFinishedTaskRankingByDomain`,
            method: 'get',
            params: {
                dptId: departmentId,
            },
        });
    }

    getFinishedTaskOverview(departmentId?: number) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/getFinishedTaskOverview`,
            method: 'get',
            params: {
                dptId: departmentId,
            },
        });
    }

    getCreatedTasks(departmentId?: number) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetNewCreatedTasks`,
            method: 'get',
            params: {
                dptId: departmentId,
            },
        });
    }

    getCreatedTaskByDomain(departmentId?: number) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetNewCreatedTaskByDomain`,
            method: 'get',
            params: {
                dptId: departmentId,
            },
        });
    }

    getCreatedTaskOverview(departmentId?: number) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetNewCreatedTaskOverview`,
            method: 'get',
            params: {
                dptId: departmentId,
            },
        });
    }

    //  ACTIVITY
    getTaskActivityById(taskId, orderBy) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetTaskActivityById?taskId=${taskId}&orderBy=${
                orderBy || 'desc'
            }`,
            method: 'get',
        });
    }

    getTaskGroupActivityById(orgId, taskId, orderBy) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetTaskGroupActivityById?orgId=${orgId}&taskId=${taskId}&orderBy=${
                orderBy || 'desc'
            }`,
            method: 'get',
        });
    }

    // COMMENT
    getAllComments(orgId, taskId) {
        return BaseRepository.requestWithAuthorize({
            url: 'Task/GetAllComments',
            method: 'get',
            params: { orgId, taskId },
        });
    }

    createComment(orgId, taskId, data) {
        return BaseRepository.requestWithAuthorize({
            url: 'Task/CreateComment',
            method: 'post',
            data,
            params: { orgId, taskId },
        });
    }

    updateComment(commentId, data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/UpdateComment?commentId=${commentId}`,
            method: 'post',
            data: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    updateCommentAttachments(commentId, data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/UpdateCommentAttachments?commentId=${commentId}`,
            method: 'post',
            data,
        });
    }

    pinnedComment(commentId) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/PinnedComment?commentId=${commentId}`,
            method: 'post',
        });
    }

    reactionComment(commentId, data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/ReactionComment?commentId=${commentId}`,
            method: 'post',
            data,
        });
    }

    deleteComment(commentId) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/DeleteComment?commentId=${commentId}`,
            method: 'post',
        });
    }

    deleteAttachmentInComment(commentId, data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/RemoveCommentAttachments?commentId=${commentId}`,
            method: 'post',
            data,
        });
    }

    createTaskRepeatInfo(taskId, data: ITaskReccurring, taskCode) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Task/CreateTaskRepeatInfo?taskId=${taskId}`,
                method: 'post',
                data,
            },
            {
                message: translate(
                    'API_ACTION_MESSAGE_TASK_TURN_ON_REPEAT_TASK',
                    {
                        taskCode,
                    }
                ),
            }
        );
    }

    getRepeatTemplateInfo(templateId) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetRepeatTemplateInfo?templateId=${templateId}`,
            method: 'get',
        });
    }

    getRepeatDetail(taskId) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetTaskRepeatInfo?taskId=${taskId}`,
            method: 'get',
        });
    }

    getTaskWorkFlow(taskId) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetTaskWorkflow?taskId=${taskId}`,
            method: 'get',
        });
    }

    getAllStepTemplates() {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/GetWorkflowStepTemplates`,
            method: 'get',
        });
    }

    getAllSharedWorkFlow() {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/GetAllSharedWorkFlow`,
            method: 'get',
        });
    }

    updateTaskRepeatInfo(taskId, data: ITaskReccurring, taskCode, isTurnOn) {
        const messageCode = isTurnOn
            ? 'API_ACTION_MESSAGE_TASK_TURN_ON_REPEAT_TASK'
            : data.repeat.repeatType === ETaskRepeatType.None
            ? 'API_ACTION_MESSAGE_TASK_TURN_OFF_REPEAT_TASK'
            : 'API_ACTION_MESSAGE_TASK_UPDATE_REPEAT_TASK';
        return BaseRepository.requestWithAuthorize(
            {
                url: `Task/UpdateTaskRepeatInfo?taskId=${taskId}`,
                method: 'post',
                data,
            },
            {
                message: translate(messageCode, {
                    taskCode,
                }),
            }
        );
    }

    setRepeat(taskId, data: ITaskRepeat) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/UpdateTaskRepeatInfo?taskId=${taskId}`,
            method: 'post',
            data,
        });
    }

    skipRepeatToCurrentTask(taskId) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/SkipRepeatToCurrentTask?taskId=${taskId}`,
            method: 'post',
        });
    }

    getUserTaskSummaryStatistics(userId) {
        return BaseRepository.requestWithAuthorize({
            url: `Dashboard/GetUserTaskSummaryStatistics?userId=${userId}`,
            method: 'get',
        });
    }

    getTaskSummaryStatistics(sourceType: ETaskListModule, listOwnerId: number) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetTaskSummaryStatistics`,
            method: 'get',
            params: {
                sourceType,
                listOwnerId,
            },
        });
    }
    getTaskApprovalStatistic(listOwnerId: number) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetTaskApprovalStatistic`,
            method: 'get',
            params: {
                listOwnerId,
            },
        });
    }

    updateMultipleTasks(data: UpdateMultipleTaskPayload) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/UpdateMultipleTasks`,
            method: 'post',
            data,
        });
    }

    getAllTaskUserRole() {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetAllTaskUserRole`,
            method: 'get',
        });
    }

    createOrUpdateTaskUserRole(data: TaskUserRole) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/CreateOrUpdateTaskUserRole`,
            data,
        });
    }

    getUserCustomColumnTask() {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetUserCustomColumnTask`,
            method: 'get',
        });
    }

    addUserCustomTaskColumn(data: ICustomTaskColumn[]) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/AddUserCustomTaskColumn`,
            method: 'post',
            data,
        });
    }

    getTaskUserAffectedByRole() {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetTaskUserAffectedByRole`,
            method: 'get',
        });
    }

    deleteTaskUserRole(data: IDeleteTaskUserRolePayload) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/DeleteTaskUserRole`,
            method: 'post',
            data,
        });
    }

    addCollaboratorsInTask(data: IUpdateCollaboratorPayload) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/AddCollaboratorsInTask`,
            method: 'post',
            data,
        });
    }

    deleteCollaboratorsInTask(data: IUpdateCollaboratorPayload) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/DeleteCollaboratorsInTask`,
            method: 'post',
            data,
        });
    }

    updateUserCustomTaskColumn(data: ICustomTaskColumn[]) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/UpdateUserCustomTaskColumn`,
            method: 'post',
            data,
        });
    }

    resetUserCustomTaskColumn() {
        return BaseRepository.requestWithAuthorize({
            url: `Task/DeleteUserCustomTaskColumn`,
            method: 'post',
        });
    }

    getUserCustomTaskFilters(
        sourceType: ETaskListModule | ECustomFilterSourceType,
        sourceId: number
    ) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetUserCustomTaskFilters`,
            method: 'get',
            params: {
                sourceType,
                sourceId,
            },
        });
    }

    addUserCustomTaskFilter(
        sourceType: ETaskListModule | ECustomFilterSourceType,
        sourceId: number,
        data: ICustomTaskFilter
    ) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/AddUserCustomTaskFilter`,
            method: 'post',
            params: {
                sourceType,
                sourceId,
            },
            data,
        });
    }

    updateUserCustomTaskFilterFilter(
        sourceType: ETaskListModule,
        sourceId: number,
        data: ICustomTaskFilter
    ) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/UpdateUserCustomTaskFilterFilter`,
            method: 'post',
            params: {
                sourceType,
                sourceId,
            },
            data,
        });
    }

    deleteUserCustomTaskFilter(
        sourceType: ETaskListModule,
        sourceId: number,
        id: string
    ) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/DeleteUserCustomTaskFilter`,
            method: 'post',
            params: {
                sourceType,
                sourceId,
                id,
            },
        });
    }

    getAllAssigneeTaskStatisticByUser(userId) {
        return BaseRepository.requestWithAuthorize({
            url: `Dashboard/GetAllAssigneeTaskStatisticByUser?userId=${userId}`,
            method: 'get',
        });
    }

    // todo list
    getTaskCheckListByTaskId(taskId, orgId?: number) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetTaskCheckListByTaskId`,
            method: 'get',
            params: { taskId, orgId },
        });
    }
    getSubTasks(taskId) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetSubTasks`,
            method: 'get',
            params: { taskId },
        });
    }

    createOrUpdateTaskCheckList(data: ITaskTodoList, orgId?: number) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/UpdateTaskCheckList`,
            method: 'post',
            data,
            params: { orgId },
        });
    }

    removeTaskCheckList(
        data: { taskId: number; taskTodoIds: string[] },
        orgId?: number
    ) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/RemoveTaskCheckList`,
            method: 'post',
            data,
            params: { orgId },
        });
    }

    removeItemsInTaskCheckList(
        data: {
            taskId: number;
            taskTodoId: string;
            todoItemIds: string[];
        },
        orgId?: number
    ) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/RemoveItemsInTaskCheckList`,
            method: 'post',
            data,
            params: { orgId },
        });
    }

    changePin(data: { taskId: number; color: string }) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/PinTask`,
            method: 'post',
            data,
        });
    }

    unpin(taskId: number) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/UnpinTask`,
            method: 'post',
            params: {
                taskId,
            },
        });
    }

    getPinnedTasks(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetPinnedTask`,
            method: 'post',
            data,
        });
    }

    getTaskSharedByTaskId(taskId) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetTaskSharedByTaskId/${taskId}`,
            method: 'get',
        });
    }

    saveTaskShared(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/SaveTaskShared`,
            method: 'post',
            data,
        });
    }

    attachWorkFlowToTask(taskId: number, payload: ITaskWorkflowAttachPayload) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Task/AttachWorkflowToTask`,
                method: 'post',
                params: {
                    taskId,
                },
                data: payload,
            },
            {
                message:
                    translate('COMMON_LABEL_YOU') +
                    ' ' +
                    translate(
                        'NOTIFICATION_CONTENT_BY_ACTION_TYPE_ATTACH_WORKFLOW'
                    ),
            }
        );
    }
    setTaskApproval(
        taskId: number,
        payload: {
            approvalEnabled: boolean;
            approverIds: number[];
        }
    ) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/SetTaskApproval`,
            method: 'put',
            params: {
                taskId,
            },
            data: payload,
        });
    }
    requestTaskApproval(
        taskId: number,
        data: {
            isCreateComment: boolean;
            comment: string;
            attachments: {
                files: any[];
                notes: any[];
            };
            workflowApprovalNextStepId: string | undefined;
        }
    ) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/RequestTaskApproval`,
            method: 'put',
            params: {
                taskId,
            },
            data,
        });
    }
    setTaskApprovalStatus(
        taskId: number,
        data: {
            approvalStatus: TaskApprovalStatus;
            comment: string;
            attachments: {
                files: any[];
                notes: any[];
            };
            workflowApprovalNextStepId: string | undefined;
        }
    ) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/SetTaskApprovalStatus`,
            method: 'put',
            params: {
                taskId,
            },
            data,
        });
    }
    cancelRequestTaskApproval(taskId: number) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/CancelRequestTaskApproval`,
            method: 'put',
            params: {
                taskId,
            },
        });
    }

    //Task reminder
    setReminderTask(taskId, payload: ITaskReminderInput) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/SetReminder/${taskId}`,
            method: 'post',
            data: payload,
        });
    }

    renewReminderTask(eventId, expiredAfter: number) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/RenewReminder/${eventId}`,
            method: 'post',
            params: {
                expiredAfter: expiredAfter,
            },
        });
    }

    stopReminderTask(eventId) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/StopReminder/${eventId}`,
            method: 'post',
        });
    }

    reminderActionFromWebPush(eventId) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/ReminderActionFromWebPush`,
            method: 'get',
            params: {
                eventId: eventId,
            },
        });
    }

    getTaskReminder(taskId) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetTaskReminder/${taskId}`,
            method: 'get',
        });
    }

    removeReminderByTaskId(taskId) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Task/RemoveReminder/${taskId}`,
                method: 'post',
            },
            {
                message: translate(
                    'API_ACTION_MESSAGE_REMOVE_REMINDER_TASK_SUCCESS'
                ),
            }
        );
    }
    updateTaskWorkflowSteps(taskWorkflowId, data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/UpdateTaskWorkflowSteps`,
            method: 'put',
            params: {
                taskWorkflowId,
            },
            data,
        });
    }
    removeAsSubTask(taskId, parentTaskCode) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Task/removeSubTaskFromParentTask`,
                method: 'put',
                params: {
                    taskId,
                },
            },
            {
                message: translate(
                    'API_ACTION_MESSAGE_REMOVE_SUB_TASK_FROM_PARENT_TASK',
                    {
                        parentTaskCode,
                    }
                ),
            }
        );
    }
    setParentTask(taskId, parentId) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/SetTaskAsSubOfParent`,
            method: 'put',
            params: {
                taskId,
            },
            data: {
                parentId,
            },
        });
    }
    getStatisticOverview(departmentId?: number) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetStatisticsOverview`,
            method: 'get',
            params: {
                dptId: departmentId,
            },
        });
    }
    getStatisticsByPlanToday(departmentId?: number) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetStatisticsByPlanToday`,
            method: 'get',
            params: {
                dptId: departmentId,
            },
        });
    }
    changeTaskScheduleTimeLocked(taskId, scheduleTimeLocked) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/ChangeTaskScheduleTimeLocked`,
            method: 'put',
            params: {
                taskId,
            },
            data: {
                scheduleTimeLocked,
            },
        });
    }
    updateEvidenceSettings(taskId, data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/UpdateTaskFinishProofSetting`,
            method: 'put',
            params: {
                taskId,
            },
            data,
        });
    }
    finishTaskInWorkflow(taskId, nextStepId) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/FinishTaskInWorkflow`,
            method: 'put',
            params: {
                taskId,
            },
            data: {
                nextStepId,
            },
        });
    }
}

export enum GET_TASK_DETAIL_ERROR_CODE {
    HAS_NOT_PERMISSION = 6004,
    DELETED = 6000,
    SOMETHINGS_WRONG = 6403,
}
