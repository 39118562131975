import { ref } from 'vue';
import { TaskFromChat } from '@/domain/entities/task/TaskFromChat';
import { ITaskTodo } from '@/application/types/task/task.types';

export const isShowTaskDetailGlobal = ref(false);
export const taskIdGlobal = ref(0);
export const taskCodeGlobal = ref('');
export const isOpenTaskDrawer = ref(false);
export const isLoadingCacheNewTask = ref(true);
export const isOpenCreateTaskLocal = ref(false);
export const isOpenCreateTaskGroup = ref(false);

export const allowedExtensionString =
    '.txt,.jpg,.png,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.gif,.bmp,.mp3,.zip,.rar';

// CREATE TASK GLOBAL IN BOTTOM
export const isOpenCreateTaskGlobal = ref(false);

// HANDLE CREATE TASK IN DRAWER
export const isOpenCreateTaskDrawer = ref(false);
export interface INewTaskDefaultPayload {
    groupId?: number;
    assigneeId?: number;
    name?: string;
    description?: string;
    files?: File[];
    checklist?: ITaskTodo[];
    taskFromChat?: TaskFromChat | null;
    scheduleTime?: string;
    domainId?: number | null;
    projectId?: number | null;
    subprojectId?: number | null;
    taskWorkflow?: any;
}
export const newTaskDefaultPayload = ref<INewTaskDefaultPayload | undefined>(
    undefined
);

export const onCreateGlobalSuccess = ref((_task) => {});
export const attachmentToTaskGlobal = ref<{
    isOpen: boolean;
    attachments?: File[];
    checklist?: ITaskTodo[];
    description?: string;
}>({
    isOpen: false,
});

// PINNED TASKS
export const isOpenTaskPinnedDrawer = ref(false);
export const taskCreateNewRef = ref<any>();
