<script setup lang="ts">
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import CalendarAllEvents from '@/ui/components/calendars/CalendarAllEvents.vue';
// import TaskCreateLocal from '@/ui/modules/task/create/TaskCreateLocal.vue';
import calendarStore from '@/store/calendar';
import appStore from '@/store/app';
import myProfileStore from '@/store/auth/my-profile';
import PendingRequestDrawer from '@/ui/components/dayoff/PendingRequestDrawer.vue';
import MyActiveRequests from '@/ui/components/dayoff/MyActiveRequests.vue';
import { hasRolePermission } from '@/ui/hooks/permission/permission-by-role';
import { AdminType, GroupMemberRole } from '@/ui/common/constants/constant';
import calendarsOverviewStore from '@/store/calendars/calendars-overview';
import AddEventModal from '@/ui/components/calendars/add-event/AddEventModal.vue';
import { fullCalendarInstance } from '@/store/dayoff/dayoff-state';
import ModalFilterCalendar from '@/ui/modules/calendar/modal-filter-calendar/ModalFilterCalendar.vue';
import ChooseAddNewEvent from '@/ui/modules/calendar/modal-add-event/ChooseAddNewEvent.vue';
import settingStore from '@/store/setting';
import CheckPermissionContainer from '@/ui/components/setting/permissions/CheckPermissionContainer.vue';
import StatisticUserIcon from '@/ui/modules/calendar/calendar-statistic/StatisticUserIcon.vue';
import { lastUpdateFromRealtime } from './calendar-state';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import permissionStore from '@/store/permission';
import DayoffDetailClass from '@/domain/entities/dayoff/DayoffDetailClass';
import CalendarActions from '@/ui/modules/calendar/calendar-actions/CalendarActions.vue';
import CalendarSyncProgress from '@/ui/modules/calendar/calendar-sync/CalendarSyncProgress.vue';

defineEmits(['close']);
const calendarView = [
  {
    key: 'dayGridMonth',
    text: 'CALENDAR_TITLE_MONTH',
    type: 'month',
  },
  {
    key: 'dayGridWeek',
    text: 'CALENDAR_TITLE_WEEK',
    type: 'week',
  },
  {
    key: 'dayGridDay',
    text: 'CALENDAR_TITLE_DAY',
    type: 'day',
  },
  {
    key: 'listWeek',
    text: 'CALENDAR_TITLE_LIST',
    type: 'list',
  },
  // {
  //   key: 'dayGridDay',
  //   text: 'CALENDAR_TITLE_LIST_DAY',
  //   type: 'daylist',
  // },
];
const _calendarsOverviewStore = calendarsOverviewStore();
const _eventStore = calendarStore();
const _appStore = appStore();
const _settingStore = settingStore();
const _myProfileStore = myProfileStore();

const imageWel = computed(() => _appStore.welcomeImage);
const myProfile = computed(() => myProfileStore().myProfile);
const imVisitor = computed(() => myProfileStore().isVisitor);

const dayoffEnabled = computed(() => _settingStore.dayoffEnabled);
const calendarEnabled = computed(() => _settingStore.calendarEnabled);
const myDepartment = computed(() => _myProfileStore.myDepartment);
const departmentManagementEnabled = computed(
  () => _settingStore._organizationSetting.departmentManagement.enabled
);
const isAdmin = computed(() => {
  return (
    hasRolePermission(AdminType.Admin) ||
    hasRolePermission(AdminType.Owner) ||
    (departmentManagementEnabled.value &&
      myDepartment.value?.role == GroupMemberRole.Admin)
  );
});
const rightTabs = ref({
  // EVENTS: {
  //   key: 'EVENTS',
  //   label: 'DAYOFF_OVERVIEW_TAB_EVENT',
  //   labelDefault: 'Events',
  //   isActive: true,
  //   subContent: '',
  //   isShow: true,
  // },
  MY_ACTIVE_REQUESTS: {
    key: 'MY_ACTIVE_REQUESTS',
    label: 'DAYOFF_OVERVIEW_MY_ACTIVE_REQUEST',
    labelDefault: 'My Active Requests',
    isActive: false,
    subContent: '',
    isShow: true,
  },
} as any);
const calendarShowByType = computed<any>(() => {
  let result = {};
  if (
    dayoffEnabled.value &&
    permissionStore().organizationModel !== 'PERSONAL' &&
    !imVisitor.value
  ) {
    result = {
      ...result,
      DAYOFF: _calendarsOverviewStore.calendarShowByType.DAYOFF,
    };
  }
  if (calendarEnabled.value) {
    result = {
      ...result,
      EVENTS: _calendarsOverviewStore.calendarShowByType.EVENTS,
    };
  }
  return result;
});
// const isShowRightContent = computed(
//   () => _calendarsOverviewStore.isShowRightContent
// );

// PENDING_REQUESTS: {
//   key: 'PENDING_REQUESTS',
//   label: 'pending requests',
//   labelDefault: 'Pending Requests',
//   isActive: true,
// },

watch(
  () => lastUpdateFromRealtime.value,
  (value) => {
    if (Date.now() - value > 60 * 1000) return;
    reloadCalendar();
  }
);

const changeTab = async (key) => {
  Object.keys(rightTabs.value).forEach((e) => {
    rightTabs.value[e].isActive = e == key;
  });
  await nextTick();
  // _calendarsOverviewStore.onChangeViewDateCalendar();
  _calendarsOverviewStore.updateSizeCalendar();
};

const isLoadingPage = ref(false);
const viewTitle = computed(() => _calendarsOverviewStore.viewTitle);

onMounted(async () => {
  rightTabs.value = isAdmin.value
    ? {
        PENDING_REQUESTS: {
          key: 'PENDING_REQUESTS',
          label: 'DAYOFF_OVERVIEW_PENDING_REQUESTS',
          labelDefault: 'Pending Requests',
          isActive: false,
          isShow: true,
          icon: 'UserPending',
          iconLine: 'UserPendingLine',
          fill: 'fill-orange-400',
          background: 'bg-orange-50',
        },
        MY_ACTIVE_REQUESTS: {
          key: 'MY_ACTIVE_REQUESTS',
          label: 'DAYOFF_OVERVIEW_MY_ACTIVE_REQUEST',
          labelDefault: 'My Active Requests',
          isActive: false,
          isShow: true,
          icon: 'MyRequest',
          iconLine: 'MyRequestLine',
          fill: 'fill-current',
          background: 'bg-current-50',
        },
      }
    : {
        MY_ACTIVE_REQUESTS: {
          key: 'MY_ACTIVE_REQUESTS',
          label: 'DAYOFF_OVERVIEW_MY_ACTIVE_REQUEST',
          labelDefault: 'My Active Requests',
          isActive: false,
          isShow: true,
          icon: 'MyRequest',
          iconLine: 'MyRequestLine',
          fill: 'fill-current',
          background: 'bg-current-50',
        },
      };
  _eventStore.setIsFilter(false);
  if (isAdmin.value) {
    await _calendarsOverviewStore.getListPending();
  }
  await _calendarsOverviewStore.getMyActiveRequest();
  isLoadingPage.value = false;
});

onUnmounted(() => {
  _calendarsOverviewStore.$reset();
});

const isView = computed(() => _calendarsOverviewStore.viewCalendar);

const onChangeOptionViewCalendar = (isViewCalendar) => {
  _calendarsOverviewStore.onChangeViewCalender(isViewCalendar);
};

const backToviewMonth = () => {
  onChangeOptionViewCalendar('dayGridMonth');
};

const dayoffCalendarRef = ref(null as any);

const reloadCalendar = () => {
  dayoffCalendarRef.value?.update();
  _calendarsOverviewStore.getMyActiveRequest();
  if (isAdmin.value) _calendarsOverviewStore.getListPending();
};

const onChangeShowing = (item) => {
  _calendarsOverviewStore.changeCalendarShowByType(item);
};
// const toggleRightContent = () => {
//   _calendarsOverviewStore.toggleShowRightContent();
//   dayoffCalendarRef.value?.update();
// };

const isOpenCreateForm = computed(
  () => _calendarsOverviewStore.isOpenCreateForm
);
const onCloseCreateForm = () => {
  _calendarsOverviewStore.onCloseCreateForm();
};

const onNext = () => {
  fullCalendarInstance.value.next();
  _calendarsOverviewStore.updateViewTitle();
};

const onPrevious = () => {
  fullCalendarInstance.value.prev();
  _calendarsOverviewStore.updateViewTitle();
};

const onClosePanel = async () => {
  Object.keys(rightTabs.value).forEach((key) => {
    if (rightTabs.value[key].isActive) rightTabs.value[key].isActive = false;
  });
  await nextTick();
  _calendarsOverviewStore.updateSizeCalendar();
  // _calendarsOverviewStore.onChangeViewDateCalendar();
};

const pendingList = computed<DayoffDetailClass[]>(
  () => _calendarsOverviewStore.pendingList
);

const onSyncComplete = () => {
  _calendarsOverviewStore.getRangeEvents();
};
</script>

<template>
  <CheckPermissionContainer
    :function-names="['calendarEnabled', 'dayoffEnabled']"
  >
    <div
      v-permission-screen="{
        screenCode: ALL_SCREENS.COMMON.CALENDAR.ALL,
      }"
      class="h-full w-full overflow-hidden flex flex-col relative"
      :style="{
        'background-image': `linear-gradient(rgba(255,255,255,1), rgba(255,255,255,.90)), url(${imageWel})`,
        // 'background-color': `white`,
        'background-size': 'cover',
        'background-position': 'bottom right',
      }"
    >
      <SynPageLoading v-if="isLoadingPage" class="absolute top-0" />
      <template v-else-if="!calendarEnabled && !dayoffEnabled">
        <div class="h-full flex-center">
          <span class="text-2xl text-gray-700">
            You do not have permission to use this function. Please contact your
            admin!
          </span>
        </div>
      </template>
      <!-- EXTEND FULL SCREEN -->
      <div v-else class="h-full flex flex-col">
        <div class="flex items-center justify-between px-2">
          <div class="flex-center space-x-2 px-1">
            <div
              v-if="isView !== 'dayGridMonth'"
              class="
                px-2
                py-1
                h-8
                space-x-2
                flex-center
                bg-white
                border border-gray-200
                rounded
                cursor-pointer
                hover:bg-gray-200
                hover:ring
                hover:ring-gray-100
                hover:border-gray-400
              "
              @click="backToviewMonth"
            >
              <SynIcon name="back" custom-class="h-3 w-3 fill-gray-500" />
              <span class="text-gray-500 text-sm font-semibold">{{
                $t('COMMON_LABEL_BACK')
              }}</span>
            </div>
            <span class="capitalize font-semibold text-current-600">
              {{ viewTitle }}
            </span>
            <span
              class="
                h-8
                w-8
                hover:bg-current-50
                cursor-pointer
                rounded-full
                flex-center
              "
              @click="onPrevious"
            >
              <SynIcon name="previous" custom-class="h-3 w-3" />
            </span>
            <span
              class="
                h-8
                w-8
                hover:bg-current-50
                cursor-pointer
                rounded-full
                flex-center
              "
              @click="onNext"
            >
              <SynIcon name="next" custom-class="h-3 w-3" />
            </span>

            <CalendarSyncProgress
              class="h-8"
              @on-sync-complete="onSyncComplete"
            />
          </div>
          <div class="h-12 flex items-center space-x-2 justify-end">
            <div v-permission-model class="flex-center space-x-2">
              <div v-for="(item, index) in calendarShowByType" :key="index">
                <SynCheckboxLabel
                  class="text-lg"
                  size="middle"
                  :is-check="item.isShow"
                  @click="onChangeShowing(item)"
                >
                  <template #label>
                    <span class="text-sm">
                      {{ $t(item.label) || item.labelDefault }}
                    </span>
                  </template>
                </SynCheckboxLabel>
              </div>

              <!-- FILTER   -->
              <ModalFilterCalendar @update="reloadCalendar" />

              <!-- STATISTIC DAYOFF -->
              <div
                v-permission-model
                v-permission-by-admin-type="{ functionName: 'DayOff' }"
                class="
                  flex-center
                  bg-white
                  border border-gray-200
                  rounded
                  cursor-pointer
                  hover:bg-gray-200
                  hover:ring
                  hover:ring-gray-100
                  hover:border-gray-400
                "
              >
                <StatisticUserIcon :user-id="myProfile?.id" />
              </div>
            </div>
            <!-- VIEW CALENDAR -->
            <div class="flex-center space-x-2">
              <div>
                <SynSelect
                  v-model="isView"
                  width-class="w-16 text-sm"
                  is-translate
                  :options="calendarView"
                  key-field="key"
                  text-field="text"
                  @change="onChangeOptionViewCalendar"
                />
              </div>
              <!-- CREATE -->
              <VigDropdown trigger="click">
                <template #dropdown-toggle>
                  <div
                    class="
                      flex-center
                      fill-white
                      text-white
                      bg-current-400
                      rounded
                      cursor-pointer
                      w-8
                      h-8
                      p-2
                      hover:bg-current-500 hover:ring hover:ring-current-100
                    "
                  >
                    <SynIcon name="plus" custom-class="w-3 h-3" />
                  </div>
                </template>
                <template #dropdown-menu>
                  <div class="py-4 px-2">
                    <ChooseAddNewEvent size="mini" />
                  </div>
                </template>
              </VigDropdown>

              <!--  RELOAD  -->
              <div
                v-vig-tooltip="$t('TASK_LIST_LABEL_RELOAD')"
                class="
                  rounded
                  w-8
                  h-8
                  flex-center
                  border border-gray-200
                  hover:bg-gray-200
                  hover:ring
                  hover:ring-gray-100
                  hover:border-gray-400
                  cursor-pointer
                  fill-gray-500
                "
                @click="reloadCalendar()"
              >
                <SynIcon name="reload" />
              </div>
            </div>

            <CalendarActions />
          </div>
        </div>
        <div
          class="
            flex-1
            min-h-0
            h-full
            w-full
            flex-center
            relative
            small-scrollbar
          "
        >
          <div
            v-permission-screen="{
              screenCode: ALL_SCREENS.COMMON.CALENDAR.CALENDAR,
            }"
            class="flex-1 h-full transition-all"
          >
            <CalendarAllEvents
              ref="dayoffCalendarRef"
              class="h-full flex-1 overflow-auto small-scrollbar font-roboto"
            />
          </div>

          <div
            class="w-16 h-full bg-white border-t border-r flex justify-center"
          >
            <div class="space-y-4 flex flex-col" style="margin-top: 15rem">
              <div
                v-for="item in Object.keys(rightTabs)"
                :key="item"
                v-vig-tooltip="$t(rightTabs[item].label)"
                class="
                  flex-center
                  h-12
                  w-12
                  rounded-full
                  cursor-pointer
                  relative
                "
                :class="[
                  `hover:${rightTabs[item].background}`,
                  rightTabs[item].isActive ? rightTabs[item].background : '',
                ]"
                @click="changeTab(item)"
              >
                <SynIcon
                  :name="rightTabs[item]?.icon"
                  :class="rightTabs[item].fill"
                  custom-class="w-5 h-5"
                />
                <div
                  v-if="
                    item == rightTabs?.PENDING_REQUESTS?.key &&
                    pendingList.length > 0
                  "
                  class="
                    absolute
                    top-0
                    right-0
                    p-1
                    rounded-full
                    text-2xs
                    font-medium
                    bg-orange-400
                    text-white
                    w-5
                    h-5
                    flex-center
                  "
                >
                  {{ pendingList.length }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-permission-model
            class="
              h-full
              flex flex-col
              divide-y divide-current-100
              overflow-hidden
              transition-all
            "
            :style="
              rightTabs?.MY_ACTIVE_REQUESTS?.isActive ||
              rightTabs?.PENDING_REQUESTS?.isActive
                ? 'width: 26%'
                : 'width: 0%'
            "
            style="max-width: 100%"
          >
            <div
              v-permission-screen="{
                screenCode: ALL_SCREENS.COMMON.CALENDAR.WAITING_REQUEST,
              }"
              class="flex-1 flex flex-col border-t min-h-0"
            >
              <div class="flex items-center justify-between p-2 shadow-sm z-10">
                <div class="text-muted font-medium text-gray-800">
                  <span v-if="rightTabs?.PENDING_REQUESTS?.isActive">
                    {{
                      $t(rightTabs?.PENDING_REQUESTS?.label) ||
                      rightTabs?.PENDING_REQUESTS?.labelDefault
                    }}
                  </span>
                  <span v-if="rightTabs?.MY_ACTIVE_REQUESTS?.isActive">
                    {{
                      $t(rightTabs?.MY_ACTIVE_REQUESTS?.label) ||
                      rightTabs?.MY_ACTIVE_REQUESTS?.labelDefault
                    }}
                  </span>
                </div>
                <div
                  class="
                    h-8
                    w-8
                    rounded-full
                    hover:bg-gray-100
                    fill-gray-500
                    flex-center
                    cursor-pointer
                  "
                  @click="onClosePanel"
                >
                  <SynIcon name="close" custom-class="w-5 h-5" />
                </div>
              </div>
              <div class="flex-1 flex flex-col min-h-0">
                <PendingRequestDrawer
                  v-if="rightTabs?.PENDING_REQUESTS?.isActive"
                />
                <MyActiveRequests
                  v-else-if="rightTabs?.MY_ACTIVE_REQUESTS?.isActive"
                />
                <MyActiveRequests v-else-if="rightTabs?.EVENTS?.isActive" />
              </div>
              <!--              <SynTabs-->
              <!--                :option-status="rightTabs"-->
              <!--                positon="center"-->
              <!--                class="h-full"-->
              <!--                @change-tab="changeTab"-->
              <!--              >-->
              <!--                <template #tabBody>-->
              <!--                  <PendingRequestDrawer-->
              <!--                    v-if="rightTabs?.PENDING_REQUESTS?.isActive"-->
              <!--                  />-->
              <!--                  <MyActiveRequests-->
              <!--                    v-else-if="rightTabs?.MY_ACTIVE_REQUESTS?.isActive"-->
              <!--                  />-->
              <!--                  <MyActiveRequests v-else-if="rightTabs?.EVENTS?.isActive" />-->
              <!--                </template>-->
              <!--              </SynTabs>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </CheckPermissionContainer>
  <!--  <TaskCreateLocal :assignee-id="myProfile?.id" />-->
  <AddEventModal v-if="isOpenCreateForm" @close="onCloseCreateForm" />
</template>
