<script setup lang="ts">
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import attachmentLogic from './attachment-logic';
import noteLogic from './note-logic';
import commentLogic from './comment-logic';
import audioLogic from './audio-logic';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { isImage } from '@/ui/hooks/fileHook';
import taskCreateFormStore from '@/store/task/create-form';
import chatStore from '@/store/chat';
import { getDefaultTaskSchedule } from '@/application/parsers/TaskParser';
import UserById from '@/ui/components/user/UserById.vue';
import GroupById from '@/ui/components/group/GroupById.vue';
import { taskStates, TaskUrgency } from '@/ui/hooks/taskHook';
import { ScheduleDayOptions, TaskLife } from '@/domain/enums/taskEnum';
import {
  settingGlobalModal,
  ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import UploadPreview from '@/ui/components/file-viewer/UploadPreview.vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import ModalMultipleFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewer.vue';
import RecorderModal from '@/ui/common/atoms/SynRecorder/RecorderModal.vue';
import {
  isOpenCreateTaskLocal,
  isOpenCreateTaskGroup,
  isOpenCreateTaskGlobal,
} from '@/ui/modules/task/task-global-state';
import dayjs from 'dayjs';
import { isOpenTaskDrawer } from '@/ui/modules/task/task-global-state';
import TaskState from '@/ui/modules/task/components/TaskState.vue';
import CreateNoteModal from '@/ui/modules/task/detail/CreateNoteModal.vue';
import UploadDropZone from '@/ui/modules/ged/upload/upload-drop-zone/UploadDropZone.vue';
import ChangeAssign from '@/ui/modules/task/detail/ChangeAssign.vue';
import TaskCreateUpdatePayload from '@/domain/entities/task/TaskCreateUpdatePayload';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import UpdateDeadline from '@/ui/modules/task/detail/UpdateDeadline.vue';
import { IUpdateDeadlineForm } from '@/domain/entities/TaskType';
import UpdateDomain from '@/ui/modules/task/detail/UpdateDomain.vue';
import newDomainComps from '@/ui/modules/task/composables/new-domain-compsable';
import settingStore from '@/store/setting';
import ExplainIcon from '@/ui/components/common/ExplainIcon.vue';
import TaskDescriptionInput from '@/ui/common/plugins/ckeditor/TaskDescriptionInput.vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import SynModalNoFocus from '@/ui/common/molecules/SynModal/SynModalNoFocus.vue';
import taskTabViewStore from '@/store/task/task-tab-view';
import ManageCollaborator from '@/ui/modules/task/detail/assign/ManageCollaborator.vue';
import { EFilterTaskDefault } from '@/application/constants/task.const';
import remoteConfigStore from '@/store/remoteConfig';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import UpdateDeadlineTime from '@/ui/modules/task/detail/UpdateDeadlineTime.vue';
import myProfileStore from '@/store/auth/my-profile';

const urgencyList = ref(Object.values(TaskUrgency) as any[]);
const dateConfig = { type: 'string', mask: 'YYYY/MM/DD' };

const props = defineProps<{
  isOpenFull?: boolean;
  isGlobal?: boolean;
  assigneeId?: string | number;
  isShowApart?: boolean;
  isHiddenAssign?: boolean;
  isGroup?: boolean;
  groupId?: string | number;
  taskName?: string;
  descriptionTask?: string;
  inputFileId?: string;
  files?: File[];
}>();
const emit = defineEmits(['onOpenInterface', 'onCreateSuccess']);
const useCkeditorWithMention = false;
const _taskCreateFormStore = taskCreateFormStore();
const _chatStore = chatStore();
const _settingStore = settingStore();
const _taskTabViewStore = taskTabViewStore();

const taskCreateNewInputFileId = computed(
  () => `task-create-form_attachment_${Date.now}`
);
const childrenTabActive = computed(() => _taskTabViewStore.childrenTabActive);
const cachedFormData = computed(() => _taskCreateFormStore.cachedFormData);
const myTimezone = computed(() => myProfileStore().timezone);

const isInputName = ref(false);
const isOnFinishing = ref(false);
const isImportant = ref(false);
const isLoadingDataCache = computed(
  () => _taskCreateFormStore.isLoadingCacheFormData
);
const defaultPublicTask = computed(
  () => _settingStore._organizationSetting?.taskManagement?.defaultPublicTask
);

const multipleAssigneeSetting = computed(
  () =>
    _settingStore._organizationSetting.taskManagement?.multipleAssigneeSetting
);

const formData = ref<TaskCreateUpdatePayload>(
  new TaskCreateUpdatePayload({
    groupId: props.groupId,
    assigneeId: props.assigneeId,
    name: props.taskName || '',
    description: props.descriptionTask || '',
    files: [],
    isPrivate: !defaultPublicTask.value,
    taskLife: TaskLife.Todo,
  })
);

const nameInputRef = ref(null as any);
const onOpenForm = () => {
  onCloseEditCollaborator();
  if (props.isOpenFull) return;
  updateDomainRef.value?.initDomain();

  emit('onOpenInterface');
  // Close all chat panel
  _chatStore.closeBubbleChat();
  isOpenTaskDrawer.value = false;

  setTimeout(() => {
    nameInputRef.value?.focus();
  });
};
const {
  isInputComment,
  myComment,
  onOpenComment,
  onSaveComment,
  onCancelComment,
} = commentLogic(false, null);

const {
  isOpenRecording,
  onCreateAudio,
  onCreateAudioDone,
  onCreateAudioCancel,
} = audioLogic(formData);

const {
  isDraging,
  onChooseFiles,
  onDropImage,
  dragOver,
  onRemoveAttachments,
  onAddFiles,
} = attachmentLogic(formData, onOpenForm);

const {
  isCreateNote,
  currentNote,
  isLoadingDrawData,
  onSaveNote,
  onCreateNote,
  onCancelNote,
  onRemoveNote,
  onEditNote,
} = noteLogic(formData);

const { addNewDomainParams, updateDomainBefore } = newDomainComps(formData);

const initData = async () => {
  // _taskCreateFormStore.getCachedFromIndexDb();
  if (!props.isGlobal) {
    if (props.isGroup) {
      await onLoadCacheGroupForm(props.groupId);
    } else {
      await onLoadCacheUserForm(props.assigneeId);
    }
  }

  updateDomainRef.value?.initDomain();

  if (props.files?.length > 0) {
    formData.value.files = [];
    formData.value.attachments = [];
    formData.value.notes = [];
    onAddFiles(props.files);
  }
  if (props.descriptionTask) formData.value.description = props.descriptionTask;
  if (props.taskName) formData.value.name = props.taskName;
  if (props.isGlobal && props.assigneeId)
    formData.value.assigneeId = props.assigneeId as any;
  if (props.groupId) formData.value.groupId = props.groupId as any;
  formData.value.isPrivate = !defaultPublicTask.value;

  if (formData.value?.taskSchedule?.finishItToday) onFinishItToday(true);
};

const webTaskUploadContentTypes = computed<any>(() => {
  return remoteConfigStore().webTaskUploadContentTypes;
});

onMounted(() => {
  initData();
});

onUnmounted(() => {
  onSaveCacheForm();
});

watch(
  () => props.assigneeId,
  (newValue) => {
    if (newValue) {
      // console.log('watch, assigneeId', props.assigneeId);
      // formData.value.assigneeId = props.assigneeId as any;
      initData();
    }
  }
);
watch(
  () => props.groupId,
  (newValue) => {
    if (newValue) {
      formData.value.groupId = props.groupId as any;
      initData();
    }
  }
);

watch(
  () => [props.descriptionTask, props?.taskName],
  (newValue) => {
    if (newValue) {
      formData.value.description = props.descriptionTask || '';
      formData.value.name = props.taskName || '';
      initData();
    }
  }
);

// let autoReloadUserDayInfo;
watch(
  () => props.isOpenFull,
  () => {
    if (props.isOpenFull) {
      // autoReloadUserDayInfo = setInterval(() => {
      //   getUserDayInfo();
      // }, 60 * 60 * 1000);
      initData();
      if (childrenTabActive.value == EFilterTaskDefault.DO_TODAY) {
        formData.value.taskSchedule.doItToday = true;
      }
    } else {
      // clearInterval(autoReloadUserDayInfo);
      // 4.1 Save form data to store
      onSaveCacheForm();
    }
  }
);
// export const formData = ref({});
const changeAssignRef = ref<any>(null);
const onResetForm = () => {
  isValidate.value = false;
  formData.value = new TaskCreateUpdatePayload({
    assigneeId: props.assigneeId,
    groupId: props.groupId,
    isPrivate: !defaultPublicTask.value,
    taskLife: TaskLife.Todo,
  });
  console.log('🚀 Hyrin ~ onResetForm ~ formData.value:', formData.value);
  isOnFinishing.value = false;
  isImportant.value = false;

  setTimeout(() => {
    changeAssignRef.value?.initData && changeAssignRef.value?.initData();
    updateDomainRef.value?.initDomain && updateDomainRef.value?.initDomain();
  });

  // 2. reset cached
  onSaveCacheForm();
};

const onCancelForm = () => {
  onResetForm();
  isOpenCreateTaskLocal.value = false;
  isOpenCreateTaskGroup.value = false;
  isOpenCreateTaskGlobal.value = false;
};

// Name
const onFocusInputName = () => {
  isInputName.value = true;
  onOpenForm();
};

const onPasteImage = async (event) => {
  if (isCreateNote.value) {
    return;
  }
  var files = (event.clipboardData || event.originalEvent.clipboardData).files;

  // 1. if image file => open note
  if (files?.length == 1 && files[0].type.includes('image')) {
    onCreateNote(true, files);
    return;
  }
  // 2. If not image file => attachment
  if (files?.length > 0) {
    formData.value.addFiles(files);
    emit('onOpenInterface');
  }
};
// Submit form
const isValidate = ref(false);
const isLoadingSubmitButton = ref(false);

const onSubmitForm = async () => {
  if (!props.isOpenFull) return;
  if (
    addNewDomainParams.value.addNewDomainType ||
    addNewDomainParams.value.addNewProjectType ||
    addNewDomainParams.value.addNewSubProjectType
  )
    await updateDomainBefore();

  // old logic
  if (
    !formData.value?.name?.trim() ||
    !formData.value?.domainId ||
    (!formData.value?.assigneeId && !formData.value?.groupId) ||
    formData.value?.urgency == null ||
    formData.value?.taskSchedule?.scheduleOptionKey == null ||
    isLoadingSubmitButton.value
  ) {
    isValidate.value = true;
    return;
  }

  isValidate.value = false;

  try {
    isLoadingSubmitButton.value = true;
    const newTask = await formData.value.create();

    resetFormAfterCreate();
    emit('onCreateSuccess', newTask);
  } catch (error) {
    console.log(error);
  } finally {
    isLoadingSubmitButton.value = false;
  }
};
const resetFormAfterCreate = () => {
  formData.value = new TaskCreateUpdatePayload({
    assigneeId: formData.value.assigneeId,
    groupId: formData.value.groupId,
    domainId: formData.value.domainId,
    domainName: formData.value.domainName,
    projectId: formData.value.projectId,
    projectName: formData.value.projectName,
    subprojectId: formData.value.subprojectId,
    subprojectName: formData.value.subprojectName,
    taskType: formData.value.taskType,
    planType: formData.value.planType,
    estimationTime: 1,
    priority: formData.value.priority == 1 ? 1 : null,
    taskSchedule: formData.value.taskSchedule,
    scheduleTime: formData.value.scheduleTime,
    isPrivate: !defaultPublicTask.value,
    urgency: null,
    taskLife: TaskLife.Todo,
  });
  isOnFinishing.value = false;
  isImportant.value = false;

  const inputImage: HTMLInputElement = document.getElementById(
    taskCreateNewInputFileId.value
  ) as HTMLInputElement;
  if (inputImage) inputImage.value = '';

  // 2. reset cached
  onSaveCacheForm();
};
const onSaveCacheForm = () => {
  let catchData = {} as any;
  if (props.isGroup) {
    catchData = cachedFormData.value[`group_${props.assigneeId}`];
  } else
    catchData = cachedFormData.value[`user_${props.assigneeId}`] || {
      assigneeId: props.assigneeId,
      taskLife: TaskLife.Todo,
    };

  formData.value.taskSchedule.doItToday = catchData?.taskSchedule?.doItToday;

  // console.log('catchData', catchData);
  const key = props?.isGroup
    ? `group_${props?.groupId}`
    : `user_${props?.assigneeId}`;
  _taskCreateFormStore.setCachedFormData(key, formData.value);
};
const onLoadCacheUserForm = (selectedUserId) => {
  if (!selectedUserId) return;

  return new Promise((resolve) => {
    const catchData = cachedFormData.value[`user_${selectedUserId}`] || {
      assigneeId: selectedUserId,
      taskLife: TaskLife.Todo,
    };
    formData.value = new TaskCreateUpdatePayload({
      ...catchData,
    });
    // console.log('🚀 Hyrin ~ returnnewPromise ~ formData.value', formData.value);
    resolve(true);
  });
};
const onLoadCacheGroupForm = (selectedGroupId) => {
  if (!selectedGroupId) return;

  return new Promise((resolve) => {
    const catchData = cachedFormData.value[`group_${selectedGroupId}`];

    formData.value = new TaskCreateUpdatePayload({
      ...catchData,
      groupId: selectedGroupId,
      taskLife: TaskLife.Todo,
    });

    resolve(true);
  });
};

const isGroupTask = computed(() => !!formData.value?.groupId);

const updateDomainRef = ref(null as any);
const onSaveAssign = async (group, user) => {
  // isOpenChangeAssign.value = false;
  const groupId = group?.id;
  const userId = user?.userId;
  const allowChange = await checkAllowSetPrivateTask(false, groupId, userId);
  //  Need to refator the logic change assigned in task for cleaning code
  if (!allowChange) return;

  formData.value.assigneeId = userId || null;
  formData.value.groupId = groupId || null;
  formData.value.taskLife = TaskLife.Todo;
  formData.value.taskSchedule = getDefaultTaskSchedule();
  updateDomainRef.value?.updateDomainAfterAssigneeChanged(userId, groupId);
};

const checkAllowSetPrivateTask = async (
  isSetPrivateAction,
  groupId,
  userId
) => {
  let allowed = true;
  //  Nếu task chưa private:
  //  -> set private trong group: Không cho phép
  if (isSetPrivateAction) {
    if (formData.value.groupId && formData.value.groupId > 0) {
      allowed = false;
      formData.value.isPrivate = false;
      settingGlobalModal({
        type: 'notification',
        title: '',
        content: `Private task only set in personal task, cannot set in group task`,

        confirmable: true,
      });
      await ask();
    } else {
      const previousState = !formData.value.isPrivate;

      const currentId = getCurrentUserId();
      if (!!formData.value.creatorId && formData.value.creatorId != currentId) {
        formData.value.isPrivate = previousState;
        settingGlobalModal({
          type: 'notification',
          title: '',
          content:
            translate('COMMON_LABEL_QUESTION_ALERT_ONLY_CREATOR_SET_PRIVATE') ||
            `Only the creator can set private state of this task!`,

          confirmable: true,
        });
        await ask();
      } else {
        settingGlobalModal({
          type: 'confirm',
          title: '',
          content:
            translate('COMMON_LABEL_QUESTION_SET_PRIVATE_TASK') ||
            `Do you want to make this task to private?`,
          confirmable: true,
          closeable: true,
        });
        const confirmed = !previousState ? await ask() : true;
        if (confirmed) {
          formData.value.isPrivate = !previousState;
        } else {
          formData.value.isPrivate = previousState;
        }
      }
    }
  }
  //  Nếu task đang là private:
  else if (formData.value.isPrivate) {
    //  -> set group cho task: show cảnh báo xoá private:
    if (groupId > 0) {
      // formData.value.isPrivate = false;
      settingGlobalModal({
        type: 'confirm',
        title: '',
        content:
          translate('TASK_CREATE_FORM_CANNOT_SET_PRIVATE') ||
          'Cannot set private task for group. Do you want to unset private task?',
        confirmable: true,
        closeable: true,
      });

      const confirmed = await ask();
      allowed = true;
      if (confirmed) {
        formData.value.isPrivate = false;
      } else {
        formData.value.groupId = null;
        formData.value.assigneeId = userId || getCurrentUserId();
        //  temporary set this to false to check in onSaveAssign func
        allowed = false;
      }
    }
    //  -> neu edit 1 task private, chuyen assign sang nguoi khac thi can thong bao
    else if (
      !!userId &&
      userId != formData.value.assigneeId &&
      formData.value.id > 0
    ) {
      settingGlobalModal({
        type: 'confirm',
        title: '',
        content:
          !formData.value.creatorId ||
          formData.value.creatorId == getCurrentUserId()
            ? translate('COMMON_LABEL_QUESTION_CHANGE_PRIVATE_TASK') ||
              `The previous assignee will not allowed to view this private task. Do you want to continue?`
            : translate('COMMON_LABEL_QUESTION_SAVE_PRIVATE_TASK') ||
              'You will not allowed to see this private task if you save change your updated. Do you want to continue?',
        confirmable: true,
        closeable: true,
      });
      const confirmed = await ask();
      if (confirmed) {
        formData.value.assigneeId = userId;
        allowed = true;
      } else {
        allowed = false;
      }
    }
  }

  return allowed;
};

const onSetPrivateTask = async () => {
  await checkAllowSetPrivateTask(
    true,
    formData.value.groupId,
    formData.value.assigneeId
  );
};

const currentFileIndex = ref(0);
const isPreview = ref(false);
const onOpenFiles = (index, file) => {
  // const file = attachments.value[index];
  if (file.isNote) {
    onEditNote(file);
    return;
  }

  currentFileIndex.value = index;
  isPreview.value = true;
};

const onChangeTaskState = (data) => {
  formData.value.taskLife = data;
};

const onChangeUrgency = (data) => {
  formData.value.changeUrgency(data);
};

const isShowScheduleTime = ref(false);
let scheduleTimeoutFn;
const showSelectedScheduleTime = () => {
  if (!formData.value?.scheduleTime) return;

  if (scheduleTimeoutFn) clearTimeout(scheduleTimeoutFn);
  isShowScheduleTime.value = true;

  scheduleTimeoutFn = setTimeout(() => {
    isShowScheduleTime.value = false;
  }, 2 * 1000);
};
const onSaveDeadline = (deadlineDate: IUpdateDeadlineForm) => {
  formData.value.changeDeadline(deadlineDate);

  showSelectedScheduleTime();
};

const onUpdateStartTime = async (startTime) => {
  formData.value.changeStartEndTime({
    startTime,
    changeStartTimeOnly: true,
  });
};
const changeDeadlineTime = async ({ dateTime, timezone }) => {
  formData.value.scheduleTimezone = timezone;
  formData.value.scheduleByHour = true;
  formData.value.scheduleTime = dateTime;
};

const onDoItToday = (value) => {
  formData.value.doItToday(value);

  showSelectedScheduleTime();
};

const updateDeadlineRef = ref<any>(null);
const onFinishItToday = async (value) => {
  formData.value.finishItToday(value);
  if (
    value &&
    updateDeadlineRef.value?.updateScheduleTime &&
    typeof updateDeadlineRef.value?.updateScheduleTime == 'function'
  )
    updateDeadlineRef.value?.updateScheduleTime(ScheduleDayOptions.OnDay);

  showSelectedScheduleTime();
};

const onClickSaveDescription = () => {
  onSaveComment((data) => {
    formData.value.description = data?.content;
  });
};

const onSaveDomain = ({
  domainId,
  domainName,
  projectId,
  projectName,
  subprojectId,
  subprojectName,
}) => {
  formData.value.domainId = domainId;
  formData.value.domainName = domainName;
  formData.value.projectId = projectId;
  formData.value.projectName = projectName;
  formData.value.subprojectId = subprojectId;
  formData.value.subprojectName = subprojectName;
};

const onAddNewDomain = (updateData) => {
  addNewDomainParams.value = {
    ...addNewDomainParams.value,
    ...updateData,
  };
};

const elementId = `TASK_CREATE_FORM_${Date.now()}`;

const isEditCollaborator = ref<boolean>(false);
const manageCollaboratorRef = ref<any>(null);

const onCloseEditCollaborator = () => {
  manageCollaboratorRef.value?.onCloseEditMode();
};

const onChangeCollaborator = async (collaborators) => {
  const payload = collaborators?.map((user) => {
    return {
      ...user,
      memberUserId: user?.id || user?.userId,
    };
  });
  formData.value.addCollaboratorsInTask(payload);
};
const onRemoveCollaborator = async (userId) => {
  formData.value.deleteCollaboratorsInTask(userId);
};

const allAttachments = computed<any[]>(() => {
  return arrayOrderBy(
    [...formData.value.files, ...formData.value.notes],
    [(data) => dayjs(data?.creationTime)],
    [' desc']
  );
});

const onRemoveScheduleTime = async ({ dateTime }) => {
  formData.value.scheduleTimezone = '';
  formData.value.scheduleByHour = false;
  if (
    updateDeadlineRef.value?.onChooseDateSpecific &&
    typeof updateDeadlineRef.value?.onChooseDateSpecific == 'function'
  )
    updateDeadlineRef.value?.onChooseDateSpecific(dateTime);
  else formData.value.scheduleTime = dateTime;
};
</script>

<template>
  <div
    :id="elementId"
    v-drop-zone="{ isDragging: dragOver, onDrop: onDropImage }"
    v-element-active="{
      id: elementId,
      disabled: !isOpenFull,
      zIndex: 'z-50',
    }"
    class="
      ease-out
      flex
      absolute
      right-0
      w-full
      transition
      duration-300
      transform
      bottom-0
      text-sm
    "
    @click="onOpenForm"
    @paste="onPasteImage"
  >
    <UploadDropZone
      v-if="isDraging"
      class="absolute w-full top-0 left-0 right-0 bottom-0 z-20"
    />
    <div
      class="
        max-h-full
        fixed
        w-full
        inset-x-0
        lg:static lg:inset-0
        rounded-xl rounded-tr-none
      "
      :class="isOpenFull ? 'bg-gray-100' : 'bg-current-50'"
    >
      <div class="flex flex-col w-full h-full relative">
        <div
          v-if="isLoadingDataCache"
          class="
            inset-0
            top-0
            w-full
            flex-center flex-col
            h-full
            absolute
            bg-gray-300 bg-opacity-50
            z-50
          "
        >
          <syn-icon
            name="spinner"
            class="animate-spin"
            custom-class="w-5 h-5 fill-current"
          />
          <span class="text-current-600 animate-pulse py-1">Loading...</span>
        </div>

        <!-- Header zone -->
        <div
          v-permission-model
          class="
            absolute
            right-0
            h-10
            w-max
            p-2
            px-4
            rounded-t-xl
            flex-center
            cursor-pointer
          "
          :class="
            isOpenFull
              ? 'bg-gray-100 -top-10'
              : isShowApart
              ? 'bg-current-50 text-current-800 -top-10'
              : 'bg-current-500 text-white -top-10 fill-white shadow-xl'
          "
        >
          <div
            v-if="
              (!isOpenFull && !isShowApart) ||
              (!formData?.groupId && !formData?.assigneeId)
            "
            class="flex-center space-x-2 font-semibold"
          >
            <SynIcon custom-class="w-3 h-3" name="plus" />
            <span>
              {{ $t('COMMON_LABEL_CREATE_TASK') || 'Create task' }}
            </span>
          </div>
          <template v-else>
            <div v-if="formData?.groupId" class="flex-center space-x-2">
              <span class="text-xs text-gray-500">
                {{ $t('COMMON_LABEL_GROUP') || 'Group' }}
              </span>
              <GroupById
                v-if="formData?.groupId"
                :group-id="formData?.groupId"
                class="px-2 text-xs font-semibold"
              />
            </div>
            <div v-if="formData?.assigneeId" class="flex-center space-x-2">
              <span class="text-xs text-gray-500">
                {{ $t('TASK_LABEL_ASSIGNEE') || 'Responsible' }}
              </span>
              <UserById
                v-if="formData?.assigneeId"
                class="text-xs font-semibold"
                :user-id="formData?.assigneeId"
                hidden-name
              />
            </div>
          </template>
        </div>

        <!-- Body zone -->
        <div
          v-if="isShowApart || isOpenFull"
          class="grid grid-cols-1 md:grid-cols-2"
        >
          <!-- Left zone -->
          <div class="w-full flex flex-col p-4 bg-current-50 rounded-t-xl">
            <div class="flex flex-col" style="margin-left: 0px">
              <div class="w-full flex items-center">
                <SynTag
                  v-if="!isInputName && formData.name?.trim()"
                  class="
                    bg-white
                    w-full
                    whitespace-pre-wrap
                    max-h-20
                    overflow-y-auto
                    border-2 border-transparent
                  "
                  @click="isInputName = true"
                >
                  <div class="w-full font-semibold">
                    <span>
                      {{ formData.name }}
                    </span>
                  </div>
                </SynTag>
                <SynInput
                  v-else
                  ref="nameInputRef"
                  v-model="formData.name"
                  :placeholder="
                    $t('TASK_CREATE_FORM_TASK_CREATE_FORM_NAME') || 'Task name'
                  "
                  max-length="200"
                  :autofocus="isOpenFull"
                  @focus="onFocusInputName"
                  @blur="isInputName = false"
                />
                <SynTag
                  v-if="!isOpenFull"
                  v-vig-tooltip="$t('COMMON_LABEL_WRITE') || 'Write'"
                  custom-class="h-8 w-8 my-1 mx-1 flex-center text-gray-500 bg-white rounded-md hover:text-current hover:fill-current cursor-pointer"
                  @click="onOpenComment(formData.description)"
                >
                  <SynIcon name="comment" class="text-current" />
                </SynTag>
                <SynTag
                  v-if="!isOpenFull"
                  v-vig-tooltip="$t('COMMON_LABEL_DESIGN') || 'Design'"
                  custom-class="h-8 w-8 my-1 mr-1 flex-center text-gray-500 bg-white rounded-md hover:text-current hover:fill-current cursor-pointer"
                  @click="onCreateNote(false)"
                >
                  <SynIcon name="noter" is-active class="fill-orange-500" />
                </SynTag>
                <SynTag
                  v-if="!isOpenFull"
                  v-vig-tooltip="
                    $t('TASK_CREATE_FORM_LABEL_AUDIO') || 'Recoder'
                  "
                  custom-class="h-8 w-8 my-1 mr-1 flex-center bg-white rounded-md hover:text-current hover:fill-current cursor-pointer"
                  @click="onCreateAudio"
                >
                  <SynIcon
                    is-active
                    name="microphone"
                    class="fill-blue-500 text-current-500"
                  />
                </SynTag>
              </div>
              <span v-if="isValidate && !formData.name" class="text-red-500">
                *
                {{
                  $t('TASK_CREATE_FORM_TASK_CREATE_FORM_IS_REQUIRED') ||
                  'is required'
                }}
              </span>
            </div>
            <div v-if="isOpenFull" class="flex items-center flex-wrap">
              <div class="flex-center space-x-1 mt-1 mr-1">
                <SynTag
                  v-vig-tooltip="$t('COMMON_LABEL_WRITE') || 'Write'"
                  custom-class="h-10 w-max text-gray-500 flex-center bg-white rounded-md hover:text-current hover:fill-current cursor-pointer"
                  @click="onOpenComment(formData.description)"
                >
                  <div class="w-full h-full px-4 space-x-2 flex-center">
                    <SynIcon name="comment" class="text-current" />
                    <span>
                      {{ $t('COMMON_LABEL_WRITE') || 'Write' }}
                    </span>
                  </div>
                </SynTag>
                <SynTag
                  v-vig-tooltip="$t('COMMON_LABEL_DESIGN') || 'Design'"
                  custom-class="h-10 w-max text-gray-500 flex-center bg-white rounded-md hover:text-current hover:fill-current cursor-pointer"
                  @click="onCreateNote(false)"
                >
                  <div class="w-full h-full px-4 space-x-2 flex-center">
                    <SynIcon name="noter" is-active class="fill-orange-500" />
                    <span>
                      {{ $t('COMMON_LABEL_DESIGN') || 'Design' }}
                    </span>
                  </div>
                </SynTag>
                <SynTag
                  v-vig-tooltip="
                    $t('COMMON_LABEL_DRAG_AND_DROP_FILE_HERE') || 'Attachment'
                  "
                  custom-class="h-10 w-10 flex-center bg-white rounded-md "
                >
                  <div class="w-full h-full">
                    <label
                      :for="taskCreateNewInputFileId"
                      class="
                        absolute
                        w-full
                        h-full
                        flex-center
                        cursor-pointer
                        hover:text-current hover:fill-current
                      "
                    >
                      <SynIcon
                        name="attach"
                        class="fill-blue-500 text-blue-500"
                      />
                    </label>
                  </div>
                </SynTag>

                <SynTag
                  v-vig-tooltip="
                    $t('TASK_CREATE_FORM_LABEL_AUDIO') || 'Recorder'
                  "
                  custom-class="h-10 w-10 flex-center bg-white rounded-md hover:text-current hover:fill-current cursor-pointer"
                  @click="onCreateAudio"
                >
                  <SynIcon
                    is-active
                    name="microphone"
                    color="blue"
                    class="fill-blue-500 text-current-500"
                  />
                </SynTag>
              </div>
              <div v-permission-model>
                <SynTag
                  v-if="isOpenFull && !isHiddenAssign"
                  v-permission="'FunctionTaskAssign'"
                  class="bg-white w-max max-w-full mt-1 mr-1 flex-1"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <div class="flex items-center space-x-4">
                    <ChangeAssign
                      ref="changeAssignRef"
                      v-permission="'FunctionTaskAssign'"
                      is-update-instant
                      can-remove-all
                      :group-id="formData.groupId"
                      :user-id="formData.assigneeId"
                      @on-save="onSaveAssign"
                    />
                  </div>
                </SynTag>
              </div>
              <SynTag
                v-if="
                  isOpenFull &&
                  multipleAssigneeSetting?.systemEnable &&
                  multipleAssigneeSetting?.organizationEnable
                "
                :custom-class="isEditCollaborator ? '' : 'h-10'"
                class="bg-white w-max max-w-full mt-1"
              >
                <div class="flex items-center space-x-4 px-2">
                  <div class="flex items-center space-x-4">
                    <div class="flex flex-wrap">
                      <label class="font-medium text-current-800">
                        {{ $t('COMMON_LABEL_COLLABORATORS') }}
                      </label>
                    </div>
                  </div>
                  <ManageCollaborator
                    ref="manageCollaboratorRef"
                    v-model:is-edit-mode="isEditCollaborator"
                    class="flex-1"
                    :group-id="formData.groupId || 0"
                    :assignee-id="formData.assigneeId || 0"
                    :collaborators="formData.collaborators"
                    @on-add="onChangeCollaborator"
                    @on-remove="onRemoveCollaborator"
                  />
                </div>
              </SynTag>
            </div>
            <SynTag
              v-if="formData.description && isOpenFull"
              class="bg-white w-max max-w-full mt-1"
            >
              <div class="w-full flex items-center space-x-4 px-2">
                <div class="font-bold text-current-800">
                  {{ $t('COMMON_LABEL_WRITE') || 'Write' }}
                </div>
                <div
                  v-if="useCkeditorWithMention"
                  class="
                    flex
                    items-center
                    whitespace-pre-wrap
                    max-h-96
                    overflow-y-auto
                    small-scrollbar
                  "
                  @click.stop="onOpenComment(formData.description)"
                >
                  {{ formData.description }}
                </div>
                <div
                  v-else
                  class="
                    task-detail_description
                    whitespace-pre-wrap
                    max-h-96
                    overflow-y-auto
                  "
                  @click.stop="onOpenComment(formData.description)"
                  v-html="formData.description"
                ></div>
              </div>
            </SynTag>
            <div v-permission-model>
              <SynTag
                v-if="isOpenFull"
                class="bg-white w-max max-w-full mt-1"
                style="min-height: 3.5em"
              >
                <div class="flex items-center space-x-4 px-1">
                  <div class="flex-center space-x-2">
                    <span class="font-medium text-current-800">
                      {{ $t('TASK_CREATE_FORM_DOMAIN') }}
                    </span>
                    <ExplainIcon type="domain" />
                  </div>
                  <div class="flex flex-col">
                    <UpdateDomain
                      ref="updateDomainRef"
                      :task-detail="formData"
                      createable
                      @save="onSaveDomain"
                      @on-add-new-domain="onAddNewDomain"
                    />
                    <span
                      v-if="isValidate && !formData.domainId"
                      class="text-red-500"
                    >
                      *
                      {{
                        $t('TASK_CREATE_FORM_TASK_CREATE_FORM_IS_REQUIRED') ||
                        'is required'
                      }}
                    </span>
                  </div>
                </div>
              </SynTag>
            </div>

            <div
              class="overflow-y-auto small-scrollbar"
              style="max-height: 25vh"
            >
              <div
                v-if="isOpenFull && allAttachments?.length > 0"
                class="w-full flex items-center justify-start flex-wrap"
              >
                <!-- <template v-if="attachedFiles?.length > 0">
                  <UploadPreview
                    v-for="(file, index) in attachedFiles"
                    :key="file"
                    :file="file"
                    @on-select="onOpenFiles(index)"
                    @remove="onRemoveAttachments(file)"
                  />
                </template> -->
                <UploadPreview
                  v-for="(file, index) in allAttachments"
                  :key="index"
                  :is-note="file.isNote"
                  :file="file"
                  @on-select="onOpenFiles(index, file)"
                  @remove="
                    file.isNote ? onRemoveNote(file) : onRemoveAttachments(file)
                  "
                />
                <div
                  class="
                    w-16
                    h-16
                    flex-center flex-col
                    cursor-pointer
                    border border-dashed border-blue-300
                    rounded-md
                    shadow
                    hover-to-show__parent
                    relative
                  "
                >
                  <SynIcon
                    name="plus"
                    class="fill-blue-500 text-blue-500"
                    custom-class="h-6 w-6"
                  />
                  <div
                    class="
                      absolute
                      w-full
                      h-full
                      flex-center flex-col
                      cursor-pointer
                      rounded-md
                      hover-to-show__children
                      bg-white
                    "
                  >
                    <div class="flex-center w-full h-1/2">
                      <label
                        :for="taskCreateNewInputFileId"
                        class="
                          w-1/2
                          h-full
                          hover:bg-current-100
                          flex-center
                          cursor-pointer
                        "
                        :title="
                          $t('COMMON_LABEL_DRAG_AND_DROP_FILE_HERE') ||
                          'Attachment'
                        "
                      >
                        <SynIcon name="attach" class="fill-current" />
                      </label>
                      <div
                        class="w-1/2 h-full hover:bg-orange-100 flex-center"
                        :title="
                          $t('TASK_CREATE_FORM_LABEL_ADD_NOTE') || 'Add note'
                        "
                        @click="onCreateNote(false)"
                      >
                        <SynIcon
                          name="noter"
                          is-active
                          class="fill-orange-500"
                          custom-class="h-4 w-4"
                        />
                      </div>
                    </div>
                    <div class="flex-center w-full h-1/2">
                      <div
                        class="w-1/2 h-full hover:bg-blue-100 flex-center"
                        :title="
                          $t('TASK_DETAIL_LABEL_ADD_AUDIO') || 'Add audio'
                        "
                        @click="onCreateAudio"
                      >
                        <SynIcon
                          is-active
                          name="microphone"
                          class="fill-blue-500"
                        />
                      </div>
                      <div
                        class="w-1/2 h-full hover:bg-blue-100 flex-center"
                        :title="
                          $t('TASK_CREATE_FORM_LABEL_COMMENT') || 'Add comment'
                        "
                        @click="onOpenComment(formData.description)"
                      >
                        <SynIcon
                          is-active
                          name="comment"
                          class="fill-current"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="relative w-full">
              <SynIcon
                class="absolute bg-current -right-8 -bottom-4"
                has-action
                :name="isShowMore ? 'chevron-double-down' : 'chevron-double-up'"
                @click="isShowMore = !isShowMore"
              />
            </div> -->
          </div>
          <!-- Right zone -->
          <div class="flex flex-col p-4 w-full">
            <div class="flex justify-between items-center w-full space-x-1">
              <!-- Urgency -->
              <SynTag
                class="bg-white w-max"
                custom-class="px-2"
                style="min-height: 2.5rem"
              >
                <div class="flex items-center space-x-2">
                  <div v-if="isOpenFull" class="font-medium text-current-800">
                    {{ $t('TASK_TABLE_LABEL_URGENCY') || 'Urgency' }}
                  </div>

                  <div class="flex flex-col">
                    <div
                      class="flex items-center"
                      :class="isOpenFull ? 'flex-wrap' : ''"
                    >
                      <SynRadioTag
                        v-for="item in urgencyList"
                        :key="item"
                        v-model="formData.urgency"
                        :value="item.key"
                        radio-name="task-create-form_priority"
                        style="margin: 0.9px"
                        @change="onChangeUrgency"
                      >
                        <SynTag
                          size="circle-small"
                          custom-class="p-1 border border-current-100"
                        >
                          <PriorityTask :urgency="item.key" size="mini" />
                        </SynTag>
                      </SynRadioTag>
                    </div>
                    <span
                      v-if="isValidate && formData.urgency == null"
                      class="text-red-500"
                    >
                      *
                      {{
                        $t('TASK_CREATE_FORM_TASK_CREATE_FORM_IS_REQUIRED') ||
                        'is required'
                      }}
                    </span>
                  </div>
                </div>
              </SynTag>

              <!-- State -->
              <SynTag
                class="w-max"
                :class="isOpenFull ? 'flex bg-white ' : 'hidden 2xl:flex'"
                custom-class="px-1 "
                style="min-height: 2.5rem"
              >
                <div class="flex items-center space-x-2 px-1">
                  <div v-if="isOpenFull" class="flex-center space-x-2">
                    <span class="font-medium text-current-800">
                      {{ $t('COMMON_LABEL_STATUS') || 'Status' }}
                    </span>
                    <ExplainIcon type="task-state" />
                  </div>
                  <TaskState
                    :task-life="formData?.taskLife"
                    mode="create"
                    @change-state="onChangeTaskState"
                  />
                </div>
              </SynTag>

              <!-- Submit button -->
              <SynButton
                v-if="!isOpenFull"
                v-vig-tooltip="$t('COMMON_LABEL_CREATE') || 'Cancel'"
                :is-loading="isLoadingSubmitButton"
                :label="$t('COMMON_LABEL_CREATE') || 'Cancel'"
              />
            </div>

            <!-- Deadline -->
            <SynTag
              v-if="isOpenFull && formData.assigneeId"
              class="bg-white mt-1"
            >
              <div class="flex flex-col space-y-2 w-full px-1">
                <div class="flex items-center space-x-4 w-full">
                  <div
                    class="flex flex-col items-start justify-center space-y-2"
                  >
                    <AtomSwitch
                      v-model="formData.taskSchedule.doItToday"
                      @change="onDoItToday"
                    >
                      <span class="font-semibold text-current-800">
                        {{
                          $t('TASK_CREATE_FORM_DOING_TODAY') || 'Do it today'
                        }}
                      </span>
                    </AtomSwitch>

                    <AtomSwitch
                      v-model="formData.taskSchedule.finishItToday"
                      @change="onFinishItToday"
                    >
                      <span class="font-semibold text-current-800">
                        {{
                          $t('TASK_CREATE_FORM_FINISH_TODAY') ||
                          'Finish it today'
                        }}
                      </span>
                    </AtomSwitch>
                  </div>
                  <div class="flex-1 flex flex-col">
                    <div class="bg-current-50 p-2 rounded">
                      <div class="flex items-center space-x-2 px-2">
                        <div class="font-medium text-current-800">
                          {{ $t('TASK_TABLE_LABEL_DEADLINE') }}
                        </div>
                        <div class="flex-1 flex flex-wrap">
                          <UpdateDeadline
                            ref="updateDeadlineRef"
                            :schedule-key="
                              formData?.taskSchedule?.scheduleOptionKey
                            "
                            :other-time="formData.taskSchedule.otherTime"
                            :schedule="formData.scheduleTime"
                            :schedule-timezone="formData.scheduleTimezone"
                            :user-id="formData.assigneeId"
                            :group-id="formData.groupId"
                            :start-time="formData.startTime"
                            @save="onSaveDeadline"
                            @on-change-schedule-time="
                              (value) => (formData.scheduleTime = value)
                            "
                            @on-change-start-time="onUpdateStartTime"
                          />

                          <UpdateDeadlineTime
                            :schedule-time="formData?.scheduleTime"
                            :schedule-by-hour="formData?.scheduleByHour"
                            :schedule-timezone="formData?.scheduleTimezone"
                            :my-timezone="myTimezone"
                            @on-change="changeDeadlineTime"
                            @on-remove-time="onRemoveScheduleTime"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SynTag>
            <SynTag
              v-if="isOpenFull && !formData.assigneeId"
              class="bg-white mt-1 w-max"
            >
              <div class="flex items-center space-x-2 px-2">
                <div class="font-bold text-current-800">
                  {{ $t('TASK_TABLE_LABEL_DEADLINE') }}
                </div>
                <div class="flex-1 flex items-center gap-1 flex-wrap">
                  <v-date-picker
                    v-model="formData.scheduleTime"
                    :locale="$locale"
                    color="current"
                    :attributes="[
                      {
                        key: 'today',
                        dot: {
                          color:
                            dayjs(new Date()).format('MM-DD-YYYY') ==
                            dayjs(formData.scheduleTime).format('MM-DD-YYYY')
                              ? 'yellow'
                              : 'current',
                          class:
                            dayjs(new Date()).format('MM-DD-YYYY') ==
                            dayjs(formData.scheduleTime).format('MM-DD-YYYY')
                              ? 'mb-1 z-70'
                              : '',
                        },
                        dates: new Date(),
                      },
                    ]"
                    :model-config="dateConfig"
                    :popover="{ visibility: 'click' }"
                    :first-day-of-week="2"
                  >
                    <template #default="{ inputValue, inputEvents }">
                      <div class="relative">
                        <input
                          type="text"
                          class="vig-input"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                        <button
                          v-if="formData.scheduleTime"
                          class="
                            absolute
                            top-1/2
                            right-1
                            -mt-3
                            w-6
                            h-6
                            flex-center
                            rounded-full
                            hover:bg-gray-100
                          "
                          @click="formData.scheduleTime = ''"
                        >
                          <SynIcon name="Close" />
                        </button>
                        <SynIcon
                          v-else
                          class="
                            absolute
                            top-1/2
                            right-1
                            -mt-3
                            w-6
                            h-6
                            text-gray-500
                          "
                          name="calendar"
                        />
                      </div>
                    </template>
                  </v-date-picker>

                  <UpdateDeadlineTime
                    :schedule-time="formData?.scheduleTime"
                    :schedule-by-hour="formData?.scheduleByHour"
                    :schedule-timezone="formData?.scheduleTimezone"
                    :my-timezone="myTimezone"
                    @on-change="changeDeadlineTime"
                    @on-remove-time="onRemoveScheduleTime"
                  />
                </div>
              </div>
            </SynTag>
            <div v-permission-model class="flex items-center space-x-1">
              <SynTag
                v-if="isOpenFull && !isGroupTask"
                class="bg-white w-max max-w-full mt-1 h-10"
              >
                <div class="flex items-center space-x-4 px-2">
                  <div class="flex items-center space-x-4">
                    <SynIcon name="private" class="fill-current" />
                    <div class="flex flex-wrap">
                      <label class="font-medium text-current-800">
                        {{ $t('TASK_CREATE_MAKE_PRIVATE') }}
                      </label>
                      <!-- <label class="text-xs w-full text-gray-500">
                        When a group is set to private, it can only be viewed or
                        joined by member.
                      </label> -->
                    </div>
                  </div>
                  <AtomSwitch
                    v-model="formData.isPrivate"
                    @change="onSetPrivateTask"
                  />
                </div>
              </SynTag>
            </div>
          </div>
        </div>

        <!-- Footer zone -->
        <div
          v-if="isOpenFull"
          class="flex items-center justify-between px-4 py-2 w-full bg-white"
        >
          <div class="flex-center space-x-2 pr-5">
            <SynButton
              :label="$t('COMMON_LABEL_RESET') || 'Reset'"
              class="bg-gray-100"
              color="gray"
              type-text
              name-icon="reload"
              @click.stop="onResetForm"
            />
            <div v-if="isShowScheduleTime" class="flex-center space-x-2">
              <span>{{ $t('TASK_TABLE_LABEL_DEADLINE') }}</span> :
              <span class="font-semibold text-current-800">
                {{ formData.scheduleTime }}
              </span>
            </div>
          </div>
          <div class="flex-center space-x-2">
            <SynButton
              :label="$t('COMMON_LABEL_CANCEL') || 'Cancel'"
              color="gray"
              type-text
              @click.stop="onCancelForm"
            />
            <SynButton
              v-vig-tooltip="$t('COMMON_LABEL_CREATE') || 'Create'"
              :is-loading="isLoadingSubmitButton"
              @click="onSubmitForm"
            >
              <div class="flex items-center justify-between px-1 space-x-1">
                <span class="text-medium text-white">{{
                  $t('COMMON_LABEL_CREATE') || 'Create'
                }}</span>
                <div
                  v-if="taskStates[formData.taskLife]?.key !== TaskLife.Created"
                  class="flex items-center space-x-1 italic rounded-full px-1"
                  :class="`${taskStates[formData.taskLife]?.background} ${
                    taskStates[formData.taskLife]?.color
                  }`"
                  style="font-size: 11px"
                >
                  <span
                    class="p-0.5 rounded-full"
                    :class="`bg-${taskStates[formData.taskLife]?.colorKey}`"
                  ></span>
                  <span>{{ $t(taskStates[formData.taskLife]?.nameCode) }}</span>
                </div>
              </div>
            </SynButton>
          </div>
        </div>
      </div>
    </div>
  </div>

  <SynModalNoFocus
    v-if="isInputComment"
    z-index="z-50"
    container-class="md:w-2/3"
    disable-click-outside
    is-hidden-footer
    :cancel-text="$t('COMMON_LABEL_CANCEL')"
    :confirm-text="$t('COMMON_LABEL_SAVE')"
    style-body="px-4 py-1"
    @cancel="onCancelComment"
  >
    <template #header>
      <div class="flex items-center">
        <span class="text-base">
          {{ $t('COMMON_LABEL_WRITE') }}
        </span>
      </div>
    </template>
    <template #body>
      <div v-if="useCkeditorWithMention">
        <!--        <CKEditor-->
        <!--          v-model="myComment"-->
        <!--          :placeholder="-->
        <!--            $t('TASK_CREATE_FORM_TASK_CREATE_FORM_COMMENT_HERE') ||-->
        <!--              'Comment here'-->
        <!--          "-->
        <!--          @change="onChangeComment"-->
        <!--        ></CKEditor>-->
      </div>
      <div v-else class="task-update-description-modal py-3">
        <TaskDescriptionInput
          ref="taskDescriptionRef"
          v-model="myComment"
          :current-task="formData"
          @on-close="onCancelComment"
          @on-save="onClickSaveDescription"
        />
      </div>
    </template>
  </SynModalNoFocus>

  <CreateNoteModal
    v-if="isCreateNote"
    :draw-note-data="{
      ...currentNote,
      isLoading: isLoadingDrawData,
      images:
        formData.files?.length > 0
          ? formData.files.filter((o) => isImage(o))
          : [],
      originFile: '',
      readonly: false,
    }"
    @on-close="onCancelNote"
    @on-save="
      (data) =>
        onSaveNote({
          ...currentNote,
          ...data,
        })
    "
  />

  <RecorderModal
    v-if="isOpenRecording"
    @on-done="onCreateAudioDone"
    @on-cancel="onCreateAudioCancel"
  />

  <ModalMultipleFileViewer
    v-if="isPreview"
    :start-index="currentFileIndex"
    :files="allAttachments"
    @on-close="isPreview = false"
    @on-save-description="onClickSaveDescription"
    @on-save-note="onSaveNote"
  >
    <template #title>
      <span>
        {{ formData?.name }}
      </span>
    </template>
    <template #footer-action>
      <div
        class="
          m-0.5
          w-16
          h-16
          flex-center flex-col
          cursor-pointer
          border border-dashed border-blue-300
          rounded-md
          shadow
          hover-to-show__parent
          relative
          bg-white
        "
      >
        <SynIcon
          name="plus"
          class="fill-blue-500 text-blue-500"
          custom-class="h-6 w-6"
        />
        <div
          class="
            absolute
            w-full
            h-full
            flex-center flex-col
            cursor-pointer
            rounded-md
            hover-to-show__children
            bg-white
          "
        >
          <div class="flex-center w-full h-1/2">
            <label
              :for="taskCreateNewInputFileId"
              class="
                w-1/2
                h-full
                hover:bg-current-100
                flex-center
                cursor-pointer
              "
              :title="
                $t('COMMON_LABEL_DRAG_AND_DROP_FILE_HERE') || 'Attachment'
              "
            >
              <SynIcon name="attach" class="fill-current" />
            </label>
            <div
              class="w-1/2 h-full hover:bg-orange-100 flex-center"
              :title="$t('TASK_CREATE_FORM_LABEL_ADD_NOTE') || 'Add note'"
              @click="onCreateNote(false)"
            >
              <SynIcon
                name="noter"
                is-active
                class="fill-orange-500"
                custom-class="h-4 w-4"
              />
            </div>
          </div>
          <div class="flex-center w-full h-1/2">
            <div
              class="w-1/2 h-full hover:bg-blue-100 flex-center"
              :title="$t('TASK_DETAIL_LABEL_ADD_AUDIO') || 'Add audio'"
              @click="onCreateAudio"
            >
              <SynIcon is-active name="microphone" class="fill-blue-500" />
            </div>
            <div
              class="w-1/2 h-full hover:bg-blue-100 flex-center"
              :title="$t('TASK_CREATE_FORM_LABEL_COMMENT') || 'Add comment'"
              @click="onOpenComment(formData.description)"
            >
              <SynIcon is-active name="comment" class="fill-current" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </ModalMultipleFileViewer>
  <input
    :id="taskCreateNewInputFileId"
    type="file"
    multiple
    class="hidden opacity-0"
    :accept="webTaskUploadContentTypes"
    @change="onChooseFiles"
  />
</template>
