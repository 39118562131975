<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { loginAgain } from '@/ui/hooks/commonHook';
import appStore from '@/store/app';

const isInitializing = ref<boolean>(true);

const _myProfileStore = myProfileStore();
const myProfile = computed<any>(() => _myProfileStore.myProfile);
onMounted(async () => {
  isInitializing.value = true;
  const token = getLocalStorage(StorageConstant.TOKEN);

  if (token) await myProfileStore().fetchCurrentLoginUser();
  isInitializing.value = false;
});

const handleLogout = () => {
  isInitializing.value = true;
  appStore().setIsLoading(true);

  loginAgain();

  isInitializing.value = false;
};

const handleClickLogout = async () => {
  settingGlobalModal({
    type: 'confirm',
    title: translate('HEADER_LOGOUT_CONFIRM_TITLE'),
    content: translate('HEADER_LOGOUT_CONFIRM_SUB'),
    confirmable: true,
    closeable: true,
  });
  const confirm = await ask();
  if (confirm) handleLogout();
};
</script>

<template>
  <SynLoading v-if="isInitializing" />

  <template v-else>
    <div class="fixed top-0 bottom-0 left-0 right-0">
      <div class="h-full relative overflow-auto small-scrollbar">
        <!--HEADER-->
        <div class="sticky top-0 bg-white shadow z-20">
          <div
            class="
              max-w-screen-lg
              mx-auto
              px-4
              py-2
              flex
              items-center
              justify-between
            "
          >
            <router-link to="/" class="flex items-center gap-2">
              <SynIcon name="tictop-logo" custom-class="h-8" />
              <div>
                <SynIcon name="tictop-logo-label" custom-class="w-16" />
                <div class="tracking-widest text-xs text-current-700 font-thin">
                  Working is fun!
                </div>
              </div>
            </router-link>

            <template v-if="myProfile?.id">
              <div class="flex-center gap-2">
                <router-link
                  to="/tasks/my-tasks"
                  class="
                    relative
                    cursor-pointer
                    hover:bg-current-50
                    rounded-full
                    py-1
                    pr-2
                    pl-1
                  "
                >
                  <SynAvatar
                    has-name
                    :name="myProfile?.fullName"
                    :src="myProfile?.avatarUrl"
                    custom-class="w-8 h-8 ring ring-current ring-opacity-30 border border-current"
                    type="group"
                  >
                    <template #name>
                      <div
                        class="
                          font-medium
                          text-gray-800
                          overflow-hidden
                          flex-nowrap
                          text-ellipsis
                          hidden
                          xl:flex
                        "
                        style="white-space: nowrap; max-width: 10rem"
                      >
                        {{ myProfile?.fullName }}
                      </div>
                    </template>
                  </SynAvatar>
                </router-link>
                <VigDropdown class="hover:text-current bg-white shadow-none">
                  <template #dropdown-toggle>
                    <SynIcon
                      name="dots-vertical"
                      has-action
                      custom-class="h-4 w-4 hover:text-current text-current"
                    />
                  </template>
                  <template #dropdown-menu>
                    <SynDropdownMenu class="right-0 min-w-[11rem]">
                      <div
                        class="dropdown-item list-li text-red-500"
                        @click="handleClickLogout"
                      >
                        <div class="flex items-center">
                          <SynIcon name="logout" />
                          <span class="pl-2">
                            {{ $t('COMMON_LABEL_LOGOUT') || 'Log out' }}
                          </span>
                        </div>
                      </div>
                    </SynDropdownMenu>
                  </template>
                </VigDropdown>
              </div>
            </template>
            <div v-else class="flex items-center gap-2">
              <router-link to="/register">
                <VigButton ghost label="COMMON_LABEL_SIGN_UP"></VigButton>
              </router-link>
              <router-link to="/">
                <VigButton ghost label="COMMON_MODULE_LOGIN"></VigButton>
              </router-link>
            </div>
          </div>
        </div>

        <div class="relative">
          <slot></slot>
        </div>
      </div>
    </div>
  </template>
</template>
