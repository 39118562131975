<script setup lang="ts">
import { computed, ref } from 'vue';
import userStore from '@/store/user';
import { ignoreUnicode } from '@/ui/plugins/utils';
import domainStore from '@/store/scope';
import DomainDetailClass from '@/domain/entities/domains/DomainDetailClass';
import UpdateMemberDomainSingleModal from '@/ui/modules/domains/component/UpdateMemberDomainSingleModal.vue';
import DomainMemberLabel from '@/ui/modules/domains/component/DomainMemberLabel.vue';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import SearchTaskOwners from '@/ui/components/search/SearchTaskOwners.vue';

const props = withDefaults(
  defineProps<{
    modelValue: any[];
    domainId?: number | string | null;
    isEditting: boolean;
    autofocus?: boolean;
    domainName?: string;
  }>(),
  {
    domainName: '',
  }
);
const emit = defineEmits<{
  (e: 'update:modelValue', value: any): void;
}>();

const _userStore = userStore();

const _domainStore = domainStore();

const domainByIds = computed(() => _domainStore.domainByIds);
const parentDomain = computed<DomainDetailClass>(() => {
  if (!props.domainId) return {};
  return domainByIds.value[props.domainId];
});
const anyProjectMembers = computed<number[]>(() => {
  if (!props.domainId) return [];
  return parentDomain.value.anyProjectMembers;
});

const path =
  'https://d1tvqetrcurhzb.cloudfront.net/common/images/team-member.jpg';

const initData = () => {
  console.log(parentDomain.value);
};

const isVisitor = computed(() => _userStore.isVisitor);

const searchText = ref('');

const filteredUsers = computed(() => {
  if (isVisitor.value) return [];
  const searchTxt = ignoreUnicode(searchText.value);
  if (searchTxt)
    return (_userStore.allActiveUsers || []).filter(
      (user) =>
        user?.name &&
        ignoreUnicode(user?.name).includes(searchTxt) &&
        !props.modelValue?.some((u) => u.id == user?.id) &&
        !anyProjectMembers.value?.includes(user?.id)
    );
  return _userStore.allActiveUsers?.filter(
    (user) =>
      !props.modelValue?.some((u) => u.id == user?.id) &&
      !anyProjectMembers.value?.includes(user?.id)
  );
});

initData();

const unSelectedUser = (userId) => {
  emit(
    'update:modelValue',
    props.modelValue.filter((u) => u.id !== userId)
  );
};

const onSelectUser = (user) => {
  emit(
    'update:modelValue',
    arrayOrderBy(
      [
        ...props.modelValue,
        {
          id: user?.id,
          anyProjects: true,
        },
      ],
      ['anyProjects', (user) => user?.name?.split(' ')?.pop()],
      ['desc', 'asc']
    )
  );
  setTimeout(() => {
    if (filteredUsers.value?.length == 0) searchText.value = '';
  });
};

const editDomainByUserId = ref(0);
const isAnyProjects = ref(true);
const isNeedGetProject = ref(true);
const currentUserProjectIds = ref<any[]>([]);
const onEditAnyProject = (userId) => {
  editDomainByUserId.value = userId;

  const index = props.modelValue?.findIndex((user) => user.id == userId);
  if (index == -1) return;
  isAnyProjects.value = !!props.modelValue[index]?.anyProjects;
  currentUserProjectIds.value = props.modelValue[index]?.projectIds;
  isNeedGetProject.value = !props.modelValue[index]?.updated;
};

const onSaveAnyProjects = (data: {
  anyProjects: boolean;
  projectIds: number[];
}) => {
  const index = props.modelValue?.findIndex(
    (user) => user.id == editDomainByUserId.value
  );
  if (index == -1) return;

  const newModelValue = props.modelValue;
  newModelValue[index].anyProjects = data.anyProjects;
  newModelValue[index].projectIds = data.projectIds;
  newModelValue[index].updated = true;
  emit(
    'update:modelValue',
    arrayOrderBy(
      newModelValue,
      ['anyProjects', (user) => user?.name?.split(' ')?.pop()],
      ['desc', 'asc']
    )
  );

  editDomainByUserId.value = 0;
};
</script>

<template>
  <div
    class="flex flex-col flex-1 min-h-0 overflow-hidden"
    :class="isEditting ? 'grid grid-cols-2 ' : ''"
  >
    <div
      v-if="isEditting"
      class="flex-1 min-h-0 h-full small-scrollbar overflow-auto px-2"
    >
      <SearchTaskOwners
        :owner-type-list="['USER']"
        is-hidden-action-item
        is-select-multiple
        is-filter-when-selected
        is-hidden-selected-users
        :selected-ids="modelValue?.map((u) => `USER_${u?.id}`)"
        @on-choose-owner="onSelectUser"
      />
    </div>

    <div class="flex flex-col flex-1 min-h-0 overflow-auto small-scrollbar">
      <div v-if="isEditting" class="p-4 flex space-x-2">
        <template v-if="modelValue?.length > 0">
          <span class="">
            {{
              $t('GED_NUM_SELECTED', {
                number: modelValue?.length || 0,
              })
            }}
          </span>
        </template>
        <!--        <span v-else>-->
        <!--          {{ $t('COMMON_LABEL_SELECT_MEMBER_NULL') }}-->
        <!--        </span>-->
      </div>
      <div class="flex flex-col space-y-2 flex-1 min-h-0 px-4">
        <template v-if="modelValue?.length > 0">
          <DomainMemberLabel
            v-for="user of modelValue"
            :key="user.id"
            editable
            :user-id="user?.id"
            :any-projects="user.anyProjects"
            :is-edit="isEditting"
            @on-remove="unSelectedUser(user?.id)"
            @on-edit="onEditAnyProject(user?.id)"
          />
        </template>
        <template v-else>
          <div class="flex flex-col w-full h-full items-center justify-center">
            <div class="w-full h-32 flex-center grow-0 shrink-0">
              <VigImage :path="path" :custom-class="`w-2/3`" />
            </div>
            <div class="">
              <div class="flex-center flex-col space-y-1">
                <span
                  class="text-gray-500 pt-8 text-sm text-center"
                  v-html="
                    $t('COMMON_LABEL_SELECT_USER_FOR_DOMAIN', {
                      project: domainName,
                    })
                  "
                >
                </span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>

  <UpdateMemberDomainSingleModal
    v-if="editDomainByUserId"
    v-model:isAnyProjects="isAnyProjects"
    :user-id="editDomainByUserId"
    :domain-id="domainId || 0"
    :domain-name="domainName"
    :user-project-ids="currentUserProjectIds"
    :is-need-get-project="isNeedGetProject"
    @on-close="editDomainByUserId = 0"
    @on-submit="onSaveAnyProjects"
  />
</template>
