<script setup lang="ts">
import { computed } from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { TaskUrgency } from '@/ui/hooks/taskHook';
import { CalendarType } from '@/ui/common/constants/calendar';
import {
  colorByAlphabet,
  convertViToEn,
  cleanString,
} from '@/ui/common/molecules/SynAvatar/colorByAlphabet';

import datetimeComposable from '@/ui/composables/common/datetime-composable';
dayjs.extend(utc);
dayjs.extend(tz);

const props = withDefaults(
  defineProps<{
    typeEvent?: number;
    srcAvatar?: string;
    nameUser?: string;
    titleEvent?: string;
    urgency?: number;
    nameIcon?: any;
    isActive?: boolean;
    startDate?: string;
    endDate?: string;
    allDay?: boolean;
    system?: boolean;
  }>(),
  {
    typeEvent: 10,
    titleEvent: 'Title Event',
    urgency: 0,
    nameIcon: 'coffee',
  }
);

const { formatDate } = datetimeComposable();

const textAva = computed(() => {
  if (!props?.nameUser) return 'A';
  return cleanString(props.nameUser)
    .split(' ')
    [cleanString(props.nameUser).split(' ').length - 1].charAt(0);
});

const convertedTime = computed<any>(() => {
  // if (dayjs(props.startDate).isSame(dayjs(props.endDate))) {
  return formatDate(props.startDate, 'time', {
    isIgnoreTimezone: true,
  });
  // }
  // return `${formatDate(props.startDate, 'time', {
  //   isIgnoreTimezone: true,
  // })} - ${formatDate(props.endDate, 'time', {
  //   isIgnoreTimezone: true,
  // })}`;
});

// const eventItemColor = {
//   reminder: 'green',
//   meeting: 'purple',
//   system: 'indigo',
// };
</script>

<template>
  <div
    :title="titleEvent"
    class="
      flex
      justify-between
      items-center
      space-x-1
      cursor-pointer
      w-full
      px-1.5
      py-2
      rounded
    "
    :class="[
      {
        'bg-green-50': typeEvent == CalendarType.Reminder,
      },
      {
        'bg-purple-50': typeEvent == CalendarType.Meeting,
      },
      {
        'bg-red-200':
          typeEvent == CalendarType.Task &&
          urgency == TaskUrgency.UrgentImportant.key,
      },
      {
        'bg-blue-200':
          typeEvent == CalendarType.Task &&
          urgency !== TaskUrgency.UrgentImportant.key,
      },
      {
        'bg-cyan-100': system,
      },
    ]"
  >
    <!-- UGENTCY -->
    <div class="flex items-center space-x-2 overflow-hidden">
      <template v-if="!system">
        <!-- ICON EVENT -->
        <div
          v-if="typeEvent !== CalendarType.Task"
          class="w-5 h-5 flex-center shadow-sm rounded-full"
          :class="[
            {
              'bg-green-200': typeEvent == CalendarType.Reminder,
            },
            {
              'bg-purple-200': typeEvent == CalendarType.Meeting,
            },
            {
              'bg-red-200':
                typeEvent == CalendarType.Task &&
                urgency == TaskUrgency.UrgentImportant.key,
            },
            {
              'bg-blue-200':
                typeEvent == CalendarType.Task &&
                urgency !== TaskUrgency.UrgentImportant.key,
            },
            {
              'bg-cyan-200': system,
            },
          ]"
        >
          <component
            :is="nameIcon"
            :is-active="isActive"
            class="w-3 h-3"
            :class="
              typeEvent == CalendarType.Reminder
                ? 'text-green-500 fill-green-500'
                : 'text-purple-500 fill-purple-500'
            "
          >
          </component>
        </div>

        <!-- ICON TASK -->
        <div
          v-if="
            (typeEvent == CalendarType.Task && urgency !== 0) ||
            (typeEvent == CalendarType.Task && urgency !== 1)
          "
          class="w-4 h-4 flex-center"
        >
          <component
            :is="nameIcon"
            :is-active="isActive"
            class="inline-block w-3 h-3 fill-red-500"
          >
          </component>
        </div>
      </template>

      <div
        v-if="system"
        class="w-5 h-5 flex-center bg-cyan-200 rounded-full shadow-sm"
      >
        <component
          :is="nameIcon"
          :is-active="isActive"
          class="w-3 h-3 fill-cyan-500"
        >
        </component>
      </div>

      <!-- TITLE -->
      <div class="truncate text-black flex items-baseline flex-1">
        <span v-if="!allDay" class="pr-2 italic text-xs text-gray-500">
          {{ convertedTime }}
        </span>
        <span
          class="font-medium truncate text-xs"
          :class="[
            {
              'text-green-500': typeEvent == CalendarType.Reminder,
            },
            {
              'text-purple-500': typeEvent == CalendarType.Meeting,
            },
            {
              'text-red-500':
                typeEvent == CalendarType.Task &&
                urgency == TaskUrgency.UrgentImportant.key,
            },
            {
              'text-blue-500':
                typeEvent == CalendarType.Task &&
                urgency !== TaskUrgency.UrgentImportant.key,
            },
            {
              'text-cyan-500': system,
            },
          ]"
        >
          {{ titleEvent }}
        </span>
      </div>
    </div>

    <div class="pr-1">
      <!-- AVATAR -->
      <div v-if="typeEvent == CalendarType.Task">
        <div v-if="srcAvatar" class="rounded-full overflow-hidden w-5 h-5">
          <img class="w-full h-full object-cover shadow" :src="srcAvatar" />
        </div>
        <div
          v-else
          class="
            object-cover
            flex-center
            text-white
            shadow
            font-thin
            rounded-full
            w-5
            h-5
          "
          :style="`background: ${
            colorByAlphabet[convertViToEn(textAva, true)] || '#4bb0b2'
          }`"
        >
          <span v-if="nameUser" class="uppercase" style="font-size: 0.75em">{{
            textAva
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
