import {
    StorageConstant,
    setLocalStorage,
    getLocalStorage,
    removeLocalStorage,
} from '@/ui/hooks/storageHook';
import { AdminType, SettingProgressEnum } from '@/ui/common/constants/constant';
import { clearAllLocalData } from '@/ui/hooks/commonHook';
import commonIdb from '@/indexedDB/common/common.idb';

export class MyOrganizationSingleton {
    private static instance: MyOrganizationSingleton;
    activeOrganization: number;
    token: string;
    refreshToken: string;
    myUserId: number;

    /**
     * The Singleton's constructor should always be private to prevent direct
     * construction calls with the `new` operator.
     */

    private constructor(
        activeOrganization: number,
        token: string,
        refreshToken: string,
        myUserId: number
    ) {
        this.activeOrganization = activeOrganization;
        this.token = token;
        this.refreshToken = refreshToken;
        this.myUserId = myUserId;
    }
    public static setInstance(
        organizations: any[],
        currentOrgId: number,
        token: string,
        refreshToken: string,
        numberOfLogins: number,
        organizationSettingProgress: SettingProgressEnum | null
    ): void {
        try {
            const localStorageOrganizations = getLocalStorage(
                StorageConstant.ORGANIZATIONS
            );

            let orgByIds = {};
            if (localStorageOrganizations) {
                orgByIds = JSON.parse(localStorageOrganizations);
                removeLocalStorage(StorageConstant.ORGANIZATIONS);
            } else
                orgByIds = organizations.reduce((currentObj, currentOrg) => {
                    const orgId = currentOrg?.id;
                    return {
                        ...currentObj,
                        [orgId]: {
                            id: orgId,
                            name: currentOrg?.name,
                            logoUrl: currentOrg?.logoUrl,
                        },
                    };
                }, {});

            MyOrganizationSingleton.instance = new MyOrganizationSingleton(
                currentOrgId,
                token,
                refreshToken,
                0
            );

            setLocalStorage(StorageConstant.ACTIVE_ORG, currentOrgId);
            setLocalStorage(StorageConstant.TOKEN, token);
            setLocalStorage(StorageConstant.REFRESH_TOKEN, refreshToken);

            if (currentOrgId) {
                this.setNumberOfLogin(numberOfLogins);
                this.setOrganizationSettingProgress(
                    organizationSettingProgress
                );
                commonIdb.saveCommonInformation(orgByIds[currentOrgId]);
            }
        } catch (error) {
            console.log('🚀 Tictop ~ error:', error);
        }
    }

    /**
     * The static method that controls the access to the singleton instance.
     *
     * This implementation let you subclass the Singleton class while keeping
     * just one instance of each subclass around.
     */
    public static getInstance(): MyOrganizationSingleton {
        if (!MyOrganizationSingleton.instance) {
            // Get from local storage

            const activeOrganization = getLocalStorage(
                StorageConstant.ACTIVE_ORG
            );
            const token = getLocalStorage(StorageConstant.TOKEN);
            const refreshToken = getLocalStorage(StorageConstant.REFRESH_TOKEN);

            if (!token || !refreshToken || !activeOrganization) this.reLogin();

            MyOrganizationSingleton.instance = new MyOrganizationSingleton(
                parseInt(activeOrganization),
                token,
                refreshToken == 'null' ? '' : refreshToken,
                0
            );
        }

        return MyOrganizationSingleton.instance;
    }

    static async reLogin(): Promise<void> {
        await clearAllLocalData();
        location.reload();
    }

    getActiveOrganization() {
        return commonIdb.getAll();
    }

    getNumberOfLogin(): number {
        return parseInt(getLocalStorage(StorageConstant.NUMBER_OF_LOGIN_TIME));
    }

    getUserRole() {
        return parseInt(getLocalStorage(StorageConstant.CURRENT_USER_ROLE));
    }

    getOrganizationSettingProgress(): SettingProgressEnum | null {
        return getLocalStorage(
            StorageConstant.SETTING_PROGRESS
        ) as SettingProgressEnum;
    }

    getCurrentTaskDrawer(): Promise<any> {
        return commonIdb.getDetailByKeyPath('taskDrawer');
    }

    public static setUserRole(role: number): void {
        setLocalStorage(StorageConstant.CURRENT_USER_ROLE, role);
    }

    public static setUserId(userId: number): void {
        this.instance.myUserId = userId;
        setLocalStorage(StorageConstant.CURRENT_USER_ID, userId);
    }

    public static setActiveOrganization(organizationId: number): void {
        this.instance.activeOrganization = organizationId;
    }

    public static setNumberOfLogin(numberOfLogins: number): void {
        setLocalStorage(StorageConstant.NUMBER_OF_LOGIN_TIME, numberOfLogins);
        commonIdb.saveDataByKey('numberOfLogins', numberOfLogins);
    }

    public static setOrganizationSettingProgress(
        settingProgress: SettingProgressEnum | null
    ): void {
        setLocalStorage(StorageConstant.SETTING_PROGRESS, settingProgress);
        commonIdb.saveDataByKey('settingProgress', settingProgress);
    }

    public static setOrganizationTaskDrawer(taskDrawer: ITaskDrawer): void {
        commonIdb.saveDataByKey('taskDrawer', taskDrawer);
    }
}

export class OrganizationSetting {
    id: number;
    name: string;
    logoUrl: string;
    isDefault: boolean;
    role: number | null;
    settingProgress: SettingProgressEnum | null;
    numberOfLogins: number;
    taskDrawer: ITaskDrawer;

    constructor(
        id = 0,
        name = '',
        logoUrl = '',
        isDefault = false,
        role: AdminType | null = null,
        settingProgress: SettingProgressEnum | null = null,
        numberOfLogins = 0,
        taskDrawer: ITaskDrawer = {
            currentTabId: 0,
            allTabByIds: {},
            allTabIds: [],
        }
    ) {
        this.id = id;
        this.name = name;
        this.logoUrl = logoUrl;
        this.isDefault = isDefault;
        this.role = role;
        this.settingProgress = settingProgress;
        this.numberOfLogins = numberOfLogins;
        this.taskDrawer = taskDrawer;
    }
}

interface ITaskDrawer {
    currentTabId: number;
    allTabByIds: any;
    allTabIds: any[];
}
