<script setup lang="ts">
import { ref, computed } from 'vue';
import { severity, stateDeployments } from '@/ui/common/constants/system';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
import systemStore from '@/store/system';

const _systemStore = systemStore();

const state = computed(() => _systemStore.stateDeploymentActive);
const languge = getLocalStorage(StorageConstant.LANGUAGE);
const infoDeployment = ref(
  getLocalStorage(StorageConstant.INFO_DEPLOYMENT)
    ? JSON.parse(getLocalStorage(StorageConstant.INFO_DEPLOYMENT))
    : null
);

const colorDot = computed(() => {
  if (infoDeployment.value?.info?.severity == severity.NORMAL.index)
    return 'bg-yellow-300 text-yellow-800';
  if (infoDeployment.value?.severity == severity.HIGH.index)
    return 'bg-orange-500 text-orange-100';
  return 'bg-red-600 text-red-50';
});

const listDescription = computed(() =>
  infoDeployment.value?.info?.description
    ? infoDeployment.value?.info?.description[
        languge?.toLowerCase() || 'en' || 'fr' || 'vi'
      ]
    : []
);

const onContinueWork = () => {
  history.replaceState({}, '', '/');
  window.location.reload();
};
</script>
<template>
  <div
    class="
      h-screen
      w-full
      flex-col flex
      items-center
      justify-around
      bg-current-50 bg-opacity-50
    "
  >
    <div class="flex-1 flex flex-row">
      <syn-animation
        class="w-1/2"
        name="webMaintenace"
        stype="width: 600px; height: 600px"
      />

      <div
        class="flex items-center h-full flex-col space-y-2"
        style="align-items: flex-start; justify-content: center"
      >
        <span
          class="text-4xl font-bold text-white text-left"
          style="text-shadow: 0px 0px 5px #1a9793"
        >
          {{ $t('SYNTEM_DEPLOYMENT_MAINTENANCE_PAGE_TITLE') }}
        </span>
        <span class="font-thin text-xl text-left text-current-700 pt-4 w-2/3">
          {{ $t('SYNTEM_DEPLOYMENT_MAINTENANCE_PAGE_SUB_TITLE') }}
        </span>

        <div v-if="listDescription.length > 0" class="pt-6">
          <div class="font-medium text-current-500 flex items-center space-x-2">
            <span :class="colorDot" class="w-2 h-2 rounded-full p-1"></span>
            <span>{{ $t('SYSTEM_DEPLOYMENT_VIEW_INFO_TITLE') }}</span>
          </div>
          <ul class="list-decimal p-2 pl-8">
            <template v-for="item in listDescription" :key="item">
              <li v-if="item">{{ item }}</li>
            </template>
          </ul>
        </div>

        <div
          v-if="
            state !== stateDeployments.UPGRADING.state &&
            state !== stateDeployments.MAINTENING.state
          "
          class="pt-4"
        >
          <syn-button
            :label="$t('COMMON_LABEL_CONTINUE_TO_WORD')"
            @click="onContinueWork"
          ></syn-button>
        </div>
      </div>
    </div>

    <section class="text-center py-6 text-sm">
      <syn-logo
        class="p-4"
        class-logo="h-7"
        class-label="w-14"
        class-sub-text="text-xs"
      />
      <div class="flex items-center space-x-8">
        <div class="flex items-center space-x-2">
          <SynIcon name="globals" class="fill-current" />
          <span>
            <a
              class="text-current-700 hover:text-current-900"
              href="https://www.fiine.vn/"
              target="_blank"
              >https://www.fiine.vn</a
            >
          </span>
        </div>
        <div class="flex items-center space-x-2">
          <SynIcon name="email" class="fill-current" />
          <span>
            <a
              class="text-current-700 hover:text-current-900"
              href="mailto:services@fiine.pro"
              target="_blank"
              >services@fiine.pro</a
            >
          </span>
        </div>
        <div class="flex items-center space-x-2">
          <SynIcon name="support" class="fill-current" />
          <span>
            <a
              class="text-current-700 hover:text-current-900"
              href="mailto:support@fiine.pro"
              target="_blank"
              >support@fiine.pro</a
            >
          </span>
        </div>
        <div class="flex items-center space-x-2">
          <SynIcon name="phone" class="fill-current" />
          <span>
            <a class="text-current-700 hover:text-current-900" target="_blank"
              >(+84) 919 326 983</a
            >
          </span>
        </div>
      </div>
      <div class="text-gray-600 text-xs pt-4">
        Copyright © 2024 Tictop | Powered by Tictop
      </div>
    </section>
  </div>
</template>
