<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue';
import FileViewer from './FileViewer.vue';
import VigSlider from '@/ui/common/molecules/Slider/VigSlider.vue';
import UploadPreview from '@/ui/components/file-viewer/UploadPreview.vue';
import DrawerWidget from '@/ui/components/DrawerWidget/DrawerWidget.vue';
import { cloneDeep } from '@/ui/hooks/commonFunction';
import ActionInFile from '@/ui/modules/ged/modal-file-viewer/ActionInFile.vue';
import SynModalNoFocus from '@/ui/common/molecules/SynModal/SynModalNoFocus.vue';
import TaskDescriptionInput from '@/ui/common/plugins/ckeditor/TaskDescriptionInput.vue';
import commentLogic from '@/ui/modules/task/general/comment-logic';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import ModalSaveToResource from '@/ui/modules/ged/folder/modal-save-to-resource/ModalSaveToResource.vue';
import TaskComment from '@/ui/modules/task/detail/TaskComment.vue';
import TaskCommentBottom from '@/ui/modules/task/detail/TaskCommentBottom.vue';
import TaskTodoListAll from '@/ui/modules/task/components/check-list/TaskTodoListAll.vue';

const props = withDefaults(
  defineProps<{
    startIndex: number;
    files: any[];
    editable?: boolean;
    isLoading?: boolean;
    hasTaskActions?: boolean;
    hasImageActions?: boolean;
    disabledDownload?: boolean;
    currentTask?: any;
  }>(),
  {
    startIndex: 0,
    editable: true,
    isLoading: false,
    hasTaskActions: true,
    hasImageActions: true,
  }
);

const emit = defineEmits([
  'onReady',
  'onClose',
  'onSaveNote',
  'onRemove',
  'onSaveDescription',
  'onFileClick',
]);

const currentViewMode = ref<'VIEW_FILE' | 'EDIT_NOTE' | 'EDIT_DESCRIPTION'>(
  'VIEW_FILE'
);

const currentIndex = ref(props.startIndex || 0);

onMounted(() => {
  filesPreview.value?.focus();
  onScollViewFile();
  emit('onReady');
});

watch(
  () => props.files.length,
  (newValue) => {
    if (newValue > 1 && newValue >= currentIndex.value)
      currentIndex.value = newValue - 2;
  }
);

const sliderItems = computed<any>(() => {
  return props.files.filter(
    (file) => file.type !== 'text' || (file.type == 'text' && file.data)
  );
});

const isLoadingFile = computed(() => props.isLoading);
const onSelectFile = (file) => {
  const index = sliderItems.value?.findIndex((f) => f?.id == file?.id);
  if (index == -1) return;

  onChangeViewByIndex(index);
};
const onChangeViewByIndex = (index) => {
  emit('onFileClick', index);
  currentViewMode.value = 'VIEW_FILE';
  currentIndex.value = index;
};
const totalFile = computed(() => sliderItems.value?.length);
const currentFile = computed(() => {
  const total = totalFile.value;
  if (total == 0) return {};
  if (total <= currentIndex.value) {
    return cloneDeep(sliderItems.value[total - 1]);
  }

  return sliderItems.value[currentIndex.value];
});

const currentViewModeByType = computed<
  'FILE' | 'DESCRIPTION' | 'TODO_LIST' | 'COMMENT'
>(() => {
  const itemType = currentFile.value?.type;
  if (itemType == 'text') return 'DESCRIPTION';
  if (itemType == 'todo_list') return 'TODO_LIST';
  if (itemType == 'comment') return 'COMMENT';
  return 'FILE';
});

const onNext = () => {
  if (
    currentViewMode.value == 'EDIT_DESCRIPTION' ||
    currentViewMode.value == 'EDIT_NOTE'
  )
    return;

  const maxLength = sliderItems.value?.length;

  if (currentIndex.value == maxLength - 1) {
    onChangeViewByIndex(0);
    // onScrollTo('end');
    return;
  }
  onChangeViewByIndex(currentIndex.value + 1);
  // onScrollTo('end');
  onScollViewFile();
};
const onBack = () => {
  if (
    currentViewMode.value == 'EDIT_DESCRIPTION' ||
    currentViewMode.value == 'EDIT_NOTE'
  )
    return;
  const maxLength = sliderItems.value?.length;

  if (currentIndex.value == 0) {
    onChangeViewByIndex(maxLength - 1);
    // onScrollTo('start');
    return;
  }
  onChangeViewByIndex(currentIndex.value - 1);
  onScollViewFile();
};

const onScollViewFile = () => {
  const element = document.getElementById(
    `multiple_file_slider_${currentIndex.value}`
  ) as HTMLDivElement;

  element && element.scrollIntoView({ block: 'center' });
};
const filesPreview = ref(null as any);
const currentFileUrl = computed(() => {
  if (currentFile.value instanceof Blob)
    return URL.createObjectURL(currentFile.value);
  if (currentFile.value?.fileBlob instanceof Blob)
    return URL.createObjectURL(currentFile.value?.fileBlob);
  if (currentFile.value?.base64) return currentFile.value?.base64;
  if (currentFile.value?.fileUrl) return currentFile.value?.fileUrl;
  if (currentFile.value?.url_full) return currentFile.value?.url_full;
  if (currentFile.value?.pathUrl) return currentFile.value?.pathUrl;

  return currentFile.value?.imagePath || currentFile.value?.src;
});

const saveToResource = ref(false);
const onSaveNote = (data) => {
  console.log(
    'File: ModalMultipleFileViewer.vue - L: 154 - currentFile.value',
    { ...currentFile.value, ...data }
  );
  emit('onSaveNote', { ...currentFile.value, ...data });
  currentViewMode.value = 'VIEW_FILE';
  setTimeout(() => {
    filesPreview.value?.focus();
  });
};
const onCancelNote = () => {
  currentViewMode.value = 'VIEW_FILE';
  setTimeout(() => {
    filesPreview.value?.focus();
  });
};

const onRemove = async () => {
  let isNeedConfirm = true;
  if (currentFile.value?.isNote) {
    settingGlobalModal({
      type: 'confirm',
      title: '',
      content:
        translate('COMMON_LABEL_QUESTION_REMOTE_NOTE') ||
        'Do you want to remove this note?',
      confirmable: true,
      confirmLabel: translate('COMMON_LABEL_CONFIRM') || 'Confirm',
      closeable: true,
    });

    const confirmed = await ask();
    if (!confirmed) return;

    currentViewMode.value = 'VIEW_FILE';
    isNeedConfirm = false;
  }
  emit('onRemove', currentFile.value, isNeedConfirm);
};

const descriptionValue = ref('');

const taskDescriptionRef = ref<any>(null);
const myCommentValid = ref(true);
const { onFocus } = commentLogic(true, (value) => {
  taskDescriptionRef.value?.onInsertText(value);
});

const isEditDescription = ref<boolean>(false);
const onOpenEditDescription = () => {
  isEditDescription.value = true;

  descriptionValue.value = currentFile.value?.data;
};
const onCloseEditDescription = () => {
  descriptionValue.value = '';
  isEditDescription.value = false;
};

const onChangeDescription = () => {
  emit('onSaveDescription', descriptionValue.value);
  currentIndex.value = descriptionValue.value
    ? sliderItems.value?.length - 1
    : 0;
  onCloseEditDescription();
};

const taskTodoListAllRef = ref<any>();
const onAddTodoList = () => {
  const index = props.files?.findIndex((file) => file?.type == 'todo_list');
  if (index > -1) {
    onChangeViewByIndex(index);

    setTimeout(() => {
      taskTodoListAllRef.value?.onClickNewTab();
    }, 100);
  }
};
const updateCurrentViewMode = (viewMode) => {
  currentViewMode.value = viewMode;
};

defineExpose({
  onOpenEditDescription,
  onChangeViewByIndex,
  onAddTodoList,
  updateCurrentViewMode,
});
</script>

<template>
  <SynModalNoFocus
    is-hidden-header
    is-hidden-footer
    disable-click-outside
    z-index="z-50"
    container-class="w-11/12 h-[87%] sm:h-full"
    :container-style="`max-height: 1920px;
          max-width: 1920px;
          min-height:500px;
          width: 100%;
          `"
    :background-style="`padding: 20px;`"
    style-body="p-0 flex flex-col relative"
    @cancel="$emit('onClose')"
  >
    <template #body>
      <!--      <div-->
      <!--        v-if="isLoadingFile"-->
      <!--        class="-->
      <!--          absolute-->
      <!--          top-0-->
      <!--          bg-gray-100 bg-opacity-50-->
      <!--          h-full-->
      <!--          w-full-->
      <!--          flex-center-->
      <!--        "-->
      <!--      >-->
      <!--        <SynIcon name="spinner" class="fill-current border-none animate-spin">-->
      <!--        </SynIcon>-->
      <!--      </div>-->
      <!--      <template>-->
      <div
        v-if="currentViewMode == 'EDIT_NOTE'"
        ref="filesPreview"
        tabindex="0"
        class="h-full flex flex-col outline-none"
      >
        <div class="flex-1 min-h-0 w-full">
          <DrawerWidget
            :note-id="currentFile?.id"
            :is-remove-able="true"
            :draw-data="currentFile?.drawData"
            @cancel="onCancelNote"
            @on-save="onSaveNote"
            @on-remove="onRemove"
          />
        </div>
      </div>
      <div
        v-else
        ref="filesPreview"
        tabindex="0"
        class="h-full flex flex-col outline-none"
        @keyup.left="onBack"
        @keyup.down="onBack"
        @keyup.up="onNext"
        @keyup.right="onNext"
      >
        <div
          class="
            flex-1
            h-0
            flex flex-col
            items-center
            w-full
            relative
            bg-gray-800
            px-2
            pb-2
          "
          style="padding-top: 4.75rem"
        >
          <!-- HEADER -->
          <div
            class="
              absolute
              top-0
              left-0
              px-4
              py-4
              text-gray-800
              bg-white
              w-full
              flex
              items-center
              justify-between
              font-semibold
            "
          >
            <slot name="title"></slot>
            <div class="flex items-center">
              <template v-if="currentFile?.type !== 'comment'">
                <div
                  v-if="editable && currentFile?.type == 'text'"
                  :title="$t('COMMON_LABEL_EDIT') || 'Edit'"
                  class="
                    flex-center
                    font-bold
                    rounded-full
                    w-9
                    h-9
                    cursor-pointer
                    border border-current-100
                  "
                  :class="
                    currentViewMode == 'EDIT_DESCRIPTION'
                      ? 'bg-current shadow-lg'
                      : 'bg-white hover:bg-current-50'
                  "
                  @click.stop="onOpenEditDescription"
                >
                  <!--                <SynIcon-->
                  <!--                  name="edit"-->
                  <!--                  is-active-->
                  <!--                  class="fill-current"-->
                  <!--                  :title="$t('COMMON_LABEL_EDIT') || 'Edit'"-->
                  <!--                />-->
                  <SynIcon
                    name="edit"
                    :title="$t('COMMON_LABEL_EDIT') || 'Edit'"
                    :custom-class="
                      currentViewMode == 'EDIT_DESCRIPTION'
                        ? 'fill-white h-4 w-4'
                        : 'fill-current-500 h-4 w-4'
                    "
                  />
                </div>
                <ActionInFile
                  v-else
                  :file="currentFile"
                  :name="
                    currentFile?.name ||
                    currentFile?.fileName ||
                    'tictop-note.jpeg'
                  "
                  :path="currentFileUrl"
                  :content-type="currentFile?.contentType || currentFile?.type"
                  :editable="currentFile?.isNote && editable"
                  :removeable="editable"
                  :has-task-actions="hasTaskActions"
                  :has-image-actions="hasImageActions"
                  @edit="updateCurrentViewMode('EDIT_NOTE')"
                  @remove="onRemove"
                  @close="$emit('onClose')"
                  @save-to-resource="saveToResource = true"
                />
              </template>
              <template v-if="files.length > 1">
                <div class="ml-6 flex items-center space-x-2">
                  <div class="flex items-center">
                    <div
                      class="
                        flex-center
                        text-white
                        font-bold
                        rounded-full
                        w-9
                        h-9
                      "
                      :class="
                        currentViewMode == 'EDIT_DESCRIPTION'
                          ? 'bg-gray-300 cursor-not-allowed'
                          : 'bg-current-500 bg-opacity-70 hover:bg-opacity-100 cursor-pointer'
                      "
                      @click="onBack"
                    >
                      <SynIcon name="before" class="fill-white" />
                    </div>
                  </div>
                  <div class="flex items-center">
                    <div
                      id="column_view_flat_next"
                      class="
                        flex-center
                        text-white
                        font-bold
                        rounded-full
                        w-9
                        h-9
                        cursor-pointer
                      "
                      :class="
                        currentViewMode == 'EDIT_DESCRIPTION'
                          ? 'bg-gray-300 cursor-not-allowed'
                          : 'bg-current-500 bg-opacity-70 hover:bg-opacity-100 cursor-pointer'
                      "
                      @click="onNext"
                    >
                      <SynIcon name="next" class="fill-white" />
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <!-- FILE -->
          <FileViewer
            v-if="currentViewModeByType === 'FILE'"
            :file="currentFile"
            :name="
              currentFile?.name || currentFile?.fileName || 'tictop-note.jpeg'
            "
            :path="currentFileUrl"
            :content-type="currentFile?.contentType || currentFile?.type"
            :editable="currentFile?.isNote && editable"
            :removeable="editable"
            :is-loading="isLoadingFile"
            @edit="currentViewMode = 'EDIT_NOTE'"
            @remove="onRemove"
            @close="$emit('onClose')"
          />

          <!-- DESCRIPTION -->
          <template v-if="currentViewModeByType === 'DESCRIPTION'">
            <!-- EDIT DESCRIPTION -->
            <div
              v-if="isEditDescription"
              class="
                flex-1
                min-h-0
                h-full
                w-full
                flex flex-col
                relative
                bg-gray-50
                rounded-md
                ring-4 ring-current
                text-2xl
              "
            >
              <TaskDescriptionInput
                ref="taskDescriptionRef"
                v-model="descriptionValue"
                v-model:isValid="myCommentValid"
                :current-task="currentTask"
                @on-focus="onFocus"
                @on-close="onCloseEditDescription"
                @on-save="onChangeDescription"
              />
            </div>
            <!-- DISPLAY DESCRIPTION -->
            <div
              v-else
              class="
                w-1/2
                flex-1
                min-h-0
                overflow-auto
                small-scrollbar
                flex-center flex-col
                bg-white
                rounded
              "
              @dblclick="onOpenEditDescription"
            >
              <div
                class="
                  flex-1
                  text-gray-600
                  rounded
                  bg-white
                  w-full
                  h-full
                  hover-to-show__parent
                  flex flex-col
                "
                :class="
                  editable && !currentFile?.data
                    ? 'cursor-pointer'
                    : 'cursor-text'
                "
                @click.stop="
                  editable && !currentFile?.data && onOpenEditDescription()
                "
              >
                <span
                  class="
                    flex-1
                    whitespace-pre-wrap
                    break-words
                    px-6
                    py-4
                    text-2xl
                  "
                  v-html="
                    currentFile?.data ||
                    $t('COMMON_LABEL_PLACEHOLDER_ADD_COMMENT') ||
                    'Add a comment ...'
                  "
                ></span>
              </div>
            </div>
          </template>

          <!-- TODO LIST -->
          <div
            v-if="currentViewModeByType === 'TODO_LIST'"
            class="
              flex-1
              min-h-0
              h-full
              w-1/2
              flex flex-col
              relative
              bg-gray-50
              rounded
              p-4
            "
          >
            <TaskTodoListAll
              ref="taskTodoListAllRef"
              :task-id="currentTask?.id"
            />
          </div>
          <!-- COMMENT -->
          <div
            v-if="currentViewModeByType === 'COMMENT'"
            class="
              flex-1
              min-h-0
              h-full
              w-1/2
              flex flex-col
              relative
              bg-gray-50
              rounded
            "
          >
            <div
              id="commentAndTaskLog"
              class="
                mx-2
                flex
                bg-white
                items-center
                justify-start
                space-x-2
                py-2
                px-2
                sticky
                top-0
                z-10
              "
              style="margin-right: 20px"
            >
              <SynTagField
                :label="$t('TASK_ACTIVITY_LABEL_COMMENT')"
                is-active
              />
            </div>
            <div class="flex-1 min-h-0 overflow-auto small-scrollbar">
              <TaskComment
                :task-id="currentTask?.id"
                :current-task="currentTask"
              />
            </div>
            <section
              class="
                w-full
                flex
                items-start
                justify-between
                pb-3
                pt-4
                px-3
                border-t border-gray-100
              "
              style="max-height: 25rem"
            >
              <TaskCommentBottom
                :task-id="currentTask?.id"
                :current-task="currentTask"
              />
              <!--      <div class="pl-2 pt-2 flex-center">-->
              <!--        <GroupAttachmentAction-->
              <!--          :has-permission="hasPermission"-->
              <!--          :input-file-id="taskDetailFileInputId"-->
              <!--          @handle-click-note="onOpenCreateNote"-->
              <!--          @handle-click-audio="isOpenRecording = true"-->
              <!--        />-->
              <!--      </div>-->
            </section>
          </div>
        </div>

        <!-- FOOTER -->
        <div class="bg-gray-500">
          <VigSlider>
            <UploadPreview
              v-for="(file, index) in sliderItems"
              :id="`multiple_file_slider_${index}`"
              :key="file"
              :file="file"
              :is-active="index == currentIndex"
              :is-note="file?.isNote"
              :readonly="
                !editable ||
                file?.type == 'comment' ||
                file?.type == 'todo_list'
              "
              selectable
              is-hidden-info
              has-opacity
              @on-select="onSelectFile(file)"
              @remove="$emit('onRemove', file)"
            />

            <slot name="footer-action"></slot>
          </VigSlider>
        </div>
      </div>
      <!--      </template>-->
    </template>
  </SynModalNoFocus>

  <ModalSaveToResource
    v-if="saveToResource"
    :file="currentFile"
    @on-close="saveToResource = false"
  />
</template>
