<template>
  <svg v-if="isActive" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 24">
    <g
      id="Icon_feather-video"
      data-name="Icon feather-video"
      transform="translate(0 -6)"
    >
      <path
        id="Path_146"
        data-name="Path 146"
        d="M34.5,10.5,24,18l10.5,7.5Z"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      />
      <path
        id="Path_147"
        data-name="Path 147"
        d="M4.5,7.5H21a3,3,0,0,1,3,3v15a3,3,0,0,1-3,3H4.5a3,3,0,0,1-3-3v-15a3,3,0,0,1,3-3Z"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      />
    </g>
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    width="37.243"
    height="37.243"
    viewBox="0 0 37.243 37.243"
  >
    <g
      id="Icon_feather-video-off"
      data-name="Icon feather-video-off"
      transform="translate(0.621 0.621)"
    >
      <path
        id="Path_145"
        data-name="Path 145"
        d="M24,24v1.5a3,3,0,0,1-3,3H4.5a3,3,0,0,1-3-3v-15a3,3,0,0,1,3-3h3m8.49,0H21a3,3,0,0,1,3,3v5.01l1.5,1.5,9-6.51v15"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      />
      <path
        id="Path_146"
        data-name="Path 146"
        d="M1.5,1.5l33,33"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>
