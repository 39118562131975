<script setup lang="ts">
// *** IMPORTS ***
import { toRefs } from 'vue';
import FilePreview from '@/ui/modules/ged/_commons/file-preview/FilePreview.vue';
import useFileItem from '@/ui/composables/resource/file-item-composables';
import VideoRecordingFileActions from '@/ui/modules/video-recordings/recording-file-action/VideoRecordingFileActions.vue';

// *** PROPS, EMITS ***
const props = defineProps<{
  file: any;
}>();

defineEmits(['onFileClick']);

// *** PRIVATE VARIABLES ***

// *** COMPOSABLES ***

// *** REFS ***
const { file } = toRefs(props);
const { fileUrl, fileCreator } = useFileItem(file);

// *** COMPUTED ***

// *** WATCHES ***

// *** HOOKS ***

// *** HANDLER FUNCTIONS ***

// *** PRIVATE FUNCTIONS ***

// *** EXPOSES ***
</script>

<template>
  <tr
    class="
      cursor-pointer
      border-b border-gray-50
      group
      rounded
      hover:bg-gray-100
    "
    @click="$emit('onFileClick')"
  >
    <td class="py-1 px-2 w-14">
      <a :href="fileUrl" @click.prevent>
        <FilePreview
          :file="file"
          size="sm"
          class="h-9 w-14 flex-center relative"
        />
      </a>
    </td>
    <td class="py-1 px-2" style="width: 300px">
      <a :href="fileUrl" @click.prevent>
        <div class="truncate text-sm" :title="file?.name">{{ file?.name }}</div>
        <div
          :title="file?.link"
          class="text-overflow-hidden-line text-xs text-current-500"
        >
          <span>{{ file?.link }}</span>
        </div>
      </a>
    </td>
    <td class="py-1 px-2 truncate text-gray-500 text-sm w-56">
      <div class="flex items-center space-x-2">
        <SynAvatar
          :src="
            fileCreator?.avatarUrl ||
            fileCreator?.avatar ||
            fileCreator?.avatarThumbnailUrl
          "
          :name="fileCreator?.name"
          :type="'user'"
          custom-class="w-6 h-6"
        />
        <span>
          {{ fileCreator?.name }}
        </span>
      </div>
    </td>
    <td class="py-1 px-2 truncate text-gray-500 text-sm w-56">
      <SynLabelDateTime
        format="date_time"
        ignore-timezone
        :datetime="file?.creationTime"
      />
    </td>
    <td class="relative w-0">
      <div class="invisible group-hover:visible" @click.stop>
        <VideoRecordingFileActions :file="file">
          <VigButton
            ghost
            color="gray"
            padding="p-0"
            rounded="rounded-full"
            class="bg-white hover:border-gray-200 w-8 h-8"
          >
            <SynIcon name="DotsVertical" />
          </VigButton>
        </VideoRecordingFileActions>
      </div>
    </td>
  </tr>
</template>

<style scoped></style>
