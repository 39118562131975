import {
    ConsoleLogger,
    DefaultDeviceController,
    DefaultMeetingSession,
    LogLevel,
    MeetingSessionConfiguration,
    VideoTileState,
} from 'amazon-chime-sdk-js';
import { ref } from 'vue';
import {
    StorageConstant,
    getLocalStorage,
    setLocalStorage,
} from '@/ui/hooks/storageHook';
import {
    checkNavigatorPermission,
    PermissionNameString,
} from '@/ui/hooks/navigatorPermission';

let _logger: any, _controller: any, _meetingSession: any;

export const microphones = ref<any[]>([]);
export const cameras = ref<any[]>([]);
export const activeMicrophone = ref<any>();
export const activeCamera = ref<any>();
export const microphoneSignalStrength = ref<number>();
export const currentVideoTileId = ref<any>();
export const shareScreenVideoTileId = ref<any>();
export const isSharingScreen = ref<boolean>();

export const initLocalDevices = () => {
    return Promise.all([_initLocalMicrophone(), _initLocalCamera()]);
};

export const initMeetingSession = (meeting: any, attendee: any) => {
    const config = new MeetingSessionConfiguration(meeting, attendee);

    _logger = new ConsoleLogger('Chime logger:', LogLevel.ERROR);

    _controller = new DefaultDeviceController(_logger);
    _controller.addDeviceChangeObserver({
        audioInputsChanged: (freshAudioInputDeviceList) => {
            microphones.value = freshAudioInputDeviceList || [];
        },
        videoInputsChanged: (freshVideoInputDeviceList) => {
            cameras.value = freshVideoInputDeviceList || [];
        },
        // audioInputMuteStateChanged: (device: any, muted) => {},
    });

    _meetingSession = new DefaultMeetingSession(config, _logger, _controller);

    _subscribeMeetingVideo();
    _subscribeVolumeIndicator();

    return _meetingSession;
};

export const startMeetingSession = async (audioElement: HTMLAudioElement) => {
    await _meetingSession?.audioVideo?.startAudioInput(
        activeMicrophone.value?.deviceId
    );

    _meetingSession?.audioVideo?.bindAudioElement(audioElement);
    _meetingSession?.audioVideo?.start();

    _openCamera();
};

export const stopMeetingSession = () => {
    _stopSharingScreen();

    _meetingSession?.audioVideo?.stop();

    _resetMeetingData();
};

export const bindVideoElement = (tileId, videoElement: HTMLVideoElement) => {
    _meetingSession?.audioVideo?.bindVideoElement(tileId, videoElement);
};

export const selectMicrophone = (device) => {
    activeMicrophone.value = device;

    _meetingSession?.audioVideo?.chooseAudioOutput(activeMicrophone.value);

    setLocalStorage(
        StorageConstant.VIDEO_RECORDING_MICRO,
        JSON.stringify(activeMicrophone.value)
    );
};

export const selectCamera = (device) => {
    activeCamera.value = device;

    _openCamera();

    setLocalStorage(
        StorageConstant.VIDEO_RECORDING_CAMERA,
        JSON.stringify(activeCamera.value)
    );
};

export const toggleShareScreen = async () => {
    if (isSharingScreen.value) {
        _stopSharingScreen();
    } else {
        await _meetingSession?.audioVideo?.startContentShareFromScreenCapture();
        isSharingScreen.value = true;
    }
};

const _subscribeMeetingVideo = () => {
    const videoObserver = {
        videoTileDidUpdate: (tileState: VideoTileState) => {
            if (!tileState?.boundAttendeeId) return;

            // Shared screen video
            if (tileState?.isContent) {
                shareScreenVideoTileId.value = tileState?.tileId;
            } else {
                // const userId = tileState?.boundExternalUserId;
                currentVideoTileId.value = tileState?.tileId;
            }
        },
        videoTileWasRemoved: (tileId) => {
            if (shareScreenVideoTileId.value?.tileId === tileId) {
                shareScreenVideoTileId.value = null;
            }
        },
    };

    _meetingSession?.audioVideo?.addObserver(videoObserver);
};

const _subscribeVolumeIndicator = () => {
    const presentAttendeeId =
        _meetingSession.configuration?.credentials?.attendeeId;
    if (!presentAttendeeId) return;

    _meetingSession?.audioVideo?.realtimeSubscribeToVolumeIndicator(
        presentAttendeeId,
        (attendeeId, volume) => {
            microphoneSignalStrength.value = (volume || 0) * 100;
        }
    );
};

const _initLocalMicrophone = async () => {
    const microphonePermission = await checkNavigatorPermission(
        PermissionNameString.microphone
    );
    if (microphonePermission?.state === 'denied') throw 'MICROPHONE_DENIED';

    await navigator.mediaDevices.getUserMedia({ audio: true });

    const devices = await navigator.mediaDevices.enumerateDevices();
    microphones.value = devices?.filter(
        (device) => device.kind === 'audioinput'
    );
    if (!microphones.value?.length) throw 'NO_MICROPHONES';

    // Set first microphone active
    activeMicrophone.value = microphones.value[0];

    // Set local storage microphone active if existed
    const storageMicrophone = getLocalStorage(
        StorageConstant.VIDEO_RECORDING_MICRO
    );

    if (storageMicrophone) {
        const storageMicrophoneJson = JSON.parse(storageMicrophone);
        const storageMicrophoneDevice = microphones.value.find(
            (device) => device?.deviceId == storageMicrophoneJson?.deviceId
        );
        if (storageMicrophoneDevice) {
            activeMicrophone.value = storageMicrophoneDevice;
        }
    }
};

const _initLocalCamera = async () => {
    const cameraPermission = await checkNavigatorPermission(
        PermissionNameString.camera
    );
    if (cameraPermission?.state === 'denied') throw 'CAMERA_DENIED';

    await navigator.mediaDevices.getUserMedia({ video: true });

    const devices = await navigator.mediaDevices.enumerateDevices();
    cameras.value = devices?.filter((device) => device.kind === 'videoinput');
    if (!cameras.value?.length) throw 'NO_CAMERAS';

    // Set first camera active
    activeCamera.value = cameras.value[0];

    // Set local storage camera active if existed
    const storageCamera = getLocalStorage(
        StorageConstant.VIDEO_RECORDING_CAMERA
    );

    if (storageCamera) {
        const storageCameraJson = JSON.parse(storageCamera);
        const storageCameraDevice = cameras.value.find(
            (device) => device?.deviceId == storageCameraJson?.deviceId
        );
        if (storageCameraDevice) activeCamera.value = storageCameraDevice;
    }
};

const _openCamera = async () => {
    await _meetingSession?.audioVideo?.startVideoInput(
        activeCamera.value?.deviceId
    );

    _meetingSession?.audioVideo?.startLocalVideoTile();
};

const _stopSharingScreen = () => {
    _meetingSession?.audioVideo?.stopContentShare();
    isSharingScreen.value = false;
};

const _resetMeetingData = () => {
    currentVideoTileId.value = null;
    shareScreenVideoTileId.value = null;
};
