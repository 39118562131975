<script setup lang="ts">
import { computed, markRaw, ref, watch } from 'vue';
import { Ckeditor } from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import DeletePlugin from '@ckeditor/ckeditor5-typing/src/delete';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import AutoLinkPlugin from '@ckeditor/ckeditor5-link/src/autolink';
import MentionPlugin from '@ckeditor/ckeditor5-mention/src/mention';
import AutoformatPlugin from '@/ui/plugins/ckeditor/autoformat/autoformat';
import PasteLinkPlugin from '@/ui/plugins/ckeditor/paste-link/paste-link';
import CustomLinkTaskTargetPlugin from '@/ui/plugins/ckeditor/autoformat/customlinktask';
import CustomLinkMentionPlugin from '@/ui/plugins/ckeditor/mentions/customlinkmention';
import { renderItemListMentionChat } from '@/ui/modules/messaging/chat-panel/chat-form/chat-input/chat-item-mention/RenderComponentChat.js';
import taskStore from '@/store/task';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { ignoreUnicode } from '@/ui/plugins/utils';
import { getDomainUrl } from '@/ui/hooks/commonHook';
import { isForceActive } from '@/ui/modules/messaging/chat-widget/chat-widget-public-state';
import userStore from '@/store/user';
import { getFullName } from '@/ui/helpers/utils';
import { encryptUserId } from '@/ui/plugins/crypto/crypto';

const props = defineProps<{
  conversation?: any;
  modelValue?: string;
  mentionUserIds?: any[];
  placeholder?: string;
}>();

const emit = defineEmits([
  'update:modelValue',
  'update:mentionUserIds',
  'onGetLinkFromMsg',
  'onEnter',
  'onKeydown',
  'focus',
  'deleteAfter',
  'back',
  'next',
  'backspace',
]);

const _taskStore = taskStore();
let ckEditorInstance;

const currentCycle = computed<{
  currentCycle: number;
  letter: string;
  code: number;
}>(() => _taskStore.currentTaskCycle);
// const listMentionUser = computed(() => _chatStore.getListUserMention);
const listMentionUser = computed(() => {
  // const fullUrlArray = window.location.href?.split('/#');
  // const baseDomainUrl = fullUrlArray?.length
  //     ? fullUrlArray[0]
  //     : import.meta.env.VITE_DOMAIN_DEFAULT;
  const baseDomainUrl = import.meta.env.VITE_DOMAIN_DEFAULT;
  return userStore().listShortInfosUser?.map((user) => {
    return {
      ...user,
      id: `@${user?.firstName?.trim()}`,
      userId: user?.userId,
      name: getFullName(user),
      avatar: user?.avatarUrl || user?.avatar,
      link: `${baseDomainUrl}/tasks/users/${encryptUserId(user?.userId)}`,
      isInGroup: true,
    };
  });
});

const editorRef = ref();
const editor = ref(ClassicEditor);

const editorConfig = computed(() => {
  return {
    toolbar: [],
    startupFocus: true,
    plugins: [
      ParagraphPlugin,
      DeletePlugin,
      AutoformatPlugin,
      LinkPlugin,
      AutoLinkPlugin,
      MentionPlugin,
      PasteLinkPlugin,
      CustomLinkTaskTargetPlugin,
      CustomLinkMentionPlugin,
    ],
    placeholder:
      props.placeholder ||
      `${translate('TASK_TODO_LIST_NEW_ITEM')} (${translate(
        'COMMON_HASH_FOR_TASK'
      )})`,
    link: {
      defaultTaskLink: `${getDomainUrl()}/tasks/`,
      currentCycle: currentCycle.value,
      defaultProtocol: 'https://',
      addTargetToExternalLinks: true,
      decorators: {
        openInNewTab: {
          mode: 'manual',
          label: 'Open in a new tab',
          attributes: {
            target: '_blank',
            rel: 'noopener noreferrer',
          },
        },
      },
    },
    mention: {
      feeds: [
        {
          marker: '@',
          feed: getFeedItems,
          itemRenderer: customItemRenderer,
        },
      ],
    },
    keystrokes: [[13, 'doNothing']],
  };
});

watch(
  () => currentCycle.value,
  () => {
    if (ckEditorInstance?.config) {
      ckEditorInstance?.config?.set('link', {
        defaultTaskLink: `${getDomainUrl()}/tasks/`,
        currentCycle: currentCycle.value,
        defaultProtocol: 'https://',
        addTargetToExternalLinks: true,
        decorators: {
          openInNewTab: {
            mode: 'manual',
            label: 'Open in a new tab',
            attributes: {
              target: '_blank',
              rel: 'noopener noreferrer',
            },
          },
        },
      });
    }
  }
);

const getFeedItems = (queryText) => {
  const querySearch = ignoreUnicode(queryText);
  if (!queryText) return listMentionUser.value.slice(0, 12);
  return listMentionUser.value.filter(
    (el) =>
      ignoreUnicode(el.name)?.includes(querySearch) ||
      ignoreUnicode(el.id)?.includes(querySearch)
  );
};

const customItemRenderer = (item) => {
  return renderItemListMentionChat(item);
};

const handleGetLinkFromMsg = (message) => {
  if (!message || !message.includes('<a') || !message.includes('</a>')) return;

  let elementMessage = document.createElement('html');
  elementMessage.innerHTML = message;

  let mentionArr = Array.prototype.filter
    .call(
      elementMessage.getElementsByTagName('a'),
      (link) =>
        link?.getAttribute('href') &&
        link?.getAttribute('data-mention') &&
        link?.getAttribute('userid') &&
        link?.getAttribute('data-type') == 'mention'
    )
    .map((elLink) => elLink?.getAttribute('userid'));

  let linkArr = Array.prototype.filter
    .call(
      elementMessage.getElementsByTagName('a'),
      (link) =>
        link?.getAttribute('href') &&
        !link?.getAttribute('href').includes('tictop.app') &&
        !link?.getAttribute('href').includes('fiine.pro') &&
        !link?.getAttribute('href').includes('app.tictop.eu') &&
        !link?.getAttribute('href').includes('fiine.pro') &&
        !link?.getAttribute('data-mention') &&
        !link?.getAttribute('userid') &&
        !link?.getAttribute('data-type')
    )
    .map((elLink, index) => {
      let domainUrl = new URL(elLink.getAttribute('href'));
      let domain = domainUrl.hostname.replace('www.', '');

      return {
        title: domain,
        link: elLink.getAttribute('href'),
        index: index,
        previewData: {
          isPreview: false,
          description: '',
          title: domain,
          imageUrl: '',
          domain: domain,
        },
      };
    });

  const linkObjects = linkArr.filter(
    (a, i) => linkArr.findIndex((s) => a.link === s.link) === i
  );

  const mentionUserIds = mentionArr.filter(
    (a, i) => mentionArr.findIndex((s) => a === s) === i
  );

  emit('onGetLinkFromMsg', linkObjects);
  emit('update:mentionUserIds', mentionUserIds);
};

const onChange = (messageText) => {
  if (!props.modelValue && !messageText) return;

  emit('update:modelValue', messageText);
  handleGetLinkFromMsg(messageText);
};

const _getContentText = (fragment) => {
  let textContent = '';

  // Iterate over child nodes and concatenate text content
  fragment.getChild(0)?._children._nodes?.forEach((child) => {
    if (child?._attrs.has('taskLink') && child?._attrs.get('taskLink'))
      textContent += `<a target="_blank" data-type="task" href="${child?._attrs.get(
        'taskLink'
      )}" rel="noopener noreferrer" class="ck-link_selected">${child.data}</a>`;
    else if (child?._attrs.has('linkHref') && child?._attrs.get('linkHref'))
      textContent += `<a target="_blank" data-type="task" href="${child?._attrs.get(
        'linkHref'
      )}" rel="noopener noreferrer" class="ck-link_selected">${child.data}</a>`;
    else textContent += child.is('text') ? child.data : child.getData();
  });

  return textContent;
};

const onEditorReady = (editor) => {
  ckEditorInstance = editor;
  editorRef.value = markRaw(editor);

  // editor.keystrokes.set('Enter', (data, stop) => {
  //   stop(); // Works like data.preventDefault() + evt.stop()
  //   emit('update:modelValue', editor.getData());
  //   // emit('onEnter');
  //   // editor.setData('');
  // });
  editor.keystrokes.set('Backspace', () => {
    const selection = editor.model.document.selection;

    // Get the current position of the cursor
    const cursorPosition = selection.getFirstPosition();

    if (cursorPosition.offset == 0) emit('backspace', editor.getData());
  });

  ckEditorInstance.model.change((writer) => {
    writer.setSelection(editor.model.document.getRoot(), 'end');
  });

  //   ckEditorInstance.editing.view.focus();

  editor.editing.view.document.on('keydown', (evt, data) => {
    const selection = editor.model.document.selection;

    // Get the current position of the cursor
    const cursorPosition = selection.getFirstPosition();

    if (data.keyCode === 13) {
      evt.stop();
      // Get the current selection

      // Trim content from the cursor position to the end
      editor.model.change((writer) => {
        const endPosition = writer.createPositionAt(
          editor.model.document.getRoot(),
          'end'
        );
        const range = writer.createRange(cursorPosition, endPosition);

        const removeSelection = writer.createSelection(range);

        const trimmedValue = editor.model?.getSelectedContent
          ? _getContentText(editor.model.getSelectedContent(removeSelection))
          : '';

        // const removeContent = writer.createDocumentFragment(range);

        // Remove content from cursor position to the end
        writer.remove(range);

        emit('onEnter', {
          currentValue: editor.getData(),
          trimmedValue,
        });
      });

      return;
    }
    if (data.keyCode === 46 && cursorPosition?.isAtEnd) {
      emit('deleteAfter');

      return;
    }
    if (data.keyCode === 38 && cursorPosition?.isAtStart) {
      emit('back');

      return;
    }
    if (data.keyCode === 40 && cursorPosition?.isAtEnd) {
      emit('next');

      return;
    }

    emit('onKeydown', data, {
      position: cursorPosition.offset,
      value: editor.getData(),
    });
  });

  editor.editing.view.document.on('focus', () => {
    onFocus();
  });
};

const focusToEnd = (newValue) => {
  if (!editorRef.value || !ckEditorInstance) return;
  if (newValue) ckEditorInstance.setData(newValue);

  // Get the current position of the cursor
  ckEditorInstance.editing.view.focus();
  const model = ckEditorInstance.editing.model;
  const document = model.document;
  model.change((writer) => {
    writer.setSelection(writer.createPositionAt(document.getRoot(), 'end'));
  });
};
const focusToStart = () => {
  if (!ckEditorInstance) return;

  ckEditorInstance.editing.view.focus();
  const model = ckEditorInstance.editing.model;

  // Create a position at the start of the root
  const startPosition = ckEditorInstance.model.createPositionAt(
    ckEditorInstance.model.document.getRoot(),
    0
  );

  //   console.log(
  //     '🚀 Tictop ~ ckEditorInstance.model.document.selection:',
  //     ckEditorInstance.model.document.selection
  //   );
  //   // Set the selection to the start position
  //   ckEditorInstance.model.document.selection._setFocus(startPosition, 'before');
  model.change((writer) => {
    writer.setSelection(writer.createPositionAt(startPosition, 'start'));
  });
};

const insertEmoji = (emoji) => {
  if (!editorRef.value) return;
  editorRef.value?.model.change((writer) => {
    writer.insertText(
      emoji,
      editorRef.value?.model.document.selection.getFirstPosition()
    );
    // writer.setSelection(
    //   writer.createPositionAt(
    //     editorRef.value?.model.document.getRoot(),
    //     'end'
    //   )
    // );
  });

  ckEditorInstance.editing.view.focus();
};

const onBlur = () => {
  isForceActive.value = false;
};

const onFocus = () => {
  emit('focus');
};
const insertToEnd = (htmlString) => {
  editorRef.value?.model.change((writer) => {
    const currentFirstPosition =
      editorRef.value?.model.document.selection.getFirstPosition();

    const viewFragment = editorRef.value?.data.processor.toView(htmlString);
    const modelFragment = editorRef.value?.data.toModel(viewFragment);
    editorRef.value?.model.insertContent(modelFragment);

    writer.setSelection(writer.createPositionAt(currentFirstPosition, 'end'));
  });
};

defineExpose({
  focusToStart,
  focusToEnd,
  insertEmoji,
  insertToEnd,
});
</script>
<template>
  <Ckeditor
    :model-value="modelValue"
    :editor="editor"
    :config="editorConfig"
    @ready="onEditorReady"
    @update:model-value="onChange"
    @blur="onBlur"
    @focus="onFocus"
  />
</template>
<style scoped></style>
