<script setup lang="ts">
import { onMounted, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue: string | number;
    placeholder?: string;
    inputEvents?: any;
    isDisabled?: boolean;
    isRequired?: boolean;
    autofocus?: boolean;
    minDate?: Date | string | number;
    maxDate?: Date | string | number;
    customClass?: string;
    isFocusing?: boolean;
  }>(),
  {
    placeholder: 'dd-mm-yyyy',
    inputEvents: () => {},
    minDate: undefined,
    maxDate: undefined,
    customClass: 'vig-input py-1.5 pl-4',
  }
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number): void;
  (e: 'enter', value: string | number): void;
  (e: 'onClear'): void;
  (e: 'focus'): void;
  (e: 'blur'): void;
  (e: 'update:isFocusing', value: boolean): void;
  (e: 'input', value: boolean): void;
}>();

const inputDateRef = ref(null as any);

const onClear = () => {
  emit('onClear');
};

onMounted(() => {
  if (props.autofocus) {
    setTimeout(() => {
      inputDateRef.value?.focus();
    });
  }
});

const isFocusing = ref<boolean>(false);
const onFocus = () => {
  isFocusing.value = true;
  emit('update:isFocusing', true);
  emit('focus');
};
const onBlur = () => {
  isFocusing.value = false;
  emit('update:isFocusing', false);
  emit('blur');
};

const focus = () => {
  if (inputDateRef.value) inputDateRef.value?.focus();
};

const onInput = (event) => {
  // const value = clearDoubleSpaces(event.target.value);
  const value = event.target.value;

  emit('input', value);
};

defineExpose({
  focus,
});
</script>
<template>
  <div class="flex-center relative">
    <input
      ref="inputDateRef"
      :value="modelValue"
      :class="[customClass, 'py-1.5 pl-4 pr-7']"
      :autofocus="autofocus"
      :placeholder="isFocusing ? 'dd/mm/yyyy' : placeholder || 'dd/mm/yyyy'"
      :disabled="isDisabled"
      @update:model-value="(value) => $emit('update:modelValue', value)"
      @change="(event) => $emit('update:modelValue', event?.target?.value)"
      @keyup.enter.prevent="(value) => $emit('enter', value)"
      v-on="inputEvents"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
    />
    <span class="flex-center absolute right-2 cursor-pointer" @click="onClear">
      <SynIcon v-if="modelValue && !isDisabled && !isRequired" name="close" />
      <SynIcon v-else class="fill-gray-500" name="calendars" />
    </span>
  </div>
</template>
