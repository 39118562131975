<script setup lang="ts">
import { computed, ref } from 'vue';
import { getConfigScheduleColor, getBgClass } from '@/ui/hooks/taskHook';
import { TaskLife } from '@/domain/enums/taskEnum';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import taskDrawerStore from '@/store/task/drawer';
import { TaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import { PlanTypeEnum } from '@/domain/enums/taskEnum';
import taskStore from '@/store/task';
import DomainById from '@/ui/modules/domains/component/DomainById.vue';
import bindingInterfaceTaskComposables from '@/ui/composables/task/binding-interface-task';
import UserByAction from '@/ui/components/user/UserByAction.vue';
import GroupById from '@/ui/components/group/GroupById.vue';
import UserById from '@/ui/components/user/UserById.vue';
import TaskDetailField from '@/ui/modules/task/components/TaskDetailField.vue';
import settingStore from '@/store/setting';
import ManageCollaborator from '@/ui/modules/task/detail/assign/ManageCollaborator.vue';
import commonActionsTaskComposables from '@/ui/composables/task/common-actions-task';
import TaskDetailAttachmentInfo from '@/ui/modules/task/components/task-list/TaskDetailAttachmentInfo.vue';
import { isOpenTaskDrawer } from '@/ui/modules/task/task-global-state';
import ModalNewChat from '@/ui/modules/messaging/modal-new-chat/ModalNewChat.vue';
import { taskStates } from '@/ui/hooks/taskHook';
// import { TASK_PIN_BY_CODE } from '@/application/constants/task.const';
import SetPinTask from '@/ui/modules/task/components/pin/SetPinTask.vue';
import { TASK_PIN_BY_CODE } from '@/application/constants/task.const';
import {
  TaskApprovalStatus,
  EActionOnTask,
} from '@/application/types/task/task.types';
import ActionOnTask from '@/ui/modules/task/detail/action-on-task/ActionOnTask.vue';
import TaskSharingModal from '@/ui/modules/task/sharing/modal-task-sharing/TaskSharingModal.vue';
import TaskSnapshotsModal from '@/ui/modules/task/sharing/modal-task-shapshots/TaskSnapshotsModal.vue';
import TaskDeadlineBlockingShortInfo from '@/ui/modules/task/deadline-blocking/TaskDeadlineBlockingShortInfo.vue';
import TaskApprovalModal from '@/ui/modules/task/approval/TaskApprovalModal.vue';

const props = withDefaults(
  defineProps<{
    taskDetail: TaskDetailClass;
    readonly?: boolean;
    isDraggable?: boolean;
    isSelected?: boolean;
    isSelecting?: boolean;
    selectable?: boolean;
    isHiddenShowMore?: boolean;
    disabledOpenDrawer?: boolean;
    isChangeDeadline?: boolean;
  }>(),
  {
    taskDetail: () => new TaskDetailClass({}),
    readonly: false,
  }
);

const emits = defineEmits(['onClickDetail', 'unPinSuccess']);

const _taskDrawerStore = taskDrawerStore();
const _taskStore = taskStore();
const _settingStore = settingStore();

const chatEnabled = computed(() => _settingStore.chatEnabled);
const currentTaskId = computed(() => _taskDrawerStore.currentTabId);
const { isTaskHasJustCreate, hasPermission, isEditable } =
  bindingInterfaceTaskComposables(props?.taskDetail);

const taskStateList = computed(() => {
  if (!taskStates) return [];
  const stateList = Object.values(taskStates);

  return stateList.sort((a, b) => {
    return a.index - b.index;
  });
});

const {
  taskDetail: taskDetailRef,
  isOpenRequestConfirmModal,
  viewModeProvideEvidence,
  canChangeDeadline,
  isNewChat,
  // onChangeUrgency,
  onChangeTaskState,
  // onChangeAssign,
  onCloneTask,
  onDelete,
  onChangeApprovalStatus,
  onChangePin,
  onCopyLink,
  onToggleLockDeadline,
  goToTaskParent,
  openTaskInDrawer,
} = commonActionsTaskComposables({
  currentTask: props?.taskDetail,
  currentTaskId: props?.taskDetail?.id,
});

const isDraggableRef = computed<boolean>(() => {
  return (
    props.isDraggable &&
    (!props.isChangeDeadline ||
      (props.isChangeDeadline && canChangeDeadline.value))
  );
});

const multipleAssigneeSetting = computed(
  () =>
    _settingStore._organizationSetting.taskManagement?.multipleAssigneeSetting
);
const openTaskDetail = () => {
  if (props.disabledOpenDrawer) {
    emits('onClickDetail');
    return;
  }

  openTaskInDrawer();
};

const getConfigName = (sitKey, typeKey) => {
  return _taskStore.getConfigName(sitKey, typeKey);
};

const getConfigScheduleName = (data) => {
  return _taskStore.getConfigScheduleName(data);
};

const isShowMore = ref<boolean>(false);
const isShowOptions = ref<boolean>(false);

const isOpenTaskSharing = ref();
const isOpenTaskSnapshots = ref();

const onSelectActionOnTask = (actionKey) => {
  switch (actionKey) {
    case EActionOnTask.FINISH:
      onChangeTaskState(TaskLife.Finished);
      break;
    case EActionOnTask.DISCUSS:
      isNewChat.value = true;
      break;
    case EActionOnTask.CLONE:
      onCloneTask();
      break;
    case EActionOnTask.RELOAD:
      break;
    case EActionOnTask.SET_REPEAT:
      break;
    case EActionOnTask.COPY_LINK:
      onCopyLink();
      break;
    case EActionOnTask.SHARE:
      isOpenTaskSharing.value = true;
      break;
    case EActionOnTask.SNAPSHOT:
      isOpenTaskSnapshots.value = true;
      break;
    case EActionOnTask.WORKFLOW:
      break;
    case EActionOnTask.APPROVAL:
      break;
    case EActionOnTask.REMINDER_TASK:
      break;
    case EActionOnTask.PIN:
      onChangePin(
        !taskDetailRef.value?.pinnedColor ||
          taskDetailRef.value?.pinnedColor == 'UN_PIN'
          ? TASK_PIN_BY_CODE.PIN_GRAY
          : ''
      );
      break;
    case EActionOnTask.EXPAND:
      break;
    case EActionOnTask.VIEW_DETAIL:
      openTaskDetail();
      break;

    default:
      break;
  }
};
</script>

<template>
  <div
    v-bind="$attrs"
    :title="taskDetailRef?.name"
    class="
      px-2
      pt-2
      pb-4
      relative
      bg-white
      task-card-item
      hover-to-hidden__parent
      flex flex-col
      space-y-1
      shadow-sm
      rounded
    "
    :class="{
      'ring-1 ring-current': isShowMore,
      'hover:bg-current-50 hover:text-current-800': !readonly,
      'task-card-item_handle': isDraggableRef,
    }"
    :style="
      isDraggableRef ? 'cursor: grab;' : !readonly ? 'cursor: pointer;' : ''
    "
    @click="openTaskDetail"
  >
    <div
      v-if="selectable"
      class="
        group
        h-8
        w-8
        flex-center
        rounded-full
        cursor-pointer
        absolute
        top-0
        left-0
      "
      :class="{
        'hover-to-show__children': !isSelecting,
      }"
      @click.stop="openTaskDetail"
    >
      <div
        class="
          cursor-pointer
          h-4
          w-4
          flex-center
          rounded
          border border-current-300
          group-hover:border-2
        "
        :class="{
          'bg-current-300': isSelected,
          'bg-white': !isSelected,
        }"
      >
        <SynIcon v-if="isSelected" name="check" class="text-white" />
      </div>
    </div>
    <!-- SELECTED BORDER -->
    <span
      v-if="currentTaskId == taskDetailRef?.id"
      :class="isOpenTaskDrawer ? 'border-current' : 'border-gray-300'"
      class="absolute left-0 top-0 h-full border-2"
    />

    <!-- PIN ICON -->
    <span
      v-if="
        taskDetailRef?.pinnedColor && taskDetailRef?.pinnedColor !== 'UN_PIN'
      "
      class="absolute -left-1 -top-2 z-10"
      @click.stop.prevent="() => {}"
    >
      <!-- <SynIcon
        name="pin"
        custom-class="w-5 h-5"
        is-active
        :style="{
          fill: currentPin?.color,
        }"
      /> -->
      <SetPinTask
        :current-pin-code="taskDetailRef?.pinnedColor"
        @on-change-pin="onChangePin(TASK_PIN_BY_CODE.PIN_GRAY)"
        @on-unpin="onChangePin(null)"
      />
    </span>
    <!-- NEW TAG -->
    <span
      v-if="isTaskHasJustCreate(taskDetail)"
      class="
        absolute
        top-0
        left-2
        text-2xs
        bg-current
        h-max
        items-end
        text-white
        px-1
        rounded
      "
    >
      {{ $t('COMMON_LABEL_NEW') }}
    </span>
    <div class="absolute top-2 right-2 flex items-center space-x-1">
      <SynIcon
        v-if="taskDetailRef?.isPrivate"
        name="private"
        class="fill-current"
        custom-class="w-2.5 h-2.5"
      />
      <TaskDeadlineBlockingShortInfo
        v-if="taskDetailRef?.scheduleTimeLocked"
        :readonly="!isEditable || !canChangeDeadline"
        @on-cancel-block-deadline="onToggleLockDeadline(false)"
      />
      <div
        class="w-6 h-6"
        :class="{
          'hover-to-hidden__children': !readonly,
        }"
      >
        <UserByAction
          v-if="taskDetailRef?.assigneeId"
          :user-id="taskDetailRef?.assigneeId"
          is-hidden-name
          is-show-action-user
          avatar-class="w-6 h-6 border border-white"
        />

        <GroupById
          v-else-if="taskDetailRef?.groupId"
          :group-id="taskDetailRef?.groupId"
          avatar-class="w-6 h-6 border border-white"
          is-hidden-name
        />
      </div>
      <div
        class="task-card-item_hover-to-show bg-white rounded"
        @click.stop.prevent="() => {}"
      >
        <slot name="moreActions"></slot>
        <ActionOnTask
          :current-task="taskDetailRef"
          :chat-enabled="chatEnabled"
          :readonly="!isEditable || readonly"
          :task-code="taskDetailRef?.code"
          :current-pin-code="taskDetailRef?.pinnedColor"
          :action-list="[
            EActionOnTask.FINISH,
            EActionOnTask.DISCUSS,
            EActionOnTask.CLONE,
            EActionOnTask.SHARE,
            EActionOnTask.SNAPSHOT,
            EActionOnTask.PIN,
            EActionOnTask.COPY_LINK,
            EActionOnTask.VIEW_DETAIL,
            EActionOnTask.SUB_TASK,
          ]"
          is-hidden-tool
          @select-action="onSelectActionOnTask"
          @delete="onDelete"
          @on-finish-task="onChangeTaskState(TaskLife.Finished)"
        >
          <template #status>
            <div
              :title="$t('TASK_STATE_LIST_TITLE')"
              class="
                py-2
                flex
                items-center
                justify-between
                rounded
                px-2
                relative
                text-sm
              "
              :class="[
                taskStates[taskDetailRef?.taskLife]?.background,
                taskStates[taskDetailRef?.taskLife]?.color,
                hasPermission ? 'cursor-pointer hover:bg-current-50' : '',
              ]"
              @click.stop="isShowOptions = !isShowOptions"
            >
              <div class="flex-center space-x-2.5">
                <SynIcon name="state" custom-class="w-4 h-4 fill-gray-500" />
                <div class="truncate" style="max-width: 7rem">
                  {{
                    $t(taskStates[taskDetailRef?.taskLife]?.nameCode) ||
                    $t('TASK_STATE_CREATED')
                  }}
                </div>
              </div>
              <SynIcon
                v-if="hasPermission"
                :name="'sort-down'"
                :class="[
                  taskStates[taskDetailRef?.taskLife]?.fill,
                  !isShowOptions ? '-rotate-90' : '',
                ]"
                custom-class="h-2.5 w-2.5"
              />

              <!-- STATE LIST -->
              <div
                v-show="isShowOptions"
                class="
                  absolute
                  top-0
                  -right-1
                  translate-x-full
                  bg-white
                  rounded
                  shadow
                "
              >
                <div v-for="status in taskStateList" :key="status?.key">
                  <div
                    class="
                      p-2
                      cursor-pointer
                      w-32
                      dropdown-item
                      text-sm
                      font-medium
                      truncate
                    "
                    :class="`hover:${status?.background} ${
                      taskDetailRef?.taskLife === status?.key
                        ? status?.background
                        : ''
                    }`"
                    @click="onChangeTaskState(status?.key)"
                  >
                    <div class="flex items-center justify-between space-x-2">
                      <span
                        :class="status?.color"
                        :title="$t(status?.nameCode)"
                      >
                        {{ $t(status?.nameCode) }}
                      </span>
                      <!-- <span
                        class="p-1 rounded-full"
                        :class="`bg-${status?.colorKey}`"
                      ></span> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </ActionOnTask>
      </div>
    </div>
    <div class="flex flex-col">
      <section
        v-if="taskDetailRef?.parentId"
        class="
          w-3/4
          items-center
          inline-flex
          text-sm
          gap-2
          hover:underline
          cursor-pointer
        "
        @click.stop="goToTaskParent"
      >
        <p class="inline text-gray-600 truncate">
          {{ taskDetailRef?.parentName }}
        </p>
        <SynIcon name="SortDownThin" custom-class="h-2.5 w-2.5 fill-gray-600" />
      </section>
      <span class="break-words text-overflow-hidden text-base">
        <div class="flex items-center space-x-1">
          <span
            v-encode-html="{ text: taskDetailRef?.name }"
            class="text-overflow-hidden text-[0.91rem]"
          >
          </span>
        </div>
      </span>
    </div>
    <div class="w-full flex justify-between items-center py-1">
      <div class="flex space-x-1 overflow-hidden">
        <PriorityTask
          size="mini"
          :urgency="taskDetailRef?.urgency"
          is-hidden-label
        />
        <SynTag
          v-if="
            taskDetailRef?.taskSchedule?.planType !== PlanTypeEnum.Nothing &&
            taskDetailRef?.taskLife !== TaskLife.Finished
          "
          class="font-normal"
          size="small"
          :class="getBgClass(taskDetailRef?.taskSchedule?.planType, 'planType')"
        >
          {{ getConfigName(taskDetailRef?.taskSchedule?.planType, 'planType') }}
        </SynTag>
        <SynTag
          v-else-if="taskDetailRef?.taskLife == TaskLife.Finished"
          size="small"
          :class="getBgClass(taskDetailRef?.taskLife, 'taskLife')"
        >
          {{ getConfigName(taskDetailRef?.taskLife, 'taskLife') }}
        </SynTag>
        <SynTag
          v-if="taskDetailRef?.scheduleTime"
          class="border bg-white"
          size="small"
          :class="getConfigScheduleColor(taskDetailRef?.taskSchedule)"
        >
          <div>
            <span
              v-if="
                taskDetailRef?.assigneeId && getConfigScheduleName(taskDetail)
              "
            >
              {{ getConfigScheduleName(taskDetail) }}
            </span>
            <SynLabelDateTime
              v-else-if="taskDetailRef?.scheduleTime"
              format="date"
              :datetime="taskDetailRef?.scheduleTime"
            />
            <span v-else>
              {{ $t('TASK_DETAIL_LABEL_NO_SCHEDULE') || 'No schedule' }}
            </span>
          </div>
        </SynTag>

        <TaskDetailAttachmentInfo
          :task="taskDetail"
          :total-files="taskDetailRef?.attachmentCount"
          :total-notes="taskDetailRef?.noteCount"
          :total-comments="taskDetailRef?.totalComment"
          :total-todo-lists="taskDetailRef?.taskTodoTotal"
          :total-sub-tasks="taskDetailRef?.totalSubTask"
          @open-task-detail="openTaskDetail"
        />
      </div>
      <div class="flex flex-col items-center space-x-1">
        <span class="text-current-800 text-xs font-bold">
          <DomainById :domain-id="taskDetailRef?.domainId" />
        </span>
      </div>
    </div>

    <!-- MORE INFORMATION -->
    <div v-if="isShowMore" class="py-4">
      <div class="w-full flex items-center">
        <TaskDetailField>
          <template #fieldName>
            <span>{{ $t('TASK_LABEL_ASSIGNEE') }}</span>
          </template>
          <template #body>
            <div
              class="
                flex-center
                space-x-1
                relative
                rounded
                text-xs
                font-medium
                px-2
                py-2
              "
            >
              <div
                v-if="taskDetailRef?.groupId"
                class=""
                :style="taskDetailRef?.assigneeId ? 'flex: 50%' : 'flex: 100%'"
              >
                <GroupById
                  :group-id="taskDetailRef?.groupId"
                  avatar-class="w-6 h-6 text-xs"
                />
              </div>
              <span
                v-if="taskDetailRef?.assigneeId && taskDetailRef?.groupId"
                class="px-2 text-gray-500"
                >→</span
              >
              <div
                v-if="taskDetailRef?.assigneeId"
                class=""
                :style="taskDetailRef?.groupId ? 'flex: 50%' : 'flex: 100%'"
              >
                <UserById
                  is-show-first-name
                  avatar-class="w-6 h-6 text-xs"
                  :user-id="taskDetailRef?.assigneeId"
                />
              </div>
            </div>
          </template>
        </TaskDetailField>
        <template
          v-if="
            multipleAssigneeSetting?.systemEnable &&
            multipleAssigneeSetting?.organizationEnable &&
            taskDetailRef?.collaborators?.length > 0
          "
        >
          <TaskDetailField>
            <template #fieldName>
              <span>{{ $t('COMMON_LABEL_COLLABORATORS') }}</span>
            </template>
            <template #body>
              <div
                class="flex-1 px-4 flex items-center justify-between space-x-6"
              >
                <div class="flex-1 flex-center space-x-2">
                  <ManageCollaborator
                    ref="manageCollaboratorRef"
                    class="flex-1"
                    readonly
                    :group-id="taskDetailRef?.groupId || 0"
                    :assignee-id="taskDetailRef?.assigneeId || 0"
                    :collaborators="taskDetailRef?.collaborators"
                  />
                </div>
              </div>
            </template>
          </TaskDetailField>
        </template>
      </div>
      <!-- DESCRIPTION -->
      <div>
        <span
          class="task-detail_description break-words text-gray-500"
          v-html="taskDetailRef?.description"
        ></span>
      </div>
    </div>
    <div
      class="
        w-full
        flex
        items-center
        justify-between
        text-gray-500
        absolute
        right-2
        bottom-0
        pb-1
        pl-4
        text-[0.65rem]
      "
    >
      <div class="items-center space-x-1 task-card-item_hover-to-show">
        <span>By</span>
        <UserById
          v-if="taskDetailRef?.creatorId"
          :user-id="taskDetailRef?.creatorId"
          is-hidden-avatar
          is-position-tag
        />
        <SynLabelDateTime
          format="date_time"
          :datetime="taskDetailRef?.creationTime"
        />
      </div>
      <span></span>
      <span class="hover-to-hidden__children">
        {{
          taskDetailRef?.code?.substring(
            taskDetailRef?.code.indexOf('-') > -1
              ? taskDetailRef?.code.indexOf('-') + 1
              : 0,
            taskDetailRef?.code.length
          )
        }}
      </span>
      <span
        v-if="!isHiddenShowMore"
        class="
          task-card-item_hover-to-show
          hover:bg-current-50
          cursor-pointer
          rounded-full
          items-center
          justify-center
          w-6
          h-4
        "
        @click.stop.prevent="isShowMore = !isShowMore"
      >
        <SynIcon
          :name="isShowMore ? 'chevron-double-up' : 'chevron-double-down'"
        />
      </span>
    </div>
  </div>

  <ModalNewChat
    v-if="isNewChat"
    :title="
      $t('COMMON_LABEL_DISCUSS_ABOUT_WITH') || 'Discuss about this task with'
    "
    :attached-task="taskDetail"
    @on-close="isNewChat = false"
    @on-conversation-click="isNewChat = false"
  />

  <TaskSharingModal
    v-if="isOpenTaskSharing"
    :task="taskDetail"
    @on-close="isOpenTaskSharing = false"
  />

  <TaskSnapshotsModal
    v-if="isOpenTaskSnapshots"
    :task="taskDetail"
    @on-close="isOpenTaskSnapshots = false"
  />

  <TaskApprovalModal
    v-if="isOpenRequestConfirmModal"
    :task="taskDetail"
    :approval-status="TaskApprovalStatus.WAITING"
    :view-mode="viewModeProvideEvidence"
    @on-close="isOpenRequestConfirmModal = false"
    @confirm="
      (comment) => onChangeApprovalStatus(TaskApprovalStatus.WAITING, comment)
    "
  />
</template>
<style lang="scss">
.task-card-item_hover-to-show {
  display: none;
}
.task-card-item:hover .task-card-item_hover-to-show {
  display: flex;
}
</style>
