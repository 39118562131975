<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import appStore from '@/store/app';
import {
  CallParticipantStatus,
  ConferenceTypeEnum,
  AnswerState,
  CallDeviceState,
  CallActionTypeEnum,
} from '@/domain/enums/VideoCallEnum';
import { updateFirebaseVideoCallForUser } from '@/ui/modules/video-call/hooks/firebase-helper';
import { UserStatusEnum } from '@/domain/enums/UserStatusEnum';
import { updateAudioVideoCallData } from '@/ui/modules/video-call/hooks/firebase-helper';
import dashboardStore from '@/store/dashboard';
import { openVideoCallPage } from '@/ui/modules/video-call/hooks/conference-helper';
import conferenceStore from '@/store/conference';
import UserDynamicVideoCallModel from '@/ui/plugins/firebases/realtime-database/model/UserDynamicVideoCallModel';
import myProfileStore from '@/store/auth/my-profile';
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';

const _dashboardStore = dashboardStore();
const _appStore = appStore();
const _conferenceStore = conferenceStore();
const isRinging = ref(false);
const videoCallData = ref({});
const ringingCallIds = ref([] as string[]);
const currentCallId = ref('' as string);
const joinToken = ref('');

const myProfile = computed(() => myProfileStore().myProfile);
const myId = computed(() => myProfile.value?.id);
// const participants = computed(() => videoCallData.value?.participants);
const callData = computed(() => videoCallData.value[currentCallId.value]);
const isInvited = computed(
  () => callData.value?.actionType == CallActionTypeEnum.Invited
);
const currentTabBrowerId = computed(() => _appStore.currentTabId);
const myCallStatus = computed(() =>
  _dashboardStore.getUserStatus(getCurrentUserId())
);
let unscibleChildChange;
let unscibleDataChange;
const onInit = async () => {
  const userId = getCurrentUserId();
  if (!userId) return;
  // Update user status for Organization

  const videoCallFbClass = new UserDynamicVideoCallModel(userId);

  unscibleDataChange = videoCallFbClass.subcribleHasNewCall((data) => {
    // console.log('subcribleHasNewCall', data);
    handleUserHasNewCall(data);
  });

  // console.log('videoCallFbClass.onChildChanged');
  unscibleChildChange = videoCallFbClass.onChildChanged((videoCallObj) => {
    handleUserVideoDataChange(videoCallObj);
  });
};

const onTheNextCall = () => {
  // console.log('onTheNextCall 1');
  openOrCloseRinging(false);
  currentCallId.value = '';
  if (
    myCallStatus.value?.includes(UserStatusEnum.OnCall) ||
    ringingCallIds.value.length == 0
  ) {
    return;
  }

  currentCallId.value = ringingCallIds.value[0];

  const videoCallObj = videoCallData.value[currentCallId.value];

  joinToken.value = videoCallObj?.joinToken;

  // console.log('onTheNextCall 2');
  openOrCloseRinging(true);
};

const onClearData = () => {
  ringingCallIds.value = ringingCallIds.value.filter(
    (o) => o !== currentCallId.value
  );
  if (!currentCallId.value) return;
  // 1. update mine
  updateFirebaseVideoCallForUser(myId.value, currentCallId.value, {
    status: CallParticipantStatus.Missed,
  });

  // 2.1 Update call participants
  updateAudioVideoCallData(
    `${currentCallId.value}/participants/${myId.value}`,
    {
      status: CallParticipantStatus.Missed,
    }
  );
  currentCallId.value = '';
};

const audioRingtoneRef = ref(null as any);
const openOrCloseRinging = (isOpen) => {
  isRinging.value = isOpen;

  if (!audioRingtoneRef.value) return;

  setTimeout(() => {
    const currentMainTabId = _appStore.getMainBrowserTab();
    if (
      isOpen &&
      (currentTabBrowerId.value == currentMainTabId || !currentMainTabId)
    ) {
      // console.log('audioRingtoneRef.value?.play()');
      audioRingtoneRef.value?.play();
    } else {
      audioRingtoneRef.value?.pause();
      audioRingtoneRef.value.currentTime = 0;
    }
  }, 500);
};

const acceptedTimeBefore = ref(0);

const handleUserHasNewCall = (videoCallObj) => {
  const callId = videoCallObj?.callId;
  const isHost = videoCallObj?.isHost;
  const status = videoCallObj?.status;
  const actionType = videoCallObj?.actionType;

  if (
    !callId ||
    isHost ||
    status !== CallParticipantStatus.Ringing ||
    (callId == currentCallId.value &&
      Date.now() - acceptedTimeBefore.value < 10000)
  ) {
    return;
  }

  acceptedTimeBefore.value = Date.now();

  ringingCallIds.value = [...new Set([...ringingCallIds.value, callId])];

  let durationTime = videoCallObj?.durationTime;

  if (actionType == CallActionTypeEnum.Invited) {
    const remainsTime = Math.trunc(
      ((videoCallObj?.planedEndTime || 0) - Date.now()) / 60000
    );
    durationTime = remainsTime > 0 ? remainsTime : durationTime;
  }

  videoCallData.value[callId] = {
    ...videoCallObj,
    callType:
      videoCallObj?.callType == 2
        ? ConferenceTypeEnum.Video
        : ConferenceTypeEnum.Audio,
    durationTime,
  };

  onTheNextCall();
};

const handleUserVideoDataChange = (videoCallObj) => {
  // console.log('handleUserVideoDataChange', videoCallObj);
  const callId = videoCallObj?.callId;
  if (!callId) return;

  if (videoCallObj?.status == CallParticipantStatus.Ringing) {
    if (ringingCallIds.value?.every((id) => id !== callId)) {
      handleUserHasNewCall(videoCallObj);
    }
    return;
  }

  ringingCallIds.value = ringingCallIds.value.filter((id) => id !== callId);
  delete videoCallData.value[callId];

  openOrCloseRinging(false);

  _conferenceStore.getHistories();
};

onMounted(() => {
  onInit();
});

onUnmounted(() => {
  unscibleDataChange && unscibleDataChange();
  unscibleChildChange && unscibleChildChange();
});

const onConfirmCallVideo = async (turnOffCamera) => {
  // console.log('onConfirmCallVideo');
  openOrCloseRinging(false);

  if (!joinToken.value) return;

  await _conferenceStore.answer(
    joinToken.value,
    AnswerState.Accept,
    turnOffCamera ? CallDeviceState.Close : CallDeviceState.Open
  );
  openVideoCallPage(
    joinToken.value,
    Object.values(callData.value?.participants)?.map((par: any) => {
      return {
        name: par?.name,
        avatar: par?.avatar?.split('/')?.pop() || '',
      };
    })
  );
};

const onBusy = () => {
  // console.log('onBusy');
  openOrCloseRinging(false);

  const jToken = joinToken.value;

  _conferenceStore.answer(jToken, AnswerState.Decline, CallDeviceState.Close);

  onClearData();
  onTheNextCall();
};
</script>

<template>
  <syn-modal
    v-if="isRinging"
    z-index="z-50"
    disable-click-outside
    is-hidden-header
    is-hidden-footer
    is-hidden-close
    container-class="w-96 p-4"
  >
    <template #body>
      <div class="flex-center space-x-2 pt-2 text-2xl font-semibold">
        <div class="flex-center w-6 h-6 rounded-full bg-current">
          <SynIcon
            :name="
              callData?.callType == ConferenceTypeEnum.Video ? 'video' : 'phone'
            "
            class="fill-white"
            custom-class="w-3 h-3"
          />
        </div>
        <span>
          {{
            callData?.callType == ConferenceTypeEnum.Video ? 'Video' : 'Audio'
          }}
          call
        </span>
      </div>
      <div class="flex flex-col items-center w-full">
        <!--  INFOMATION CALL      -->
        <div class="w-full flex-center flex-col space-y-4">
          <div
            class="
              coccoc-alo-phone coccoc-alo-green coccoc-alo-show
              flex-center
            "
          >
            <div class="coccoc-alo-ph-circle"></div>
            <div class="coccoc-alo-ph-circle-fill"></div>
            <syn-avatar
              custom-class="h-20 w-20"
              name-icon="phone"
              :src="callData?.hosterAvatar"
              :name="callData?.hosterName"
            />
          </div>

          <div class="flex-center space-x-2 flex-wrap">
            <span class="font-semibold text-current-800">
              {{ callData?.hosterName || 'Fiine' }}
            </span>
            <span class="animate-pulse">
              {{
                isInvited
                  ? $t('VIDEO_CALL_STATUS_INVITED_YOU') ||
                    'invited you to a call...'
                  : $t('VIDEO_CALL_STATUS_IS_CALLING_YOU') ||
                    'is calling you...'
              }}
            </span>
          </div>
          <div class="flex justify-between items-baseline space-x-2 text-sm">
            <span>{{ $t('COMMON_LABEL_PLANED_TIME') }}</span>
            <span class="font-semibold text-lg text-green-500 space-x-2">
              {{ callData?.durationTime || 0 }}
              <span>{{ $t('COMMON_LABEL_MINUTE') }}</span>
            </span>
          </div>
        </div>
        <!--  BUTTON ACTION    -->
        <div class="flex-center px-2 py-6 space-x-6">
          <SynButton
            custom-class="w-14 h-14 rounded-full"
            color="red"
            name-icon="phoneEnd"
            @click="onBusy"
          />
          <SynButton
            custom-class="w-14 h-14 rounded-full coccoc-alo-ph-img-circle animate-ping"
            color="green"
            :custom-class-icon="
              callData?.callType === ConferenceTypeEnum.Video
                ? 'h-6 w-6'
                : 'h-5 w-5'
            "
            :name-icon="
              callData?.callType === ConferenceTypeEnum.Video
                ? 'video'
                : 'phone'
            "
            @click="onConfirmCallVideo(false)"
          />
        </div>
        <!--   TEXT CHOOSE CALL     -->
        <div
          v-if="callData?.callType === ConferenceTypeEnum.Video"
          class="
            flex-center
            space-x-2
            bg-current-50 bg-opacity-50
            border border-current-50
            w-max
            text-current-800
            cursor-pointer
            hover:text-current hover:bg-current-50
            px-10
            py-3
            rounded
            mt-4
          "
          @click="onConfirmCallVideo(true)"
        >
          <div class="w-5 h-5 flex-center rounded-full bg-current">
            <SynIcon name="phone" custom-class="w-2.5 h-2.5 fill-white" />
          </div>
          <span>{{ $t('COMMON_LABEL_CALL_CHOOSE_AUDIO') }}</span>
        </div>
      </div>
    </template>
  </syn-modal>
  <audio ref="audioRingtoneRef" class="hidden" loop>
    <source
      :src="`${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/audio/waiting-ringtone.wav`"
      type="audio/ogg"
    />
  </audio>
</template>
<style scoped>
.coccoc-alo-ph-circle {
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  position: absolute;
  background-color: transparent;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid rgba(30, 30, 30, 0.4);
  opacity: 0.1;
  -webkit-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -moz-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -ms-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -o-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.coccoc-alo-phone {
  background-color: transparent;
  width: 200px;
  height: 200px;
  cursor: pointer;
  z-index: 200000 !important;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -webkit-transition: visibility 0.5s;
  -moz-transition: visibility 0.5s;
  -o-transition: visibility 0.5s;
  transition: visibility 0.5s;
  right: 150px;
  top: 30px;
}

.coccoc-alo-phone-icon {
  background-color: transparent;
  width: 70px;
  height: 70px;
  cursor: pointer;
  z-index: 200000 !important;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -webkit-transition: visibility 0.5s;
  -moz-transition: visibility 0.5s;
  -o-transition: visibility 0.5s;
  transition: visibility 0.5s;
  right: 150px;
  top: 30px;
}

.coccoc-alo-ph-img-circle {
  -webkit-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  -moz-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  -ms-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  -o-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
}

.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-circle-fill {
  background-color: rgba(0, 141, 141, 0.5);
  opacity: 0.75 !important;
}

.coccoc-alo-phone-icon.coccoc-alo-green .coccoc-alo-ph-circle-fill {
  background-color: rgba(0, 141, 141, 0.5);
  opacity: 0.75 !important;
}

.coccoc-alo-ph-circle-fill {
  width: 150px;
  height: 150px;
  top: 25px;
  left: 25px;
  position: absolute;
  background-color: #000;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: 0.1;
  -webkit-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -moz-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -ms-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -o-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-img-circle {
  background-color: #008d8d;
}

.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-circle {
  border-color: #008d8d;
  opacity: 0.5;
}

.coccoc-alo-phone.coccoc-alo-green.coccoc-alo-hover .coccoc-alo-ph-circle,
.coccoc-alo-phone.coccoc-alo-green:hover .coccoc-alo-ph-circle {
  border-color: #75eb50;
  opacity: 0.5;
}

.coccoc-alo-phone.coccoc-alo-green.coccoc-alo-hover .coccoc-alo-ph-circle-fill,
.coccoc-alo-phone.coccoc-alo-green:hover .coccoc-alo-ph-circle-fill {
  background-color: rgba(117, 235, 80, 0.5);
  opacity: 0.75 !important;
}

.coccoc-alo-phone.coccoc-alo-green.coccoc-alo-hover .coccoc-alo-ph-img-circle,
.coccoc-alo-phone.coccoc-alo-green:hover .coccoc-alo-ph-img-circle {
  background-color: #75eb50;
}

@-moz-keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@-webkit-keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@-o-keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@-moz-keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@-webkit-keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@-o-keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@-moz-keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

@-webkit-keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

@-o-keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

@keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}
</style>
