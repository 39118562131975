<script setup lang="ts">
import { computed, ref } from 'vue';
import TaskAttachmentDetail from '@/ui/modules/task/detail/TaskAttachmentDetail.vue';
import { TaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import TaskWorkflowSnapshot from '@/ui/modules/task/workflow/TaskWorkflowSnapshot.vue';
import { ATTACHMENTS_ICON_LIST } from '@/application/constants/task.const';
import TaskApprovalShortInfo from '@/ui/modules/task/approval/TaskApprovalShortInfo.vue';

const props = defineProps<{
  task?: TaskDetailClass;
  totalFiles?: number;
  totalNotes?: number;
  totalTodoLists?: number;
  totalComments?: number;
  totalSubTasks?: number;
}>();

const emit = defineEmits([
  'onOpenAttachmentDetail',
  'onCloseAttachment',
  'openTaskDetail',
]);

const isOpenAttachmentDetail = ref(false);
const defaultViewFileId = ref<string>('');
const onOpenAttachmentDetail = (defaultId?) => {
  defaultViewFileId.value = defaultId;

  console.log('🚀 Tictop ~ props.task:', props.task);
  if (props.task?.id) isOpenAttachmentDetail.value = true;

  emit('onOpenAttachmentDetail');
};

const onCloseAttachment = () => {
  isOpenAttachmentDetail.value = false;

  emit('onCloseAttachment');
};

const ICON_LIST = ATTACHMENTS_ICON_LIST.map((o) => {
  return {
    ...o,

    onClick: () => {
      if (o.key == 'SUB_TASK') {
        emit('openTaskDetail');
        return;
      }
      onOpenAttachmentDetail(o?.id || '');
    },
  };
});

const totalByKeys = computed<any>(() => {
  return {
    APPROVAL: props.task?.approvalEnabled ? 1 : 0,
    WORKFLOW: props.task?.workFlowTaskId ? 1 : 0,
    FILE: props.totalFiles || 0,
    NOTE: props.totalNotes || 0,
    CHECKLIST: props.totalTodoLists || 0,
    DESCRIPTION: props.task?.description ? 1 : 0,
    COMMENT: props.totalComments || 0,
    SUB_TASK: props.totalSubTasks || 0,
  };
});

const currentIconList = computed<any[]>(() => {
  const iconList = ICON_LIST.map((icon) => {
    return {
      ...icon,
      total: totalByKeys.value[icon?.key] || 0,
    };
  });

  return iconList?.filter((icon) => icon?.total > 0)?.slice(0, 3);
});
</script>
<template>
  <div class="w-full flex items-center justify-between gap-10">
    <div class="flex-1 flex items-center justify-start gap-1">
      <slot name="prefix"></slot>
      <div class="flex cursor-pointer gap-1.5">
        <template v-for="icon in currentIconList" :key="icon?.key">
          <template v-if="icon?.key == 'APPROVAL'">
            <TaskApprovalShortInfo
              v-if="task?.approvalEnabled"
              :approval-enabled="task?.approvalEnabled"
              :approval-status="task?.approvalStatus"
              :approvers="task?.approvers"
              :approver-id="task?.approverId"
              readonly
            />
          </template>
          <template v-else-if="icon?.key == 'WORKFLOW'">
            <div
              v-if="task?.workFlowTaskId"
              v-vig-tooltip="$t(icon?.titleKey)"
              v-system-config="[
                {
                  functionCode: 'ADVANCED_WORKFLOW_WORKFLOW_ALLOWED_BY_SYSTEM',
                  functionValue: '1',
                },
                {
                  functionCode: 'DRAFT_WORKFLOW_WORKFLOW_ALLOWED',
                  functionValue: '1',
                },
              ]"
              class="w-5 h-5"
            >
              <TaskWorkflowSnapshot
                :task-id="task?.id"
                :task-detail="task"
                is-show-icon-only
              />
            </div>
          </template>
          <span
            v-else
            v-vig-tooltip="$t(icon?.titleKey)"
            class="
              text-xs
              p-0.5
              flex-center
              space-x-0.5
              rounded
              hover:bg-current-50
            "
            :style="{
              fill: icon?.color,
              color: icon?.color,
            }"
            @click.stop="icon?.onClick && icon?.onClick()"
          >
            <SynIcon
              :is-active="icon?.isActive"
              :name="icon?.iconName"
              :custom-class="
                icon?.key == 'SUB_TASK' ? 'w-3 h-3' : 'w-3.5 h-3.5'
              "
            />
            <template v-if="icon?.total > 1">
              <span>
                {{ icon?.total }}
              </span>
            </template>
          </span>
        </template>
      </div>
    </div>

    <slot name="suffix"></slot>
  </div>

  <TaskAttachmentDetail
    v-if="isOpenAttachmentDetail"
    :task-id="task?.id"
    :default-view-file-id="defaultViewFileId"
    @on-close="onCloseAttachment"
  />
</template>
