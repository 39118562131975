import { chain } from 'lodash';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import {
    settingGlobalModal,
    ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';

import { encryptGroupId, encryptUserId } from '@/ui/plugins/crypto/crypto';
import { TaskLife } from '@/domain/enums/taskEnum';
import { diffDate } from '@/ui/plugins/filters';
import { ScheduleDayOptions } from '@/domain/enums/taskEnum';
import { getDomainUrl } from './commonHook';
import dayjs from 'dayjs';

const low = 'bg-purple-100 text-purple-800';
const normal = 'bg-blue-200 text-blue-800';
const success = 'bg-green-200 text-green-800';
const warning = 'bg-yellow-100 text-yellow-800';
const danger = 'bg-red-100 text-red-800';
const inactive = 'bg-gray-400';

export const getBgColor = (task) => {
    if (!task || !task?.id) return '';
    const key = task?.taskSchedule?.finishItToday
        ? 2
        : task?.taskSchedule?.doItToday
        ? 1
        : 0;
    const taskLife = task.taskLife;
    if (taskLife == TaskLife.Finished) return taskStates[taskLife].background;
    if (key === 0 && taskLife) {
        return taskStates[taskLife].background;
    }
    if (key == 1) return normal;
    if (key == 2) return danger;
    // if (taskLife == 1) return low; on finalism - no longer use
    return '';
};

export const getBgClass = (key, local) => {
    if (local == 'taskLife') {
        if (key == TaskLife.Finished) return success; //TaskLife = Finished (Green-50) #ECFDF5
        if (key == TaskLife.Created) return 'bg-current-100 text-current-600'; //TaskLife = Activated #b0f2ff
        // if (key == TaskLife.InProcess) return '#EF4444'; //TaskLife = OnFinalisation (Red-500) #EF4444 trc la onfinalism nhung da ko dung
    }
    if (local == 'planType') {
        if (key == 0) return low;
        if (key == 1) return normal;
        if (key == 2) return danger;
    }
    if (local == 'priority') {
        if (key == 0) return danger;
        if (key == 1) return normal;
        if (key == 2) return low;
    }
    if (local == 'situation') {
        if (key == 1) {
            return warning;
        } else {
            return 'bg-indigo-100 text-indigo-700';
        }
    }
    if (local == 'schedule') {
        if (key == 'On day' || key == 'No schedule') {
            return low;
        } else {
            return danger;
        }
    }
    if (key == 0) return low;
    if (key == 1) return warning;
    if (key == 2) return inactive;
    if (key == 3) return success;
    if (key == 4) return normal;
    if (key == 5 || key < 0) return danger;
    return low;
};

export const getPriorityIconColor = (key) => {
    if (key == 0) return 'fill-red-500 text-red-800';
    if (key == 1) return 'fill-blue-500 text-blue-800';
    if (key == 2) return 'fill-purple-500 text-purple-800';
};

export const getPriorityIconName = (key) => {
    if (key == 0) return 'high';
    if (key == 1) return 'normal';
    if (key == 2) return 'low';
};

export const getConfigScheduleColor = (taskSchedule) => {
    if (
        taskSchedule?.scheduleOptionKey == ScheduleDayOptions.Nothing &&
        !taskSchedule?.scheduleDateValue
    )
        return '';
    if (
        taskSchedule?.scheduleOptionKey == ScheduleDayOptions.D1 ||
        taskSchedule?.scheduleOptionKey == ScheduleDayOptions.OnDay
    )
        return 'text-orange-500';

    return taskSchedule?.overdue > 0 ||
        dayjs().isAfter(dayjs(taskSchedule?.scheduleDateValue))
        ? 'text-red-500'
        : 'text-green-500';
};

export const getDetailTaskUrl = (taskCode, isFullPath) => {
    if (!taskCode) return '';
    const relativePath = `/tasks/${taskCode}`;

    const domainUrl = getDomainUrl();

    return isFullPath ? `${domainUrl}${relativePath}` : relativePath;
};

export const getGroupTaskListUrl = (groupId, isFullPath) => {
    if (!groupId) return;
    const relativePath = `/tasks/groups/${groupId}`;
    const domainUrl = getDomainUrl();

    return isFullPath ? `${domainUrl}${relativePath}` : relativePath;
};

export const getTaskUrgencyText = (task) => {
    if (!task) return null;

    switch (task.urgency) {
        case TaskUrgency.UrgentImportant.key:
            return TaskUrgency.UrgentImportant.textCode;
        case TaskUrgency.Urgent.key:
            return TaskUrgency.Urgent.textCode;
        case TaskUrgency.Important.key:
            return TaskUrgency.Important.textCode;
        case TaskUrgency.Cool.key:
            return TaskUrgency.Cool.textCode;
    }
    return null;
};

export const formatTaskCode = (taskCode) => {
    if (!taskCode) return '';

    return taskCode.split('-').pop();
};

export const convertUrgencyToPriority = (urgency) => {
    if (
        urgency == TaskUrgency.UrgentImportant.key ||
        urgency == TaskUrgency.Urgent.key
    ) {
        return 0;
    }
    return 1;
};

export const convertUrgencyToImportant = (urgency: number) => {
    if (
        urgency == TaskUrgency.UrgentImportant.key ||
        urgency == TaskUrgency.Important.key
    ) {
        return true;
    }
    return false;
};

export const isValidTaskCode = (taskCode) => {
    const regex = /\d+-[A-Z]{1}\d{1,3}$/i;
    return regex.test(taskCode);
};

export const checkAllowSetPrivateTask = async (
    isSetPrivateAction,
    groupId,
    userId,
    taskDetail
) => {
    let allowed = true;
    //  Nếu task chưa private:
    //  -> set private trong group: Không cho phép
    if (isSetPrivateAction) {
        if (taskDetail.groupId && taskDetail.groupId > 0) {
            allowed = false;
            taskDetail.isPrivate = false;
            settingGlobalModal({
                type: 'confirm',
                title: '',
                content:
                    'Private task only set in personal task, cannot set in group task',
                confirmable: true,
            });
            await ask();
        } else {
            const previousState = !taskDetail.isPrivate;

            const currentId = getCurrentUserId();
            if (!!taskDetail.creatorId && taskDetail.creatorId != currentId) {
                taskDetail.isPrivate = previousState;
                settingGlobalModal({
                    type: 'notification',
                    title: '',
                    content:
                        translate(
                            'COMMON_LABEL_QUESTION_ALERT_ONLY_CREATOR_SET_PRIVATE'
                        ) ||
                        `Only the creator can set private state of this task!`,
                    confirmable: true,
                });
                await ask();
            } else {
                settingGlobalModal({
                    type: 'confirm',
                    title: '',
                    content:
                        translate('COMMON_LABEL_QUESTION_SET_PRIVATE_TASK') ||
                        `Do you want to make this task to private?`,
                    confirmable: true,
                    closeable: true,
                });

                const confirmed = !previousState ? await ask() : true;
                if (confirmed) {
                    taskDetail.isPrivate = !previousState;
                } else {
                    taskDetail.isPrivate = previousState;
                }
            }
        }
    }
    //  Nếu task đang là private:
    else if (taskDetail.isPrivate) {
        //  -> set group cho task: show cảnh báo xoá private:
        if (groupId > 0) {
            // taskDetail.isPrivate = false;

            settingGlobalModal({
                type: 'confirm',
                title: '',
                content:
                    translate('TASK_CREATE_FORM_CANNOT_SET_PRIVATE') ||
                    'Cannot set private task for group. Do you want to unset private task?',
                confirmable: true,
                closeable: true,
            });

            const confirmed = await ask();
            allowed = true;
            if (confirmed) {
                taskDetail.isPrivate = false;
            } else {
                taskDetail.groupId = null;
                taskDetail.assigneeId = userId || getCurrentUserId();
                //  temporary set this to false to check in onSaveAssign func
                allowed = false;
            }
        }
        //  -> neu edit 1 task private, chuyen assign sang nguoi khac thi can thong bao
        else if (
            !!userId &&
            userId != taskDetail.assigneeId &&
            taskDetail.id > 0
        ) {
            settingGlobalModal({
                type: 'confirm',
                title: '',
                content:
                    !taskDetail.creatorId ||
                    taskDetail.creatorId == getCurrentUserId()
                        ? translate(
                              'COMMON_LABEL_QUESTION_CHANGE_PRIVATE_TASK'
                          ) ||
                          `The previous assignee will not allowed to view this private task. Do you want to continue`
                        : translate(
                              'COMMON_LABEL_QUESTION_SAVE_PRIVATE_TASK'
                          ) ||
                          'You will not allowed to view this private task if you save change your updated. Do you want to continue?',
                confirmable: true,
                closeable: true,
            });

            const confirmed = await ask();
            if (confirmed) {
                taskDetail.assigneeId = userId;
                allowed = true;
            } else {
                allowed = false;
            }
        }
    }

    return allowed;
};

export const getMemberGroupTaskUrl = ({ isGroup, id }) => {
    return isGroup
        ? `/tasks/groups/${encryptGroupId(id)}`
        : `/tasks/users/${encryptUserId(id)}`;
};

export const sortDefaultTaskList: {
    iterate: any[];
    order: any[];
} = {
    iterate: [
        (task) => {
            return task?.taskSchedule?.finishItToday &&
                task?.taskLife !== TaskLife.Finished
                ? 1
                : task?.taskSchedule?.doItToday &&
                  task?.taskLife !== TaskLife.Finished
                ? 2
                : 3;
        },
        (t) => {
            return taskStates[t.taskLife]?.defaultListIndex;
        },
        (t) => {
            const finishedOverDay = diffDate(
                t.finishedTime,
                new Date(),
                'day',
                false
            );
            return t.taskLife == TaskLife.Finished && finishedOverDay == 0
                ? 1
                : 2;
        },
        'finishedTime',
        'urgency',
        (t) => {
            return t.scheduleTime ? 1 : 2;
        },
        'scheduleTime',
        (task) => new Date(task.creationTime),
        'name',
    ],
    order: ['asc', 'asc', 'asc', 'desc', 'desc', 'asc', 'asc', 'desc', 'asc'],
};

export const taskStates = {
    0: {
        index: 0,
        colorKey: 'current-600',
        color: 'text-current-600',
        background: 'bg-current-100',
        backgroundStep: 'bg-current-600',
        fill: 'fill-current',
        nameCode: 'TASK_STATE_CREATED',
        descriptionCode: 'TASK_STATE_DESCRIPTION_CREATED',
        name: 'Undefined',
        key: TaskLife.Created,
        colorHex: '#018180',
        iconName: 'StateUndefined',
        defaultListIndex: 4,
    },
    7: {
        index: 1,
        colorKey: 'blue-500',
        color: 'text-blue-500',
        background: 'bg-blue-50',
        backgroundStep: 'bg-blue-500',
        fill: 'fill-blue-500',
        nameCode: 'TASK_STATE_TODO',
        descriptionCode: 'TASK_STATE_DESCRIPTION_TODO',
        name: 'To do',
        key: TaskLife.Todo,
        colorHex: '#3b82f6',
        iconName: 'StateTodo',
        defaultListIndex: 2,
    },
    1: {
        index: 2,
        colorKey: 'pink-500',
        color: 'text-pink-500',
        background: 'bg-pink-100',
        backgroundStep: 'bg-pink-500',
        fill: 'fill-pink-500 text-pink-500',
        nameCode: 'TASK_STATE_IN_PROCESS',
        descriptionCode: 'TASK_STATE_DESCRIPTION_IN_PROCESS',
        name: 'In process',
        key: TaskLife.InProcess,
        colorHex: '#ec4899',
        iconName: 'StateInprogress',
        defaultListIndex: 1,
    },
    3: {
        index: 3,
        colorKey: 'green-500',
        color: 'text-green-500',
        background: 'bg-green-100',
        backgroundStep: 'bg-green-500',
        fill: 'fill-green-500',
        nameCode: 'TASK_STATE_FINISHED',
        descriptionCode: 'TASK_STATE_DESCRIPTION_FINISHED',
        name: 'Finished',
        key: TaskLife.Finished,
        colorHex: '#22c55e',
        iconName: 'StateFinished',
        defaultListIndex: 3,
    },

    2: {
        index: 4,
        colorKey: 'gray-800',
        color: 'text-gray-800',
        fill: 'fill-gray-500',
        background: 'bg-gray-200',
        backgroundStep: 'bg-gray-400',
        nameCode: 'TASK_STATE_PENDING',
        descriptionCode: 'TASK_STATE_DESCRIPTION_PENDING',
        name: 'Pending',
        key: TaskLife.Pending,
        colorHex: '#1f2937',
        iconName: 'StatePedding',
        defaultListIndex: 8,
    },
    8: {
        index: 5,
        colorKey: 'gray-800',
        color: 'text-gray-800',
        fill: 'fill-gray-500',
        background: 'bg-gray-200',
        backgroundStep: 'bg-gray-400',
        nameCode: 'TASK_STATE_DRAFT',
        descriptionCode: 'TASK_STATE_DESCRIPTION_DRAFT',
        name: 'Draft',
        key: TaskLife.Draft,
        colorHex: '#1f2937',
        iconName: 'StateDraft',
        defaultListIndex: 9,
    },
    6: {
        index: 6,
        colorKey: 'gray-800',
        color: 'text-gray-800',
        fill: 'fill-gray-500',
        background: 'bg-gray-200',
        backgroundStep: 'bg-gray-500',
        nameCode: 'TASK_STATE_DUPLICATED',
        descriptionCode: 'TASK_STATE_DESCRIPTION_DUPLICATED',
        name: 'Duplicated',
        key: TaskLife.Duplicated,
        colorHex: '#1f2937',
        iconName: 'StateDuplicated',
        defaultListIndex: 10,
    },
    4: {
        index: 7,
        colorKey: 'gray-600',
        color: 'text-gray-600',
        fill: 'fill-gray-500',
        background: 'bg-gray-100',
        backgroundStep: 'bg-gray-500',
        nameCode: 'TASK_STATE_ARCHIVED',
        descriptionCode: 'TASK_STATE_DESCRIPTION_ARCHIVED',
        name: 'Archived',
        key: TaskLife.Archived,
        colorHex: '#4b5563',
        iconName: 'StateArchived',
        defaultListIndex: 11,
    },
    5: {
        index: 8,
        colorKey: 'gray-500',
        color: 'text-gray-500',
        fill: 'fill-gray-500',
        background: 'bg-gray-50',
        backgroundStep: 'bg-gray-500',
        nameCode: 'TASK_STATE_CACELLED',
        descriptionCode: 'TASK_STATE_DESCRIPTION_CACELLED',
        name: 'Cancelled',
        key: TaskLife.Cancelled,
        colorHex: '#6b7280',
        iconName: 'StateCanceled',
        defaultListIndex: 12,
    },
};

export const getUrgencyValue = (priority, important) => {
    if (priority == 0 && important) return TaskUrgency.UrgentImportant.key;

    if (priority == 0 && !important) return TaskUrgency.Urgent.key;

    if ((priority == 1 || priority == 2) && important)
        return TaskUrgency.Important.key;

    if ((priority == 1 || priority == 2) && !important)
        return TaskUrgency.Cool.key;

    return TaskUrgency.Cool.key;
};

export const TaskUrgency = {
    UrgentImportant: {
        key: 4,
        text: 'URGENT & IMPORTANT',
        textCode: 'TASK_CREATE_FORM_URGENT_IMPORTANT',
        data: {
            priority: 0,
            important: true,
        },
    },
    Urgent: {
        key: 3,
        text: 'URGENT',
        textCode: 'TASK_CREATE_FORM_URGENT',
        data: {
            priority: 0,
            important: false,
        },
    },
    Important: {
        key: 2,
        text: 'IMPORTANT',
        textCode: 'TASK_CREATE_FORM_IMPORTANT',
        data: {
            priority: 1, // 1 || 2
            important: true,
        },
    },
    Cool: {
        key: 1,
        text: 'COOL',
        textCode: 'TASK_CREATE_FORM_COOL',
        data: {
            priority: 1, // 1 || 2
            important: false,
        },
    },
};
export const TaskUrgencyByKey = {
    4: {
        key: 4,
        text: 'URGENT & IMPORTANT',
        textCode: 'TASK_CREATE_FORM_URGENT_IMPORTANT',
        data: {
            priority: 0,
            important: true,
        },
    },
    3: {
        key: 3,
        text: 'URGENT',
        textCode: 'TASK_CREATE_FORM_URGENT',
        data: {
            priority: 0,
            important: false,
        },
    },
    2: {
        key: 2,
        text: 'IMPORTANT',
        textCode: 'TASK_CREATE_FORM_IMPORTANT',
        data: {
            priority: 1, // 1 || 2
            important: true,
        },
    },
    1: {
        key: 1,
        text: 'COOL',
        textCode: 'TASK_CREATE_FORM_COOL',
        data: {
            priority: 1, // 1 || 2
            important: false,
        },
    },
};

export const sortTaskDefault = (tasks: any[]) => {
    if (!tasks || tasks.length == 0) return [];
    return chain(tasks)
        .orderBy(sortDefaultTaskList.iterate, sortDefaultTaskList.order)
        .value();
};

export const genSortData = (
    currentSortData,
    sortObj: any,
    enableSortMultiple
) => {
    const { key, name, orderFunction } = sortObj;
    let orderBy = 'asc';
    let sortData = [] as any[];
    if (enableSortMultiple) {
        const indexExisted = currentSortData.findIndex(
            (o: any) => o.key == key
        );
        if (indexExisted > -1) {
            const currentOrderBy = currentSortData[indexExisted].orderBy;
            if (currentOrderBy == 'desc') {
                sortData = sortData.filter((o: any) => o.key !== key);
            } else {
                orderBy = currentOrderBy == 'asc' ? 'desc' : 'asc';
                sortData[indexExisted] = { key, name, orderBy, orderFunction };
            }
        } else {
            sortData = [...sortData, { key, name, orderBy, orderFunction }];
        }
    } else {
        const indexExisted = currentSortData.findIndex((o) => o.key == key);

        if (indexExisted > -1) {
            const currentOrderBy = currentSortData[indexExisted].orderBy;
            if (currentOrderBy == 'asc') {
                sortData = [];
            } else {
                orderBy = currentOrderBy == 'desc' ? 'asc' : 'desc';

                sortData = [{ key, name, orderBy, orderFunction }];
            }
        } else {
            sortData =
                key == 'scheduleTime'
                    ? [
                          {
                              key,
                              name,
                              orderBy: 'desc',
                              orderFunction: (t) => {
                                  if (!t?.scheduleTime) {
                                      return '';
                                  } else return t?.scheduleTime;
                              },
                          },
                      ]
                    : [{ key, name, orderBy: 'desc', orderFunction }];
        }
    }
    return sortData;
};

export const getScheduleTimeAfterChangeDate = (
    currentScheduleTime,
    newScheduleDate,
    scheduleByHour,
    scheduleTimezone
) => {
    if (!newScheduleDate) return null;

    if (scheduleByHour)
        return dayjs(
            `${dayjs.utc(newScheduleDate).format('YYYY/MM/DD')} ${
                currentScheduleTime
                    ? dayjs(currentScheduleTime)
                          .tz(scheduleTimezone)
                          .format('HH:mm:ss')
                    : '00:00:00'
            }`
        )
            .tz(scheduleTimezone, true)
            .format();

    return dayjs
        .utc(dayjs(newScheduleDate).format('YYYY/MM/DD'))
        .startOf('day')
        .format();
};
