<script setup lang="ts">
import { ref, computed, watch, onMounted, onUnmounted, reactive } from 'vue';
import { useRoute } from 'vue-router';
import noteLogic from './note-logic';
import commentLogic from './comment-logic';
import audioLogic from './audio-logic';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { isImage } from '@/ui/hooks/fileHook';
import taskCreateFormStore from '@/store/task/create-form';
import chatStore from '@/store/chat';
import { getDefaultTaskSchedule } from '@/application/parsers/TaskParser';
import { taskStates } from '@/ui/hooks/taskHook';
import { ScheduleDayOptions, TaskLife } from '@/domain/enums/taskEnum';
import {
  settingGlobalModal,
  ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import ModalMultipleFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewer.vue';
import RecorderModal from '@/ui/common/atoms/SynRecorder/RecorderModal.vue';

// import TaskState from '@/ui/modules/task/components/TaskState.vue';
import CreateNoteModal from '@/ui/modules/task/detail/CreateNoteModal.vue';
import UploadDropZone from '@/ui/modules/ged/upload/upload-drop-zone/UploadDropZone.vue';
import ChangeAssign from '@/ui/modules/task/detail/ChangeAssign.vue';
import TaskCreateEntity from '@/domain/entities/task/TaskCreateEntity';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import UpdateDeadline from '@/ui/modules/task/detail/UpdateDeadline.vue';
import UpdateDomain from '@/ui/modules/task/detail/UpdateDomain.vue';
import newDomainComps from '@/ui/modules/task/composables/new-domain-compsable';
import settingStore from '@/store/setting';
import ExplainIcon from '@/ui/components/common/ExplainIcon.vue';
import TaskDescriptionInput from '@/ui/common/plugins/ckeditor/TaskDescriptionInput.vue';
import orgStore from '@/store/organization';
import getGroupInfo from '@/ui/helpers/group-helper';
import taskStore from '@/store/task';
import taskDrawerStore from '@/store/task/drawer';
import { RouterByModuleName } from '@/ui/router/private-routes';
import { decryptGroupId, decryptUserId } from '@/ui/plugins/crypto/crypto';
import TaskStateStepper from '@/ui/modules/task/components/TaskStateStepper.vue';
import taskTabViewStore from '@/store/task/task-tab-view';
import { arrayOrderBy, parseHTML } from '@/ui/hooks/commonFunction';
import getUserInfo from '@/ui/helpers/user-helper';
import permissionStore from '@/store/permission';
import ManageCollaborator from '@/ui/modules/task/detail/assign/ManageCollaborator.vue';
import UserByAction from '@/ui/components/user/UserByAction.vue';
import myProfileStore from '@/store/auth/my-profile';
import { EFilterTaskDefault } from '@/application/constants/task.const';
import { newTaskDefaultPayload } from '@/ui/modules/task/task-global-state';
import TaskDetailField from '@/ui/modules/task/components/TaskDetailField.vue';
import ApplyWorkFlowModal from '@/ui/modules/task/workflow/ApplyWorkFlowModal.vue';
import { ITaskWorkflowEntity } from '@/domain/entities/task/TaskWorkflowEntity';
import ActionOnTask from '@/ui/modules/task/detail/action-on-task/ActionOnTask.vue';
// import remoteConfigStore from '@/store/remoteConfig';
import {
  EActionOnTask,
  ITaskTodo,
  ITaskTodoItemStatus,
  TaskApprovalStatus,
} from '@/application/types/task/task.types';
import AttachmentInput from '@/ui/components/forms/AttachmentInput.vue';
import UpdateDeadlineTime from '@/ui/modules/task/detail/UpdateDeadlineTime.vue';
import useUpdateDeadlineComposables from '../detail/update-deadline/use-update-deadline.composables';
// import AttachWorkflowDropdown from '@/ui/modules/task/workflow/AttachWorkflowDropdown.vue';
import TaskApprovalShortInfo from '@/ui/modules/task/approval/TaskApprovalShortInfo.vue';
import TaskSetApprovalModal from '@/ui/modules/task/approval/TaskSetApprovalModal.vue';
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';
// import SubTaskList from '@/ui/modules/task/detail/sub-tasks/SubTaskList.vue';
import TaskMultipleAssignee from '@/ui/modules/task/detail/sub-tasks/TaskMultipleAssignee.vue';
import taskMultipleAssigneeComposable from '@/ui/modules/task/detail/sub-tasks/task-multiple-assignee.composable';
import SelectMultipleUserModal from '@/ui/components/user/SelectMultipleUserModal.vue';
import UserById from '@/ui/components/user/UserById.vue';
import TaskWorkflowCurrentStep from '@/ui/modules/task/workflow/TaskWorkflowCurrentStep.vue';
import SearchTasksModal from '@/ui/modules/task/components/search/SearchTasksModal.vue';

// *** PROPS, EMITS ***
const props = defineProps<{
  localId?: number | string;
  isCreateTaskOnStartWorking?: boolean;
}>();
const emit = defineEmits<{
  (e: 'onOpenInterface'): void;
  (e: 'onClose'): void;
  (e: 'onCreateSuccess', localId: any, newTask: any): void;
  (e: 'onCancel'): void;
  (e: 'update:isCreatingTask', value: boolean): void;
}>();
// const urgencyList = ref(Object.values(TaskUrgency) as any[]);
const taskCreateNewInputFileId = `task-create-new_attachment_${Date.now()}`;
const _taskDrawerStore = taskDrawerStore();
const _taskStore = taskStore();
const _taskCreateFormStore = taskCreateFormStore();
const _chatStore = chatStore();
const _orgStore = orgStore();
const _settingStore = settingStore();
const _taskTabViewStore = taskTabViewStore();
const route = useRoute();

const myProfile = computed(() => myProfileStore().myProfile);
const myTimezone = computed(() => myProfileStore().timezone);
const formDataCacheAfterCreate = computed(
  () => _taskCreateFormStore.formDataCacheAfterCreate
);
const childrenTabActive = computed(() => _taskTabViewStore.childrenTabActive);
const orgInfo = computed(() => _orgStore.organizationInfo);
// const cachedFormData = computed(() => _taskCreateFormStore.cachedFormData);
const isInputName = ref(false);
const isOnFinishing = ref(false);
const isImportant = ref(false);
const defaultPublicTask = computed(
  () => _settingStore._organizationSetting?.taskManagement?.defaultPublicTask
);

const multipleAssigneeSetting = computed(
  () =>
    _settingStore._organizationSetting?.taskManagement?.multipleAssigneeSetting
);

const formData = ref<TaskCreateEntity>(
  new TaskCreateEntity(
    newTaskDefaultPayload.value
      ? {
          ...(newTaskDefaultPayload.value || {}),
          creatorId: myProfile.value?.id,
          assigneeId:
            permissionStore().organizationModel === 'PERSONAL'
              ? getCurrentUserId()
              : newTaskDefaultPayload.value?.assigneeId,
          isPrivate:
            !defaultPublicTask.value ||
            permissionStore().organizationModel === 'PERSONAL',
          taskLife: TaskLife.Todo,
          urgency: 1,
          files: [],
          localId: props.localId,
          taskFromChat: newTaskDefaultPayload.value?.taskFromChat,
          scheduleTime: newTaskDefaultPayload.value?.scheduleTime,
          domainId: newTaskDefaultPayload.value?.domainId,
          projectId: newTaskDefaultPayload.value?.projectId,
          subprojectId: newTaskDefaultPayload.value?.subprojectId,
        }
      : {
          ...(formDataCacheAfterCreate.value || {}),
          creatorId: myProfile.value?.id,
          assigneeId:
            permissionStore().organizationModel === 'PERSONAL'
              ? getCurrentUserId()
              : formDataCacheAfterCreate.value?.assigneeId,
          isPrivate:
            !defaultPublicTask.value ||
            permissionStore().organizationModel === 'PERSONAL',
          taskLife: TaskLife.Todo,
          urgency: 1,
          files: [],
          localId: props.localId,
          taskFromChat: formDataCacheAfterCreate.value?.taskFromChat,
        }
  )
);

const isAssignUserListTask = computed<boolean>(() => {
  return (
    formData.value?.multiAssignees && formData.value?.multiAssignees?.length > 0
  );
});
const onOpenForm = () => {
  // if (props.isOpenFull) return;
  updateDomainRef.value?.initDomain();
  // Close all chat panel
  _chatStore.closeBubbleChat();
};

const {
  isInputComment,
  myComment,
  onOpenComment,
  onSaveComment,
  onCancelComment,
} = commentLogic(false, null, true);

const {
  isOpenRecording,
  onCreateAudio,
  onCreateAudioDone,
  onCreateAudioCancel,
} = audioLogic(formData);

const onSaveAudio = (data) => {
  onCreateAudioDone(data);

  if (isOpenFull.value) return;

  onOpenNewForm('');
};

const isDragging = ref<boolean>(false);
const dragOver = (_isDragging) => {
  isDragging.value = _isDragging;
  onOpenForm();
};

const onDropFiles = (e) => {
  if (e?.dataTransfer?.files?.length == 0) return;
  isDragging.value = false;
  const files = e.dataTransfer.files;
  if (
    attachmentInputRef.value &&
    typeof attachmentInputRef.value?.onAddFiles == 'function'
  )
    attachmentInputRef.value?.onAddFiles(files);

  if (!isOpenFull.value) onOpenNewForm('');
};

const {
  isCreateNote,
  currentNote,
  isLoadingDrawData,
  onSaveNote,
  onCreateNote,
  onCancelNote,
} = noteLogic(formData);

const onHandleSaveNote = (data) => {
  onSaveNote(data);

  if (isOpenFull.value) return;

  onOpenNewForm('');
  onFocusInputName();
};

const { addNewDomainParams, updateDomainBefore } = newDomainComps(formData);

const handlePropsData = () => {
  onResetForm(false);

  if (
    newTaskDefaultPayload.value &&
    (newTaskDefaultPayload.value?.name ||
      newTaskDefaultPayload.value?.description ||
      (newTaskDefaultPayload.value?.files &&
        newTaskDefaultPayload.value?.files?.length > 0) ||
      (newTaskDefaultPayload.value?.checklist &&
        newTaskDefaultPayload.value?.checklist?.length > 0) ||
      newTaskDefaultPayload.value?.scheduleTime ||
      newTaskDefaultPayload.value?.domainId)
  )
    onOpenNewForm(newTaskDefaultPayload.value?.name);
};

const loadingData = reactive<{ init: boolean }>({ init: true });

const initData = async (localId) => {
  loadingData.init = true;
  isValidate.value = false;
  isOpenFull.value = false;

  onCancelComment();

  await onLoadCacheForm(localId);
  if (
    formData.value?.files?.length > 0 ||
    formData.value?.notes?.length > 0 ||
    formData.value?.name?.length >= 3 ||
    formData.value.taskTodos?.length > 0 ||
    formData.value.description
  )
    isOpenFull.value = true;

  onFocusInputName();

  loadingData.init = false;
};

onMounted(() => {
  initData(props.localId);
});
watch(
  () => [
    newTaskDefaultPayload.value?.name,
    newTaskDefaultPayload.value?.description,
  ],
  () => {
    formData.value.name = newTaskDefaultPayload.value?.name || '';
    formData.value.description = newTaskDefaultPayload.value?.description || '';
  }
);
watch(
  () => newTaskDefaultPayload.value?.assigneeId,
  (newValue) => {
    if (newValue) {
      initData(props.localId);
    }
  }
);
watch(
  () => newTaskDefaultPayload.value?.groupId,
  (newValue) => {
    if (newValue) {
      initData(props.localId);
    }
  }
);

onUnmounted(() => {
  onSaveCacheForm(props.localId, formData.value);
  newTaskDefaultPayload.value = undefined;
});

// export const formData = ref({});
const changeAssignRef = ref<any>(null);
const updateDeadlineRef = ref<any>(null);

const initReferenceComponent = () => {
  changeAssignRef.value?.initData &&
    changeAssignRef.value?.initData(formData.value);

  updateDomainRef.value?.initDomain &&
    updateDomainRef.value?.initDomain(formData.value);

  taskTodoListAllRef.value?.initComponent &&
    taskTodoListAllRef.value?.initComponent(formData.value);

  updateDeadlineRef.value?.initComponent &&
    updateDeadlineRef.value?.initComponent();

  attachmentInputRef.value?.initComponent &&
    attachmentInputRef.value?.initComponent({
      files: formData.value?.files,
      notes: formData.value?.notes,
      taskTodos: formData.value?.taskTodos,
      isUploadRightAway: true,
    });

  if (
    manageCollaboratorRef.value &&
    typeof manageCollaboratorRef.value?.initData == 'function'
  )
    manageCollaboratorRef.value?.initData();
};
const onResetForm = (keepOpenFull: boolean = true) => {
  // 2. reset cached

  isValidate.value = false;
  isOpenFull.value = keepOpenFull;

  onCancelComment();

  formData.value = new TaskCreateEntity(
    newTaskDefaultPayload.value
      ? {
          ...(newTaskDefaultPayload.value || {}),
          creatorId: myProfile.value?.id,
          assigneeId:
            permissionStore().organizationModel === 'PERSONAL'
              ? getCurrentUserId()
              : newTaskDefaultPayload.value?.assigneeId,
          isPrivate:
            !defaultPublicTask.value ||
            permissionStore().organizationModel === 'PERSONAL',
          taskLife: TaskLife.Todo,
          urgency: 1,
          files: [],
          localId: props.localId,
          taskFromChat: newTaskDefaultPayload.value?.taskFromChat,
        }
      : {
          ...(formDataCacheAfterCreate.value || {}),
          creatorId: myProfile.value?.id,
          assigneeId:
            permissionStore().organizationModel === 'PERSONAL'
              ? getCurrentUserId()
              : formDataCacheAfterCreate.value?.assigneeId,
          isPrivate:
            !defaultPublicTask.value ||
            permissionStore().organizationModel === 'PERSONAL',
          taskLife: TaskLife.Todo,
          urgency: 1,
          files: [],
          localId: props.localId,
          taskFromChat: formDataCacheAfterCreate.value?.taskFromChat,
        }
  );

  if (
    newTaskDefaultPayload.value &&
    newTaskDefaultPayload.value?.files &&
    newTaskDefaultPayload.value?.files?.length > 0
  ) {
    if (
      attachmentInputRef.value &&
      typeof attachmentInputRef.value?.onAddFiles == 'function'
    )
      attachmentInputRef.value?.onAddFiles(newTaskDefaultPayload.value?.files);
  }

  if (
    newTaskDefaultPayload.value &&
    newTaskDefaultPayload.value?.checklist &&
    newTaskDefaultPayload.value?.checklist?.length > 0
  ) {
    if (
      attachmentInputRef.value &&
      typeof attachmentInputRef.value?.onSaveTodoList == 'function'
    )
      attachmentInputRef.value?.onSaveTodoList(
        newTaskDefaultPayload.value?.checklist,
        true
      );
  }
  if (
    newTaskDefaultPayload.value &&
    newTaskDefaultPayload.value?.taskWorkflow?.id
  ) {
    onAttachWorkflow(newTaskDefaultPayload.value?.taskWorkflow);
  }

  onDetachOwner();
  onSaveCacheForm(props.localId, formData.value);

  isOnFinishing.value = false;
  isImportant.value = false;
  initReferenceComponent();
};

const onCancelForm = () => {
  emit('onCancel');
};

// Name
const onFocusInputName = () => {
  isInputName.value = true;
  onOpenForm();
};

const attachmentInputRef = ref<any>();
const onPasteImage = (event) => {
  if (
    attachmentInputRef.value &&
    typeof attachmentInputRef.value?.onPasteFiles == 'function'
  )
    attachmentInputRef.value?.onPasteFiles(event);
};
// Submit form
const isValidate = ref(false);
const isLoadingSubmitButton = ref(false);

const onSubmitForm = async () => {
  // if (!props.isOpenFull) return;
  if (
    addNewDomainParams.value.addNewDomainType ||
    addNewDomainParams.value.addNewProjectType ||
    addNewDomainParams.value.addNewSubProjectType
  )
    await updateDomainBefore();

  // old logic
  if (
    !formData.value?.name?.trim() ||
    !formData.value?.domainId ||
    (!formData.value?.assigneeId && !formData.value?.groupId) ||
    formData.value?.urgency == null ||
    formData.value?.taskSchedule?.scheduleOptionKey == null ||
    isLoadingSubmitButton.value ||
    (formData.value?.multiAssignees &&
      formData.value?.multiAssignees?.length > 0 &&
      formData.value?.multiAssignees?.some((o) => !o?.domainId))
  ) {
    isValidate.value = true;
    return;
  }

  isValidate.value = false;

  try {
    isLoadingSubmitButton.value = true;
    emit('update:isCreatingTask', true);
    const currentLocalId = props.localId;
    if (!isIncludeParent.value) {
      formData.value.parentId = undefined;
      formData.value.parentCode = undefined;
    }

    const newTask = await formData.value.create(false);
    emit('onCreateSuccess', currentLocalId, newTask);
  } catch (error) {
    console.log(error);
  } finally {
    isLoadingSubmitButton.value = false;
    // if (newLocalId.value) {
    //   onSaveCacheForm(formData.value?.id, formData.value);
    //   initData(newLocalId.value);
    // }
    emit('update:isCreatingTask', false);
  }
};
const onSaveCacheForm = (localId, data) => {
  if (
    !localId ||
    !_taskDrawerStore.allTabByIds ||
    !Object.prototype.hasOwnProperty.call(_taskDrawerStore.allTabByIds, localId)
  )
    return;

  _taskCreateFormStore.setCachedFormData(localId, data);
  _taskDrawerStore.onUpdateCache();
};
const onLoadCacheForm = async (localId) => {
  if (!localId) return;
  // const catchData = cachedFormData.value[localId];

  const cacheData = await _taskCreateFormStore.getCachedByOwnerId(localId);

  if (!cacheData) {
    handlePropsData();
    loadingData.init = false;

    return;
  }

  // Has localId => in process create
  isOpenFull.value = true;

  formData.value = new TaskCreateEntity({
    ...cacheData,
    creatorId: myProfile.value?.id,
    subTasks: cacheData?.subTasks?.map((t) => {
      return {
        ...t,
        title: t?.name,
        status: ITaskTodoItemStatus.FINISHED,
        isSelecting: true,
      };
    }),

    subTaskIds: cacheData?.subTasks
      ?.filter((t) => t?.taskLife == TaskLife.Finished)
      ?.map((t) => t?.id),
    localId,
  });

  formData.value.isPrivate = !defaultPublicTask.value;

  if (
    childrenTabActive.value == EFilterTaskDefault.DO_TODAY ||
    props.isCreateTaskOnStartWorking
  ) {
    formData.value.taskSchedule.doItToday = true;
  }

  initReferenceComponent();
};

const isGroupTask = computed(() => !!formData.value?.groupId);

const textPrivateParser = computed(() => {
  if (!formData.value?.groupId) {
    if (!formData.value?.isPrivate)
      return parseHTML(
        translate('TASK_CREATE_IS_PUBLIC', {
          orgName: orgInfo.value?.name,
        })
      );
    if (formData.value?.assigneeId === getCurrentUserId())
      return parseHTML(translate('TASK_CREATE_IS_PRIVATE'));

    if (formData.value?.assigneeId !== getCurrentUserId())
      return parseHTML(
        translate('TASK_CREATE_IS_PRIVATE_WITH_OTHER', {
          name: getUserInfo(formData?.value?.assigneeId)?.name,
        })
      );
  }

  if (getGroupInfo(formData.value?.groupId)?.isPrivate)
    return parseHTML(
      translate('TASK_CREATE_IS_PUBLIC_GROUP', {
        groupName: getGroupInfo(formData?.value?.groupId)?.name,
      })
    );
  else
    return parseHTML(
      translate('TASK_CREATE_IS_PUBLIC', { orgName: orgInfo.value?.name })
    );
});

const updateDomainRef = ref(null as any);

const synchronizeTaskToWorkflowCurrentStep = () => {
  // if (!formData.value?.taskWorkflow || !formData.value?.taskWorkflow?.id)
  //   return;
  // formData.value.synchronizeTaskToWorkflowCurrentStep();
};
const onSaveAssign = async (group, user) => {
  // isOpenChangeAssign.value = false;
  const groupId = group?.id;
  const userId = user?.userId;

  formData.value.changeAssignee(groupId, userId);

  formData.value.taskLife = TaskLife.Todo;
  formData.value.taskSchedule = getDefaultTaskSchedule();

  updateDomainRef.value?.updateDomainAfterAssigneeChanged(userId, groupId);

  synchronizeTaskToWorkflowCurrentStep();
  formData.value.multiAssignees = synchronizeAssigneeWithTaskOwner(
    formData.value.multiAssignees,
    formData.value.groupId
  );
};

const checkAllowSetPrivateTask = async (
  isSetPrivateAction
): Promise<boolean> => {
  // Can not set private in group's task
  if (isDisableSetPrivate.value) {
    // formData.value.isPrivate = false;
    settingGlobalModal({
      type: 'confirm',
      title: '',
      content:
        translate('TASK_CREATE_FORM_CANNOT_SET_PRIVATE_IN_GROUP') ||
        'A job can only be created private for the creator and the person in charge',
      closeable: true,
      confirmable: false,
    });

    await ask();

    return false;
  }

  const isContinue = await formData.value.confirmBeforeChangePrivate(
    isSetPrivateAction
  );

  return isContinue;
};

const onSetPrivateTask = async (newValue) => {
  const isValid = await checkAllowSetPrivateTask(newValue);
  if (!isValid) return;

  formData.value.isPrivate = newValue;
};

const currentFileIndex = ref(0);
const isPreview = ref(false);

const onChangeTaskState = (data) => {
  formData.value.taskLife = data;
};

const onChangeUrgency = (data) => {
  formData.value.changeUrgency(data);

  synchronizeTaskToWorkflowCurrentStep();
};

const isShowScheduleTime = ref(false);
let scheduleTimeoutFn;
const showSelectedScheduleTime = () => {
  if (!formData.value?.scheduleTime) return;

  if (scheduleTimeoutFn) clearTimeout(scheduleTimeoutFn);
  isShowScheduleTime.value = true;

  scheduleTimeoutFn = setTimeout(() => {
    isShowScheduleTime.value = false;
  }, 3 * 1000);
};

const onSaveDeadline = (deadlineDate) => {
  formData.value.changeDeadline(deadlineDate);
  showSelectedScheduleTime();

  synchronizeTaskToWorkflowCurrentStep();
};

const onDoItToday = (value) => {
  formData.value.doItToday(value);

  showSelectedScheduleTime();

  synchronizeTaskToWorkflowCurrentStep();
};

const onFinishItToday = async (value) => {
  formData.value.finishItToday(value);

  if (
    value &&
    updateDeadlineRef.value?.updateScheduleTime &&
    typeof updateDeadlineRef.value?.updateScheduleTime == 'function'
  )
    updateDeadlineRef.value?.updateScheduleTime(ScheduleDayOptions.OnDay);

  showSelectedScheduleTime();

  synchronizeTaskToWorkflowCurrentStep();
};

const allAttachments = computed<any[]>(() => {
  const attachments = arrayOrderBy(
    [
      ...formData.value?.files?.filter(
        (file) => file?.editStatus !== 'REMOVED'
      ),
      ...formData.value?.notes
        ?.filter((note) => note?.editStatus !== 'REMOVED')
        ?.map((note) => {
          return { ...note, isNote: true };
        }),
    ],
    ['creationTime'],
    ['asc']
  );
  return formData.value?.description
    ? [
        ...attachments,
        {
          type: 'text',
          data: formData.value?.description,
        },
      ]
    : attachments;
});

const onClickSaveDescription = () => {
  onSaveComment((data) => {
    formData.value.description = data?.content;
  });
};

const onSaveDomain = ({
  domainId,
  domainName,
  projectId,
  projectName,
  subprojectId,
  subprojectName,
}) => {
  formData.value.domainId = domainId;
  formData.value.domainName = domainName;
  formData.value.projectId = projectId;
  formData.value.projectName = projectName;
  formData.value.subprojectId = subprojectId;
  formData.value.subprojectName = subprojectName;

  formData.value.multiAssignees = synchronizeAssigneeWithDomain(
    formData.value.multiAssignees,
    formData.value.domainId,
    formData.value?.projectId,
    formData.value?.subprojectId
  );

  synchronizeTaskToWorkflowCurrentStep();
};

const onAddNewDomain = (updateData) => {
  addNewDomainParams.value = {
    ...addNewDomainParams.value,
    ...updateData,
  };
};

// const getConfigScheduleName = (data) => {
//   return _taskStore.getConfigScheduleName(data);
// };

const myDescriptionValid = ref(true);

const onDetachOwner = () => {
  if (formData.value.assigneeId || formData.value.groupId) return;

  switch (route.name) {
    case RouterByModuleName.USER_TASKS:
      formData.value.assigneeId =
        formData.value.assigneeId ||
        parseInt(decryptUserId(route.params?.id) || getCurrentUserId());
      break;
    case RouterByModuleName.GROUP_TASKS:
      formData.value.groupId =
        formData.value.groupId || parseInt(decryptGroupId(route.params?.id));
      break;

    default:
      formData.value.assigneeId =
        formData.value.assigneeId || getCurrentUserId();
      break;
  }
};
const isOpenFull = ref(false);

const onOpenNewForm = (taskName) => {
  isOpenFull.value = true;
  const localId = props.localId || Date.now();

  onSaveCacheForm(localId, formData.value);

  _taskDrawerStore.pushCurrentIds(
    {
      id: `${localId}`,
      code: 'New task 01',
      name: taskName,
      tabType: 'NEW',
    },
    false
  );

  emit('onOpenInterface');
  onFocusInputName();
};
const onChangeTaskName = (event) => {
  const taskName = event?.target?.value;
  if (!taskName || taskName?.length < 3 || isOpenFull.value) return;
  onDetachOwner();
  onOpenNewForm(taskName);
};

// const newLocalId = ref<any>(null);

const onUpdateDescription = (value) => {
  formData.value.description = value;

  synchronizeTaskToWorkflowCurrentStep();
};

const isEditCollaborator = ref<boolean>(false);
const manageCollaboratorRef = ref<any>(null);

const onCloseEditCollaborator = () => {
  manageCollaboratorRef.value?.onCloseEditMode();
};

const onChangeCollaborator = async (collaborators) => {
  const payload = collaborators?.map((user) => {
    return {
      ...user,
      memberUserId: user?.id || user?.userId,
    };
  });
  formData.value.addCollaboratorsInTask(payload);
};
const onRemoveCollaborator = async (userId) => {
  formData.value.deleteCollaboratorsInTask(userId);
};

const taskTodoListAllRef = ref<any>();
const isDraggingTodoList = ref<boolean>(false);
const isOpenWorkFlow = ref<boolean>(false);

const taskWorkflowBreadcrumbRef = ref<any>(null);

const latestUpdatedTime = ref<number>(Date.now());

const { useDayByScheduleOptionKey, getUserDay } =
  useUpdateDeadlineComposables();
const onAttachWorkflow = async (
  workflow: ITaskWorkflowEntity,
  needUpdateBreadcrumb?
) => {
  if (!isOpenFull.value) onOpenNewForm('');

  isOpenWorkFlow.value = false;
  const { needToUpdateDomain } = formData.value.onAttachWorkflow(workflow);
  if (formData.value?.assigneeId) await getUserDay(formData.value?.assigneeId);
  formData.value.updatePlanAndScheduleAfterAttachWorkflow(
    useDayByScheduleOptionKey.value
  );

  if (needToUpdateDomain && updateDomainRef.value?.initDomain)
    updateDomainRef.value?.initDomain();

  if (
    needUpdateBreadcrumb &&
    taskWorkflowBreadcrumbRef.value &&
    taskWorkflowBreadcrumbRef.value?.initComponent
  )
    taskWorkflowBreadcrumbRef.value?.initComponent();

  setTimeout(() => {
    initReferenceComponent();
  });

  latestUpdatedTime.value = Date.now();
};
const onDetachWorkflow = () => {
  formData.value.onDetachWorkflow();
};

const getFormData = () => {
  return formData.value;
};

const updateAttachments = (attachments: { files: any[]; notes: any[] }) => {
  formData.value.files = attachments?.files;
  formData.value.notes = attachments?.notes;

  if (
    (formData.value?.files?.length > 0 || formData.value?.notes?.length > 0) &&
    !isOpenFull.value
  ) {
    onDetachOwner();
    onOpenNewForm('');
  }
};
const updateChecklist = (checklist: ITaskTodo[] | null) => {
  formData.value.taskTodos = checklist || [];

  if (formData.value.taskTodos?.length > 0 && !isOpenFull.value) {
    onDetachOwner();
    onOpenNewForm('');
  }
};

const uploadingFiles = ref<any[]>([]);
const updateUploadingFiles = (value) => {
  uploadingFiles.value = value;
};

const isDisableSetPrivate = computed<boolean>(() => {
  return (
    isGroupTask.value ||
    formData.value?.taskWorkflow?.id ||
    (formData.value?.creatorId !== myProfile.value?.id &&
      formData.value?.assigneeId !== myProfile.value?.id) ||
    (formData.value?.collaborators && formData.value?.collaborators?.length > 0)
  );
});

const changeDeadlineTime = async ({ dateTime, timezone }) => {
  formData.value.scheduleTimezone = timezone;
  formData.value.scheduleByHour = true;
  formData.value.scheduleTime = dateTime;
  synchronizeTaskToWorkflowCurrentStep();
};
const onRemoveScheduleTime = async ({ dateTime }) => {
  formData.value.scheduleTimezone = '';
  formData.value.scheduleByHour = false;
  if (
    updateDeadlineRef.value?.onChooseDateSpecific &&
    typeof updateDeadlineRef.value?.onChooseDateSpecific == 'function'
  )
    updateDeadlineRef.value?.onChooseDateSpecific(dateTime);
  else formData.value.scheduleTime = dateTime;
};

const updateCurrentStep = (_, currentStep) => {
  let taskWorkflow = formData.value?.taskWorkflow;
  if (!taskWorkflow || taskWorkflow?.steps?.length == 0) return;

  taskWorkflow.steps[0] = {
    ...taskWorkflow.steps[0],
    ...currentStep,
  };
  onAttachWorkflow(taskWorkflow, true);
};

const isOpenApprovalModal = ref<boolean>(false);
const onSetApprover = async (approverIds) => {
  isOpenApprovalModal.value = false;
  formData.value.approvalEnabled = true;
  formData.value.approvalStatus = TaskApprovalStatus.NOT_REQUESTED;
  formData.value.approvers = approverIds?.map((userId) => {
    return {
      userId,
    };
  });
  formData.value.approverIds = approverIds;
};
const unMaskAsApproval = async () => {
  isOpenApprovalModal.value = false;
  formData.value.approvalEnabled = false;
  formData.value.approvalStatus = undefined;
  formData.value.approvers = [];
};

const doNotNeedCheckSubtaskPermission = ref<boolean>(false);

const ignoreCheckSubtaskPermission = () => {
  doNotNeedCheckSubtaskPermission.value = true;
};
const updateSubTasks = (newSubTasks) => {
  formData.value.subTaskIds = newSubTasks
    ?.filter((t) => t?.isSelecting)
    ?.map((t) => t?.id);
};

const isIncludeParent = ref<boolean>(true);

const isOpenSetMultipleAssignee = ref<boolean>(false);

const { synchronizeAssigneeWithDomain, synchronizeAssigneeWithTaskOwner } =
  taskMultipleAssigneeComposable(
    computed(() => {
      return {
        creatorId: formData.value?.creatorId || 0,
        assigneeId: formData.value?.assigneeId || 0,
        groupId: formData.value?.groupId,
        domainId: formData.value?.domainId,
        projectId: formData.value?.projectId,
        subprojectId: formData.value?.subprojectId,
      };
    })
  );

// *** HANDLER FUNCTIONS ***

const updateCollaboratorsByAssignees = () => {
  formData.value.collaborators =
    formData.value.multiAssignees
      ?.filter(
        (o) =>
          o?.assigneeId !== formData.value?.creatorId &&
          o?.assigneeId !== formData.value?.assigneeId
      )
      ?.map((user) => {
        return {
          ...user,
          memberUserId: user?.assigneeId,
          memberRoleId: _taskStore.roleCollaboratorId,
        };
      }) || [];
};
const onSetMultipleAssignee = async (data) => {
  formData.value.groupId = undefined;
  formData.value.assigneeId = formData.value?.creatorId;
  formData.value.isPrivate = false;

  formData.value.multiAssignees = synchronizeAssigneeWithDomain(
    data?.map((o) => {
      return {
        ...o,
        assigneeId: o?.id,
      };
    }),
    formData.value?.domainId,
    formData.value?.projectId,
    formData.value?.subprojectId
  );
  updateCollaboratorsByAssignees();
  isOpenSetMultipleAssignee.value = false;
};

// *** PRIVATE FUNCTIONS ***

// *** EXPOSES ***

const selectAction = (key) => {
  switch (key) {
    case EActionOnTask.ASSIGN_USER_LIST:
      isOpenSetMultipleAssignee.value = true;
      break;
    case EActionOnTask.WORKFLOW:
      isOpenWorkFlow.value = true;

      break;

    default:
      break;
  }
};

const toggleSelectParentTask = () => {
  isIncludeParent.value = !isIncludeParent.value;
};

const isOpenSearchTaskModal = ref<boolean>(false);
const setParentTask = async (task) => {
  isOpenSearchTaskModal.value = false;
  isIncludeParent.value = true;
  formData.value.setParentTask(task);
};

const removeAsSubTask = async () => {
  formData.value.removeAsSubTask();
};

defineExpose({
  onResetForm,
  onFocusInputName,
  getFormData,
  onAttachWorkflow,
});
</script>

<template>
  <div
    id="task-create-new"
    v-drop-zone="{ isDragging: dragOver, onDrop: onDropFiles }"
    class="flex flex-col w-full h-full relative text-sm"
    @paste="onPasteImage"
    @click="onCloseEditCollaborator"
  >
    <UploadDropZone
      v-if="!isDraggingTodoList && isDragging"
      class="absolute w-full top-0 left-0 right-0 bottom-0 z-20"
    />
    <div
      v-if="formData?.taskWorkflow?.id"
      class="w-full flex flex-col items-start pb-2 space-y-1"
    >
      <!-- <span class="text-xs w-28 text-gray-500 flex items-center pt-2">
  {{ $t('TASK_ACTION_WORK_FLOW') }}
</span> -->
      <div class="flex-1 min-w-0 w-full flex items-center relative">
        <TaskWorkflowCurrentStep
          ref="taskWorkflowBreadcrumbRef"
          view-mode="ATTACH_TO_TASK"
          :task-detail="formData"
          :default-task-workflow="formData.taskWorkflow"
          @detach="onDetachWorkflow"
          @on-attach="onAttachWorkflow"
          @update-current-step="updateCurrentStep"
        />
        <div
          v-if="loadingData.workflow"
          class="w-full h-full cursor-wait absolute top-0 left-0 z-10"
        ></div>
      </div>
    </div>
    <!-- HEADER OPTION  -->
    <section
      v-if="isOpenFull"
      class="
        w-full
        flex
        items-center
        justify-center
        py-2
        px-2
        border-b border-gray-100
      "
    >
      <section
        class="flex-1"
        :class="
          [TaskLife.Todo, TaskLife.InProcess, TaskLife.Finished]?.includes(
            formData?.taskLife
          )
            ? 'flex-center'
            : 'ml-2'
        "
      >
        <div class="flex-center space-x-2">
          <TaskStateStepper
            :task-detail="formData"
            :state-form="'CREATE'"
            @on-change-state="onChangeTaskState"
          />
          <ExplainIcon type="task-state" />
        </div>
      </section>
      <!-- <AttachWorkflowDropdown
        v-if="!formData?.taskWorkflow?.id"
        :form-data="formData"
        @on-attach="(data) => onAttachWorkflow(data, true)"
      /> -->

      <div class="flex-center gap-2">
        <ActionOnTask
          v-if="!formData?.taskWorkflow?.id && !isAssignUserListTask"
          view-mode="QUICK_ACTION"
          :current-task="formData"
          :action-list="[EActionOnTask.WORKFLOW]"
          @attach-workflow="(data) => onAttachWorkflow(data, true)"
        />
        <ActionOnTask
          :current-task="formData"
          is-hidden-remove
          is-hidden-tool
          :action-list="
            formData?.taskWorkflow?.id
              ? [EActionOnTask.WORKFLOW]
              : [
                  EActionOnTask.WORKFLOW,
                  EActionOnTask.ASSIGN_USER_LIST,
                  EActionOnTask.SUB_TASK,
                ]
          "
          @select-action="selectAction"
          @set-parent-task="setParentTask"
          @remove-as-sub-task="removeAsSubTask"
        />
      </div>
    </section>
    <!--  END HEADER OPTION  -->

    <!-- BODY -->
    <section
      ref="taskDetailRef"
      v-perfect-scrollbar
      class="flex-1 min-h-0 flex flex-col space-y-3"
    >
      <!-- PRIVATE -->
      <section
        v-if="isOpenFull"
        v-permission-model
        class="w-full flex items-center gap-2 p-2 text-sm bg-gray-50"
      >
        <template v-if="!isGroupTask">
          <div class="flex items-center justify-between w-full space-x-2 px-2">
            <div class="flex items-center space-x-2" style="max-width: 75%">
              <div class="flex-center h-2.5 w-2.5">
                <SynIcon
                  :name="formData.isPrivate ? 'private' : 'global'"
                  class="fill-gray-500"
                  custom-class="h-2.5 w-2.5"
                />
              </div>
              <span
                class="text-gray-600 text-overflow-hidden-line"
                v-html="textPrivateParser"
              >
              </span>
            </div>
            <div
              v-if="!isDisableSetPrivate"
              class="flex items-center space-x-2"
            >
              <AtomSwitch
                :model-value="formData.isPrivate"
                size="small"
                @update:model-value="onSetPrivateTask"
              />
              <span class="rounded cursor-pointer text-gray-500">
                {{ $t('COMMON_LABEL_PRIVATE') }}
              </span>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="flex items-center space-x-2">
            <span
              class="text-gray-600"
              v-html="
                getGroupInfo(formData?.groupId)?.isPrivate
                  ? $t('TASK_CREATE_IS_PUBLIC_GROUP', {
                      groupName: getGroupInfo(formData?.groupId)?.name,
                    })
                  : $t('TASK_CREATE_IS_PUBLIC', { orgName: orgInfo?.name })
              "
            >
            </span>
          </div>
        </template>

        <TaskApprovalShortInfo
          :approval-enabled="formData?.approvalEnabled"
          :approval-status="formData?.approvalStatus"
          :approvers="formData?.approvers"
          :approver-id="formData?.approverId"
          @on-edit="isOpenApprovalModal = true"
          @on-remove="unMaskAsApproval"
        />
      </section>
      <section
        v-if="formData?.parentId"
        class="w-1/2 items-center px-4 inline-flex text-sm gap-2 cursor-pointer"
      >
        <div
          v-if="formData?.isClone"
          class="
            h-4
            w-4
            flex-center
            rounded
            border
            ring-current-300
            cursor-pointer
            group-hover:ring-1
            border-current-300
          "
          :class="{
            'bg-current-300': isIncludeParent,
          }"
          @click="toggleSelectParentTask"
        >
          <SynIcon v-if="isIncludeParent" name="check" class="text-white" />
        </div>
        <p class="inline text-gray-600 truncate">
          {{ formData?.parentName }}
        </p>
        <AtomHelpDropdown
          v-if="formData?.isClone"
          title="TASK_LABEL_SELECT_DUPLICATED_INCLUDE_PARENT_TITLE"
          :content="[
            'TASK_LABEL_SELECT_DUPLICATED_INCLUDE_PARENT_CONTENT_1',
            'TASK_LABEL_SELECT_DUPLICATED_INCLUDE_PARENT_CONTENT_2',
          ]"
        />
        <SynIcon
          v-else
          name="edit"
          is-active
          class="fill-gray-500 cursor-pointer hover:fill-current-500"
          :title="$t('COMMON_LABEL_EDIT') || 'Edit'"
          @click.stop="isOpenSearchTaskModal = true"
        />
      </section>
      <!-- NAME TASK -->
      <section
        class="
          flex
          items-start
          relative
          space-x-2
          justify-between
          text-xl
          px-2
          pt-3
          pb-2
        "
      >
        <UserByAction
          :user-id="formData?.creatorId"
          is-show-action-user
          is-hidden-name
          avatar-class="w-10 h-10 text-xs font-medium"
        />
        <div
          v-if="isInputName"
          class="flex-1 flex-col space-x-2 relative z-20"
          style="min-height: 2.5rem"
        >
          <SynInput
            v-model="formData.name"
            input-id="task-create-new"
            :placeholder="
              $t('TASK_CREATE_FORM_TASK_CREATE_FORM_NAME') || 'Task name'
            "
            max-length="200"
            autofocus
            @focus="onFocusInputName"
            @blur="isInputName = false"
            @change="onChangeTaskName"
          />
        </div>
        <div
          v-else
          class="
            flex-1 flex
            items-center
            space-x-2
            px-2
            rounded
            cursor-text
            w-2/3
            hover:bg-gray-100
            hover-to-show__parent
          "
          style="min-height: 2.5rem"
          @click.stop="isInputName = true"
        >
          <span v-if="formData?.name?.trim()" class="break-words font-medium">
            {{ formData?.name }}
          </span>
          <span
            v-else
            class="font-medium text-gray-300 border-l-2 border-gray-500"
          >
            {{ $t('TASK_CREATE_NEW_PLACEHOLDER') || 'Task name' }}
          </span>
        </div>

        <span
          v-if="isValidate && !formData.name?.trim()"
          class="absolute -bottom-2 left-0 text-red-500 text-xs"
        >
          *
          {{
            $t('TASK_CREATE_FORM_TASK_CREATE_FORM_IS_REQUIRED') || 'is required'
          }}
        </span>
      </section>

      <template v-if="isOpenFull">
        <!-- ASSIGNMENT -->
        <section
          v-permission-model
          class="w-full pl-4 pr-2 flex items-center space-x-4"
        >
          <span class="text-xs w-28 text-gray-500 flex items-center h-8">
            {{ $t('TASK_LABEL_ASSIGNEE') || 'Responsible' }}
          </span>
          <div class="flex-1 flex items-center space-x-1">
            <TaskDetailField :is-editable="!isAssignUserListTask">
              <template #fieldName>
                <span>{{ $t('TASK_LABEL_ASSIGNEE') }}</span>
              </template>
              <template #body>
                <div style="max-width: 24rem; min-width: 10rem">
                  <template v-if="isAssignUserListTask">
                    <div
                      class="
                        flex
                        items-center
                        relative
                        rounded
                        text-xs
                        font-medium
                        p-2
                      "
                    >
                      <UserById
                        v-if="formData?.assigneeId"
                        is-show-first-name
                        avatar-class="w-6 h-6 text-xs"
                        :user-id="formData?.assigneeId"
                      />
                    </div>
                  </template>
                  <ChangeAssign
                    v-else
                    ref="changeAssignRef"
                    v-permission="'FunctionTaskAssign'"
                    is-update-instant
                    can-remove-all
                    :group-id="formData.groupId"
                    :user-id="formData.assigneeId"
                    @on-save="onSaveAssign"
                    @focus="onCloseEditCollaborator"
                  />
                </div>
              </template>
            </TaskDetailField>
            <!-- COLLABORATOR -->
            <template
              v-if="
                multipleAssigneeSetting?.systemEnable &&
                multipleAssigneeSetting?.organizationEnable &&
                !formData?.isPrivate
              "
            >
              <TaskDetailField>
                <template #fieldName>
                  <span>{{ $t('COMMON_LABEL_COLLABORATORS') }}</span>
                </template>
                <template #body>
                  <div class="px-2">
                    <ManageCollaborator
                      ref="manageCollaboratorRef"
                      v-model:is-edit-mode="isEditCollaborator"
                      class="flex-1"
                      :group-id="formData.groupId || 0"
                      :assignee-id="formData.assigneeId || 0"
                      :creator-id="myProfile?.id || 0"
                      :collaborators="formData.collaborators"
                      :total-show-item="4"
                      :readonly="isAssignUserListTask"
                      @on-add="onChangeCollaborator"
                      @on-remove="onRemoveCollaborator"
                    />
                  </div>
                </template>
              </TaskDetailField>
            </template>
          </div>
        </section>
        <!-- END ASSIGNMENT -->

        <!-- DOMAIN  -->
        <section
          v-permission-model
          class="w-full px-4 flex items-center space-x-4"
        >
          <div
            class="w-28 flex items-center justify-between space-x-1 relative"
          >
            <span class="text-xs text-gray-500 flex items-center h-8">
              {{ $t('DOMAIN_LABEL_TITLE') }}
            </span>
            <ExplainIcon type="domain" />
          </div>

          <div class="flex flex-col flex-1">
            <div class="flex space-x-1 text-xs">
              <UpdateDomain
                ref="updateDomainRef"
                :task-detail="formData"
                createable
                :is-update-domain-only="!!formData?.taskWorkflow?.id"
                @save="onSaveDomain"
                @on-add-new-domain="onAddNewDomain"
              />
            </div>
            <span
              v-if="isValidate && !formData.domainId"
              class="text-red-500 text-xs"
            >
              *
              {{
                $t('TASK_CREATE_FORM_TASK_CREATE_FORM_IS_REQUIRED') ||
                'is required'
              }}
            </span>
          </div>
        </section>
        <!-- END DOMAIN -->

        <!-- URGENCY + STATE  -->
        <section class="w-full px-4 flex items-center space-x-4">
          <span class="text-xs w-28 text-gray-500 flex items-center h-8">
            {{ $t('TASK_TABLE_LABEL_URGENCY') || 'Urgency' }}
          </span>
          <div class="flex flex-col">
            <div class="flex items-center space-x-2">
              <div
                v-for="(item, index) in [4, 3, 2, 1]"
                :key="index"
                class="
                  cursor-pointer
                  rounded-full
                  pr-2
                  pl-1
                  py-1
                  hover:bg-opacity-100
                "
                :class="
                  formData.urgency === item
                    ? 'bg-current text-white font-medium'
                    : 'border border-current-50 hover:bg-current-50'
                "
                @click="onChangeUrgency(item)"
              >
                <PriorityTask :urgency="item" size="mini" />
              </div>
            </div>
            <span
              v-if="isValidate && formData.urgency == null"
              class="text-red-500"
            >
              *
              {{
                $t('TASK_CREATE_FORM_TASK_CREATE_FORM_IS_REQUIRED') ||
                'is required'
              }}
            </span>
          </div>
        </section>
        <!-- END URGENCY + STATE  -->

        <!--  PLAN TODAY -->
        <section
          v-if="formData.assigneeId && formData.taskLife !== TaskLife.Finished"
          class="w-full px-4 flex items-center space-x-4"
        >
          <span class="text-xs w-28 text-gray-500 flex items-center h-8">
            {{ $t('TASK_FILTER_LABEL_PLANNED') }}
          </span>

          <div class="flex items-center rounded-md space-x-4">
            <div class="flex items-center space-x-2">
              <AtomSwitch
                v-model="formData.taskSchedule.doItToday"
                @change="onDoItToday"
              >
                <span class="text-current-800 text-xs">
                  {{ $t('TASK_CREATE_FORM_DOING_TODAY') || 'Do it today' }}
                </span>
              </AtomSwitch>
            </div>
            <div class="flex items-center space-x-2">
              <AtomSwitch
                v-model="formData.taskSchedule.finishItToday"
                @change="onFinishItToday"
              >
                <span class="text-current-800 text-xs">
                  {{ $t('TASK_CREATE_FORM_FINISH_TODAY') || 'Finish it today' }}
                </span>
              </AtomSwitch>
            </div>
          </div>
        </section>
        <!-- END PLAN TODAY -->

        <!--  DEADLINE -->
        <section class="w-full px-4 flex items-center">
          <div class="flex items-center w-full space-x-6">
            <div class="flex items-center space-x-4">
              <span class="text-xs w-28 text-gray-500 flex items-center h-8">{{
                $t('TASK_TABLE_LABEL_DEADLINE') || 'Deadline'
              }}</span>
              <div class="flex items-center h-full gap-2">
                <UpdateDeadline
                  v-if="formData?.assigneeId"
                  ref="updateDeadlineRef"
                  :schedule-key="formData?.taskSchedule?.scheduleOptionKey"
                  :other-time="formData.taskSchedule.otherTime"
                  :schedule="formData.scheduleTime"
                  :schedule-timezone="formData.scheduleTimezone"
                  :user-id="formData.assigneeId"
                  :group-id="formData.groupId"
                  :start-time="formData.startTime"
                  :is-get-schedule-time-by-default="
                    !!newTaskDefaultPayload?.scheduleTime
                  "
                  @save="onSaveDeadline"
                  @on-change-schedule-time="
                    (value) => (formData.scheduleTime = value)
                  "
                >
                  <template #scheduleTime>
                    <UpdateDeadlineTime
                      v-if="formData?.scheduleTime"
                      :schedule-time="formData?.scheduleTime"
                      :schedule-by-hour="formData?.scheduleByHour"
                      :schedule-timezone="formData?.scheduleTimezone"
                      :my-timezone="myTimezone"
                      @on-change="changeDeadlineTime"
                      @on-remove-time="onRemoveScheduleTime"
                    />
                  </template>
                </UpdateDeadline>
                <template v-else>
                  <AtomDatePicker
                    v-model="formData.scheduleTime"
                    :config="{ type: 'string', mask: 'YYYY/MM/DD' }"
                  />
                  <UpdateDeadlineTime
                    :schedule-time="formData?.scheduleTime"
                    :schedule-by-hour="formData?.scheduleByHour"
                    :schedule-timezone="formData?.scheduleTimezone"
                    :my-timezone="myTimezone"
                    @on-change="changeDeadlineTime"
                    @on-remove-time="onRemoveScheduleTime"
                  />
                </template>
              </div>
            </div>
          </div>
        </section>
        <!-- END  DEADLINE -->

        <!-- DESCRIPTION -->
        <div class="w-full px-4 flex items-start">
          <span class="text-xs w-32 text-gray-500 flex items-center h-8">
            {{ $t('COMMON_LABEL_DESCRIPTION') || 'Description' }}
          </span>
          <div
            class="flex-1 flex-center space-x-4"
            style="min-width: 0; max-width: 100%"
          >
            <TaskDescriptionInput
              v-if="isInputComment"
              ref="taskDescriptionRef"
              v-model="myComment"
              v-model:is-valid="myDescriptionValid"
              :current-task="formData"
              :is-action="false"
              @on-close="onCancelComment"
              @update:model-value="onUpdateDescription"
            />
            <div
              v-else
              class="
                whitespace-pre-wrap
                rounded
                border border-transparent
                hover:border-gray-300
                inline-block
                w-full
                hover-to-show__parent
                leading-6
              "
              :class="[
                !formData.description ? 'cursor-pointer' : 'cursor-text',
                formData.description ? 'p-1 pl-1' : 'px-2 py-1',
              ]"
              :style="
                formData.description
                  ? 'padding-top: 12px; padding-bottom: 12px'
                  : ''
              "
              @click.stop="onOpenComment(formData.description)"
            >
              <span
                class="task-detail_description break-words text-gray-500"
                v-html="
                  formData.description ||
                  $t('COMMON_LABEL_PLACEHOLDER_ADD_DESCRIPTION') ||
                  'Add description ...'
                "
              ></span>
              <SynIcon
                name="edit"
                is-active
                has-action
                class="hover-to-show__children absolute top-1 right-1"
                :title="$t('COMMON_LABEL_EDIT') || 'Edit'"
                @click.stop="onOpenComment(formData.description)"
              />
            </div>
          </div>
        </div>
        <!-- END DESCRIPTION -->

        <!-- MULTIPLE ASSIGNEE -->
        <template
          v-if="formData.multiAssignees && formData.multiAssignees?.length > 0"
        >
          <div
            class="
              flex
              items-center
              gap-2
              text-gray-500
              hover:text-current
              cursor-pointer
            "
            @click="isOpenSetMultipleAssignee = true"
          >
            <span class="px-4">
              {{ $t('TASK_LABEL_ASSIGN_USER_LIST') }}
            </span>
            <SynIcon
              name="edit"
              is-active
              has-action
              :title="$t('COMMON_LABEL_EDIT') || 'Edit'"
            />
          </div>
          <div class="w-full px-4 flex items-start">
            <span class="text-xs w-8 text-gray-500 flex items-center h-8">
            </span>
            <div
              class="flex-1 flex-center space-x-4"
              style="min-width: 0; max-width: 100%"
            >
              <TaskMultipleAssignee
                v-model="formData.multiAssignees"
                :form-data="formData"
                @update:model-value="updateCollaboratorsByAssignees"
              />
            </div>
          </div>
        </template>

        <!-- SUB TASK -->
        <div
          v-if="formData?.subTasks?.length > 0"
          v-permission-function="{
            functionCode: doNotNeedCheckSubtaskPermission
              ? ''
              : ALL_FUNCTIONS.TASK.SUB_TASK,
            onContinue: () => {
              ignoreCheckSubtaskPermission();
            },
          }"
          v-system-config="[
            {
              functionCode: 'ADVANCED_SUB_TASK_SUB_TASK_ALLOWED',
              functionValue: '1',
            },
            {
              functionCode: 'ADVANCED_SUB_TASK_SUB_TASK_ALLOWED_SYSTEM',
              functionValue: '1',
            },
          ]"
          class="w-full px-4 flex flex-col"
        >
          <!-- <div class="flex items-center gap-2">
            <span class="text-xs text-gray-500 flex items-center h-8">
              {{ $t('COMMON_LABEL_SUB_TASK') || 'Sub tasks' }}
            </span>
            <AtomHelpDropdown
              title="TASK_LABEL_SELECT_THE_SUB_TASK_TO_BE_DUPLICATED_TITLE"
              content="TASK_LABEL_SELECT_THE_SUB_TASK_TO_BE_DUPLICATED_CONTENT"
            />
          </div> -->
          <div
            class="flex-1 flex-center space-x-4"
            style="min-width: 0; max-width: 100%"
          >
            <!-- <SubTaskList
              ref="atomChecklistRef"
              :parent-task="formData"
              is-select-mode
              @update:sub-tasks="updateSubTasks"
            /> -->
            <TaskMultipleAssignee
              v-model="formData.subTasks"
              :form-data="formData"
              is-sub-tasks
              @update:model-value="updateSubTasks"
            />
          </div>
        </div>
      </template>

      <!-- ATTACHMENT -->
      <AttachmentInput
        ref="attachmentInputRef"
        :key="latestUpdatedTime"
        :local-id="localId"
        :original-data="formData"
        :files="formData?.files"
        :notes="formData?.notes"
        :input-file-id="taskCreateNewInputFileId"
        is-upload-right-away
        @update:attachments="updateAttachments"
        @update:checklist="updateChecklist"
        @update:uploading-files="updateUploadingFiles"
      />
    </section>
    <!-- Footer zone -->
    <div class="flex items-center justify-between px-4 py-2 w-full bg-white">
      <div class="flex-center space-x-2 pr-5">
        <SynButton
          :label="$t('COMMON_LABEL_RESET') || 'Reset'"
          class="bg-gray-100"
          color="gray"
          type-text
          name-icon="reload"
          :disabled="!isOpenFull"
          @click.stop="onResetForm"
        />
        <div
          class="flex-center space-x-2 transition-all"
          :class="isShowScheduleTime ? 'opacity-1' : 'opacity-0'"
        >
          <span class="text-xs text-gray-500 flex items-center h-8">
            {{ $t('TASK_TABLE_LABEL_DEADLINE') }}
          </span>
          <SynLabelDateTime2
            v-if="formData.scheduleByHour"
            format="date_time"
            :datetime="formData.scheduleTime"
            :timezone="formData.scheduleTimezone"
            class="font-semibold text-current-600"
          />
          <SynLabelDateTime
            v-else-if="formData.scheduleTime"
            format="date"
            :datetime="formData.scheduleTime"
            class="font-semibold text-current-600"
          />
        </div>
      </div>
      <div class="flex-center space-x-2">
        <SynButton
          :label="$t('COMMON_LABEL_CANCEL') || 'Cancel'"
          color="gray"
          type-text
          :disabled="!isOpenFull"
          @click.stop="onCancelForm"
        />
        <SynButton
          v-vig-tooltip="$t('COMMON_LABEL_CREATE') || 'Create'"
          :is-loading="isLoadingSubmitButton"
          :disabled="
            (!isOpenFull && !formData.name?.trim()) ||
            uploadingFiles?.length > 0
          "
          @click="onSubmitForm"
        >
          <div class="flex items-center justify-between px-1 space-x-1">
            <span class="text-medium text-white">{{
              $t('COMMON_LABEL_CREATE') || 'Create'
            }}</span>
            <div
              v-if="
                taskStates[formData.taskLife]?.key !== TaskLife.Created &&
                isOpenFull
              "
              class="flex items-center space-x-1 italic rounded-full px-1"
              :class="`${taskStates[formData.taskLife]?.background} ${
                taskStates[formData.taskLife]?.color
              }`"
              style="font-size: 11px"
            >
              <span
                class="p-0.5 rounded-full"
                :class="`bg-${taskStates[formData.taskLife]?.colorKey}`"
              ></span>
              <span>{{ $t(taskStates[formData.taskLife]?.nameCode) }}</span>
            </div>
          </div>
        </SynButton>
      </div>
    </div>
  </div>

  <CreateNoteModal
    v-if="isCreateNote"
    :draw-note-data="{
      ...currentNote,
      isLoading: isLoadingDrawData,
      images:
        formData.files?.length > 0
          ? formData.files.filter((o) => isImage(o))
          : [],
      originFile: '',
      readonly: false,
    }"
    @on-close="onCancelNote"
    @on-save="
      (data) =>
        onHandleSaveNote({
          ...currentNote,
          ...data,
        })
    "
  />

  <RecorderModal
    v-if="isOpenRecording"
    @on-done="onSaveAudio"
    @on-cancel="onCreateAudioCancel"
  />

  <ModalMultipleFileViewer
    v-if="isPreview"
    :start-index="currentFileIndex"
    :files="allAttachments"
    @on-close="isPreview = false"
    @on-save-description="onClickSaveDescription"
    @on-save-note="onSaveNote"
  >
    <template #title>
      <span>
        {{ formData?.name }}
      </span>
    </template>
    <template #footer-action>
      <div
        class="
          m-0.5
          w-16
          h-16
          flex-center flex-col
          cursor-pointer
          border border-dashed border-blue-300
          rounded-md
          shadow
          hover-to-show__parent
          relative
          bg-white
        "
      >
        <SynIcon
          name="plus"
          class="fill-blue-500 text-blue-500"
          custom-class="h-6 w-6"
        />
        <div
          class="
            absolute
            w-full
            h-full
            flex-center flex-col
            cursor-pointer
            rounded-md
            hover-to-show__children
            bg-white
          "
        >
          <div class="flex-center w-full h-1/2">
            <label
              :for="taskCreateNewInputFileId"
              class="
                w-1/2
                h-full
                hover:bg-current-100
                flex-center
                cursor-pointer
              "
              :title="
                $t('COMMON_LABEL_DRAG_AND_DROP_FILE_HERE') || 'Attachment'
              "
            >
              <SynIcon name="attach" class="fill-current-500" />
            </label>
            <div
              class="w-1/2 h-full hover:bg-orange-100 flex-center"
              :title="$t('TASK_CREATE_FORM_LABEL_ADD_NOTE') || 'Add note'"
              @click="onCreateNote(false)"
            >
              <SynIcon
                name="noter"
                is-active
                class="fill-orange-500"
                custom-class="h-4 w-4"
              />
            </div>
          </div>
          <div class="flex-center w-full h-1/2">
            <div
              class="w-1/2 h-full hover:bg-blue-100 flex-center"
              :title="$t('TASK_DETAIL_LABEL_ADD_AUDIO') || 'Add audio'"
              @click="onCreateAudio"
            >
              <SynIcon is-active name="microphone" class="fill-blue-500" />
            </div>
            <div
              class="w-1/2 h-full hover:bg-blue-100 flex-center"
              :title="$t('TASK_CREATE_FORM_LABEL_COMMENT') || 'Add comment'"
              @click="onOpenComment(formData.description)"
            >
              <SynIcon is-active name="comment" class="fill-current" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </ModalMultipleFileViewer>

  <ApplyWorkFlowModal
    v-if="isOpenWorkFlow"
    :task-workflow="
      formData?.taskWorkflow?.id ? formData?.taskWorkflow : undefined
    "
    :domain-id="formData?.domainId"
    :group-id="formData?.groupId"
    :task-detail="formData"
    @cancel="isOpenWorkFlow = false"
    @on-attach="(data) => onAttachWorkflow(data, true)"
  />

  <TaskSetApprovalModal
    v-if="isOpenApprovalModal"
    :task="formData"
    @on-close="isOpenApprovalModal = false"
    @on-save="onSetApprover"
  />

  <SelectMultipleUserModal
    v-if="isOpenSetMultipleAssignee"
    :selected-users="formData?.multiAssignees"
    is-cache-default
    @cancel="isOpenSetMultipleAssignee = false"
    @save="onSetMultipleAssignee"
  >
    <template #header>
      <div>
        {{ $t('TASK_LABEL_ASSIGN_USER_LIST') || 'Add involved people' }}
      </div>
    </template>
  </SelectMultipleUserModal>
  <SearchTasksModal
    v-if="isOpenSearchTaskModal"
    :title="$t('SUB_TASK_SELECT_A_TASK_TO_MAKE_THIS_TASK_A_SUBTASK')"
    :filter-function="
      (t) => {
        return !t?.parentId && t?.id !== formData?.parentId;
      }
    "
    @on-choose="setParentTask"
    @on-close="isOpenSearchTaskModal = false"
  />
</template>
