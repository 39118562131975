import { createPinia } from 'pinia';
import { createApp } from 'vue';

import Root from '@/ui/Root.vue';
// import './ui/interceptor-os';
import './ui/interceptor';

import VCalendar from 'v-calendar';
import router from './ui/router';
import { declare } from './ui/hooks/declareComponents';
import filters from './ui/plugins/filters';
import { myi18n } from './ui/plugins/i18n/myi18n';
import './ui/index.css';
import 'nprogress/nprogress.css';
import '@/ui/plugins/scrollbar/perfect-scrollbar.css';
import { detectLocation } from '@/ui/app-global-state';
import customDirective from '@/ui/directive';
import '@/base/systems/check-network';
import 'rss-time-picker/dist/style.css';
import 'itl-drawer';
import $constants from '@/ui/plugins/constants';

document.title = 'Fiine';

detectLocation();

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $t: any;
        $constants: any;
        $locale: any;
    }
}

(window as any).global = window;
window.Buffer = window.Buffer;

const app = createApp(Root);
app.directive('cusFocus', {
    // When the bound element is mounted into the DOM...
    mounted(el, binding) {
        // Focus the element
        if (binding.value) {
            el.focus();
        }
    },
});
app.config.globalProperties.$constants = $constants;
app.use(createPinia());
app.use(myi18n);

app.use(VCalendar, {});
app.use(filters);
// declare custom directive
customDirective(app);

// declare(requireCommon, app);
// declare(iconComponent, app);
// declare(layoutComponent, app);

declare(
    import.meta.glob(
        [
            './ui/common/**/{Syn,Vig,Atom,Molecule}*.vue',
            './ui/components/icons/*.vue',
            './ui/components/layouts/*.vue',
        ],
        {
            eager: true,
        }
    ),
    app
);

app.use(router);
app.mount('#app');
