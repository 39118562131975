<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { isValidPhoneNumber, stringNomalize } from '@/ui/hooks/commonFunction';
import organizationStore from '@/store/organization';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { companySizeRange, teamSizeRange } from '@/ui/common/constants/org';
import { bucketTictop } from '@/ui/plugins/awss3/AwsS3Config';
import {
  putObjectToAwsS3,
  awsS3Delete,
} from '@/ui/plugins/awss3/aws-s3-services';
import { getFileType, getFileExtension } from '@/ui/hooks/fileHook';
import UploadAvatarIcon from '@/ui/components/photo-upload/UploadAvatarIcon.vue';
import inputValueComp from '@/ui/common/composables/form/input-value-comp';
import appStore from '@/store/app';
import { CountryCode } from 'libphonenumber-js';
import atomFormInputComposables from '@/ui/common/atoms/SynInput/atom-form-input-composables';
import TictopWebSocket, {
  USER_REG_EVENT,
} from '@/ui/plugins/websocket/ws-instance';
import {
  StorageConstant,
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from '@/ui/hooks/storageHook';
import { AuthenticateSelectOrganization } from '@/application/services/authenticate/authenticate-service';
import stepperComposables from '@/ui/composables/app/stepper-composables';
import {
  GOOGLE_ANALYTICS_ELEMENT_ID,
  REGISTER_STEP_KEYS,
  dataByOrganizationModel,
} from '@/application/constants/register.const';
import {
  EOrganizationModel,
  IOrganizationInfoPayload,
} from '@/application/types/organization/organization.type';
import permissionStore from '@/store/permission';

const props = withDefaults(
  defineProps<{
    organizationModel: EOrganizationModel;
    doNotNeedToCheckPersonal: boolean;
    isSubmitting?: boolean;
    hasUpdateCurrentOrganization?: boolean;
    selectedModel?: any;
    isUpgradeCurrentOrg?: boolean;
    isHomePage?: boolean;
  }>(),
  {}
);

const emit = defineEmits<{
  (e: 'onCancel'): void;
  (
    e: 'onSubmit',
    data: { mode: number; data: any; isUpgradeCurrentOrganization: boolean }
  ): void;
  (e: 'chooseOtherModel'): void;
}>();

let ws: TictopWebSocket;

const _appStore = appStore();
const _organizationStore = organizationStore();
const currentOrganizationInfo = computed<any>(
  () => _organizationStore.organizationInfo
);

const organizationByIds = computed(() => {
  return _organizationStore.organizationByIds;
});

const myPersonalWorkspace = computed<any>(() => {
  return Object.values(organizationByIds.value)?.find(
    (org: any) => org?.model == 'PERSONAL'
  );
});

const isPersonalModel = computed<boolean>(
  () => permissionStore().organizationModel === 'PERSONAL'
);

const isHasPersonalModel = computed<boolean>(() => {
  if (props.doNotNeedToCheckPersonal) return false;

  return !!myPersonalWorkspace.value?.id;
});
const appLanguage = computed(() => appStore().language);

const currentOrganizationModelData = computed<any>(() => {
  if (
    !props.organizationModel ||
    !Object.values(EOrganizationModel).includes(props.organizationModel)
  )
    return dataByOrganizationModel[EOrganizationModel.PERSONAL];

  return dataByOrganizationModel[props.organizationModel];
});

const {
  currentStep,
  stepperByKey,
  onHandleGoToPreviousStep,
  onHandleGoToNextStep,
  updateCurrentStep,
} = stepperComposables(
  {
    EXPLAIN: {
      key: REGISTER_STEP_KEYS.EXPLAIN,
      previous: REGISTER_STEP_KEYS.EXPLAIN,
      previousAction: () => {
        emit('chooseOtherModel');
        ws.send(USER_REG_EVENT.CLICK_SELECT_ORGANIZATION_MODEL_BACK);
      },
      next: REGISTER_STEP_KEYS.ENTER_MAIN_INFO,
      nextAction: () => {
        ws.send(USER_REG_EVENT.CLICK_SELECT_ORGANIZATION_MODEL_CONTINUE);
      },
      label: 'ORGANIZATION_LABEL_TYPE',
      buttonBack: 'COMMON_LABEL_PREVIOUS',
      buttonNext: props.hasUpdateCurrentOrganization
        ? 'COMMON_LABEL_CONTINUE_CREATE'
        : 'COMMON_LABEL_NEXT',
      title: 'FIRSTTIME_SETUP_PERSONAL_ORGANIZATION',
      subTitle: 'FIRSTTIME_SETUP_PERSONAL_ORGANIZATION_SUB',
    },
    ENTER_MAIN_INFO: {
      key: REGISTER_STEP_KEYS.ENTER_MAIN_INFO,
      previous: '',
      previousAction: () => {},
      next: REGISTER_STEP_KEYS.CHOOSE_TYPE,
      nextAction: () => {
        ws.send(USER_REG_EVENT.INPUT_ORGANIZATION_INFO);
      },
      label: 'ORGANIZATION_LABEL_TYPE',
      buttonBack: '',
      buttonNext: 'COMMON_LABEL_NEXT',
      title: 'FIRSTTIME_SETUP_PERSONAL_ORGANIZATION',
      subTitle: 'FIRSTTIME_SETUP_PERSONAL_ORGANIZATION_SUB',
    },
    CHOOSE_TYPE: {
      key: REGISTER_STEP_KEYS.CHOOSE_TYPE,
      previous: 'ENTER_MAIN_INFO',
      previousAction: () => {},
      next: '',
      nextAction: () => {
        if (!infoPayload.value?.type) {
          return (typeError.value = translate('WELCOME_SETTING_TYPE_REQUIRED'));
        }
        ws.send(USER_REG_EVENT.CLICK_SELECT_ORGANIZATION_SETTING_TYPE);

        emit('onSubmit', {
          mode: 2,
          data: {
            ...infoPayload.value,
            name: organizationName.value,
            phone: organizationPhone.value,
          },
          isUpgradeCurrentOrganization: !!props.isUpgradeCurrentOrg,
        });
      },
      label: 'ORGANIZATION_LABEL_TYPE',
      buttonBack: 'COMMON_LABEL_PREVIOUS',
      buttonNext: 'COMMON_LABEL_FINISH',
      title: 'FIRSTTIME_SETUP_PERSONAL_ORGANIZATION',
      subTitle: 'FIRSTTIME_SETUP_PERSONAL_ORGANIZATION_SUB',
    },
  },
  REGISTER_STEP_KEYS.EXPLAIN
);

const organizationTypes = computed(() => _organizationStore.organizationTypes);

const isChoosingPersonal = computed<boolean>(() => {
  return props.organizationModel == EOrganizationModel.PERSONAL;
});

const infoPayload = ref<IOrganizationInfoPayload>({
  name: '',
  logo: '',
  model: props.organizationModel,
  type: '',
  size: dataByOrganizationModel[props.organizationModel]?.sizeDefault || '6-10',
  address: '',
  addressDetail: '',
  phone: '',
  websiteUrl: '',
  taxCode: '',
  subType: '',
});

const formRef = ref();

const memberSizeRangeByMode = computed<any>(() => {
  if (infoPayload.value?.model == 'TEAM') return teamSizeRange;
  if (isShowFullSize.value) return companySizeRange;
  return teamSizeRange?.slice(0, 4);
});

const updateDefaultValueByLanguage = () => {
  const currentLanguage = appLanguage.value || 'en';
  const currentModel = infoPayload.value?.model;

  if (currentModel !== 'PERSONAL') {
    if (!isEditedName.value) organizationName.value = '';
    setTimeout(() => {
      nameError.value = '';
    });
    return;
  }

  organizationName.value =
    dataByOrganizationModel[currentModel]?.defaultNameByLang[currentLanguage];

  nameError.value = '';
};

onMounted(async () => {
  ws = new TictopWebSocket();
  updateDefaultValueByLanguage();

  if (props.selectedModel?.id) {
    updateCurrentStep(REGISTER_STEP_KEYS.ENTER_MAIN_INFO);
  }
});

onUnmounted(async () => {
  ws?.close();
});

const TemplateByModeConstant = {
  name: {
    key: 'name',
    translateKey: '',
    placeholderTranslateKey: '',
  },
  address: {
    key: 'address',
    translateKey: '',
    placeholderTranslateKey: '',
  },
  phone: {
    key: 'phone',
    translateKey: '',
    placeholderTranslateKey: '',
  },
  website: {
    key: 'website',
    translateKey: '',
    placeholderTranslateKey: '',
  },
  taxCode: {
    key: 'taxCode',
    translateKey: '',
    placeholderTranslateKey: '',
  },
  size: {
    key: 'size',
    translateKey: '',
    placeholderTranslateKey: '',
  },
};

const templateByModel = computed(() => {
  let temp = [] as any[];
  switch (infoPayload.value.model) {
    case EOrganizationModel.COMPANY:
      temp = [
        TemplateByModeConstant.name,
        TemplateByModeConstant.address,
        TemplateByModeConstant.phone,
        TemplateByModeConstant.website,
        TemplateByModeConstant.taxCode,
        TemplateByModeConstant.size,
      ];
      break;
    case EOrganizationModel.TEAM:
      temp = [TemplateByModeConstant.name, TemplateByModeConstant.size];
      break;
    case EOrganizationModel.FAMILY:
      temp = [TemplateByModeConstant.name, TemplateByModeConstant.size];
      break;
    case EOrganizationModel.PERSONAL:
      temp = [];
      break;
    default:
      temp = [
        TemplateByModeConstant.name,
        TemplateByModeConstant.address,
        TemplateByModeConstant.phone,
        TemplateByModeConstant.website,
        TemplateByModeConstant.taxCode,
        TemplateByModeConstant.size,
      ];
      break;
  }

  return temp;
});

const typeError = ref('');
const avatarFile = ref();

const { value: organizationName, errorMessage: nameError } = inputValueComp(
  (value) => {
    if (!value?.trim()) return 'COMMON_THIS_FIELD_REQUIRED';
    return '';
  }
);

// Phone

const organizationPhone = ref('');
const {
  isTyping: isTypingPhone,
  isChecking: isCheckingPhone,
  validationResult: validationResultPhone,
  onValidation: onValidationPhone,
  reset: onResetValidationPhone,
} = atomFormInputComposables([
  () => {
    return !resultPhoneObject.value?.isValid && organizationPhone.value
      ? 'COMMON_INVALID_PHONE_NUMBER'
      : '';
  },
  (value) => {
    const isValid = !organizationPhone.value || isValidPhoneNumber(value);
    return isValid ? '' : 'COMMON_INVALID_PHONE_NUMBER';
  },
  (value) => {
    const isValid =
      !organizationPhone.value ||
      (value.startsWith('+') &&
        ['+33', '+84', '+1', '+81', '+82'].includes(value?.slice(0, 3)));
    return isValid ? '' : 'COMMON_INVALID_PHONE_NUMBER';
  },
]);

const isDisableNextButton = computed(() => {
  const disableStepOne =
    currentStep.value == 'ENTER_MAIN_INFO' && !organizationName.value.trim();
  const disableStepTwo =
    currentStep.value == 'CHOOSE_TYPE' && !infoPayload.value?.type;
  return disableStepOne || disableStepTwo || isTypingPhone.value;
});
const labelNameByType = computed(() => {
  if (infoPayload.value.model == EOrganizationModel.COMPANY)
    return translate('ORGANIZATION_LABEL_NAME_COMPANY');
  if (infoPayload.value.model == EOrganizationModel.TEAM)
    return translate('ORGANIZATION_LABEL_NAME_TEAM');
  if (infoPayload.value.model == EOrganizationModel.FAMILY)
    return translate('ORGANIZATION_LABEL_NAME_GROUP');
  return translate('ORG_SETUP_NAME_PERSONAL');
});

const isUpgradeCurrentOrganization = ref<boolean>(false);
const onClickNext = () => {
  onHandleGoToNextStep();
};
const onClickNextAndUpgrade = () => {
  isUpgradeCurrentOrganization.value = true;
  organizationName.value = currentOrganizationInfo.value?.name;

  onHandleGoToNextStep();
};

const isShowOtherType = ref(false);
const onChangeOrganizationType = (type, index) => {
  infoPayload.value.type = type;
  typeError.value = '';

  isShowOtherType.value = index == organizationTypes.value?.length - 1;
};

const organizationNameRef = ref<any>(null);

const isAddAvatar = ref(false);

const onUploadAvatar = async (file) => {
  avatarFile.value = file;
  isAddAvatar.value = !isAddAvatar.value;
  const oldPath = infoPayload.value?.logo;
  if (oldPath) {
    awsS3Delete({
      Bucket: bucketTictop.UserAvatar,
      Key: oldPath,
    });
  }

  const fileName = stringNomalize(file.name, true).toString();
  const extension = getFileExtension(fileName)?.toLowerCase();
  const filePath = `${
    currentOrganizationInfo.value?.id
  }/original/${uuidv4()}.${extension}`;
  const s3Payload = {
    Bucket: bucketTictop.UserAvatar,
    Key: filePath,
    Body: file,
    ContentType: file.type || file.mimeType || getFileType(file),
  };
  infoPayload.value.logo = filePath;
  await putObjectToAwsS3(s3Payload);
};

const onAddressSelect = (value) => {
  infoPayload.value.addressDetail = JSON.stringify(value);
};

const handleLoginSuccess = async (result) => {
  if (!result) return;

  getLocalStorage(StorageConstant.HAVE_AVATAR) &&
    removeLocalStorage(StorageConstant.HAVE_AVATAR);

  setLocalStorage(StorageConstant.HAS_LOGIN, 'true');
};

const goToPersonalWorkspace = async () => {
  if (!myPersonalWorkspace.value?.id) return;

  _appStore.setIsLoading(true);
  const authenSelectOrganization = new AuthenticateSelectOrganization(
    myPersonalWorkspace.value?.id
  );
  const res = await authenSelectOrganization.login(true);

  handleLoginSuccess(res);
};

const onFinishWithPersonalMode = async () => {
  emit('onSubmit', {
    mode: 1,
    data: {
      name: organizationName.value,
      logo: infoPayload.value?.logo,
      model: infoPayload.value?.model,
    },
    isUpgradeCurrentOrganization: false,
  });
};

const resultPhoneObject = ref<{
  country?: CountryCode;
  countryCallingCode: string;
  nationalNumber: string;
  number: string;
  isValid: boolean;
  formattedNumber: string;
}>({
  countryCallingCode: '',
  nationalNumber: '',
  number: '',
  isValid: true,
  formattedNumber: '',
});
const onChangePhone = (phoneObj: {
  country?: CountryCode;
  countryCallingCode: string;
  nationalNumber: string;
  number: string;
  isValid: boolean;
  formattedNumber: string;
}) => {
  onResetValidationPhone();
  resultPhoneObject.value = phoneObj;
  organizationPhone.value = phoneObj?.number || '';

  if (phoneObj?.number?.length == 12) onValidationPhone(phoneObj.number);
};

const isEditedName = ref<boolean>(false);
const onChangeOrgName = () => {
  isEditedName.value = true;
};

const onBlurPhone = () => {
  if (!organizationPhone.value || validationResultPhone.value?.isValid) return;

  onValidationPhone(organizationPhone.value);
};

const isShowFullSize = ref<boolean>(false);
</script>

<template>
  <div class="flex-1 flex flex-col space-y-6 items-center text-gray-800">
    <!-- Body -->
    <div
      class="absolute -top-10 w-20 h-20 z-60 flex-center rounded-full"
      :style="{
        background: dataByOrganizationModel[infoPayload?.model]?.background,
      }"
    >
      <SynIcon
        :name="infoPayload?.model?.toLowerCase()"
        custom-class="w-12 h-12 fill-white"
      />
    </div>
    <template v-if="currentStep == REGISTER_STEP_KEYS.EXPLAIN">
      <div
        class="flex-center flex-col space-y-4 z-60 pb-16 px-4"
        style="min-width: 20rem"
      >
        <span
          class="text-2xl py-4"
          v-html="
            $t('SELECT_ORGANIZATION_MODEL_USE_FOR', {
              model: $t(
                dataByOrganizationModel[infoPayload?.model]?.nameKey
              )?.toUpperCase(),
            })
          "
        >
        </span>
        <div class="flex items-start flex-col space-y-3">
          <div
            v-for="(item, index) in dataByOrganizationModel[infoPayload?.model]
              ?.explainList"
            :key="index"
            class="flex justify-center items-start space-x-4"
          >
            <SynIcon
              name="check"
              custom-class="h-5 w-5 fill-current text-current "
            />
            <span class="flex-1 italic text-gray-600">
              {{ $t(item) }}
            </span>
          </div>
        </div>
      </div>
      <span
        v-if="infoPayload?.model == 'PERSONAL' && isHasPersonalModel"
        class="text-center italic text-xs text-orange-500 pb-6"
        style="width: 24rem"
      >
        {{ $t('SETUP_ORGANIZATION_PERSONAL_EXISTED') }}
      </span>
    </template>
    <template v-if="currentStep == REGISTER_STEP_KEYS.ENTER_MAIN_INFO">
      <div class="flex-center flex-col space-y-4 px-8">
        <template v-if="isChoosingPersonal">
          <div class="text-center pb-8">
            {{ $t('FIRSTTIME_SETUP_PERSONAL_ORGANIZATION_ADD_AVATAR') }}
          </div>

          <UploadAvatarIcon
            is-show-button
            class="mt-6"
            :file="avatarFile"
            :name="organizationName?.trim() || infoPayload?.model"
            size="medium"
            :text-style="`font-size: 2.5rem; font-weight: 600;`"
            type-upload="GROUP"
            type="group"
            z-index="z-50"
            :is-update-success="isAddAvatar"
            @upload-avatar="onUploadAvatar"
          />
        </template>
        <template v-else>
          <div
            class="font-medium text-center"
            style="font-size: 1.75rem; line-height: 2rem"
          >
            {{ $t(stepperByKey[currentStep]['title']) }}
          </div>
          <form
            ref="formRef"
            action="#"
            class="mt-6 flex flex-col space-y-3 overflow-hidden small-scrollbar"
            style="width: 22rem"
          >
            <!--  NAME -->
            <div
              v-if="
                templateByModel.some(
                  (temp) => temp.key == TemplateByModeConstant.name.key
                )
              "
              class="w-full flex flex-col space-y-1"
            >
              <div
                v-if="infoPayload?.model !== 'PERSONAL'"
                class="flex items-center text-sm pr-4 w-44 text-gray-600"
              >
                <span>
                  {{ labelNameByType }}
                </span>
                <span class="text-red-500 pl-1">*</span>
              </div>

              <SynFormInput :error-message="nameError">
                <SynInput
                  ref="organizationNameRef"
                  v-model="organizationName"
                  custom-class="text-sm"
                  autofocus
                  :placeholder="
                    $t(currentOrganizationModelData?.placeholder) || ''
                  "
                  :error-message="nameError"
                  @update:model-value="onChangeOrgName"
                />
              </SynFormInput>
            </div>

            <!-- PERSONAL -->
            <!-- <template v-if="infoPayload?.model == 'PERSONAL'">
              <span
                v-if="isHasPersonalModel"
                class="text-center italic text-sm text-orange-500 pt-4"
              >
                {{ $t('SETUP_ORGANIZATION_PERSONAL_EXISTED') }}
              </span>
              <span
                v-else
                class="text-center italic text-sm text-gray-500 pt-4"
              >
                {{ $t('SETUP_ORGANIZATION_PERSONAL_DESCRIPTION') }}
              </span>
            </template> -->

            <!--  ADDRESS -->
            <div
              v-if="
                templateByModel.some(
                  (temp) => temp.key == TemplateByModeConstant.address.key
                )
              "
              class="my-2 flex flex-col space-y-1"
            >
              <div class="flex items-center text-sm pr-4 w-44 text-gray-600">
                <span>{{ $t('USERMANAGEMENT_TABLE_ADDRESS') }}</span>
              </div>
              <SynFormInput>
                <VigAddressBox
                  v-model="infoPayload.address"
                  :placeholder="$t('COMMON_LABEL_PLACEHOLDER_ENTER_ADDRESS')"
                  class="vig-input text-sm"
                  @on-address-select="onAddressSelect"
                />
              </SynFormInput>
            </div>

            <!--  PHONE        -->
            <div
              v-if="
                templateByModel.some(
                  (temp) => temp.key == TemplateByModeConstant.phone.key
                )
              "
              class="my-2 flex flex-col space-y-1"
            >
              <div class="flex items-center text-sm pr-4 w-44 text-gray-600">
                <span>{{ $t('USERMANAGEMENT_FORM_PHONE') }}</span>
              </div>
              <SynFormInput
                :is-checking="isCheckingPhone"
                :is-valid="
                  organizationPhone !== null &&
                  organizationPhone !== '' &&
                  validationResultPhone.isValid
                "
                :error-message="validationResultPhone.message"
              >
                <AtomPhoneInput
                  :value="organizationPhone"
                  input-class="text-sm"
                  :input-options="{
                    placeholder: $t('COMMON_LABEL_COMPANY_MOBILE_PHONE'),
                  }"
                  :auto-format="false"
                  :auto-default-country="false"
                  @change="onChangePhone"
                  @blur="onBlurPhone"
                />
              </SynFormInput>
            </div>

            <!--  SIZE        -->
            <div
              v-if="
                templateByModel.some(
                  (temp) => temp.key == TemplateByModeConstant.size.key
                )
              "
              class="my-2 flex flex-col space-y-1"
            >
              <div class="flex items-center text-sm pr-4 w-44 text-gray-600">
                <span>
                  {{
                    $t(
                      infoPayload.model == 'TEAM'
                        ? 'ORGANIZATION_LABEL_TEAM_SIZE'
                        : 'ORGANIZATION_LABEL_ORGANIZATION_SIZE'
                    )
                  }}
                </span>
              </div>

              <SynFormInput>
                <div
                  class="flex-center flex-wrap space-x-1"
                  style="width: 22rem"
                >
                  <template
                    v-for="range in memberSizeRangeByMode"
                    :key="range.key"
                  >
                    <div
                      class="
                        w-max
                        flex-center
                        rounded-full
                        px-3
                        py-1.5
                        cursor-pointer
                        text-xs
                        mb-2
                      "
                      :class="
                        infoPayload.size === range.value
                          ? 'text-white bg-current-500  '
                          : 'border border-gray-300'
                      "
                      style="min-width: 4rem"
                      @click="infoPayload.size = range.value"
                    >
                      {{ range.key }}
                    </div>
                  </template>
                  <div
                    v-if="infoPayload?.model == 'COMPANY' && !isShowFullSize"
                    class="
                      w-max
                      flex-center
                      rounded-full
                      px-3
                      py-1.5
                      cursor-pointer
                      text-xs
                      mb-2
                      border border-gray-400
                    "
                    style="min-width: 4rem"
                    @click="isShowFullSize = true"
                  >
                    {{ $t('COMMON_LABEL_OTHER') || 'More' }}
                  </div>
                </div>
              </SynFormInput>
            </div>
          </form>
        </template>
      </div>
    </template>
    <template v-if="currentStep == REGISTER_STEP_KEYS.ENTER_SUB_INFO">
      <div class="flex-center flex-col space-y-4 px-8">
        <div class="text-center pb-8">
          {{ $t('FIRSTTIME_SETUP_PERSONAL_ORGANIZATION_ADD_AVATAR') }}
        </div>

        <UploadAvatarIcon
          is-show-button
          class="mt-6"
          :file="avatarFile"
          :name="organizationName?.trim() || infoPayload?.model"
          size="medium"
          :text-style="`font-size: 2.5rem; font-weight: 600;`"
          type-upload="GROUP"
          type="group"
          z-index="z-50"
          :is-update-success="isAddAvatar"
          @upload-avatar="onUploadAvatar"
        />
        <form
          ref="formRef"
          action="#"
          class="mt-6 flex flex-col space-y-3 overflow-hidden small-scrollbar"
          style="width: 22rem"
        >
          <!--  WEBSITE        -->
          <div
            v-if="
              templateByModel.some(
                (temp) => temp.key == TemplateByModeConstant.website.key
              )
            "
            class="my-2 flex flex-col space-y-1 w-full"
          >
            <!-- <div class="flex items-center text-sm pr-4 text-gray-600">
                <span>{{ $t('USERMANAGEMENT_TABLE_WEBSITE') }}</span>
              </div> -->
            <SynInput
              v-model="infoPayload.websiteUrl"
              custom-class="text-sm"
              :placeholder="$t('COMMON_LABEL_PLACEHOLDER_ENTER_WEBSITE')"
            />
          </div>

          <!--  TAX CODE        -->
          <!-- <div
              v-if="
                templateByModel.some(
                  (temp) => temp.key == TemplateByModeConstant.taxCode.key
                )
              "
              class="my-2 flex flex-col"
            >
              <div class="flex items-center text-sm pr-4 w-44 text-gray-600">
                <span>{{ $t('USERMANAGEMENT_TABLE_TAXCODE') }}</span>
              </div>
              <SynInput
                v-model="infoPayload.taxCode"
                custom-class="text-sm"
                :placeholder="$t('COMMON_LABEL_PLACEHOLDER_ENTER_TAXCODE')"
              />
            </div> -->
        </form>
      </div>
    </template>
    <template v-if="currentStep == REGISTER_STEP_KEYS.CHOOSE_TYPE">
      <div class="flex flex-col">
        <div class="text-center pt-8 font-semibold text-xl">
          {{
            $t('ORGANIZATION_SETUP_MAIN_ACTIVITY') ||
            'What is your main activity?'
          }}
        </div>
        <div class="flex-center w-full flex-wrap p-4">
          <div
            v-for="(item, index) in organizationTypes"
            :key="index"
            class="w-max p-2"
          >
            <SynCheckboxLabel
              class="text-base py-1.5"
              :is-check="infoPayload.type === item.code"
              :text="item.name"
              @click="onChangeOrganizationType(item.code, index)"
            />
          </div>
        </div>
        <div class="flex-center">
          <SynInput
            v-if="isShowOtherType"
            v-model="infoPayload.subType"
            class="w-96"
            :max-length="100"
            :placeholder="$t('SETUP_ORGANIZATION_TELL_US_YOUR_BUSINESS')"
          />
        </div>
        <div v-if="typeError" class="text-red-400">* {{ typeError }}</div>
      </div>
    </template>
  </div>

  <div
    class="
      w-full
      flex
      justify-end
      items-center
      hover:text-current
      cursor-pointer
      space-x-2
    "
  >
    <SynButton
      v-if="stepperByKey[currentStep]['buttonBack']"
      :id="currentOrganizationModelData?.backButtonIdByCurrentStep[currentStep]"
      :label="$t(stepperByKey[currentStep]['buttonBack'])"
      type-text
      color="gray"
      @click="onHandleGoToPreviousStep"
    />

    <template v-if="organizationModel == 'PERSONAL'">
      <SynButton
        v-if="isPersonalModel && !doNotNeedToCheckPersonal"
        :label="$t('COMMON_LABEL_GOT_IT')"
        @click="$emit('onCancel')"
      />
      <SynButton
        v-else-if="isHasPersonalModel"
        :is-loading="isSubmitting"
        :label="
          $t('COMMON_LABEL_GO_TO', {
            destination: $t('COMMON_LABEL_MY_WORKSPACE'),
          })
        "
        @click="goToPersonalWorkspace"
      />
      <SynButton
        v-else-if="currentStep == 'EXPLAIN'"
        :id="
          currentOrganizationModelData?.nextButtonIdByCurrentStep[currentStep]
        "
        :disabled="isDisableNextButton"
        :is-loading="isSubmitting"
        :label="$t(stepperByKey[currentStep]['buttonNext'])"
        @click="onClickNext"
      />
      <SynButton
        v-else
        :id="
          GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
            .setting_org_select_personal_3rd_complete
        "
        :disabled="isDisableNextButton || isHasPersonalModel"
        :is-loading="isSubmitting"
        :label="$t('TASK_DETAIL_FINISH')"
        @click="onFinishWithPersonalMode"
      />
    </template>
    <template v-else>
      <SynButton
        :id="
          currentOrganizationModelData?.nextButtonIdByCurrentStep[currentStep]
        "
        :disabled="isDisableNextButton"
        :is-loading="isSubmitting"
        :label="$t(stepperByKey[currentStep]['buttonNext'])"
        @click="onClickNext"
      />
      <SynButton
        v-if="
          !isHomePage &&
          hasUpdateCurrentOrganization &&
          currentStep == REGISTER_STEP_KEYS.EXPLAIN
        "
        :disabled="isDisableNextButton"
        :is-loading="isSubmitting"
        :label="$t('COMMON_LABEL_UPGRADE_CURRENT_ORGANIZATION')"
        @click="onClickNextAndUpgrade"
      />
    </template>
  </div>
</template>
