<template>
  <div class="h-screen w-full flex-col flex-center bg-current-50">
    <syn-logo class="p-4" />
    <div class="h-full flex-center flex-col space-y-2">
      <span
        class="text-5xl font-bold tracking-wider text-white"
        style="text-shadow: 0px 0px 5px #1a9793"
      >
        {{ $t('LOGIN_FAILED_ACCOUNT_DEACTIVATED_TITLE') }}
      </span>
      <span class="font-thin text-2xl text-center text-current-700">
        {{
          $t('LOGIN_FAILED_ACCOUNT_DEACTIVATED_SUBTITLE', {
            organizationName: loginId,
          })
        }}
      </span>
      <div class="mt-8">
        <syn-button
          :label="$t('COMMON_LABEL_LOGIN_AGAIN') || 'Login again'"
          @click="loginAgain"
        >
        </syn-button>
      </div>
      <syn-animation name="inactiveOrg" />
      <section class="absolute bottom-0 text-center py-6">
        <div class="flex items-center space-x-8">
          <div class="flex items-center space-x-2">
            <SynIcon name="globals" class="fill-current" />
            <span>
              <a
                class="text-current-700 hover:text-current-900"
                href="https://www.fiine.vn/"
                target="_blank"
                >https://www.fiine.vn</a
              >
            </span>
          </div>
          <div class="flex items-center space-x-2">
            <SynIcon name="email" class="fill-current" />
            <span>
              <a
                class="text-current-700 hover:text-current-900"
                href="mailto:services@fiine.pro"
                target="_blank"
                >services@fiine.pro</a
              >
            </span>
          </div>
          <div class="flex items-center space-x-2">
            <SynIcon name="support" class="fill-current" />
            <span>
              <a
                class="text-current-700 hover:text-current-900"
                href="mailto:support@fiine.pro"
                target="_blank"
                >support@fiine.pro</a
              >
            </span>
          </div>
          <div class="flex items-center space-x-2">
            <SynIcon name="phone" class="fill-current" />
            <span>
              <a class="text-current-700 hover:text-current-900" target="_blank"
                >(+84) 919 326 983</a
              >
            </span>
          </div>
        </div>
        <div class="text-gray-600 text-xs pt-4">
          Copyright © 2024 Tictop | Powered by Tictop
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { clearLocalStorage } from '@/ui/hooks/storageHook';
import authStore from '@/store/auth';
import { onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
export default {
  setup() {
    const _authStore = authStore();
    const router = useRouter();
    const route = useRoute();
    const loginId = ref('');
    const loginAgain = () => {
      clearLocalStorage({ isSwitchingOrg: false });
      router.push('/login');
    };
    onMounted(async () => {
      const id = route.query?.id || '';
      const res = await _authStore.getUserOrganizationName(id);
      loginId.value = res.organizationName;
      router.replace({ name: 'AccountDeactivated' });
      _authStore.phone;
    });
    return { loginId, loginAgain };
  },
};
</script>
