import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import DomainService from '@/application/services/DomainService';
import { arrayOrderBy, cloneDeep } from '@/ui/hooks/commonFunction';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import DomainClass from '@/domain/entities/PresentClass/DomainClass';
import ProjectClass from '@/domain/entities/PresentClass/ProjectClass';
import {
    DomainAddMemberPayload,
    DomainAddOrUpdatePayload,
    DomainAddPayload,
    ProjectAddMemberPayload,
    ProjectAddOrUpdatePayload,
    ProjectAddPayload,
    UpdateDomainInformationPayload,
    UpdateMembersPayload,
    UpdateProjectInformationPayload,
    UpdateUserDomainRelativePayload,
} from '@/application/types/domain/DomainPayload';
import { DomainStatus } from '@/domain/enums/domain-enums';
import TaskService from '@/application/services/task/TaskService';
import { FilterByDomain } from '@/domain/entities/task/FilterTask';
import {
    RejectDomainPayload,
    RejectProjectPayload,
} from '@/domain/entities/projects/ProjectPayload';
import remoteConfigStore from '@/store/remoteConfig';
import myProfileStore from '@/store/auth/my-profile';
import { getGroupDomainById } from '@/ui/modules/task/helper/task-domain-helper';
import DomainDetailClass, {
    IDomainDetail,
} from '@/domain/entities/domains/DomainDetailClass';
import ProjectDetailClass from '@/domain/entities/domains/ProjectDetailClass';

export default defineStore({
    id: storeId.domain,
    state: () => ({
        _domainService: DomainService.getInstance(),
        domains: [] as DomainDetailClass[],
        projects: [] as ProjectDetailClass[],
        domainByIds: {} as { number: IDomainDetail },
        projectByIds: {} as any,
        isLoadingDomain: false,
        isCreateableDomain: false,
    }),
    getters: {
        allDomains(state): any[] {
            return state.domains;
        },
        getLoading(status): boolean {
            return status.isLoadingDomain;
        },
        notVerifiedDomains(state): any {
            const notVerifiedDomains = state.domains.filter(
                (domain: any) => domain.status === DomainStatus.Created
            );
            return notVerifiedDomains.reduce((oldValue, currentValue: any) => {
                return {
                    ...oldValue,
                    [currentValue.id]: currentValue,
                };
            }, {});
        },
    },
    actions: {
        updateStoreDataByDomainId(domainId: number, data: any) {
            this.domainByIds[domainId] = {
                ...this.domainByIds[domainId],
                ...data,
            };

            const index = this.domains?.findIndex((d) => d?.id == domainId);
            if (index == -1) {
                this.domains.push(data);
            } else {
                this.domains[index] = {
                    ...this.domains[index],
                    ...data,
                };
            }
        },
        updateStoreDataByProjectId(projectId: number, data: any) {
            this.projectByIds[projectId] = {
                ...this.projectByIds[projectId],
                ...data,
            };

            const index = this.projects?.findIndex((d) => d?.id == projectId);
            if (index == -1) {
                this.projects.push(data);
            } else {
                this.projects[index] = {
                    ...this.projects[index],
                    ...data,
                };
            }
        },
        async removeDomain(domainId): Promise<void> {
            try {
                await this._domainService.deleteDomain(domainId);
                this.domains = this.domains.filter(
                    (domain: any) => domain.id !== domainId
                );
            } catch (error) {
                console.log('🚀 ~ removeDomain ~ error', error);
            }
        },
        quicklyCreateDomain(data) {
            return this._domainService.quicklyCreateDomain(
                new DomainAddOrUpdatePayload(data)
            );
        },
        quicklyCreateProject(data) {
            return this._domainService.quicklyCreateProject(
                new ProjectAddOrUpdatePayload(data)
            );
        },
        async createDomain(dataItem: DomainAddPayload) {
            try {
                const res = await this._domainService.createDomain(dataItem);
                const newDomain = new DomainDetailClass({
                    ...res?.result,
                    members: dataItem.members,
                });

                if (!newDomain.id) return {};
                this.updateStoreDataByDomainId(newDomain.id, newDomain);
                this.fetchAllDomainProjects();

                return newDomain;
            } catch (error) {
                console.log('🚀 ~ createDomain ~ error', error);
            }
        },
        async createProject(dataItem: any): Promise<any> {
            try {
                const addedList = dataItem?.members.filter((mem) => {
                    return !mem.updated;
                });
                const updatedList = dataItem?.members.filter((mem) => {
                    return mem.updated;
                });

                const newPayload = new ProjectAddPayload({
                    ...dataItem,
                    members: {
                        addMembers: addedList,
                        updateMembers: updatedList,
                    },
                });

                const res = await this._domainService.createProject(newPayload);

                const newProject = new ProjectDetailClass({
                    ...res?.result,
                    members: dataItem?.members?.map((user) => user?.id),
                });
                if (!newProject.id) return {};

                this.updateStoreDataByProjectId(newProject.id, newProject);
                this.fetchAllDomainProjects();
                return newProject;
            } catch (error) {
                console.log('🚀 ~ createDomain ~ error', error);
                return {};
            }
        },

        async updateInformation(data: any): Promise<any> {
            try {
                if (!data?.id) return;
                const updatePayload = new UpdateDomainInformationPayload(data);

                await this._domainService.updateInformation(updatePayload);

                this.domainByIds[data.id] = {
                    ...this.domainByIds[data.id],
                    name: updatePayload.name,
                    description: updatePayload.description,
                };
            } catch (error) {
                console.log('🚀 ~ updateDomain ~ error', error);
            }
        },

        async updateProjectInformation(
            data: UpdateProjectInformationPayload
        ): Promise<any> {
            try {
                if (!data?.id) return;

                await this._domainService.updateProjectInformation(data);

                this.projectByIds[data.id] = {
                    ...this.projectByIds[data.id],
                    name: data.name,
                    description: data.description,
                };
            } catch (error) {
                console.log('🚀 ~ updateDomain ~ error', error);
            }
        },
        async updateProjectMembers(
            originDetail,
            newMemberList: any[]
        ): Promise<any> {
            try {
                if (!originDetail?.domainId || !originDetail?.id) return;

                const addedList = newMemberList
                    ? newMemberList.filter((mem) => {
                          return !originDetail.members?.some(
                              (memId) => memId == mem.id
                          );
                      })
                    : [];
                const updatedList = newMemberList
                    ? newMemberList.filter((mem) => {
                          return mem.updated;
                      })
                    : [];
                const deletedList = [
                    ...(originDetail.members
                        ? originDetail.members
                              ?.filter((memId) => {
                                  return !newMemberList?.some(
                                      (newMem) => newMem.id == memId
                                  );
                              })
                              .map((id) => {
                                  return { id };
                              })
                        : []),
                    ...newMemberList.filter((mem) => {
                        return mem.deleted;
                    }),
                ];

                await this._domainService.updateProjectMembers(
                    originDetail?.domainId,
                    originDetail?.id,
                    {
                        addMembers: addedList,
                        updateMembers: updatedList,
                        deleteMembers: deletedList,
                    }
                );

                this.projectByIds[originDetail?.id] = {
                    ...this.projectByIds[originDetail?.id],
                    members: newMemberList?.map((user) => user?.id),
                };

                await this.fetchAllDomainProjects();
            } catch (error) {
                console.log('🚀 ~ updateDomain ~ error', error);
            }
        },
        async fetchAllDomains(): Promise<any> {
            this.domains = await this._domainService.fetchAllDomains();
            this.domainByIds = this.domains.reduce(
                (previousValue: any, currentValue: any) => {
                    return {
                        ...previousValue,
                        [currentValue.id]: {
                            ...currentValue,
                            members: arrayOrderBy(
                                currentValue.members,
                                ['anyProjects'],
                                ['desc']
                            ),
                        },
                    };
                },
                {}
            );
        },
        async fetchAllProjects(): Promise<void> {
            this.projects = await this._domainService.fetchAllProjects();
            this.projectByIds = this.projects?.reduce(
                (
                    previousValue: ProjectDetailClass,
                    currentValue: ProjectDetailClass
                ) => {
                    return {
                        ...previousValue,
                        [currentValue.id]: currentValue,
                    };
                },
                new ProjectDetailClass({})
            );
        },
        async fetchAllDomainProjects(): Promise<any> {
            return Promise.all([
                this.fetchAllDomains(),
                this.fetchAllProjects(),
            ]);
        },
        getDomainDetailById(domainId): DomainClass {
            if (
                !Object.prototype.hasOwnProperty.call(
                    this.domainByIds,
                    domainId
                )
            )
                return new DomainClass({});
            return new DomainClass(cloneDeep(this.domainByIds[domainId]));
        },
        getProjectDetailById(projectId) {
            if (
                !Object.prototype.hasOwnProperty.call(
                    this.projectByIds,
                    projectId
                )
            )
                return new ProjectClass({});
            return new ProjectClass(cloneDeep(this.projectByIds[projectId]));
        },
        async addMembers(data, needFetchAll = true): Promise<any> {
            const res = await this._domainService.addMembers(
                new DomainAddMemberPayload({
                    domainId: data.domainId,
                    userIds: data.userIds?.toString(),
                    groupIds: data.groupIds?.toString(),
                })
            );
            if (needFetchAll) this.fetchAllDomainProjects();
            return res?.result;
        },
        async addProjectMembers(data, needFetchAll = true): Promise<any> {
            const res = await this._domainService.addProjectMembers(
                new ProjectAddMemberPayload({
                    domainId: data.domainId,
                    projectId: data.projectId,
                    subProjectId: data.subProjectId,
                    userIds: data.userIds?.toString(),
                })
            );
            if (needFetchAll) this.fetchAllDomainProjects();
            return res?.result;
        },
        async updateMembers(
            originDetail: DomainDetailClass,
            newMemberList: UpdateMembersPayload[]
        ): Promise<any> {
            try {
                if (!originDetail.id)
                    return Promise.reject('Domain Id is not valid');

                const addedList = newMemberList.filter((mem) => {
                    return !originDetail.members.some(
                        (originMem) => originMem.id == mem.id
                    );
                });
                const updatedList = newMemberList.filter((mem) => {
                    return (
                        mem.updated &&
                        !addedList?.some((add) => add?.id == mem.id)
                    );
                });
                const deletedList = originDetail.members?.filter(
                    (originMem) => {
                        return !newMemberList.some(
                            (newMem) => newMem.id == originMem.id
                        );
                    }
                );
                const res = await this._domainService.updateMembers(
                    originDetail.id,
                    {
                        addMembers: addedList,
                        updateMembers: updatedList,
                        deleteMembers: deletedList,
                    }
                );

                this.updateStoreDataByDomainId(originDetail.id, {
                    members: newMemberList.map((m) => {
                        return {
                            id: m.id,
                            anyProjects: m.anyProjects,
                        };
                    }),
                });
                this.fetchAllDomainProjects();
                return res;
            } catch (error) {
                console.log('🚀 ~ addDomainMember ~ error', error);
            }
        },
        async updateGroups(
            domainId: number,
            newGroupList: number[]
        ): Promise<any> {
            try {
                if (!domainId) return Promise.reject('Domain Id is not valid');

                const res = await this._domainService.updateGroups(
                    domainId,
                    newGroupList?.map((id) => {
                        return {
                            id,
                        };
                    })
                );

                this.updateStoreDataByDomainId(domainId, {
                    groups: newGroupList,
                });
                this.fetchAllDomainProjects();

                return res;
            } catch (error) {
                console.log('🚀 ~ addDomainMember ~ error', error);
            }
        },
        async updateUserDomainRelative(
            data: UpdateUserDomainRelativePayload
        ): Promise<any> {
            try {
                if (!data.domainId)
                    return Promise.reject('Domain Id is not valid');

                const res = await this._domainService.updateUserDomainRelative(
                    data
                );
                console.log('🚀 ~ file: index.ts ~ line 247 ~ res', res);

                return res;
            } catch (error) {
                console.log('🚀 ~ addDomainMember ~ error', error);
            }
        },

        async removeMember(data): Promise<any> {
            const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);
            const res = await this._domainService.removeMember({
                organizationId: organizationId,
                userIds: data.userIds,
                domainId: data.domainId,
                projectId: data.projectId || null,
                subProjectId: data.subProjectId || null,
            });
            return res;
        },
        getTasksByDomain(data): Promise<any> {
            return TaskService.getInstance().getTasksByDomain(
                new FilterByDomain(data)
            );
        },
        async rejectDomain(data: {
            domainId: number;
            relatedTasks: any[];
        }): Promise<any> {
            try {
                const payload = new RejectDomainPayload(data);
                await this._domainService.rejectDomain(payload);

                this.fetchAllDomainProjects();

                return true;
            } catch (error) {
                console.log('🚀 ~ rejectProject ~ error', error);
            }
        },
        async rejectProject(data: {
            projectId: number;
            relatedTasks: any[];
        }): Promise<any> {
            try {
                const payload = new RejectProjectPayload(data);
                await this._domainService.rejectProject(payload);

                this.fetchAllDomainProjects();

                return true;
            } catch (error) {
                console.log('🚀 ~ rejectProject ~ error', error);
            }
        },
        checkQuicklyCreateDomainPermission() {
            const webDomainCreateRealtimePermission =
                remoteConfigStore().webDomainCreateRealtimePermission;
            const myProfile = myProfileStore().myProfile;

            this.isCreateableDomain =
                webDomainCreateRealtimePermission?.includes(
                    myProfile.adminType?.toString()
                );
        },

        async getUserDomainById(userId) {
            const res: any =
                await DomainService.getInstance().getDomainScopeListByUserId(
                    userId
                );
            const newData = res?.result || [];

            return newData;
        },

        async getDomainByOwnerId(userId, groupId) {
            const domainOfUser = userId
                ? await this.getUserDomainById(userId)
                : [];

            return groupId
                ? getGroupDomainById(groupId, domainOfUser)
                : domainOfUser;
        },
        async setVisibleDomain(domainId) {
            await DomainService.getInstance().setVisibleDomain(domainId);
            this.fetchAllDomains();
        },
        async updateDomainSupplement(data) {
            await DomainService.getInstance().updateDomainSupplement(data);
        },
    },
});
