<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { severity, typeDeployments } from '@/ui/common/constants/system';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
import systemStore from '@/store/system';
import { formatDate } from '@/ui/hooks/calendarHook';

const _systemStore = systemStore();

const languge = getLocalStorage(StorageConstant.LANGUAGE);
const infoDeployment = computed(() => _systemStore.deploymentInfo);
const colorDot = computed(() => {
  if (infoDeployment.value?.severity == severity.NORMAL.index)
    return 'bg-yellow-300 text-yellow-800';
  if (infoDeployment.value?.severity == severity.HIGH.index)
    return 'bg-orange-500 text-orange-100';
  return 'bg-red-600 text-red-50';
});

onMounted(() => {
  if (!infoDeployment.value || infoDeployment.value?.state === 0) {
    history.replaceState({}, '', '/');
    window.location.reload();
  }
});

const listDescription = computed(() => {
  if (!infoDeployment.value?.description) return [];
  if (
    infoDeployment.value?.description[languge?.toLowerCase()] &&
    infoDeployment.value?.description[languge?.toLowerCase()]?.length > 0
  )
    return infoDeployment.value?.description[languge?.toLowerCase()];
  else if (
    infoDeployment.value?.description['en'] &&
    infoDeployment.value?.description['en']?.length > 0
  ) {
    return infoDeployment.value?.description['en'];
  } else if (
    infoDeployment.value?.description['vi'] &&
    infoDeployment.value?.description['vi']?.length > 0
  ) {
    return infoDeployment.value?.description['vi'];
  }
  return infoDeployment.value?.description['fr'] || [];
});
</script>
<template>
  <div
    class="
      h-screen
      w-full
      flex-col flex
      items-center
      justify-around
      bg-current-50 bg-opacity-50
    "
  >
    <div class="flex-1 flex flex-row">
      <syn-animation
        class="w-1/2"
        :name="
          infoDeployment?.type == typeDeployments.UPGRADE
            ? 'inactiveOrg'
            : 'webMaintenace'
        "
        stype="width: 600px; height: 600px"
      />

      <div
        class="flex items-center h-full flex-col space-y-2"
        style="align-items: flex-start; justify-content: center"
      >
        <span
          class="text-4xl font-bold text-white text-left"
          style="text-shadow: 0px 0px 5px #1a9793"
        >
          {{
            infoDeployment?.type == typeDeployments.UPGRADE
              ? $t('SYNTEM_DEPLOYMENT_UDGRADE_PAGE_TITLE')
              : $t('SYNTEM_DEPLOYMENT_MAINTENANCE_PAGE_TITLE')
          }}
        </span>

        <span class="font-thin text-xl text-left text-current-700 pt-4">
          <span>{{ $t('SYSTEM_DEPLOYMENT_SUB_TITLE_IMPROVING') }}</span> <br />
          <span
            v-html="
              `${
                infoDeployment?.type == typeDeployments.UPGRADE
                  ? $t('SYNTEM_DEPLOYMENT_UDGRADE_PAGE_SUB_TITLE', {
                      date: formatDate(infoDeployment?.endTime)
                        .formatddddMMMMDYYYY,
                      time: formatDate(infoDeployment?.endTime).time,
                    })
                  : $t('SYNTEM_DEPLOYMENT_MAINTENANCE_PAGE_SUB_TITLE', {
                      date: formatDate(infoDeployment?.endTime)
                        .formatddddMMMMDYYYY,
                      time: formatDate(infoDeployment?.endTime).time,
                    })
              }`
            "
          ></span>
        </span>

        <div
          v-if="listDescription?.length > 0"
          class="pt-6"
          style="max-width: 40rem"
        >
          <div class="font-medium text-current-500 flex items-center space-x-2">
            <span :class="colorDot" class="w-2 h-2 rounded-full p-1"></span>
            <span>{{ $t('SYSTEM_DEPLOYMENT_VIEW_INFO_TITLE') }}</span>
          </div>
          <ul class="list-disc p-2 pl-8 text-current-800">
            <template v-for="item in listDescription" :key="item">
              <li v-if="item" v-html="item"></li>
            </template>
          </ul>
        </div>
      </div>
    </div>

    <section class="text-center py-6 text-sm">
      <syn-logo
        class="p-4"
        class-logo="h-7"
        class-label="w-14"
        class-sub-text="text-xs"
      />
      <div class="flex items-center space-x-8">
        <div class="flex items-center space-x-2">
          <SynIcon name="globals" class="fill-current" />
          <span>
            <a
              class="text-current-700 hover:text-current-900"
              href="https://www.fiine.vn/"
              target="_blank"
              >https://www.fiine.vn</a
            >
          </span>
        </div>
        <div class="flex items-center space-x-2">
          <SynIcon name="email" class="fill-current" />
          <span>
            <a
              class="text-current-700 hover:text-current-900"
              href="mailto:services@fiine.pro"
              target="_blank"
              >services@fiine.pro</a
            >
          </span>
        </div>
        <div class="flex items-center space-x-2">
          <SynIcon name="support" class="fill-current" />
          <span>
            <a
              class="text-current-700 hover:text-current-900"
              href="mailto:support@fiine.pro"
              target="_blank"
              >support@fiine.pro</a
            >
          </span>
        </div>
        <div class="flex items-center space-x-2">
          <SynIcon name="phone" class="fill-current" />
          <span>
            <a class="text-current-700 hover:text-current-900" target="_blank"
              >(+84) 919 326 983</a
            >
          </span>
        </div>
      </div>
      <div class="text-gray-600 text-xs pt-4">
        Copyright © 2024 Tictop | Powered by Tictop
      </div>
    </section>
  </div>
</template>
