<script setup lang="ts">
import { ref, watch, onMounted, nextTick, onUnmounted } from 'vue';
import { useField } from 'vee-validate';
import authStore from '@/store/auth';
import { translate } from '@/ui/plugins/i18n/myi18n';
import userStore from '@/store/user';
import { clearLocalStorage } from '@/ui/hooks/storageHook';
import { isValidEmail, isValidPhoneNumber } from '@/ui/hooks/commonFunction';
import GetCode from '../../auth/GetCode.vue';
import { ErrorNotificationLogin } from '@/ui/common/constants/constant';
import {
  initGoogleCaptcha,
  destroyGoogleCaptcha,
} from '@/ui/components/layouts/logic/init-google-capcha';
import { CountryCode } from 'libphonenumber-js';

const props = defineProps({
  type: {
    type: String,
    default: 'EMAIL',
  },
  login: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['cancel', 'complete']);
const _authStore = authStore();
const _userStore = userStore();
const loginValue = ref(props.login);
const isBlockLoginByCode = ref(false);
const isBlockSendPhoneCode = ref(false);
const isShowModalError = ref(false);
const isLoadingRefresh = ref(false);
const keyStatus = ref('INFO');
const status = ref({
  INFO: {
    key: 'INFO',
    label: 'COMMON_LABEL_CHANGE',
    next: 'GET_CODE',
    previous: 'CANCEL',
    isActive: true,
    previousText: 'COMMON_LABEL_CANCEL',
    confirmText: 'COMMON_LABEL_CHANGE',
  },
  GET_CODE: {
    key: 'GET_CODE',
    label: 'COMMON_LABEL_CHANGE',
    next: 'FINISH',
    previous: 'INFO',
    isActive: false,
    previousText: 'COMMON_LABEL_PREVIOUS',
    confirmText: 'COMMON_LABEL_CONFIRM',
  },
  FINISH: {
    key: 'FINISH',
    label: 'COMMON_LABEL_CHANGE',
    next: 'COMPLETE',
    previous: '',
    isActive: false,
    isResult: false,
    previousText: '',
    confirmText: 'COMMON_LABEL_DONE',
  },
});
const isResendCode = ref(false);
const errorEmailMess = ref('');
const errorPhoneMess = ref('');
const isLoadFinishGroup = ref(false);
const sessionInfo = ref('');
const errorCode = ref('');
const currentLanguage = ref('');
const capchaTokenText = ref('');
const isReady = ref(false);
const codeOTP = ref('');

const { value: email, errorMessage: emailError } = useField(
  'data.email',
  async function (value) {
    if (props.type == 'EMAIL' && value && value == loginValue.value.trim()) {
      isReady.value = false;
      return translate('COMMON_LABEL_ALERT_SAME_EMAIL');
    }
    if (!value || (value && !isValidEmail(value))) {
      isReady.value = false;
    }
    if (value && isValidEmail(value)) {
      const resCheckExist = await _authStore.checkEmail(value);
      if (resCheckExist?.existed) {
        isReady.value = false;
        return translate('COMMON_LABEL_REQUIRE_EMAIL_EXISTED');
      } else {
        isReady.value = true;
      }
    }
    return true;
  }
) as any;
const { value: phone, errorMessage: phoneError } = useField(
  'data.phone',
  async function (value) {
    if (!value || (value && !isValidPhoneNumber(value))) {
      isReady.value = false;
    }
    if (props.type == 'PHONE' && value && value == loginValue.value.trim()) {
      isReady.value = false;
      return translate('COMMON_LABEL_ALERT_SAME_PHONE');
    }
    if (isValidPhoneNumber(value) && value && value[0] === '+') {
      const resCheckExistPhone = await _authStore.checkPhone(value);
      if (resCheckExistPhone.existed) {
        isReady.value = false;
        return translate('COMMON_LABEL_REQUIRE_PHONE_EXISTED');
      } else {
        isReady.value = true;
      }
    }
    return true;
  }
) as any;

watch(
  () => props.login,
  () => {
    loginValue.value = props.login;
  }
);

const onChangePhone = (phoneObj: {
  country?: CountryCode;
  countryCallingCode: string;
  nationalNumber: string;
  number: string;
  isValid: boolean;
  formattedNumber: string;
}) => {
  if (!phoneObj || !phoneObj.number) {
    phone.value = '';
    return;
  }

  phone.value = phoneObj.number;

  const local = phoneObj.countryCallingCode?.toLowerCase();
  currentLanguage.value = local == 'vn' ? 'vi' : local;
};

onMounted(async () => {
  await nextTick();
});

onUnmounted(() => {
  destroyGoogleCaptcha();
});

const resetCaptcha = async (type) => {
  capchaTokenText.value = await initGoogleCaptcha(
    'g-recaptcha-change-login',
    'captcha-change-login'
  );
  if (!capchaTokenText.value) return;

  if (type == 'send') {
    onSendCode();
  } else {
    resendCode();
  }
};

const onRefreshPage = async () => {
  isLoadingRefresh.value = true;
  clearLocalStorage({ isSwitchingOrg: false });
  await window.location.reload();
  isLoadingRefresh.value = false;
};

const onCancel = () => {
  email.value = '';
  phone.value = '';
  keyStatus.value = 'INFO';
  status.value.INFO.isActive = true;
  status.value.GET_CODE.isActive = false;
  status.value.FINISH.isActive = false;
  emit('cancel', false);
};

const changePrevious = (stt, statusCurrent) => {
  if (stt == 'CANCEL') {
    onCancel();
  }
  keyStatus.value = stt;
  status.value[statusCurrent].isActive = false;
};

const changeNext = async (stt) => {
  isLoadFinishGroup.value = true;
  if (stt == 'GET_CODE') {
    if (props.type == 'EMAIL') {
      const res = await _authStore.checkEmail(email.value);
      if (res.existed) {
        errorEmailMess.value = translate('COMMON_LABEL_REQUIRE_EMAIL_EXISTED');
        isLoadFinishGroup.value = false;
        return;
      } else {
        await onSendCode();
        isLoadFinishGroup.value = false;
      }
    } else {
      const res = await _authStore.checkPhone(phone.value.trim());
      if (res.existed) {
        errorPhoneMess.value = translate('COMMON_LABEL_REQUIRE_PHONE_EXISTED');
        isLoadFinishGroup.value = false;
        return;
      } else {
        capchaTokenText.value = await initGoogleCaptcha(
          'g-recaptcha-change-login',
          'captcha-change-login'
        );

        await onSendCode();
        isLoadFinishGroup.value = false;
      }
    }
  } else if (stt == 'FINISH') {
    await changeUserLogin(stt);
    isLoadFinishGroup.value = false;
    if (!status.value[stt].isResult) {
      return;
    }
  } else {
    emit('complete', true);
    onCancel();
  }
  keyStatus.value = stt;
  status.value[stt].isActive = true;
};

const onSendCode = async () => {
  if (isBlockLoginByCode.value || isBlockSendPhoneCode.value) return;
  isLoadFinishGroup.value = true;
  errorCode.value = '';

  const res = ref();
  try {
    if (props.type == 'EMAIL') {
      res.value = await _authStore.sendVerificationCodeByEmail({
        email: email.value,
        locale: currentLanguage.value || 'vi',
      });
      isLoadFinishGroup.value = false;
    } else {
      res.value = await _authStore.sendVerificationCodeByPhoneNumber({
        phoneNumber: phone.value,
        captchaToken: capchaTokenText.value,
        locale: currentLanguage.value || 'vi',
      });
      sessionInfo.value = res.value?.result.sessionInfo;
      isLoadFinishGroup.value = false;
    }
  } catch (response: any) {
    if (response.error.message == ErrorNotificationLogin.CAPTCHA_CHECK_FAILED) {
      resetCaptcha('send');
    } else if (
      response.error.message == ErrorNotificationLogin.SEND_VERIFICATION_FAILED
    ) {
      parserErrorMessage(response);
      isShowModalError.value = true;
      isLoadFinishGroup.value = false;
    } else {
      parserErrorMessage(response);
      isLoadFinishGroup.value = false;
    }
  }
};

const getCodeVerify = (code) => {
  codeOTP.value = code;
};

const resendCode = async () => {
  if (isBlockLoginByCode.value || isBlockSendPhoneCode.value) return;
  isResendCode.value = true;
  errorCode.value = '';
  const res = ref();
  try {
    if (props.type == 'EMAIL') {
      res.value = await _authStore.reSendVerificationCodeByEmail({
        email: email.value,
        locale: currentLanguage.value || 'vi',
      });
      isResendCode.value = false;
    } else {
      res.value = await _authStore.reSendVerificationCodeByPhoneNumber({
        phoneNumber: phone.value,
        captchaToken: capchaTokenText.value,
        locale: currentLanguage.value || 'vi',
      });
      sessionInfo.value = res.value?.result.sessionInfo;
      isResendCode.value = false;
    }
  } catch (response: any) {
    if (response.error.message == ErrorNotificationLogin.CAPTCHA_CHECK_FAILED) {
      resetCaptcha('resend');
    } else if (
      response.error.message == ErrorNotificationLogin.SEND_VERIFICATION_FAILED
    ) {
      parserErrorMessage(response);
      isShowModalError.value = true;
      isResendCode.value = false;
    } else {
      isResendCode.value = false;
      parserErrorMessage(response);
    }
  }
};

const changeUserLogin = async (stt) => {
  isLoadFinishGroup.value = true;
  try {
    if (props.type == 'EMAIL') {
      const res = await _userStore.changeUserEmail({
        verificationCode: codeOTP.value,
        email: email.value,
      });
      if (res?.result) {
        status.value[stt].isResult = true;
      } else {
        status.value[stt].isResult = false;
      }
      isLoadFinishGroup.value = false;
    } else {
      const res = await _userStore.changeUserPhone({
        phoneNumber: phone.value.trim(),
        code: codeOTP.value,
        sessionInfo: sessionInfo.value,
      });
      if (res?.result) {
        status.value[stt].isResult = true;
      } else {
        status.value[stt].isResult = false;
      }
      isLoadFinishGroup.value = false;
    }
  } catch (error) {
    isLoadFinishGroup.value = false;
    parserErrorMessage(error);
  }
};

const parserErrorMessage = (response) => {
  const responseMsg = response.error.message;
  isBlockLoginByCode.value =
    responseMsg == ErrorNotificationLogin.LOGIN_BY_CODE_BLOCKED;
  isBlockSendPhoneCode.value =
    responseMsg == ErrorNotificationLogin.SEND_PHONE_CODE_BLOCKED;
  errorCode.value = translate(ErrorNotificationLogin[responseMsg]);
};
</script>
<template>
  <syn-modal
    container-class="w-1/3 h-max"
    disable-click-outside
    @cancel="onCancel()"
  >
    <template #header>
      <span>{{
        type == 'EMAIL' && !loginValue
          ? $t('COMMON_LABEL_ADD_EMAIL')
          : $t('COMMON_LABEL_CHANGE')
      }}</span>
    </template>
    <template #body>
      <!-- INFO -->
      <section v-if="keyStatus == 'INFO'">
        <div class="flex item-center space-x-3 font-medium text-current-600">
          <SynIcon
            :name="type == 'EMAIL' ? 'email' : 'phone'"
            custom-class="w-5 h-5 fill-current"
          />
          <label>
            {{
              type == 'EMAIL'
                ? $t('VERIFY_CHANGE_EMAIL_LABEL')
                : $t('VERIFY_CHANGE_PHONE_LABEL')
            }}
          </label>
        </div>
        <label class="text-sm font-thin">
          {{
            type == 'EMAIL'
              ? $t('VERIFY_CHANGE_EMAIL_SUB')
              : $t('VERIFY_CHANGE_PHONE_SUB')
          }}
        </label>

        <div v-if="type == 'EMAIL'" class="mt-6">
          <div v-if="loginValue" class="my-2 flex-center space-x-2">
            <div class="flex items-center text-sm pr-4 text-current-800 w-32">
              <span>{{ $t('VERIFY_CHANGE_EMAIL_CURRENT') }}</span>
            </div>
            <syn-form-input>
              <syn-input
                input-type="email"
                :value="loginValue"
                is-disabled
                :placeholder="loginValue || $t('VERIFY_CHANGE_EMAIL_CURRENT')"
              ></syn-input>
            </syn-form-input>
          </div>
          <div class="my-2 flex-center space-x-2">
            <div class="flex items-center text-sm pr-4 text-current-800 w-32">
              <span>{{ $t('VERIFY_CHANGE_EMAIL_NEW') }}</span>
            </div>
            <syn-form-input
              :error-message="errorEmailMess ? errorEmailMess : emailError"
            >
              <syn-input
                v-model="email"
                input-type="email"
                autofocus
                :error-message="errorEmailMess ? errorEmailMess : emailError"
                :placeholder="$t('VERIFY_CHANGE_EMAIL_NEW')"
                @enter="changeNext(status[keyStatus].next)"
              ></syn-input>
            </syn-form-input>
          </div>
        </div>
        <div v-else class="mt-6">
          <div class="my-2 flex-center space-x-2">
            <div
              class="
                flex
                items-center
                text-sm
                min-w-max
                pr-4
                text-current-800
                w-64
              "
            >
              <span>{{ $t('VERIFY_CHANGE_PHONE_CURRENT') }}</span>
            </div>
            <AtomPhoneInput
              :value="loginValue"
              :placeholder="loginValue"
              :auto-default-country="false"
              disabled
            />
          </div>
          <div class="my-2 flex-center space-x-2">
            <div
              class="
                flex
                items-center
                text-sm
                min-w-max
                pr-4
                text-current-800
                w-64
              "
            >
              <span>{{ $t('VERIFY_CHANGE_PHONE_NEW') }}</span>
            </div>
            <SynFormInput
              :error-message="errorPhoneMess ? errorPhoneMess : phoneError"
            >
              <AtomPhoneInput
                autofocus
                :value="phone"
                :input-options="{
                  placeholder: $t('VERIFY_CHANGE_PHONE_NEW'),
                }"
                @change="onChangePhone"
              />
            </SynFormInput>
          </div>
        </div>
      </section>

      <!-- GET CODE -->
      <section v-if="keyStatus == 'GET_CODE'">
        <get-code
          class="flex"
          type="getcode"
          :code-type="type.toLowerCase()"
          :is-get-code-only="true"
          :email="email"
          :phone="phone"
          :is-loading-resend="isResendCode"
          :is-loading="isLoadFinishGroup"
          :block-code-login="isBlockLoginByCode"
          :block-sending-code="isBlockSendPhoneCode"
          :error-code="errorCode"
          @change-code="(code) => getCodeVerify(code)"
          @resend-code="resendCode()"
        ></get-code>
      </section>

      <!-- COMPLETE -->
      <section v-if="keyStatus == 'FINISH'">
        <div class="flex-center flex-wrap">
          <syn-animation name="success" stype="width:200px;" />
          <div class="w-full text-center text-xl font-medium text-green-600">
            {{ $t('COMMON_LABEL_SUCCESS') }}
          </div>
        </div>
      </section>
    </template>
    <template #footer>
      <div class="pr-4 flex py-4 justify-end space-x-2">
        <syn-button
          v-if="status[keyStatus].previousText"
          type-outline
          :label="$t(status[keyStatus].previousText)"
          :disabled="isLoadFinishGroup"
          @click="changePrevious(status[keyStatus].previous, keyStatus)"
        >
        </syn-button>
        <syn-button
          v-if="status[keyStatus].confirmText"
          :is-loading="isLoadFinishGroup"
          :label="$t(status[keyStatus].confirmText)"
          :disabled="
            isLoadFinishGroup ||
            (!isReady && type == 'EMAIL') ||
            (!isReady && type == 'PHONE')
              ? true
              : false
          "
          @click="changeNext(status[keyStatus].next)"
        >
        </syn-button>
      </div>
    </template>
  </syn-modal>
  <div id="captcha-change-login"></div>
  <syn-question-modal
    :visible="isShowModalError"
    header-text="Oops!!"
    :show-confirm-btn="false"
    show-delete-btn
    :delete-label="$t('COMMON_LABEL_LOGIN_AGAIN')"
    :is-loading="isLoadingRefresh"
    @cancel="(value) => (isShowModalError = value)"
    @on-confirm="(value) => onRefreshPage()"
  >
    <template #content>
      <syn-animation name="oops" stype="width: 200px;" :loop="false" />
      <div class="text-center text-red-700">
        {{ errorCode }}
      </div>
    </template>
  </syn-question-modal>
</template>
