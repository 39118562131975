<script setup lang="ts">
import { computed, ref } from 'vue';
import dayjs from 'dayjs';
import dayoffCreate from '@/store/dayoff/dayoff-create';
import { hasRolePermission } from '@/ui/hooks/permission/permission-by-role';
import { AdminType } from '@/ui/common/constants/constant';
import {
  DayoffDateSession,
  DayoffExceptionReason,
  DayoffExceptionType,
  DayoffReason,
  DayoffType,
} from '@/domain/enums/DayoffEnums';
import calendarsOverviewStore from '@/store/calendars/calendars-overview';
import DayoffTypeAtom from '@/ui/components/dayoff/atoms/DayoffTypeAtom.vue';
import DayoffReasonAtom from '@/ui/components/dayoff/atoms/DayoffReasonAtom.vue';
// import dayoffOverviewStore from '@/store/dayoff/dayoff-overview';
import DayoffDateSessionAtom from '@/ui/components/dayoff/atoms/DayoffDateSessionAtom.vue';
import DateoffExceptionClass from '@/domain/entities/dayoff/DateoffExceptionClass';
import DayoffExceptionForm from '@/ui/components/dayoff/DayoffExceptionForm.vue';
import SynInputArea from '@/ui/common/atoms/SynInput/SynInputArea.vue';
import DayoffStatistic from '@/ui/components/dayoff/components/DayoffStatistic.vue';
import dayoffStatisticStore from '@/store/dayoff/dayoff-statistic';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { IDayOffNewEntity } from '@/domain/entities/dayoff/DayOffNewEntity';

const props = defineProps<{
  defaultData: {
    startDate?: number;
    endDate?: number;
    startDateSession?: DayoffDateSession;
    endDateSession?: DayoffDateSession;
    type?: DayoffType;
    requestReason?: DayoffReason | DayoffExceptionReason;
    requestNote?: string;
    exception?: any;
  };
}>();

const emit = defineEmits(['onCreateSuccess', 'cancel']);

const _calendarsOverviewStore = calendarsOverviewStore();
const _dayoffCreate = dayoffCreate();
const _dayoffStatisticStore = dayoffStatisticStore();

const isAdmin = computed(
  () => hasRolePermission(AdminType.Admin) || hasRolePermission(AdminType.Owner)
);
const isCurrentDateSelect = computed(
  () =>
    dayjs(new Date()).format('MM-DD-YYYY') ==
      dayjs(dateOffRange.value.start).format('MM-DD-YYYY') ||
    dayjs(new Date()).format('MM-DD-YYYY') ==
      dayjs(dateOffRange.value.end).format('MM-DD-YYYY')
);
const isDisabled = computed(
  () =>
    dayoffData.value.type !== DayoffType.EXCEPTION &&
    (!totalDay.value || totalDay.value == 0)
);
const detailDayoffUsed = computed(() => {
  return [
    {
      type: DayoffType.SCHEDULE,
      totalCount:
        dayoffUsed.value?.detail?.find((el) => el?.type == DayoffType.SCHEDULE)
          ?.totalCount || 0,
      totalDayoff:
        dayoffUsed.value?.detail?.find((el) => el?.type == DayoffType.SCHEDULE)
          ?.totalDayoff || 0,
    },
    {
      type: DayoffType.WFH,
      totalCount:
        dayoffUsed.value?.detail?.find((el) => el?.type == DayoffType.WFH)
          ?.totalCount || 0,
      totalDayoff:
        dayoffUsed.value?.detail?.find((el) => el?.type == DayoffType.WFH)
          ?.totalDayoff || 0,
    },
    {
      type: DayoffType.EXCEPTION,
      totalCount:
        dayoffUsed.value?.detail?.find((el) => el?.type == DayoffType.EXCEPTION)
          ?.totalCount || 0,
      totalDayoff:
        dayoffUsed.value?.detail?.find((el) => el?.type == DayoffType.EXCEPTION)
          ?.totalDayoff || 0,
    },
  ];
});
const parserDataType = computed(() => _dayoffStatisticStore.parserDataType);
const totalDayoff = computed(() => dayoffUsed.value?.totalDayoff);
// const statusUser = computed(() => {
//   return {
//     VERY_IMPORTANT: {
//       keyLabel: 'DAYOFF_STATUS_USER_VERY_IMPORTANT_LABEL',
//       statusColor: 'bg-red-500',
//     },
//     IMPORTANT: {
//       keyLabel: 'DAYOFF_STATUS_USER_IMPORTANT_LABEL',
//       statusColor: 'bg-red-200',
//     },
//     NECESSARY: {
//       keyLabel: 'DAYOFF_STATUS_USER_NECESSARY_LABEL',
//       statusColor: 'bg-orange-400',
//     },
//   };
// });

// exception
const exceptionData = ref({
  type: DayoffExceptionType.START_LATE,
  startTime: 0,
  endTime: 0,
  daySession: DayoffDateSession.MORNING,
});
const dayoffUsed = ref([] as any);
const totalDayoffInYear = 12;
const dateOffRange = ref({
  start: Date.now(),
  end: 0,
});
const dayoffData = ref<IDayOffNewEntity>({
  startDate: props.defaultData?.startDate || Date.now(),
  endDate: props.defaultData?.startDate || Date.now(),
  startDateSession:
    props.defaultData?.startDateSession || DayoffDateSession.ALL_DAY,
  endDateSession:
    props.defaultData?.endDateSession || DayoffDateSession.ALL_DAY,
  type: props.defaultData?.type || DayoffType.SCHEDULE,
  requestReason:
    props.defaultData?.requestReason ||
    (DayoffReason.PERSONAL as DayoffReason | DayoffExceptionReason),
  requestNote: props.defaultData?.requestNote || '',
  exception: props.defaultData?.exception || (null as any),
});
const totalDay = ref(0);
const isCreating = ref(false);

const initData = () => {
  getDayoffUsedByUser(getCurrentUserId());
  const defaultDate = props.defaultData?.startDate
    ? props.defaultData?.startDate
    : Date.now();
  dateOffRange.value = {
    start: defaultDate,
    end: defaultDate,
  };
  dayoffData.value = {
    ...dayoffData.value,
    startDate: defaultDate,
    endDate: defaultDate,
  };

  calculateTotalDay();
};

const clearToDate = () => {
  dateOffRange.value = {
    start: dayoffData.value.startDate,
    end: dayoffData.value.startDate,
  };
};

const onChangeDayoffRange = async (rangeDate) => {
  console.log('🚀 Hyrin ~ onChangeDayoffRange ~ rangeDate:', rangeDate);
  dateOffRange.value = rangeDate;
  dayoffData.value.startDate = rangeDate.start;
  dayoffData.value.endDate = rangeDate.end;
  calculateTotalDay();
};

const calculateTotalDay = async () => {
  const dayOffObj = await _dayoffCreate.calculateTotalDay({
    startDate: dayoffData.value.startDate,
    endDate: dayoffData.value.endDate,
    startDateSession: dayoffData.value.startDateSession,
    endDateSession: dayoffData.value.endDateSession,
  });

  totalDay.value = dayOffObj.total;
};

const onSubmitCreateDayoff = async () => {
  try {
    if (dayoffData.value.type == DayoffType.EXCEPTION) {
      dayoffData.value.exception = new DateoffExceptionClass(
        exceptionData.value
      );
      dayoffData.value.endDate = dayoffData.value.startDate;
    }
    isCreating.value = true;
    console.log(dayoffData.value, 'dayoffData.value--------');
    const res = await _dayoffCreate.createNewDayoff(dayoffData.value);
    await _calendarsOverviewStore.getListDayoffs();
    _calendarsOverviewStore.getMyActiveRequest();

    if (isAdmin.value) _calendarsOverviewStore.getListPending();
    isCreating.value = false;

    emit('onCreateSuccess', res?.result);
  } catch (error) {
    console.log('🚀 ~ onSubmitCreateDayoff ~ error', error);
  }
};

const onChangeDayoffType = () => {
  if (dayoffData.value.type === DayoffType.EXCEPTION) {
    exceptionData.value.type = DayoffExceptionType.START_LATE;
    dayoffData.value.requestReason = DayoffExceptionReason.SICK;
  } else {
    dayoffData.value.requestReason = DayoffReason.TRAVELING;
  }
};

const getDayoffUsedByUser = async (
  userId,
  fromDate: any = null,
  toDate: any = null
) => {
  dayoffUsed.value = await _dayoffStatisticStore.getDayoffUsedByUser(
    userId,
    fromDate,
    toDate
  );
};

initData();
</script>
<template>
  <div class="flex-1 flex flex-col">
    <!-- Header -->
    <div class="flex items-center justify-between px-4 py-4">
      <div class="text-gray-800 font-semibold text-lg">
        {{ $t('DAYOFF_CREATE_FORM_TITLE') || 'New request day off' }}
      </div>
      <!--      <div class="flex-center space-x-2">-->
      <!--        <span class="text-sm text-gray-800"-->
      <!--          >{{ $t('DAYOFF_CREATE_FORM_PERIOD') || 'My period' }}:</span-->
      <!--        >-->
      <!--        <div class="flex-center space-x-2">-->
      <!--          <span-->
      <!--            class="w-3 h-3 rounded-full"-->
      <!--            :class="statusUser.VERY_IMPORTANT?.statusColor"-->
      <!--          ></span>-->
      <!--          <span class="font-semibold">-->
      <!--            {{ $t(statusUser.VERY_IMPORTANT?.keyLabel) || 'Very important' }}-->
      <!--          </span>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <!-- Body -->
    <div class="flex items-start h-full">
      <div class="w-1/3 h-fit flex items-center flex-col relative">
        <!-- <div class="flex items-between flex-wrap">
          <div
            v-for="used in 5"
            :key="used"
            class="m-1 w-8 h-8 bg-orange-400 rounded flex-center"
          >
            <span class="text-xs text-white">{{ used + 7 }}/4</span>
          </div>
          <div
            v-for="available in 7"
            :key="available"
            class="m-1 w-8 h-8 bg-current rounded"
          ></div>
        </div>
        <div class="flex-center space-x-2 text-sm">
          <span>{{ $t('DAYOFF_CREATE_FORM_AVAILABLE') || 'Available' }}:</span>
          <span class="text-current font-semibold text-lg">7</span>/12
        </div> -->
        <DayoffStatistic
          :total-dayoff="totalDayoff"
          :total-dayoff-in-year="totalDayoffInYear"
          :detail-dayoff-used="detailDayoffUsed"
          :parser-data-type="parserDataType"
        />
      </div>

      <div class="h-max px-4 flex-1 justify-between flex flex-col space-y-4">
        <!-- Type -->
        <div class="flex items-center space-x-2">
          <SynRadioTag
            v-model="dayoffData.type"
            :value="DayoffType.SCHEDULE"
            radio-name="dayoff-create-form_type"
            custom-class="rounded-full"
            custom-active-class="bg-current-50"
            custom-inactive-class="opacity-70"
            @change="onChangeDayoffType"
          >
            <DayoffTypeAtom
              :is-active="dayoffData.type == DayoffType.SCHEDULE"
              :type="DayoffType.SCHEDULE"
            />
          </SynRadioTag>
          <SynRadioTag
            v-model="dayoffData.type"
            :value="DayoffType.WFH"
            radio-name="dayoff-create-form_type"
            custom-class="rounded-full"
            custom-active-class="bg-current-50"
            custom-inactive-class="opacity-70"
            @change="onChangeDayoffType"
          >
            <DayoffTypeAtom
              :is-active="dayoffData.type == DayoffType.WFH"
              :type="DayoffType.WFH"
            />
          </SynRadioTag>
          <SynRadioTag
            v-model="dayoffData.type"
            :value="DayoffType.EXCEPTION"
            radio-name="dayoff-create-form_type"
            custom-class="rounded-full"
            custom-active-class="bg-current-50"
            custom-inactive-class="opacity-70"
            @change="onChangeDayoffType"
          >
            <DayoffTypeAtom
              :type="DayoffType.EXCEPTION"
              :is-active="dayoffData.type == DayoffType.EXCEPTION"
            />
          </SynRadioTag>
        </div>

        <!-- SCHEDULE -->
        <template
          v-if="
            dayoffData.type == DayoffType.SCHEDULE ||
            dayoffData.type == DayoffType.WFH
          "
        >
          <!-- Select date -->
          <div
            class="
              flex
              items-center
              justify-between
              space-x-2
              rounded
              p-2
              bg-current-50
            "
          >
            <div>
              <v-date-picker
                v-model.range.number="dateOffRange"
                :rules="{
                  hours: 0,
                  minutes: 0,
                  seconds: 0,
                }"
                timezone="UTC"
                :attributes="[
                  {
                    key: 'today',
                    highlight: {
                      fillMode: isCurrentDateSelect ? 'solid' : 'outline',
                    },
                    dates: new Date(),
                  },
                ]"
                :model-config="{ type: 'number' }"
                :masks="{ input: 'DD-MM-YYYY' }"
                :popover="{ visibility: 'click' }"
                color="current"
                :first-day-of-week="2"
                is-range
                @update:model-value="onChangeDayoffRange"
              >
                <template #default="{ inputValue, inputEvents }">
                  <div class="flex justify-center items-center space-x-2">
                    <div class="flex flex-col space-y-2">
                      <input
                        :value="inputValue.start"
                        class="
                          w-full
                          border
                          px-2
                          py-1
                          rounded
                          focus:outline-none focus:border-current
                        "
                        v-on="inputEvents.start"
                      />
                      <div class="flex items-center space-x-1">
                        <SynRadioTag
                          v-model="dayoffData.startDateSession"
                          :value="DayoffDateSession.ALL_DAY"
                          radio-name="dayoff-create-form"
                          @change="calculateTotalDay"
                        >
                          <div class="px-2 text-sm">
                            <DayoffDateSessionAtom
                              is-text-only
                              :date-session="DayoffDateSession.ALL_DAY"
                            />
                          </div>
                        </SynRadioTag>
                        <SynRadioTag
                          v-if="dateOffRange?.end == dateOffRange?.start"
                          v-model="dayoffData.startDateSession"
                          :value="DayoffDateSession.MORNING"
                          radio-name="dayoff-create-form"
                          @change="calculateTotalDay"
                        >
                          <div class="px-2 text-sm">
                            <DayoffDateSessionAtom
                              is-text-only
                              :date-session="DayoffDateSession.MORNING"
                            />
                          </div>
                        </SynRadioTag>
                        <SynRadioTag
                          v-model="dayoffData.startDateSession"
                          :value="DayoffDateSession.AFTERNOON"
                          radio-name="dayoff-create-form"
                          @change="calculateTotalDay"
                        >
                          <div class="px-2 text-sm">
                            <DayoffDateSessionAtom
                              is-text-only
                              :date-session="DayoffDateSession.AFTERNOON"
                            />
                          </div>
                        </SynRadioTag>
                      </div>
                    </div>
                    <div
                      v-if="dateOffRange?.end !== dateOffRange?.start"
                      class="flex flex-col space-y-2"
                    >
                      <div class="flex-center relative">
                        <input
                          id="dayoff-create-form_to-date-input"
                          :value="inputValue.end"
                          class="
                            w-full
                            border
                            px-2
                            py-1
                            rounded
                            focus:outline-none focus:border-current
                          "
                          v-on="inputEvents.end"
                        />
                        <span v-if="inputValue.end" class="absolute right-1">
                          <SynIcon
                            has-action
                            name="close"
                            @click="clearToDate"
                          />
                        </span>
                      </div>
                      <div class="flex items-center space-x-1">
                        <SynRadioTag
                          v-model="dayoffData.endDateSession"
                          :value="DayoffDateSession.ALL_DAY"
                          radio-name="dayoff-create-form"
                          @change="calculateTotalDay"
                        >
                          <div class="px-2 text-sm">
                            <DayoffDateSessionAtom
                              is-text-only
                              :date-session="DayoffDateSession.ALL_DAY"
                            />
                          </div>
                        </SynRadioTag>
                        <SynRadioTag
                          v-model="dayoffData.endDateSession"
                          :value="DayoffDateSession.MORNING"
                          radio-name="dayoff-create-form"
                          @change="calculateTotalDay"
                        >
                          <div class="px-2 text-sm">
                            <DayoffDateSessionAtom
                              is-text-only
                              :date-session="DayoffDateSession.MORNING"
                            />
                          </div>
                        </SynRadioTag>
                      </div>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="flex-center bg-white p-2 rounded relative">
              <div class="flex items-end">
                <span
                  class="text-current"
                  style="font-size: 3rem; line-height: 3rem"
                >
                  {{ totalDay }}
                </span>
                <span class="text-sm">{{
                  $t(
                    totalDay > 1
                      ? 'DAYOFF_CREATE_FORM_DAYS'
                      : 'DAYOFF_CREATE_FORM_DAY'
                  )
                }}</span>
              </div>
            </div>
          </div>

          <!-- REASON -->
          <div class="flex flex-col space-y-2">
            <!-- reason type -->
            <div
              v-if="dayoffData.type !== DayoffType.WFH"
              class="flex space-x-2"
            >
              <SynRadioTag
                v-for="reason in DayoffReason"
                :key="reason"
                v-model="dayoffData.requestReason"
                :value="reason"
                radio-name="dayoff-create-form_reason"
                custom-active-class="bg-current-50"
                custom-inactive-class="opacity-70"
              >
                <DayoffReasonAtom
                  :is-active="dayoffData.requestReason === reason"
                  :reason="reason"
                />
              </SynRadioTag>
            </div>
            <!-- reason note -->
            <div>
              <SynInputArea
                v-model="dayoffData.requestNote"
                input-type="text"
                :is-disabled="false"
                :placeholder="
                  $t('DAYOFF_CREATE_FORM_NOTE_PLACEHOLDER') ||
                  'Note more detail in here...'
                "
              />
            </div>
          </div>
        </template>
        <template v-if="dayoffData.type == DayoffType.EXCEPTION">
          <DayoffExceptionForm
            v-model:exceptionDataModel="exceptionData"
            v-model:startDateModel="dayoffData.startDate"
            v-model:requestReasonModel="dayoffData.requestReason"
            v-model:requestNoteModel="dayoffData.requestNote"
          />
        </template>
      </div>
    </div>

    <!-- Footer -->
    <div class="px-4 py-2 flex items-center justify-end">
      <div class="flex-center space-x-4">
        <span
          class="text-gray-800 cursor-pointer hover:text-current"
          @click="$emit('cancel')"
        >
          {{ $t('COMMON_LABEL_CANCEL') || 'Cancel' }}
        </span>
        <SynButton
          :disabled="isDisabled"
          :is-loading="isCreating"
          @click="onSubmitCreateDayoff"
        >
          <span>
            {{ $t('COMMON_LABEL_SAVE') || 'Save' }}
          </span>
        </SynButton>
      </div>
    </div>
  </div>
</template>
