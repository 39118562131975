import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import DayoffService from '@/application/services/DayoffService';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { DayoffExceptionTypeUI } from '@/ui/components/dayoff/atoms/dayoff-ui-constant';
// import dayjs from 'dayjs';

type DayOffStatisticState = {
    _dayoffService: DayoffService;
    startDateDefault: number;
    endDateDefault: number;
    parserDataType: object;
    dayoffListByMonth: [];
};

export default defineStore({
    id: storeId.DAYOFF_STATISTICS,
    state: () =>
        ({
            _dayoffService: DayoffService.getInstance(),
            startDateDefault: new Date(
                new Date().getFullYear(),
                0,
                1
            ).getTime(),
            endDateDefault: new Date(
                new Date().getFullYear(),
                11,
                31
            ).getTime(),
            dayoffListByMonth: [] as any[],
            parserDataType: {
                SCHEDULE: {
                    type: 'SCHEDULE',
                    label: 'DAYOFF_TYPE_SCHEDULE',
                    color: ['#076260', '#4bb0b2'], //1, 2
                    value: [1, 2],
                    labelValue: [
                        translate('COMMOM_LABEL_DAY', { day: 1 }),
                        translate('COMMOM_LABEL_DAY', { day: 0.5 }),
                    ],
                },
                WFH: {
                    type: 'WFH',
                    label: 'DAYOFF_TYPE_WFH',
                    color: ['#3b82f6', '#93c5fd'], //3, 4
                    value: [3, 4],
                    labelValue: [
                        translate('COMMOM_LABEL_DAY', { day: 1 }),
                        translate('COMMOM_LABEL_DAY', { day: 0.5 }),
                    ],
                },
                EXCEPTION: {
                    type: 'EXCEPTION',
                    label: 'DAYOFF_TYPE_EXCEPTION',
                    color: ['#facc15', '#ea580c', '#b91c1c'], //5, 6, 7
                    value: [5, 6, 7],
                    labelValue: [
                        translate(
                            DayoffExceptionTypeUI['STOP_EARLY']?.textCode
                        ),
                        translate(
                            DayoffExceptionTypeUI['START_LATE']?.textCode
                        ),
                        translate(
                            DayoffExceptionTypeUI['BREAK_TIME']?.textCode
                        ),
                    ],
                },
            },
        } as DayOffStatisticState),
    getters: {},
    actions: {
        async getDayoffUsedByUser(
            userId,
            fromDate: any = null,
            toDate: any = null
        ) {
            const payloadFromDate = fromDate || this.startDateDefault;
            const payloadToDate = toDate || this.endDateDefault;

            try {
                const res = await this._dayoffService.getDayoffUsedByUser(
                    payloadFromDate,
                    payloadToDate,
                    userId
                );

                // console.log('getDayoffUsedByUser', res['result']);
                return res['result'];
            } catch (error) {
                // console.log(error);
                return [];
            }
        },
        async getStatisticByUserAndRange(
            userId,
            fromDate: any = null,
            toDate: any = null
        ) {
            const payloadFromDate = fromDate || this.startDateDefault;
            const payloadToDate = toDate || this.endDateDefault;

            try {
                const res =
                    await this._dayoffService.getStatisticByUserAndRange(
                        payloadFromDate,
                        payloadToDate,
                        userId
                    );
                const arr = res['result'];
                arr.map((e) => {
                    e.dateTime = new Date(e.date);
                    return e;
                });
                const arrMonth = Array.from(Array(12).keys()) as any;

                this.dayoffListByMonth = arrMonth.map((o) => ({
                    month: o + 1,
                    dayOffList: arr.filter(
                        (e) =>
                            e.dateTime.getMonth() === o && e.type == 'SCHEDULE'
                    ),
                    startLateList: arr.filter(
                        (e) =>
                            e.dateTime.getMonth() === o &&
                            e.type == 'EXCEPTION' &&
                            e.exception.type == 'START_LATE'
                    ),
                    stopEarlyList: arr.filter(
                        (e) =>
                            e.dateTime.getMonth() === o &&
                            e.type == 'EXCEPTION' &&
                            e.exception.type == 'STOP_EARLY'
                    ),
                    breakTimeList: arr.filter(
                        (e) =>
                            e.dateTime.getMonth() === o &&
                            e.type == 'EXCEPTION' &&
                            e.exception.type == 'BREAK_TIME'
                    ),
                    workFromHomeList: arr.filter(
                        (e) => e.dateTime.getMonth() === o && e.type == 'WFH'
                    ),
                }));

                return res['result'];
            } catch (error) {
                // console.log(error);
                return [];
            }
        },
    },
});
