<script setup lang="ts">
import { ref, onMounted, computed, inject } from 'vue';
import dayjs from 'dayjs';
import principleStore from '@/store/principle';
import TimeWorking from '@/ui/modules/working/TimeWorking.vue';
import {
  isShowTaskDetailGlobal,
  taskCodeGlobal,
} from '@/ui/modules/task/task-global-state';
import workingTimeComposable from '@/ui/modules/dashboard/monitor/working-time-composable';
import VigSearchBox from '@/ui/common/molecules/VigSearchBox/VigSearchBox.vue';
// import { taskListLinkByType } from '@/ui/hooks/commonHook';
import router from '@/ui/router';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import VigDropdown from '@/ui/common/atoms/VigDropdown/VigDropdown.vue';
import SynTh from '@/ui/common/molecules/SynTableCustom/SynTh.vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import settingStore from '@/store/setting';
import FilterPrincipleListByDate from '@/ui/modules/dashboard/monitor/FilterPrincipleListByDate.vue';
import UserByAction from '@/ui/components/user/UserByAction.vue';
import DepartmentById from '@/ui/modules/departments/components/DepartmentById.vue';
import FilterDepartmentMultiple from '@/ui/modules/dashboard/monitor/FilterDepartmentMultiple.vue';

const props = defineProps<{
  departmentId?: number;
  currentTab?: string;
}>();
const _principleStore = principleStore();
const _settingStore = settingStore();
const isHiddenBlockPage: any = inject('IS_HIDDEN_BLOCK_PAGE');

const {
  keySearch,
  workingTimeByUsers,
  isShowOrderTimeStartWD,
  departmentList,
  loading,
  departmentSelectedList,
  initComponent,
} = workingTimeComposable(props.departmentId);

const departmentFilterList = computed(() => departmentList.value);
const isLoading = computed(() => loading.value);
const isShowOrder = ref(true);
const departmentManagementEnabled = computed(
  () => _settingStore._organizationSetting.departmentManagement.enabled
);

// const isLoading = ref(false);
const filterObj = ref({
  dateFrom: new Date(),
  dateTo: new Date(),
  fileTypes: {},
});

const dateFilter = ref<number | null>(null);

onMounted(() => {
  fetchData();
});

const fetchData = () => {
  keySearch.value = '';
  // initComponent(null);
};

const onChooseUser = async (principle: any) => {
  console.log('🚀 Hyrin ~ onChooseUser ~ principle', principle);
};

const onReload = async () => {
  onResetDate();
  initComponent(dateFilter.value);
};

const calculateDuration = (principle: any) => {
  if (
    principle?.start &&
    principle?.pauseList.length == 0 &&
    !principle?.stop &&
    dayjs.utc().startOf('day').valueOf() ===
      dayjs.utc(principle?.idDay).startOf('day').valueOf()
  ) {
    let duration;
    const currentTime = dayjs.utc().valueOf();
    duration = currentTime - dayjs.utc(principle?.start).valueOf();
    const durationObj = dayjs.duration(duration);
    const hours = durationObj.hours() > 0 ? `${durationObj.hours()}h` : '';
    const minutes = `${durationObj.minutes()}m`;
    return `${hours} ${minutes}`;
  } else if (
    principle?.start &&
    principle?.pauseList.length > 0 &&
    !principle?.stop &&
    dayjs.utc().startOf('day').valueOf() ===
      dayjs.utc(principle?.idDay).startOf('day').valueOf()
  ) {
    let duration;
    const currentTime = dayjs.utc().valueOf();
    duration =
      currentTime - dayjs.utc(principle?.pauseList[0].checkIn).valueOf();
    const totalDuration = (
      duration / (1000 * 60 * 60) +
      principle.duration
    ).toFixed(2);
    return principle?.pauseList[0].checkIn == null
      ? formatDecimalToTime(principle.duration)
      : formatDecimalToTime(totalDuration);
  } else
    return principle.duration > 0
      ? formatDecimalToTime(principle.duration)
      : principle.duration;
};

const formatDecimalToTime = (duration) => {
  const hours = Math.floor(duration);
  const minutes = Math.floor((duration % 1) * 60);
  const durationObj = dayjs.duration({ hours, minutes });
  return hours > 0
    ? durationObj.format('H[h] m[m]')
    : durationObj.format('m[m]');
};

const onResetDate = () => {
  filterObj.value.dateFrom = new Date();
};

const onOpenTaskDetail = (taskCode) => {
  taskCodeGlobal.value = taskCode;
  isShowTaskDetailGlobal.value = true;
};

const sortDataOrderByKey = computed(() => _principleStore.sortDataOrderByKey);

const onClickSort = (key, name) => {
  _principleStore.genSortDataList({ key, name });
  window.scrollTo(0, 0);
};

const inputSearchWorkingTime = ref();

window.addEventListener('keydown', (e) => {
  if (
    e.ctrlKey &&
    e.keyCode === 83 &&
    router.currentRoute.value.fullPath.includes('/dashboard/monitor') &&
    props.currentTab === 'MEMBERS'
  ) {
    inputSearchWorkingTime.value?.focus();
    e.preventDefault();
  }
});

const onFilerDepartment = (value) => {
  departmentSelectedList.value = value;
};

const onFilterDate = async (value) => {
  dateFilter.value = value;
  await initComponent(dateFilter.value);
};

const getValueShowOrder = (value) => {
  isShowOrder.value = value;
};

// const getMenuLinkUrl = (id) => {
//   return taskListLinkByType('USER', id, true);
// };
</script>
<template>
  <!-- <syn-coming-soon></syn-coming-soon> -->
  <div
    v-permission-screen="
      isHiddenBlockPage
        ? false
        : {
            screenCode: ALL_SCREENS.COMMON.OVERVIEW.MEMBER,
          }
    "
    class="h-full w-full flex flex-col"
  >
    <div
      class="
        pr-4
        h-full
        rounded-md
        flex flex-col
        space-y-1
        p-2
        overflow-y-hidden
      "
    >
      <!-- SEARCH/ FILTER -->
      <div class="flex w-full items-center justify-between pt-1 pb-2">
        <div class="flex space-x-2 items-center">
          <div class="flex-center w-72">
            <VigSearchBox
              ref="inputSearchWorkingTime"
              v-model="keySearch"
              class="w-full"
              :placeholder="$t('COMMON_LABEL_SEARCH_PLACEHOLDER_MEMBER')"
            />
          </div>
        </div>
        <FilterPrincipleListByDate
          @on-filter-date="onFilterDate"
          @is-show-order-start-w-d="getValueShowOrder"
        />
        <div class="flex items-end space-x-4">
          <div class="flex items-center space-x-1">
            <span
              class="
                text-xs
                rounded
                py-1
                px-2
                border border-current-400
                text-current-500
              "
            >
              {{ `${$t('COMMON_LABEL_TOTAL')}: ${workingTimeByUsers.length}` }}
            </span>
            <span>|</span>
            <span class="rounded text-xs py-1 px-2 bg-current text-white">
              {{
                `${$t('COMMON_LABEL_START_ONDAY')}: ${
                  workingTimeByUsers.filter(
                    (user) => user?.workingStatus === 'online'
                  ).length || 0
                }`
              }}
            </span>
            <span class="rounded text-xs py-1 px-2 bg-orange-400 text-white">
              {{
                `${$t('COMMON_LABEL_PAUSE_ONDAY')}: ${
                  workingTimeByUsers.filter(
                    (user) => user?.workingStatus === 'absent'
                  ).length || 0
                }`
              }}
            </span>
            <span class="rounded text-xs py-1 px-2 bg-gray-400 text-white">{{
              $t('COMMON_LABEL_STOP_ONDAY') +
              ': ' +
              workingTimeByUsers.filter(
                (user) => user?.workingStatus == 'offline'
              ).length
            }}</span>
          </div>
        </div>
      </div>

      <div class="flex-1 overflow-y-auto small-scrollbar">
        <!-- TABLE SHOW LIST USER ON DASHBOARD -->
        <SynTableCustom class="rounded">
          <template #header>
            <SynTr>
              <SynTh v-if="isShowOrderTimeStartWD && isShowOrder" />
              <SynTh
                :label="$t('COMMON_LABEL_MEMBERS')"
                is-sort
                :order-data="sortDataOrderByKey['fullName']"
                style="width: 230px"
                @on-sort="onClickSort('fullName', $t('COMMON_LABEL_MEMBERS'))"
              />
              <SynTh
                v-if="!departmentId && departmentManagementEnabled"
                :label="$t('COMMON_MODULE_MANAGEMENT_DEPARTMENT')"
              >
                <template #custom>
                  <FilterDepartmentMultiple
                    :department-list="departmentFilterList"
                    @on-filter="onFilerDepartment"
                  />
                </template>
              </SynTh>
              <!--                <SynTh-->
              <!--                  :label="$t('DASHBOARD_TABLE_LABEL_TIME_UTC')"-->
              <!--                  is-sort-->
              <!--                  :order-data="sortDataOrderByKey['utcZone']"-->
              <!--                  @on-sort="-->
              <!--                    onClickSort('utcZone', $t('DASHBOARD_TABLE_LABEL_TIME_UTC'))-->
              <!--                  "-->
              <!--                />-->
              <SynTh
                :label="$t('OVERVIEW_LABEL_START_TO_WORK_SHORT')"
                :full-label="$t('OVERVIEW_LABEL_START_TO_WORK')"
              />
              <SynTh
                :label="$t('DASHBOARD_LABEL_DESCRIPTION_PAUSE_TIME_SHORT')"
                :full-label="$t('DASHBOARD_LABEL_DESCRIPTION_PAUSE_TIME')"
              />
              <SynTh
                :label="$t('OVERVIEW_LABEL_STOP_TO_WORK_SHORT')"
                :full-label="$t('OVERVIEW_LABEL_STOP_TO_WORK')"
              />
              <SynTh
                :label="$t('DASHBOARD_TABLE_LABEL_DUR') + '(h)'"
                :full-label="$t('DASHBOARD_TABLE_LABEL_DUR_UNIT')"
                is-sort
                :order-data="sortDataOrderByKey['duration']"
                @on-sort="
                  onClickSort('duration', $t('DASHBOARD_TABLE_LABEL_DUR'))
                "
              />
              <!--              <SynTh-->
              <!--                :label="-->
              <!--                  $t('DASHBOARD_TABLE_LABEL_DASHBOARD_TABLE_LABEL_ON_DAY')-->
              <!--                "-->
              <!--                is-sort-->
              <!--                :order-data="sortDataOrderByKey['countTaskOnday']"-->
              <!--                @on-sort="-->
              <!--                  onClickSort(-->
              <!--                    'countTaskOnday',-->
              <!--                    $t('DASHBOARD_TABLE_LABEL_DASHBOARD_TABLE_LABEL_ON_DAY')-->
              <!--                  )-->
              <!--                "-->
              <!--              />-->
              <!--              <SynTh-->
              <!--                :label="-->
              <!--                  $t('DASHBOARD_TABLE_LABEL_DASHBOARD_TABLE_LABEL_FINISH_TODAY')-->
              <!--                "-->
              <!--                is-sort-->
              <!--                :order-data="sortDataOrderByKey['countTaskOnFinish']"-->
              <!--                @on-sort="-->
              <!--                  onClickSort(-->
              <!--                    'countTaskOnFinish',-->
              <!--                    $t(-->
              <!--                      'DASHBOARD_TABLE_LABEL_DASHBOARD_TABLE_LABEL_FINISH_TODAY'-->
              <!--                    )-->
              <!--                  )-->
              <!--                "-->
              <!--              />-->
              <SynTh :label="$t('LABEL_STATISTIC_TASK_ON_DAY')" />
              <SynTh>
                <button
                  v-vig-tooltip="$t('NOTIFICATION_RELOAD') || 'Reload'"
                  class="
                    flex-center
                    rounded-full
                    hover:fill-current
                    fill-gray-500
                    w-6
                    h-6
                  "
                  @click="onReload"
                >
                  <SynIcon name="reload" class="w-4 h-4" />
                </button>
              </SynTh>
            </SynTr>

            <!-- <syn-table-th>
                                                                                                                                                                                                                  {{ $t('DASHBOARD_TABLE_LABEL_DASHBOARD_TABLE_LABEL_PENDING') }}
                                                                                                                                                                                                                </syn-table-th> -->
          </template>
          <template #body>
            <template v-if="isLoading">
              <SynTr class="h-full w-full" hover="hover:bg-current-50">
                <SynTd
                  :colspan="
                    isShowOrderTimeStartWD && isShowOrder == true ? 9 : 8
                  "
                  style="height: calc(100vh - 50rem)"
                  class="text-center"
                >
                  <!--                  <syn-animation-->
                  <!--                    name="searchIcon"-->
                  <!--                    stype="width: 200px; height: 200px"-->
                  <!--                    :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"-->
                  <!--                  />-->
                  <SynIcon
                    name="spinner"
                    class="mt-5 fill-current border-none animate-spin"
                    custom-class="w-7 h-7"
                  />
                </SynTd>
              </SynTr>
            </template>
            <!--            <syn-table-tr-loading v-if="isLoading">-->
            <!--              <div v-for="item in 8" :key="item" class="table-cell p-2">-->
            <!--                <div class="p-2 w-3/4 bg-gray-200 rounded-md"></div>-->
            <!--              </div>-->
            <!--            </syn-table-tr-loading>-->
            <template
              v-for="(principle, index) in workingTimeByUsers"
              v-else-if="workingTimeByUsers.length > 0"
              :key="principle.userId"
            >
              <SynTr
                v-if="workingTimeByUsers.length > 0"
                class="hover:bg-white-50 hover:text-current-500"
                :class="index % 2 == 0 ? 'bg-white' : ''"
                @click="onChooseUser(principle)"
              >
                <SynTd
                  v-if="isShowOrderTimeStartWD && isShowOrder"
                  style="width: 50px"
                >
                  <div v-if="!principle.start" class="flex-center w-6">-</div>
                  <div
                    v-else
                    class="
                      w-6
                      h-6
                      rounded-full
                      flex-center
                      font-semibold
                      text-white
                      z-10
                    "
                    :class="{
                      'bg-green-500': principle?.workingStatus === 'online',
                      'bg-orange-400': principle?.workingStatus === 'absent',
                      'bg-gray-400': principle?.workingStatus === 'offline',
                    }"
                  >
                    {{ principle?.startIndex }}
                  </div>
                </SynTd>
                <SynTd>
                  <div
                    class="
                      flex
                      items-center
                      space-x-2
                      font-medium
                      cursor-pointer
                      text-xs
                      3xl:text-sm
                    "
                  >
                    <!--                    <syn-avatar-notification-->
                    <!--                      :src="-->
                    <!--                        principle?.avatar?.replace('original', 'mini') ||-->
                    <!--                        principle?.avatarUrl?.replace('original', 'mini')-->
                    <!--                      "-->
                    <!--                      custom-class="w-8 h-8"-->
                    <!--                      :name="principle.fullname"-->
                    <!--                      has-name-->
                    <!--                      :status="userWorkingStatus[principle?.userId]?.status"-->
                    <!--                    >-->
                    <!--                    </syn-avatar-notification>-->

                    <UserByAction
                      :user-id="principle?.userId"
                      is-show-action-user
                      :is-show-user-notification="!dateFilter"
                      avatar-class="w-8 h-8"
                    />
                    <!--                    <span class="text-overflow-hidden-line">{{-->
                    <!--                      principle.fullname-->
                    <!--                    }}</span>-->
                  </div>
                </SynTd>
                <SynTd
                  v-if="!departmentId && departmentManagementEnabled"
                  class="text-xs 3xl:text-sm"
                >
                  <DepartmentById
                    v-if="principle?.departmentId"
                    :department-id="principle?.departmentId"
                    :is-name-center="false"
                  />
                </SynTd>

                <!--                  <SynTd style="width: 200px">-->
                <!--                    <syn-tag-->
                <!--                      class="w-max bg-current-50"-->
                <!--                      position="flex item-center justify-center"-->
                <!--                    >-->
                <!--                      {{-->
                <!--                        $filters.dayjsByZone(-->
                <!--                          new Date(),-->
                <!--                          principle?.utcZone || '7',-->
                <!--                          'hh:mm A'-->
                <!--                        )-->
                <!--                      }}-->
                <!--                    </syn-tag>-->
                <!--                  </SynTd>-->

                <SynTd>
                  <div
                    v-if="principle.start"
                    class="
                      border
                      flex
                      items-center
                      rounded-full
                      bg-white
                      w-24
                      py-1
                      space-x-2
                      px-1
                      text-xs
                      3xl:text-sm 3xl:w-28
                    "
                  >
                    <div
                      class="w-6 h-6 bg-current-500 flex-center rounded-full"
                    >
                      <SynIcon
                        name="start"
                        custom-class="w-3 h-3 fill-white text-white"
                      />
                    </div>
                    <span class="ms-2">
                      <SynLabelDateTime
                        format="time"
                        :datetime="principle.start"
                      />
                    </span>
                  </div>
                  <div
                    v-else
                    class="
                      border
                      rounded-full
                      bg-gray-50
                      w-24
                      flex
                      items-center
                      py-1
                      px-1
                      space-x-4
                      3xl:w-28
                    "
                  >
                    <div
                      class="
                        w-6
                        h-6
                        bg-current-500 bg-opacity-70
                        flex-center
                        rounded-full
                      "
                    >
                      <SynIcon
                        name="start"
                        custom-class="w-3 h-3 fill-white text-white"
                      />
                    </div>
                    <span> --:-- </span>
                  </div>
                </SynTd>

                <SynTd class="text-xs 3xl:text-sm">
                  <TimeWorking
                    :pause-list="principle.pauseList"
                    :timezone="principle?.utcZone"
                    :time-limit="principle?.timeLimit"
                  />
                </SynTd>

                <SynTd
                  class-custom="text-xs
                  3xl:text-sm"
                >
                  <div
                    v-if="principle.stop"
                    class="
                      border
                      flex
                      items-center
                      rounded-full
                      bg-white
                      w-24
                      py-1
                      space-x-2
                      px-1
                      3xl:w-28
                    "
                  >
                    <div class="w-6 h-6 bg-gray-600 flex-center rounded-full">
                      <SynIcon
                        name="stop-video"
                        custom-class="w-3 h-3 fill-white text-white"
                      />
                    </div>
                    <span class="ms-2">
                      <SynLabelDateTime
                        format="time"
                        :datetime="principle.stop"
                      />
                    </span>
                  </div>
                  <div
                    v-else
                    class="
                      border
                      rounded-full
                      bg-gray-50
                      w-24
                      flex
                      items-center
                      py-1
                      px-1
                      space-x-4
                      3xl:w-28
                    "
                  >
                    <div class="w-6 h-6 bg-gray-500 flex-center rounded-full">
                      <SynIcon
                        name="stop-video"
                        custom-class="w-3 h-3 fill-white text-white"
                      />
                    </div>
                    <span> --:-- </span>
                  </div>
                </SynTd>
                <SynTd class="text-xs 3xl:text-sm">
                  <span class="ms-4">{{ calculateDuration(principle) }} </span>
                </SynTd>
                <SynTd>
                  <div class="flex items-center space-x-3">
                    <div v-if="principle.countTaskOnday > 0" class="">
                      <VigDropdown class="relative" trigger="mouseenter">
                        <template #dropdown-toggle>
                          <div
                            class="
                              bg-blue-100
                              rounded-md
                              flex
                              items-center
                              space-x-1
                              p-1
                            "
                          >
                            <div
                              class="
                                h-4
                                w-4
                                3xl:h-5 3xl:w-5 3xl:text-sm
                                text-xs
                                rounded-md
                                bg-blue-500
                                text-white
                                flex-center
                              "
                            >
                              {{ principle.countTaskOnday }}
                            </div>
                            <span class="text-blue-800 text-2xs 3xl:text-sm">{{
                              $t('TASK_LIST_VIEW_DO_TODAY_LABEL')
                            }}</span>
                          </div>
                        </template>
                        <template #dropdown-menu>
                          <div
                            class="
                              w-max
                              bg-white
                              rounded-md
                              overflow-auto
                              small-scrollbar
                              divide-y
                            "
                            style="max-height: 400px"
                          >
                            <div
                              v-for="item in principle.taskCodesOnday"
                              :key="item"
                              class="
                                py-2
                                px-3
                                cursor-pointer
                                dropdown-item
                                hover:bg-gray-50
                              "
                            >
                              <div
                                class="text-current-500"
                                @click="onOpenTaskDetail(item)"
                              >
                                <span class="text-xs font-bold">
                                  {{ item }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </template>
                      </VigDropdown>
                    </div>
                    <div
                      v-if="principle.countTaskOnFinish > 0"
                      class="flex-center"
                    >
                      <VigDropdown class="relative" trigger="mouseenter">
                        <template #dropdown-toggle>
                          <div
                            class="
                              bg-red-100
                              rounded-md
                              flex
                              items-center
                              space-x-1
                              p-1
                            "
                          >
                            <div
                              class="
                                h-4
                                w-4
                                3xl:h-5 3xl:w-5 3xl:text-sm
                                text-xs
                                rounded-md
                                bg-red-500
                                text-white
                                flex-center
                              "
                            >
                              {{ principle.countTaskOnFinish }}
                            </div>
                            <span class="text-red-800 text-2xs 3xl:text-sm">{{
                              $t('TASK_CREATE_FORM_FINISH_TODAY')
                            }}</span>
                          </div>
                        </template>
                        <template #dropdown-menu>
                          <div
                            class="
                              w-max
                              bg-white
                              rounded-md
                              overflow-auto
                              small-scrollbar
                              divide-y
                            "
                            style="max-height: 400px"
                          >
                            <div
                              v-for="item in principle.taskCodesOnFinish"
                              :key="item"
                              class="
                                py-2
                                px-3
                                cursor-pointer
                                dropdown-item
                                hover:bg-gray-50
                              "
                            >
                              <div
                                class="text-current-500"
                                @click="onOpenTaskDetail(item)"
                              >
                                <span class="text-xs font-bold">
                                  {{ item }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </template>
                      </VigDropdown>
                    </div>
                  </div>
                </SynTd>
                <SynTd style="width: 50px"></SynTd>
              </SynTr>
              <SynTr
                v-else
                class="h-full border-b-none"
                hover="hover:bg-current-50"
                :class="index % 2 == 0 ? 'bg-blue-50' : ''"
              >
                <SynTd :colspan="6">
                  <syn-animation
                    name="searchNotFound"
                    stype="width: 200px; height: 200px"
                  ></syn-animation>
                </SynTd>
              </SynTr>
            </template>
            <template v-else>
              <SynTr class="h-full" hover="hover:bg-current-50">
                <SynTd
                  :colspan="isShowOrderTimeStartWD && isShowOrder ? 9 : 8"
                  style="height: calc(100vh - 33rem)"
                >
                  <syn-animation
                    name="searchIcon"
                    stype="width: 200px; height: 200px"
                    :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
                  />
                </SynTd>
              </SynTr>
            </template>
          </template>
        </SynTableCustom>
      </div>
    </div>
  </div>
</template>
