<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import chatStore from '@/store/chat';
import settingStore from '@/store/setting';
import domainStore from '@/store/scope';
import getUserInfo from '@/ui/helpers/user-helper';
import getGroupInfo from '@/ui/helpers/group-helper';
import { AdminType } from '@/ui/common/constants/constant';
import {
  isOpenCreateTaskDrawer,
  newTaskDefaultPayload,
  onCreateGlobalSuccess,
} from '@/ui/modules/task/task-global-state';
import { taskListLinkByType } from '@/ui/hooks/commonHook';
import ModalProfileUser from '@/ui/components/user/ModalProfileUser.vue';
import FavoriteButton from '@/ui/components/user/FavoriteButton.vue';
import useChatCommon from '@/ui/composables/chat/chat-common-composables';

const props = withDefaults(
  defineProps<{
    contactId: string | number;
    types?: any;
    typeContact?: 'member' | 'group' | 'domain';
  }>(),
  {
    types: ['favourite', 'information', 'list-task', 'create-task', 'chat'],
    typeContact: 'member',
  }
);

const emit = defineEmits(['onActionClick']);

// const route = useRoute();
const _domainStore = domainStore();
const _chatStore = chatStore();
const _settingStore = settingStore();
const chatEnabled = computed(() => _settingStore.chatEnabled);
const domainByIds = computed(() => _domainStore.domainByIds);

const { goToChatFromContact } = useChatCommon();

const listTypes = {
  favourite: 'favourite',
  information: 'information',
  listTask: 'list-task',
  createTask: 'create-task',
  chat: 'chat',
};

const ownerInformation = computed(() => {
  switch (props.typeContact) {
    case 'member':
      return getUserInfo(props.contactId);
    case 'group':
      return getGroupInfo(props.contactId);
    case 'domain':
      return domainByIds.value[props.contactId];
    default:
      return getUserInfo(props.contactId);
  }
});
const isOpenProfileUser = ref(false);

const onMessageToUser = (contact) => {
  goToChatFromContact(contact, props.typeContact == 'group');

  emit('onActionClick', listTypes.chat);
};

const chooseUser = (contact) => {
  let routeType: 'USER' | 'GROUP' | 'DOMAIN' = 'USER';

  switch (props.typeContact) {
    case 'member':
      routeType = 'USER';
      break;
    case 'group':
      routeType = 'GROUP';
      break;
    case 'domain':
      routeType = 'DOMAIN';
      break;
    default:
      routeType = 'USER';
      break;
  }
  return taskListLinkByType(routeType, contact?.id);

  // return isGroup
  //   ? `/tasks/groups/${encryptGroupId(id)}`
  //   : `/tasks/users/${encryptUserId(id)}`;
};

onMounted(async () => {
  // showMessage.value = _settingStore.checkSettingAccessPermission('chatEnabled');
});

const onOpenUserInfo = () => {
  isOpenProfileUser.value = true;
};

const openCreateTaskForUser = (contact) => {
  const taskOwnerId = contact?.id;

  newTaskDefaultPayload.value = {
    groupId: props.typeContact === 'group' ? taskOwnerId : undefined,
    assigneeId: props.typeContact === 'member' ? taskOwnerId : undefined,
  };

  onCreateGlobalSuccess.value = (task) => {
    setTimeout(() => _chatStore.setChatAboutTask(task));
  };
  isOpenCreateTaskDrawer.value = true;
};
</script>
<template>
  <div class="flex items-center space-x-2">
    <!-- CREATE TASK -->
    <div
      v-if="
        types.includes(listTypes.createTask) &&
        ((typeContact === 'group' && ownerInformation?.isMember) ||
          typeContact === 'member')
      "
      :title="
        $t('TASK_CREATE_FORM_CREATE_TASK_CREATE_FORM_FOR') +
        ' ' +
        (typeContact === 'member'
          ? ownerInformation?.firstName
          : ownerInformation?.name)
      "
      class="
        flex-center
        fill-white
        cursor-pointer
        bg-current-500
        rounded-full
        shadow-lg
        w-7
        h-7
        p-2
        hover:bg-current-600
      "
      @click.stop="openCreateTaskForUser(ownerInformation)"
    >
      <SynIconCustom name="plus" custom-class="w-3 h-3" />
    </div>

    <!-- LIST TASK -->
    <RouterLink
      v-if="types.includes(listTypes.listTask)"
      :title="
        $t('LIST_TASK_VIEW_USER_TASK', {
          user:
            typeContact === 'member'
              ? ownerInformation?.firstName
              : ownerInformation?.name,
        })
      "
      :to="chooseUser(ownerInformation)"
      class="
        dropdown-item
        cursor-pointer
        flex-center
        w-7
        h-7
        shadow-md
        rounded-full
        fill-white
        text-white
        bg-current-500
        hover:bg-current-700
      "
    >
      <SynIconCustom name="note" is-active custom-class="w-3.5 h-3.5" />
    </RouterLink>

    <!-- PROFILE -->
    <div
      v-if="types.includes(listTypes.information)"
      :title="$t('COMMON_LABEL_PROFILE')"
      class="
        flex-center
        cursor-pointer
        bg-blue-400
        text-white
        rounded-full
        shadow-lg
        w-7
        h-7
        hover:bg-blue-500
      "
      @click.stop="onOpenUserInfo()"
    >
      <span>i</span>
      <!-- <SynIconCustom is-active name="info" class="fill-blue-500 w-7 h-7" /> -->
    </div>

    <!-- CHAT -->
    <div
      v-if="
        (chatEnabled &&
          types.includes(listTypes.chat) &&
          typeContact === 'member') ||
        (chatEnabled &&
          types.includes(listTypes.chat) &&
          typeContact === 'group' &&
          ownerInformation?.isMember)
      "
      v-permission="'Messages'"
      :title="
        $t('LIST_TASK_CHAT_TO_USER', {
          user:
            typeContact === 'member'
              ? ownerInformation?.firstName
              : ownerInformation?.name,
        })
      "
      class="
        dropdown-item
        cursor-pointer
        flex-center
        w-7
        h-7
        shadow-md
        rounded-full
        fill-white
        text-white
        bg-blue-500
        hover:bg-blue-600
      "
      @click.stop="onMessageToUser(ownerInformation)"
    >
      <SynIconCustom name="message" is-active custom-class="w-3.5 h-3.5" />
    </div>

    <!-- FAVORITE -->
    <div v-permission-model>
      <FavoriteButton
        v-if="
          ownerInformation?.adminType !== AdminType.Visitor &&
          types.includes(listTypes.favourite)
        "
        :ref-id="contactId"
        :type="typeContact"
      />
    </div>
  </div>

  <modal-profile-user
    v-if="isOpenProfileUser"
    :profile-id="ownerInformation?.userId || ownerInformation?.id"
    @cancel="isOpenProfileUser = false"
  ></modal-profile-user>
</template>
