<script setup lang="ts">
import { computed } from 'vue';
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';

withDefaults(
  defineProps<{
    title: string;
    subTitle: string;
    idButton: string;
    type: 'SUCCESS' | 'ERROR';
  }>(),
  {
    title: 'Congratulations!',
  }
);

const backgroundImage = computed<string>(() => {
  return `url(${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/common/Congratulations.png)`;
});
defineEmits(['apply']);
</script>
<template>
  <teleport to="body">
    <div
      class="
        absolute
        w-full
        h-full
        bg-current-800
        inset-0
        overflow-hidden
        flex-center
        z-50
      "
      style="background: rgba(0, 0, 0, 0.7)"
    >
      <div class="flex flex-col bg-white rounded-xl" style="width: 32rem">
        <!-- HEADER -->
        <div
          class="
            flex-center
            p-4
            flex-col
            bg-gradient-to-r
            from-current-500
            to-green-400
            relative
            rounded-t-xl
            h-36
          "
        >
          <!-- <SynIcon
            name="present"
            class="absolute -top-24 w-32 h-32 fill-white z-60"
          /> -->
          <div
            class="absolute w-full -top-32 h-64"
            :style="{
              backgroundImage: backgroundImage,
              backgroundSize: 'cover',
            }"
          ></div>
        </div>
        <!-- BODY -->
        <div class="flex-center p-4 px-8 flex-col text-current-800">
          <!-- Letter -->
          <div
            class="py-4 flex flex-col text-current-500"
            style="font-weight: 450"
          >
            <div class="w-full pb-4">
              {{ $t('HEADER_LABEL_WELCOME_HELLO') || 'Hi' }},
            </div>
            <div v-html="$t('VOUCHER_LABEL_LETTER_01')"></div>
            <div>
              {{
                $t('VOUCHER_LABEL_LETTER_02') ||
                'Fiine Team is very grateful for your trust and efforts to bring the best experience to our customers!'
              }}
            </div>
          </div>

          <!-- Voucher code -->
          <div
            class="w-full p-4 flex-center rounded-md"
            style="background: #eff9fa8a 0% 0% no-repeat padding-box"
          >
            <div class="flex-center px-4 w-20 h-20">
              <SynIcon name="ticket-voucher" :custom-class="'w-20 h-20'" />
            </div>
            <div class="flex-1 flex-center flex-col">
              <div class="uppercase text-current-800 font-semibold">
                {{ $t('VOUCHER_LABEL_YOUR_GIFT_CODE') || 'YOUR GIFT CODE' }}
              </div>
              <div class="text-3xl font-bold text-current-350 uppercase"></div>
            </div>
          </div>
        </div>

        <!-- FOOTER -->
        <div class="flex justify-end p-8">
          <SynButton
            class="h-14 px-12"
            :label="$t('VOUCHER_LABEL_APPLY_NOW') || 'Apply now'"
            @click="$emit('apply')"
          />
        </div>
      </div>
    </div>
  </teleport>
</template>
