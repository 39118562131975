<script setup lang="ts">
// *** IMPORTS ***
import {
  VideoRecordingModel,
  VideoRecordingStatus,
} from '@/domain/entities/video-recording/VideoRecordingModel';
import {
  currentVideoTileId,
  initMeetingSession,
  isSharingScreen,
  startMeetingSession,
  stopMeetingSession,
  toggleShareScreen,
} from '@/ui/plugins/awss3/aws-chime';
import { toPascalCaseObject } from '@/ui/hooks/commonFunction';
import { onMounted, ref, watch } from 'vue';
import VideoCameraTile from '@/ui/modules/video-recorder/video-recording/video-camera-tile/VideoCameraTile.vue';
import RecodingDuration from '@/ui/modules/video-recorder/video-recording/recording-duration/RecodingDuration.vue';
import VideoRecordingRepository from '@/application/repositories/VideoRecordingRepository';
import VideoShareScreenTile from '@/ui/modules/video-recorder/video-recording/video-share-screen-tile/VideoShareScreenTile.vue';

// *** PROPS, EMITS ***
const props = defineProps<{
  recording: VideoRecordingModel;
}>();

const emit = defineEmits(['onRecordingStop']);

// *** PRIVATE VARIABLES ***

// *** COMPOSABLES ***

// *** REFS ***
const audioRef = ref<HTMLAudioElement>();
const updatedRecording = ref<VideoRecordingModel>();
const isRecordingStopping = ref<boolean>();

// *** COMPUTED ***

// *** WATCHES ***
watch(
  () => currentVideoTileId.value,
  () => _startRecording()
);

// *** HOOKS ***
onMounted(async () => {
  await _initChimeMeeting();
  _startRecording();
});

// *** HANDLER FUNCTIONS ***
const onStopRecordingClick = async () => {
  // stopMeetingSession();
  // if (props.recording?.id) return;

  if (!props.recording?.id) return;

  isRecordingStopping.value = true;

  try {
    const res: any =
      await VideoRecordingRepository.getInstance().stopVideoRecording(
        props.recording?.id
      );

    if (res?.result) {
      updatedRecording.value = res.result;

      stopMeetingSession();

      emit('onRecordingStop', updatedRecording.value);
    }
  } catch (e) {
    console.log(e);
  }

  isRecordingStopping.value = false;
};

// *** PRIVATE FUNCTIONS ***
const _initChimeMeeting = async () => {
  if (!props.recording?.meeting || !props.recording?.attendee) return;

  await initMeetingSession(
    toPascalCaseObject(props.recording.meeting),
    toPascalCaseObject(props.recording.attendee)
  );

  if (audioRef.value) startMeetingSession(audioRef.value);
};

const _startRecording = async () => {
  // if (props.recording?.id) return;
  if (
    !props.recording?.id ||
    !currentVideoTileId.value ||
    props.recording?.status !== VideoRecordingStatus.Created
  ) {
    return;
  }

  const res: any =
    await VideoRecordingRepository.getInstance().startVideoRecording(
      props.recording?.id
    );

  if (res?.result) {
    updatedRecording.value = res.result;
  }
};

// *** EXPOSES ***
</script>

<template>
  <div class="h-full flex gap-3 p-3 relative">
    <!--CAMERA & ACTIONS-->
    <div class="flex-1 overflow-hidden flex flex-col gap-3 relative">
      <!--CAMERA-->
      <div class="w-full flex-1 overflow-hidden">
        <VideoCameraTile
          class="flex justify-center"
          :class="isSharingScreen ? 'items-start' : 'items-center'"
        />
      </div>

      <!--ACTIONS-->
      <div class="flex items-center flex-wrap gap-3 px-3">
        <div class="flex-1"></div>
        <div class="flex-center gap-3">
          <VigButton
            v-if="false"
            color="current"
            rounded="rounded-full"
            padding="p-3"
          >
            <SynIcon name="Microphone" is-active></SynIcon>
          </VigButton>
          <VigButton
            color="red"
            rounded="rounded-full"
            padding="p-3"
            :processing="isRecordingStopping"
            @click="onStopRecordingClick"
          >
            <SynIcon v-if="!isRecordingStopping" name="StopVideo"></SynIcon>
          </VigButton>
          <VigButton
            :color="isSharingScreen ? 'current' : 'gray'"
            rounded="rounded-full"
            padding="p-3"
            @click="toggleShareScreen"
          >
            <SynIcon name="Presentation" :is-active="isSharingScreen"></SynIcon>
          </VigButton>
        </div>
        <div class="flex-1 flex justify-end">
          <VigButton
            v-if="false"
            color="gray"
            rounded="rounded-full"
            padding="p-3"
          >
            <SynIcon name="Settings"></SynIcon>
          </VigButton>
        </div>
      </div>

      <!--DURATION-->
      <div class="absolute top-3 left-1/2 -ml-[3rem] w-[6rem] flex-center">
        <RecodingDuration :recording="updatedRecording || recording" />
      </div>
    </div>

    <!--SHARE SCREEN-->
    <div v-if="isSharingScreen" class="w-[75%]">
      <VideoShareScreenTile />
    </div>

    <!--LOADING-->
    <div
      v-if="!updatedRecording"
      class="
        absolute
        top-0
        left-0
        w-full
        h-full
        flex-center
        bg-black bg-opacity-70
      "
    >
      <SynIcon
        name="Processing"
        custom-class="w-10 h-10 text-white animate-spin"
      ></SynIcon>
    </div>

    <!--AUDIO-->
    <audio ref="audioRef" class="hidden"></audio>
  </div>
</template>

<style scoped></style>
