<script setup lang="ts">
import useResourceFilterCategories from '@/ui/composables/resource/resource-filter-categories-composables';
import { computed } from 'vue';
import $constants from '@/ui/plugins/constants';

const props = defineProps<{
  selectedCategoryId: string;
  selectedMember: any;
  isViewTrash: boolean;
}>();

defineEmits(['update:selectedCategoryId', 'update:isViewTrash']);

const { categories } = useResourceFilterCategories();

const categoriesFiltered = computed(() => {
  if (props.selectedMember?.isDomain) {
    return categories.value?.filter(
      (cate) => cate?.id === $constants.FILE.CATEGORY.STORAGE
    );
  }

  return categories.value;
});
</script>

<template>
  <div v-perfect-scrollbar class="h-full">
    <div class="h-full p-2 pb-3 flex space-x-2">
      <template v-if="!isViewTrash">
        <button
          v-for="category of categoriesFiltered"
          :key="category"
          :title="$t(category?.title)"
          class="h-full py-2 px-4 shadow rounded relative font-medium"
          :class="
            category?.id === selectedCategoryId
              ? 'bg-current text-white'
              : 'bg-white hover:bg-current-50 text-gray-500'
          "
          style="max-width: 9rem"
          @click="$emit('update:selectedCategoryId', category?.id)"
        >
          <span class="h-7 leading-7 block truncate">{{
            $t(category?.name) || category?.name
          }}</span>
          <span
            v-if="category?.id === selectedCategoryId"
            class="block w-5 overflow-hidden absolute -bottom-3 left-1/2 -ml-2"
          >
            <span
              class="
                block
                w-3
                h-3
                bg-current
                -rotate-45
                transform
                origin-top-left
                shadow
              "
            ></span>
          </span>
        </button>
      </template>

      <button
        v-else
        :title="'File bạn đã xóa'"
        class="
          h-full
          py-2
          px-4
          shadow
          rounded
          relative
          font-medium
          bg-current
          text-white
        "
        style="max-width: 9rem"
      >
        <span class="h-7 leading-7 block truncate">Thùng rác</span>
        <span
          class="block w-5 overflow-hidden absolute -bottom-3 left-1/2 -ml-2"
        >
          <span
            class="
              block
              w-3
              h-3
              bg-current
              -rotate-45
              transform
              origin-top-left
              shadow
            "
          ></span>
        </span>
      </button>
    </div>
  </div>
</template>
