<script setup lang="ts">
import ReminderCreateForm from '@/ui/modules/calendar/reminder/ReminderCreateForm.vue';

defineProps<{
  type: 'CALENDAR' | 'CHAT' | 'TASK' | undefined;
  defaultData?: {
    title?: string;
    description?: string;
    startDate?: number;
    endDate?: number;
  };
  taskDetail?: any;
  isEdit: Boolean;
}>();
const emit = defineEmits([
  'cancel',
  'onCreateSuccess',
  'onUpdateSuccess',
  'setReminderTaskSuccess',
]);

const onCancel = () => {
  emit('cancel');
};
</script>

<template>
  <SynModal
    :is-hidden-header="type != 'TASK'"
    is-hidden-footer
    disable-click-outside
    :style-body="
      type == 'TASK'
        ? 'px-6 pb-6 pt-3 flex flex-col space-y-2'
        : 'p-6 flex flex-col space-y-2'
    "
    :container-class="'w-3/12 min-h-max'"
    :container-style="`height: fit-content; min-width: 32rem`"
    @cancel="onCancel"
  >
    <template #header>
      <div class="font-semibold text-current-800 text-base">
        {{ $t('COMMON_LABEL_REMINDER_SETTING') }}
      </div>
    </template>
    <template #body>
      <!-- SECTION CHOOSE -->
      <ReminderCreateForm
        :default-data="defaultData"
        :task-detail="taskDetail"
        :type="type"
        :is-edit="isEdit"
        @cancel="onCancel"
        @on-create-success="(newData) => $emit('onCreateSuccess', newData)"
        @on-update-success="(newData) => $emit('onUpdateSuccess', newData)"
        @set-reminder-task-success="
          (data) => $emit('setReminderTaskSuccess', data)
        "
      />
    </template>
  </SynModal>
</template>
